import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box, Menu, Tooltip } from '@mui/material';
import clsx from 'clsx';

// Component
import { Checkbox } from '../../ui/Checkbox/Checkbox';
import { IconButton } from '../../ui/IconButton/IconButton';

// Models
import { Notifications as INotifications } from '../../models/shared.types';

// Styles
import styles from './Notifications.module.scss';

type NotificationsProps = {
  buttonClasses?: string;
  iconColor?: string;
  reset?: string;
  onChange: (notifications: INotifications) => void;
};

export const Notifications = (props: NotificationsProps) => {
  const { t } = useTranslation();

  // Component state
  const [anchorEl, setAnchorEl] = useState<Element | null | undefined>(null);
  const open = Boolean(anchorEl);

  const { register, setValue, watch } = useForm();
  const watchEmail = watch('email');
  const watchSms = watch('sms');

  /**
   * Close menu.
   */
  const onClose = () => {
    setAnchorEl(null);
  };

  // Reset notifications on prop change
  useEffect(() => {
    if (props.reset) {
      setValue('email', false);
      setValue('sms', false);
    }
    // eslint-disable-next-line
  }, [props.reset]);

  // Emit notification data on change
  useEffect(() => {
    props.onChange({ send_email: watchEmail, send_sms: watchSms });
    // eslint-disable-next-line
  }, [watchEmail, watchSms]);

  return (
    <>
      <div
        style={{
          position: 'relative',
        }}
        className={clsx(props.buttonClasses && props.buttonClasses)}
      >
        <IconButton
          icon={['far', 'bell-exclamation']}
          iconSize="small"
          sxButton={{
            backgroundColor: anchorEl ? 'primary.light' : undefined,
          }}
          sxIcon={{
            color: anchorEl ? 'primary.main' : 'text.secondary',
          }}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        />
        {(watchEmail || watchSms) && (
          <Box
            sx={{ backgroundColor: 'error.main' }}
            className={styles['news-center-notifications-info']}
          ></Box>
        )}
      </div>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        open={open}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '0.5rem',
            boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
            marginTop: '-0.5rem',
          },
          '& .MuiList-root': {
            padding: 0,
          },
        }}
        className={styles['news-center-notifications']}
      >
        <Box
          className={styles['news-center-notifications-header']}
          sx={{ borderColor: 'border.app' }}
        >
          <div className={styles['news-center-notifications-header-title']}>
            {t('newscenter.room.notifications.title')}
          </div>
          <Tooltip
            title={t('newscenter.room.notifications.tooltip').toString()}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'bg.tooltip',
                },
              },
            }}
          >
            <IconButton
              icon={['fal', 'info-circle']}
              iconSize="small"
              sxIcon={{
                opacity: 0.5,
                width: '1rem',
              }}
              padding="0.25rem"
            />
          </Tooltip>
        </Box>
        <form className={styles['news-center-notifications-inputs']}>
          <Checkbox
            checked={watchEmail}
            classes={styles['news-center-notifications-inputs-email']}
            content={t('app.email')}
            register={register('email')}
            onToggle={(checked) => setValue('email', checked)}
          />
          <Checkbox
            checked={watchSms}
            content={t('app.sms')}
            register={register('sms')}
            onToggle={(checked) => setValue('sms', checked)}
          />
        </form>
      </Menu>
    </>
  );
};
