import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// Components
import { Loader } from '../shared/ui/Loader/Loader';

// Lazy-load pages
const ToolPreview = lazy(
  () => import('../modules/public/pages/ToolPreview/ToolPreview')
);
const MarketView = lazy(
  () => import('../modules/public/pages/MarketView/MarketView')
);

export const PublicRouter = () => {
  return (
    <Routes>
      <Route
        path="/tools/preview/:id"
        element={
          <Suspense fallback={<Loader />}>
            <ToolPreview />
          </Suspense>
        }
      />
      <Route
        path="/market"
        element={
          <Suspense fallback={<Loader />}>
            <MarketView />
          </Suspense>
        }
      />
      <Route
        path="/market/:templateid"
        element={
          <Suspense fallback={<Loader />}>
            <MarketView />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
