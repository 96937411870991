// Hooks
import { useFetch } from '../../../shared/hooks/use-fetch.hook';

// Models
import {
  Community,
  CommunityData,
  CommunityInfo,
  CommunityLicense,
  CommunityNotification,
  CommunityPostRequest,
  LicenseNumberPatchRequest,
  Tag,
} from '../models/communities.types';
import { UserLinkChild } from '../../user/models/user.types';

// Stores
import { AuthState, useAuthStore } from '../../public/stores/use-auth.store';

export const useCommunitiesHttp = () => {
  const { fetchData, uploadData } = useFetch();

  // Auth store state
  const [accessToken] = useAuthStore((state: AuthState) => [state.accessToken]);

  /**
   * Get community.
   * @returns Community
   */
  const communityGet = async (id: string): Promise<Community | undefined> => {
    if (accessToken) {
      // const decodedJWT: JwtPayload = jwt_decode(accessToken);
      return await fetchData(`communities/${id}`);
    }
  };

  /**
   * Get community.
   * @returns Community
   */
  const communityGetByID = async (
    id: string
  ): Promise<CommunityData | undefined> => {
    if (accessToken) {
      // const decodedJWT: JwtPayload = jwt_decode(accessToken);
      return await fetchData(`communities/${id}`);
    }
  };

  /**
   * Get community info
   * @param id Community id
   * @returns License
   */
  const communityInfoGet = async (
    id: string | undefined
  ): Promise<CommunityLicense | undefined> => {
    if (accessToken && id) {
      // const decodedJWT: JwtPayload = jwt_decode(accessToken);
      return await fetchData(`communities/${id}/license`);
    }
  };

  /**
   * Update community info.
   * @param id Community id
   * @param communityInfo Community info
   * @returns Community info
   */
  const communityInfoUpdate = async (
    id: string | undefined,
    communityInfo: CommunityInfo
  ): Promise<CommunityInfo | undefined> => {
    if (accessToken && id && communityInfo) {
      // const decodedJWT: JwtPayload = jwt_decode(accessToken);
      return await fetchData(`communities/${id}/info`, {
        method: 'PATCH',
        body: communityInfo,
      });
    }
  };

  /**
   * Get community license.
   * @param id Community id
   * @returns License
   */
  const communityLicenseGet = async (
    id: string | undefined
  ): Promise<CommunityLicense | undefined> => {
    if (accessToken && id) {
      // const decodedJWT: JwtPayload = jwt_decode(accessToken);
      return await fetchData(`communities/${id}/license`);
    }
  };

  /**
   * Update community license.
   * @param id
   * @param communityLicense Community license
   * @returns Community license
   */
  const communityLicenseUpdate = async (
    id: string | undefined,
    communityLicense: CommunityLicense
  ): Promise<CommunityLicense | undefined> => {
    if (accessToken && id && communityLicense) {
      // const decodedJWT: JwtPayload = jwt_decode(accessToken);
      return await fetchData(`communities/${id}/license`, {
        method: 'PATCH',
        body: communityLicense,
      });
    }
  };

  /**
   * Get community Notification.
   * @param id
   * @param communityNotification Community Notification
   * @returns Community Notification
   */
  const communityNotificationGet = async (
    id: string
  ): Promise<CommunityNotification | undefined> => {
    if (accessToken) {
      return await fetchData(`communities/notification/${id}`, {
        method: 'GET',
      });
    }
  };

  /**
   * Update community Notification.
   * @param id
   * @param communityNotification Community Notification
   * @returns Community Notification
   */
  const communityNotificationUpdate = async (
    data: CommunityNotification
  ): Promise<CommunityNotification | undefined> => {
    if (accessToken) {
      return await fetchData(`communities/notification/${data.community_id}`, {
        method: 'PATCH',
        body: data,
      });
    }
  };

  /**
   * Post community
   * @param id
   * @returns Community
   */
  const communityPost = async (
    communityPostRequest: CommunityPostRequest
  ): Promise<UserLinkChild | undefined> => {
    if (accessToken && communityPostRequest) {
      // const decodedJWT: JwtPayload = jwt_decode(accessToken);
      var formData = new FormData();
      const fromBody = {
        name: communityPostRequest.name,
        sector: communityPostRequest.sector,
        employee: communityPostRequest.employeename ?? undefined,
        member: communityPostRequest.membername ?? undefined,
      };
      formData.append('body', JSON.stringify(fromBody));
      if (communityPostRequest.logo) {
        formData.append('logo_file', communityPostRequest.logo);
      }

      return await uploadData(`communities`, {
        method: 'POST',
        formData,
      });
    }
  };

  /**
   * Patch community license
   * @body LicenseNumberPatchRequest
   * @returns LicenseNumberPatchRequest
   */
  const licenseNumberPatch = async (
    body: LicenseNumberPatchRequest
  ): Promise<LicenseNumberPatchRequest | undefined> => {
    if (accessToken) {
      return await fetchData(`communities/license/number`, {
        method: 'PATCH',
        body: body,
      });
    }
  };

  /**
   * GET community folder
   * @body community id
   * @returns LicenseNumberPatchRequest
   */
  const communityGetFolder = async (body: {
    id: string;
  }): Promise<Tag[] | undefined> => {
    if (accessToken) {
      return await fetchData(`communities/folder/${body.id}`, {
        method: 'GET',
      });
    }
  };

  return {
    communityGet,
    communityGetByID,
    communityPost,
    communityInfoGet,
    communityInfoUpdate,
    communityLicenseGet,
    communityLicenseUpdate,
    communityNotificationGet,
    communityNotificationUpdate,
    licenseNumberPatch,
    communityGetFolder,
  };
};
