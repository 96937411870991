import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

// Components
import { PageLayout } from '../../../../shared/components/PageLayout/PageLayout';
import MarketSummary from '../../components/MarketSummary/MarketSummary';
import { MarketFilters } from '../../components/MarketFilters/MarketFilters';
// import { Loader } from '../../../../shared/ui/Loader/Loader';

// Hooks
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useMarketHttp } from '../../hooks/use-market-http.hook';
import { useMarketMock } from '../../hooks/use-market-mock.hook';
// import { useTemplates } from '../../../templates/hooks/use-templates.hook';
import { useShared } from '../../../../shared/hooks/use-shared.hook';

// Models
import { PagePath } from '../../../../shared/models/shared.types';
import { MarketTemplatesGetParams } from '../../models/market.types';
import { MarketTemplate as IMarketTemplate } from '../../models/market.types';
import { TemplateType } from '../../../templates/models/templates.types';

// Stores
import { useMarketStore } from '../../stores/use-market.store';
import {
  SharedState,
  useSharedStore,
} from '../../../../shared/stores/use-shared.store';
import { useToolsStore } from '../../../tools/stores/use-tools.store';

// Styles
// import styles from './Market.module.scss';

// Lazy-load components
// const MarketSummary = lazy(
//   () => import('../../components/MarketSummary/MarketSummary')
// );
// const MarketTemplatePreview = lazy(
//   () => import('../MarketTemplatePreview/MarketTemplatePreview')
// );
type MarketPublicProps = {
  public?: boolean;
};
export const Market = (props: MarketPublicProps) => {
  const { handleError, handleRetry } = useFetch();
  const { marketTemplatesGet, marketTemplatesPublicGet } = useMarketHttp();
  const { marketFiltersTagsGet, templatesGet } = useMarketMock();
  const { getBreadcrumbPath } = useShared();

  // Shared store state
  const [setMarket, setBreadcrumbPath] = useSharedStore(
    (state: SharedState) => [state.setMarket, state.setBreadcrumbPath]
  );

  // eslint-disable-next-line
  const [templates, setTemplates] = useState<IMarketTemplate[]>([]);

  // Market store state
  const [setSelectedMenuItemType, setSelectedTemplate, setTags] =
    useMarketStore((state) => [
      state.setSelectedMenuItemType,
      state.setSelectedTemplate,
      state.setTags,
    ]);

  // Tool store state
  const [resetToolsStoreData] = useToolsStore((state) => [
    state.resetToolsStoreData,
  ]);

  // Set state on mount
  useEffect(() => {
    if (props.public) {
    } else {
      setMarket(true);
      setBreadcrumbPath(getBreadcrumbPath(PagePath.Market));
      resetToolsStoreData();

      return () => {
        setMarket(false);
        setBreadcrumbPath([]);
        setSelectedTemplate(undefined);
      };
    }

    // eslint-disable-next-line
  }, []);

  // ######### //
  // MUTATIONS //
  // ######### //

  // GET Market templates mutation
  const marketTemplatesGetMutation = useMutation(
    (params?: MarketTemplatesGetParams) => marketTemplatesGet(params)
  );

  // GET Market templates public mutation
  const marketTemplatesPublicGetMutation = useMutation(
    (params?: MarketTemplatesGetParams) => marketTemplatesPublicGet(params)
  );

  // Add templates to state
  useEffect(() => {
    if (marketTemplatesGetMutation.data) {
      try {
        setTemplates(marketTemplatesGetMutation.data);
      } catch (error) {
        console.log('ERROR on getting template:', error);
      }
    }
    if (marketTemplatesGetMutation.error) {
      // const errRes = marketTemplatesGetMutation.error?.response;
      // if (errRes) {
      //   handleError(errRes.status);
      // }
      // #TODO: Remove mock data
      setTemplates(templatesGet());
    }
    // eslint-disable-next-line
  }, [marketTemplatesGetMutation.data, marketTemplatesGetMutation.error]);

  useEffect(() => {
    if (marketTemplatesPublicGetMutation.data) {
      try {
        setTemplates(marketTemplatesPublicGetMutation.data);
      } catch (error) {
        console.log('ERROR on getting template:', error);
      }
    }
    if (marketTemplatesPublicGetMutation.error) {
      // const errRes = marketTemplatesPublicGetMutation.error?.response;
      // if (errRes) {
      //   handleError(errRes.status);
      // }
      // #TODO: Remove mock data
      setTemplates(templatesGet());
    }
    // eslint-disable-next-line
  }, [
    marketTemplatesPublicGetMutation.data,
    marketTemplatesPublicGetMutation.error,
  ]);

  // ####### //
  // EFFECTS //
  // ####### //

  // Component mount effects
  useEffect(() => {
    if (props.public) {
      marketTemplatesPublicGetMutation.mutate({
        template_type: TemplateType.Public,
      });
    } else {
      //Set filters data
      setTags(marketFiltersTagsGet());
      //Set public market templates
      marketTemplatesGetMutation.mutate({
        template_type: TemplateType.Public,
      });
    }

    // eslint-disable-next-line
  }, []);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handler on menu item click.
   * @param params MarketTemplatesGetParams
   */
  const onMenuItemClick = useCallback((params?: MarketTemplatesGetParams) => {
    params?.template_type && setSelectedMenuItemType(params?.template_type);
    marketTemplatesGetMutation.mutate(params);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageLayout
        leftComponent={
          <MarketFilters
            public={props.public}
            onMenuItemClick={onMenuItemClick}
          />
        }
        rightComponent={
          <MarketSummary public={props.public} templates={templates} />
        }
      />
    </>
  );
};
