import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';

// Components
import { Input } from '../../../../shared/ui/Input/Input';
import { Select } from '../../../../shared/ui/Select/Select';
import { TextButton } from '../../../../shared/ui/TextButton/TextButton';

// Contexts
import { SocketContext } from '../../../../shared/context/socket.context';

// Models
import {
  GroupCreateEditState,
  GroupRoomSettings,
} from '../../models/news-center.types';
import {
  CrudState,
  ResultState,
  Option,
} from '../../../../shared/models/shared.types';

// Stores
import { useAuthStore } from '../../../public/stores/use-auth.store';
import { useNewsCenterStore } from '../../stores/use-news-center.store';
import { useUserStore } from '../../../user/stores/use-user.store';

// Styles
import styles from './GroupCreateEditSettings.module.scss';
import { Checkbox } from '../../../../shared/ui/Checkbox/Checkbox';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useMutation } from 'react-query';
import { useContactsHttp } from '../../../contacts/hooks/use-contacts-http.hook';
import { useContacts } from '../../../contacts/hooks/use-contacts.hook';
import {
  CommunityFolder,
  CommunityGroup,
  ContactCommunity,
  ContactCommunityIdType,
  ContactsGetFilters,
} from '../../../contacts/models/contacts.types';
import {
  ContactsState,
  useContactsStore,
} from '../../../contacts/stores/use-contacts.store';
import { FormFieldLabel } from '../../../../shared/ui/FormFieldLabel/FormFieldLabel';
import {
  SharedState,
  useSharedStore,
} from '../../../../shared/stores/use-shared.store';
import { CommunityUserRole } from '../../../communities/models/communities.types';
import { Switch } from '../../../../shared/ui/Switch/Switch';
import { IconTextButton } from '../../../../shared/ui/IconTextButton/IconTextButton';

type GroupCreateEditSettingsProps = {
  newsCenter?: boolean;
  seletedFolder?: CommunityFolder[] | undefined;
  selectedCommunity?: ContactCommunity;
  selectedGroup?: CommunityGroup;
  groupRoomCreateEditCrudState: CrudState;
  contactCommunity?: ContactCommunity;
  setIsGroupSettingLoad?: Dispatch<SetStateAction<boolean>>;
  setGroupRoomCreateEditState?: Dispatch<SetStateAction<GroupCreateEditState>>;
  setCommunityGroup?: Dispatch<SetStateAction<CommunityGroup | undefined>>;
  setContactCommunity?: Dispatch<SetStateAction<ContactCommunity>>;
  onClose: () => void;
  onContactsGet?: (params?: ContactsGetFilters) => void;
};

export const GroupCreateEditSettings = (
  props: GroupCreateEditSettingsProps
) => {
  // Context
  const socket = useContext(SocketContext);

  // Hooks
  const { t } = useTranslation();
  const { handleError, handleRetry } = useFetch();
  const {
    getContactFolders,
    contactGroupPost,
    contactGroupPatch,
    setGroupInviteLink,
    getContactMsgRoomHasMsgs,
  } = useContactsHttp();
  const { getContactFoldersEffect } = useContacts();

  // Component state
  const [communityOptions, setCommunityOptions] = useState<Option[]>([]);
  const [toolOptions, setToolOptions] = useState<Option[]>([]);
  const [defaultValues, setDefaultValues] = useState<GroupRoomSettings>({
    room_has_msgs: false,
    folder_option: undefined,
    community_id: '',
    description: '',
    name: '',
  });
  const [messageFunction, setMessageFunction] = useState<boolean>(false);
  const [noIncludeCreator, setNoIncludeCreator] = useState<boolean>(false);
  const [hasAllreadyMsgs, setHasAllreadyMsgs] = useState<boolean | undefined>(
    false
  );
  const [foldersOptions, setFoldersOptions] = useState<Option[]>([]);
  const [selectedFolderOptions, setSelectedFolderOptions] = useState<any>([]);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [groupLink, setGroupLink] = useState<string | null>(null);

  // Auth store state
  const [accessToken] = useAuthStore((state) => [state.accessToken]);

  // News center store state
  const [selectedRoom, setGroupRoomCreateEditSettings] = useNewsCenterStore(
    (state) => [state.selectedRoom, state.setGroupRoomCreateEditSettings]
  );

  // User store state
  const [communities] = useUserStore((state) => [state.communities]);

  // Shared store state
  const [setNotification] = useSharedStore((state: SharedState) => [
    state.setNotification,
  ]);

  // Contacts Store State
  const [navCommunities, groupChats, setNavCommunities, setGroupChats] =
    useContactsStore((state: ContactsState) => [
      state.navCommunities,
      state.groupChats,
      state.setNavCommunities,
      state.setGroupChats,
    ]);

  // ######## //
  // FORM INIT //
  // ######### //

  // React hook form validation schema
  const validation = yup.object({
    // community_id: yup
    //   .string()
    //   .required(t('newscenter.groups.create_edit.settings.community.error')),
    community_id: yup.string(),
    description: yup
      .string()
      .required(t('newscenter.groups.create_edit.settings.description.error')),
    name: yup
      .string()
      .required(t('newscenter.groups.create_edit.settings.name.error')),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues,
    resolver: yupResolver(validation),
  });
  const communityId = watch('community_id');
  const toolId = watch('tool_id');

  // ######### //
  // MUTATIONS //
  // ######### //
  const getNewsCenterFolderMutation = useMutation(
    (id: string | undefined) => getContactFolders({ id: id }),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          setFoldersOptions(getContactFoldersEffect(data));
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            handleError(errRes.status);
          }
        }
      },
    }
  );
  const getgetContactMsgRoomHasMsgs = useMutation(
    (id: string) => getContactMsgRoomHasMsgs({ id: id }),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          setHasAllreadyMsgs(data);
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            handleError(errRes.status);
          }
        }
      },
    }
  );
  const contactGroupPostMutation = useMutation(
    (settings: GroupRoomSettings) => contactGroupPost(settings),
    {
      // retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        // data type is CommunityGroup or Room.
        if (data) {
          handleAfterCreatePatchGroup(data);
          setNotification({
            title: `${t('contacts.toolbar.group.create')}`,
            state: ResultState.Success,
          });
        }
        if (error) {
          console.error('Error posting contact group:');
        }
      },
    }
  );

  const contactGroupPatchMutation = useMutation(
    (settings: GroupRoomSettings) => contactGroupPatch(settings),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          handleAfterCreatePatchGroup(data);
          setNotification({
            title: `Gruppeneinstellung gespeichert!`,
            state: ResultState.Success,
          });
        }
      },
    }
  );

  // ####### //
  // EFFECTS //
  // ####### //

  // Control submit button
  useEffect(() => {
    if (isButtonDisabled) {
      const timeoutId = setTimeout(() => {
        setButtonDisabled(false);
      }, 60000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isButtonDisabled]);

  // Show and Hide Load component
  useEffect(() => {
    if (props.setIsGroupSettingLoad) {
      if (contactGroupPatchMutation.isLoading) {
        props.setIsGroupSettingLoad(true);
      }
    }
  }, [contactGroupPatchMutation.isLoading, contactGroupPostMutation.isLoading]);

  // Set checkbox value for message function
  useEffect(() => {
    props.newsCenter && setMessageFunction(true);
  }, [props.newsCenter]);

  // Set community select options
  useEffect(() => {
    const options: Option[] = [];

    if (
      props.selectedCommunity &&
      props.selectedCommunity.id !== ContactCommunityIdType.All
    ) {
      options.push({
        placeholder: props.selectedCommunity.name,
        value: props.selectedCommunity.id,
      });
    } else if (communities && communities.length > 0) {
      options.push({
        placeholder: t('app.select'),
        value: '',
      });
      for (const community of communities) {
        options.push({
          placeholder: community.data.name,
          value: community.data.id,
        });
      }
    }
    setCommunityOptions(options);
    // eslint-disable-next-line
  }, [props.selectedCommunity, communities]);

  useEffect(() => {
    if (selectedRoom?.tools_options && selectedRoom?.tools_options.length > 0) {
      const options: Option[] = [
        {
          placeholder: t('app.select'),
          value: '',
        },
      ];
      for (const tool of selectedRoom?.tools_options) {
        options.push({
          placeholder: tool.name,
          value: tool.id,
        });
      }
      setToolOptions(options);
    }
    // eslint-disable-next-line
  }, [selectedRoom?.tools_options]);

  useEffect(() => {
    if (
      // communityOptions.length > 0 &&
      props.groupRoomCreateEditCrudState === CrudState.Update &&
      selectedRoom
    ) {
      const values: GroupRoomSettings = {
        community_id: '',
        description: '',
        name: '',
        tool_id: '',
      };

      if (selectedRoom.settings?.community_id) {
        values.community_id = selectedRoom.settings.community_id;
        setValue('community_id', selectedRoom.settings.community_id ?? '');
      }

      if (selectedRoom.settings?.description) {
        values.description = selectedRoom.settings.description;
        setValue('description', selectedRoom.settings.description);
      }

      if (selectedRoom.settings?.name) {
        values.name = selectedRoom.settings.name;
        setValue('name', selectedRoom.settings.name);
      }

      if (selectedRoom.settings?.tool_id) {
        values.tool_id = selectedRoom.settings.tool_id;
        setValue('tool_id', selectedRoom.settings.tool_id);
      }
      initializeFolderOptions();
      setDefaultValues({ ...values });
    } else if (
      // communityOptions.length > 0 &&
      props.groupRoomCreateEditCrudState === CrudState.Create ||
      props.groupRoomCreateEditCrudState === CrudState.CreateNoMember
    ) {
      setValue('name', '');
      setValue('description', '');
      props.selectedCommunity?.id &&
        props.selectedCommunity?.id !== ContactCommunityIdType.All &&
        setValue('community_id', props.selectedCommunity.id);
      initializeFolderOptions();
    } else if (
      props.groupRoomCreateEditCrudState === CrudState.UpdateNoMember &&
      props.selectedGroup
    ) {
      const values: GroupRoomSettings = {
        community_id: '',
        description: '',
        name: '',
        room_has_msgs: undefined,
      };

      if (props.selectedGroup?.community?.id) {
        values.community_id = props.selectedGroup.community.id;
        setValue('community_id', props.selectedGroup.community.id ?? '');
      } else if (props.selectedGroup?.community_id?.id) {
        values.community_id = props.selectedGroup.community_id.id;
        setValue('community_id', props.selectedGroup.community_id.id ?? '');
      }

      if (props.selectedGroup?.description) {
        values.description = props.selectedGroup.description;
        setValue('description', props.selectedGroup.description);
      }

      if (props.selectedGroup?.name) {
        values.name = props.selectedGroup.name;
        setValue('name', props.selectedGroup.name);
      }

      if (props.selectedGroup?.room_has_msgs) {
        values.room_has_msgs = props.selectedGroup.room_has_msgs;
        setMessageFunction(props.selectedGroup.room_has_msgs);
      }

      if (props.selectedGroup?.no_include_creator) {
        values.no_include_creator = props.selectedGroup?.no_include_creator;
        setNoIncludeCreator(props.selectedGroup?.no_include_creator);
      }

      // if (props.selectedGroup?.folders) {
      //   values.folder_option = [...props.selectedGroup.folders];
      //   setSelectedFolderOptions([...props.selectedGroup.folders]);
      // }

      initializeFolderOptions();

      setDefaultValues({ ...values });
    }

    return () => {
      setValue('name', '');
      setValue('description', '');
      setValue('community_id', '');
      setValue('tool_id', undefined);
      setValue('room_has_msgs', false);
      setValue('no_include_creator', false);
    };
  }, []);

  // Set GroupsOptins by selecting Community
  useEffect(() => {
    const id =
      communityId === ContactCommunityIdType.All ? undefined : communityId;
    communityId && getNewsCenterFolderMutation.mutate(id);
    props.selectedGroup &&
      getgetContactMsgRoomHasMsgs.mutate(props.selectedGroup.id ?? '');
  }, [communityId, props.selectedGroup]);

  useEffect(() => {
    const values: GroupRoomSettings = {
      community_id: '',
      description: '',
      name: '',
      room_has_msgs: undefined,
    };

    if (props.selectedGroup?.community_id?.id) {
      values.community_id = props.selectedGroup.community_id.id;
      setValue('community_id', props.selectedGroup.community_id.id);
    }

    if (
      props.selectedCommunity?.id &&
      props.selectedCommunity.id !== ContactCommunityIdType.All
    ) {
      values.community_id = props.selectedCommunity.id;
      setValue('community_id', props.selectedCommunity.id);
    }

    setDefaultValues({ ...values });

    if (props.selectedGroup?.link) {
      setIsChecked(true);
      setGroupLink(props.selectedGroup?.link ?? '');
    }
  }, [props.selectedGroup, props.selectedCommunity]);

  /**
   * Handler to add or remove Group in Navigation
   * @param data CommunityGroup
   */
  const handleAfterCreatePatchGroup = (data: any) => {
    props.setIsGroupSettingLoad && props.setIsGroupSettingLoad(false);

    // Update selectedGroup setting
    if (props.selectedGroup && props.setCommunityGroup) {
      const updatedGroup: CommunityGroup = {
        ...props.selectedGroup,
      };
      updatedGroup.name = data.name;
      updatedGroup.description = data.description;
      updatedGroup.room_has_msgs = data.room_has_msgs ?? undefined;
      updatedGroup.no_include_creator = data.no_include_creator ?? undefined;
      updatedGroup.tags = data.folders ? data.folders : undefined;
      props.setCommunityGroup(updatedGroup);
    }

    // Update contactCommunity
    if (props.contactCommunity && props.setContactCommunity) {
      const updatedContacctCommunity = { ...props.contactCommunity };
      updatedContacctCommunity.selected_group = data;
      props.setContactCommunity(updatedContacctCommunity);
    }

    const updatedNavCommunities = [...navCommunities];

    // When a group belongs to a folder,
    if (data.folders) {
      updatedNavCommunities.map((navCom) => {
        if (data.community && data.community.data.id === navCom.id) {
          if (data.folders) {
            let handeldFolders: string[] = [];
            if (navCom.folders) {
              navCom.folders?.map((folder) => {
                // Case 1: When the group is created in a folder that already exists
                if (
                  data.folders?.some(
                    (dataFolder: { id: string; name: string }) =>
                      dataFolder.id === folder.id
                  )
                ) {
                  // Mark this folder as handled.
                  handeldFolders.push(folder.id);
                  // Add or update group to Folder in Navigation
                  if (folder.groups) {
                    const groupIndex = folder.groups.findIndex(
                      (preGroup) => preGroup.id === data.id
                    );
                    // Group exists in Folder
                    if (groupIndex > -1) {
                      folder.groups[groupIndex].name = data.name;
                    }
                    // No Group exists in Folder
                    else {
                      folder.groups.push(data);
                    }
                  } else {
                    folder.groups = [data];
                  }
                } else {
                  // remove group from folder
                  if (folder.groups) {
                    folder.groups = folder.groups?.filter(
                      (preGroup) => preGroup.id !== data.id
                    );
                  }
                }
                // update Contacts view
                // props.onContactsGet &&
                //   props.onContactsGet({
                //     community_id_group: data.id,
                //     community_id_folder: folder.id,
                //   });\
                return folder.groups.length > 0 ? folder : undefined;
              });
            }

            for (const dataFolder of data.folders) {
              // This folder is not handled
              if (
                !handeldFolders.some(
                  (handeldFolder) => handeldFolder === dataFolder.id
                )
              ) {
                let newFolder = {
                  id: dataFolder.id,
                  name: dataFolder.name,
                  groups: [data],
                };
                if (navCom.folders) {
                  //  Case 2: If the community has a folder but a new folder is created
                  navCom.folders.push(newFolder);
                } else {
                  // Case 3: If the community does not have a folder and a new folder is created
                  navCom.folders = [newFolder];
                }
              }
            }

            // Remove the Group from Community groups
            navCom.groups = navCom.groups?.filter(
              (navGroup) => navGroup.id !== data.id
            );
            // Remove empty folder
            navCom.folders = navCom.folders?.filter(
              (folder) => folder.groups.length > 0
            );
          }
        }

        return navCom;
      });

      setNavCommunities(updatedNavCommunities);

      // When the group belongs to a community,
    } else if (data.community) {
      const comIndex = updatedNavCommunities.findIndex(
        (navCom) => navCom.id === data.community?.data.id
      );
      if (comIndex > -1) {
        if (updatedNavCommunities[comIndex].groups) {
          const groupIndex = updatedNavCommunities[comIndex].groups!.findIndex(
            (group) => group.id === data.id
          );
          if (groupIndex > -1) {
            // Update group name in navigation
            updatedNavCommunities[comIndex].groups![groupIndex].name =
              data.name;
          } else {
            updatedNavCommunities[comIndex].groups?.push(data);
          }
        } else {
          updatedNavCommunities[comIndex].groups = [data];
        }

        // Case 4: If the group has been removed from all folders
        //Remove a group from previous folder
        updatedNavCommunities[comIndex].folders?.map((folder) => {
          if (folder.groups) {
            folder.groups = folder.groups?.filter(
              (preGroup) => preGroup.id !== data.id
            );
          }
          return folder;
        });
      }
      setNavCommunities(updatedNavCommunities);
      // props.onContactsGet &&
      //   props.onContactsGet({
      //     community_id_group: data.id,
      //   });

      // When a group does not belong to any community,
      // Data type is Room
    } else {
      const updatedGroupChats = [...groupChats];
      updatedGroupChats.push(data);
      setGroupChats(updatedGroupChats);
      // props.onContactsGet &&
      //   props.onContactsGet({
      //     group_id: data.id,
      //   });
    }
  };

  /**
   * Handler to submit group settings form data.
   * @param formData GroupSettings
   */
  const onSettingsSubmit = useCallback(
    (settings: GroupRoomSettings) => {
      //handle button click
      setButtonDisabled(true);

      const groupRoomSettings = settings;
      groupRoomSettings.room_has_msgs = messageFunction;
      groupRoomSettings.folder_option = selectedFolderOptions;
      groupRoomSettings.no_include_creator = noIncludeCreator;

      if (props.selectedGroup) {
        groupRoomSettings.group_id = props.selectedGroup.id;
      }

      if (props.groupRoomCreateEditCrudState === CrudState.Create) {
        setGroupRoomCreateEditSettings(groupRoomSettings);
        props.setGroupRoomCreateEditState &&
          props.setGroupRoomCreateEditState(GroupCreateEditState.Members);
      }
      if (props.groupRoomCreateEditCrudState === CrudState.CreateNoMember) {
        contactGroupPostMutation.mutate(groupRoomSettings);
        props.onClose();
      }
      if (props.groupRoomCreateEditCrudState === CrudState.UpdateNoMember) {
        contactGroupPatchMutation.mutate(groupRoomSettings);
        props.onClose();
      }
      if (
        props.groupRoomCreateEditCrudState === CrudState.Update &&
        selectedRoom
      ) {
        socket.emit(
          'updateRoomSettings',
          {
            accessToken,
            room_id: selectedRoom.id,
            groupRoomSettings,
          }
          // (response: SocketEventSubscriptionResponse) => {
          //   if (response.status === ResultState.Success) {
          //     props.onClose();
          //   }
          // }
        );
        props.onClose();
      }
    },
    // eslint-disable-next-line
    [
      props.groupRoomCreateEditCrudState,
      selectedRoom,
      messageFunction,
      selectedFolderOptions,
      noIncludeCreator,
    ]
  );

  const initializeFolderOptions = useCallback(() => {
    const options: any[] = [];

    props.selectedGroup?.tags?.map((folder) => {
      options.push({
        value: folder.id,
        placeholder: folder.name,
      });
    });
    setSelectedFolderOptions(options);
  }, [props.seletedFolder]);

  /**
   * Handler to set folder tag in AutoCompelte
   */
  const folderRenderTags = useCallback(
    (values: readonly any[], getTagProps) => {
      return selectedFolderOptions.map((value: any, index: number) => (
        <Chip
          variant="outlined"
          label={value.placeholder ? value.placeholder : value}
          {...getTagProps({ index })}
        />
      ));
    },
    [selectedFolderOptions]
  );

  /**
   * Handler to set folder options in AutoCompelte
   */
  const folderOnChange = useCallback((event, newValue) => {
    setSelectedFolderOptions(newValue);
  }, []);

  const setLinkMutation = useMutation(
    (data: {
      id: string | undefined;
      cid: string | undefined;
      link?: string;
    }) =>
      setGroupInviteLink({ group_id: data.id, cid: data.cid, link: data.link }),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error, variables) => {
        if (data) {
          // Update selectedGroup setting
          if (props.selectedGroup && props.setCommunityGroup) {
            const updatedGroup: CommunityGroup = {
              ...props.selectedGroup,
            };
            updatedGroup.link = variables.link;
            props.setCommunityGroup(updatedGroup);
          }
          setNotification({
            title: 'Link wurde gespeichert!',
            state: ResultState.Success,
          });
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            handleError(errRes.status);
          }
        }
      },
    }
  );

  /**
   * Handler to switch url state.
   * @param checked Switch state
   */
  const onLinkSwitch = useCallback(
    (checked: boolean) => {
      let newURL;
      if (checked) {
        newURL = uuidv4();
        setIsChecked(true);
        setGroupLink(newURL);
      }

      if (!checked) {
        newURL = undefined;
        setIsChecked(false);
        setGroupLink(null);
      }

      setLinkMutation.mutate({
        id: props.selectedGroup?.id,
        cid: props.selectedCommunity?.id,
        link: newURL,
      });

      return checked;
      // eslint-disable-next-line
    },
    [props.selectedCommunity, props.selectedGroup]
  );

  const onUrlPaste = useCallback(() => {
    navigator.clipboard.writeText(
      `${window.location.href.substring(
        0,
        window.location.href.indexOf('/', window.location.href.indexOf('/') + 2)
      )}/group/invite/${groupLink}`
    );
    setNotification({
      title: t('tools.preview.info.share.notification'),
    });
    // eslint-disable-next-line
  }, [groupLink]);

  return (
    <>
      {props.groupRoomCreateEditCrudState === CrudState.UpdateNoMember &&
      (props.selectedGroup?.id === CommunityUserRole.Admin ||
        props.selectedGroup?.id === CommunityUserRole.Employee ||
        props.selectedGroup?.id === CommunityUserRole.Member) ? (
        <>
          <FormFieldLabel
            required={false}
            label={'Einladungslink'}
            sublabel={
              'Mit diesem Link kannst du Personen direkt in diese Gruppe einladen. Nutze diese Funktion in Verbindung mit den Zugriffsrechten in den Hilfsmitteln'
            }
            paddingClassName={
              styles['tool-element-date-picker-form-field-label-padding']
            }
          />
          <Switch
            classes={styles['template-publish-type-next']}
            checked={isChecked || false}
            disabled={false}
            children={
              isChecked && (
                <IconTextButton
                  sx={{ width: 1 / 2 }}
                  borderStyle="dashed"
                  classes={styles['tool-content-read-edit-text-button']}
                  icon={['fal', 'copy']}
                  padding="0.5rem 0.75rem"
                  preset="tools-primary"
                  onClick={onUrlPaste}
                >
                  {`${window.location.href.substring(
                    0,
                    window.location.href.indexOf(
                      '/',
                      window.location.href.indexOf('/') + 2
                    )
                  )}/group/invite/${groupLink}`}
                </IconTextButton>
              )
            }
            onChange={onLinkSwitch}
          ></Switch>
        </>
      ) : (
        <>
          {(props.groupRoomCreateEditCrudState === CrudState.Create ||
            props.groupRoomCreateEditCrudState === CrudState.CreateNoMember ||
            props.groupRoomCreateEditCrudState === CrudState.UpdateNoMember ||
            (props.groupRoomCreateEditCrudState === CrudState.Update &&
              defaultValues)) && (
            <form
              onSubmit={handleSubmit(onSettingsSubmit)}
              className={styles['group-create-edit-common']}
            >
              <div className={styles['group-create-edit-common-label']}>
                <Input
                  classes={styles['group-create-edit-common-field-half']}
                  label={t('newscenter.groups.create_edit.settings.name.label')}
                  message={errors?.name && errors.name.message}
                  placeholder={t(
                    'newscenter.groups.create_edit.settings.name.placeholder'
                  )}
                  register={register('name')}
                  state={errors?.name && ResultState.Error}
                />
                <div className="flex flex-col w-full">
                  <Checkbox
                    classes={styles['group-create-edit-common-field-check']}
                    content={t(
                      'newscenter.groups.create_edit.settings.message'
                    )}
                    disabled={hasAllreadyMsgs}
                    checked={messageFunction}
                    onToggle={(checked) => {
                      !props.newsCenter && setMessageFunction(checked);
                      checked && setNoIncludeCreator(false);
                    }}
                  />
                  <Checkbox
                    classes={styles['group-create-edit-common-field-check']}
                    content={t(
                      'newscenter.groups.create_edit.settings.no_include_creator'
                    )}
                    disabled={
                      props.groupRoomCreateEditCrudState ===
                        CrudState.UpdateNoMember ||
                      props.groupRoomCreateEditCrudState === CrudState.Update ||
                      messageFunction
                    }
                    checked={noIncludeCreator}
                    onToggle={(checked) => setNoIncludeCreator(checked)}
                  />
                </div>
              </div>
              <Input
                classes={styles['group-create-edit-common-field']}
                label={t(
                  'newscenter.groups.create_edit.settings.description.label'
                )}
                message={errors?.description && errors.description.message}
                multiline={6}
                placeholder={t(
                  'newscenter.groups.create_edit.settings.description.placeholder'
                )}
                register={register('description')}
                state={errors?.description && ResultState.Error}
              />
              {communityOptions.length > 0 && (
                <div className="flex flex-row items-end">
                  <Select
                    disabled={
                      (props.selectedCommunity &&
                        props.selectedCommunity.id !==
                          ContactCommunityIdType.All) ||
                      (props.groupRoomCreateEditCrudState ===
                        CrudState.Update &&
                        selectedRoom?.settings?.community_id)
                        ? true
                        : false
                    }
                    classes={styles['group-create-edit-common-field-half']}
                    label={t(
                      'newscenter.groups.create_edit.settings.community.label'
                    )}
                    optional
                    options={communityOptions}
                    value={
                      props?.selectedGroup?.community_id?.id
                        ? props?.selectedGroup?.community_id?.id
                        : communityId
                        ? communityId
                        : props?.selectedCommunity?.id !==
                          ContactCommunityIdType.All
                        ? props?.selectedCommunity?.id
                        : ''
                    }
                    onChange={(value) => setValue('community_id', value)}
                  />
                  <div
                    className={clsx(
                      styles['group-create-edit-common-field'],
                      'flex flex-col'
                    )}
                  >
                    <FormFieldLabel
                      label={t('newscenter.groups.create_edit.settings.folder')}
                      optional
                    />
                    <Autocomplete
                      sx={{
                        '& .MuiOutlinedInput-root ': {
                          height: '52px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          backgroundColor: 'inherit',
                          borderColor: 'border.app',
                        },
                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                          {
                            borderColor: 'border.app',
                          },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                          {
                            borderColor: 'primary.main',
                          },
                      }}
                      multiple
                      freeSolo
                      disabled={false}
                      options={foldersOptions}
                      getOptionLabel={(folder: Option) => folder.placeholder}
                      value={selectedFolderOptions}
                      onChange={folderOnChange}
                      renderTags={folderRenderTags}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </div>
                </div>
              )}

              {/* 
          {toolOptions.length > 0 && (
            <Select
              classes={styles['group-create-edit-common-field']}
              label={'Hilfsmittel'}
              optional
              options={toolOptions}
              value={toolId}
              onChange={(value) => setValue('tool_id', value)}
            />
          )} */}
              {toolOptions.length > 0 && (
                <Select
                  classes={styles['group-create-edit-common-field']}
                  label={'Hilfsmittel'}
                  optional
                  options={toolOptions}
                  value={toolId}
                  onChange={(value) => setValue('tool_id', value)}
                />
              )}

              <FormFieldLabel
                required={false}
                label={'Einladungslink'}
                sublabel={
                  'Mit diesem Link kannst du Personen direkt in diese Gruppe einladen. Nutze diese Funktion in Verbindung mit den Zugriffsrechten in den Hilfsmitteln'
                }
                paddingClassName={
                  styles['tool-element-date-picker-form-field-label-padding']
                }
              />
              <Switch
                classes={styles['template-publish-type-next']}
                checked={isChecked || false}
                disabled={false}
                children={
                  isChecked && (
                    <IconTextButton
                      sx={{ width: 1 / 2 }}
                      borderStyle="dashed"
                      classes={styles['tool-content-read-edit-text-button']}
                      icon={['fal', 'copy']}
                      padding="0.5rem 0.75rem"
                      preset="tools-primary"
                      onClick={onUrlPaste}
                    >
                      {`${window.location.href.substring(
                        0,
                        window.location.href.indexOf(
                          '/',
                          window.location.href.indexOf('/') + 2
                        )
                      )}/group/invite/${groupLink}`}
                    </IconTextButton>
                  )
                }
                onChange={onLinkSwitch}
              ></Switch>

              <Box className={styles['group-create-edit-common-buttons']}>
                <TextButton
                  disabled={!!Object.keys(errors).length || isButtonDisabled}
                  preset="primary"
                  type="submit"
                >
                  {props.groupRoomCreateEditCrudState === CrudState.Create &&
                    t('newscenter.groups.create_edit.settings.buttons.create')}
                  {props.groupRoomCreateEditCrudState === CrudState.Update &&
                    t('newscenter.groups.create_edit.settings.buttons.edit')}
                  {props.groupRoomCreateEditCrudState ===
                    CrudState.CreateNoMember &&
                    t('contacts.toolbar.community.add_group')}
                  {props.groupRoomCreateEditCrudState ===
                    CrudState.UpdateNoMember &&
                    t('contacts.toolbar.community.edit_group')}
                </TextButton>
              </Box>
            </form>
          )}
        </>
      )}
    </>
  );
};
