import { Box } from '@mui/material';

// Component
import { ImageFallback } from '../ImageFallback/ImageFallback';

// Hooks
import { useBreakpoints } from '../../hooks/use-breakpoints.hook';

// Models
import { ImageFallbackType } from '../../models/shared.types';

// Styles
import styles from './PopoverImagePreviewItem.module.scss';

type PopoverImagePreviewItemProps = {
  overlap?: boolean;
  name: string;
  src?: string;
  type?: ImageFallbackType;
};

export const PopoverImagePreviewItem = (
  props: PopoverImagePreviewItemProps
) => {
  const { xxlDown } = useBreakpoints();

  return (
    <li
      className={
        props.overlap ? styles['popover-image-preview-item-overlap'] : ''
      }
    >
      <Box
        className={styles['popover-image-preview-item-container']}
        sx={{
          '& img ': { backgroundColor: 'background.paper' },
        }}
      >
        {props.src ? (
          <img
            alt={props.name}
            src={props.src}
            className={styles['popover-image-preview-item-image']}
          />
        ) : (
          <Box
            className={styles['popover-image-preview-item-fallback']}
            sx={{ backgroundColor: 'background.paper' }}
          >
            <ImageFallback
              iconSize={xxlDown ? '0.75em' : '1em'}
              roundedClassName={
                styles['popover-image-preview-item-fallback-rounded']
              }
              sizeClassName={
                styles['popover-image-preview-item-fallback-content']
              }
              type={props.type ?? ImageFallbackType.Profile}
            />
          </Box>
        )}
      </Box>
    </li>
  );
};
