import { FormTranslations } from '../models/form.translations.interface';

export const formTranslationsIT: FormTranslations = {
  address: {
    country: {
      options: {
        austria: 'Austria',
        germany: 'Germania',
        switzerland: 'Svizzera',
      },
      placeholder: 'scegli',
      title: 'paese',
    },
    houseNumber: {
      placeholder: 'p.e.',
      title: 'numero civico',
    },
    place: {
      placeholder: 'p.e. cità',
      title: 'posto',
    },
    street: {
      placeholder: 'p.e. strada',
      title: 'srada',
    },
    title: 'Indirizzo',
    zipCode: {
      placeholder: 'p.e. 14467',
      title: 'cap',
    },
  },
  file: {
    types: {
      image: 'Tipi di file sono permessi: png, jpg, jpeg  ',
      size: 'Larghezza: 1920px Altezza: 112px',
    },
  },
  file_drop: {
    placeholder: 'Metti il tuo file qui o scegli un file',
  },
  common: {
    errors: {
      matches: {
        delete: 'cancella',
        response: 'l`immissione non concorda',
        text1: 'batti',
        text2: 'per confermare',
      },
    },
  },
  profile: {
    birthday: {
      placeholder: '_ _._ _._ _ _ _',
      title: 'compleanno',
    },
    email: {
      error: {
        conflict: 'questo email veniva usato gia. prendi un altro per favore',
      },
      placeholder: 'p.e. paul.mueller@mail.de',
      title: 'E-Mail',
      validation: {
        required: 'un email è necessario',
        schema: 'Input non è un email',
      },
    },
    first_name: {
      placeholder: 'p.e. Paul',
      title: 'nome',
    },
    function: {
      placeholder: 'p.e. madre',
      title: 'funzione',
    },
    last_name: {
      placeholder: 'p.e. Mueller',
      title: 'cognome',
    },
    phone: {
      placeholder: 'p.e. 01514638618',
      title: 'numero telefono',
    },
    telephone: {
      placeholder: 'p.e. 01514638618',
      title: 'numero telefono',
    },
  },
};
