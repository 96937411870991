import { Navigate, Route, Routes } from 'react-router-dom';

// Components
import { Market } from '../modules/market/page/Market/Market';

export const MarketRouter = () => {
  return (
    <Routes>
      <Route path="" element={<Market />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
