import { UserTranslations } from '../models/user.translations.interface';

export const userTranslationsDE: UserTranslations = {
  account: {
    notifications: {
      text: 'Demnächst kannst du hier auswählen, wie du deine Informationen erhalten möchtest.',
      title: 'Benachrichtigungen',
      subtitle: `Du entscheidest, worüber dich Pengueen informiert.`,
      subtitle2: `Bitte beachte, dass die Mitarbeiter:Innen deiner Gemeinschaften in wichtigen Fällen deine Wahl z.B. über die (Nicht) Benachrichtigung über neue Nachrichten im Einzelfall überschreiben können.`,
      web: 'Web Benachrichtigungen',
    },
    delete: {
      confirmation: 'Bestätigung',
      now: 'Jetzt dein Konto unwiderruflich löschen?',
      subtitle:
        'Dein Konto wird mit allen verbundenen Daten unwiderruflich gelöscht.',
      title: 'Meinen Account löschen',
    },
    email: {
      text: 'Nutzung für deinen Log-in. E-Mail wird nicht veröffentlicht.',
      change: {
        confirmation: {
          password: 'Aktuelles Passwort',
          text: 'Bestätige mit deinem aktuellen Passwort.',
        },
        text5: 'Deine neue E-Mail-Adresse ist: ',
        text4:
          'Bitte gebe dein Passwort ein, um deine E-Mail-Adresse zu ändern.',
        text3:
          '2. Du erhältst außerdem eine E-Mail an deine neue E-Mail-Adresse. Bitte bestätige durch Auswahl des Links in der E-Mail, dass du im Besitz dieses E-Mail-Kontos bist.',
        text2:
          '1. Zu deiner Sicherheit verschicken wir eine E-Mail an deine bisherige E-Mail-Adresse. Diese dient zu deiner Information.',
        text1:
          'Um deine E-Mail-Adresse zu ändern, erhältst du zwei E-Mails von uns.',
        subtitle:
          'Dein Konto ist dann nur noch über die neue E-Mail-Adresse erreichbar.',
        title: 'Bestätige die Änderung deiner E-Mail-Adresse',
      },
    },
    language: {
      lng: {
        de: 'Deutsch',
        en: 'Englisch',
        it: 'Italienisch',
      },
      text: 'Wir arbeiten daran, dir weitere Sprachen zur Verfügung zu stellen.',
      title: 'Sprache',
      validation: 'Es muss eine Sprache angegeben werden.',
    },
    save: {
      request: 'Eine Bestätigungs-E-Mail wurde gesendet.',
      success: 'Kontoänderungen erfolgreich übernommen.',
    },
    subtitle: 'Sprache und Benachrichtigungen',
    text: 'Einstellungen für dein Konto. Ändere deine Kontoinformationen wie Sprache oder Kommunikationswege.',
    title: 'Kontoinformation',
  },
  community: {
    data: {
      form: {
        logo: 'Logo',
        name: {
          placeholder: 'z.B. Gemeinschaftsname',
          title: 'Bezeichnung',
        },
        sector: 'Branche',
      },
      save: {
        success: 'Gemeinschaftsänderungen erfolgreich übernommen.',
      },
      subtitle: 'Gebe hier Informationen zu deiner Gemeinschaft an.',
    },
    notification: {
      form: {
        case: 'Wenn',
        user: 'Nutzer',
        invite_users: 'Einladung neuer Nutzer',
        invitation_accepted: 'Einladung angenommen',
        registration_link: 'Registrierung über Link',
        template: 'Hilfsmittel-Vorlagen',
        template_changed: 'Änderung an Hilfsmittel-Vorlagen',
        inform: 'Informiere',
        receiver: 'Empfänger',
        community_owner: 'Inhaber der Gemeinschaft',
        admin: 'Administratoren',
        employees: 'Mitarbeiter:Innen',
        following_receiver: 'Folgende Empfänger',
        submit: 'Einstellungen übernehmen',
      },
      save: {
        success: 'ÄNDERUNGEN ANSEHEN',
      },
      subtitle:
        'Entscheide, über was du und die Administratoren deiner Gemeinschaft benachrichtigt werdensollen. Ergänzende Einstellungen findest du in den Hilfsmittel-Vorlagen.',
      title: 'Benachrichtigungen',
    },
    license: {
      form: {
        address: 'Rechnungsadresse',
        legal: {
          company: {
            placeholder: 'z.B. Schmidt GmbH',
            title: 'Firmierung',
          },
          title: 'Rechtlich',
          vat_id: {
            placeholder: 'z.B. 079 / 123 / 12347',
            title: 'USt-ID.',
          },
        },
        contact: {
          email: 'Abweichende E-Mail-Adresse',
          title: 'Ansprechpartner:in',
        },
      },
      save: {
        success: 'Lizenzänderungen erfolgreich übernommen',
      },
      subtitle:
        'Bei Änderungswünschen, Fragen oder Wunsch zur Kündigung melde dich bitte bei uns unter hilfe@pengueen.de',
      title: 'Lizenz- und Zahlungsangaben',
    },
    license_summary: {
      bill: {
        button_summary: 'Zur Rechnungsübersicht',
        date: 'Nächstes Rechnungsdatum',
        title: 'Hier findest du deine Rechnungsübersicht',
      },
      cost: {
        text: 'pro Lizenz, pro Monat',
        title: 'Deine nächste Rechnung:',
      },
      number: {
        button_manage: 'Lizenzen verwalten',
        manage: {
          licenses: 'Einzel-Lizenzen',
          submit: {
            button: 'Lizenzen aktualisieren',
            error:
              'Es ist ein Fehler beim Aktualisieren der Lizenzen aufgetreten',
            success: 'Lizenzen erfolgreich aktualisiert',
          },
          subtitle:
            'Füge hier neue Lizenzen hinzu oder entferne Lizenzen aus deinem Plan.',
          summary: {
            text1: ' á {{cost}} € netto.',
            text2: 'Team-Lizenzen',
            text3: 'Rechnungsbetrag, netto',
            text4: 'MwSt.',
            text5: 'Rechnungsbetrag, brutto',
            text6: 'Nächstes Rechnungsdatum: {{date}}',

            title: 'Zusammenfassung',
          },
          text1:
            'Du kannst die Anzahl der Lizenzen einfach erhöhen oder reduzieren.',
          text2:
            'Du erhältst deine nächste Rechnung über die Lizenzen zum nächsten Stichtag am: {{date}}.',
          title: 'Verwalte deine Lizenzen',
        },
        text: 'Lizenz(en) in deinem Abonnement zum Stichtag',
        title: 'Aktuelle Lizenzen',
        tooltip:
          'Der Stichtag ist immer der 20. eines Monats. Die zu diesem Tag aktiven Lizenzen bilden die Grundlage zur Rechnungsstellung',
      },
      subtitle:
        'Hier findest du eine Übersicht über deine abgeschlossenen Lizenzen.',
      title: 'Lizenzübersicht',
      total_gross: 'Gesamtsumme brutto',
      total_net: 'Gesamtsumme netto',
      vat: 'MwSt.',
    },
    subtitle: 'Informationen zu deiner Gemeinschaft',
    title: 'Meine Gemeinschaft',
  },
  documents: {
    filters: {
      sort: {
        date: 'Datum',
        name: 'Bezeichnung',
      },
    },
    subtitle: 'Dokumentenübersicht',
    text: 'Ablageort für die wichtigen Dokumente, die du von uns - deinem Pengueen Team - erhältst.',
    title: 'Wichtige Dokumente',
  },
  logout: 'Abmelden',
  profile: {
    form: {
      address: 'Adresse',
      availability: {
        title: 'Erreichbarkeit',
        verify: {
          textUnverified: 'Du erhältst keine Benachrichtigung per SMS!',
          textVerified1: 'Du kannst nun auch unter',
          textVerified2: 'die sichere Anmeldung mit SMS-TAN aktivieren.',
          title: 'Verifiziere deine Handynummer',
        },
      },
      avatar: 'Profilbild',
      contact: 'Kontaktdaten',
    },
    save: {
      success: 'Profiländerungen erfolgreich übernommen.',
    },
    subtitle: 'Kontoeinstellungen und mehr',
    text: 'Die hier angegebenen Daten werden Personen und Gemeinschaften angezeigt, mit denen du dich verbunden hast.',
    title: 'Persönliche Informationen',
  },
  security: {
    word: {
      title: ' Dein Sicherheits-Satz',
      description: 'kann Klartext sein, wir wollen ja nicht übertreiben.',
      subtitle:
        'Du findest diesen Satz in den E-Mails, die wir dir schicken. So kannst du sicher sein, dass wir der Absender dieser E-Mails sind. ',
      notification: 'Dein Sicherheits-Satz wurde gespeichert.',
    },
    change: {
      error: {
        bad: 'Falsches Passwort eingegeben.',
        conflict: 'Es muss ein neues Passwort gewählt werden.',
        forbidden: 'Passwort ändern nicht erlaut.',
      },
      passwordCurrent: 'Aktuelles Passwort',
      success: 'Passwort erfolgreich geändert!',
      title: 'Passwort ändern',
    },
    subtitle: 'Passwort ändern',
    tan: {
      activate: {
        form: {
          buttonSubmit: 'Sichere Anmeldung mit SMS-TAN aktivieren',
          buttonRequest: 'TAN anfordern',
          error: {
            incorrect: 'Die von dir eingegebene TAN ist inkorrekt.',
            length: 'Die TAN muss exakt 5 Zeichen lang sein',
          },
          input: 'TAN eingeben',
          missing: 'Du hast die TAN nicht erhalten?',
          resend: 'Erneut zusenden',
        },
        subtitle:
          'Fordere eine TAN an und wir senden dir eine SMS mit einer Bestätigungs-TAN an {*********211}.',
        title: 'Aktivieren der sicheren Anmeldung mit SMS-TAN',
      },
      deactivate: {
        submit: 'Deaktivieren',
        subtitle: 'Deine sichere Anmeldung mit SMS-TAN wird ausgeschaltet.',
        text: `Wenn du die sichere Anmeldung mit SMS-TAN deaktivierst, benötigst du nicht länger eine Bestätigungs-TAN per SMS,
          um dich in deinem Konto anzumelden. Wir empfehlen die Deaktivierung dieser Funktion nicht, da dies die Sicherheit deines Kontos verringert.`,
        title: 'Deaktivieren der sicheren Anmeldung mit SMS-TAN',
      },
      text1:
        'Um die sichere Anmeldung mit SMS-TAN verwenden zu können, musst du deine Handynummer unter',
      text2: 'verifizieren.',
      textActive: `Jedes Mal, wenn du dich in deinem Konto anmelden möchtest, benötigst du dein Passwort und eine Bestätigungs-TAN, die wir dir auf deine Handynummer,
      die du für Benachrichtigungen eingestellt hast, zusenden.`,
      title: 'Sichere Anmeldung mit SMS-TAN',
    },
    text: 'Einstellungen für deine Kontosicherheit. Ändere deine Kontoinformationen wie Passwort oder verknüpfte E-Mail Adresse.',
    title: 'Sicherheit',
  },
  theme: {
    dark: 'Dunkel',
    light: 'Hell',
    subtitle:
      'Stelle ein, ob du Pengueen in einem hellen oder dunklen Erscheinungsbild verwenden möchtest.',
    title: 'Erscheinungsbild',
  },
  title: 'Nutzerprofil',
};
