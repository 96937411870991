import { FormTranslations } from '../models/form.translations.interface';

export const formTranslationsEN: FormTranslations = {
  address: {
    country: {
      options: {
        austria: 'Austria',
        germany: 'Germany',
        switzerland: 'Switzerland',
      },
      placeholder: 'Select',
      title: 'Country',
    },
    houseNumber: {
      placeholder: 'e.g. 12a',
      title: 'Hausnummer',
    },
    place: {
      placeholder: 'e.g. Town',
      title: 'Place',
    },
    street: {
      placeholder: 'e.g. Street',
      title: 'Street',
    },
    title: 'Address',
    zipCode: {
      placeholder: 'e.g. 14467',
      title: 'Zip code',
    },
  },
  file: {
    types: {
      image: 'Allowed file types: png, jpg, jpeg  ',
      size: 'Width: 1920px Height: 112px',
    },
  },
  file_drop: {
    placeholder: 'Lege deine Datei hier ab oder wähle eine Datei aus',
  },
  common: {
    errors: {
      matches: {
        delete: 'DELETE',
        response: 'Eingabe stimmt nicht überein.',
        text1: 'Tippe',
        text2: 'um zu bestätigen',
      },
    },
  },
  profile: {
    birthday: {
      placeholder: '_ _._ _._ _ _ _',
      title: 'Birthday',
    },
    email: {
      error: {
        conflict:
          'Diese E-Mail wird bereits benutzt. Bitte nutze eine andere E-Mail.',
      },
      placeholder: 'e.g. paul.mueller@mail.de',
      title: 'E-Mail',
      validation: {
        required: 'An e-mail is required.',
        schema: 'Input is not an e-mail.',
      },
    },
    first_name: {
      placeholder: 'e.g. Paul',
      title: 'First name',
    },
    function: {
      placeholder: 'e.g. Mother',
      title: 'Function',
    },
    last_name: {
      placeholder: 'e.g. Mueller',
      title: 'Last name',
    },
    phone: {
      placeholder: 'e.g. 01514638618',
      title: 'Phone number',
    },
    telephone: {
      placeholder: 'e.g. 01514638618',
      title: 'Telephone number',
    },
  },
};
