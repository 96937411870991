import { ReactNode } from 'react';
import { TFunctionResult } from 'i18next';
import { Box } from '@mui/material';
import clsx from 'clsx';

// Hooks
import { useBreakpoints } from '../../hooks/use-breakpoints.hook';

// Styles
import styles from './H3.module.scss';

type H3Props = {
  children: TFunctionResult | string | ReactNode;
  classes?: string;
  color?: string;
  marginBottom?: string;
};

export const H3 = (props: H3Props) => {
  const { lgDown } = useBreakpoints();

  return (
    <Box
      component="h3"
      sx={{
        color: props.color ?? 'text.primary',
        marginBottom: props.marginBottom
          ? props.marginBottom
          : lgDown
          ? '0.25rem'
          : '0.5rem',
      }}
      className={clsx(styles['h3'], props.classes && props.classes)}
    >
      {props.children}
    </Box>
  );
};
