import { memo, useCallback, useState } from 'react';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { Box, Button } from '@mui/material';
import clsx from 'clsx';

// Components
import { Icon } from '../Icon/Icon';

// Styles
import styles from './IconSwitchButton.module.scss';

type IconSwitchButtonProps = {
  checked: boolean;
  classes?: string;
  iconOff: [IconPrefix, IconName];
  iconOn: [IconPrefix, IconName];
  sameColor?: boolean;
  onChange: (checked: boolean) => void;
};

const IconSwitchButton = (props: IconSwitchButtonProps) => {
  const [checked, setChecked] = useState<boolean>(props.checked ?? false);

  /**
   * Handler to toggle switch button.
   */
  const onToggle = useCallback(() => {
    props.onChange(!checked);
    setChecked(!checked);
  }, [checked, props]);

  return (
    <Button
      className={clsx(
        styles['icon-switch-button'],
        props.classes && props.classes
      )}
      color="inherit"
      sx={{ borderColor: 'border.app' }}
      onClick={onToggle}
    >
      <Box
        className={styles['icon-switch-button-icon']}
        sx={{
          backgroundColor: !props.checked
            ? props.sameColor
              ? 'primary.main'
              : 'pengueen.gray'
            : undefined,
        }}
      >
        <Icon
          icon={props.iconOff}
          sx={{
            color: !props.checked ? 'white' : 'text.secondary',
          }}
        />
      </Box>
      <Box
        className={styles['icon-switch-button-icon']}
        sx={{
          backgroundColor: props.checked ? 'primary.main' : undefined,
        }}
      >
        <Icon
          icon={props.iconOn}
          sx={{
            color: props.checked ? 'white' : 'text.secondary',
          }}
        />
      </Box>
    </Button>
  );
};

export default memo(IconSwitchButton);
