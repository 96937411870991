import { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// Components
import { Loader } from '../shared/ui/Loader/Loader';

// Stores
import { useSharedStore } from '../shared/stores/use-shared.store';

// Lazy-load pages
const Account = lazy(() => import('../modules/user/pages/Account/Account'));
const Documents = lazy(
  () => import('../modules/user/pages/Documents/Documents')
);
const Profile = lazy(() => import('../modules/user/pages/Profile/Profile'));
const Security = lazy(() => import('../modules/user/pages/Security/Security'));

// Lazy-load pages
const Community = lazy(
  () => import('../modules/user/pages/Community/Community')
);

export const UserRouter = () => {
  // Shared store state
  const [setUserRouter] = useSharedStore((state) => [state.setUserRouter]);

  // Change Tooltip color in Header on mounted
  useEffect(() => {
    setUserRouter(true);
    return () => {
      setUserRouter(false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Routes>
      <Route
        path="/account"
        element={
          <Suspense fallback={<Loader />}>
            <Account />
          </Suspense>
        }
      />
      <Route
        path="/community"
        element={
          <Suspense fallback={<Loader />}>
            <Community />
          </Suspense>
        }
      />
      <Route
        path="/community/:id"
        element={
          <Suspense fallback={<Loader />}>
            <Community />
          </Suspense>
        }
      />
      <Route
        path="/documents"
        element={
          <Suspense fallback={<Loader />}>
            <Documents />
          </Suspense>
        }
      />
      <Route
        path="/profile"
        element={
          <Suspense fallback={<Loader />}>
            <Profile />
          </Suspense>
        }
      />
      <Route
        path="/security"
        element={
          <Suspense fallback={<Loader />}>
            <Security />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
