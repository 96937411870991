import { aboutTranslationsIT } from './it/about.translations.it';
import { appTranslationsIT } from './it/app.translations.it';
import { authTranslationsIT } from './it/auth.translations.it';
import { communityTranslationsIT } from './it/community.translations.it';
import { ContactsTranslationsIT } from './it/contacts.translations.it';
import { dashboardTranslationsIT } from './it/dashboard.translations.it';
import { formTranslationsIT } from './it/form.translations.it';
import { godparentschatTranslationsIT } from './it/godparentschat.translations.it';
import { headerTranslationsIT } from './it/header.translations.it';
import { marketTranslationsIT } from './it/market.translations.it';
import { newscenterTranslationsIT } from './it/newscenter.translations.it';
import { templatesTranslationsIT } from './it/templates.translations.it';
import { toolsTranslationsIT } from './it/tools.translations.it';
import { userTranslationsIT } from './it/user.translations.it';

export const translationsIt = {
  about: aboutTranslationsIT,
  app: appTranslationsIT,
  auth: authTranslationsIT,
  community: communityTranslationsIT,
  contacts: ContactsTranslationsIT,
  dashboard: dashboardTranslationsIT,
  form: formTranslationsIT,
  godparentschat: godparentschatTranslationsIT,
  header: headerTranslationsIT,
  market: marketTranslationsIT,
  newscenter: newscenterTranslationsIT,
  templates: templatesTranslationsIT,
  tools: toolsTranslationsIT,
  user: userTranslationsIT,
};
