// Hooks
import { useFetch } from '../../../shared/hooks/use-fetch.hook';

// Models
import { AttachmentFile, Option } from '../../../shared/models/shared.types';

// Models
import {
  Tool,
  ToolMultiElementRowPostRequest,
  ToolValuesSection,
  ToolValuesPatchRequest,
  ToolMultiElementRowPatchRequest,
  ToolSectionElementRowIds,
  ToolElementDocumentPostRequest,
  ToolElementDocumentPostRequestAll,
  ToolUsersPatchRequest,
  ToolUsersPatchResponse,
  ToolUserPermissionPatchRequestResponse,
  ToolPrivateEditReadLink,
  ToolLinkRemoveRequestObj,
  ToolLinkGetRequestObj,
  ToolLinkPatchRequestObj,
  ToolSettingsPostRequest,
  ToolVoteSubmitResponse,
  ToolMultiElementVoteGetRequest,
  ToolElementImageDeleteRequest,
  ToolElementDisplayRequest,
  ToolPermissionSlotUsersPatchRequest,
  ToolPermissionSlotUsersPatchResponse,
  AutoSaveToolRequest,
  UpdateContactRequest,
  GetToolChatIdResponse,
  PostToolChatRequest,
  PostToolChatResponse,
  GetToolChatIdRequest,
  CreateRowRequest,
  SummaryValue,
  ToolAccordionExpansionState,
  ToolAccordionExpansionGetRequest,
  ToolAccordionExpansionStatePost,
  ToolElementMultiDocumentPostRequest,
} from '../models/tools.types';

export const useToolsHttp = () => {
  const { fetchData, uploadData } = useFetch();

  /**
   * GET Tool by id.
   * Example mock data: toolGet()
   * @param id Tool id
   * @returns Tool
   */
  const toolGet = async (id: string): Promise<Tool | undefined> => {
    return await fetchData(`tools/${id}`, {
      method: 'GET',
    });
  };

  /**
   * DELETE Tool by id.
   * Component: ToolContent
   * @param id Tool id
   * @returns Tool id
   */
  const toolDelete = async (id: string): Promise<string | undefined> => {
    return await fetchData(`tools/${id}`, {
      method: 'DELETE',
    });
  };

  /**
   * DELETE Tool by id.
   * Component: ToolContent
   * @param id Tool id
   * @returns Tool id
   */
  const avatarDelete = async (id: string): Promise<string | undefined> => {
    return await fetchData(`tools/${id}/avatar`, {
      method: 'DELETE',
    });
  };

  /**
   * POST Element document.
   * @param file File document
   * @returns AvatarData
   */
  const toolElementDocumentPost = async (
    data: ToolElementDocumentPostRequestAll
  ): Promise<AttachmentFile | undefined> => {
    const formData = new FormData();
    formData.append('file', data.file);
    return await uploadData(
      `tools/${data.body.toolId}/values/sections/${data.body.sectionId}/elements/${data.body.element.id}/documents`,
      {
        method: 'POST',
        formData,
      }
    );
  };

  /**
   * POST Multi element child document.
   * @param file File document
   * @returns AvatarData
   */
  const toolMultiElementChildDocumentPost = async (
    data: ToolElementDocumentPostRequest
  ): Promise<AttachmentFile | undefined> => {
    const formData = new FormData();
    formData.append('file', data.file);
    return await uploadData(
      `tools/${data.body.toolId}/values/sections/${data.body.sectionId}/elements/${data.body.elementId}/rows/${data.body.rowId}/documents/${data.body.rowChildId}`,
      {
        method: 'POST',
        formData,
      }
    );
  };

  const toolMultiElementChildMultiDocumentPost = async (
    data: ToolElementMultiDocumentPostRequest
  ): Promise<AttachmentFile | undefined> => {
    const formData = new FormData();

    for (let i = 0; i < data.files.length; i++) {
      formData.append('files', data.files[i].file);
    }
    return await uploadData(
      `tools/${data.body.toolId}/values/sections/${data.body.sectionId}/elements/${data.body.elementId}/rows/${data.body.rowId}/documents/${data.body.rowChildId}`,
      {
        method: 'POST',
        formData,
      }
    );
  };

  /**
   * POST Tool multi element row values by ids and body.
   * @param data ToolMultiElementRowPostRequest
   * @returns ToolMultiElementRowPostPatchResponse
   */
  const toolMultiElementRowPost = async (
    data: CreateRowRequest
  ): Promise<CreateRowRequest | undefined> => {
    return await fetchData(`tools/values/sections/elements/rows`, {
      method: 'POST',
      body: data,
    });
  };

  /**
   * PATCH Tool multi element row values by ids and body.
   * @param data ToolMultiElementRowPatchRequest
   * @returns ToolMultiElementRowPostPatchResponse
   */
  const toolMultiElementRowPatch = async (
    data: CreateRowRequest
  ): Promise<CreateRowRequest | undefined> => {
    return await fetchData(
      `tools/values/sections/elements/rows/${data.row_id}`,
      {
        method: 'PATCH',
        body: data,
      }
    );
  };

  /**
   * DELETE Tool multi element row by ids.
   * @param data ToolSectionElementRowIds
   * @returns ToolSectionElementRowIds
   */
  const toolMultiElementRowDelete = async (
    data: ToolSectionElementRowIds
  ): Promise<ToolSectionElementRowIds | undefined> => {
    return await fetchData(
      `tools/${data.toolId}/values/sections/${data.sectionId}/elements/${data.elementId}/rows/${data.rowId}`,
      {
        method: 'DELETE',
      }
    );
  };

  /**
   * GET Tool preview by preview id.
   * Component: ToolPreview
   * Example mock data: toolGet()
   * @param id Tool preview id
   * @returns Tool
   */
  const toolPreviewGet = async (id: string): Promise<Tool | undefined> => {
    return await fetchData(`tools/preview/${id}`, {
      method: 'GET',
    });
  };

  /**
   * POST Tool preview use by preview id.
   * Component: Dashboard
   * @param id Tool preview id
   * @returns Tool
   */
  const toolPreviewUsePost = async (id: string): Promise<Tool | undefined> => {
    return await fetchData(`tools/preview/${id}/use`, {
      method: 'POST',
    });
  };

  /**
   * PATCH Tool values by id and body.
   * @param data Tool id and values.
   * @returns
   */
  const toolValuesPatch = async (data: {
    id: string;
    body: ToolValuesPatchRequest;
  }): Promise<ToolValuesSection | undefined> => {
    return await fetchData(`tools/${data.id}/values/`, {
      method: 'PATCH',
      body: data.body,
    });
  };

  /**
   * PATCH Tool users room by tool id and user ids (add).
   * @param body ToolUsersPatchRequest
   * @returns ToolUsersPatchResponse
   */
  const usersPatch = async (
    body: ToolUsersPatchRequest
  ): Promise<ToolUsersPatchResponse | undefined> => {
    return await fetchData(`tools/${body.toolId}/users`, {
      method: 'PATCH',
      body,
    });
  };

  /**
   * PATCH Tool users room by tool id and user ids (add).
   * @param body ToolUsersPatchRequest
   * @returns ToolUsersPatchResponse
   */
  const permissionSlotsUsersPatch = async (
    body: ToolPermissionSlotUsersPatchRequest
  ): Promise<ToolPermissionSlotUsersPatchResponse | undefined> => {
    return await fetchData(
      `tools/${body.toolId}/permission_slot/${body.permission_slot}/users`,
      {
        method: 'POST',
        body: { users: body.users, groups: body.selectedGroups },
      }
    );
  };

  /**
   * PATCH Tool user permission by ids and body data.
   * @param data ToolUserPermissionPatchRequestResponse
   * @returns ToolUserPermissionPatchRequestResponse
   */
  const userPermissionPatch = async (
    data: ToolUserPermissionPatchRequestResponse
  ): Promise<ToolUserPermissionPatchRequestResponse | undefined> => {
    return await fetchData(
      `tools/${data.toolId}/users/${data.userId}/permission`,
      {
        method: 'PATCH',
        body: {
          permission: data.permission,
        },
      }
    );
  };

  /**
   * DELETE User from tool by ids.
   * @param toolId Tool id
   * @param userId User id
   * @returns User id
   */
  const userRemove = async (
    toolId: string,
    userId: string
  ): Promise<string | undefined> => {
    return await fetchData(`tools/${toolId}/users/${userId}`, {
      method: 'DELETE',
    });
  };

  /**
   * DELETE Link from tool by id.
   * @param toolId Tool id
   * @param linkId Link id
   * @returns Link id
   */
  const linkRemove = async (
    data: ToolLinkRemoveRequestObj
  ): Promise<ToolLinkRemoveRequestObj | undefined> => {
    return await fetchData(`tools/${data.toolId}/links/${data.linkId}`, {
      method: 'DELETE',
    });
  };

  /**
   * POST new tool link read/edit
   * Component: Dashboard
   * @param toolId Tool preview id
   * @param permission ToolPermission
   * @returns ToolPrivateEditReadLink
   */
  const toolAddLinkPost = async (
    data: ToolLinkGetRequestObj
  ): Promise<ToolPrivateEditReadLink | undefined> => {
    return await fetchData(`tools/${data.toolId}/links`, {
      method: 'POST',
      body: {
        permission: data.permission,
      },
    });
  };

  /**
   * PATCH tool link read/edit
   * Component: Dashboard
   * @param toolId Tool id
   * @param linkId Link id
   * @returns ToolPrivateEditReadLink
   */
  const toolUpdateLinkPatch = async (
    data: ToolLinkPatchRequestObj
  ): Promise<ToolPrivateEditReadLink | undefined> => {
    return await fetchData(`tools/${data.toolId}/links/${data.linkId}`, {
      method: 'PATCH',
      body: {
        description: data.description,
      },
    });
  };

  /**
   * GET Tool private link by tool id.
   * @param id Tool id
   * @returns ToolPrivateEditReadLink[]
   */
  const toolPrivateLinksEditReadGet = async (
    data: ToolLinkGetRequestObj
  ): Promise<ToolPrivateEditReadLink[] | undefined> => {
    return await fetchData(`tools/${data.toolId}/links/${data.permission}`, {
      method: 'GET',
    });
  };

  /**
   * PATCH Tool settings.
   * @param id Tool id
   * @returns Tool
   */
  const toolSettingsPost = async (
    data: ToolSettingsPostRequest
  ): Promise<Tool | undefined> => {
    const formData = new FormData();

    formData.append('file', data.file ?? '');
    formData.append(
      'body',
      JSON.stringify({
        name: data.name ?? '',
        public: data.public ?? false,
        connected_user: data.contact ?? null,
      })
    );
    return await uploadData(`tools/${data.id}/settings`, {
      method: 'PATCH',
      formData,
    });
  };

  const toolVoteSubmitMultiRow = async (
    data: ToolMultiElementRowPatchRequest
  ): Promise<ToolVoteSubmitResponse | undefined> => {
    return await fetchData(
      `tools/${data.id}/values/sections/${data.section_id}/elements/${data.element_id}/rows/${data.row_id}/vote`,
      {
        method: 'POST',
        body: data.body,
      }
    );
  };

  const toolVoteSubmit = async (
    data: ToolMultiElementRowPostRequest
  ): Promise<ToolVoteSubmitResponse | undefined> => {
    return await fetchData(
      `tools/${data.id}/values/sections/${data.section_id}/elements/${data.element_id}/vote`,
      {
        method: 'POST',
        body: data.body,
      }
    );
  };

  const voteSummaryGet = async (
    data: ToolMultiElementVoteGetRequest
  ): Promise<SummaryValue[]> => {
    return await fetchData(
      `tools/${data.id}/values/sections/${data.section_id}/elements/${data.element_id}/vote/summary`,
      {
        method: 'POST',
        body: data.body,
      }
    );
  };

  /**
   * DELETE Tool element Image.
   * @param id image file id
   * @returns id image file id
   */
  const toolElementImageDelete = async (
    data: ToolElementImageDeleteRequest
  ): Promise<any> => {
    return await fetchData(`tools/element/image/delete/${data.id}`, {
      method: 'DELETE',
    });
  };

  /**
   * GET Tool element display.
   * @param id element id
   * @returns
   */
  const toolMultiElmentDisplayGet = async (
    data: ToolElementDisplayRequest
  ): Promise<ToolElementDisplayRequest | undefined> => {
    return await fetchData(`tools/element/display/${data.id}`, {
      method: 'GET',
    });
  };

  /**
   * GET Tool element display.
   * @param id element id
   * @returns
   */
  const toolMultiElmentDisplaySet = async (
    data: ToolElementDisplayRequest
  ): Promise<ToolElementDisplayRequest | undefined> => {
    return await fetchData(`tools/element/display/${data.id}`, {
      method: 'POST',
      body: data,
    });
  };

  /**
   * POST Auto Save Tool
   * @param id tool id
   * @returns
   */
  const autoSaveTool = async (
    data: AutoSaveToolRequest
  ): Promise<AutoSaveToolRequest | undefined> => {
    return await fetchData(`tools/autosave/${data.id}`, {
      method: 'POST',
      body: data,
    });
  };

  /**
   * PATCH
   * @param
   * @returns
   */
  const toolElementContactUpdate = async (
    data: UpdateContactRequest
  ): Promise<any | undefined> => {
    return await fetchData(`tools/update/contact`, {
      method: 'PATCH',
      body: data,
    });
  };

  /**
   * GET
   * @param
   * @returns
   */
  const getChatRoomIdMutiTable = async (
    data: any
  ): Promise<any | undefined> => {
    return await fetchData(
      `tools/${data.tool_id}/section/${data.section_id}/multitable/${data.multi_id}/chat/${data.row_id}`,
      {
        method: 'GET',
      }
    );
  };

  /**
   * GET
   * @param
   * @returns
   */
  const getToolChatId = async (
    data: GetToolChatIdRequest
  ): Promise<GetToolChatIdResponse | undefined> => {
    return await fetchData(`tools/${data.tool_id}/chat`, {
      method: 'POST',
      body: data,
    });
  };

  /**
   * POST
   * @param
   * @returns
   */
  const postToolChat = async (
    data: PostToolChatRequest
  ): Promise<PostToolChatResponse | undefined> => {
    return await fetchData(`tools/${data.tool_id}/chat/create`, {
      method: 'POST',
      body: data,
    });
  };

  /**
   * DELETE
   * @param
   * @returns
   */
  const removeToolChat = async (
    data: GetToolChatIdRequest
  ): Promise<PostToolChatRequest | undefined> => {
    return await fetchData(`tools/${data.tool_id}/chat`, {
      method: 'DELETE',
    });
  };

  /**
   * DELETE
   * @param
   * @returns
   */
  const toolElementContactDelete = async (
    data: UpdateContactRequest
  ): Promise<any | undefined> => {
    return await fetchData(`tools/delete/contact`, {
      method: 'DELETE',
      body: data,
    });
  };

  /**
   * @param
   * @returns
   */
  const getGroupConnectedContact = async (
    data: any
  ): Promise<Option[] | undefined> => {
    return await fetchData(
      `tools/${data.tool_id}/group/contact/${data.contact_id}`,
      {
        method: 'GET',
      }
    );
  };

  const getToolGroupConnectedContact = async (
    data: any
  ): Promise<Option[] | undefined> => {
    return await fetchData(
      `tools/${data.tool_id}/group/${data.group_id}/tools`,
      {
        method: 'GET',
      }
    );
  };

  const toolAccordionExpansionPost = async (
    data: ToolAccordionExpansionStatePost
  ): Promise<ToolAccordionExpansionState | undefined> => {
    return await fetchData(
      `navigation-state/${data.tool_id}/expansion/${data.is_mobile}`,
      {
        method: 'POST',
        body: data,
      }
    );
  };

  const toolAccordionExpansionGet = async (
    data: ToolAccordionExpansionGetRequest
  ): Promise<ToolAccordionExpansionState | undefined> => {
    return await fetchData(
      `navigation-state/${data.tool_id}/expansion/${data.is_mobile}`,
      {
        method: 'Get',
      }
    );
  };

  return {
    autoSaveTool,
    getChatRoomIdMutiTable,
    getToolChatId,
    postToolChat,
    getGroupConnectedContact,
    toolGet,
    toolDelete,
    toolElementDocumentPost,
    toolElementImageDelete,
    toolMultiElementChildDocumentPost,
    toolMultiElementChildMultiDocumentPost,
    toolMultiElementRowPost,
    toolMultiElementRowPatch,
    toolMultiElementRowDelete,
    toolPreviewGet,
    toolPreviewUsePost,
    toolValuesPatch,
    usersPatch,
    permissionSlotsUsersPatch,
    userPermissionPatch,
    userRemove,
    linkRemove,
    toolAddLinkPost,
    toolUpdateLinkPatch,
    toolPrivateLinksEditReadGet,
    toolSettingsPost,
    avatarDelete,
    toolVoteSubmit,
    toolVoteSubmitMultiRow,
    toolMultiElmentDisplayGet,
    toolMultiElmentDisplaySet,
    toolElementContactUpdate,
    voteSummaryGet,
    toolElementContactDelete,
    removeToolChat,
    getToolGroupConnectedContact,
    toolAccordionExpansionPost,
    toolAccordionExpansionGet,
  };
};
