// Assets
import stock4 from '../../../assets/pictures/stock4.jpg';
import stock5 from '../../../assets/pictures/stock5.jpg';
import stock6 from '../../../assets/pictures/stock6.jpg';
import { CommunityLicenseSummary } from '../../communities/models/communities.types';

// Models
import { UserInfoState, UserLinkChild } from '../models/user.types';

export const useUsersMock = () => {
  /**
   * GET Community license summary mock data.
   * @returns CommunityLicenseSummary
   * @deprecated To be removed
   */
  const communityLicenseSummaryMockGet = (): CommunityLicenseSummary => {
    return {
      cost: 7.9,
      current_license: 2,
      current_team_license: 0,
      payment_date: '2022-04-14T11:00:00',
    };
  };

  /**
   * GET User settings communities mock data.
   * @returns User settings communites
   * @deprecated To be removed.
   */
  const userSettingsCommunitesMockGet = (): UserLinkChild[] => {
    return [
      {
        id: '2ff93993-4f14-46d1-aa58-513c4a457298',
        logo: {
          id: 'logo1',
          url: stock4,
        },
        title: 'Gemeinschaft 1',
        type: UserInfoState.Community,
      },
      {
        id: '4bb9cf9c-6525-4644-afca-26138196acab',
        logo: {
          id: 'logo2',
          url: stock5,
        },
        title: 'Gemeinschaft 2',
        type: UserInfoState.Community,
      },
      {
        id: '7a936459-e777-4cda-ab35-26182daa1e26',
        logo: {
          id: 'logo3',
          url: stock6,
        },
        title: 'Gemeinschaft 3',
        type: UserInfoState.Community,
      },
    ];
  };

  return {
    communityLicenseSummaryMockGet,
    userSettingsCommunitesMockGet,
  };
};
