// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Contacts_contacts__Q08hI {\n  height: 100vh;\n  max-height: 100vh;\n  border-radius: 0px; }\n  .Contacts_contacts-width__xbsRS {\n  width: 100vw; }\n  .Contacts_contacts-padding__leEYi {\n  padding: 0.75rem; }\n  .Contacts_contacts-height-nav__KH-9l {\n  height: calc(100vh - 60px); }\n\n@media (min-width: 1024px) {\n  .Contacts_contacts__Q08hI {\n    height: -webkit-fit-content;\n    height: -moz-fit-content;\n    height: fit-content; }\n  .Contacts_contacts__Q08hI {\n    max-height: calc(100vh - 32px); }\n  .Contacts_contacts__Q08hI {\n    border-radius: 0.375rem; }\n    .Contacts_contacts-width__xbsRS {\n      width: calc(100% - 128px); }\n    .Contacts_contacts-padding__leEYi {\n    padding: 1.5rem; } }\n", "",{"version":3,"sources":["<no source>","webpack://./src/modules/contacts/page/Contacts/Contacts.module.scss"],"names":[],"mappings":"AAAA;EAAA,cAAA;EAAA,kBAAA;EAAA,qBAAA;EAAA;EAAA,eAAA;EAAA;EAAA,mBAAA;EAAA;EAAA,6BAAA;;ACiBA;EDjBA;IAAA,4BAAA;IAAA,yBAAA;IAAA,sBAAA;EAAA;IAAA,iCAAA;EAAA;IAAA,0BAAA;ICoBI;MACE,yBAAyB,EAAA;IDrB/B;IAAA,kBAAA,CCyBK","sourcesContent":[null,"@import '../../../../styles/breakpoints.scss';\n\n.contacts {\n  @apply h-screen max-h-screen rounded-none;\n  &-width {\n    @apply w-screen;\n  }\n  &-padding {\n    @apply p-3;\n  }\n  &-height {\n    &-nav {\n      @apply h-[calc(100vh-60px)];\n    }\n  }\n}\n\n@media (min-width: $lg) {\n  .contacts {\n    @apply h-fit max-h-[calc(100vh-32px)] rounded-md;\n    &-width {\n      width: calc(100% - 128px);\n    }\n    &-padding {\n      @apply p-6;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contacts": "Contacts_contacts__Q08hI",
	"contacts-width": "Contacts_contacts-width__xbsRS",
	"contacts-padding": "Contacts_contacts-padding__leEYi",
	"contacts-height-nav": "Contacts_contacts-height-nav__KH-9l"
};
export default ___CSS_LOADER_EXPORT___;
