import { ReactNode } from 'react';
import { TFunctionResult } from 'i18next';
import { Box } from '@mui/material';
import clsx from 'clsx';

// Hooks
import { useBreakpoints } from '../../hooks/use-breakpoints.hook';

// Styles
import styles from './H2.module.scss';

type H2Props = {
  children: TFunctionResult | string | ReactNode;
  classes?: string;
  color?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginTop?: string;
  marginRight?: string;
};

export const H2 = (props: H2Props) => {
  const { lgDown } = useBreakpoints();

  return (
    <Box
      component="h2"
      sx={{
        color: props.color ?? 'text.primary',
        marginBottom: props.marginBottom
          ? props.marginBottom
          : lgDown
          ? '0.5rem'
          : '1rem',
        marginLeft: props.marginLeft && props.marginLeft,
        marginRight: props.marginRight && props.marginRight,
        marginTop: props.marginTop && props.marginTop,
      }}
      className={clsx(styles['h2'], props.classes)}
    >
      {props.children}
    </Box>
  );
};
