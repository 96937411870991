import { memo, ReactNode } from 'react';
import { Box } from '@mui/material';

// Components
import { Drawer } from '../Drawer/Drawer';
import { Header } from '../Header/Header';
// import { TrialExpired } from '../common/TrialExpired';

// Stores
// import { SharedState, useSharedStore } from '../../store/useSharedStore';

// Styles
import styles from './ProtectedContainer.module.scss';

type ProtectedContainerProps = {
  page: ReactNode;
};

const ProtectedContainer = (props: ProtectedContainerProps) => {
  const currentUrl = window.location.href;

  // Component state
  // const [showTrial, setShowTrial] = useState<boolean>(false);

  // Shared store state
  // const [trialExpired] = useSharedStore((state: SharedState) => [
  //   state.trialExpired,
  // ]);

  // Set overflow hidden
  // useEffect(() => {
  //   if (trialExpired) {
  //     setShowTrial(true);
  //   } else {
  //     setShowTrial(false);
  //   }
  // }, [trialExpired]);

  return (
    <Box className={styles['container-box']}>
      {currentUrl.includes('/printview') ? (
        <>
          <Box className={styles['protected-container']}>{props.page}</Box>
        </>
      ) : (
        <>
          <Header />
          <Drawer />
          <Box className={styles['protected-container']}>{props.page}</Box>
        </>
      )}
    </Box>
  );
};

export default memo(ProtectedContainer);
