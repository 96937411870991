import { useTranslation } from 'react-i18next';
import { GridClasses, GridLocaleText } from '@mui/x-data-grid-pro';

export const useDataGrid = () => {
  const { t } = useTranslation();

  /**
   * Returns data grid custom classes.
   * @returns Data grid custom classes
   */
  const gridClassesGet = (): Partial<GridClasses> | undefined => {
    return {
      columnHeaders: 'data-grid-column-headers',
      footerContainer: 'data-grid-footer-container',
      root: 'data-grid',
      virtualScrollerContent: 'data-grid-virtual-scroller-content',
      overlay: 'data-grid-overlay',
    };
  };

  /**
   * Returns data grid custom sx.
   * @returns  Data grid custom sx
   */
  // const gridSxGet = (cellSelect?: boolean): SxProps<Theme> | undefined => {
  //   return {
  //     '& .MuiDataGrid-cell': {
  //       cursor: cellSelect ? 'pointer' : 'auto',
  //       borderColor: 'border.light',
  //     },
  //     '& .MuiDataGrid-columnHeaders': {
  //       backgroundColor: 'bg.card',
  //     },
  //     //   '& .MuiDataGrid-footerContainer': {
  //     //     borderColor: 'border.app',
  //     //   },
  //     '& .MuiDataGrid-iconSeparator': {
  //       display: 'none',
  //     },
  //     '& .MuiDataGrid-menuIcon': {
  //       display: 'none',
  //     },
  //     '& .MuiDataGrid-virtualScrollerContent': {
  //       borderColor: 'border.light',
  //     },
  //   };
  // };

  /**
   * Returns data grid custom translations.
   * https://github.com/mui/mui-x/blob/HEAD/packages/grid/x-data-grid/src/constants/localeTextConstants.ts
   * @returns Data grid custom translations.
   */
  const localeTextGet = (): Partial<GridLocaleText> | undefined => {
    return {
      // footerTotalRows: 'TOTAL',
      footerRowSelected: (count) =>
        count !== 1
          ? `${count.toLocaleString()} ${t(
              'app.data_grid.rows_selected.multi'
            )}`
          : `${count.toLocaleString()} ${t(
              'app.data_grid.rows_selected.single'
            )}`,
      footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} ${t(
          'app.of'
        )} ${totalCount.toLocaleString()}`,
      filterOperatorContains: t('tools.filters.filterOperatorContains'),
      filterOperatorEquals: t('tools.filters.filterOperatorEquals'),
      filterOperatorStartsWith: t('tools.filters.filterOperatorStartsWith'),
      filterOperatorEndsWith: t('tools.filters.filterOperatorEndsWith'),
      filterOperatorIsEmpty: t('tools.filters.filterOperatorIsEmpty'),
      filterOperatorIsNotEmpty: t('tools.filters.filterOperatorIsNotEmpty'),
      filterOperatorIsAnyOf: t('tools.filters.filterOperatorIsAnyOf'),
      filterOperatorIs: t('tools.filters.filterOperatorIs'),
      filterOperatorNot: t('tools.filters.filterOperatorNot'),
      filterOperatorAfter: t('tools.filters.filterOperatorAfter'),
      filterOperatorOnOrAfter: t('tools.filters.filterOperatorOnOrAfter'),
      filterOperatorBefore: t('tools.filters.filterOperatorBefore'),
      filterOperatorOnOrBefore: t('tools.filters.filterOperatorOnOrBefore'),
      filterValueAny: t('tools.filters.filterValueAny'),
      filterValueFalse: t('tools.filters.filterValueFalse'),
      filterValueTrue: t('tools.filters.filterValueTrue'),
      filterPanelOperatorOr: t('tools.filters.filterPanelOperatorOr'),
      filterPanelOperatorAnd: t('tools.filters.filterPanelOperatorAnd'),
      toolbarColumns: '',
      toolbarDensity: '',
      toolbarFilters: '',
      noResultsOverlayLabel: t('app.no_results'),
      filterPanelAddFilter: t('tools.filters.filterPanelAddFilter'),
      filterPanelColumns: t('tools.filters.filterPanelColumns'),
      filterPanelInputLabel: t('tools.filters.filterPanelInputLabel'),
      filterPanelInputPlaceholder: t(
        'tools.filters.filterPanelInputPlaceholder'
      ),
      toolbarExport: '',
      toolbarExportCSV: t('app.download_as_csv'),
      toolbarExportPrint: t('app.print'),
      toolbarQuickFilterPlaceholder: t('app.search'),
      toolbarFiltersTooltipShow: t('tools.filters.toolbarFiltersTooltipShow'),
      toolbarFiltersTooltipHide: t('tools.filters.toolbarFiltersTooltipHide'),
      toolbarDensityCompact: t('tools.filters.toolbarDensityCompact'),
      toolbarDensityComfortable: t('tools.filters.toolbarDensityComfortable'),
      columnsPanelShowAllButton: t('tools.filters.columnsPanelShowAllButton'),
      columnsPanelHideAllButton: t('tools.filters.columnsPanelHideAllButton'),
      columnsPanelTextFieldPlaceholder: t(
        'tools.filters.columnsPanelTextFieldPlaceholder'
      ),
      columnsPanelTextFieldLabel: t('tools.filters.columnsPanelTextFieldLabel'),
    };
  };

  return {
    gridClassesGet,
    // gridSxGet,
    localeTextGet,
  };
};
