// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconButton_icon-button__ba1ce {\n  min-width: 0px;\n  border-radius: 0.375rem }\n  .IconButton_icon-button-disabled__JtnJC {\n  opacity: 0.25 }\n  .IconButton_icon-button-icon__2KYQp {\n  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;\n  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;\n  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;\n  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n  transition-duration: 150ms }\n\n@media (min-width: 1280px) {\n  .IconButton_icon-button__ba1ce {\n    box-sizing: border-box } }\n", "",{"version":3,"sources":["<no source>","webpack://./src/shared/ui/IconButton/IconButton.module.scss"],"names":[],"mappings":"AAAA;EAAA,eAAA;EAAA,yBAAA;EAAA;EAAA,eAAA;EAAA;EAAA,wGAAA;EAAA,gGAAA;EAAA,+HAAA;EAAA,yDAAA;EAAA,4BAAA;;ACYA;EDZA;IAAA,wBAAA,CCeG","sourcesContent":[null,"@import '../../../styles/breakpoints.scss';\n\n.icon-button {\n  @apply min-w-0 rounded-md;\n  &-disabled {\n    @apply opacity-25;\n  }\n  &-icon {\n    @apply transition-colors;\n  }\n}\n\n@media (min-width: $xl) {\n  .icon-button {\n    @apply box-border;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon-button": "IconButton_icon-button__ba1ce",
	"icon-button-disabled": "IconButton_icon-button-disabled__JtnJC",
	"icon-button-icon": "IconButton_icon-button-icon__2KYQp"
};
export default ___CSS_LOADER_EXPORT___;
