import { MarketTranslations } from '../models/market.translations.interface';

export const marketTranslationsIT: MarketTranslations = {
  drawer: {
    footer: {
      title: 'crea il tuo modello!',
      content:
        'Sapevi gia, che puoi costruire con il nostro banco da lavoro anche un modello proprio? Adatta il modello ai tui bisogni o crea modelli, qualle dividi con la comunità e collezioni Pengueen Rewards 🙌',
      link: 'al banco da lavoro',
    },
  },
  button: 'al banco da lavoro',
  community_tools: 'strumenti dalla comunità',
  filters: {
    search: 'cerca a nome',
    search_detail: 'cerca descrizione',
  },
  levels: {
    advanced: 'avanzato',
    beginner: 'principiante',
    expert: 'Esperto',
    title: 'livelli',
  },
  field_empty: {
    text1: '',
    text2: '',
  },
  preview: {
    back: 'Indietro ai strumenti',
    contains: {
      input_fields: 'campi l`ìnput',
      title: 'contenuto',
      used1: 'usato',
      used2: 'ora',
    },
    copy: 'copia al mio banco da lovoro',
    created_by: {
      title: 'creato da',
    },
    mit: {
      text: 'questo strumento trovi sotto seguente',
      title: 'licenza open source',
    },
    notification: {
      subtitle:
        'hai aggiunto il strumento con successo al tuo banco da lavoro e puoi usarlo e modificarlo.',
      title: 'aggiunto con successo',
    },
    title: 'previsione',
  },
  subtitle:
    'usa i tui modelli dei strumenti o trova modelli nuovi di strumenti dalla comunità Pengueen',
  tags: 'Tag',
  templates: {
    all: 'I tutti template',
    newest: 'aggiunto recentemente',
    popular: 'popolare',
    title: 'Template',
  },
  title: 'Marcetplace',
  use: {
    dialog: {
      name_placeholder: 'strumento',
      subtitle:
        'per distinguere i strumenti, usa la designazione correta quando creando',
      title: 'da il strumento una designazione specifica',
    },
    form: {
      button: 'usa e crea adesso',
      name: {
        error: 'deve dato una designazione',
        label: 'designazione',
        placeholder: 'progetto p.e. festa d`estate',
      },
    },
    community: '',
    title: 'usa template',
    more: '',
  },
  use_cases: {
    grid: {
      item: {
        more: 'piu',
        select: 'scegli',
        use: 'usa',
      },
      show_less: 'Indica meno',
      show_more: 'Indica piu',
    },
    selected: {
      cancel: 'cancella la scelta',
      view: 'guardare strumenti selezionati',
    },
    title: 'usa casi',
  },
  find: 'Trovare strumenti',
};
