import { DashboardTranslations } from '../models/dashboard.translations.interface';

export const dashboardTranslationsDE: DashboardTranslations = {
  recommendation: {
    title: 'Hilfsmittel aus dem Marktplatz',
    subtitle:
      'Im Marktpatz von Pengueen findest du Hilfsmittel zu den unterschiedlichsten Themen, für verschiedene Branchen. Hilfsmittel die wir besonders gut und hilfreich finden, werden wir dir hier vorstellen. ',
  },
  title: 'Dashboard',
  communities: {
    title: 'Aktuelles von',
  },
  header: {
    explore: 'Gehe auf Entdeckungstour',
    subtitle: 'Lass uns gemeinsam schauen, was aktuell ansteht.',
  },
  widgets: {
    show_more: 'Alle Hilfsmittel anzeigen',
    hide_items: 'Weniger Hilfsmittel anzeigen',
    delete: {
      check: 'Bist du sicher?',
    },
    add: 'Neues Widget hinzufügen (coming soon)',
    community: {
      title: 'Willkommens-Text',
      tooltip:
        'Willkommenstext auf dem Dashboard. Heiße die neuen Mitglieder und Mitarbeiter:Innen deiner Gemeinschaft willkommen.',
    },
    changes: {
      answer: 'Schreibe eine Kurznachricht ...',
      room: {
        invite: '{{user1}} hat dich zur Gruppe {{groupName}} eingeladen, ',
        invite_others:
          '{{user1}} hat {{user2}} zur Gruppe {{groupName}} eingeladen, ',
        close:
          '{{user1}} hat die Gruppe {{groupName}} geschlossen. Du kannst keine neuen Nachrichten an die Gruppe schreiben, ',
        remove:
          '{{user1}} hat {{user2}} aus der Gruppe {{groupName}} entfernt, ',
        remove_me:
          '{{user1}} hat dich aus der Gruppe {{groupName}} entfernt, , ',
        sticky: 'Neue Nachricht(en) von {{user1}}, ',
        new_message:
          '{{user1}} hat eine neue Nachricht in Hilfsmittel {{toolName}} geschrieben.',

        leave: '{{user1}} hat eure Direktnachricht verlassen, ',
        mention: '{{user1}} hat dich persönlich angesprochen, ',
        alert: {
          close: {
            title: 'Gruppen Verlassen',
            subtitle:
              'Bist du sicher? Deine Nachrichten werden unwiderruflich gelöscht!',
          },
          leave: {
            title: 'Direktnachricht Verlassen',
            subtitle:
              'Bist du sicher? Alle deine Nachrichten werden unwiderruflich gelöscht',
          },
          invite_me: {
            subtitle: 'Bist du sicher?',
          },
        },
      },
      tool: {
        add_tool_chat_group:
          '{{user1}} hat für das Hilfsmittel {{toolName}} einen Austausch gestartet',
        add_tool_chat_multi_row:
          '{{user1}} hat für den Eintrag {{rowName}} einen Austausch gestartet',
        remove_tool_chat_group:
          '{{user1}} hat den Austausch für das Hilfsmittel {{toolName}} wieder beendet',
        create:
          '{{user1}} hat das Hilfsmittel {{toolName}} für {{communityName}} angelegt, ',
        create_no_community:
          '{{user1}} hat das Hilfsmittel {{toolName}} angelegt, ',
        change:
          '{{user1}} hat etwas am Hilfsmittel {{toolName}} für {{communityName}} geändert, ',
        change_no_community:
          '{{user1}} hat etwas am Hilfsmittel {{toolName}} geändert, ',
        change_duplicate:
          '{{user1}} hat {{count}} Änderungen an {{toolName}} für {{communityName}} vorgenommen, ',
        change_duplicate_no_community:
          '{{user1}} hat {{count}} Änderungen an {{toolName}} vorgenommen, ',

        delete:
          '{{user1}} hat das Hilfsmittel {{toolName}} von {{communityName}} gelöscht, ',
        delete_no_community:
          '{{user1}} hat das Hilfsmittel {{toolName}} gelöscht, ',
        right_assigned:
          'Dir wurde von {{user1}} in dem Hilfsmittel {{toolName}} das {{right}} Recht {{role}} gegeben, ',
        right_removed:
          'Dir wurde von {{user1}} in dem Hilfsmittel {{toolName}} das {{right}} Recht {{role}} genommen, ',
        removed:
          'Du wurdest von {{user1}} aus einem Eintrag im Hilfsmittel {{toolName}} entfernt, ',
        selected:
          'Du wurdest von {{user1}} in einem Eintrag im Hilfsmittel {{toolName}} genannt, ',
        multi_seleted: 'Du wurdest in einem MFE {{rowName}} ausgewählt, ',
        multi_assigned: 'Du wurdest zu einem MFE {{rowName}} zugewiesen, ',
        multi_removed: 'Du wurdest von einem MFE {{rowName}} entfernt, ',
        multi_row_add:
          '{{user1}} hat einen neuen Eintrag am Hilfsmittel {{toolName}} in {{tableName}} für {{communityName}} hinzugefügt, ',
        multi_rows_add:
          '{{user1}} hat neue Einträge am Hilfsmittel {{toolName}} in {{tableName}} für {{communityName}} hinzugefügt, ',
        multi_row_add_no_community:
          '{{user1}} hat einen neuen Eintrag am Hilfsmittel {{toolName}} in {{tableName}} hinzugefügt, ',
        multi_row_update:
          '{{user1}} hat einen Eintrag am Hilfsmittel {{toolName}} in  {{multiElementName}} geändert. {{columnName}} wurde von {{oldValue}} auf {{newValue}} geändert, ',
        multi_row_update_no_old_value:
          '{{user1}} hat einen Eintrag am Hilfsmittel {{toolName}} in  {{multiElementName}} geändert. {{columnName}} wurde auf {{newValue}} geändert, ',
      },
      contact: {
        rejected: '{{user1}} hat deine Einladung nicht angenommen, ',
        accepted:
          '{{user1}} hat deine Einladung angenommen und ist jetzt bei Pengueen, ',
        accepted_link:
          '{{user1}} hat deine Einladung über den Link zur {{group}} angenommen.',
        accepted_link_group:
          '{{user1}} hat deine Einladung über den Link zur Gruppe {{group}} der Gemeinschaft {{community}} angenommen',
        accepted_link_community:
          '{{user1}} hat deine Einladung zur Gemeinschaft {{community}} über den Link angenommen',
        accepted_link_admin:
          '{{user1}} hat deine Einladung zur Gemeinschaft {{community}} als Admin über den Link angenommen',
        accepted_link_employee:
          '{{user1}} hat deine Einladung zur Gemeinschaft {{community}} als Mitarbeiter:In über den Link angenommen',
        accepted_link_member:
          '{{user1}} hat deine Einladung zur Gemeinschaft {{community}} als Mitglied über den Link angenommen',
        community_accepted:
          'Einladung von {{user1}} durch {{user2}} angenommen, ',
        community_invited:
          '{{user1}} hat dich zur Gemeinschaft {{community}} eingeladen, ',
        community_rejected:
          'Einladung von {{user1}} durch {{user2}} abgelaufen, ',
        delete_contact: 'Möchtest du {{nickname}} aus deinem Netzwerk löschen?',
      },
      create_change: {
        at: 'um',
        room: {
          direct: 'Neue Nachricht(en) von',
          group: 'Neue Nachricht(en) in',
          text: 'letzte Nachricht am',
        },
        tool: {
          new: {
            text1: 'Neues Hilfsmittel',
            text2: 'erstellt am',
          },
          updated: {
            text1: 'Änderung(en) in dem Hilfsmittel',
            text2: 'zuletzt geändert',
          },
        },
      },
      empty: {
        buttons: {
          market: 'Zum Hilfsmittel-Marktplatz',
          workbench: 'Zur Werkbank',
        },
        text1: 'Scheint als hättest du heute frei 🥳',
        text2:
          'Wir haben einen super Tipp, wie du dich beschäftigen kannst. Was hältst du von Folgendem: Baue doch einfach mal ein Hilfsmittel für ein leidenschaftliches Thema von dir oder eines, welches dir deine Arbeit erleichtert.',
        text3:
          'Dein Vorteil? Du hast ein passgenaues Hilfsmittel für deine Bedürfnisse. Wenn du es in unserem Marktplatz teilst, kannst du anderen, die auch ein solches Hilfsmittel nutzen wollen, helfen.',
        text4: 'Oder gehe einfach auf Entdeckungstour durch Pengueen 🔭',
      },
      filters: {
        title: 'Ansicht',
      },
      title: 'Was ist seit deinem letzten Log-in passiert?',
    },
    community_todos: {
      title: 'Deine nächsten Schritte in deiner Gemeinschaft',
      users: {
        add: {
          button: "Los geht's!",
          text: 'Füge Personen zu Deiner Gemeinschaft hinzu!',
        },
      },
    },
    favorite_tools: {
      add: 'Hilfsmittel hinzufügen',
      empty: {
        button: 'Zeige mir, wie ich Hilfsmittel als Favoriten markiere',
        text: 'Hier kannst du besonders wichtige oder oft genutzte Hilfsmittel anheften.',
      },
      title: 'Deine Hilfsmittel',
    },
    favorite_users: {
      empty: {
        button: 'Zeige mir, wie ich Personen als Favoriten markiere',
        text: 'Ziehe dir jetzt die wichtigsten Personen und Gruppen direkt auf dein Dashboard. Markiere sie als Favoriten. 🤩',
      },
      title: 'Deine Favoriten – Personen und Gruppen',
    },
    show_all: 'Alle anzeigen',
  },
};
