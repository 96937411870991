import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog as MuiDialog } from '@mui/material';

// Components
import { IconButton } from '../IconButton/IconButton';
import { TextButton } from '../TextButton/TextButton';

// Hooks
import { useBreakpoints } from '../../hooks/use-breakpoints.hook';

// Models
import { Alert, AlertAction } from '../../models/shared.types';

// Styles
import styles from './AlertLocal.module.scss';

type AlertLocalProps = {
  alert: Alert | undefined;
  setAlertAction: (
    value: React.SetStateAction<AlertAction | undefined>
  ) => void;
};
export const AlertLocal = (props: AlertLocalProps) => {
  const { smDown } = useBreakpoints();
  const { t } = useTranslation();

  // Component state
  const [show, setShow] = useState(false);
  // const [alert, setAlert] = useState<Alert | undefined>(undefined);
  // const [alertAction, setAlertAction] = useState<AlertAction | undefined>(
  //   undefined
  // );

  // Open alert.
  useEffect(() => {
    props.alert && setShow(true);
    // eslint-disable-next-line
  }, [props.alert]);

  /**
   * Handler to close notification panel.
   */
  const onClose = useCallback(
    (action: AlertAction) => {
      setShow(false);
      // Wait for animation
      setTimeout(() => {
        action === AlertAction.Close && props.setAlertAction(AlertAction.Close);
        action === AlertAction.Submit &&
          props.setAlertAction(AlertAction.Submit);
        // setAlert(undefined);
      }, 200);
    },
    // eslint-disable-next-line
    [alert, show]
  );

  return (
    <MuiDialog
      aria-labelledby="alert-title"
      aria-describedby="alert-description"
      className={styles['alert']}
      open={show}
      scroll="body"
      sx={{
        '& .MuiPaper-root': {
          backgroundImage: 'none',
          margin: 0,
          padding: '1rem',
          maxWidth: 'none',
          width: smDown ? '95%' : '480px',
        },
      }}
      onClose={onClose}
    >
      <div className={styles['alert-header']}>
        <span className={styles['alert-header-title']}>
          {props.alert?.title}
        </span>
        <div className={styles['alert-header-icon']}>
          <IconButton
            icon={['fas', 'times']}
            iconColor="text-app"
            onClick={() => onClose(AlertAction.Close)}
          />
        </div>
      </div>
      {props.alert?.subtitle && (
        <Box className={styles['alert-subtitle']}>{props.alert.subtitle}</Box>
      )}
      <div className={styles['alert-buttons']}>
        <TextButton
          onClick={() => onClose(AlertAction.Close)}
          classes={styles['alert-buttons-cancel']}
        >
          {t('app.cancel')}
        </TextButton>
        <TextButton onClick={() => onClose(AlertAction.Submit)}>
          {t('app.ok')}
        </TextButton>
      </div>
    </MuiDialog>
  );
};
