// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Imprint_imprint-col__fSWFw {\n\n    display: flex;\n\n    flex-direction: column\n}\n\n.Imprint_imprint-paragraph__IyHBX {\n\n    margin-top: 1rem;\n\n    margin-bottom: 1rem\n}\n\n.Imprint_imprint-title__Ne30L {\n\n    font-weight: 600\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;;IAAA,cAAA;;IAAA;CAAA;;AAAA;;IAAA,iBAAA;;IAAA;CAAA;;AAAA;;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imprint-col": "Imprint_imprint-col__fSWFw",
	"imprint-paragraph": "Imprint_imprint-paragraph__IyHBX",
	"imprint-title": "Imprint_imprint-title__Ne30L"
};
export default ___CSS_LOADER_EXPORT___;
