import {
  createContext,
  useContext,
  useRef,
  MutableRefObject,
  useMemo,
} from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';

type StableNavigateContextType = {
  navigateRef: MutableRefObject<NavigateFunction> | null;
};

const StableNavigateContext = createContext<StableNavigateContextType>({
  navigateRef: null,
});

const StableNavigateContextProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  navigateRef.current = navigate;

  const contextValue = useMemo(() => {
    return { navigateRef };
  }, [navigateRef]);

  return (
    <StableNavigateContext.Provider value={contextValue}>
      {children}
    </StableNavigateContext.Provider>
  );
};

const useStableNavigate = (): NavigateFunction => {
  const { navigateRef } = useContext(StableNavigateContext);
  if (navigateRef === null)
    throw new Error('StableNavigate context is not initialized');

  return navigateRef.current;
};

export {
  StableNavigateContext,
  StableNavigateContextProvider,
  useStableNavigate,
};
