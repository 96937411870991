import { ReactNode } from 'react';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { Box } from '@mui/material';
import clsx from 'clsx';

// Components
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';

// Styles
import styles from './RemoveButton.module.scss';

type RemoveButtonProps = {
  children: ReactNode;
  classes?: string;
  icon?: [IconPrefix, IconName];
  width?: string;
  onRemove: () => void;
};

export const RemoveButton = (props: RemoveButtonProps) => {
  return (
    <Box
      className={clsx(styles['remove-button'], props.classes && props.classes)}
      sx={{
        backgroundColor: 'bg.card',
        borderColor: 'border.app',
        width: props.width ?? '240px',
      }}
    >
      {props.icon && (
        <Icon
          classes={styles['remove-button-icon']}
          icon={props.icon}
          sx={{ color: 'text.secondary' }}
        />
      )}
      <Box
        className={styles['remove-button-content']}
        sx={{ color: 'text.secondary' }}
      >
        {props.children}
      </Box>
      <IconButton
        classes={styles['remove-button-remove']}
        icon={['fas', 'times']}
        padding="0.25rem"
        onClick={props.onRemove}
      />
    </Box>
  );
};
