import { Box, SxProps, Theme } from '@mui/material';
import clsx from 'clsx';

// Styles
import styles from './Loader.module.scss';

type LoaderProps = {
  classes?: string;
  color?: string;
  size?: number;
  sx?: SxProps<Theme>;
};

export const Loader = (props: LoaderProps) => {
  const size = props.size ?? 8;

  return (
    <Box
      className={clsx(styles['loader'], props.classes && props.classes)}
      sx={{ ...props.sx }}
    >
      <Box
        className={styles['bounce1']}
        sx={{
          backgroundColor: props.color ?? 'text.primary',
          height: size + 'px',
          width: size + 'px',
        }}
      ></Box>
      <Box
        className={styles['bounce2']}
        sx={{
          backgroundColor: props.color ?? 'text.primary',
          height: size + 'px',
          width: size + 'px',
        }}
      ></Box>
      <Box
        className={styles['bounce3']}
        sx={{
          backgroundColor: props.color ?? 'text.primary',
          height: size + 'px',
          width: size + 'px',
        }}
      ></Box>
    </Box>
  );
};
