import Checkbox from '@mui/material/Checkbox';
import { FavoriteTool } from '../../../dashboard/components/WidgetFavoriteTools/WidgetFavoriteTools';
import { ToolFavorite } from '../../../dashboard/models/dashboard.types';
import { useCallback, useEffect, useState } from 'react';
import { TFunctionResult } from 'i18next';
import { FormFieldLabel } from '../../../../shared/ui/FormFieldLabel/FormFieldLabel';
import { useContactsStore } from '../../stores/use-contacts.store';

// Styles
import styles from './ContactsToolsCard.module.scss';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

type ContactsToolsProps = {
  tool: ToolFavorite;
  onClick: (id: string) => void;
};
const ContactsTools = (props: ContactsToolsProps) => {
  const [checked, setChecked] = useState<boolean>(false);

  const handleChange = () => {
    props.onClick(props.tool.id);

    setChecked(!checked);
  };

  return (
    <div className={styles['contacts-tools-container']}>
      <Checkbox
        checked={checked}
        onChange={() => handleChange()}
        className="absolute z-50 right-1 top-1"
        sx={{
          color: 'text.secondary',
          '&.Mui-checked': {
            color: 'success.main',
          },
        }}
      />
      <FavoriteTool
        tool={props.tool}
        disableCommunityLogo
        disableOnClick
        classes="w-full"
        heightClasses="h-fit"
        onClick={() => handleChange()}
      />
    </div>
  );
};

type ContactsToolsCardProps = {
  tools: ToolFavorite[];
  label?: string | TFunctionResult;
  labelColor?: string;
  labelFontWeightClasses?: string;
};
export const ContactsToolsCard = (props: ContactsToolsCardProps) => {
  // Contacts store state
  const [selectedTools, setSelectedTools] = useContactsStore((state) => [
    state.seletedTools,
    state.setSelectedTools,
  ]);
  const { t } = useTranslation();

  // Reset Values on unmount
  useEffect(() => {
    return () => {
      setSelectedTools([]);
    };
    // eslint-disable-next-line
  }, []);

  // ######### //
  // CALLBACKS //
  // ######### //

  const onSelectTools = useCallback(
    (id: string) => {
      const updatedTools = [...selectedTools];
      const index = updatedTools.findIndex((tool_id) => tool_id === id);

      index > -1 ? updatedTools.splice(index, 1) : updatedTools.push(id);

      setSelectedTools(updatedTools);
    },
    [selectedTools]
  );

  return (
    <div className={styles['select-tools']}>
      {props.label && (
        <FormFieldLabel
          label={props.label}
          labelColor={props.labelColor}
          labelFontWeightClasses={props.labelFontWeightClasses}
        />
      )}
      <div className={styles['select-tools-box']}>
        {props.tools.length > 0 && (
          <>
            {props.tools.map((tool, index) => {
              return (
                <ContactsTools
                  key={index}
                  tool={tool}
                  onClick={onSelectTools}
                />
              );
            })}
          </>
        )}
        {props.tools.length === 0 && (
          <>
            <Box className="mb-4" sx={{ color: 'text.secondary' }}>
              {t('contacts.tools_empty')}
            </Box>
          </>
        )}
      </div>
    </div>
  );
};
