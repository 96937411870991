import dayjs from 'dayjs';
import create, { State } from 'zustand';
import 'dayjs/locale/de';
import 'dayjs/locale/en';

// Models
import {
  Community,
  CommunityData,
} from '../../communities/models/communities.types';
import { FilterType, SortDirection } from '../../../shared/models/shared.types';
import {
  Account,
  NavigationCommunityResponse,
  Profile,
  UserInfoState,
} from '../models/user.types';
import { Change } from '../../dashboard/models/dashboard.types';

export interface UserState extends State {
  // #TODO: Change account language to correct locales? en --> en-US etc.
  account: Account | undefined;
  activeCommunity: CommunityData | undefined;
  changes: Change[];
  communities: Community[] | undefined;
  communityText: string | undefined;
  dialogCommunityLicenseManage: boolean;
  documentsFilter: DocumentsFilter | undefined;
  drawer: boolean;
  isChangesUpdated: boolean;
  modalAccountDelete: boolean;
  modalEmailChange: boolean;
  modalProfilePhoneVerify: boolean;
  modalSecurityTanActivate: boolean;
  modalSecurityTanDeactivate: boolean;
  navigationCommunities: NavigationCommunityResponse | undefined;
  profile: Profile | undefined;
  settingsActive: UserInfoState | undefined;
  tan: boolean | null;
  trial: number | null;
  verified: boolean | null;
  unreadMessages: number;
  resetUserStoreData: () => void;
  setAccount: (accountData: Account | undefined) => void;
  setActiveCommunity: (communtiy: CommunityData | undefined) => void;
  setChanges: (changes: Change[]) => void;
  setCommunities: (communities: Community[] | undefined) => void;
  setCommunityText: (communityText: string | undefined) => void;
  setDialogCommunityLicenseManage: (
    dialogCommunityLicenseManage: boolean
  ) => void;
  setDocumentsFilter: (DocumentsFilter: DocumentsFilter | undefined) => void;
  setDrawer: (drawer: boolean) => void;
  setIsChangesUpdated: (isChangesUpdated: boolean) => void;
  setModalAccountDelete: (modalAccountDelete: boolean) => void;
  setModalEmailChange: (modalEmailChange: boolean) => void;
  setModalProfilePhoneVerify: (modalProfilePhoneVerify: boolean) => void;
  setModalSecurityTanActivate: (modalSecurityTanActivate: boolean) => void;
  setModalSecurityTanDeactivate: (modalSecurityTanDeactivate: boolean) => void;
  setNavigationCommunities: (
    navCommunities: NavigationCommunityResponse | undefined
  ) => void;
  setProfile: (profileData: Profile | undefined) => void;
  setSettingsActive: (settingsActive: UserInfoState | undefined) => void;
  setTan: (tan: boolean) => void;
  setTrial: (trial: number | null) => void;
  setUnreadMessages: (unreadMessages: number) => void;
  setVerified: (verified: boolean) => void;
}

export interface DocumentsFilter {
  select: FilterType.Date | FilterType.Name;
  sort: SortDirection;
}

const initialDocumentsFilter = (): DocumentsFilter => {
  return {
    select: FilterType.Name,
    sort: SortDirection.None,
  };
};

export const initialUserStoreData = {
  account: undefined,
  activeCommunity: undefined,
  changes: [],
  communities: undefined,
  communityText: undefined,
  dialogCommunityLicenseManage: false,
  documentsFilter: initialDocumentsFilter(),
  drawer: false,
  isChangesUpdated: false,
  modalAccountDelete: false,
  modalEmailChange: false,
  modalProfilePhoneVerify: false,
  modalSecurityTanActivate: false,
  modalSecurityTanDeactivate: false,
  navigationCommunities: undefined,
  profile: undefined,
  settingsActive: undefined,
  tan: null,
  trial: null,
  verified: null,
  unreadMessages: 0,
};

export const useUserStore = create<UserState>((set, get) => ({
  ...initialUserStoreData,
  resetUserStoreData: () =>
    set((state: UserState) => ({
      ...state,
      ...initialUserStoreData,
    })),
  setAccount: (accountData: Account | undefined) => {
    set({ account: accountData });
    accountData?.language && dayjs.locale(accountData.language);
  },
  setActiveCommunity: (activeCommunity: CommunityData | undefined) =>
    set({ activeCommunity }),
  setChanges: (changes: Change[]) => set({ changes }),
  setCommunities: (communities: Community[] | undefined) =>
    set({ communities }),
  setCommunityText: (communityText: string | undefined) =>
    set({ communityText }),
  setDialogCommunityLicenseManage: (dialogCommunityLicenseManage: boolean) =>
    set({ dialogCommunityLicenseManage }),
  setDocumentsFilter: (documentsFilter: DocumentsFilter | undefined) =>
    set({ documentsFilter }),
  setDrawer: (drawer: boolean) => set({ drawer }),
  setIsChangesUpdated: (isChangesUpdated: boolean) => set({ isChangesUpdated }),
  setModalAccountDelete: (modalAccountDelete: boolean) =>
    set({ modalAccountDelete }),
  setModalEmailChange: (modalEmailChange: boolean) => set({ modalEmailChange }),
  setModalProfilePhoneVerify: (modalProfilePhoneVerify: boolean) =>
    set({ modalProfilePhoneVerify }),
  setModalSecurityTanActivate: (modalSecurityTanActivate: boolean) =>
    set({ modalSecurityTanActivate }),
  setModalSecurityTanDeactivate: (modalSecurityTanDeactivate: boolean) =>
    set({ modalSecurityTanDeactivate }),
  setNavigationCommunities: (
    navigationCommunities: NavigationCommunityResponse | undefined
  ) => set({ navigationCommunities }),
  setProfile: (profileData: Profile | undefined) => {
    // Get tan and verified state
    const tan = get().tan;
    const verified = get().verified;

    // Unset tan authentication and verified state if no phone number is provided
    set((state: UserState) => ({
      ...state,
      profile: profileData,
      tan: false,
      verified: false,
    }));
  },
  setSettingsActive: (settingsActive: UserInfoState | undefined) => {
    set({ settingsActive });
  },
  setTan: (tan: boolean) => {
    set({ tan });
  },
  setTrial: (trial: number | null) => {
    set({ trial });
  },
  setUnreadMessages: (unreadMessages: number) => set({ unreadMessages }),
  setVerified: (verified: boolean) => {
    set({ verified });
  },
}));
