import { forwardRef, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunctionResult } from 'i18next';
import {
  Box,
  Button,
  Popover as MuiPopover,
  SxProps,
  Theme,
} from '@mui/material';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';

// Components
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';

// Hooks
import { useBreakpoints } from '../../hooks/use-breakpoints.hook';

// Styles
import styles from './Popover.module.scss';

type PopoverProps = {
  anchor?: Element;
  button?: ReactNode;
  buttonClasses?: string;
  buttonPadding?: string;
  buttonMargin?: string;
  buttonSx?: SxProps<Theme>;
  buttonTitle?: ReactNode | TFunctionResult | string;
  children: ReactNode;
  contentTitle?: TFunctionResult | string;
  count?: number;
  disableButtonTitle?: boolean;
  disableContentSm?: boolean;
  disableHeader?: boolean;
  icon?: [IconPrefix, IconName];
  maxWidth?: string;
  preset?:
    | 'popover'
    | 'primary'
    | 'tools'
    | 'tools-menu'
    | 'secondary'
    | 'paper-secondary';
  onClick?: (action: any) => void;
  onClose?: () => void;
  onOpen: (anchor: Element | undefined) => void;
};

export const Popover = forwardRef((props: PopoverProps, ref: any) => {
  const { lgDown } = useBreakpoints();
  const { t } = useTranslation();

  // Component state
  const [anchor, setAnchor] = useState<Element | null | undefined>(null);
  const open = Boolean(anchor);
  const [preset, setPreset] = useState<
    | 'popover'
    | 'primary'
    | 'tools'
    | 'tools-menu'
    | 'secondary'
    | 'paper-secondary'
  >('popover');
  const [sx, setSx] = useState<SxProps<Theme> | undefined>(undefined);
  const [sxIcon, setSxIcon] = useState<SxProps<Theme> | undefined>(undefined);
  const [sxContent, setSxContent] = useState<any>(undefined); // any: BlaBlaBla error

  // Close menu by anchor
  useEffect(() => {
    if (props.anchor && props.anchor !== anchor) {
      // Set anchor to open menu
      setAnchor(props.anchor);
    }
    if (!props.anchor && anchor) {
      // Reset anchor to close menu
      setAnchor(null);
    }
  }, [anchor, props]);

  useEffect(() => {
    if (props.count && props.count > 0) {
      setPreset('primary');
    } else {
      if (props.preset) {
        setPreset(props.preset);
      } else {
        setPreset('popover');
      }
    }
  }, [props]);

  useEffect(() => {
    if (preset === 'popover') {
      setSx({
        backgroundColor: 'bg.card',
        '&:hover': { backgroundColor: 'primary.main' },
        '&:hover #button-content .MuiBox-root:first-of-type': {
          color: 'white',
        },
        '&:hover .MuiSvgIcon-root': { color: 'white' },
      });
      setSxIcon({ color: 'text.primary' });
      setSxContent({ color: 'text.primary' });
    }
    if (preset === 'primary') {
      setSx({
        backgroundColor: 'primary.main',
        '&:hover': {
          backgroundColor: 'primary.dark',
        },
      });
      setSxIcon({
        color: 'white',
      });
      setSxContent({
        color: 'white',
        '.MuiBox-root:last-child': {
          color: 'text.primary',
        },
      });
    }
    if (preset === 'secondary') {
      setSx({
        backgroundColor: 'background.paper',
        '&:hover': {
          backgroundColor: 'background.paper',
        },
      });
      setSxIcon({
        color: 'primary.main',
      });
      setSxContent({
        color: 'primary.main',
      });
    }
    if (preset === 'tools-menu') {
      setSx({
        backgroundColor: 'bg.card',
        '&:hover': {
          backgroundColor: 'background.paper',
          color: 'primary.main',
        },
      });
      setSxIcon({
        color: 'text.secondary',
      });
      setSxContent({
        color: 'text.secondary',
      });
    }
    if (preset === 'tools') {
      setSx({
        backgroundColor: 'bg.card',
        borderColor: 'text.secondary',
        borderRadius: '0.5rem',
        borderStyle: 'dashed',
        borderWidth: '1px',
        '&:hover': { backgroundColor: 'bg.card' },
      });
      setSxIcon({ color: 'text.secondary' });
      setSxContent({ color: 'text.secondary' });
    }
    if (preset === 'paper-secondary') {
      setSx({
        backgroundColor: 'background.paper',
        border: 1,
        borderColor: 'border.app',
        '&:hover': { backgroundColor: 'bg.card' },
      });
      setSxIcon({ color: 'text.secondary' });
      setSxContent({ color: 'text.secondary' });
    }
  }, [preset]);

  /**
   * Callback to init close menu.
   */
  const onClose = useCallback(() => {
    props.onOpen(undefined);
    props.onClose && props.onClose();
  }, [props]);

  return (
    <>
      {props.button ?? (
        <Button
          className={clsx(
            styles['popover-button'],
            props.buttonClasses && props.buttonClasses
          )}
          color="inherit"
          disableElevation
          ref={ref}
          sx={{
            ...sx,
            padding: props.buttonPadding ?? '0.25rem 0.75rem',
            margin: props.buttonMargin ?? '',
          }}
          variant="contained"
          onClick={(event) => props.onOpen(event.currentTarget)}
        >
          <Icon
            classes={styles['popover-button-icon']}
            icon={props.icon ?? ['fas', 'filter']}
            sx={{
              ...sxIcon,
              marginRight: !props.disableButtonTitle
                ? lgDown
                  ? props.disableContentSm
                    ? '0'
                    : '0.5rem'
                  : '0.5rem'
                : '0',
              width: !props.disableButtonTitle ? '0.75rem' : '1rem',
            }}
          />
          {!props.disableContentSm && (
            <Box
              className={styles['popover-button-content']}
              id="button-content"
              sx={{ ...sxContent }}
            >
              {!props.disableButtonTitle && (
                <Box className={styles['popover-button-content-title']}>
                  {props.buttonTitle ?? t('app.filters.title')}
                </Box>
              )}
              {props.count!! > 0 && (
                <Box
                  className={styles['popover-button-content-count']}
                  sx={{ backgroundColor: 'background.paper' }}
                >
                  {props.count}
                </Box>
              )}
            </Box>
          )}
        </Button>
      )}
      <MuiPopover
        anchorEl={anchor ?? props.anchor}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        className={styles['popover']}
        open={open}
        onClose={onClose}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        // Scss modules material ui elements nesting not working...
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '0.5rem',
            boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
            maxWidth: props.maxWidth ?? '320px',
            margin: '0.5rem',
            height: 'fit-content',
            overflowY: 'auto',
            padding: '1rem',
          },
        }}
        // transformOrigin={{
        //   horizontal: 'right',
        //   vertical: 'top'
        // }}
      >
        {!props.disableHeader && (
          <div className={styles['popover-header']}>
            <div className={styles['popover-header-title']}>
              {props.contentTitle ?? t('app.filters.headline')}
            </div>
            <IconButton
              classes={styles['popover-header-close']}
              icon={['fas', 'times']}
              onClick={onClose}
            />
          </div>
        )}
        {props.children}
      </MuiPopover>
    </>
  );
});
