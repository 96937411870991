import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import emojiRegex from 'emoji-regex';

// Contexts
import { SocketContext } from '../../../shared/context/socket.context';

// Models
import { MenuItem, ResultState } from '../../../shared/models/shared.types';
import {
  InfoMessage,
  InfoMessageType,
  Message,
  MessageCreateEditRequest,
} from '../models/message.types';
import {
  GroupRoomSettings,
  NewsCenterResponse,
  Room,
  RoomAction,
  RoomType,
} from '../models/news-center.types';
import { User } from '../../user/models/user.types';

// Stores
import { useNewsCenterStore } from '../stores/use-news-center.store';
import { useUserStore } from '../../user/stores/use-user.store';
import { unstable_batchedUpdates } from 'react-dom';
import { useSharedStore } from '../../../shared/stores/use-shared.store';
import { useContext } from 'react';
import { useAuthStore } from '../../public/stores/use-auth.store';
import { useShared } from '../../../shared/hooks/use-shared.hook';
import {
  ContactCommunity,
  ContactCommunityIdType,
} from '../../contacts/models/contacts.types';
import { useMutation } from 'react-query';
import { useFetch } from '../../../shared/hooks/use-fetch.hook';
import { useNewsCenterHttp } from './use-news-center-http.hook';
import { CommunityUserRole } from '../../communities/models/communities.types';

export const useNewsCenter = () => {
  const { t } = useTranslation();
  const { fullNameGet } = useShared();
  const { handleError, handleRetry } = useFetch();
  const { newsCenterGet } = useNewsCenterHttp();

  // Context
  const socket = useContext(SocketContext);

  // Shared store state
  const [setNotification] = useSharedStore((state) => [state.setNotification]);

  // Auth store state
  const [accessToken] = useAuthStore((state) => [state.accessToken]);

  // User store state
  const [profile] = useUserStore((state) => [state.profile]);

  // News center store state
  const [
    directRooms,
    groupRooms,
    selectedRoom,
    selectedRoomMessages,
    setTempMessage,
    setDirectRooms,
    setGroupRooms,
    setSelectedFiles,
    setSelectedRoom,
    setSelectedRoomMessages,
    setGodparentsChat,
    setLatestUnreadRooms,
    setFavoriteRooms,
  ] = useNewsCenterStore((state) => [
    state.directRooms,
    state.groupRooms,
    state.selectedRoom,
    state.selectedRoomMessages,
    state.setTempMessage,
    state.setDirectRooms,
    state.setGroupRooms,
    state.setSelectedFiles,
    state.setSelectedRoom,
    state.setSelectedRoomMessages,
    state.setGodparentsChat,
    state.setLatestUnreadRooms,
    state.setFavoriteRooms,
  ]);

  // User store state
  const [account, setUnreadMessages] = useUserStore((state) => [
    state.account,
    state.setUnreadMessages,
  ]);

  // GET user data mutation
  const newsCenterGetMutation = useMutation(() => newsCenterGet(), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
    onSuccess: (data: NewsCenterResponse | undefined) => {
      if (data) {
        try {
          // Set news center data
          unstable_batchedUpdates(() => {
            data.groupChats && setGroupRooms(data.groupChats);
            data.privateChats && setDirectRooms(data.privateChats);
            data.invitorChat && setGodparentsChat(data.invitorChat);
            data.unreadChats && setLatestUnreadRooms(data.unreadChats);
            data.favoriteChats && setFavoriteRooms(data.favoriteChats);

            let unread = 0;
            data.groupChats.map((chat) => {
              if (chat.unread_msgs && chat.unread_msgs > 0) {
                unread += chat.unread_msgs;
              }
              return chat;
            });
            data.privateChats.map((chat) => {
              if (chat.unread_msgs && chat.unread_msgs > 0) {
                unread += chat.unread_msgs;
              }
              return chat;
            });
            setUnreadMessages(unread);
          });
        } catch (e) {}
      }
    },
    onError: (error) => {
      const errRes = error?.response;
      if (errRes) {
        handleError(errRes.status);
      }
    },
  });

  /**
   * GET News center action items.
   * @returns MenuItem array
   */
  const actionItemsGet = (): MenuItem[] => {
    return [
      {
        action: RoomType.Direct,
        title: t('newscenter.actions.direct'),
      },
      {
        action: RoomType.Group,
        title: t('newscenter.actions.group'),
      },
      {
        action: RoomType.Broadcast,
        title: t('newscenter.actions.broadcast'),
      },
    ];
  };

  /**
   * GET Direct room chat partner by own id.
   * @param id User id
   * @param users User array
   * @returns Chat partner user
   */
  const directRoomChatPartnerGet = (
    id: string,
    users: Partial<User>[]
  ): Partial<User> | undefined => {
    return users.find((user) => user.account?.id !== id);
  };

  /**
   * DELETE Direct room.
   */
  const directRoomDeleteEffect = () => {
    if (directRooms && directRooms.length > 0 && selectedRoom) {
      const updatedDirectRooms = [...directRooms];
      setDirectRooms(
        updatedDirectRooms.filter((pChat) => pChat.id !== selectedRoom.id)
      );
      setSelectedRoom(undefined);
      setNotification({
        title: t('newscenter.room.groups.leave.title'),
        state: ResultState.Success,
      });
    }
  };

  /**
   * UPDATE Direct room unread messages.
   * @param id Direct room id
   */
  const directRoomGetEffect = (id: string) => {
    if (directRooms) {
      const pChats = [...directRooms];
      const pChatIndex = [...pChats].findIndex((gChat) => gChat.id === id);
      if (pChatIndex > -1) {
        pChats[pChatIndex] = { ...pChats[pChatIndex], unread_msgs: 0 };
        setDirectRooms(pChats);
      }
    }
  };

  /**
   * Get direct room id by user id.
   * @param id User id
   * @returns Direct room id
   */
  const directRoomIdByUserGet = (id: string): string | undefined => {
    if (directRooms) {
      for (let pChat of directRooms) {
        if (pChat.users.find((user) => user.account?.id === id)) {
          return pChat.id;
        }
      }
    }
    return undefined;
  };

  /**
   * CREATE Direct room.
   * @param directRoom Direct room
   */
  const directRoomPostEffect = (directRoom: Room) => {
    if (directRooms) {
      const updatedDirectRooms = [...directRooms];
      if (
        !directRooms.some((el) => {
          return directRoom.id === el.id;
        })
      ) {
        updatedDirectRooms.push(directRoom);
        setDirectRooms(updatedDirectRooms);
      }
    } else {
      setDirectRooms([directRoom]);
    }
  };

  /**
   * UPDATE group room unread messages.
   * @param id Group room id
   */
  const groupRoomGetEffect = (id: string) => {
    if (groupRooms) {
      const gChats = [...groupRooms];
      const gChatIndex = [...gChats].findIndex((gChat) => gChat.id === id);
      if (gChatIndex > -1) {
        gChats[gChatIndex] = { ...gChats[gChatIndex], unread_msgs: 0 };
        setGroupRooms(gChats);
      }
    }
  };

  /**
   * DELETE group room.
   */
  const groupRoomDeleteEffect = () => {
    if (groupRooms && groupRooms.length > 0 && selectedRoom) {
      const updatedGroupChats = [...groupRooms];
      setGroupRooms(
        updatedGroupChats.filter((gChat) => gChat.id !== selectedRoom.id)
      );
      setSelectedRoom(undefined);
      setNotification({
        title: selectedRoom.permissions?.can_delete_group
          ? t('newscenter.room.groups.close.title')
          : t('newscenter.room.groups.leave.title'),
        state: ResultState.Success,
      });
    }
  };

  /**
   * CREATE Group room.
   * @param room Group room
   */
  const groupRoomPostEffect = (room: Room) => {
    if (groupRooms) {
      const updatedGroupChats = [...groupRooms];
      updatedGroupChats.push(room);
      setGroupRooms(updatedGroupChats);
    } else {
      setGroupRooms([room]);
    }
  };

  /**
   * UPDATE Group room settings.
   * @param settings GroupRoom settings
   */
  const groupRoomSettingsUpdateEffect = (data: {
    room_id: string;
    settings: GroupRoomSettings;
  }) => {
    const updatedGroupChats = [...groupRooms];
    let updateIndex = updatedGroupChats.findIndex(
      (gChat) => gChat.id === data.room_id
    );
    if (updateIndex > -1) {
      updatedGroupChats[updateIndex].settings = data.settings;
      setGroupRooms(updatedGroupChats);
      if (selectedRoom?.id === data.room_id) {
        const updatedSelectedRoom = {
          ...selectedRoom,
          settings: data.settings,
        };
        setSelectedRoom(updatedSelectedRoom);
      }
    }
  };

  /**
   * DELETE Group user by id.
   * @param data Room and user ids
   */
  const groupRoomUserDeleteEffect = (data: {
    room_id: string;
    user_id: string;
  }) => {
    if (data.user_id === account?.id) {
      let updatedSelectedRoom: Room | undefined = selectedRoom;
      if (updatedSelectedRoom) {
        updatedSelectedRoom.inactive_room = true;
      }
      setSelectedRoom(updatedSelectedRoom);
      socket.emit('leaveRoom', {
        accessToken,
        room_id: data.room_id,
      });
    } else {
      if (selectedRoom?.id === data.room_id) {
        const updatedSelectedRoom = {
          ...selectedRoom,
        };
        updatedSelectedRoom.users = updatedSelectedRoom.users.filter(
          (user) => user.account?.id !== data.user_id
        );
        setSelectedRoom(updatedSelectedRoom);
      }
    }
  };

  /**
   * UPDATE Group users.
   * @param users Users
   */
  const groupRoomUsersUpdateEffect = (data: {
    room_id: string;
    users: Partial<User>[];
  }) => {
    const updatedGroupChats = [...groupRooms];
    let updateIndex = updatedGroupChats.findIndex(
      (gChat) => gChat.id === data.room_id
    );
    if (updateIndex > -1) {
      updatedGroupChats[updateIndex].users = data.users;
      setGroupRooms(updatedGroupChats);
      if (selectedRoom?.id === data.room_id) {
        const updatedSelectedRoom = {
          ...selectedRoom,
          users: [...selectedRoom.users].concat(data.users),
        };
        setSelectedRoom(updatedSelectedRoom);
      }
    }
  };

  /**
   * Checks if there is an active direct room by user id.
   * @param id User id
   */
  const isDirectRoomActive = (id: string): boolean => {
    if (directRooms && directRooms.length > 0) {
      for (let directRoom of directRooms) {
        for (let user of directRoom.users) {
          if (user.account?.id === id) {
            return true;
          }
        }
      }
    }
    return false;
  };

  /**
   * CREATE Message by body.
   * @param message Message
   */
  const messageCreateEffect = (data: { message: Message; room_id: string }) => {
    if (selectedRoomMessages) {
      unstable_batchedUpdates(() => {
        const updatedMessages = [...selectedRoomMessages];
        updatedMessages.push(data.message);
        setSelectedRoomMessages(updatedMessages);
        setSelectedFiles([]);
        setTempMessage(undefined);

        if (selectedRoom) {
          let updatedRoom = { ...selectedRoom };
          if (data.message.info && selectedRoom.type === RoomType.Direct) {
            if (data.message.info.type === InfoMessageType.join) {
              if (updatedRoom.inactive_room) {
                updatedRoom.inactive_room = false;
                setSelectedRoom(updatedRoom);
              }
            }
            if (data.message.info.type === InfoMessageType.leave) {
              updatedRoom.inactive_room = true;
              setSelectedRoom(updatedRoom);
            }
          }

          if (data.message.info && updatedRoom?.type === RoomType.Group) {
            if (data.message.info.type === InfoMessageType.close) {
              updatedRoom.inactive_room = true;
              setSelectedRoom(updatedRoom);
            }
            if (data.message.info.type === InfoMessageType.leave) {
              const index = updatedRoom.users?.findIndex((user) => {
                let userName = fullNameGet(
                  user.profile?.personal_data.first_name,
                  user.profile?.personal_data.last_name
                )?.toString();
                return userName && data.message.info?.sub?.includes(userName);
              });
              if (index && index > -1) {
                updatedRoom.users?.splice(index, 1);
                setSelectedRoom(updatedRoom);
              }
            }
          }
        }
      });
    }
  };

  /**
   * DELETE Message by id.
   * @param id Message id
   */
  const messageDeleteEffect = (id: string) => {
    if (selectedRoomMessages.length > 0) {
      if (selectedRoomMessages) {
        unstable_batchedUpdates(() => {
          const updatedMessages = [...selectedRoomMessages];
          setSelectedRoomMessages(
            updatedMessages.filter((message) => message.id !== id)
          );
        });
      }
    }
  };

  /**
   * Handle message content. Replace links.
   * @param messageContent Message content
   * @returns Handled message content
   */
  const messageHandleContent = (messageContent: string) => {
    // Replace links outside <a> elements with <a> elements
    function replaceLinks(content: string) {
      const urlRegex = /((https?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/g;
      return content.replace(urlRegex, function (url: string) {
        return `<a href="${url}" target="_blank">${url}</a>`;
      });
    }

    function replaceEmails(content: string) {
      const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
      return content.replace(emailRegex, function (email: string) {
        return `<a href="mailto:${email}">${email}</a>`;
      });
    }

    // function replacePhonesWithPlus(content: string) {
    //   const phoneRegex =
    //     /(\+\d{1,2}\s?)?(\()?\d{2,3}(\))?[-.\s]?\d{3,4}[-.\s]?\d{3,4}/g;
    //   return content.replace(phoneRegex, function (phone: string) {
    //     return `<span>${phone}</span>`;
    //   });
    // }

    let handledContent = messageContent;

    // Sanitise raw HTML to prevent cross-site scripting (XSS) attack
    handledContent = DOMPurify.sanitize(handledContent);

    // Replace links with <a> elements
    handledContent = replaceLinks(handledContent);
    // Replace emails with <a mailto> elements
    handledContent = replaceEmails(handledContent);

    // Set emoji bigger
    handledContent = emojiHandle(handledContent);  

    // Replace phones with <span> elements
    // handledContent = replacePhonesWithPlus(handledContent);

    return handledContent;
  };
  
  const emojiHandle= (message: string)=> {
    const emojiRegExp = emojiRegex();
    return message.replace(emojiRegExp, `<span style="font-size: 1.5em;">$&</span>`);
  }
  
  /**
   * Remove Html Tags.
   * @param str Message string
   * @returns Handled string
   */
  const removeHtmlTags = (str: string | undefined) => {
    return str?.replace(/<[^>]*>/g, '');
  };
  /**
   * Handle content. Add commas to number.
   */
  const addCommasToNumber = (content: string) => {
    let handledContent = content;

    // Sanitise raw HTML to prevent cross-site scripting (XSS) attack
    handledContent = DOMPurify.sanitize(handledContent);

    const numStr = String(handledContent);
    let formattedNumber;
    if (numStr.includes(',')) {
      const [beforeComma, afterComma] = numStr.split(',');
      // Add thousand point
      const formattedBeforeComma = beforeComma.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        '.'
      );
      formattedNumber =
        formattedBeforeComma + (afterComma ? ',' + afterComma : '');
    } else if (numStr.includes('.')) {
      const [beforeComma, afterComma] = numStr.split('.');
      // Add thousand point
      const formattedBeforeComma = beforeComma.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        '.'
      );
      formattedNumber =
        formattedBeforeComma + (afterComma ? ',' + afterComma : '');
    } else {
      formattedNumber = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return formattedNumber;
  };

  /**
   * Check PhoneNumber.
   */
  const isValidPhoneNumberFormat = (content: string) => {
    let handledContent = content;

    // Sanitise raw HTML to prevent cross-site scripting (XSS) attack
    handledContent = DOMPurify.sanitize(handledContent);

    const phoneRegex = /^[0-9+()\-\s]+$/;
    return phoneRegex.test(content);
  };

  /**
   * Check Url.
   */
  const isValidUrlFormat = (content: string) => {
    let handledContent = content;

    // Sanitise raw HTML to prevent cross-site scripting (XSS) attack
    handledContent = DOMPurify.sanitize(handledContent);

    const urlRegex = /^((https?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)$/;
    return urlRegex.test(content);
  };

  /**
   * Check Email.
   */
  const isValidEmailFormat = (content: string) => {
    let handledContent = content;

    // Sanitise raw HTML to prevent cross-site scripting (XSS) attack
    handledContent = DOMPurify.sanitize(handledContent);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(content);
  };

  const isValidTimeFormat = (time: string) => {
    // Checks whether the input conforms to the hh:mm format
    const regex = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/;
    const matches = time.match(regex);

    // If the input matches the pattern and contains valid hours/minutes
    if (matches) {
      const hours = parseInt(matches[1], 10);
      const minutes = parseInt(matches[2], 10);

      // Check that the hours and minutes are within valid ranges
      if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
        return true;
      }
    }

    return false;
  };
  /**
   * Handle message content. Get all emojis.
   * @param messageContent Message content
   */
  const messageHandleEmojis = (
    messageContent: string
  ): 'small' | 'middle' | 'big' => {
    function getEmojies(content: string) {
      const emojiRegExp = emojiRegex();

      // Check if content from first paragraph is emojis only
      function isOnlyEmojis(paragraphContent: string) {
        return paragraphContent.replace(emojiRegExp, '') === '';
      }

      // Count paragraphs. Emojis only only should have one
      let paragraphCount = (content.match(/<p>/g) || []).length;

      if (paragraphCount === 1) {
        // Extract content from first paragraph
        const substringContent = content.substring(
          content.indexOf('<p>') + 3,
          content.indexOf('</p>')
        );

        // Extract all emojis
        const matchedEmojis = substringContent.match(emojiRegExp);

        // Check for emoji only content
        if (isOnlyEmojis(substringContent)) {
          switch (matchedEmojis?.length) {
            case 1:
              return 'big';
            case 2:
            case 3:
              return 'middle';
            default:
              return 'small';
          }
        }
      } else {
        return 'small';
      }
      return 'small';
    }

    return getEmojies(messageContent);
  };

  /**
   * UPDATE Message by id.
   * @param message Message
   */
  const messageUpdateEffect = (message: Message) => {
    unstable_batchedUpdates(() => {
      if (selectedRoomMessages) {
        const updatedMessages = [...selectedRoomMessages];
        const updatedMessage = updatedMessages.find(
          (msg) => msg.id === message.id
        );
        if (updatedMessage) {
          updatedMessage.content = message.content;
          updatedMessage.create_change_info = message.create_change_info;
          updatedMessage.quote = message.quote;
          setSelectedRoomMessages(updatedMessages);
        }
      }
    });
  };

  /**
   * GET Messages. Insert into state.
   * @param messages Messages
   */
  const messagesGetEffect = (messages: Message[]) => {
    if (messages.length > 0) {
      setSelectedRoomMessages([...messages, ...selectedRoomMessages]);
    }
  };

  /**
   * GET Unread messages. Insert unread into state.
   * @param messages Messages
   */
  const messagesUnreadGetEffect = (
    messages: Message[]
  ): Message[] | undefined => {
    const unreadMessages: Message[] = [];
    for (let message of messages) {
      if (
        message.read.findIndex((user) => user.account?.id === account?.id) < 0
      ) {
        unreadMessages.push(message);
      }
    }

    // Insert unread messages
    if (selectedRoomMessages && unreadMessages.length > 0) {
      const updatedMessages = [...selectedRoomMessages];
      for (const unreadMessage of unreadMessages) {
        updatedMessages.push(unreadMessage);
      }
      return updatedMessages;
    }
  };

  /**
   * GET Room actions by room type.
   * @param type RoomType
   */
  const roomActionsGet = (
    type: RoomType,
    community?: ContactCommunity,
    printView?: boolean
  ): MenuItem[] => {
    if (type === RoomType.Group) {
      if (printView) {
        return [
          {
            action: RoomAction.Add,
            disabled: !selectedRoom?.permissions?.can_add_user,
            title: t('newscenter.room.groups.users.add.title'),
          },
          {
            action: RoomAction.Delete,
            disabled:
              selectedRoom &&
              selectedRoom.settings?.community_id &&
              !selectedRoom.permissions?.can_delete_group
                ? true
                : false,
            title: selectedRoom?.settings?.community_id
              ? t('newscenter.room.groups.delete.title')
              : selectedRoom?.permissions?.can_delete_group
              ? t('newscenter.room.groups.close.text')
              : t('newscenter.room.groups.leave.text'),
          },
          {
            action: RoomAction.Settings,
            disabled: !selectedRoom?.permissions?.can_edit_settings,
            title: t('app.settings'),
          },
          {
            action: RoomAction.PrintView,
            title: 'Alles anzeigen',
          },
        ];
      }

      return [
        {
          action: RoomAction.Add,
          disabled: !selectedRoom?.permissions?.can_add_user,
          title: t('newscenter.room.groups.users.add.title'),
        },
        {
          action: RoomAction.Delete,
          disabled:
            selectedRoom &&
            selectedRoom.settings?.community_id &&
            !selectedRoom.permissions?.can_delete_group
              ? true
              : false,
          title: selectedRoom?.settings?.community_id
            ? t('newscenter.room.groups.delete.title')
            : selectedRoom?.permissions?.can_delete_group
            ? t('newscenter.room.groups.close.text')
            : t('newscenter.room.groups.leave.text'),
        },
        {
          action: RoomAction.Settings,
          disabled: !selectedRoom?.permissions?.can_edit_settings,
          title: t('app.settings'),
        },
      ];
    } else if (type === RoomType.Network) {
      if (
        community?.selected_group?.id === CommunityUserRole.Admin ||
        community?.selected_group?.id === CommunityUserRole.Employee ||
        community?.selected_group?.id === CommunityUserRole.Member
      ) {
        return [
          {
            action: RoomAction.Settings,
            title: t('app.settings'),
          },
        ];
      }
      return [
        {
          action: RoomAction.Settings,
          title: t('app.settings'),
        },
        {
          action: RoomAction.Delete,
          title: t('newscenter.room.groups.delete.title'),
        },
      ];
    } else {
      if (printView) {
        return [
          {
            action: RoomAction.Delete,
            title: t('newscenter.room.direct.delete.title'),
          },
          {
            action: RoomAction.PrintView,
            title: 'Alles anzeigen',
          },
        ];
      }
      return [
        {
          action: RoomAction.Delete,
          title: t('newscenter.room.direct.delete.title'),
        },
      ];
    }
  };

  /**
   * GET Room actions by room type in mobile.
   * @param type RoomType
   */
  const roomActionsGetInMobile = (
    type: RoomType,
    community?: ContactCommunity
  ): MenuItem[] => {
    if (type === RoomType.Group) {
      return [
        {
          action: RoomAction.User,
          title: t('newscenter.room.groups.users.title'),
        },
        {
          action: RoomAction.Add,
          title: t('newscenter.room.groups.users.add.title'),
        },
        {
          action: RoomAction.Delete,
          title: t('newscenter.room.groups.delete.title'),
        },
        {
          action: RoomAction.Settings,
          title: t('app.settings'),
        },
      ];
    } else if (type === RoomType.Network) {
      return [
        {
          action: RoomAction.Settings,
          title: t('app.settings'),
        },
        {
          action: RoomAction.Delete,
          title: t('newscenter.room.groups.delete.title'),
        },
      ];
    } else {
      return [
        {
          action: RoomAction.Delete,
          title: t('newscenter.room.direct.delete.title'),
        },
      ];
    }
  };

  /**
   * GET Unread messages ids by user id and messages.
   * @param id User id
   * @param messages Messages
   * @returns Unread messages ids
   */
  const roomUnreadMessagesGet = (id: string, messages: Message[]): string[] => {
    let unreadMessages: string[] = [];
    for (let i = messages.length - 1; i > -1; i--) {
      if (!messages[i].read.find((user) => user.account?.id === id)) {
        unreadMessages.push(messages[i].id);
      } else {
        return unreadMessages;
      }
    }
    return unreadMessages;
  };

  /*
   *
   */
  const onInfoMessageSubmit = (
    infoMessageType: InfoMessageType,
    object?: string[],
    room_id?: string
  ) => {
    if (
      ((selectedRoom && !selectedRoom.inactive_room) || room_id) &&
      accessToken
    ) {
      let object_toString;
      if (object && object.length > 0) {
        object_toString = object.join(', ');
      }

      const info_message: InfoMessage = {
        type: infoMessageType,
        sub:
          fullNameGet(
            profile?.personal_data.first_name,
            profile?.personal_data.last_name
          )?.toString() ?? undefined,
        obj: object_toString && object_toString,
      };

      const messageInfoPostRequest: MessageCreateEditRequest = {
        accessToken,
        files: [],
        room_id: room_id ? room_id : selectedRoom?.id!,
        message: {
          content: '',
          send_email: false,
          send_sms: false,
          info: info_message,
        },
        message_id: undefined,
      };
      socket.emit('createMessage', messageInfoPostRequest);
    }
  };

  /*
   *
   */
  const onMessageSubmit = (message_content: string, room_id: string) => {
    if (accessToken) {
      const messagePostRequest: MessageCreateEditRequest = {
        accessToken,
        files: [],
        room_id: room_id,
        message: {
          content: `<p>${message_content}</p>\n`,
          send_email: false,
          send_sms: false,
        },
      };
      socket.emit('createMessage', messagePostRequest);
    }
  };

  return {
    actionItemsGet,
    addCommasToNumber,
    directRoomChatPartnerGet,
    directRoomDeleteEffect,
    directRoomGetEffect,
    directRoomIdByUserGet,
    directRoomPostEffect,
    emojiHandle,
    groupRoomDeleteEffect,
    groupRoomGetEffect,
    groupRoomPostEffect,
    groupRoomSettingsUpdateEffect,
    groupRoomUsersUpdateEffect,
    groupRoomUserDeleteEffect,
    isDirectRoomActive,
    isValidPhoneNumberFormat,
    isValidUrlFormat,
    isValidEmailFormat,
    isValidTimeFormat,
    onInfoMessageSubmit,
    onMessageSubmit,
    messageCreateEffect,
    messageDeleteEffect,
    messageHandleContent,
    messageHandleEmojis,
    messageUpdateEffect,
    messagesGetEffect,
    messagesUnreadGetEffect,
    roomActionsGet,
    roomActionsGetInMobile,
    roomUnreadMessagesGet,
    newsCenterGetMutation,
    removeHtmlTags,
  };
};
