// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Search_search__LXhrz {\n    display: flex;\n    align-items: center\n}\n  .Search_search-outlined-input__qlm00 {\n    padding: 0px\n}\n    .Search_search-outlined-input-root__88lBQ {\n    height: 100%;\n    width: 100%;\n    border-radius: 0.375rem\n}\n  .Search_search__LXhrz input {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    font-size: 0.875rem;\n    line-height: 1.25rem\n}\n    .Search_search__LXhrz input::-webkit-input-placeholder {\n    opacity: 1\n}\n    .Search_search__LXhrz input:-ms-input-placeholder {\n    opacity: 1\n}\n    .Search_search__LXhrz input::placeholder {\n    opacity: 1\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;IAAA,cAAA;IAAA;CAAA;EAAA;IAAA;CAAA;IAAA;IAAA,aAAA;IAAA,YAAA;IAAA;CAAA;EAAA;IAAA,iBAAA;IAAA,wBAAA;IAAA,oBAAA;IAAA,oBAAA;IAAA;CAAA;IAAA;IAAA;CAAA;IAAA;IAAA;CAAA;IAAA;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": "Search_search__LXhrz",
	"search-outlined-input": "Search_search-outlined-input__qlm00",
	"search-outlined-input-root": "Search_search-outlined-input-root__88lBQ"
};
export default ___CSS_LOADER_EXPORT___;
