import { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  DraftHandleValue,
  EditorState,
  Modifier,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { v4 as uuidv4 } from 'uuid';
import { useFilePicker } from 'use-file-picker';
import { Box } from '@mui/material';
import clsx from 'clsx';

// Contexts
import { SocketContext } from '../../../../shared/context/socket.context';

// Components
import { EmojiPicker } from '../../../../shared/components/EmojiPicker/EmojiPicker';
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';
import { Notifications } from '../../../../shared/components/MessageNotifications/Notifications';
import { ResultBox } from '../../../../shared/ui/ResultBox/ResultBox';

// Hooks
import { useDebouncedEffect } from '../../../../shared/hooks/use-debounced-effect.hook';
import { useParams } from 'react-router-dom';

// Models
import {
  Message as IMessage,
  MessageAction,
  MessageCreateEditRequest,
  SocketFile,
} from '../../models/message.types';
import {
  Notifications as INotifications,
  ResultState,
  SocketEventSubscriptionResponse,
} from '../../../../shared/models/shared.types';

// Stores
import { AuthState, useAuthStore } from '../../../public/stores/use-auth.store';
import {
  NewsCenterState,
  useNewsCenterStore,
} from '../../stores/use-news-center.store';
import { useUserStore } from '../../../user/stores/use-user.store';

// Styles
import styles from './NewsCenterChatEditor.module.scss';
import {
  FileType,
  FileTypeErrorSendRequest,
  RoomType,
} from '../../models/news-center.types';
import { useMutation } from 'react-query';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useNewsCenterHttp } from '../../hooks/use-news-center-http.hook';

type NewsCenterChatEditorProps = {
  children?: ReactNode;
  // editorState: EditorState;
  messageEdit?: IMessage;
  messageQuote?: IMessage;
  messageState?: MessageAction;
  readOnly?: boolean | undefined;
  roomId?: string;
  type?: RoomType;
  tool_id?: string;
  row_id?: string;
  onScrollToBottom: () => void;
  onSetLastMessageToEdit: () => void;
  onSetSelectedFiles: (selectedFiles: SocketFile[]) => void;
  onStateReset: () => void;
  // setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
};

export const NewsCenterChatEditor = (props: NewsCenterChatEditorProps) => {
  const { handleRetry } = useFetch();
  const { fileTypeErrorSend } = useNewsCenterHttp();
  // Context
  const socket = useContext(SocketContext);

  const [openFileSelector, { plainFiles, errors, clear }] = useFilePicker({
    // Check file type
    accept: [
      '.png',
      '.jpg',
      '.jpeg',
      '.pdf',
      '.zip',
      '.gif',
      '.doc',
      '.dot',
      '.docx',
      '.ppt',
      '.pot',
      '.pps',
      '.ppa',
      '.pptx',
      '.ai',
      '.eps',
      '.ps',
      '.psd',
      '.csv',
      '.ics',
      '.txt',
      '.rtx',
      '.mp4',
      '.mp3',
      '.wav',
      '.m4a',
      '.mpeg',
      '.mpg',
      '.mpe',
      '.qt',
      '.mov',
      '.xls',
      '.xla',
      '.xlsx',
      '.zip',
      '.rar',
      '.rtf',
    ],
    maxFileSize: 7, //MB,
  });
  const { id } = useParams<'id'>();
  const { t } = useTranslation();

  // Component state
  const [editorContent, setEditorContent] = useState<string | undefined>(
    undefined
  );
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [messageEditState, setMessageEditState] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<
    INotifications | undefined
  >(undefined);
  const [notificationsReset, setNotificationsReset] = useState<
    string | undefined
  >(undefined);
  const [prevMessageEdit, setPrevMessageEdit] = useState<IMessage | undefined>(
    undefined
  );
  const [typeError, setTypeError] = useState<boolean>(false);

  // Auth store state
  const [accessToken] = useAuthStore((state: AuthState) => [state.accessToken]);
  const [account] = useUserStore((state) => [state.account]);

  // News center store state
  const [
    anchorEl,
    selectedFiles,
    selectedRoom,
    selectedRoomMessages,
    setTempMessage,
    setSelectedFiles,
  ] = useNewsCenterStore((state: NewsCenterState) => [
    state.anchorEl,
    state.selectedFiles,
    state.selectedRoom,
    state.selectedRoomMessages,
    state.setTempMessage,
    state.setSelectedFiles,
  ]);

  // ####### //
  // MUTATION //
  // ####### //

  // Send unsupported file types.
  const fileTypeErrorSendMutation = useMutation(
    (data: FileTypeErrorSendRequest) => fileTypeErrorSend(data)
  );

  // ####### //
  // EFFECTS //
  // ####### //

  // Effects on component mount
  useEffect(() => {
    // Reset editor state on escape press
    const onKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onEditorStateReset();
        setSelectedFiles([]);
      }
    };
    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
    // eslint-disable-next-line
  }, []);

  // Effect auto Focus
  useEffect(() => {
    setEditorState(EditorState.moveFocusToEnd(editorState));
  }, [selectedRoomMessages.length, props.messageQuote, anchorEl]);

  // Reset EditorState by changing chat room
  useEffect(() => {
    setEditorState(EditorState.moveFocusToEnd(EditorState.createEmpty()));
  }, [id]);

  // Assign the ref's current value to the count Hook
  //Run this code when the value of count changes
  useEffect(() => {
    props.messageEdit !== prevMessageEdit &&
      setPrevMessageEdit(props.messageEdit);
    // eslint-disable-next-line
  }, [props]);

  // Watches arrow up key event to init last message edit
  // Checks for differences in messages (message edit state)
  // Disables submit button if message in editor is an exact match
  useEffect(() => {
    const onSetLastMessageToEdit = (event: KeyboardEvent) => {
      // Select empty editor placeholder
      const editorElem = document.getElementsByClassName(
        'public-DraftEditorPlaceholder-root'
      )[0];
      // Check if arrow up is pressed and if editor is on focus init message edit
      if (
        event.key === 'ArrowUp' &&
        editorElem &&
        editorElem.classList.contains('public-DraftEditorPlaceholder-hasFocus')
      ) {
        props.onSetLastMessageToEdit();
      }
    };
    // Add keydown event listener on mount
    document.addEventListener('keydown', onSetLastMessageToEdit);

    if (editorState) {
      // Get raw html string
      const content = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      setEditorContent(content);

      // Check if current message doesn't match message to edit
      if (props.messageEdit) {
        const messageToEdit = new DOMParser().parseFromString(
          props.messageEdit.content,
          'text/html'
        ).documentElement.textContent;

        const currentMessage = new DOMParser().parseFromString(
          content,
          'text/html'
        ).documentElement.textContent;

        if (messageToEdit !== currentMessage) {
          setMessageEditState(true);
        } else {
          setMessageEditState(false);
        }
      }
    }

    // Remove keydown event listener on unmount
    return () => {
      document.removeEventListener('keydown', onSetLastMessageToEdit);
    };
  }, [editorState, props]);

  // Set isTyping state to true on editor content change
  useEffect(() => {
    if (editorContent && editorContent.length > 8) {
      if (!isTyping) {
        setIsTyping(true);
        socket.emit('isTyping', {
          accessToken,
          isTyping: true,
          room_id: id ? id : props.roomId,
        });
      }
    }
    // eslint-disable-next-line
  }, [editorContent]);

  // Set isTyping state to false after stop typing (3 seconds debounce)
  useDebouncedEffect(
    () => {
      if (isTyping) {
        setIsTyping(false);
        socket.emit('isTyping', {
          accessToken,
          isTyping: false,
          room_id: id ? id : props.roomId,
        });
      }
    },
    [editorContent, isTyping],
    3000
  );

  // Watch selected file changes
  useEffect(() => {
    if (plainFiles && plainFiles.length > 0) {
      setTypeError(false);
      const files: SocketFile[] = [...selectedFiles];
      // Search for duplicates in current file selection. Push new file into state.
      for (let plainFile of plainFiles) {
        let fileTypeOk = false;

        // Check file type
        if (
          plainFile.type !== FileType.DOC &&
          plainFile.type !== FileType.DOCX &&
          plainFile.type !== FileType.GIF &&
          plainFile.type !== FileType.JPG &&
          plainFile.type !== FileType.MP4 &&
          plainFile.type !== FileType.PDF &&
          plainFile.type !== FileType.PNG &&
          plainFile.type !== FileType.XLS &&
          plainFile.type !== FileType.ZIP &&
          plainFile.type !== FileType.DOT &&
          plainFile.type !== FileType.AI &&
          plainFile.type !== FileType.EPS &&
          plainFile.type !== FileType.PS &&
          plainFile.type !== FileType.PSD &&
          plainFile.type !== FileType.PPTX &&
          plainFile.type !== FileType.PPT &&
          plainFile.type !== FileType.PPS &&
          plainFile.type !== FileType.MP3 &&
          plainFile.type !== FileType.M4A &&
          plainFile.type !== FileType.WAV &&
          //plainFile.type !== FileType.CSV &&
          plainFile.type !== FileType.ICS &&
          plainFile.type !== FileType.TXT &&
          plainFile.type !== FileType.RTX &&
          plainFile.type !== FileType.MPEG &&
          plainFile.type !== FileType.MPG &&
          plainFile.type !== FileType.MPE &&
          plainFile.type !== FileType.QT &&
          plainFile.type !== FileType.MOV &&
          plainFile.type !== FileType.XLA &&
          plainFile.type !== FileType.XLSX &&
          plainFile.type !== FileType.ODT &&
          plainFile.type !== FileType.ODS &&
          plainFile.type !== FileType.ODP &&
          plainFile.type !== FileType.RAR
        ) {
          setTypeError(true);
          setTimeout(() => {
            setTypeError(false);
          }, 10000);

          // Send unsupported file types.
          fileTypeErrorSendMutation.mutate({
            date: new Date(),
            fileType: plainFile.type ? plainFile.type : plainFile.name,
          });
        } else {
          fileTypeOk = true;
        }

        const duplicate = files.find(
          (file) => file.file.name === plainFile.name
        );
        if (!duplicate && fileTypeOk) {
          files.push({ fileName: plainFile.name, file: plainFile });
        }
      }
      files.length !== selectedFiles.length && setSelectedFiles(files);
    }
    // eslint-disable-next-line
  }, [plainFiles]);

  // Set editor state by edit message
  useEffect(() => {
    if (props.messageEdit !== prevMessageEdit) {
      if (props.messageEdit) {
        // Set edit message for difference check. Insert empty string because editor state got one at the end
        const descriptionFromHTML = convertFromHTML(props.messageEdit.content);
        const content = ContentState.createFromBlockArray(
          descriptionFromHTML.contentBlocks,
          descriptionFromHTML.entityMap
        );
        setEditorState(
          EditorState.moveFocusToEnd(EditorState.createWithContent(content))
        );
      } else {
        setEditorState(EditorState.moveFocusToEnd(EditorState.createEmpty()));
      }
    }
    // eslint-disable-next-line
  }, [props, prevMessageEdit]);

  // Push pasted image into state
  useEffect(() => {
    const onPaste = (event: ClipboardEvent) => {
      if (event.clipboardData?.files && event.clipboardData.files.length > 0) {
        onImagePaste(event.clipboardData.files[0]);
      }
    };
    document.addEventListener('paste', onPaste);
    return () => {
      document.removeEventListener('paste', onPaste);
    };
    // eslint-disable-next-line
  }, [selectedFiles]);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handle editor key command (send message on enter if desktop device)
   */
  const onEditorHandleKeyCommand = useCallback(
    (command: string): DraftHandleValue => {
      if (isDesktop && command === 'split-block') {
        onMessageSubmit();
        return 'handled';
      }
      return 'not-handled';
    },
    // eslint-disable-next-line
    [editorState, notifications, props]
  );

  /**
   * Handler for pasted text. Insert text into editor state.
   * @param text Text
   * @param html HTML
   * @param editorState EditorState
   * @param onChange onChange
   * @returns Boolean
   */
  const onEditorHandlePastedText = (
    text: string,
    html: string,
    editorState: EditorState,
    onChange: (editorState: EditorState) => void
  ): boolean => {
    const currentContent = editorState?.getCurrentContent();
    const currentSelection = editorState?.getSelection();
    if (editorState && currentContent && currentSelection && text) {
      // Insert content at current selected position
      const newContent = Modifier.replaceText(
        currentContent,
        currentSelection,
        text
      );
      const newEditorState = EditorState.push(
        editorState,
        newContent,
        'insert-characters'
      );
      setEditorState(
        EditorState.forceSelection(
          newEditorState,
          newContent.getSelectionAfter()
        )
      );
    }
    props.onScrollToBottom();
    return true;
  };

  /**
   * Handler to reset editor state.
   */
  const onEditorStateReset = useCallback(() => {
    setEditorState(EditorState.createEmpty());
    const newState = EditorState.createEmpty();
    setEditorState(EditorState.moveFocusToEnd(newState));
    setNotificationsReset(uuidv4());
    props.onStateReset();
    // eslint-disable-next-line
  }, []);

  /**
   * Handler to insert an emoji at current selected position.
   */
  const onEmojiAdd = useCallback(
    (emoji: string) => {
      const currentContent = editorState?.getCurrentContent();
      const currentSelection = editorState?.getSelection();
      if (editorState && currentContent && currentSelection) {
        // Insert content at current selected position
        const newContent = Modifier.replaceText(
          currentContent,
          currentSelection,
          // String.fromCharCode(0xd83d, 0xde04)
          emoji
        );
        const newEditorState = EditorState.push(
          editorState,
          newContent,
          'insert-characters'
        );
        setEditorState(
          EditorState.moveFocusToEnd(
            EditorState.forceSelection(
              newEditorState,
              newContent.getSelectionAfter()
            )
          )
        );
      }
    },
    // eslint-disable-next-line
    [editorState]
  );

  /**
   * Handler on image paste. Push into state.
   * @param pastedFile File
   */
  const onImagePaste = useCallback(
    (pastedFile: File) => {
      const updatedSelectedFiles = [...selectedFiles];
      updatedSelectedFiles.push({
        fileName: pastedFile.name,
        file: pastedFile,
      });
      setSelectedFiles(updatedSelectedFiles);
    },
    // eslint-disable-next-line
    [selectedFiles]
  );

  /**
   * Handler to cancel editing message.
   */
  const onMessageEditCancel = useCallback(() => {
    onEditorStateReset();
    setSelectedFiles([]);
    // eslint-disable-next-line
  }, []);

  /**
   * Handler to send message and reset editor state.
   */
  const onMessageSubmit = useCallback(() => {
    // remove file size error message
    typeError && setTypeError(false);

    let content = '';
    if (editorState) {
      content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    }

    if (
      accessToken &&
      (id || props.roomId) &&
      (content?.length > 8 || selectedFiles.length > 0) &&
      selectedRoom &&
      !selectedRoom.inactive_room
    ) {
      //Set a message displayed temporarily until the message is fully loaded (Socket Resonse 'createMessage')
      const newMessage: IMessage = {
        id: uuidv4(),
        content,
        quote: props.messageQuote && props.messageQuote,
        read: [],
        create_change_info: {
          changed_at: new Date().toString(),
          created_at: new Date().toString(),
          created_by: {
            account: account && account,
          },
        },
      };
      !props.messageEdit && setTempMessage(newMessage);

      //Initialize EditorState
      onEditorStateReset();

      // Scroll to Bottom, only when not quoting messages
      if (!props.messageQuote) {
        props.onScrollToBottom();
        // Run function after 1 second in case message loading is delayed
        setTimeout(() => {
          props.onScrollToBottom();
        }, 1000);
      }

      const messagePostPatchRequest: MessageCreateEditRequest = {
        accessToken,
        files: selectedFiles,
        room_id: props.roomId ? props.roomId : id!,
        type: props.type,
        message: {
          content,
          quote: props.messageQuote && props.messageQuote.id,
          send_email: notifications && notifications.send_email ? true : false,
          send_sms: notifications && notifications.send_sms ? true : false,
        },
        tool_id: props.tool_id,
        row_id: props.row_id,
        message_id: props.messageEdit?.id ?? undefined,
      };

      // Create / update message
      socket.emit(
        !props.messageEdit ? 'createMessage' : 'updateMessage',
        messagePostPatchRequest,
        (response: SocketEventSubscriptionResponse) => {
          if (response.status === ResultState.Success) {
            // Reset editor state on success
            // onEditorStateReset();
          }
        }
      );
    }
    // eslint-disable-next-line
  }, [
    editorState,
    notifications,
    props,
    selectedRoom,
    selectedRoom?.inactive_room,
  ]);

  return (
    <div
      className={
        props.roomId
          ? styles['godparents-chat-editor']
          : styles['news-center-chat-editor']
      }
    >
      {props.children}
      <Box className="flex w-full xs:flex-col lg:flex-row">
        <Editor
          editorState={editorState}
          editorClassName={
            props.roomId ? styles['godparents-editor'] : styles['editor']
          }
          editorStyle={{ overflow: 'hidden' }}
          handleKeyCommand={onEditorHandleKeyCommand}
          handlePastedText={onEditorHandlePastedText}
          placeholder={
            props.readOnly
              ? t('newscenter.room.groups.delete.editor')
              : t('newscenter.room.direct.message.placeholder')
          }
          readOnly={props.readOnly}
          toolbar={{
            options: ['inline', 'list'],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
            list: {
              options: ['unordered', 'ordered'],
            },
          }}
          toolbarClassName={clsx(
            styles['editor-toolbar'],
            styles['editor-toolbar-h']
          )}
          toolbarStyle={{
            background: 'inherit',
            border: 0,
            margin: 0,
            padding: 0,
          }}
          wrapperClassName={styles['editor-wrapper']}
          // onContentStateChange={setContentState}
          onEditorStateChange={(editorState) => {
            setEditorState(editorState);
          }}
        />
        <Box
          className={
            props.roomId
              ? styles['godparents-editor-inputs']
              : styles['editor-inputs']
          }
        >
          <Box className={styles['editor-inputs-actions']}>
            <EmojiPicker
              buttonClasses={styles['editor-inputs-actions-button']}
              onSelect={onEmojiAdd}
            />
            <IconButton
              classes={styles['editor-inputs-actions-button']}
              icon={['far', 'paperclip']}
              iconSize="small"
              preset="text.secondary"
              onClick={() => openFileSelector()}
            />
            {!props.roomId && (
              <Notifications
                buttonClasses={styles['editor-inputs-actions-notifications']}
                iconColor="text-sub"
                reset={notificationsReset}
                onChange={setNotifications}
              />
            )}
            <IconButton
              icon={[
                'fas',
                props.messageState === MessageAction.Edit
                  ? messageEditState
                    ? 'paper-plane'
                    : 'times'
                  : 'paper-plane',
              ]}
              padding="0.75rem"
              // preset="primary"
              preset={props.readOnly ? 'popover' : 'primary'}
              disabled={props.readOnly ? true : false}
              onClick={
                props.messageState === MessageAction.Edit
                  ? messageEditState
                    ? onMessageSubmit
                    : onMessageEditCancel
                  : onMessageSubmit
              }
            />
          </Box>
        </Box>
      </Box>

      {/* file Error message */}
      {errors[0]?.fileSizeToolarge && (
        <Box className={styles['news-center-chat-editor-error']}>
          <ResultBox
            classes={styles['news-center-chat-editor-error-result']}
            state={ResultState.Warning}
            onClose={clear}
          >
            {t('newscenter.file.size')}
            <span className="underline">
              <a
                color="warning.main"
                target="_blank"
                rel="noreferrer"
                href="https://www.pengueen.de/tipps-und-tricks/datengroessen-reduzieren"
              >
                {t('newscenter.file.link')}
              </a>
            </span>
          </ResultBox>
        </Box>
      )}
      {typeError && (
        <Box className={styles['news-center-chat-editor-error']}>
          <ResultBox
            classes={styles['news-center-chat-editor-error-result']}
            state={ResultState.Warning}
            onClose={() => setTypeError(false)}
          >
            {t('newscenter.file.type')}
          </ResultBox>
        </Box>
      )}
    </div>
  );
};
