import { AuthTranslations } from '../models/auth.translations.interface';

export const authTranslationsIT: AuthTranslations = {
  account: {
    setup: {
      community: {
        employees: 'Collaboratori',
        form: {
          name: {
            error: 'Il nome deve essere almeno 3 lettere',
            placeholder: 'Il nome della comunità',
            title: 'Designazione',
          },
          sector: {
            error: 'Si deve sceglere una branca',
            title: 'Qualle branca è appropriata?',
          },
        },
        members: 'Membri',
        title: {
          defined: ', come si chiama la tua comunità?',
          undefined: 'Come si chiama la tua comunità?',
        },
      },
      invite: {
        success: 'Invito accettato.',
      },
      name: {
        submit: {
          defined: 'Ecco me',
          undefined: 'Avanti senza nome',
        },
        subtitle: `Siamo contenti, di conoscerti. Come ti chiami? Cosi possiame vedere il tuo nome e anche personne, chi hai invitati. Questo lo fa un po piu personale, o che ne pensi?`,
        title: 'Benvenuta/o, come ti chiami?',
      },
      password: {
        title1: 'fantastico',
        title2: 'Scegli solo il tuo password e poi si comincia',
      },
      success: {
        button: 'Alla mia comunità',
        text: 'Inizia con il tuo strumento primo e invita altre utente e configura il tuo profilo.',
        title: 'Buon lavoro',
      },
      users: {
        form: {
          employees: {
            placeholder: 'La tua designazione p.e. direzione',
            title: 'Collaboratore (diritti ampliati)',
          },
          members: {
            placeholder: 'La tua designazione p.e. consigliere',
            title: 'Membri (diritti semplici)',
          },
          title: 'L`adattamento della designazione di ruolo',
        },
        info: `a Pengueen differenziamo normalmente tralle ruoli "collaboratore" e "membro" per distinguere i diritti. Qui puoi adattare qelli.`,
        title: 'Come si chiamano gli tui utenti?',
      },
    },
  },
  login: {
    error: {
      credentials:
        'Il tuo indirizzo email o password non sono corretti. Controlla p.f. che hai digitato.',
      timeout:
        'Hai digitato il tuo password falso piu volte. Aspetti fino il timer è terminato e puoi provare di muovo o per resettarlo  clicca "password dementicato.',
    },
    keep: 'Rimanere registrato per due giorni',
    tan: {
      subtitle:
        'Hai scelto un login sicuro con SMS-TAN. Chiedi un TAN e ti mandiamo un SMS con una conferma TAN {*********211}.',
      title: 'Autentificazione è necessario',
    },
    timeout: 'Tempo rimanente fino nuovo accesso del password',
    title: 'Login',
    existing_user_invite_title: 'Hai accettato un invito,fai login adesso.',
  },
  password: {
    change: {
      btnSubmit: 'Salvare il password e fare il login',
      passwordNew: 'Password nuovo',
      passwordNewConfirm: 'Conferma il password nuovo',
      text: 'Scegli p.f. il tuo password.',
      title: 'Cambiamento dal tuo password',
    },
    forgot: 'Password dementicato',
    requested: {
      text: 'Tra breve ottieni un email per confermare e creare il tuo password nuovo. per prudenza guarda anche nella  tua cartella SPAM.',
      title: 'hai resettato il tuo password con successo',
    },
    reset: {
      error:
        'Il link, che hai scelto è scaduto. per resettare il password puoi iniziare di nuovo la procedura qui.',
      text: 'Digita p.f.  il tuo indirizzo email, per resettare il tuo password.',
      title: 'Resettare il password',
    },
    strength: 'Forza dal password',
    success: {
      text: 'Resettare il password con successo',
      title: 'Resettare il password con successo',
    },
    title: 'Password',
  },
  registration: {
    disclaimer:
      'Con cio dichiaro il mio accordo del trattamento dei miei dati personali al scopo sopraesposto. Posso revocare il mio accordo intero o parziale con impatto per la futura via un email semplice (a: support@pengueen.de) o  postale (M.F.G. Pengueen UG (haftungsbeschränkt), Damsdorfer Hauptstraße 41A, 14797 Kloster Lehnin) in qualsiasi momento.',
    info: 'Per la versione di prova di 14 giorni - una carta di credito non è necessario',
    login: 'C`è l`hai gia un conto? ti iscrivi qui',
    new: 'Registrare nuovo',
    privacy1: 'Accetto i',
    privacy2: 'e i',
    title: 'Registrare',
    title_invite:
      'Hai accettato il tuo invito con successo. Ti registra adesso.',
    submit: 'Registrare adesso',
    success: {
      text: 'Ricevi presto un email per confermare il tuo conto. solo per sicurezza vedi anche nella tua cartella spam.',
      title: 'Ti hai registrato con successo',
    },
    validation: {
      disclaimer: 'Alla questa dichiarazione si deve consentire',
    },
  },
  trial: {
    expired: {
      admin: {
        button_payment_update: 'Aggiornare dati di pagamento adesso',
        text1:
          'Tu e gli tui utenti non possono editare testi al Pengueen al momento,',
        text2: 'Solo testi esistenti si puo vedere.',
        text3:
          'Ti piace Pengueen? solo digita gli tui informazioni di pagamento a',
        text4: 'Al profilo per utilizzare Pengueen ancora.',
        title: 'La tua versione di prova di 14 giorni è scaduto purtroppo',
      },
      button_contact: 'Contattare con noi',
      button_payment: 'Ai nostri condizioni di pagamento',
      user: {
        button_inform: 'Informare l´interlocutore adesso',
        text1:
          'A causa di impostazioni alla tua comunità, non puoi editare testi nuovi o usare funzioni.',
        text2: 'Solo testi esistenti si puo vedere.',
        text3:
          'Per utilizzare pengueen ancora completamente, farti sentire al tuo interlocutore della tua comunità.',
        title: 'Si puo vedere solo i tui testi al momento, ma non modificarli',
      },
    },
  },
  validation: {
    passwordChar1:
      'Criteri seguenti devone essere soddisfatti per il tuo password',
    passwordChar2: ' 8-cifre',
    passwordChar3: ' Almeno una',
    passwordChar4: 'maiuscola',
    passwordChar5: ' almeno un ',
    passwordChar6: 'numero',
    passwordChar7: ' almeno un ',
    passwordChar8: 'simbolo',
    passwordChar9: '',
    passwordChar:
      'La tua password deve contenere almeno 8 caratteri, contenere una lettera maiuscola, un numero e un carattere speciale.',
    passwordConfirm: 'Il password deve concordare.',
    passwordReq: 'Si deve digitare un passwordn.',
    passwordStrength: 'Forza del password',
    passwordCondition: 'Il password deve soddisfare tuuti i criteri.',
  },
};
