import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunctionResult } from 'i18next';
import { Box } from '@mui/material';
import { useFilePicker } from 'use-file-picker';

// Components
import { IconButton } from '../IconButton/IconButton';
import { ImageFallback } from '../../components/ImageFallback/ImageFallback';
import { FormFieldLabel } from '../FormFieldLabel/FormFieldLabel';
import { Loader } from '../Loader/Loader';

// Hooks
import { useBreakpoints } from '../../hooks/use-breakpoints.hook';

// Models
import { Attachment, ImageFallbackType } from '../../models/shared.types';

// Styles
import styles from './ImageChanger.module.scss';
import clsx from 'clsx';

type ImageChangrProps = {
  classes?: string;
  image?: Attachment;
  label?: string | TFunctionResult;
  loading?: boolean;
  optional?: boolean;
  sublabel?: string | TFunctionResult;
  type?: ImageFallbackType;
  onChange: (file: File) => void;
  onClear: () => void;
};

const ImageChanger = (props: ImageChangrProps) => {
  const { lgDown } = useBreakpoints();
  const [openFileSelector, { clear, filesContent, plainFiles }] = useFilePicker(
    {
      accept: ['.jpg', '.jpeg', '.png'],
      readAs: 'DataURL',
    }
  );
  const { t } = useTranslation();

  // Component state
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  // ####### //
  // EFFECTS //
  // ####### //

  // Detect selected file change
  useEffect(() => {
    if (plainFiles && plainFiles[0]) {
      if (plainFiles[0].name !== selectedFile?.name) {
        setSelectedFile(plainFiles[0]);
        // Emit image on selected file change
        props.onChange(plainFiles[0]);
      }
    }
    // eslint-disable-next-line
  }, [plainFiles]);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handler to clear image selection.
   */
  const onClear = useCallback(() => {
    clear();
    props.onClear();
  }, [clear, props]);

  return (
    <div
      className={clsx(styles['section-image'], props.classes && props.classes)}
    >
      {props.label && (
        <FormFieldLabel
          label={props.label}
          optional={props.optional}
          sublabel={props.sublabel}
        />
      )}
      <div
        className={clsx(
          styles['section-image-data'],
          styles['section-image-size'],
          props.label && styles['section-image-data-margin']
        )}
      >
        {props.image?.url || (filesContent && filesContent[0]) ? (
          <img
            alt="User Avatar"
            className={clsx(
              styles['section-image-data-icon'],
              styles['section-image-size']
            )}
            src={props.image?.url ?? filesContent[0].content}
          />
        ) : (
          <ImageFallback
            iconSize={lgDown ? '3em' : '4em'}
            sizeClassName={clsx(
              styles['section-image-data-icon'],
              styles['section-image-size']
            )}
            type={props.type ?? ImageFallbackType.Profile}
          />
        )}
        {props.loading && (
          <Box
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
            }}
            className={clsx(
              styles['section-image-data-loader'],
              styles['section-image-size']
            )}
          >
            <Loader color="white" />
          </Box>
        )}
        <IconButton
          classes={styles['section-image-data-actions-select']}
          icon={['fas', 'pen']}
          iconColor="text-app"
          preset="popover-app"
          sxIcon={{ padding: 0.25 }}
          onClick={() => openFileSelector()}
        />
        {(props.image?.url || filesContent.length > 0) && (
          <IconButton
            classes={styles['section-image-data-actions-delete']}
            icon={['fas', 'times']}
            iconColor="text-app"
            preset="popover-app"
            sxIcon={{ padding: 0.25 }}
            onClick={onClear}
          />
        )}
      </div>
      <Box
        sx={{ color: 'text.secondary' }}
        className={styles['section-image-types']}
      >
        {t('form.file.types.image')}
      </Box>
    </div>
  );
};

export default memo(ImageChanger);
