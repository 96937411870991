// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconSwitchButton_icon-switch-button__5ihCB {\n    display: flex;\n    min-width: 0px;\n    align-items: center;\n    border-radius: 0.5rem;\n    border-width: 1px;\n    border-style: solid;\n    padding: 0px;\n    text-transform: none\n}\n  .IconSwitchButton_icon-switch-button-icon__i3ATU {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 0.5rem;\n    padding-left: 0.75rem;\n    padding-right: 0.75rem;\n    padding-top: 0.5rem;\n    padding-bottom: 0.5rem\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;IAAA,cAAA;IAAA,eAAA;IAAA,oBAAA;IAAA,sBAAA;IAAA,kBAAA;IAAA,oBAAA;IAAA,aAAA;IAAA;CAAA;EAAA;IAAA,cAAA;IAAA,oBAAA;IAAA,wBAAA;IAAA,sBAAA;IAAA,sBAAA;IAAA,uBAAA;IAAA,oBAAA;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon-switch-button": "IconSwitchButton_icon-switch-button__5ihCB",
	"icon-switch-button-icon": "IconSwitchButton_icon-switch-button-icon__i3ATU"
};
export default ___CSS_LOADER_EXPORT___;
