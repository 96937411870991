import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// Components
import { Loader } from '../shared/ui/Loader/Loader';

// Models
import { TemplateCreateEditDetails } from '../modules/templates/models/templates.types';

// Lazy-load components
const TemplateSummary = lazy(
  () =>
    import('../modules/templates/components/TemplateSummary/TemplateSummary')
);

// Lazy-load pages
const Template = lazy(
  () => import('../modules/templates/pages/Template/Template')
);
const Tool = lazy(() => import('../modules/tools/pages/Tool/Tool'));
const Workbench = lazy(
  () => import('../modules/templates/pages/Workbench/Workbench')
);

export const ToolsRouter = () => {
  return (
    <Routes>
      <Route
        path=":id"
        element={
          <Suspense fallback={<Loader />}>
            <Tool />
          </Suspense>
        }
      />
      <Route
        path=":id/haschat"
        element={
          <Suspense fallback={<Loader />}>
            <Tool haschat />
          </Suspense>
        }
      />
      <Route
        path="/workbench"
        element={
          <Suspense fallback={<Loader />}>
            <Workbench />
          </Suspense>
        }
      />
      <Route
        path="workbench/:id"
        element={
          <Suspense fallback={<Loader />}>
            <Template full_height component={<TemplateSummary />} />
          </Suspense>
        }
      />
      <Route
        path="workbench/:id/print"
        element={
          <Suspense fallback={<Loader />}>
            <Template component={<TemplateSummary printView />} printView />
          </Suspense>
        }
      />
      {/* section-create */}
      <Route
        path="workbench/:id/section/create/:createId"
        element={
          <Suspense fallback={<Loader />}>
            <Template
              component={<TemplateSummary />}
              createEditDetails={TemplateCreateEditDetails.SectionCreate}
            />
          </Suspense>
        }
      />
      <Route
        path="workbench/:id/section/:sectionId"
        element={
          <Suspense fallback={<Loader />}>
            <Template
              component={<TemplateSummary />}
              createEditDetails={TemplateCreateEditDetails.SectionUpdate}
            />
          </Suspense>
        }
      />
      {/* element-create */}
      <Route
        path="workbench/:id/section/:sectionId/element/create/:createId"
        element={
          <Suspense fallback={<Loader />}>
            <Template
              full_height
              component={<TemplateSummary />}
              createEditDetails={TemplateCreateEditDetails.ElementCreate}
            />
          </Suspense>
        }
      />
      {/* child-element-create */}
      <Route
        path="workbench/:id/section/:sectionId/element/:elementId/create/:createChildId"
        element={
          <Suspense fallback={<Loader />}>
            <Template
              full_height
              component={<TemplateSummary />}
              createEditDetails={TemplateCreateEditDetails.ElementChildCreate}
            />
          </Suspense>
        }
      />
      <Route
        path="workbench/:id/section/:sectionId/element/:elementId"
        element={
          <Suspense fallback={<Loader />}>
            <Template
              full_height
              component={<TemplateSummary />}
              createEditDetails={TemplateCreateEditDetails.ElementUpdate}
            />
          </Suspense>
        }
      />
      <Route
        path="workbench/:id/section/:sectionId/element/:elementId/child/:childId"
        element={
          <Suspense fallback={<Loader />}>
            <Template
              full_height
              component={<TemplateSummary />}
              createEditDetails={TemplateCreateEditDetails.ElementChildUpdate}
            />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
