import { memo, ReactNode, Suspense, useRef } from 'react';
import { TFunctionResult } from 'i18next';
import { Box } from '@mui/material';
import clsx from 'clsx';

// Components
import { Loader } from '../../../../shared/ui/Loader/Loader';
import { UserInfo } from '../UserInfo/UserInfo';

// Router
import { UserRouter } from '../../../../router/UserRouter';

// Styles
import styles from './UserSettingsContainer.module.scss';
import { PageLayout } from '../../../../shared/components/PageLayout/PageLayout';

type UserSettingsContainerProps = {
  children: ReactNode;
  classes?: string;
  subtitle: TFunctionResult | string;
  title: TFunctionResult | string;
};

export const UserSettingsContainer = (props: UserSettingsContainerProps) => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
      }}
      className={clsx(
        styles['user-settings-container'],
        props.classes && props.classes
      )}
    >
      <Box
        sx={{
          borderColor: 'border.app',
        }}
        className={styles['user-settings-container-header']}
      >
        <div className={styles['user-settings-container-header-title']}>
          {props.title}
        </div>
        <Box sx={{ color: 'text.secondary' }}>{props.subtitle}</Box>
      </Box>
      {props.children}
    </Box>
  );
};

const UserSettingsPage = () => {
  // Refs
  const navRef = useRef<HTMLDivElement | null>(null);

  // Set nav height on component mount
  // useEffect(() => {
  //   if (navRef.current) {
  //     const height = window.innerHeight;
  //     if (height < 560 + 130) {
  //       navRef.current.style.height = 'calc(100vh - 129px)';
  //     } else {
  //       navRef.current.style.height = '560px';
  //     }
  //   }
  // }, []);

  return (
    <>
      <PageLayout
        leftComponent={
          <Box className={styles['user-settings-container']} ref={navRef}>
            <UserInfo />
          </Box>
        }
        rightComponent={
          <Suspense fallback={<Loader />}>
            <UserRouter />
          </Suspense>
        }
      />
    </>
    // <div className={styles['user-settings-page']}>
    //   <div className={styles['user-settings-page-nav']}></div>
    //   <Box
    //     className={styles['user-settings-page-nav-container']}
    //     sx={{ backgroundColor: 'background.paper' }}
    //     ref={navRef}
    //   >
    //     <UserInfo />
    //   </Box>
    //   <div className={styles['user-settings-page-content']}>
    //     <Suspense fallback={<Loader />}>
    //       <UserRouter />
    //     </Suspense>
    //   </div>
    // </div>
  );
};

export default memo(UserSettingsPage);
