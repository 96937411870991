import { ContactsTranslations } from '../models/contacts.translations.interface';

export const ContactsTranslationsIT: ContactsTranslations = {
  all: 'Tutti i contatti ',
  all_loading: 'Carica tutti i contatti',
  toolbar: {
    export: 'Esporta elenco come .csv',
    show: {
      navigation: 'Mostra la navigazione',
    },
    filter: {
      search: 'Cercare',
    },
    community: {
      add_group: 'Creare un gruppo',
      edit_group: 'Salvare il gruppo',
      add_tool: 'Scelta degli strumenti',
      add_tool1: 'Strumento',
      add_tool2: 'La scelta',
      select_tool: 'Scegli un o piu strumenti, che vuoi assegnare',
    },
    group: {
      success: {
        singular: '{{userName}} veniva aggiunto con successo',
        plural: '{{userName}} venivano aggiunto con successo',
      },
      setting: 'Impostazioni',
      add_person: 'Aggiungere persone',
      invite_person: 'Invitare persone',
      create: 'Il tuo gruppo veniva creato con successo',
    },
    invite: {
      community: 'Scegliere comunità',
      group: 'Scegliere gruppo',
      tool: 'Scegliere strumento',
    },
    label: {
      community: 'Lavorate insieme ad una comunità?',
      group:
        'Aggiungi questi utenti direttamente ai gruppi esistenti (facoltativo)',
      tool: 'Crea nuovi strumenti individuali per questi utenti (facoltativo)',
    },
    link: {
      title: 'Link all`attivazione',
    },
  },
  nav: {
    no_community: {
      group: {
        title: 'Gruppi senza comunità',
      },
    },
    footer: {
      title: 'Comunicazione e organisazione nel team!',
      content:
        'Puoi creare comunità, per usare con amici, colleghi, clienti, membri questo contatto .. assegnati gli strumenti insieme e scambiarvi.',
      button: 'Lavore insieme',
    },
  },
  delete: {
    this_contact: 'Questo contatto',
    this_contact_big: 'Questo contatto',
    alert: {
      text1: 'Sei sicuro/a, che tu',
      text2: 'Vuoi rimuovere dalla tua lista dei contatti?',
    },
    notification: {
      result: {
        title: '{{userName}} veniva rimosso dai tui contatti',
      },
      room: {
        title1: 'I tui messaggi vengono',
        this_contact: 'Questo contatto',
        title2: 'Cancellato',
      },
      community: {
        title1: 'Viene da',
        title2: 'Rimosso dai gruppi',
      },
    },
  },
  teaser: {
    invite: 'Invitare i contatti',
    group: 'Contatti gruppi',
  },
  actions: {
    add: 'Aggiungere ai miei contatti',
    message: 'Lettera',
    reinvite: 'Invitare di nuovo',
    view: 'Visualizzare',
  },
  add: {
    error: 'Al aggiungere è sorto un errore',
    success: 'Invitare contatto',
  },
  community: {
    title: 'Comunità',
  },
  connection: {
    direct: 'Diretto',
    indirect: 'Indiretto',
    title: 'Connessione a me',
  },
  detail: {
    tools: {
      add: 'Aggiungi',
      title: 'Strumenti allocato',
    },
    tooltip: {
      edit_nickname: 'Modifica nickname',
      upgrade: 'Aggiornamento',
      downgrade: 'declassamento',
    },
  },
  filters: {
    button: 'Visualizzazione',
    search: 'Cercare al nome',
    title: 'Filtro nuovo',
  },
  invite: {
    reinvite: 'Ripetere l`invito',
    error: {
      emails: {
        multi: 'C` è l`hai utenti con indirizzi email',
        single: 'C` è l`hai l` utente con l`indirizzo email',
        text: 'è invitato gia ai tui contatti',
      },
    },
    info: 'Per persone, ci hai invitato via indirizzo email, vale: mandiamo un email alla personana invitatoe. Puoi scrivere messaggi anche prima. Lui o lei puo accedere automatico ai messaggi, appena log-in  è concluso e l`email veniva confermato.',
    submit: 'manda invito',
    text: 'Invita nuovi utenti a Pengueen.',
    title: 'Invita',
  },
  role: {
    title: 'Ruolo allocato',
  },
  state: {
    active: 'Attivo',
    declined: 'Declinato',
    indirect: 'Indiretto',
    invited: 'Invitato',
    requested: 'Chiesto',
    expired: 'Scaduto',
    title: 'Stato',
  },
  title_page: 'I tui contatti',
  unapproved: 'Non approvato',
  view_on_dashboard: 'Guarda al dashboard',
  title: 'Contatti',
  tools_empty: 'Nessuno strumento disponibile!',
};
