import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useUsersHttp } from '../../hooks/use-users-http.hook';

export const EmailConfirm = () => {
  // Extract id and email
  const { id } = useParams<'id'>();
  const { email } = useParams<'email'>();
  const { language } = useParams<'language'>();

  const { updateUserEmailSave } = useUsersHttp();
  const navigate = useNavigate();

  const { handleRetry } = useFetch();

  const updateUserEmailSaveMutation = useMutation(
    (data: { id: string; email: string; language: string }) =>
      updateUserEmailSave(data)
  );

  // UPDATE user email on component mount
  useEffect(() => {
    if (id && email && language) {
      updateUserEmailSaveMutation.mutate({ id, email, language });
    }
    // eslint-disable-next-line
  }, [id, email]);

  // navigate to dashboard
  useEffect(() => {
    if (updateUserEmailSaveMutation.data) {
      navigate('/dashboard');
    }
    if (updateUserEmailSaveMutation.error) {
      // const errRes = updateUserEmailSaveMutation.error?.response;
      // // Check if error contains fetch response object
      // if (errRes) {
      //   console.log('ERROR on updateUserEmailSaveMutation: ', errRes.status);
      //   // navigate('/dashboard');
      // }
    }
    // eslint-disable-next-line
  }, [updateUserEmailSaveMutation.data, updateUserEmailSaveMutation.error]);

  return <div></div>;
};
