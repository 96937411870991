import create, { State } from 'zustand';

// Models
import {
  CommunityNav,
  Contact,
  ContactNavGroup,
  ContactsGetFilters,
} from '../models/contacts.types';
import { ToolFavorite } from '../../dashboard/models/dashboard.types';
import { Room } from '../../newscenter/models/news-center.types';

export interface ContactsState extends State {
  allContacts: Contact[];
  contacts: Contact[];
  contactDeleteId: string | undefined;
  navCommunities: CommunityNav[];
  communityTools: ToolFavorite[];
  dataLoading: boolean;
  dialogInvite: boolean;
  groupChats: Room[];
  selectedNavGroups: ContactNavGroup[];
  seletedTools: string[];
  selectedContactId: string | undefined;
  selectedContactReinvite: Contact | undefined;
  selectedUsers: any[];
  setAllContacts: (allContacts: Contact[]) => void;
  setContacts: (contacts: Contact[]) => void;
  setContactDeleteId: (contactDeleteId: string | undefined) => void;
  setNavCommunities: (navCommunities: CommunityNav[]) => void;
  setCommunityTools: (communityTools: ToolFavorite[]) => void;
  setDataLoading: (dataLoading: boolean) => void;
  setDialogInvite: (dialogInvite: boolean) => void;
  setGroupChats: (groupChats: Room[]) => void;
  setSelectedNavGroups: (selectedNavGroups: ContactNavGroup[]) => void;
  setSelectedTools: (seletedTools: string[]) => void;
  setSelectedContactId: (selectedContactId: string | undefined) => void;
  setSelectedContactReinvite: (contact: Contact | undefined) => void;
  setSelectedUsers: (selectedUsers: any[]) => void;
}

export const useContactsStore = create<ContactsState>((set) => ({
  allContacts: [],
  contacts: [],
  contactDeleteId: undefined,
  navCommunities: [],
  communityTools: [],
  dataLoading: false,
  dialogInvite: false,
  groupChats: [],
  selectedNavGroups: [],
  seletedTools: [],
  selectedUsers: [],
  selectedContactId: undefined,
  selectedContactReinvite: undefined,
  setAllContacts: (allContacts: Contact[]) => set({ allContacts }),
  setContacts: (contacts: Contact[]) => set({ contacts }),
  setContactDeleteId: (contactDeleteId: string | undefined) =>
    set({ contactDeleteId }),
  setNavCommunities: (navCommunities: CommunityNav[]) =>
    set({ navCommunities }),
  setCommunityTools: (communityTools: ToolFavorite[]) =>
    set({ communityTools }),
  setDataLoading: (dataLoading: boolean) => set({ dataLoading }),
  setDialogInvite: (dialogInvite: boolean) => set({ dialogInvite }),
  setGroupChats: (groupChats: Room[]) => set({ groupChats }),
  setSelectedTools: (seletedTools: string[]) => set({ seletedTools }),
  setSelectedNavGroups: (selectedNavGroups: ContactNavGroup[]) =>
    set({ selectedNavGroups }),
  setSelectedContactId: (selectedContactId: string | undefined) =>
    set({ selectedContactId }),
  setSelectedContactReinvite: (selectedContactReinvite: Contact | undefined) =>
    set({ selectedContactReinvite }),
  setSelectedUsers: (selectedUsers: any[]) => set({ selectedUsers }),
}));
