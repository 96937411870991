import { ReactNode, useCallback, useEffect, useState } from 'react';
import { TFunctionResult } from 'i18next';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { Box, Snackbar as MuiSnackbar } from '@mui/material';

// Components
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';

// Models
import { ResultState } from '../../models/shared.types';

// Stores
import { useSharedStore } from '../../stores/use-shared.store';

// Styles
import styles from './Snackbar.module.scss';

type SnackbarContentProps = {
  state?: ResultState;
  subtitle?: string | TFunctionResult | ReactNode;
  title: string | TFunctionResult;
  onClose: () => void;
};

const SnackbarContent = (props: SnackbarContentProps) => {
  const [icon, setIcon] = useState<
    | {
        color: 'error' | 'info' | 'warning' | 'success';
        icon: [IconPrefix, IconName];
      }
    | undefined
  >(undefined);

  // Set icon color on mount
  useEffect(() => {
    switch (props.state) {
      case ResultState.Error:
        setIcon({ color: 'error', icon: ['fas', 'exclamation-triangle'] });
        break;
      case ResultState.Info:
        setIcon({ color: 'info', icon: ['fas', 'info'] });
        break;
      case ResultState.Warning:
        setIcon({ color: 'warning', icon: ['fas', 'exclamation-triangle'] });
        break;
      default:
        setIcon({ color: 'success', icon: ['fas', 'check'] });
        break;
    }
  }, [props.state]);

  return (
    <>
      {props.title && (
        <div className={styles['snackbar-content']}>
          <div className={styles['snackbar-content-info']}>
            {icon && (
              <Box
                className={styles['snackbar-content-info-icon']}
                sx={{ backgroundColor: `${icon.color}.light` }}
              >
                <Icon
                  color={icon.color}
                  icon={icon.icon}
                  sx={{ padding: 0.25 }}
                />
              </Box>
            )}
            <div className={styles['snackbar-content-info-text']}>
              <div className={styles['snackbar-content-info-text-title']}>
                {props.title}
              </div>
              {props.subtitle && (
                <Box
                  className={styles['snackbar-content-info-text-subtitle']}
                  sx={{ color: 'text.secondary' }}
                >
                  {props.subtitle}
                </Box>
              )}
            </div>
          </div>
          <IconButton
            classes={styles['snackbar-content-close']}
            icon={['fas', 'times']}
            onClick={props.onClose}
          />
        </div>
      )}
    </>
  );
};

export const Snackbar = () => {
  const [open, setOpen] = useState<boolean>(false);

  // Shared store state
  const [notification, setNotification] = useSharedStore((state) => [
    state.notification,
    state.setNotification,
  ]);

  // Open snackbar on notification
  useEffect(() => {
    notification?.title && setOpen(true);
  }, [notification?.title]);

  /**
   * Handler to close notification.
   */
  const onClose = useCallback(
    () => {
      setOpen(false);
      // Wait for animation
      setTimeout(() => {
        setNotification(undefined);
      }, 500);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <MuiSnackbar
      autoHideDuration={notification?.timeout ?? 5000}
      classes={{
        root: styles['snackbar'],
      }}
      message={
        <SnackbarContent
          subtitle={notification?.subtitle}
          title={notification?.title}
          state={notification?.state}
          onClose={onClose}
        />
      }
      open={open ? true : false}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'background.paper',
          color: 'text.primary',
          display: 'flex',
          alignItems: 'flex-start',
        },
      }}
      onClose={onClose}
    />
  );
};
