// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconTextButton_icon-text-button__1ZnKM {\n    display: flex;\n    align-items: center\n}\n  .IconTextButton_icon-text-button-disabled__IaxF7 {\n    opacity: 0.25\n}\n  .IconTextButton_icon-text-button-presets-none__iIvcl {\n    border-radius: 0.375rem\n}\n  .IconTextButton_icon-text-button-presets-tools__Cj6g- {\n    border-radius: 0.5rem\n}\n  .IconTextButton_icon-text-button-text__LgbN6 {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;\n    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;\n    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;\n    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n    transition-duration: 150ms\n}\n  .IconTextButton_icon-text-button__1ZnKM .IconTextButton_MuiSvgIcon-root__yBB6s {\n    transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;\n    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;\n    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;\n    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n    transition-duration: 150ms\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;IAAA,cAAA;IAAA;CAAA;EAAA;IAAA;CAAA;EAAA;IAAA;CAAA;EAAA;IAAA;CAAA;EAAA;IAAA,iBAAA;IAAA,wBAAA;IAAA,oBAAA;IAAA,wGAAA;IAAA,gGAAA;IAAA,+HAAA;IAAA,yDAAA;IAAA;CAAA;EAAA;IAAA,wGAAA;IAAA,gGAAA;IAAA,+HAAA;IAAA,yDAAA;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon-text-button": "IconTextButton_icon-text-button__1ZnKM",
	"icon-text-button-disabled": "IconTextButton_icon-text-button-disabled__IaxF7",
	"icon-text-button-presets-none": "IconTextButton_icon-text-button-presets-none__iIvcl",
	"icon-text-button-presets-tools": "IconTextButton_icon-text-button-presets-tools__Cj6g-",
	"icon-text-button-text": "IconTextButton_icon-text-button-text__LgbN6",
	"MuiSvgIcon-root": "IconTextButton_MuiSvgIcon-root__yBB6s"
};
export default ___CSS_LOADER_EXPORT___;
