// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResultMessage_result-message__sfKum {\n\n  display: flex;\n\n  align-items: center;\n\n  padding: 0.25rem;\n\n  font-size: 0.75rem;\n\n  line-height: 1rem }\n\n@media (min-width: 1024px) {\n  .ResultMessage_result-message__sfKum {\n\n    padding: 0.5rem } }\n", "",{"version":3,"sources":["<no source>","webpack://./src/shared/ui/ResultMessage/ResultMessage.module.scss"],"names":[],"mappings":"AAAA;;EAAA,cAAA;;EAAA,oBAAA;;EAAA,iBAAA;;EAAA,mBAAA;;EAAA,mBAAA;;ACMA;EDNA;;IAAA,iBAAA,CCSG","sourcesContent":[null,"@import '../../../styles/breakpoints.scss';\n\n.result-message {\n  @apply flex items-center p-1 text-xs;\n}\n\n@media (min-width: $lg) {\n  .result-message {\n    @apply p-2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"result-message": "ResultMessage_result-message__sfKum"
};
export default ___CSS_LOADER_EXPORT___;
