import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TFunctionResult } from 'i18next';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
} from '@mui/material';

// Components
import { Icon } from '../../../../shared/ui/Icon/Icon';

// Models
import {
  UserInfoState,
  UserLinkChild as IUserLinkChild,
} from '../../models/user.types';

// Stores
import { useUserStore } from '../../stores/use-user.store';

// Styles
import styles from './UserLink.module.scss';

// Assets
import pengueen from '../../../../assets/logo/pengueen_small.png';

type UserLinkChildProps = {
  child: IUserLinkChild;
  color: string;
  link: string;
  state: UserInfoState;
  onClick?: () => void;
};

export const UserLinkChild = (props: UserLinkChildProps) => {
  const navigate = useNavigate();
  const params = useParams();

  // Component state
  const [active, setActive] = useState<boolean>(false);

  // User store state
  const [setSettingsActive] = useUserStore((state) => [
    state.setSettingsActive,
  ]);

  // ####### //
  // EFFECTS //
  // ####### //

  // Set active child on if params id match
  useEffect(() => {
    if (params['*'] && params['*'].includes(props.child.id)) {
      setActive(true);
    } else if (active) {
      setActive(false);
    }
  }, [params, props.child.id, active]);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handler to click user link child button.
   */
  const onClickChild = useCallback(
    (id: string) => {
      setSettingsActive(props.state);
      navigate(`${props.link}/${id}`);
      props.onClick && props.onClick();
    },
    // eslint-disable-next-line
    [props.state, props.onClick]
  );

  return (
    <Button
      className={styles['user-link-child']}
      color="inherit"
      onClick={() => onClickChild(props.child.id)}
    >
      <img
        alt="Community Logo"
        className={styles['user-link-child-logo']}
        src={props.child.logo?.url ?? pengueen}
      />
      <Box
        className={styles['user-link-child-name']}
        sx={{ color: active ? `${props.color}.main` : 'text.primary' }}
      >
        {props.child.title}
      </Box>
    </Button>
  );
};

type UserLinkProps = {
  active?: boolean;
  chldrn?: IUserLinkChild[];
  subChldrn?: IUserLinkChild[];
  icon: [IconPrefix, IconName];
  link: string;
  subtitle: TFunctionResult;
  state: UserInfoState;
  title: TFunctionResult;
};

export const UserLink = (props: UserLinkProps) => {
  const navigate = useNavigate();

  // Component state
  const [color, setColor] = useState<'primary' | 'secondary'>('primary');

  // User store state
  const [setDrawer, setSettingsActive] = useUserStore((state) => [
    state.setDrawer,
    state.setSettingsActive,
  ]);

  // Set color by user info state on component mount
  useEffect(() => {
    if (props.state === UserInfoState.Community) {
      setColor('secondary');
    }
  }, [props.state]);

  /**
   * Handler to click user link button.
   */
  const onClick = useCallback(() => {
    setSettingsActive(props.state);
    navigate(props.link);
    setDrawer(false);
    // eslint-disable-next-line
  }, [props.link, props.state]);

  return (
    <>
      {props.chldrn && props.chldrn.length > 0 ? (
        <Accordion
          className={styles['user-link-accordion']}
          defaultExpanded
          disableGutters
          elevation={0}
          sx={{
            backgroundColor: props.active ? 'bg.card' : 'transparent',
            '&:hover .MuiAccordionSummary-root': {
              backgroundColor: 'bg.card',
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              <Icon
                classes={styles['user-link-accordion-summary-expand-icon']}
                icon={['fas', 'chevron-down']}
                sx={{
                  color: props.active ? `${color}.main` : 'text.primary',
                }}
              />
            }
            sx={{
              color: props.active ? `${color}.main` : 'text.primary',
              '&:hover': {
                backgroundColor: 'bg.hover',
              },
              '& .MuiAccordionSummary-content': {
                alignItems: 'center',
                display: 'flex',
                margin: 0,
              },
            }}
            className={styles['user-link-accordion-summary']}
          >
            <Icon
              classes={styles['user-link-icon']}
              color={props.active ? color : undefined}
              icon={props.icon}
              sx={{ marginLeft: '4px' }}
            />
            <Box
              sx={{ color: props.active ? `${color}.main` : 'text.primary' }}
              className={styles['user-link-text']}
            >
              {props.title}
            </Box>
          </AccordionSummary>
          <AccordionDetails className={styles['user-link-accordion-details']}>
            {props.chldrn.map((child) => (
              <UserLinkChild
                key={child.id}
                child={child}
                color={color}
                link={props.link}
                state={props.state}
              />
            ))}
            {props.subChldrn && (
              <>
                <Divider />
                {props.subChldrn.map((child) => (
                  <UserLinkChild
                    key={child.id}
                    child={child}
                    color={color}
                    link={props.link}
                    state={props.state}
                  />
                ))}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Button
          color="inherit"
          onClick={onClick}
          className={styles['user-link']}
          sx={{
            backgroundColor: props.active ? 'bg.card' : 'inherit',
            '&:hover': {
              backgroundColor: 'bg.card',
            },
          }}
        >
          <Icon
            classes={styles['user-link-icon']}
            color={props.active ? color : undefined}
            icon={props.icon}
          />
          <Box
            sx={{ color: props.active ? `${color}.main` : 'text.primary' }}
            className={styles['user-link-text']}
          >
            {props.title}
          </Box>
        </Button>
      )}
    </>
  );
};
