import { useEffect, useState } from 'react';
import { TFunctionResult } from 'i18next';
import {
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  SxProps,
  Theme,
  Tooltip,
} from '@mui/material';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';

// Components
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';
import { IconTextButton } from '../IconTextButton/IconTextButton';

// Models
import { MenuItem } from '../../models/shared.types';

// Styles
import styles from './Menu.module.scss';

type MenuProps = {
  active?: boolean;
  buttonClasses?: string;
  buttonIcon?: [IconPrefix, IconName];
  buttonPadding?: string;
  buttonSx?: SxProps<Theme>;
  buttonText?: string | TFunctionResult;
  disabled?: boolean;
  iconSize?: 'small' | 'medium' | 'large' | 'inherit';
  items: MenuItem[];
  preset?: 'popover' | 'primary' | 'secondary' | 'card-paper-white' | undefined;
  showChevron?: boolean;
  onClick?: (action: any) => void;
  onOpen?: (open: boolean) => void;
};

export const Menu = (props: MenuProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null | undefined>(null);
  const open = Boolean(anchorEl);

  // Submit open state outside component
  useEffect(() => {
    props.onOpen && props.onOpen(open);
  }, [open, props]);

  /**
   * Handle menu item action.
   * @param action Action
   */
  const onAction = (action: string) => {
    onClose();
    props.onClick && props.onClick(action);
  };

  /**
   * Close menu.
   */
  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {props.buttonText ? (
        <>
          <IconTextButton
            classes={clsx(
              styles['menu-button'],
              props.buttonClasses && props.buttonClasses
            )}
            active={props.active}
            disabled={props.disabled}
            flexDirection={props.showChevron ? 'row' : 'row-reverse'}
            icon={props.buttonIcon ?? ['fas', 'chevron-down']}
            padding={props.buttonPadding ?? '0.5rem 0.75rem'}
            preset={props.preset ?? 'popover'}
            sx={{
              ...props.buttonSx,
              '.MuiSvgIcon-root': {
                width: '0.75rem',
              },
            }}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            {props.buttonText}
            {props.showChevron && (
              <Icon
                classes={styles['menu-button-icon']}
                sx={{ ...props.buttonSx }}
                icon={['fas', 'chevron-down']}
              />
            )}
          </IconTextButton>
        </>
      ) : (
        <IconButton
          active={props.active}
          classes={props.buttonClasses && props.buttonClasses}
          disabled={props.disabled}
          icon={props.buttonIcon ?? ['fas', 'ellipsis-v']}
          iconSize={props.iconSize ?? 'small'}
          padding={props.buttonPadding}
          preset={props.preset ?? 'popover'}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        />
      )}
      <MuiMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        classes={{
          list: styles['menu-list-root'],
          paper: styles['menu-paper-root'],
        }}
        open={open}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        className={styles['menu']}
      >
        {props.items.map((item, index: number) =>
          item.tooltip ? (
            <Tooltip
              key={index}
              title={item.tooltip}
              placement={item.tooltip_placement}
            >
              <MuiMenuItem
                classes={{
                  root: styles['menu-item-root'],
                }}
                disabled={item.disabled}
                key={index}
                sx={{
                  color: 'text.secondary',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: 'text.secondary',
                  },
                }}
                onClick={() => onAction(item.action)}
              >
                {item.icon && (
                  <Icon
                    classes={styles['menu-item-icon']}
                    size="inherit"
                    icon={item.icon}
                  />
                )}
                {item.title}
              </MuiMenuItem>
            </Tooltip>
          ) : (
            <MuiMenuItem
              classes={{
                root: styles['menu-item-root'],
              }}
              disabled={item.disabled}
              key={index}
              sx={{
                '&:hover': {
                  backgroundColor: 'primary.light',
                  color: 'primary.main',
                },
              }}
              onClick={() => onAction(item.action)}
            >
              {item.icon && (
                <Icon
                  classes={styles['menu-item-icon']}
                  size="inherit"
                  icon={item.icon}
                />
              )}
              {item.title}
            </MuiMenuItem>
          )
        )}
      </MuiMenu>
    </>
  );
};
