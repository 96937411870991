// Models
import {
  Address,
  Attachment,
  Avatar,
  CreateChangeInfo,
  Role,
} from '../../../shared/models/shared.types';
import {
  CommunityData,
  CommunityUserRole,
} from '../../communities/models/communities.types';
import { ToolFavorite } from '../../dashboard/models/dashboard.types';
import { Room } from '../../newscenter/models/news-center.types';
import { ToolPrivateEditReadLink } from '../../tools/models/tools.types';

export enum ActiveState {
  Declined = 'DECLINED',
  Indirect = 'INDIRECT',
  Requested = 'REQUESTED',
  Active = 'ACTIVE',
  Invited = 'INVITED',
  Expired = 'EXPIRED',
  ToolLink = 'TOOLLINK',
}

export enum ContactAction {
  Add = 'ADD',
  Community = 'COMMUNITY',
  Message = 'MESSAGE',
  Reinvite = 'REINVITE',
  View = 'VIEW',
  Delete = 'DELETE',
  Upgrade = 'UPGRADE',
  Downgrade = 'DOWNGRADE',
}

export enum ConnectionType {
  Direct = 'DIRECT',
  Indirect = 'INDIRECT',
  Community = 'COMMUNITY',
  Group = 'GROUP',
  Admin = 'ADMIN',
}

export enum ContactsViewType {
  Community = 'COMMUNITY',
  Direct = 'DIRECT',
  Group = 'GROUP',
  AddPersonNetwork = 'ADD_PERSON_NETWORK',
  Tool = 'TOOL',
  ToolEdit = 'TOOL_EDIT',
}
export enum ContactsViewNavType {
  NetworkNavLeft = 'NETWORK_NAV_LEFT',
  NetworkNavRight = 'NETWORK_NAV_RIGHT',
}
export enum ContactCommunityGetAction {
  None = 'NONE',
  All = 'ALL',
  Community = 'COMMUNITY',
  Member = 'MEMBER',
  Employee = 'EMPLOYEE',
  Admin = 'ADMIN',
  Group = 'GROUP',
  Folder = 'FOLDER',
  FolderGroup = 'FOLDER_GROUP',
  CommunityLink = 'COMMUNITY_LINK',
  ToolLink = 'TOOL_LINK',
  Room = 'ROOM',
}

export enum ContactSelectOption {
  NoSelect = 'NO_SELECT',
}

export enum ContactCommunityIdType {
  All = 'ALL',
  None = 'NONE',
  Community = 'COMMUNITY',
  NoCommunity = 'NO_COMMUNITY',
}

export enum AddContactsFavoriteToolsType {
  User = 'USER',
  Group = 'GROUP',
}

export interface AccordionExpansionState {
  [key: string]: boolean;
}

export interface AccordionExpandedRequest {
  id: string; // folder or community id
  is_expanded: boolean;
  is_mobile: boolean;
}

export interface AccordionExpandedGet {
  is_mobile: boolean;
}

export interface ContactNavGroup {
  itemName: string;
  type: ContactCommunityGetAction;
  params: ContactsGetFilters;
}

export interface ContactDeleteResponse {
  deletedUserName: string;
  deletedUserId: string;
  deletedRoomId?: string;
  deletedFromCommunities?: string[];
  deletedCommunitiesGroups?: string[];
  deletedCommunitiesTools?: string[];
  removedFromGroups?: string[];
}

export interface Contact {
  id: string; // account.id
  address?: Address; // profile.address;
  avatar?: Attachment; // profile.avatar
  direct_chat_id?: string;
  email?: string; // account.email
  community?: Pick<CommunityData, 'id' | 'logo' | 'name'>;
  name?: string;
  first_name?: string; // profile.personal_data.first_name
  last_name?: string; // profile.personal_data.last_name
  nickname?: string; // Temporary name if ActiveState.Invited
  phone?: string; // profile.personal_data.phone
  connection: ConnectionType;
  state: ActiveState;
  role?: Role;
  invitor?: boolean;
  // tool_informations?: ToolInformationDTO[] ;
  tool_informations?: any[];
  tools?: ToolFavorite[];
}

export interface ToolInformationDTO {
  id: string;
  name: string;
  description: string;
  information: string;
}

export interface ContactBaseData {
  title: string;
  vaule: String[];
}

export interface ContactDetailTool {
  id: string;
  category: string;
  create_change_info: CreateChangeInfo;
  image_preview?: Attachment;
  name: string;
}

export interface ContactInvite {
  id?: string;
  email: string;
  nickname?: string;
  community?: string;
  groups?: string[];
  tools?: string[];
}

export interface CommunityNav {
  id: string;
  avatar?: Attachment;
  name: string;
  // groups?: Room[];
  groups?: CommunityGroup[]; // Groups not in folder
  folders?: CommunityFolder[];
  community_links?: Communitylink[];
  tool_links?: ToolLink[];
  employee_name?: string;
  member_name?: string;
  member_role?: CommunityUserRole;
}

export interface ContactsGetResponse {
  contacts: Contact[];
  community: ContactCommunity; //#TODO change to (community: ContactCommunity;)
  extraHeaders?: string[];
}

export interface ContactCommunity {
  id: string | ContactCommunityIdType;
  name: string;
  avatar?: Attachment;
  tools?: ToolFavorite[];
  groups?: CommunityGroup[];
  folders?: CommunityFolder[];
  selected_group?: CommunityGroup;
  selected_folder?: CommunityFolder;
  selected_tool?: ToolFavorite;
  member_role?: CommunityUserRole;
  member_link?: string;
  employee_link?: string;
  admin_link?: string;
  logo?: Avatar;
}

export interface CommunityFolder {
  id: string;
  name: string;
  groups: CommunityGroup[];
  // #TODO add more values
}

export interface CommunityGroup {
  id: string | CommunityUserRole;
  name: string;
  description: string;
  room_has_msgs?: boolean; // message_function
  no_include_creator?: boolean;
  chat_room?: {
    id: string;
  };
  favorite?: boolean;
  folders?: CommunityFolder[];
  community?: { id: string; name: string; logo?: Attachment };
  community_id?: { id: string; name: string; logo?: Attachment };
  link?: string;
  creator_id?: any;
  tags?: CommunityFolder[];
  // chat_room?: Room;
  // #TODO add more values
}

export interface GetContactGroupsFoldersResponse {
  groups: CommunityGroup[];
  folders: CommunityFolder[];
}

export interface ContactOptions {
  communities?: ContactCommunity[];
  groups?: CommunityGroup[];
  tools?: ToolFavorite[];
}

export interface Communitylink {
  url: string;
  name: string;
}

export interface ToolLink {
  url: string;
  name: string;
}

export interface ContactsGetFilters {
  exclude_community_id?: string;
  exclude_direct_chats?: boolean;
  exclude_group_id?: string;
  exclude_tool_id?: string;
  link_id?: string;
  group_id?: string;
  folder_id?: string;
  community_id?: string;
  community_id_member?: string;
  community_id_admin?: string;
  community_id_employee?: string;
  community_id_group?: string;
  community_id_folder?: string;
  community_id_community_link?: string;
  community_id_tool_link?: string;
  include_me?: boolean;
}

export interface ContactsInvitePostRequest {
  contacts: ContactInvite[];
  message?: string;
  communities?: string;
  groups?: string[];
  tools?: string[];
}

export interface ContactsInvitePostBadRequestResponse {
  error_emails: string[];
}

export interface ContactsNavGetResponse {
  groupChats: Room[];
  tool_links?: ToolPrivateEditReadLink[];
  communities?: CommunityNav[];
}

export interface ContactsAddMemberPost {
  type?: ContactsViewType;
  community_id?: string;
  group_id?: string;
  folder_id?: string;
  participant: string[];
}

export interface CommunityFavoriteToolsPostRequest {
  id: string;
  user_id?: string;
  group_id: string | undefined;
  tools: string[];
}
// export type ContactDetailTool = Pick<
//   Template,
//   'id' | 'category' | 'create_change_info' | 'image_preview' | 'name'
// >;
