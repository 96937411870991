import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LicenseInfo } from '@mui/x-license-pro';
import {
  deDE,
  enUS,
  LocalizationProvider,
  PickersLocaleText,
} from '@mui/x-date-pickers';
// import dayjs from 'dayjs';
import deLocale from 'dayjs/locale/de';
// import enLocale from 'dayjs/locale/en';

// Contexts
import { SocketContext } from './shared/context/socket.context';
import { StableNavigateContextProvider } from './shared/components/StableNavigateContext/StableNavigateContext';

// Components
import { Alert } from './shared/ui/Alert/Alert';
import { GodparentsChat } from './modules/godparentschat/components/GodParentsChat/GodparentsChat';
import CommunityCreate from './modules/communities/components/CommunityCreate/CommunityCreate';
import { HelmetMetaTag } from './shared/components/HelmetMetaTag/HelmetMetaTag';

// Hooks
import { useTheme } from './shared/hooks/use-theme.hook';

// Models
import { Snackbar } from './shared/ui/Snackbar/Snackbar';

// Router
import { AppRouter } from './router/AppRouter';

// Store
import { UserState, useUserStore } from './modules/user/stores/use-user.store';

// Utils
import './shared/utils/i18n';
import './shared/utils/fa';
import { HelmetProvider } from 'react-helmet-async';
import { useNewsCenterStore } from './modules/newscenter/stores/use-news-center.store';

// Set mui license key before app render
process.env.REACT_APP_MUI_LICENSE_KEY &&
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const App = () => {
  const socket = useContext(SocketContext);

  const { activeThemeGet } = useTheme();
  const { i18n } = useTranslation();

  const queryClient = new QueryClient();

  // Component state
  const [datePickerLocale, setDatePickerLocale] = useState<
    Partial<PickersLocaleText<unknown>> | undefined
  >(undefined);

  // User store state
  const [account] = useUserStore((state: UserState) => [state.account]);
  // useNewscenterStore
  const [godparentsChat] = useNewsCenterStore((state) => [
    state.godparentsChat,
  ]);

  // Set theme on application init
  useEffect(() => {
    document.documentElement.setAttribute(
      'class',
      localStorage.getItem('app:theme') || 'light'
    );
    // eslint-disable-next-line
  }, []);

  // Set locale by account language
  useEffect(() => {
    switch (account?.language) {
      case 'de':
        setDatePickerLocale(
          deDE.components.MuiLocalizationProvider.defaultProps.localeText
        );
        return;
      case 'it':
        setDatePickerLocale(
          deDE.components.MuiLocalizationProvider.defaultProps.localeText
        );
        return;
      default:
        setDatePickerLocale(
          enUS.components.MuiLocalizationProvider.defaultProps.localeText
        );
        return;
    }
  }, [account?.language]);

  // Set language on account data change. Defaults to 'de'.
  useEffect(() => {
    if (!account?.language && i18n.language !== 'de') {
      i18n.changeLanguage('de');
      document.documentElement.setAttribute('lang', 'de');
    } else if (account?.language && account?.language !== i18n.language) {
      i18n.changeLanguage(account.language);
      document.documentElement.setAttribute('lang', account.language);
    }
  }, [account?.language]);

  // Set height
  const setVhfunction = useCallback(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <SocketContext.Provider value={socket}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={deLocale}
            localeText={datePickerLocale}
          >
            <ThemeProvider theme={activeThemeGet()}>
              <CssBaseline />
              <Alert />
              <Router>
                <StableNavigateContextProvider>
                  <HelmetProvider>
                    <HelmetMetaTag
                      title="Pengueen"
                      description="Pengueen. So gelingt Gemeinschaft."
                      imgsrc="%PUBLIC_URL%/pengueen_small.png"
                      url="https://p4.pengueen.de/"
                    />
                    <AppRouter />
                    {godparentsChat && <GodparentsChat />}
                    <Snackbar />
                    <CommunityCreate />
                  </HelmetProvider>
                </StableNavigateContextProvider>
              </Router>
            </ThemeProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </SocketContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
