// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Breadcrumbs_breadcrumbs__HrkF\\+ {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center; }\n  .Breadcrumbs_breadcrumbs-item__BqNuE {\n    display: flex;\n    align-items: center;\n    font-size: 0.5rem;\n    line-height: 0.5rem; }\n    .Breadcrumbs_breadcrumbs-item-divider__DMBZY {\n    margin-left: 0.25rem;\n    margin-right: 0.25rem;\n    font-size: 0.75rem;\n    line-height: 1rem; }\n    .Breadcrumbs_breadcrumbs-item-link__G07ws {\n    transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;\n    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;\n    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;\n    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n    transition-duration: 150ms; }\n\n@media (min-width: 640px) {\n  .Breadcrumbs_breadcrumbs-item__BqNuE {\n    font-size: 13px; } }\n", "",{"version":3,"sources":["<no source>","webpack://./src/shared/ui/Breadcrumbs/Breadcrumbs.module.scss"],"names":[],"mappings":"AAAA;IAAA,cAAA;IAAA,gBAAA;IAAA,sBAAA;EAAA;IAAA,cAAA;IAAA,oBAAA;ICMI,iBAAiB;IACjB,sBDPJ;IAAA;IAAA,qBAAA;IAAA,sBAAA;IAAA,mBAAA;IAAA,oBAAA;IAAA;IAAA,wGAAA;IAAA,gGAAA;IAAA,+HAAA;IAAA,yDAAA;IAAA,6BAAA;;ACiBA;EAEI;IACE,eAAe,EAAA,EAChB","sourcesContent":[null,"@import '../../../styles/breakpoints.scss';\n\n.breadcrumbs {\n  @apply flex flex-wrap items-center;\n  &-item {\n    @apply flex items-center;\n    font-size: 0.5rem;\n    line-height: 0.5rem;\n    &-divider {\n      @apply mx-1 text-xs;\n    }\n    &-link {\n      @apply transition-colors;\n    }\n  }\n}\n\n@media (min-width: $sm) {\n  .breadcrumbs {\n    &-item {\n      font-size: 13px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs": "Breadcrumbs_breadcrumbs__HrkF+",
	"breadcrumbs-item": "Breadcrumbs_breadcrumbs-item__BqNuE",
	"breadcrumbs-item-divider": "Breadcrumbs_breadcrumbs-item-divider__DMBZY",
	"breadcrumbs-item-link": "Breadcrumbs_breadcrumbs-item-link__G07ws"
};
export default ___CSS_LOADER_EXPORT___;
