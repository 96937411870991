import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import clsx from 'clsx';

// Components
import { Icon } from '../Icon/Icon';

// Hooks
import { useBreakpoints } from '../../hooks/use-breakpoints.hook';

// Styles
import styles from './AddButton.module.scss';

type AddButtonProps = {
  children: ReactNode;
  classes?: string;
  comingSoon?: boolean;
  disabled?: boolean;
  height?: string;
  heightClassName?: string;
  smallFont?: boolean;
  width?: string;
  widthClassName?: string;
  onClick: () => void;
};

export const AddButton = (props: AddButtonProps) => {
  const { lgDown } = useBreakpoints();
  const { t } = useTranslation();

  return (
    <Button
      color="inherit"
      onClick={props.onClick}
      className={clsx(
        styles['add-button'],
        props.classes && props.classes,
        props.heightClassName
          ? props.heightClassName
          : props.height
          ? props.height
          : styles['add-button-height'],
        props.widthClassName
          ? props.widthClassName
          : props.width
          ? props.width
          : styles['add-button-width']
      )}
      disabled={props.disabled}
      sx={{
        backgroundColor: 'background.paper',
        border: 1,
        borderColor: 'border.app',
        '&:hover svg, :hover span': {
          color: 'primary.main',
        },
        height: props.height ?? undefined,
        width: props.width ?? undefined,
      }}
    >
      <Icon icon={['fas', 'plus-circle']} size={lgDown ? 'medium' : 'large'} />
      <div
        className={clsx(
          styles['add-button-title'],
          props.smallFont && styles['smallFont']
        )}
      >
        {props.children}
      </div>
      {props.comingSoon && (
        <Box sx={{ textTransform: 'none' }}>{t('app.coming_soon')}</Box>
      )}
    </Button>
  );
};
