import { UserTranslations } from '../models/user.translations.interface';

export const userTranslationsIT: UserTranslations = {
  account: {
    notifications: {
      text: 'Prossimamente puoi scegliere qui, come vuoi ricevere gli tui informazioni',
      title: 'Notificazione',
      web: 'Web notificazione',
      subtitle: '',
      subtitle2: '',
    },
    delete: {
      confirmation: 'Conferma',
      now: 'Vuoi cancellare il tuo conto irrevocabile ora?',
      subtitle: 'Il tuo conto cancellerà irrevocabile con tutti dati legati',
      title: 'Cancella il mio conto',
    },
    email: {
      text: 'Utilizzo per il tuo login. E Mail non viene publicato',
      change: {
        confirmation: {
          password: 'Password attuale',
          text: 'conferma con il tuo password attuale',
        },
        text5: 'Il tuo indirizzo E-Mail nuovo è:',
        text4:
          'per cambiare il tuo indirizzo Email, digita p.f. il tuo password',
        text3:
          '2. ricevi un Email al tuo indirizzo nuovo. clicca p.f. il link nel Email e conferma cosi, possiedi questo conto Email',
        text2:
          '1. per la tua sicurezza inviamo un Email al tuo indirizzo Email. Quella è per la tua informazione.',
        text1: 'per cambiare il tuo indirizzo Email, ricevi due Email da noi',
        subtitle:
          'Dopo, si puo raggiungere il tuo conto  solo con il tuo indirizzo Email nuovo',
        title: 'conferma il cambiamento del tuo indirizzo Email p.f.',
      },
    },
    language: {
      lng: {
        de: 'Tedesco',
        en: 'Inglese',
        it: 'Italiano',
      },
      text: 'ci stiamo lavorando, per avere a disposizione altre lingue',
      title: 'lingue',
      validation: 'una lingue è richiesta',
    },
    save: {
      request: 'un Email per confermare veniva inviato',
      success: 'conto salvato',
    },
    subtitle: 'lingua e notificazione',
    text: 'l`impostazione per il tuo conto. Cambia gli tui informazioni sull`account come lingua o canali di comunicazione',
    title: 'Informazioni sull`account',
  },
  community: {
    data: {
      form: {
        logo: 'logo',
        name: {
          placeholder: 'p.e. nome della comunità',
          title: 'nome',
        },
        sector: 'settore',
      },
      save: {
        success: 'Saving community successful.',
      },
      subtitle: 'Informazioni della tua comunità',
    },
    notification: {
      form: {
        case: 'Quando',
        user: 'Utente',
        invite_users: 'Invito nuovi utenti',
        invitation_accepted: 'Invito accettato',
        registration_link: 'Registrazione tramite link',
        template: 'Modelli di strumenti',
        template_changed: 'Modifica ai modelli di strumenti',
        inform: 'Informa',
        receiver: 'Destinatario',
        community_owner: 'Proprietario della comunità',
        admin: 'Amministratori',
        employees: 'Dipendenti',
        following_receiver: 'Destinatari seguenti',
        submit: 'Applica impostazioni',
      },
      save: {
        success: 'Visualizza modifiche',
      },
      subtitle:
        'Decidi su cosa tu e gli amministratori della tua comunità dovreste essere notificati. Ulteriori impostazioni sono disponibili nei modelli di strumenti.',
      title: 'Notifiche',
    },
    license: {
      form: {
        address: 'Indirizzo di fatturazione',
        legal: {
          company: {
            placeholder: 'p.e. Schmidt GmbH',
            title: 'apposizione della firma',
          },
          title: 'legalmente',
          vat_id: {
            placeholder: 'p.e. 079 / 123 / 12347',
            title: 'USt-ID',
          },
        },
        contact: {
          email: 'Indirizzo Email divergente',
          title: 'contatto',
        },
      },
      save: {
        success: 'cambio della licenza veniva assunto con successo',
      },
      subtitle:
        'quando hai richieste di modifica, domande o desiderio da disdire, scrivi p.f. a billing@pengueen.de',
      title: 'dati della licenza e del pagamento',
    },
    license_summary: {
      bill: {
        button_summary: 'al riepilogo della fatturazione',
        date: 'prossima data della fattura',
        title: 'Ecco trovi il tuo riepilogo della fatturazione',
      },
      cost: {
        text: 'alla licenza, al mese',
        title: 'La tua prossima fattura:',
      },
      number: {
        button_manage: 'dirigere licenza',
        manage: {
          licenses: 'Licenze individuali',
          submit: {
            button: 'aggiornamento',
            error: 'al aggiornamento della licenza è comparso un errore',
            success: 'la lincenza veniva aggiornata con successo',
          },
          subtitle:
            'aggiungi qui licenze nuove o elimina licenze dal tuo piano',
          summary: {
            text1: ' á {{cost}} € netto.',
            text2: 'Licenze di squadra',
            text3: 'Rechnungsbetrag, netto',
            text4: 'MwSt.',
            text5: 'Rechnungsbetrag, brutto',
            text6: 'Nächstes Rechnungsdatum: {{date}}',

            title: 'riassunto',
          },
          text1: 'Puoi facilmente aumentare o diminuire il numero di licenze.',
          text2:
            'Riceverai la tua prossima fattura per le licenze alla prossima data di scadenza: MM 20 YYYY.',
          title: 'amministra le tue licenze',
        },
        text: 'Licenze nel tuo abbonamento alla data della scadenza',
        title: 'Licenze attuale',
        tooltip:
          'La data della scadenza è sempre il 20 dal mese. Le licenze qualle sono attive al questo giorno sono la base del rendiconto.',
      },
      subtitle: 'Qui troverai una panoramica delle tue licenze completate.',
      title: 'Sommario delle licenze',
      total_gross: 'Somma totale brutto',
      total_net: 'Somma totale netto',
      vat: 'Mwst',
    },
    subtitle: 'Indica qui l`informazioni della tua comunità',
    title: 'la mia comunità',
  },
  documents: {
    filters: {
      sort: {
        date: 'la data',
        name: 'nome',
      },
    },
    subtitle: 'sommario dal documento',
    text: 'luogo per memorizzare documenti importante, qualle ottieni dal tuo Pengueen Team',
    title: 'documenti importante',
  },
  logout: 'logout',
  profile: {
    form: {
      address: 'Indirizzo',
      availability: {
        title: 'accesibilità',
        verify: {
          textUnverified: `Tu non ricevi notifiche per sms`,
          textVerified1: 'anche sotto puoi',
          textVerified2: 'attivare login sicuro con SMS -TAN',
          title: 'verifica il tuo nummero telefono',
        },
      },
      avatar: 'avatar del profilo',
      contact: 'dati di contatto',
    },
    save: {
      success: 'profilo salvato con successo',
    },
    subtitle: 'setting e piu',
    text: 'I dati indicati qui mostrano personne e comunità, con chi sei legato',
    title: 'Informazioni dal profilo',
  },
  security: {
    word: {
      title: 'la tua frase della sicurezza',
      description: 'srivi chiaro e tondo, non vogliamo esagerare',
      subtitle:
        'Trovi questa frase nei tui email, qualle ti mandiamo. Cosi puoi essere sicuro siamo noi i mittenti degli emails',
      notification: 'la tua fase della sicurezza veniva salvato',
    },
    change: {
      error: {
        bad: 'digitato un password falso',
        conflict: 'un password nuovo è richiesto',
        forbidden: 'non è permesso di cambiare il password',
      },
      passwordCurrent: 'password attuale',
      success: 'password veniva cambiato con successo',
      title: 'cambia il password',
    },
    subtitle: 'cambia il password',
    tan: {
      activate: {
        form: {
          buttonSubmit: 'attiva il login sicuro con SMS-TAN',
          buttonRequest: 'richiedi TAN',
          error: {
            incorrect: 'Il TAN, hai digitato non è corretto',
            length: 'Il TAN deve avere 5 lettere esatto',
          },
          input: 'digita TAN',
          missing: 'non hai ricevuto un TAN?',
          resend: 'rinvia TAN ',
        },
        subtitle:
          'richiedi un TAN e ti inviamo un SMS  con un TAN di conferma {*********211}.',
        title: 'attivare il log-in sicuro con SMS-TAN',
      },
      deactivate: {
        submit: 'disattivare',
        subtitle: 'Il tuo log-in sicuro viene spento con SMS-TAN',
        text: 'Quando disattiva il log-in sicuro con SMS-TAN, ti serve non piu un TAN di conferma per SMS per fare il log-in nel tuo conto. Sicomme una disattivazione ridurre  la sicurezza del tuo conto, non consigliamo lo',
        title: 'disattivazione dal log-in sicuro con SMS-TAN',
      },
      text1:
        'per usare il log-in sicuro con SMS-TAN, il tuo nummero celulare deve',
      text2: 'verificare',
      textActive:
        'ogni volta, quando vuoi fare il log-in nel tuo conto,ti serve il tuo password e un TAN di conferma, qualle ti inviamo al tuo telefono      die du für Benachrichtigungen eingestellt hast, zusenden.',
      title: 'login sicuro con SMS-TAN',
    },
    text: 'la configurazione della sicurezza del conto. Cambia gli tui informazioni del conto come password o l`indirizzo E-Mail legato',
    title: 'sicurezza',
  },
  theme: {
    dark: 'scuro',
    light: 'chiaro',
    subtitle: 'configura to display Pengueen in chiaro o scuro apparenza',
    title: 'apparenza',
  },
  title: 'profilo dello utente',
};
