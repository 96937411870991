import { ReactNode, useEffect, useState } from 'react';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { Box } from '@mui/material';
import clsx from 'clsx';

// Components
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';

// Styles
import styles from './RemoveButton.module.scss';

type RemoveButtonProps = {
  children: ReactNode;
  classes?: string;
  fontClasses?: string;
  paddingClasses?: string;
  icon?: [IconPrefix, IconName];
  width?: string;
  value?: any;
  preset?: 'primary';
  onRemove: (value?: any) => void;
  onClick?: () => void;
};

export const RemoveButton = (props: RemoveButtonProps) => {
  const [backGroundColor, setBackGroundColor] = useState<string>('bg.card');
  const [color, setColor] = useState<string>('text.secondary');

  useEffect(() => {
    switch (props.preset) {
      case 'primary':
        setBackGroundColor('primary.main');
        setColor('white');
        break;
      default:
        break;
    }
  }, [props.preset]);

  return (
    <Box
      className={clsx(
        styles['remove-button'],
        props.paddingClasses ?? 'px-2 py-1',
        props.classes && props.classes
      )}
      sx={{
        backgroundColor: backGroundColor,
        borderColor: 'bg.card',
        width: props.width ?? '240px',
      }}
    >
      {props.icon && (
        <Icon
          classes={styles['remove-button-icon']}
          icon={props.icon}
          sx={{ color: color }}
        />
      )}
      <Box
        className={clsx(
          styles['remove-button-content'],
          props.fontClasses && props.fontClasses
        )}
        sx={{ color: color }}
        onClick={props.onClick}
      >
        {props.children}
      </Box>
      <IconButton
        classes={clsx(
          styles['remove-button-remove'],
          props.fontClasses && props.fontClasses
        )}
        icon={['fas', 'times']}
        sxIcon={{ color: color }}
        padding="0.25rem"
        onClick={props.onRemove}
      />
    </Box>
  );
};
