import { ToolsTranslations } from '../models/tools.translations.interface';

export const toolsTranslationsIT: ToolsTranslations = {
  tool_user: {
    subtitle:
      'I proprietari e gli amministratori di {{community_name}} possono modificare il contenuto senza restrizioni.',
    rights_read: 'Diritti di lettura',
    rights_read_text: 'Questi utenti vedono la risorsa nella vista di lettura',
    rights_edit: 'Diritti di modifica',
    rights_edit_text: 'Questi utenti possono modificare la risorsa',
    rights_slots: 'Slot per i diritti',
    rights_slots_text:
      'Questi utenti hanno i diritti di visualizzazione e modifica dello slot dei diritti',
  },
  chat: {
    select: {
      title: 'La chat del tuo strumento',
      subtitle:
        'Utilizza questa chat per inviare notifiche sulla risorsa. Se questa è attivata, tutti gli utenti assegnati possono scambiarsi informazioni e non devono recarsi al centro messaggi.',
      info: 'Questa chat verrà visualizzata anche nel centro messaggi.',
    },
    group: {
      title:
        'Utilizza questa chat per inviare notifiche sulla risorsa. Se questa è attivata, tutti gli utenti assegnati possono scriversi tra loro',
      subtitle:
        'Seleziona gli utenti con cui desideri scambiare informazioni su questa risorsa:',
      info: 'Gruppi esistenti nella tua comunità',
      notice:
        'Nota: al momento non hai assegnato la tua risorsa a nessuna comunità.',
    },
    room: {
      title:
        'Le notifiche sulla risorsa vengono pubblicate qui e puoi parlare con le persone del gruppo selezionato.',
    },
  },
  footer: {
    dashboard: 'al dashboard',
    workbench: 'al bancone',
    auto_save: 'salvare auto',
    link_preview: 'link alla visualizzazione dettagliata',
    sign_up: 'ora registrarsi a Pengueen e utilizzarlo',
  },
  vote: {
    thanks: 'grazie',
    change: 'cambio',
    error: {
      star: '',
      point: 'digita p.f. un valore adatto',
    },
    sum: 'somma',
    sum_total: 'somma (totale)',
  },
  display: {
    read: 'visualizzazione da leggere',
    table: 'Tabella',
    kanban: 'kanban',
  },
  drawer: {
    footer: {
      title: 'Trovi modelli dalla comunità',
      content:
        'sapevi gia, offriamo una pluralità di modelli al nostro marketplace. Inizia semplice e cerchi per modelli al nostro marketplace 🚀',
      market: 'al marketplace',
    },
  },
  required_field: {
    text: 'Bitte fülle "{{tool_name}}" vor dem Speichern aus.',
  },
  filters: {
    filterOperatorContains: 'contiene',
    filterOperatorEquals: 'è uguale a',
    filterOperatorStartsWith: 'inizia con',
    filterOperatorEndsWith: 'termina con',
    filterOperatorIsEmpty: 'è vuoto',
    filterOperatorIsNotEmpty: 'non è vuoto',
    filterOperatorIsAnyOf: 'è uno di',
    filterOperatorIs: 'è',
    filterOperatorNot: 'non è',
    filterOperatorAfter: 'dopo',
    filterOperatorOnOrAfter: 'il o dopo',
    filterOperatorBefore: 'prima',
    filterOperatorOnOrBefore: 'il o prima',
    filterValueAny: 'tutto',
    filterValueFalse: 'no',
    filterValueTrue: 'sì',
    filterPanelOperatorOr: 'o',
    filterPanelOperatorAnd: 'e',
    toolbarColumns: 'Colonna',
    toolbarDensity: 'Densità',
    filterPanelAddFilter: 'Aggiungi filtro',
    filterPanelColumns: 'Colonne',
    filterPanelInputLabel: 'Valore',
    filterPanelInputPlaceholder: 'Scrivi qualcosa',
    toolbarFiltersTooltipShow: 'Mostra filtro',
    toolbarFiltersTooltipHide: 'Nascondi filtro',
    toolbarDensityCompact: 'Compatto',
    toolbarDensityComfortable: 'Comodo',
    columnsPanelShowAllButton: 'Mostra tutto',
    columnsPanelHideAllButton: 'Nascondi tutto',
    columnsPanelTextFieldPlaceholder: 'Titolo colonna',
    columnsPanelTextFieldLabel: 'Colonna di ricerca',
    status: {
      draft: 'bozza',
      publish: 'pubblicato',
      community: 'per comunità',
      title: 'stato',
    },
    workbench: {
      public: 'Le mie comunità',
      community: 'Pengueen comunità',
      personally: 'personale',
      title: 'banco dal lavoro',
      admin: 'Io come amministratore',
    },
    use_cases: 'casi per applicare',
  },
  settings: {
    title: 'Impostazione',
    submit: 'Salvare',
    description: 'Designazione',
    description_placeholder: 'p.e. progetto festa d`estate',
    delete_image: `L'Immagine è stata eliminata.`,
  },
  detail: {
    link: {
      delete: {
        subtitle: 'Questo collegamento privato verrà eliminato.',
        text: 'Elimini adesso questo collegamento privato?',
        title: 'Elimina collegamento privato',
        title_menu: 'Elimina',
      },
    },
    actions: {
      add: 'aggiungere partecipanti',
      delete: {
        subtitle: 'Il strumento viene cancellato e non puo ripristinato.',
        text: 'cancellare strumento adesso?',
        title: 'strumento per cancellare',
        title_menu: 'cancella',
      },
      edit: '` editare al banco dal lavoro',
      settings: 'setting',
      share: 'condividere',
    },
    elements: {
      choose: 'scegliere ...',
      contact: 'scegli contatti',
      upload: {
        add: 'aggiungi',
      },
    },
    form: {
      input: {
        placeholder: 'scrivi qualcosa ...',
      },
    },
    multi_element: {
      add: {
        button: 'aggiungi accesso',
        title: 'accesso nuovo',
      },
      created_at: 'creato a',
      created_by: 'creato da',
      changed_by: 'Geändert von',
      delete: {
        subtitle: 'Il voce viene cancellato e non puo ripristinato.',
        text: 'cancellare voce adesso?',
        title: 'cancellare voce',
      },
      download: 'Fai clic qui per scaricare tutti gli elementi scaricati dalla voce multipla come file .csv.',
      edit: 'Edita accesso',
      onwer: {
        all: 'Tutto',
        me: 'Me',
      },
      search: 'cerchi accesso',
      filters: {
        title: 'filtro nuovo',
      },
    },
    navigation: {
      title: 'contenuto',
    },
    read_edit: {
      edit: 'Editare',
      link: {
        subtitle: 'I utenti allocati vengono rimosso.',
        title: 'Eseguire un link privato',
      },
      read: 'leggere',
      select: 'scegliere utenti o gruppi',
    },
    slots: {
      title: 'altri slot',
    },
    users: {
      permissions: {
        admin: 'accesso illimitato',
        read: 'puo leggere',
        write: 'puo editare',
      },
      remove: {
        subtitle:
          'viene rimosso dal strumento e persa il suo diritto di accesso',
      },
      title: 'utenti con accesso',
    },
  },
  preview: {
    info: {
      created_by: 'Eseguito da',
      share: {
        button: 'copia link per condividere',
        notification: 'link veniva copiato agli appunti',
        text: 'conosci qualcuno, chi avrebbe interessa al questo strumento? Manda un link al previsione e invita lo/lei nella communità Pengueen 🐧',
        title: 'conosci qualcuno, chi cerca essattamente per questo?',
      },
      title: 'Marketplace da pengueen',
      text1:
        'Ti trovi al marketplace da Pengueen, la tua scatola delle costruzioni per software specializzato.',
      text2:
        'la Digitalizzazione è padrone. Ma dove iniziare? e come? Introdurre per mese un software dal branco o svilupare un anno un soluzione su misura? Come posso cointeressare i miei collaboratori? Che dovrei/vorrei digitalizzare?',
      text3:
        'software non mette in piedi processi. Si non accolla responsibilità e non fa plusvalore. Questo fanno personne. MA un buon software puo – come un strumento digitale – sgravare personale specializzato.',
      text4:
        'qui offrono i nostri utenti i sui strumenti digitali, per sostenerti al tuo lavoro',
      text5: 'Esplori tutto',
      text6: 'lo riesci , con',
      text7: 'Pengueen!',
    },
    popup: {
      buttons: {
        info: 'piu informazioni',
        try: {
          notification: 'strumenti aggiungiati',
          title: 'prova adesso e iscrivi a Pengueen',
          title_mobile: 'prova adesso',
        },
      },
      text1: 'questo è un previsione al strumento',
      text2: 'puoi usarlo, quando ti iscrivi a Pengueen',
      text3: 'questo è un previsione al nostro marketplace',
      title: 'previsione',
    },
  },
  title: 'strumenti',
  error:
    'Sie haben keinen Zugriff auf dieses Hilfsmittel! Sie werden in 3 Sekunden auf das Dashboard geleitet.',
  error_title: 'Kein Zugriff',
};
