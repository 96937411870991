import { AboutTranslations } from '../models/about.translations.interface';

export const aboutTranslationsEN: AboutTranslations = {
  start: {
    title: 'zur Startseite',
  },
  imprint: {
    address1:
      'M.F.G. Pengueen UG (haftungsbeschränkt) (nachfolgend: „M.F.G. Pengueen“)',
    address2: 'Damsdorfer Hauptstraße 41A',
    address3: '14797 Kloster Lehnin',
    consumer: {
      regulation: {
        text1:
          'Im Rahmen der Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten steht unter',
        text2:
          'eine Online-Streitbeilegungsplattform der EU-Kommission zur Verfügung.',
        title: 'Verbraucherinformationen gemäß Verordnung (EU) Nr. 524/2013',
      },
      share: {
        title: 'Information zur Verbraucherstreitbeilegung nach § 36 VSBG',
        text1: `Die M.F.G. Pengueen UG weist darauf hin, dass bei rechtlichen Konflikten mit Verbrauchern,
    die Möglichkeit besteht an einem Verbraucherschlichtungsverfahren nach dem Verbraucherstreitbeilegungsgesetz teilzunehmen.
    Die zuständige Schlichtungsstelle ist die Allgemeine Verbraucherschlichtungsstelle des Zentrums für Schlichtung e.V.,
    Straßburger Straße 8, 77694 Kehl am Rhein, Telefon 07851/7957940 Fax 07851/7957941, `,
        text2:
          'Die M.F.G. Pengueen UG ist nicht verpflichtet, an dem Verbraucherschlichtungsverfahren teilzunehmen.',
      },
    },
    disclaimer: {
      text1:
        'M.F.G. Pengueen ist als Inhaltsanbieter nach § 7 Abs. 1 Telemediengesetz für die „eigenen Inhalte die auf',
      text2: `zur Nutzung bereitgehalten werden, nach den allgemeinen Gesetzen verantwortlich.
    Von diesen eigenen Inhalten sind Querverweise („Links“) auf die von anderen Anbietern bereitgehaltenen Inhalte zu unterscheiden.
    Durch den Querverweis hält M.F.G. Pengueen insofern „fremde Inhalte“ zur Nutzung bereit.
    Diese fremden Inhalte wurden bei der erstmaligen Link-Setzung daraufhin überprüft,
    ob durch sie eine mögliche zivilrechtliche oder strafrechtliche Verantwortlichkeit ausgelöst wird.
    Es ist jedoch nicht auszuschließen, dass die Inhalte im Nachhinein von den jeweiligen Anbietern verändert werden.
    M.F.G. Pengueen überprüft die Inhalte, auf die sie in ihrem Angebot verweist, nicht ständig auf Veränderungen,
    die eine Verantwortlichkeit neu begründen könnten.
    Vorsorglich distanziert M.F.G. Pengueen sich hiermit ausdrücklich von diesen Inhalten und macht sie sich ausdrücklich nicht zu Eigen.
    Sollten Sie der Ansicht sein, dass die verlinkten externen Seiten gegen geltendes Recht verstoßen oder sonst unangemessene Inhalte haben,
    so teilen Sie uns dies bitte mit.`,
      title: 'Disclaimer',
    },
    header:
      'Angaben gemäß § 5 TMG und Bezeichnung des für den Inhalt nach § 18 Abs. 1 MStV Verantwortlichen:',
    info1:
      'Eingetragen im Handelsregistration beim Amtsgericht Potsdam HRB 31238 P',
    info2: 'Vertreten durch ihren Geschäftsführer Sascha Landowski',
    info3:
      'Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: DE320558393',
    picture_credit: {
      text1: 'Geschäftsfrau mit Handy:',
      text2: 'Ballon, Fahrten mit dem Heißluftballon, Leuchtturm /',
      title: 'Bildnachweise',
    },
    title: 'Impressum',
  },
  leaflet: {
    admin: {
      link: 'Zugriffsrechte und Rollen in Pengueen',
      text1: `Sie sind Pengueen-Admin, weil Sie eine Gemeinschaft bei Pengueen angelegt haben oder dieser Rolle von einem anderen Pengueen-Admin Ihrer Gemeinschaft zugeordnet wurden.
      Als Pengueen-Admin verwalten Sie Mitglieder, Mitarbeiter, Kategorien und Nachrichten-Gruppen.
      Darüber hinaus verwalten Sie Informationen über Ihre Gemeinschaft (z.B. Adress- und Zahlungsdaten) sowie die Bestandteile der Vorteilswelt.`,
      text2: `Innerhalb der Kategorien haben Sie als Pengueen-Admin erweiterte Schreib- und Bearbeitungsrechte und sehen mehr Informationen als die Mitglieder und Mitarbeiter.
      Bitte lesen Sie hierzu den Artikel über`,
      title: 'Ich als Pengueen Admin',
    },
    document: {
      text1: `Dieses Dokument enthält wichtige Informationen für den Pengueen-Admin, also den (oder die) Nutzer von Pengueen,
      die in einer (oder mehreren) Institutionen die höchsten Nutzungsrechte innehaben.`,
      text2: `Natürlich können Sie innerhalb Ihrer Gemeinschaft agieren, wie Sie möchten, im Zeitalter der EU-DSGVO,
      von Fake News und Hate Massages kommt Ihnen eine inhaltliche Verantwortung zu.`,
      text3:
        'Dieses Dokument ist keine Anleitung, nutzen Sie hierzu bitte unsere Webseite:',
      title: 'Dieses Dokument',
    },
    handling: {
      subtitle: 'Hate Messages, Fake News',
      text1: `Sie können Direktnachrichten zwischen den mit Ihrer Gemeinschaft verbundenen Nutzern nicht einsehen.
      Sie sind aber sehr wohl für den Umgang der verbundenen Nutzer Ihrer Gemeinschaft untereinander und den gewählten Ton verantwortlich.`,
      text2: `Wird z.B. Nutzer A von Nutzer B belästigt, sind Sie Ansprechpartner für Nutzer A und dessen Beschwerden. Es liegt in Ihrer Verantwortung,
      Nutzer B auf einen angemessenen Umgang hinzuweisen und ihn, sofern nötig, von der Gemeinschaft auszuschließen.`,
      title: 'Umgang und Netiquette',
    },
    privacy: {
      text: `Als Administrator erhalten Sie nutzerbezogene Informationen, z.B. wann ein Eintrag durch wenn zuletzt bearbeitet wurde.
      Bitte gehen Sie mit allen persönlichen Informationen und Daten besonders behutsam und vertraulich um.
      Informieren Sie sich am besten im Internet über die Themen der EU Datenschutzgrundverordnung (EU-DSGVO) und des Bundesdatenschutzgesetzes (BDSG).`,
      title: 'Datenschutz und DSGVO',
    },
    subtitle: 'Verantwortung als Pengueen-Admin einer Gemeinschaft',
    title: 'Merkblatt Pengueen-Admin',
  },
  mit: {
    title: 'Regeln (Open Source Richtlinien– MIT Lizenz)',
    text1:
      '„Wenn eine Teilnehmerin oder ein Teilnehmer selbst erstellte Hilfsmittel in den Marktplatz einstellt oder Hilfsmittel (nachfolgend: „Software“), die von anderen Teilnehmerinnen oder Teilnehmern erstellt wurden, aus dem Marktplatz nutzen möchte, so gilt dies unter ausdrücklicher Anerkennung der folgenden Lizenz:',
    text2:
      'Jedem, der eine Kopie dieser Software und der zugehörigen Dokumentationsdateien (die „Software“) erhält, wird hiermit kostenlos die Erlaubnis erteilt, ohne Einschränkung die Software zu nutzen, einschließlich und ohne Einschränkung der Rechte zum Kopieren, Ändern, Zusammenführen, Veröffentlichen, Verteilen, Unterlizenzieren und/oder Verkaufen von Kopien der Software, und Personen, denen die Software zur Verfügung gestellt wird, dies unter den folgenden Bedingungen zu gestatten:',
    text3:
      'Der obige Urheberrechtshinweis und dieser Genehmigungshinweis müssen in allen Kopien oder wesentlichen Teilen der Software enthalten sein.',
    text4:
      'Die Software wird ohne jegliche ausdrückliche oder stillschweigende Gewährleistung, einschlieẞlich, aber nicht beschränkt auf die Gewährleistung der Marktgängigkeit, der Eignung für einen bestimmten Zweck und der Nichtverletzung von Rechten Dritter, zur Verfügung gestellt. Die Autoren oder Urheberrechtsinhaber sind in keinem Fall haftbar für Ansprüche, Schäden oder andere Verpflichtungen, ob in einer Rechtsstreitigkeit, die sich aus Vertrag, unerlaubter Handlung oder sonstige Gründe stützt, die sich aus, aus oder in Verbindung mit der Software oder der Nutzung oder anderen Verwendungen der Software ergeben.',
    text5:
      'Als Verantwortliche gem. § 7 Abs. 1 TMG und § 18 Abs. 1 MStV behalten wir uns vor, Vorschläge für Hilfsmittel ohne Angabe von Gründen von der Veröffentlichung auf unserer Plattform auszuschließen.“',
  },
  privacy_policy: {
    contact: {
      agent: {
        address: {
          company: 'Innovation Technologie Management ITM GmbH',
          name: 'Sascha Kugler',
          place: '10179 Berlin',
          street: 'Alte Jakobstraße 77 b',
        },
        title: 'b) Name und Anschrift des Datenschutzbeauftragten',
      },
      info: {
        company: {
          name: 'M.F.G. Pengueen UG (haftungsbeschränkt) (nachfolgend: „M.F.G. Pengueen“)',
          place: '14797 Kloster Lehnin',
          street: 'Damsdorfer Hauptstraße 41A',
        },
        manager: 'Vertreten durch ihren Geschäftsführer Sascha Landowski',
        title:
          'a) Diese Datenschutz-Information gilt für die Datenverarbeitung durch:',
      },
      title:
        '1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des betrieblichen Datenschutzbeauftragten',
    },
    rights_affected: {
      list_item1: `gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen.
        Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern,
        gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung,
        Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden,
        sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;
        wir führen jedoch ausdrücklich kein Profiling durch.`,
      list_item2:
        'gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;',
      list_item3: `gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen,
        soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung,
        aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;`,
      list_item4: `gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird,
        die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen,
        Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;`,
      list_item5: `gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten,
        gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;`,
      list_item6: `gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen.
        Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und`,
      list_item7_1: `gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren.
        In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Sitz unseres Vereins bei der Landesdatenschutzbehörde Brandenburg unter `,
      list_item7_2: ' wenden',
      text: 'Sie haben das Recht:',
      title: '4. Betroffenenrechte',
    },
    rights_object: {
      text1:
        'Sie haben jederzeit das Recht Ihr Einverständnis in die Verarbeitung der Ihrer personenbezogenen Daten nach Art. 7 Abs. 3 DSGVO zu widerrufen.',
      text2: `Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden,
        haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen,
        die sich aus Ihrer besonderen Situation ergeben.`,
      text3:
        'Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an ',
      title: '5. Widerspruchsrecht',
    },
    security: {
      text1: `Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe,
        die von Ihrem Browser unterstützt wird. In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt,
        greifen wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird,
        erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.`,
      text2: `Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen,
          teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen.
          Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.`,
      text3_1:
        'Wenn Sie der Ansicht sind, dass Ihre personenbezogenen Daten nicht angemessen geschützt werden, oder dass es Anzeichen für Missbrauch gibt, nehmen Sie bitte Kontakt zu uns über E-Mail ',
      text3_2: ' auf.',
      title: '6. Datensicherheit',
    },
    title: 'Datenschutzbestimmung',
    title_plural: 'Datenschutzbestimmungen',
    topicality: {
      text1:
        'Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Februar 2021.',
      text2_1: `Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden,
        diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website unter `,
      text2_2: ' von Ihnen abgerufen und ausgedruckt werden.',
      title: '7. Aktualität und Änderung dieser Datenschutzerklärung',
    },
    transfer: {
      list_item1:
        'Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,',
      list_item2: `die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht,
        dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,`,
      list_item3:
        'für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie',
      list_item4:
        'dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.',
      text1:
        'Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.',
      text2: 'Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:',
      title: '3. Weitergabe von Daten',
    },
    usage: {
      contact_form: {
        text1: `Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit über unser Kontaktformular auf unserer Website Kontakt aufzunehmen.
          Dabei ist die Angabe einer gültigen E-Mail-Adresse sowie Ihres Vor- und Nachnamens erforderlich, damit wir wissen, von wem die Anfrage stammt und um diese beantworten zu können.`,
        text2:
          'Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihres freiwillig erteilten Einverständnisses vor dem Versand der Anfrage.',
        text3:
          'Die für die Nutzung des Kontaktformulars von uns erhobenen personenbezogenen Daten werden nach Erledigung der von Ihnen gestellten Anfrage automatisch gelöscht.',
        title: 'b) Bei Nutzung unseres Kontaktformulars',
      },
      title:
        '2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung',
      visit: {
        list_item1: 'IP-Adresse des anfragenden Rechners,',
        list_item2: 'Datum und Uhrzeit des Zugriffs,',
        list_item3: 'Name und URL der abgerufenen Datei,',
        list_item4: 'Website, von der aus der Zugriff erfolgt (Referrer-URL),',
        list_item5:
          'verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.',
        list_item6:
          'Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,',
        list_item7:
          'Gewährleistung einer komfortablen Nutzung unserer Website,',
        list_item8: 'Auswertung der Systemsicherheit und -stabilität sowie',
        list_item9: 'zu weiteren administrativen Zwecken.',
        text1: 'Beim Aufrufen unserer Website ',
        text2: ` werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet.
          Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:`,
        text3:
          'Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:',
        text4: `Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung.
          In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.`,
        title: 'a) Bei Besuch unserer Seite',
      },
      login_data: {
        text1: 'Die ',
        text2:
          ' verarbeitet personenbezogene Daten der Kategorie „Zugangsdaten“ (E-Mail Adresse, Passwort).',
        text3:
          'Die Datenverarbeitung zum Zweck des Logins erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung.',
        text4:
          'Die Login Daten sind nur mit dem Dienst verbunden, für den Sie sich registrieren und es wird nur der Verifizierungsteil Ihres Login-Profils verarbeitet. Ihr Login-Profil wird automatisch gelöscht, sobald Sie Ihr Konto auf der registrierten Website gelöscht haben, sofern dem keine gesetzlichen Aufbewahrungspflichten oder Verjährungsfristen entgegenstehen.',
        title: 'c) Login Daten',
      },
    },
  },
  right_of_withdrawal: {
    subtitle1: 'Folgen des Widerrufs',
    text1:
      'Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.',
    text2:
      'Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.',
    text3: `Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (M.F.G. Pengueen UG (haftungsdbeschränkt), Zum Damsdorfer Hauptstraße 41A, 14797 Kloster Lehnin, hilfe@pengueen.de) mittels einer eindeutigen Erklärung
      (z. B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren.`,
    text4:
      'Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.',
    text5: `Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
      einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene,
      günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen,
      an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel,
      das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart;
      in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.`,
    text6: `Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie uns einen angemessenen Betrag zu zahlen,
      der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten,
      bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.`,
    title: 'Widerrufsbelehrung',
  },
  terms_of_service: {
    text1: `Zur Teilnahme an den Diensten von Pengueen sind nur Personen berechtigt, die das 18. Lebensjahr vollendet haben und voll geschäftsfähig sind.
      Durch das Anklicken des „Einverstanden“-Buttons bestätigen Sie, dass Sie diese Voraussetzungen erfüllen.
      Sofern Teilnehmerinnen und Teilnehmer das 18. Lebensjahr noch nicht vollendet haben (dies gilt insbesondere für die Nutzung in Schulen),
      muss das Einverständnis zur Nutzung von Pengueen mindestens per E-Mail durch alle Erziehungsberechtigten erteilt werden.`,
    text2: `Sie sind berechtigt, den zwischen Ihnen und Pengueen, bzw. der Betreiberin von Pengueen,
      der M.F.G. Pengueen UG (haftungsbeschränkt) (nachfolgend beide gemeinsam „Pengueen“) geschlossenen Vertrag innerhalb von 14 Tagen nach Absendung der Anmeldung zu widerrufen.
      Zu Einzelheiten lesen Sie bitte die `,
    text3_1: `Der Nutzungsvertrag zwischen Ihnen und Pengueen wird auf unbestimmte Zeit geschlossen.
      Eine Kündigung erfolgt durch das Löschen des Accounts (https://p4.pengueen.de/user/account).
      Mit der Bestätigung zum Löschen des Accounts haben Sie nicht mehr die Möglichkeit, die Pengueen-Services zu nutzen.
      Weiterhin verfällt mit der Löschung des Accounts die Nutzung des restlichen Zeitraums des Premiumdienstes und damit der Anspruch auf Rückerstattung des sich aus der Differenz der Nutzungsgebühr und der verbleibenden Nutzungsdauer ergebende Betrags.
      Im Übrigen gelten die `,
    text3_2: ' von Pengueen.',
    text4: `Wir weisen ausdrücklich darauf hin, dass der Dienst und die Kommunikation mit Pengueen keinen Ersatz für ggf. erforderliche rechtliche,
      medizinische, finanzielle oder sonstige Beratung darstellt. Pengueen rät allen Teilnehmerinnen und Teilnehmern, sich professionell beraten zu lassen.
      Pengueen stellt lediglich einen ergänzenden Service zur Verfügung, der es den Teilnehmerinnen und Teilnehmern bzw. deren Paten ermöglichen soll, rasch miteinander in Kontakt zu treten.`,
    text5: `Alle Teilnehmerinnen und Teilnehmer sind verpflichtet, Daten, insbesondere persönliche Daten,
      von Dritten nur und dann in die entsprechenden Pengueen-Module (z.B. für Patenschaften) einzugeben,
      wenn diese Dritten nachweislich dokumentiert (z.B. per E-Mail, ausgedruckter SMS oder vergleichbar) ihr Einverständnis in die Verarbeitung gegeben haben.
      Die Teilnehmerinnen und Teilnehmer werden ausdrücklich darauf hingewiesen, dass es nicht sinnvoll ist, Personen als Paten/Kontaktpersonen zu benennen,
      mit denen sie sich vorher nicht abgestimmt haben, weil diese Personen z.B. (i) die Verantwortung nicht übernehmen wollen,
      (ii) abwesend sein können oder (iii) sich selbst in einer Situation befinden könnten, in der sie Unterstützung durch andere benötigen.
      Eine Verarbeitung von Daten Dritter ohne dessen Einverständnis wird untersagt.`,
    text6_1:
      'Wenn eine Teilnehmerin oder ein Teilnehmer selbst erstellte Hilfsmittel in den Marktplatz einstellt oder Hilfsmittel (nachfolgend: „',
    text6_2: 'Software',
    text6_3: `“), die von anderen Teilnehmerinnen oder Teilnehmern erstellt wurden, aus dem Marktplatz nutzen möchte, so gilt dies unter ausdrücklicher Anerkennung der folgenden Lizenz:
      Jedem, der eine Kopie dieser Software und der zugehörigen Dokumentationsdateien (die „Software“) erhält, wird hiermit kostenlos die Erlaubnis erteilt,
      ohne Einschränkung die Software zu nutzen, einschließlich und ohne Einschränkung der Rechte zum Kopieren, Ändern, Zusammenführen, Veröffentlichen,
      Verteilen, Unterlizenzieren und/oder Verkaufen von Kopien der Software, und Personen, denen die Software zur Verfügung gestellt wird, dies unter den folgenden Bedingungen zu gestatten:`,
    text6_4:
      'Der obige Urheberrechtshinweis und dieser Genehmigungshinweis müssen in allen Kopien oder wesentlichen Teilen der Software enthalten sein.',
    text6_5: `Die Software wird ohne jegliche ausdrückliche oder stillschweigende Gewährleistung, einschlieẞlich, aber nicht beschränkt auf die Gewährleistung der Marktgängigkeit,
      der Eignung für einen bestimmten Zweck und der Nichtverletzung von Rechten Dritter, zur Verfügung gestellt. Die Autoren oder Urheberrechtsinhaber sind in keinem Fall haftbar für Ansprüche,
      Schäden oder andere Verpflichtungen, ob in einer Rechtsstreitigkeit, die sich aus Vertrag, unerlaubter Handlung oder sonstige Gründe stützt, die sich aus,
      aus oder in Verbindung mit der Software oder der Nutzung oder anderen Verwendungen der Software ergeben.`,
    text6_6:
      'Als Verantwortliche gem. § 7 Abs. 1 TMG und § 18 Abs. 1 MStV behalten wir uns vor, Vorschläge für Hilfsmittel ohne Angabe von Gründen von der Veröffentlichung auf unserer Plattform auszuschließen.',
    text7: `Pengueen, Pengueens Mitarbeiter, Angestellte, Hilfspersonen, Berater und sonstige Personen, die für Pengueen auftreten und/oder handeln,
      haften nur für vorsätzlich oder grob fahrlässig herbeigeführte Schäden. Diese Einschränkung gilt nicht für Schäden an Leben,
      Körper oder Gesundheit sowie für die Haftung bei der Verletzung von vertragswesentlichen Pflichten (das sind solche,
      deren Nichterfüllung die Erreichung des Vertragszwecks schlechthin unmöglich macht, wie etwa das Bemühen um die Verfügbarhaltung der Website innerhalb der angegebenen Zeiten).
      Die Haftung für mittelbare Schäden, für immaterielle Schäden und für Mangelfolgeschäden ist ausgeschlossen.
      In jedem Fall ist die Haftung von Pengueen der Höhe nach begrenzt auf den typischerweise vorhersehbaren Schaden, der jedenfalls nicht höher ist, als die Summe,
      die Pengueens Unternehmenshaftpflichtversicherung abdeckt, also 500.000,-EUR. Die Haftung für Vorsatz sowie die Haftung nach dem Produkthaftungsgesetz bleiben unberührt.`,
    text8: `Die Teilnehmerinnen und Teilnehmer verpflichten sich, es zu unterlassen, die Pengueen-Module in missbräuchlicher Weise zu nutzen, insbesondere es zu unterlassen,
      im Zusammenhang mit der Nutzung strafrechtlich relevante Handlungen (z.B. Bildung krimineller oder terroristischer Vereinigungen, Volksverhetzung, Beleidigung usw.)
      oder nebenstrafrechtlich relevante Handlungen vorzunehmen. Pengueen behält sich ausdrücklich das Recht vor, derartige Inhalte aus dem/den Module/n der Teilnehmerinnen und Teilnehmer zu löschen;
      dies gilt bereits dann, wenn Pengueen nach eigenem Ermessen die Sorge hat, es könne sich um derartige Inhalte handeln.`,
    text9: `Die Teilnehmerinnen und Teilnehmer haften Pengueen gegenüber in vollem Umfang für jedweden Schaden,
       den Pengueen durch von ihnen vorgenommene Handlungen im Zusammenhang mit der Nutzung der Pengueen-Module erleidet. Werden die Handlungen durch Kinder, Jugendliche oder Heranwachsende begangen,
       so haften die Eltern gegenüber Pengueen unter dem Gesichtspunkt der Verletzung der Aufsichtspflicht wie für eigene Handlungen.`,
    text10:
      'Jede Teilnehmerin und jeder Teilnehmer wird gebeten, Pengueen unverzüglich davon zu unterrichten, sobald sie bzw. er Kenntnis von einer missbräuchlichen Nutzung erlangt.',
    text11_1: `Wird Pengueen nicht von einem Verbraucher, sondern von einer Institution (d.h. z.B. von einer Gebietskörperschaft, einer öffentlichen Stelle,
      einer privaten Einrichtung oder einem Unternehmen) genutzt, gilt ergänzend folgendes: Kunde bzw. Kundin von Pengueen ist die Institution.
      Eine von der Institution benannte Person erhält einen sog. Admin-Account; näheres hierzu finden Sie in dem `,
    text11_2: 'Merkblatt',
    text11_3: `. Der Admin – und nicht Pengueen – verwaltet alle Daten der Mitarbeiter der Institution (also z.B. der dort angestellten Beamten) und der Mitglieder der Institution
      (also z.B. der Bürgerinnen und Bürger einer Gebietskörperschaft). Der Admin ist auch für die Einhaltung der in diesen Nutzungsbedingungen aufgeführten Regeln durch die Mitarbeiter und
      durch die Mitglieder der Institution verantwortlich. Er ist der Verantwortliche im Sinne der DSGVO. Es wird ausdrücklich darauf hingewiesen, dass Pengueen keine Möglichkeit hat,
      mit wirtschaftlich und organisatorisch vertretbarem Aufwand die Verwaltung der jeweiligen Nutzer durchzuführen oder auch nur zu überwachen.
      Wird Pengueen aufgrund einer nicht mit diesen Bedingungen konformen Nutzung wegen eines Verstoßes gegen Recht, Gesetz oder behördliche Anweisungen von Dritten in Anspruch genommen,
      so wird Pengueen den betroffenen Account sofort stilllegen; die Institution hat Pengueen von allen derartigen Ansprüchen freizustellen.`,
    title: 'Allgemeine Nutzungsbedingungen',
    title_short: 'ANB`s',
    text_info1: `Pengueen ist eine Plattform zur Kommunikation, Information, Organisation und bietet einen (kostenfreien) Marktplatz für Hilfsmittel; sie wird von der M.F.G. Pengueen UG (haftungsbeschränkt) betrieben. Die Plattform und ihre Betreiberin werden nachfolgend gemeinsam auch als „`,
    text_info1_1: `Pengueen`,
    text_info1_2: `“ bezeichnet. In diesen Allgemeinen Nutzungsbedigungen (nachfolgend auch „`,
    text_info1_3: `ANB`,
    text_info1_4: `“) werden die generellen Regeln für die Nutzung von Pengueen erklärt.`,
    text_info1_5: `Die ANB enthalten unter Teil I allgemeine Regelungen, die sowohl für Privatpersonen (Verbraucher:Innen) als auch für Unternehmen gelten. Unter Teil II finden Sie dann spezielle Regelungen, die ausschließlich für Privatpersonen (Verbraucher:Innen) gelten. Wenn Sie also eine Privatperson (Verbraucher:In) sind, so beachten Sie bitte insbesondere auch Teil II dieser ANB. Nutzen Sie Pengueen im Rahmen Ihrer beruflichen Tätigkeit, beispielsweise aufgrund einer Einladung durch Ihren Arbeitgeber, so gelten im Zweifel nur die Allgemeinen Regelungen in Teil I (vgl. auch Teil I Ziffer 2).`,
    text_title_teil_1: `Teil I: Allgemeine Regelungen`,
    text_teil1_1: `Pengueen ist bemüht den Teilnehmerinnen und Teilnehmern eine stabile und zuverlässige Plattform zur Verfügung zu stellen. Dessen ungeachtet kann Pengueen nicht gewährleisten oder garantieren, dass die Plattform stets und ohne Unterbrechung verfügbar ist. Pengueen übernimmt keine Haftung für Störungen, Unterbrechungen oder sonstige Einschränkungen der Nutzbarkeit der Plattform.`,
    text_teil1_2: `Wird Pengueen nicht von einem/r Verbraucher:In, sondern von einer Körperschaft (also z.B. von einer Gebietskörperschaft, einer öffentlichen Stelle, einer privaten Einrichtung oder einem Unternehmen) genutzt, gilt folgendes: Vertragspartner bzw. Vertragspartnerin von Pengueen ist die Körperschaft. Mindestens eine von der Körperschaft benannte Person erhält einen sog. Admin-Account; näheres hierzu finden Sie in dem `,
    text_teil1_2_2: `. Diese Person (Admin) – und nicht Pengueen – verwaltet alle Daten der Mitarbeiter:Innen der Körperschaft (also z.B. der dort angestellten Beamtinnen und Beamten) und der Mitglieder der Körperschaft (also z.B. der Bürgerinnen und Bürger einer Gebietskörperschaft). Diese Person (Admin) ist auch für die Einhaltung der in diesen Nutzungsbedingungen aufgeführten Regeln durch die Mitarbeiter:Innen und durch die Mitglieder der Körperschaft verantwortlich. Sie ist Verantwortliche im Sinne der DSGVO. Es wird ausdrücklich darauf hingewiesen, dass Pengueen keine Möglichkeit hat, mit wirtschaftlich und organisatorisch vertretbarem Aufwand die Verwaltung der jeweiligen Nutzer durchzuführen oder auch nur zu überwachen. Wird Pengueen aufgrund einer nicht mit diesen Bedingungen konformen Nutzung wegen eines Verstoßes gegen Recht, Gesetz oder behördliche Anweisungen von Dritten in Anspruch genommen, so wird Pengueen den betroffenen Account sofort stilllegen; die Körperschaft hat Pengueen von allen derartigen Ansprüchen freizustellen.`,
    text_teil1_3: `Der Nutzungsvertrag zwischen Ihnen und Pengueen wird auf unbestimmte Zeit geschlossen, kann aber jederzeit mit Wirkung zum Ablauf der auf die Kündigungserklärung folgenden Kalenderwoche gekündigt werden; Pengueen wird eine solche Kündigung nur aus wichtigem Grund aussprechen, also etwa bei Verstößen des Nutzers gegen seine vertraglichen Pflichten einschließlich dieser ANB oder bei einer Einstellung der Dienste von Pengueen. Eine Kündigung erfolgt durch das Löschen des Accounts (`,
    text_teil1_3_2: `). Mit der Bestätigung zum Löschen des Accounts haben Sie nicht mehr die Möglichkeit, die Pengueen-Dienste zu nutzen. Weiterhin verfällt mit der Löschung des Accounts die Möglichkeit der Nutzung des restlichen Zeitraums des Premiumdienstes und damit der Anspruch auf Rückerstattung des sich aus der Differenz der Nutzungsgebühr und der verbleibenden Nutzungsdauer ergebenden Betrags. Im Übrigen gelten die `,
    text_teil1_3_3: ` von Pengueen. Es wird ausdrücklich darauf hingewiesen, dass Ihnen bei Pengueen hinterlegte Daten nach der Kündigung nicht mehr zur Verfügung stehen; Sie haben während des laufenden Vertragsverhältnisses die Möglichkeit, alle Daten bei sich lokal zu speichern.`,
    text_teil1_4: `Alle Teilnehmerinnen und Teilnehmer sind verpflichtet, Daten, insbesondere persönliche Daten, von Dritten nur und dann in die entsprechenden Pengueen-Hilfsmittel (z.B. Nutzung von Pengueen zur Stammdatenverwaltung) einzugeben, wenn diese Dritten nachweislich dokumentiert (z.B. per E-Mail, ausgedruckter SMS, Auftrag oder vergleichbar) ihr Einverständnis in die Verarbeitung gegeben haben; die Erteilung des Einverständnisses ist Pengueen auf Anfrage nachzuweisen. Eine Verarbeitung von Daten Dritter ohne deren Einverständnis wird untersagt.`,
    text_teil1_5: `Wenn eine Teilnehmerin oder ein Teilnehmer selbst erstellte Hilfsmittel (nachfolgend: „`,
    text_teil1_5_2: `Software`,
    text_teil1_5_3: `“) in den Marktplatz einstellt oder Hilfsmittel, die von anderen Teilnehmerinnen oder Teilnehmern erstellt wurden, aus dem Marktplatz nutzen möchte, so gilt dies unter ausdrücklicher Anerkennung der folgenden Lizenz: `,
    text_teil1_5_4: `Jeder Person, die eine Kopie dieser Software und der zugehörigen Dokumentationsdateien erhält, wird hiermit kostenlos die Erlaubnis erteilt, ohne Einschränkung die Software zu nutzen, einschließlich und ohne Einschränkung der Rechte zum Kopieren, Bearbeiten, Zusammenführen, Veröffentlichen, Verteilen und zur Einräumung von Unterlizenzen und Personen, denen die Software zur Verfügung gestellt wird, dies unter den vorgenannten Bedingungen zu gestatten. `,
    text_teil1_5_5: `Der obige Urheberrechtshinweis und dieser Genehmigungshinweis müssen in allen Kopien oder wesentlichen Teilen der Software enthalten sein.`,
    text_teil1_5_6: `Die Software wird ohne jegliche ausdrückliche oder stillschweigende Gewährleistung, einschlieẞlich, aber nicht beschränkt auf die Gewährleistung der Marktgängigkeit, der Eignung für einen bestimmten Zweck und der Nichtverletzung von Rechten Dritter, zur Verfügung gestellt. Die Autoren oder Urheberrechtsinhaber:Innen sind in keinem Fall haftbar für Ansprüche, Schäden oder andere Verpflichtungen, ob in einer Rechtsstreitigkeit, die sich auf Vertrag, unerlaubte Handlung oder sonstige Gründe stützt, die sich aus oder in Verbindung mit der Software oder der Nutzung oder anderen Verwendungen der Software ergeben.`,
    text_teil1_5_7: `Als Verantwortliche gem. § 7 Abs. 1 Telemediengesetz und § 18 Abs. 1 Medienstaatsvertrag behalten wir uns vor, Vorschläge für Hilfsmittel ohne Angabe von Gründen von der Veröffentlichung auf unserer Plattform auszuschließen.`,
    text_teil1_6: `Es wird darauf hingewiesen, dass die Aktivitäten aller Nutzer, die sich mit einer Institution oder Gemeinschaft verbinden oder aber deren Hilfsmittel nutzen, für Dritte einsehbar sein können.`,
    text_teil1_7: `Pengueen, Pengueens Mitarbeiter:Innen, Angestellte, Hilfspersonen, Berater:Innen und sonstige Personen, die für Pengueen auftreten und/oder handeln, haften nur für vorsätzlich oder grob fahrlässig herbeigeführte Schäden. Diese Einschränkung gilt nicht für Schäden an Leben, Körper oder Gesundheit sowie für die Haftung bei der Verletzung von vertragswesentlichen Pflichten (das sind solche, deren Nichterfüllung die Erreichung des Vertragszwecks schlechthin unmöglich macht, wie etwa das Bemühen um die Verfügbarhaltung der Website innerhalb der angegebenen Zeiten). Die Haftung für mittelbare Schäden, für immaterielle Schäden und für Mangelfolgeschäden ist ausgeschlossen. In jedem Fall ist die Haftung von Pengueen der Höhe nach begrenzt auf den typischerweise vorhersehbaren Schaden, der jedenfalls nicht höher ist, als die Summe, die Pengueens Unternehmenshaftpflichtversicherung abdeckt, also 500.000,-EUR. Die Haftung für Vorsatz sowie die Haftung nach dem Produkthaftungsgesetz bleiben unberührt.`,
    text_teil1_8: `Die Teilnehmerinnen und Teilnehmer verpflichten sich, es zu unterlassen, die Pengueen-Module in missbräuchlicher Weise zu nutzen, insbesondere es zu unterlassen, im Zusammenhang mit der Nutzung strafrechtlich relevante Handlungen (z.B. Bildung krimineller oder terroristischer Vereinigungen, Volksverhetzung, Beleidigung usw.) oder nebenstrafrechtlich relevante Handlungen vorzunehmen. Pengueen behält sich ausdrücklich das Recht vor, derartige Inhalte aus dem/den Module/n der Teilnehmerinnen und Teilnehmer zu löschen; dies gilt bereits dann, wenn Pengueen nach eigenem Ermessen die Sorge hat, es könne sich um derartige Inhalte handeln. Jede Teilnehmerin und jeder Teilnehmer wird gebeten, Pengueen unverzüglich davon zu unterrichten, sobald sie bzw. er Kenntnis von einer missbräuchlichen Nutzung erlangt.`,
    text_teil1_9: `Die Teilnehmerinnen und Teilnehmer haften Pengueen gegenüber in vollem Umfang für jedweden Schaden, den Pengueen durch von ihnen vorgenommene Handlungen im Zusammenhang mit der Nutzung der Pengueen-Module erleidet. Werden die Handlungen durch Kinder, Jugendliche oder Heranwachsende begangen, so haften die Eltern gegenüber Pengueen unter dem Gesichtspunkt der Verletzung der Aufsichtspflicht wie für eigene Handlungen.`,
    text_teil1_10: `Sollte eine der vorstehenden Regelungen unwirksam sein oder werden, so soll die Wirksamkeit der übrigen Bestimmungen hiervon unberührt bleiben. Anstelle der unwirksamen Regelung tritt eine (wirksame) solche, die dem wirtschaftlichen Sinn und Zweck der ersetzten Regelung möglichst nahekommt. Gleiches gilt für unklare oder ergänzungsbedürftige Regelungen.`,
    text_teil1_11: `Entgegenstehende Geschäftsbedingungen der Nutzer gelten ausdrücklich nicht, es sei denn, Pengueen würde sie ausdrücklich und schriftlich anerkennen.`,
    text_teil1_12: `Für Nutzerinnen und Nutzer, die keine Verbraucher sind, gilt, dass Gerichtsstand und Erfüllungsort Potsdam ist.`,
    text_title_teil_2: `Teil II: Regelungen für Privatpersonen (Verbraucher:Innen)`,
    text_teil2_1: `Zur Teilnahme an den Diensten von Pengueen sind nur Personen berechtigt, die das 18. Lebensjahr vollendet haben und voll geschäftsfähig sind. Durch das Anklicken des „Einverstanden“-Buttons bestätigen Sie, dass Sie diese Voraussetzungen erfüllen. Sofern Teilnehmerinnen und Teilnehmer das 18. Lebensjahr noch nicht vollendet haben (dies gilt insbesondere für die Nutzung in Schulen), muss das Einverständnis zur Nutzung von Pengueen mindestens per E-Mail durch den oder die gesetzlichen Vertreter erteilt werden.`,
    text_teil2_2: `Sie sind berechtigt, den zwischen Ihnen und Pengueen geschlossenen Vertrag innerhalb von 14 Tagen nach Absendung der Anmeldung zu widerrufen. Zu Einzelheiten lesen Sie bitte die`,
    text_teil2_2_2: `. Der Widerruf ist für Sie mit keinen Kosten verbunden.`,
  },
};
