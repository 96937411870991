import { useTranslation } from 'react-i18next';

// Assets
import pengueen from '../../../assets/logo/pengueen_small.png';
import stock1 from '../../../assets/pictures/stock1.jpg';
import stock2 from '../../../assets/pictures/stock2.jpg';
import stock3 from '../../../assets/pictures/stock3.jpg';

// Models
import { MarketMenuItem, MarketTemplate } from '../models/market.types';
import {
  Template,
  TemplateElementType,
  TemplatePurpose,
  TemplateUseCase,
  TemplateType,
} from '../../templates/models/templates.types';

export const useMarketMock = () => {
  const { t } = useTranslation();

  /**
   * DEVELOPMENT MOCK: Returns mock filters tags.
   * @returns Filters tags
   * @deprecated To be removed when backend functionality is available.
   */
  const marketFiltersTagsGet = (): {
    id: string;
    name: string;
  }[] => {
    return [
      { id: '90a9de4d-2918-48b0-9c18-50a2cc1e8ef0', name: '$Tag$' },
      { id: '1b8a85b5-84a0-49bf-8c46-115e4e22c1ef', name: '$Tag$' },
      { id: '20f8e858-c516-4b3e-9df6-b6ddd9f02677', name: '$Tag$' },
      { id: '52407085-34fe-4ae4-a581-3d49cc949701', name: '$Tag$' },
      { id: '5fd6cfe3-8692-402b-b8a5-b9227e9182a3', name: '$Tag$' },
      { id: 'a8a618de-32e6-4f25-ac7d-4e893a7bfc48', name: '$Tag$' },
      { id: '2b20bff3-d6cf-41a5-8d86-3b15433c2c4f', name: '$Tag$' },
      { id: 'ec718dd4-766e-4699-9561-f476ca285947', name: '$Tag$' },
      { id: '0e6bf82c-2690-4ca0-974c-112eb57ed664', name: '$Tag$' },
      { id: 'b57dd985-da3b-4c30-9e8d-248acae4ca65', name: '$Tag$' },
      { id: '464a9e19-676e-417f-9e70-d09ee6b68ce9', name: '$Tag$' },
    ];
  };

  /**
   * @returns Market menu items mock data.
   * @deprecated To be removed.
   */
  const menuItemsGet = (): MarketMenuItem[] => {
    return [
      {
        id: '30785975-76ad-4c10-a76d-668ce9314324',
        type: TemplateType.Private,
        total: 12,
      },
      {
        id: '30785975-76ad-4c10-a76d-668ce933cd43',
        image: {
          id: '214124',
          url: stock1,
        },
        name: 'Linked Gemeinschaft 1',
        type: TemplateType.Community,
        total: 3,
      },
      {
        id: '0596ddc5-02e7-43a9-8d48-ec057b02218d',
        // image: {
        //   id: '214124',
        //   url: stock2,
        // },
        name: 'Linked Gemeinschaft 2',
        type: TemplateType.Community,
        total: 22,
      },
      {
        id: '0596ddc5-02e7-43a9-8d48-ec057b02218e',
        image: {
          id: '214124',
          url: stock3,
        },
        name: 'Linked Gemeinschaft 3',
        type: TemplateType.Community,
        total: 14,
      },
      {
        id: '00d02f14-44af-40b4-aea5-4d4e1db80c0b',
        image: {
          id: '214124',
          url: pengueen,
        },
        name: t('market.community_tools'),
        type: TemplateType.Public,
        total: 101,
      },
    ];
  };

  /**
   * DEVELOPMENT MOCK: Returns mock market templates.
   * @returns Market teamplates
   * @deprecated To be removed when backend functionality is available.
   */
  const templatesGet = (): MarketTemplate[] => {
    return [
      {
        id: 't001',
        image_market: {
          id: '214124124',
          url: stock1,
        },
        preview_id: '5e4a287a-2344-4547-bca2-f40f6fa6fd33',
        settings: {
          extra: {
            restricted: false,
            unique: false,
          },
          description: 'Beschreibung 1',
          icon: {
            id: '2921712741',
            url: stock2,
          },
          purpose: TemplatePurpose.Living,
          name: 'Was ist, wenn der Name zu lang ist, zu lang, zu lang',
        },
        use_case: TemplateUseCase.Project_Management,
      },
      {
        id: 't002',
        image_market: {
          id: '2141241221414',
          url: stock2,
        },
        preview_id: 'bb898f35-0b34-4baf-8ddd-66ba8f982038',
        settings: {
          description: 'Beschreibung 2',
          extra: {
            restricted: false,
            unique: false,
          },
          icon: {
            id: '2923141343141712741',
            url: stock3,
          },
          name: 'Normal',
          purpose: TemplatePurpose.Living,
        },
        use_case: TemplateUseCase.Project_Management,
      },
      {
        id: 't003',
        image_market: {
          id: '2141243141341341124',
          url: stock3,
        },
        preview_id: '81c43556-0cc3-4a34-ab4f-b84d8a745e44',
        settings: {
          description: 'Beschreibung 3',
          extra: {
            restricted: false,
            unique: false,
          },
          icon: {
            id: '292173141413142741',
            url: stock1,
          },
          name: 'Begleitdienst für Senoiren',
          purpose: TemplatePurpose.Living,
        },
        use_case: TemplateUseCase.Project_Management,
      },
      {
        id: 't004',
        image_market: {
          id: '2141213413414124',
          url: stock2,
        },
        preview_id: '04024e30-fad9-4618-943a-10f0f8429693',
        settings: {
          description: 'Beschreibung 4',
          extra: {
            restricted: false,
            unique: false,
          },
          icon: {
            id: '29231431413441712741',
            url: stock1,
          },
          name: 'Test',
          purpose: TemplatePurpose.Living,
        },
        use_case: TemplateUseCase.Project_Management,
      },
      {
        id: 't005',
        image_market: {
          id: '214124124',
          url: stock2,
        },
        preview_id: '25dbcbb0-01ec-4ad3-a8da-f9a67bf11c8a',
        settings: {
          description: 'Beschreibung Vorlage 5',
          extra: {
            restricted: false,
            unique: false,
          },
          icon: {
            id: '2921712741',
            url: stock3,
          },
          name: 'Vorlage 5',
          purpose: TemplatePurpose.Living,
        },
        use_case: TemplateUseCase.Project_Management,
      },
      {
        id: 't006',
        image_market: {
          id: '34324324',
          url: stock3,
        },
        preview_id: '70318b63-68f7-4fdc-9ec5-5733cf92321f',
        settings: {
          description: 'Noch eine Vorlage',
          extra: {
            restricted: false,
            unique: false,
          },
          icon: {
            id: '3454254325',
            url: stock1,
          },
          name: 'Noch eine Vorlage 6',
          purpose: TemplatePurpose.Community,
        },
        use_case: TemplateUseCase.Documentation,
      },
    ];
  };

  /**
   * DEVELOPMENT MOCK: Returns template preview field types.
   * @returns Template
   * @deprecated To be removed
   */
  const templatePreviewGet = (): Template => {
    return {
      id: 't001',
      create_change_info: {
        changed_at: new Date().toISOString(),
        created_at: new Date().toISOString(),
        created_by: {
          profile: {
            address: {
              country: undefined,
              house_number: undefined,
              place: undefined,
              street: undefined,
              zip_code: undefined,
            },
            personal_data: {
              birthday: undefined,
              first_name: 'Hubert',
              last_name: 'Meyer',
              telephone: undefined,
            },
          },
        },
      },
      image_market: {
        id: '214124124',
        url: stock1,
      },
      preview_id: '67398da2-4929-423b-99f6-897c28ed36df',
      sections: [
        {
          id: '8ba80591-2b89-4f22-b352-7863668a61a0',
          elements: [
            {
              id: '79a57955-4ea1-42be-b7b8-f2ffb9489487',
              name: 'Beispielelement',
              settings: {
                rights: {
                  employee: true,
                  member: true,
                  pengueen_admin: true,
                },
              },
              type: TemplateElementType.Input,
            },
          ],
          name: 'Abschnitt 1',
          settings: {
            restricted: false,
          },
        },
      ],
      settings: {
        extra: {
          restricted: false,
          unique: false,
        },
        description: 'Beschreibung 1',
        icon: {
          id: '2921712741',
          url: stock2,
        },
        purpose: TemplatePurpose.Living,
        name: 'Was ist, wenn der Name zu lang ist, zu lang, zu lang',
      },
      use_case: TemplateUseCase.Project_Management,
    };
  };

  /**
   * DEVELOPMENT MOCK: Returns template preview tags.
   * @returns Tags
   * @deprecated To be removed when backend is available.
   */
  const templatePreviewTagsGet = (): string[] => {
    const tags: string[] = [];
    for (let i = 0; i < 9; i++) {
      tags.push('$Tagbezeichnung$');
    }
    return tags;
  };

  return {
    marketFiltersTagsGet,
    menuItemsGet,
    templatesGet,
    templatePreviewGet,
    templatePreviewTagsGet,
  };
};
