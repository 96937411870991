// Assets
import stock1 from '../../../assets/pictures/stock1.jpg';
import stock2 from '../../../assets/pictures/stock2.jpg';
import stock3 from '../../../assets/pictures/stock3.jpg';
import stock4 from '../../../assets/pictures/stock4.jpg';
import stock5 from '../../../assets/pictures/stock5.jpg';
import stock6 from '../../../assets/pictures/stock6.jpg';
import { ToolFavorite } from '../../dashboard/models/dashboard.types';

// Models
import {
  ActiveState,
  CommunityFolder,
  CommunityGroup,
  CommunityNav,
  ConnectionType,
  Contact,
  ContactCommunity,
  ContactDetailTool,
} from '../models/contacts.types';

export const useContactsMock = () => {
  /**
   * GET contact mock data.
   * @returns Contact mock data
   * @deprecated To be removed.
   */
  const contactMock1Get = (): Contact => {
    return {
      id: 'cb48597c-5bcb-4754-ac66-ba2325effade',
      invitor: true,
      nickname: 'Hans',
      email: 'hans0365@gmail.com',
      avatar: {
        id: '35a7e953-a589-4af3-b503-beb1aade3ed7',
        url: 'https://files.pengueen.de/api/download/19d6ca1b-8893-4731-a45d-6e07994cb073.jpeg',
      },
      state: ActiveState.Active,
      connection: ConnectionType.Direct,
      first_name: 'Hans',
      last_name: undefined,
      phone: '033-000-0000',
      address: {
        country: 'Deutschland',
        street: 'Postdam strasse',
        house_number: '10xedf-r6fxfd',
        place: 'Berlin',
        zip_code: '13502',
      },
      direct_chat_id: 'd7d47822-1ccf-4595-a0c7-fe6ace5c7eaf',
    };
  };

  /**
   * GET contacts mock data.
   * @returns Contacts mock data
   * @deprecated To be removed.
   */
  const contactsMockGet = (): Contact[] => {
    return [
      {
        id: '241f2gd3d3s1g2',
        community: {
          id: '2912982z191261',
          logo: {
            id: '123',
            url: stock1,
          },
          name: 'Gemeinschaft 1',
        },
        avatar: {
          id: '1',
          url: stock5,
        },
        email: 'hubert.schmidt@pengueen.de',
        connection: ConnectionType.Indirect,
        first_name: 'Hubert',
        last_name: 'Schmidt',
        // role: Role.Employee,
        state: ActiveState.Active,
      },
      {
        id: '2d1921h0d8h1d',
        community: {
          id: '291298612391',
          logo: {
            id: '456',
            url: stock2,
          },
          name: 'Gemeinschaft 2',
        },
        avatar: {
          id: '2',
          url: stock4,
        },
        connection: ConnectionType.Direct,
        email: 'trude.petersen@pengueen.de',
        first_name: 'Trude',
        last_name: 'Petersen',
        // role: Role.Admin,
        state: ActiveState.Active,
      },
      {
        id: '213d331u310h31',
        community: {
          id: '290216926412',
          logo: {
            id: '789',
            url: stock3,
          },
          name: 'Gemeinschaft 3',
        },
        avatar: {
          id: '3',
          url: stock6,
        },
        connection: ConnectionType.Indirect,
        email: undefined,
        first_name: 'Manfred',
        last_name: 'Mangold',
        // role: Role.Member,
        state: ActiveState.Invited,
      },
      {
        id: '4ddefx',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5d5bvd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '6d76t7yhbd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '7456gvc',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '81vfkjd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '93sfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '10xeidf-r6fxfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '13w4-r65xbf',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd-r65sxbf',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '14652',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'dfgd2-15z',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5d11hs35',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '512d36gex2614',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7f378zleb62',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1ds3f4fz5f1zgds53',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'zd1zs1d51dddf3g5',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '42sfd37jgl6jdvd73z2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '13d513f5133',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '124146d5251351',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's3534d2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '346dj3463s',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's5e6z4md5d6dh71',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3d4sz6ymd3f4n2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '63464d363',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '241f2gde3d3s1g21',
        community: {
          id: '2912982z191261',
          logo: {
            id: '123',
            url: stock1,
          },
          name: 'Gemeinschaft 1',
        },
        avatar: {
          id: '1',
          url: stock5,
        },
        email: 'hubert.schmidt@pengueen.de',
        connection: ConnectionType.Indirect,
        first_name: 'Hubert',
        last_name: 'Schmidt',
        // role: Role.Employee,
        state: ActiveState.Active,
      },
      {
        id: '2d1921h0dd8h1d',
        community: {
          id: '291298612391',
          logo: {
            id: '456',
            url: stock2,
          },
          name: 'Gemeinschaft 2',
        },
        avatar: {
          id: '2',
          url: stock4,
        },
        connection: ConnectionType.Direct,
        email: 'trude.petersen@pengueen.de',
        first_name: 'Trude',
        last_name: 'Petersen',
        // role: Role.Admin,
        state: ActiveState.Active,
      },
      {
        id: '21s3d331u310h31',
        community: {
          id: '290216926412',
          logo: {
            id: '789',
            url: stock3,
          },
          name: 'Gemeinschaft 3',
        },
        avatar: {
          id: '3',
          url: stock6,
        },
        connection: ConnectionType.Indirect,
        email: undefined,
        first_name: 'Manfred',
        last_name: 'Mangold',
        // role: Role.Member,
        state: ActiveState.Invited,
      },
      {
        id: '4dedefx',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5d5bdvd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '6d76t7yhb4d',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '7456dgvc',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '81vffkjd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd9z3sffsrdsrd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '10xeidf-r6f78xfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '134w4-r65xbf',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3dd-dve',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '146d52w',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'dfgd2-1n5z',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5d11hxs35',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd-512d36gex2614',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7f378zle7b62',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1ds3f4ffz5f1zgds53',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'zd1zs1d51dddf3g5g',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '4236d32',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1s3d513f5133',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '124146d52wd51351',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's3534dd2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '346dj346m3s',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's5e6z4tmd5d6dh71',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3d4sz6ymtd3f4n2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '63464d36d3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '24s1f2gde3d3s1g22',
        community: {
          id: '2912982z191261',
          logo: {
            id: '123',
            url: stock1,
          },
          name: 'Gemeinschaft 1',
        },
        avatar: {
          id: '1',
          url: stock5,
        },
        email: 'hubert.schmidt@pengueen.de',
        connection: ConnectionType.Indirect,
        first_name: 'Hubert',
        last_name: 'Schmidt',
        // role: Role.Employee,
        state: ActiveState.Active,
      },
      {
        id: '2d192s1h0dd8h1d',
        community: {
          id: '291298612391',
          logo: {
            id: '456',
            url: stock2,
          },
          name: 'Gemeinschaft 2',
        },
        avatar: {
          id: '2',
          url: stock4,
        },
        connection: ConnectionType.Direct,
        email: 'trude.petersen@pengueen.de',
        first_name: 'Trude',
        last_name: 'Petersen',
        // role: Role.Admin,
        state: ActiveState.Active,
      },
      {
        id: '21s3d331uf310h31',
        community: {
          id: '290216926412',
          logo: {
            id: '789',
            url: stock3,
          },
          name: 'Gemeinschaft 3',
        },
        avatar: {
          id: '3',
          url: stock6,
        },
        connection: ConnectionType.Indirect,
        email: undefined,
        first_name: 'Manfred',
        last_name: 'Mangold',
        // role: Role.Member,
        state: ActiveState.Invited,
      },
      {
        id: '4de5defx',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5bd5bdvd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '6d76t7yhb4fd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7456dgvc',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '81vffkzjd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd9z3sfdsrd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '10xxeidf-r6f78xfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '13xw4-r65xbf',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3dyd-dve',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '146d52wda',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'dfgd2-1nu5z',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5d11hhxs35',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'dd-512d36gex2614',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7f378zlea7b62',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1ds3f4ffz5f1hzgds53',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1151d35',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '42s36d32',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1s3d513df5133',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '12414dd52wda51351',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's353z4dd2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '346djn346m3s',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's5e6z4tmd5x6dh71',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3d4sz6-ymtd3f4n2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '6346s4d36d3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '21241343314dd52wda2',
        community: {
          id: '2912982z191261',
          logo: {
            id: '123',
            url: stock1,
          },
          name: 'Gemeinschaft 1',
        },
        avatar: {
          id: '1',
          url: stock5,
        },
        email: 'hubert.schmidt@pengueen.de',
        connection: ConnectionType.Indirect,
        first_name: 'Hubert',
        last_name: 'Schmidt',
        // role: Role.Employee,
        state: ActiveState.Active,
      },
      {
        id: '2d192s1h0d4d8h1d',
        community: {
          id: '291298612391',
          logo: {
            id: '456',
            url: stock2,
          },
          name: 'Gemeinschaft 2',
        },
        avatar: {
          id: '2',
          url: stock4,
        },
        connection: ConnectionType.Direct,
        email: 'trude.petersen@pengueen.de',
        first_name: 'Trude',
        last_name: 'Petersen',
        // role: Role.Admin,
        state: ActiveState.Active,
      },
      {
        id: '21s3d331uf310h3g1',
        community: {
          id: '290216926412',
          logo: {
            id: '789',
            url: stock3,
          },
          name: 'Gemeinschaft 3',
        },
        avatar: {
          id: '3',
          url: stock6,
        },
        connection: ConnectionType.Indirect,
        email: undefined,
        first_name: 'Manfred',
        last_name: 'Mangold',
        // role: Role.Member,
        state: ActiveState.Invited,
      },
      {
        id: '4d2e5defx',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5bd5xbdvd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '6d76t7xyhb4fd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7df456dgvc',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '81vdfdfkzjd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '9rz3sfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '10xxeidf-r6f7hxfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '173xw4-r65xbf',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3dyd-dveg',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '14dd52da',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'dfgd2-1nu5iz',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5d11hhxts35',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'dzd-512d36gex2614',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7fx78zlea7b62',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1d345153',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1151ds35',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '42sf36d32',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1s3dd513df5133',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '12414dd52d51351',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's353z4hdd2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '34746m3s',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's5e6z4tmd5x6jdh71',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3d4sz6-ydmtd3f4n2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '63f46s4d36d3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '241241343314dd52d52',
        community: {
          id: '2912982z191261',
          logo: {
            id: '123',
            url: stock1,
          },
          name: 'Gemeinschaft 1',
        },
        avatar: {
          id: '1',
          url: stock5,
        },
        email: 'hubert.schmidt@pengueen.de',
        connection: ConnectionType.Indirect,
        first_name: 'Hubert',
        last_name: 'Schmidt',
        // role: Role.Employee,
        state: ActiveState.Active,
      },
      {
        id: '2d192s1yh0d4d8h1d',
        community: {
          id: '291298612391',
          logo: {
            id: '456',
            url: stock2,
          },
          name: 'Gemeinschaft 2',
        },
        avatar: {
          id: '2',
          url: stock4,
        },
        connection: ConnectionType.Direct,
        email: 'trude.petersen@pengueen.de',
        first_name: 'Trude',
        last_name: 'Petersen',
        // role: Role.Admin,
        state: ActiveState.Active,
      },
      {
        id: '21s3d331uff310h3g1',
        community: {
          id: '290216926412',
          logo: {
            id: '789',
            url: stock3,
          },
          name: 'Gemeinschaft 3',
        },
        avatar: {
          id: '3',
          url: stock6,
        },
        connection: ConnectionType.Indirect,
        email: undefined,
        first_name: 'Manfred',
        last_name: 'Mangold',
        // role: Role.Member,
        state: ActiveState.Invited,
      },
      {
        id: '4d2be5defx',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5bd5xbgdvd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '6dg76t7xyhb4fd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7df456xgvc',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '81vdffkzjd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '9r6z3sfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '10xxxeidf-r6f7hxfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '173xwh4-r65xbf',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3dgyd-dveg',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '14dd52d5h',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'dfgd2-1nu56iz',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5d11hhxts8',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '6dzd-512d36gex2614',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7fx78zleat7b62',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1d3f45153',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1s151ds35',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '42sf36d3z2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1s3dd513f3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '12414dd52d5h451351',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's353z4jhdd2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3474f6m3s',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '564d561',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3d4zsz6-ydmtd3f4n2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '63f46s4d3f6d3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '2412541343314dd52d5h422',
        community: {
          id: '2912982z191261',
          logo: {
            id: '123',
            url: stock1,
          },
          name: 'Gemeinschaft 1',
        },
        avatar: {
          id: '1',
          url: stock5,
        },
        email: 'hubert.schmidt@pengueen.de',
        connection: ConnectionType.Indirect,
        first_name: 'Hubert',
        last_name: 'Schmidt',
        // role: Role.Employee,
        state: ActiveState.Active,
      },
      {
        id: '2d192s1yh0d4d8h1dc',
        community: {
          id: '291298612391',
          logo: {
            id: '456',
            url: stock2,
          },
          name: 'Gemeinschaft 2',
        },
        avatar: {
          id: '2',
          url: stock4,
        },
        connection: ConnectionType.Direct,
        email: 'trude.petersen@pengueen.de',
        first_name: 'Trude',
        last_name: 'Petersen',
        // role: Role.Admin,
        state: ActiveState.Active,
      },
      {
        id: '21s3d3g31uff310h3g1',
        community: {
          id: '290216926412',
          logo: {
            id: '789',
            url: stock3,
          },
          name: 'Gemeinschaft 3',
        },
        avatar: {
          id: '3',
          url: stock6,
        },
        connection: ConnectionType.Indirect,
        email: undefined,
        first_name: 'Manfred',
        last_name: 'Mangold',
        // role: Role.Member,
        state: ActiveState.Invited,
      },
      {
        id: '4d2be5fdefx',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5bd5x7bgdvd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '6dg76tfxyhb4fd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7df456fxgvc',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '81fvdffkzjd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'x9r6z3sfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '10xxxceidf-r6f7hxfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '173xwh4-r65sxbf',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3dgyd-dvedg',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '14dd52d5h42h',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'dfgd2-1nu56iiz',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5d11hhxss8',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '6dzzd-512d36gex2614',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7fx78zl6eat7b62',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1d3f451s53',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1s151dds35',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '42sfd36d3z2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1s3dd5s13f3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '12414dd52d5h42hd51351',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's353z4j5d2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '34z74f6m3s',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's564d561',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3d46342',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '63f46s4dzxf6d3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '24125413d43314dd5-2d5h42hd2',
        community: {
          id: '2912982z191261',
          logo: {
            id: '123',
            url: stock1,
          },
          name: 'Gemeinschaft 1',
        },
        avatar: {
          id: '1',
          url: stock5,
        },
        email: 'hubert.schmidt@pengueen.de',
        connection: ConnectionType.Indirect,
        first_name: 'Hubert',
        last_name: 'Schmidt',
        // role: Role.Employee,
        state: ActiveState.Active,
      },
      {
        id: '2d192s1yh0sd4d8hd1dc',
        community: {
          id: '291298612391',
          logo: {
            id: '456',
            url: stock2,
          },
          name: 'Gemeinschaft 2',
        },
        avatar: {
          id: '2',
          url: stock4,
        },
        connection: ConnectionType.Direct,
        email: 'trude.petersen@pengueen.de',
        first_name: 'Trude',
        last_name: 'Petersen',
        // role: Role.Admin,
        state: ActiveState.Active,
      },
      {
        id: '21s3d3sg31uff310h3g1',
        community: {
          id: '290216926412',
          logo: {
            id: '789',
            url: stock3,
          },
          name: 'Gemeinschaft 3',
        },
        avatar: {
          id: '3',
          url: stock6,
        },
        connection: ConnectionType.Indirect,
        email: undefined,
        first_name: 'Manfred',
        last_name: 'Mangold',
        // role: Role.Member,
        state: ActiveState.Invited,
      },
      {
        id: '4d27be5fdefx',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5bd5x7bxgdvd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '6dg76tfxxyhb4fd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7df7456fxgvc',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '81fvdffkz56jd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'x09r6z3sfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '10xx3xceidf-r6f7hxfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '173xw3h4-r65sxbf',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3ddgyd-dvedg',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '14ddd5-2d5h42hd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'dfgd2-1nu56iiaz',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5d11hhxhs8',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '512s5v6261',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd73732',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1d3f451ds53',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1s151ddf35',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '42sfd3g6dd3z2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1s3zdd5s13f3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1241d45135f1',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's35d3z4j5d2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '34z74f6fm3s',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's564d5d61',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3d463f42',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '63f46s4dxf6d3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '24125413d433s142',
        community: {
          id: '2912982z191261',
          logo: {
            id: '123',
            url: stock1,
          },
          name: 'Gemeinschaft 1',
        },
        avatar: {
          id: '1',
          url: stock5,
        },
        email: 'hubert.schmidt@pengueen.de',
        connection: ConnectionType.Indirect,
        first_name: 'Hubert',
        last_name: 'Schmidt',
        // role: Role.Employee,
        state: ActiveState.Active,
      },
      {
        id: '2d192fs1yh0sd4d8hd1dc',
        community: {
          id: '291298612391',
          logo: {
            id: '456',
            url: stock2,
          },
          name: 'Gemeinschaft 2',
        },
        avatar: {
          id: '2',
          url: stock4,
        },
        connection: ConnectionType.Direct,
        email: 'trude.petersen@pengueen.de',
        first_name: 'Trude',
        last_name: 'Petersen',
        // role: Role.Admin,
        state: ActiveState.Active,
      },
      {
        id: '21s3d3sg31fuff310h3g1',
        community: {
          id: '290216926412',
          logo: {
            id: '789',
            url: stock3,
          },
          name: 'Gemeinschaft 3',
        },
        avatar: {
          id: '3',
          url: stock6,
        },
        connection: ConnectionType.Indirect,
        email: undefined,
        first_name: 'Manfred',
        last_name: 'Mangold',
        // role: Role.Member,
        state: ActiveState.Invited,
      },
      {
        id: '4d27be5fbdefx',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5bd5x7bxgdzvd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '6dg76tfsxxyhb4fd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7dff7456fxgvc',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '81fvddffkz56jd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'x09-r6z3sfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '10xx3xceridf-r6f7hxfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '173gxw3h4-r65sxbf',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3ddgyd-dsvedg',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '14dd5-2d5h42hd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'dfgd2-1nu56niiaz',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5xd11hhxhs8',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '512d6261',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7f3732',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1ds3f451ds53',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1zs151ddf35',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '42sfd37g6dd3z2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1s3zdd5s13rf3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1241d4513d5f1',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's35d37z4j5d2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '34z74f6j3s',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's5e64d5d61',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3d46d3f42',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '63f4g6zs4dxf6d3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '241f25413d433s142',
        community: {
          id: '2912982z191261',
          logo: {
            id: '123',
            url: stock1,
          },
          name: 'Gemeinschaft 1',
        },
        avatar: {
          id: '1',
          url: stock5,
        },
        email: 'hubert.schmidt@pengueen.de',
        connection: ConnectionType.Indirect,
        first_name: 'Hubert',
        last_name: 'Schmidt',
        // role: Role.Employee,
        state: ActiveState.Active,
      },
      {
        id: '2d192fs1yh0sdg4d8hd1dc',
        community: {
          id: '291298612391',
          logo: {
            id: '456',
            url: stock2,
          },
          name: 'Gemeinschaft 2',
        },
        avatar: {
          id: '2',
          url: stock4,
        },
        connection: ConnectionType.Direct,
        email: 'trude.petersen@pengueen.de',
        first_name: 'Trude',
        last_name: 'Petersen',
        // role: Role.Admin,
        state: ActiveState.Active,
      },
      {
        id: '21s3d3sg31fguff310h3g1',
        community: {
          id: '290216926412',
          logo: {
            id: '789',
            url: stock3,
          },
          name: 'Gemeinschaft 3',
        },
        avatar: {
          id: '3',
          url: stock6,
        },
        connection: ConnectionType.Indirect,
        email: undefined,
        first_name: 'Manfred',
        last_name: 'Mangold',
        // role: Role.Member,
        state: ActiveState.Invited,
      },
      {
        id: '4dx27be5fbdefx',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5bd5x7bxogdfzvd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '6dg76tfsxgxyhb4fd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7dff7456bfxgvc',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '81fvddfbfkz56jd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'x09-r6z3dsfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '10xx3xceridf-r6f7lhxfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1r2-r65bf',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd3ddgyd-dsvedg',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '14231-5132',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'dfgd2-1nu56tniiaz',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '511d3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '512d6e261',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7f37e62',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1ds3f45f1ds53',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1zs151ddf3g5',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '42sfd37g6dd73z2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1s3zdd5sw3rf3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '124s1d4z513d5f1',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's35d37z4ej5d2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '34vz74f6j3s',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's5e64d5d671',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3d4s6d3f42',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '63f4g6zzs4dxf6d3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '241f25413d433s1g2',
        community: {
          id: '2912982z191261',
          logo: {
            id: '123',
            url: stock1,
          },
          name: 'Gemeinschaft 1',
        },
        avatar: {
          id: '1',
          url: stock5,
        },
        email: 'hubert.schmidt@pengueen.de',
        connection: ConnectionType.Indirect,
        first_name: 'Hubert',
        last_name: 'Schmidt',
        // role: Role.Employee,
        state: ActiveState.Active,
      },
      {
        id: '219s1hd0d8h1d',
        community: {
          id: '291298612391',
          logo: {
            id: '456',
            url: stock2,
          },
          name: 'Gemeinschaft 2',
        },
        avatar: {
          id: '2',
          url: stock4,
        },
        connection: ConnectionType.Direct,
        email: 'trude.petersen@pengueen.de',
        first_name: 'Trude',
        last_name: 'Petersen',
        // role: Role.Admin,
        state: ActiveState.Active,
      },
      {
        id: '21s3d3sg31fguff31b0h3g1',
        community: {
          id: '290216926412',
          logo: {
            id: '789',
            url: stock3,
          },
          name: 'Gemeinschaft 3',
        },
        avatar: {
          id: '3',
          url: stock6,
        },
        connection: ConnectionType.Indirect,
        email: undefined,
        first_name: 'Manfred',
        last_name: 'Mangold',
        // role: Role.Member,
        state: ActiveState.Invited,
      },
      {
        id: '4dx27be5fbdfefx',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5bd5x7b6xog8dfzvd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '6dg7s6tfsxgxyhb4fd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7dff7x456bfxgvc',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '81fvdmdfbfkz56jd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'xe09-r6z3dsfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '10ef-r6xfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1r2-r65xbf',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd3ddgyd-dsve7dg',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '14231-513s2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5-15',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '511s3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '512d6ge261',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7f37ze62',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1ds3f4z5f1ds53',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1zs151dddf3g5',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '42sfd37gl6dd73z2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1s3wzdd5sw3rf3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '124s1d4fz513d5f1',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's35dz37z4ej5d2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '34vzx74f6j3s',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's5e64d5d6d71',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3d4s6yd3f42',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '63f4g6zzs4dxf6ed3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '241f25413d43d3s1g2',
        community: {
          id: '2912982z191261',
          logo: {
            id: '123',
            url: stock1,
          },
          name: 'Gemeinschaft 1',
        },
        avatar: {
          id: '1',
          url: stock5,
        },
        email: 'hubert.schmidt@pengueen.de',
        connection: ConnectionType.Indirect,
        first_name: 'Hubert',
        last_name: 'Schmidt',
        // role: Role.Employee,
        state: ActiveState.Active,
      },
      {
        id: '219s1hd0d8hv1d',
        community: {
          id: '291298612391',
          logo: {
            id: '456',
            url: stock2,
          },
          name: 'Gemeinschaft 2',
        },
        avatar: {
          id: '2',
          url: stock4,
        },
        connection: ConnectionType.Direct,
        email: 'trude.petersen@pengueen.de',
        first_name: 'Trude',
        last_name: 'Petersen',
        // role: Role.Admin,
        state: ActiveState.Active,
      },
      {
        id: '21333d1u310h31',
        community: {
          id: '290216926412',
          logo: {
            id: '789',
            url: stock3,
          },
          name: 'Gemeinschaft 3',
        },
        avatar: {
          id: '3',
          url: stock6,
        },
        connection: ConnectionType.Indirect,
        email: undefined,
        first_name: 'Manfred',
        last_name: 'Mangold',
        // role: Role.Member,
        state: ActiveState.Invited,
      },
      {
        id: '4dx27be5fbbdfefx',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5bd5x7b6xogf8dfzvd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '6dg7s6tfsxgxyghb4fd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7dfff7x456bfxgvc',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '81fvdmydfbfkz56jd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '9363d',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '10edf-r6xfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1w2-r65xbf',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd3ddgiuyd-dsve7dg',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1d4231-513s2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'dfg2-15',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5d11s3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '512d6gex261',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7f37zle62',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1ds3f4z5f1gds53',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1zs1d51dddf3g5',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '42sfd37gl6jdd73z2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'b1s3wzdd5sw3rf3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1241451351',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's35dz37z4dej5d2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '346d3463',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's5e6z4d5d6d71',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3d4s6ymd3f42',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '63f4g6zzs4gdxf6ed3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },

      {
        id: '241f2g3d3s1g2',
        community: {
          id: '2912982z191261',
          logo: {
            id: '123',
            url: stock1,
          },
          name: 'Gemeinschaft 1',
        },
        avatar: {
          id: '1',
          url: stock5,
        },
        email: 'hubert.schmidt@pengueen.de',
        connection: ConnectionType.Indirect,
        first_name: 'Hubert',
        last_name: 'Schmidt',
        // role: Role.Employee,
        state: ActiveState.Active,
      },
      {
        id: '219s1hd0dx8hv1d',
        community: {
          id: '291298612391',
          logo: {
            id: '456',
            url: stock2,
          },
          name: 'Gemeinschaft 2',
        },
        avatar: {
          id: '2',
          url: stock4,
        },
        connection: ConnectionType.Direct,
        email: 'trude.petersen@pengueen.de',
        first_name: 'Trude',
        last_name: 'Petersen',
        // role: Role.Admin,
        state: ActiveState.Active,
      },
      {
        id: '213331u310h31',
        community: {
          id: '290216926412',
          logo: {
            id: '789',
            url: stock3,
          },
          name: 'Gemeinschaft 3',
        },
        avatar: {
          id: '3',
          url: stock6,
        },
        connection: ConnectionType.Indirect,
        email: undefined,
        first_name: 'Manfred',
        last_name: 'Mangold',
        // role: Role.Member,
        state: ActiveState.Invited,
      },
      {
        id: '4dx27be5fbfbdfefx',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5bd5xx7b6xogf8dfzvd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '6dg7s6tfsxgsxyghb4fd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7dffff7x456bfxgvc',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '81vkjd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '936fd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '10edf-r6fxfd',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1w4-r65xbf',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd3ddcvgiuyd-dsve7dg',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1d42f31-513s2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'dfgd2-15',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '5d11s35',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '512d36gex261',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'd7f378zle62',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1ds3f4fz5f1gds53',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'z1zs1d51dddf3g5',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '42sfd37gl6jdvd73z2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 'b1s3wzhdd5sw3rf3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '1241d451351',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3534d2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '346d3463s',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: 's5e6z4md5d6d71',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '3d4s6ymd3f4n2',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
      {
        id: '63f4g6zzsd4gdxf6ed3',
        connection: ConnectionType.Indirect,
        email: 'test@pengueen.de',
        state: ActiveState.Active,
      },
    ];
  };

  const contactsBaseDataGet = (): Contact[] => {
    return [
      {
        invitor: true,
        nickname: '503',
        avatar: undefined,
        id: 'c5894781-1da9-4b7d-a971-610e5250aafc',
        state: ActiveState.Active,
        connection: ConnectionType.Direct,
        email: 'cat503@gmail.com',
        first_name: 'Cat',
        last_name: undefined,
        phone: undefined,
        address: {
          country: undefined,
          street: undefined,
          house_number: undefined,
          place: undefined,
          zip_code: undefined,
        },
        tool_informations: [
          undefined,
          {
            id: '1532',
            name: 'Stammdaten',
            description: 'Information für Zeit',
            information: 'Vormittags',
          },
        ],
      },
      {
        invitor: true,
        nickname: 'Bear234',
        avatar: undefined,
        id: 'eb0b45a8-212b-4eb5-b72d-36a22e8ce061',
        state: ActiveState.Invited,
        connection: ConnectionType.Community,
        email: 'bear@pengueen.de',
        first_name: 'Bear',
        last_name: undefined,
        phone: undefined,
        address: {
          country: undefined,
          street: undefined,
          house_number: undefined,
          place: undefined,
          zip_code: undefined,
        },
        tool_informations: [
          {
            id: '6951',
            name: 'Stammdaten',
            description: 'Information für Sprache',
            information: 'Spanisch, Italienisch',
          },
          undefined,
        ],
      },
      {
        avatar: {
          id: '509c90ca-fb10-491b-a398-4ef75bb97d89',
          url: 'https://files.pengueen.de/api/download/eda1ea06-46c6-475f-83de-c45567770ce1.jpeg',
        },
        id: '625f31fa-180e-4096-834e-3b726fceed71',
        state: ActiveState.Active,
        connection: ConnectionType.Group,
        email: 'apple@pengueen.de',
        first_name: 'Apple',
        last_name: undefined,
        phone: undefined,
        address: {
          country: 'de',
          street: undefined,
          house_number: undefined,
          place: undefined,
          zip_code: undefined,
        },
        tool_informations: [
          {
            id: '',
            name: 'Stammdaten',
            description: 'Information für Sprache',
            information: 'Deutsch, Englisch',
          },
          undefined,
        ],
      },
    ];
  };

  const contactsTitleGet = (): string[] => {
    return ['Information für Sprache', 'Information für Zeit'];
  };

  /**
   * GET Contact mock (random direct / indirect)
   * @returns Contact mock
   * @deprecated To be removed
   */
  const contactMockGet = (): Contact => {
    const contactsMock: Contact[] = [
      {
        id: 'f71039ee-600a-4b75-85a4-07fa234530e7',
        address: {
          country: 'Deutschland',
          house_number: '34',
          place: 'Berlin',
          street: 'Unter den Linden',
          zip_code: '10234',
        },
        avatar: {
          id: '0b94acbf-3180-4c17-a983-3b39840c3fbf',
          url: stock5,
        },
        email: 'hubert.direkt@pengueen.de',
        connection: ConnectionType.Direct,
        first_name: 'Hubert',
        last_name: 'Direkt',
        phone: '012 345 6789 098',
        state: ActiveState.Active,
      },
      {
        id: '6d6b92ab-eaa7-4a83-b66d-53ebf2d22a3a',
        email: 'hubert.indirekt@pengueen.de',
        connection: ConnectionType.Indirect,
        first_name: 'Hubert',
        last_name: 'Indirekt',
        state: ActiveState.Active,
      },
    ];

    // Returns
    return contactsMock[Math.floor(Math.random() * 2)];
  };

  /**
   * GET tool mock data.
   * @returns Tool mock data.
   */
  const toolGet = (): ContactDetailTool => {
    return {
      id: '8921821-23812842',
      category: 'Betreuung',
      create_change_info: {
        changed_at: new Date().toISOString(),
        created_at: new Date().toISOString(),
        created_by: {
          profile: {
            personal_data: {
              first_name: 'Hubertus',
              last_name: 'Mertus',
            },
          },
        },
      },
      image_preview: {
        id: '291274-3151315',
        url: stock3,
      },
      name: 'Fallmanagement XY632-21',
    };
  };

  const contactsCommunityGet = (): ContactCommunity => {
    return {
      id: 'bcefa8be-7ae8-4ba9-8e43-9810f33c90ec',
      name: 'Community 1',
      avatar: {
        id: '8d3c5459-1d1f-4409-b825-501eb3f43b9',
        url: 'https://files.pengueen.de/api/download/385d20be-edd6-4b61-9cdb-f26055d09ac0.png',
      },
      tools: [
        {
          id: '609cba3a-7db2-47a4-8c13-fb7633e89fe4',
          changes: 0,
          community: {
            data: {
              id: 'bcefa8be-7ae8-4ba9-8e43-9810f33c90ec',
              name: 'Community 1',
              logo: {
                id: '30vjkkefadivjkv',
                url: 'https://files.pengueen.de/api/download/03e410df-b9fc-4763-993d-a0e350da6467.png',
              },
            },
          },
          name: 'Dokument',
          template: {
            icon: {
              id: '21',
              url: '/static/media/ka_barrierefrei-wohnen.4484abc2c2c83afb55e4a7a8f881128d.svg',
            },
            name: 'Baukasten',
          },
        },
        {
          id: '0b57c8b4-943e-4999-a32b-030f61145e34',
          changes: 0,
          community: {
            data: {
              id: 'bcefa8be-7ae8-4ba9-8e43-9810f33c90ec',
              name: 'Community 1',
              logo: {
                id: '30vjkkefadivjkv',
                url: 'https://files.pengueen.de/api/download/03e410df-b9fc-4763-993d-a0e350da6467.png',
              },
            },
          },
          name: 'Table',
          template: {
            icon: {
              id: '159',
              url: '/static/media/ka_termine.633e8835e10df88058424ca76afb6268.svg',
            },
            name: 'Zeit',
          },
        },
        {
          id: '1ec053cf-68b8-4827-b2fc-11c78c760601',
          changes: 0,
          community: {
            data: {
              id: 'bcefa8be-7ae8-4ba9-8e43-9810f33c90ec',
              name: 'Community 1',
              logo: {
                id: '30vjkkefadivjkv',
                url: 'https://files.pengueen.de/api/download/03e410df-b9fc-4763-993d-a0e350da6467.png',
              },
            },
          },
          name: 'Projektabstimmung',
          template: {
            icon: {
              id: '15',
              url: '/static/media/ka_assessment_dekubitusfaktoren.3a36467c499dce50082acd3c04f4f45d.svg',
            },
            name: 'Project',
          },
        },
      ],
    };
  };
  const contactsCommunitiesGet = (): ContactCommunity[] => {
    return [
      {
        id: 'bcefa8be-7ae8-4ba9-8e43-9810f33c90ec',
        name: 'Community 1',
        tools: [
          {
            id: '609cba3a-7db2-47a4-8c13-fb7633e89fe4',
            changes: 0,
            community: {
              data: {
                id: 'bcefa8be-7ae8-4ba9-8e43-9810f33c90ec',
                name: 'Community 1',
                logo: {
                  id: '30vjkkefadivjkv',
                  url: 'https://files.pengueen.de/api/download/03e410df-b9fc-4763-993d-a0e350da6467.png',
                },
              },
            },
            name: 'Dokument',
            template: {
              icon: {
                id: '21',
                url: '/static/media/ka_barrierefrei-wohnen.4484abc2c2c83afb55e4a7a8f881128d.svg',
              },
              name: 'Baukasten',
            },
          },
          {
            id: '0b57c8b4-943e-4999-a32b-030f61145e34',
            changes: 0,
            community: {
              data: {
                id: 'bcefa8be-7ae8-4ba9-8e43-9810f33c90ec',
                name: 'Community 1',
                logo: {
                  id: '30vjkkefadivjkv',
                  url: 'https://files.pengueen.de/api/download/03e410df-b9fc-4763-993d-a0e350da6467.png',
                },
              },
            },
            name: 'Table',
            template: {
              icon: {
                id: '159',
                url: '/static/media/ka_termine.633e8835e10df88058424ca76afb6268.svg',
              },
              name: 'Zeit',
            },
          },
          {
            id: '1ec053cf-68b8-4827-b2fc-11c78c760601',
            changes: 0,
            community: {
              data: {
                id: 'bcefa8be-7ae8-4ba9-8e43-9810f33c90ec',
                name: 'Community 1',
                logo: {
                  id: '30vjkkefadivjkv',
                  url: 'https://files.pengueen.de/api/download/03e410df-b9fc-4763-993d-a0e350da6467.png',
                },
              },
            },
            name: 'Projektabstimmung',
            template: {
              icon: {
                id: '15',
                url: '/static/media/ka_assessment_dekubitusfaktoren.3a36467c499dce50082acd3c04f4f45d.svg',
              },
              name: 'Project',
            },
          },
        ],
        groups: [
          {
            name: 'Group1',
            id: 'dfkj0fjkdj-e89dfjk',
            description: 'Group1 description',
            community: {
              id: 'bcefa8be-7ae8-4ba9-8e43-9810f33c90ec',
              name: 'Community 1',
              logo: {
                id: '30vjkkefadivjkv',
                url: 'https://files.pengueen.de/api/download/03e410df-b9fc-4763-993d-a0e350da6467.png',
              },
            },
          },
        ],
      },
    ];
  };

  const contactsGroupsGet = (): CommunityGroup[] => {
    return [
      {
        name: 'Group1',
        id: '234dfkj-39df0fjkdj-e89dfjk',
        description: 'Group1 description',
        community: {
          id: 'bcefa8be-7ae8-4ba9-8e43-9810f33c90ec',
          name: '1',
        },
      },
      {
        name: 'Group2',
        id: 'n9dfdfkj0fj-df3kdj-e89dfjk',
        description: 'Group2 description',
      },
    ];
  };

  const contactsToolsGet = (): ToolFavorite[] => {
    return [
      {
        id: '609cba3a-7db2-47a4-8c13-fb7633e89fe4',
        changes: 0,
        community: {
          data: {
            id: 'bcefa8be-7ae8-4ba9-8e43-9810f33c90ec',
            name: 'Community 1',
            logo: {
              id: '30vjkkefadivjkv',
              url: 'https://files.pengueen.de/api/download/03e410df-b9fc-4763-993d-a0e350da6467.png',
            },
          },
        },
        name: 'Dokument',
        template: {
          icon: {
            id: '21',
            url: '/static/media/ka_barrierefrei-wohnen.4484abc2c2c83afb55e4a7a8f881128d.svg',
          },
          name: 'Baukasten',
        },
      },
      {
        id: '0b57c8b4-943e-4999-a32b-030f61145e34',
        changes: 0,
        community: {
          data: {
            id: 'bcefa8be-7ae8-4ba9-8e43-9810f33c90ec',
            name: 'Community 1',
          },
        },
        name: 'Table',
        template: {
          icon: {
            id: '159',
            url: '/static/media/ka_termine.633e8835e10df88058424ca76afb6268.svg',
          },
          name: 'Zeit',
        },
      },
      {
        id: '1ec053cf-68b8-4827-b2fc-11c78c760601',
        changes: 0,
        community: {
          data: {
            id: 'bcefa8be-7ae8-4ba9-8e43-9810f33c90ec',
            name: 'Community 1',
            logo: {
              id: '30vjkkefadivjkv',
              url: 'https://files.pengueen.de/api/download/03e410df-b9fc-4763-993d-a0e350da6467.png',
            },
          },
        },
        name: 'Projektabstimmung',
        template: {
          icon: {
            id: '15',
            url: '/static/media/ka_assessment_dekubitusfaktoren.3a36467c499dce50082acd3c04f4f45d.svg',
          },
          name: 'Project',
        },
      },
    ];
  };

  const contactsCommunityNavGet = (): CommunityNav[] => {
    return [
      {
        id: '123fv54d-dfq34v45t-4qdad',
        // avatar: {
        //   url: 'https://files.pengueen.de/api/download/03e410df-b9fc-4763-993d-a0e350da6467.png',
        //   id: '498cfvkdj0-38fadkk3kjfk',
        // },
        name: 'Gemeinschaft 1',
        groups: [
          {
            id: '23x24-f3vfd345f',
            name: 'Group with Chat, no folder',
            description: 'Group description',
            chat_room: {
              id: '2139fdjk-38yuerhjd',
              // type: RoomType.Group,
              // messages: [],
              // permissions: {
              //   can_add_user: true,
              //   can_delete_group: true,
              //   can_edit_settings: true,
              //   can_link_community: true,
              // },
              // users: [],
            },
          },
          {
            id: '3f9cxhn-d83ehj',
            name: 'Group no Chat, no folder',
            description: 'Group description',
          },
        ],
        folders: [
          {
            id: '39fcdjhnj-3498fudknhj',
            name: 'Folder 1',
            groups: [
              {
                id: '293jdk-389fhjkdhf-389fhdjkfh',
                name: 'Group1 with Chat in Folder',
                chat_room: {
                  id: '39knjdf-qja90dfmnz-df',
                },
                description: 'Group description',
              },
              {
                id: 'd98kjkv-q39ujknv-q398ufkdjf',
                name: 'Group2 no Chat in Folder',
                description: 'Group description',
              },
            ],
          },
          {
            id: '1239fi-38fdjkhf',
            name: 'Folder 2',
            groups: [
              {
                id: 'd98kjdkv-q39ujknrv-q398ufekdjf',
                name: 'Group3 no Chat in Folder',
                description: 'Group description',
              },
            ],
          },
        ],
        community_links: [
          { url: '89fvcdjh3-df8abq3jbrdf', name: 'Comm Link 1' },
        ],
        tool_links: [
          { url: '12349ghj-3asdjfh98', name: 'Tool Link 1' },
          { url: '39fadkjnh3q4-f8yq j34rk', name: 'Tool Link2' },
        ],
      },
    ];
  };

  const contactsFoldersGet = (): CommunityFolder[] => {
    return [
      {
        id: '3984ncvkdj-39489dvnd',
        name: 'Folder1',
        groups: [
          {
            name: 'Group3',
            id: 'df3rdkj-39df0fjdkdj-e89dfjk',
            description: 'Group3 description',
          },
          {
            name: 'Group4',
            id: '5fgbs-34dfdv-kdjfk-57dfbhg',
            description: 'Group4 description',
          },
        ],
      },
      {
        id: '3984ncvkdj-39489dvdnd',
        name: 'Folder2',
        groups: [
          {
            name: 'Group3',
            id: 'df3rdkj-39df0fjdkdj-e89dfjk',
            description: 'Group3 description',
          },
          {
            name: 'Group4',
            id: '5fgbs-34dfdv-kdjfk-57dfbhg',
            description: 'Group4 description',
          },
        ],
      },
    ];
  };

  return {
    contactMock1Get,
    contactsMockGet,
    contactMockGet,
    contactsBaseDataGet,
    contactsTitleGet,
    contactsToolsGet,
    contactsGroupsGet,
    contactsCommunityGet,
    contactsCommunitiesGet,
    contactsCommunityNavGet,
    contactsFoldersGet,
    toolGet,
  };
};
