import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import clsx from 'clsx';

// Models
import { BreadcrumbPath } from '../../models/shared.types';

// Styles
import styles from './Breadcrumbs.module.scss';

type BreadcrumbsProps = {
  classes?: string;
  path: BreadcrumbPath[];
};

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  return (
    <div className={styles['breadcrumbs']}>
      {props.path.map((item: BreadcrumbPath, index: number) => {
        if (index < props.path.length - 1) {
          return (
            <Box
              key={index}
              className={clsx(
                styles['breadcrumbs-item'],
                props.classes && props.classes
              )}
              sx={{
                '& #link': {
                  color: 'text.secondary',
                },
                '& #link:hover': {
                  color: 'text.primary',
                },
              }}
            >
              <Link
                className={styles['breadcrumbs-item-link']}
                id="link"
                to={item.location!}
              >
                {item.title}
              </Link>
              <Box
                className={styles['breadcrumbs-item-divider']}
                sx={{ color: 'text.secondary' }}
              >
                /
              </Box>
            </Box>
          );
        } else {
          return (
            <div key={index} className={styles['breadcrumbs-item']}>
              {item.title}
            </div>
          );
        }
      })}
    </div>
  );
};
