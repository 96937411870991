import { ReactNode, useCallback, useEffect, useState } from 'react';
import { TFunctionResult } from 'i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  SxProps,
  Theme,
} from '@mui/material';
import clsx from 'clsx';

// Components
import { Icon } from '../Icon/Icon';

// Styles
import styles from './AccordionCard.module.scss';

type AccordionCardProps = {
  count?: number;
  index?: number;
  children: ReactNode;
  classes?: string;
  cssId?: string;
  defaultExpanded?: boolean;
  detailsClasses?: string;
  detailsPaddingClasses?: string;
  disabledExpanded?: boolean;
  expanded?: boolean;
  preset?: 'tools';
  summary?: ReactNode;
  summarySx?: SxProps<Theme>;
  title: TFunctionResult | string | ReactNode;
  width?: string;
  onChange?: (value: any) => void;
};

type sxSummary = {
  borderColor: string | undefined;
  summaryBackgroundColor: string | undefined;
  summaryBorder: string | undefined;
  summaryBorderColor: string | undefined;
  summaryBorderRadius: string | undefined;
};

export const AccordionCard = (props: AccordionCardProps) => {
  // Component state
  const [expanded, setExpanded] = useState<boolean>(
    props.defaultExpanded ?? false
  );
  const [sxObj, setSxObj] = useState<sxSummary>({
    borderColor: 'bg.card',
    summaryBackgroundColor: 'bg.card',
    summaryBorder: undefined,
    summaryBorderColor: undefined,
    summaryBorderRadius: undefined,
  });

  // Set expanded by prop
  useEffect(() => {
    if (typeof props.expanded === 'undefined') {
      setExpanded(true);
    }

    if (true === props.expanded || false === props.expanded) {
      setExpanded(props.expanded);
    }
  }, [props.expanded]);

  // Set accordion style properties by expanded state and preset
  useEffect(() => {
    switch (props.preset) {
      case 'tools':
        if (expanded) {
          setSxObj({
            borderColor: 'transparent',
            summaryBackgroundColor: 'transparent',
            summaryBorder: '1px solid',
            summaryBorderColor: 'primary.main',
            summaryBorderRadius: '4px',
          });
        } else {
          setSxObj({
            borderColor: 'transparent',
            summaryBackgroundColor: 'bg.card',
            summaryBorder: '1px solid',
            summaryBorderColor: 'bg.card',
            summaryBorderRadius: '4px',
          });
        }
        break;
      default:
        break;
    }
  }, [expanded, props.preset]);

  return (
    <Accordion
      className={clsx(styles['accordion-card'], props.classes && props.classes)}
      //defaultExpanded={props.defaultExpanded}
      disableGutters
      elevation={0}
      expanded={expanded}
      id={props.cssId}
      sx={{
        borderColor: sxObj.borderColor,
        width: props.width ?? '100% !important',
      }}
      onChange={(event: React.SyntheticEvent, updatedExpanded: boolean) => {
        //if (!props.disabledExpanded) {
        //updatedExpanded !== expanded && setExpanded(updatedExpanded);
        props.onChange && props.onChange(props.index);
        //}
      }}
    >
      {/* Not able to change accordion borders directly, so extra container here ... */}
      <Box
        sx={{
          border: sxObj.summaryBorder,
          borderColor: sxObj.summaryBorderColor,
          borderRadius: sxObj.summaryBorderRadius,
        }}
      >
        <AccordionSummary
          expandIcon={<Icon icon={['fas', 'chevron-down']} />}
          sx={{
            ...props.summarySx,
            backgroundColor: sxObj.summaryBackgroundColor,
            borderRadius: sxObj.summaryBorderRadius,
            fontWeight: props.preset === 'tools' ? 600 : undefined,
          }}
          className={styles['accordion-card-summary']}
        >
          {props.summary ?? (
            <>
              {props.count !== undefined && (
                <Box
                  sx={{ color: 'text.secondary' }}
                  className={styles['accordion-card-summary-count']}
                >
                  {props.count}
                </Box>
              )}
              <div>{props.title}</div>
            </>
          )}
        </AccordionSummary>
      </Box>
      <AccordionDetails
        className={clsx(
          props.detailsClasses && props.detailsClasses,
          props.detailsPaddingClasses ??
            styles['accordion-card-details-padding']
        )}
      >
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
};
