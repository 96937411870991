import create from 'zustand';

// Models
import {
  Template,
  TemplateType,
  TemplateUseCase,
} from '../../templates/models/templates.types';

export interface MarketState {
  levelAdvanced: boolean;
  levelBeginner: boolean;
  levelExpert: boolean;
  purposeCommunity: boolean;
  purposeLiving: boolean;
  search: string;
  selectedMenuItemType: TemplateType;
  selectedTags: string[];
  selectedTemplate: Template | undefined;
  selectedUseCases: TemplateUseCase[];
  tags: {
    id: string;
    name: string;
  }[];
  templateAll: boolean;
  templateNewest: boolean;
  templatePopular: boolean;
  templateDocument: boolean;
  templateProject: boolean;
  templateUseOther: boolean;
  setLevelAdvanced: (levelAdvanced: boolean) => void;
  setLevelBeginner: (levelBeginner: boolean) => void;
  setLevelExpert: (levelExpert: boolean) => void;
  setPurposeCommunity: (purposeCommunity: boolean) => void;
  setPurposeLivng: (purposeLiving: boolean) => void;
  setSearch: (search: string) => void;
  setSelectedMenuItemType: (selectedMenuItemType: TemplateType) => void;
  setSelectedTags: (selectedTags: string[]) => void;
  setSelectedTemplate: (selectedTemplate: Template | undefined) => void;
  setSelectedUseCases: (selectedUseCases: TemplateUseCase[]) => void;
  setTags: (tags: { id: string; name: string }[]) => void;
  setTemplateAll: (templateAll: boolean) => void;
  setTemplateNewest: (templateNewest: boolean) => void;
  setTemplatePopular: (templatePopular: boolean) => void;
  setTemplateDocument: (templateDocument: boolean) => void;
  setTemplateProject: (templateProject: boolean) => void;
  setTemplateUseOther: (templateUseOther: boolean) => void;
}

export const initialMarketFiltersData = {
  levelAdvanced: false,
  levelBeginner: false,
  levelExpert: false,
  purposeCommunity: false,
  purposeLiving: false,
  templateAll: false,
  templateNewest: false,
  templatePopular: false,
};

export const useMarketStore = create<MarketState>((set) => ({
  ...initialMarketFiltersData,
  search: '',
  selectedMenuItemType: TemplateType.Public,
  selectedTags: [],
  selectedTemplate: undefined,
  selectedUseCases: [],
  tags: [],
  templateDocument: false,
  templateProject: false,
  templateUseOther: false,
  setPurposeCommunity: (purposeCommunity: boolean) => set({ purposeCommunity }),
  setPurposeLivng: (purposeLiving: boolean) => set({ purposeLiving }),
  setLevelAdvanced: (levelAdvanced: boolean) => set({ levelAdvanced }),
  setLevelBeginner: (levelBeginner: boolean) => set({ levelBeginner }),
  setLevelExpert: (levelExpert: boolean) => set({ levelExpert }),
  setSearch: (search: string) => set({ search }),
  setSelectedMenuItemType: (selectedMenuItemType: TemplateType) =>
    set({ selectedMenuItemType }),
  setSelectedTags: (selectedTags: string[]) => set({ selectedTags }),
  setSelectedTemplate: (selectedTemplate: Template | undefined) =>
    set({ selectedTemplate }),
  setSelectedUseCases: (selectedUseCases: TemplateUseCase[]) =>
    set({ selectedUseCases }),
  setTags: (tags: { id: string; name: string }[]) => set({ tags }),
  setTemplateAll: (templateAll: boolean) => set({ templateAll }),
  setTemplateNewest: (templateNewest: boolean) => set({ templateNewest }),
  setTemplatePopular: (templatePopular: boolean) => set({ templatePopular }),
  setTemplateDocument: (templateDocument: boolean) => set({ templateDocument }),
  setTemplateProject: (templateProject: boolean) => set({ templateProject }),
  setTemplateUseOther: (templateUseOther: boolean) => set({ templateUseOther }),
}));
