import create, { State } from 'zustand';

// Models
import {
  SummaryValue,
  Tool,
  ToolElementImageDeleteRequest,
  ToolElementScroll,
  ToolSectionElementRowIds,
  ToolValuesMultiElementRow,
} from '../models/tools.types';

export interface ToolsState extends State {
  isToolLoading: boolean;
  fileToDelete: ToolElementImageDeleteRequest[];
  tool: Tool | undefined;
  toolCommunity: boolean;
  toolBlueprint: boolean;
  toolForCommunity: boolean;
  toolPersonal: boolean;
  toolPublish: boolean;
  toolPublic: boolean;
  toolAdmin: boolean;
  toolDocumentation: boolean;
  toolOther: boolean;
  toolProjectManagement: boolean;
  canIEditTool: boolean;
  hasFullAccess: boolean;
  multiElementReadCollapseAll: string | undefined;
  sectionScrollInto: string | undefined;
  sectionElementScrollInto: ToolElementScroll | undefined;
  selectedMultiElementRowCreate: ToolSectionElementRowIds | undefined;
  selectedMultiElementRowEdit: ToolSectionElementRowIds | undefined;
  selectedTool: Tool | undefined;
  voteSummary: SummaryValue[] | undefined;
  multiElementRowValuesInit: ToolValuesMultiElementRow | undefined;
  setIsToolLoading: (isToolLoading: boolean) => void;
  resetToolsStoreData: () => void;
  setFileToDelete: (fileToDelete: ToolElementImageDeleteRequest[]) => void;
  setTool: (tool: Tool | undefined) => void;
  setMultiElementRowValuesInit: (
    multiElementRowValuesInit: ToolValuesMultiElementRow | undefined
  ) => void;
  multiElementRowValuesUpdated: ToolValuesMultiElementRow | undefined;
  setMultiElementRowValuesUpdated: (
    multiElementRowValuesUpdated: ToolValuesMultiElementRow | undefined
  ) => void;
  setToolCommunity: (toolCommunity: boolean) => void;
  setToolBlueprint: (toolBlueprint: boolean) => void;
  setToolForCommunity: (toolForCommunity: boolean) => void;
  setToolPersonal: (toolPersonal: boolean) => void;
  setToolPublish: (toolPublish: boolean) => void;
  setToolPublic: (toolPublic: boolean) => void;
  setToolAdmin: (toolAdmin: boolean) => void;
  setToolDocumentation: (toolDocumentation: boolean) => void;
  setToolOther: (toolOther: boolean) => void;
  setToolProjectManagement: (toolProjectManagement: boolean) => void;
  setCanIEditTool: (canIEditTool: boolean) => void;
  setHasFullAccess: (hasFullAccess: boolean) => void;
  setMultiElementReadCollapseAll: (
    multiElementReadCollapseAll: string | undefined
  ) => void;
  setSectionScrollInto: (sectionScrollInto: string | undefined) => void;
  setSectionElementScrollInto: (
    sectionElementScrollInto: ToolElementScroll | undefined
  ) => void;
  setSelectedMultiElementRowCreate: (
    selectedMultiElementRowCreate: ToolSectionElementRowIds | undefined
  ) => void;
  setSelectedMultiElementRowEdit: (
    selectedMultiElementRowEdit: ToolSectionElementRowIds | undefined
  ) => void;
  setSelectedTool: (selectedTool: Tool | undefined) => void;
  selectedMultiElementRowEditPatch: ToolSectionElementRowIds | undefined;
  setSelectedMultiElementRowEditPatch: (
    selectedMultiElementRowEdit: ToolSectionElementRowIds | undefined
  ) => void;
  setVoteSummary: (voteSummary: SummaryValue[] | undefined) => void;
}

export const initialToolsData = {
  toolCommunity: false,
  toolBlueprint: false,
  toolForCommunity: false,
  toolPersonal: false,
  toolPublish: false,
  toolPublic: false,
  toolAdmin: false,
  toolDocumentation: false,
  toolOther: false,
  toolProjectManagement: false,
  canIEditTool: false,
  hasFullAccess: false,
  multiElementReadCollapseAll: undefined,
  sectionScrollInto: undefined,
  sectionElementScrollInto: undefined,
  selectedMultiElementRowCreate: undefined,
  selectedMultiElementRowEdit: undefined,
  selectedTool: undefined,
  multiElementRowValuesInit: undefined,
  multiElementRowValuesUpdated: undefined,
  voteSummary: undefined,
};

export const useToolsStore = create<ToolsState>((set) => ({
  isToolLoading: false,
  fileToDelete: [],
  tool: undefined,
  toolCommunity: false,
  toolBlueprint: false,
  toolForCommunity: false,
  toolPersonal: false,
  toolPublish: false,
  toolPublic: false,
  toolAdmin: false,
  toolDocumentation: false,
  toolOther: false,
  toolProjectManagement: false,
  canIEditTool: false,
  hasFullAccess: false,
  multiElementReadCollapseAll: undefined,
  sectionScrollInto: undefined,
  sectionElementScrollInto: undefined,
  selectedMultiElementRowCreate: undefined,
  selectedMultiElementRowEdit: undefined,
  selectedTool: undefined,
  multiElementRowValuesInit: undefined,
  multiElementRowValuesUpdated: undefined,
  voteSummary: undefined,
  setIsToolLoading: (isToolLoading: boolean) => set({ isToolLoading }),
  resetToolsStoreData: () =>
    set((state: ToolsState) => ({
      ...state,
      ...initialToolsData,
    })),
  setFileToDelete: (fileToDelete: ToolElementImageDeleteRequest[]) =>
    set({ fileToDelete }),
  setTool: (tool: Tool | undefined) => set({ tool }),
  setMultiElementRowValuesInit: (
    multiElementRowValuesInit: ToolValuesMultiElementRow | undefined
  ) => set({ multiElementRowValuesInit }),
  setMultiElementRowValuesUpdated: (
    multiElementRowValuesUpdated: ToolValuesMultiElementRow | undefined
  ) => set({ multiElementRowValuesUpdated }),
  setToolCommunity: (toolCommunity: boolean) => set({ toolCommunity }),
  setToolBlueprint: (toolBlueprint: boolean) => set({ toolBlueprint }),
  setToolForCommunity: (toolForCommunity: boolean) => set({ toolForCommunity }),
  setToolPersonal: (toolPersonal: boolean) => set({ toolPersonal }),
  setToolPublish: (toolPublish: boolean) => set({ toolPublish }),
  setToolPublic: (toolPublic: boolean) => set({ toolPublic }),
  setToolAdmin: (toolAdmin: boolean) => set({ toolAdmin }),
  setToolDocumentation: (toolDocumentation: boolean) =>
    set({ toolDocumentation }),
  setToolOther: (toolOther: boolean) => set({ toolOther }),
  setToolProjectManagement: (toolProjectManagement: boolean) =>
    set({ toolProjectManagement }),
  setCanIEditTool: (canIEditTool: boolean) => set({ canIEditTool }),
  setHasFullAccess: (hasFullAccess: boolean) => set({ hasFullAccess }),
  setMultiElementReadCollapseAll: (
    multiElementReadCollapseAll: string | undefined
  ) => set({ multiElementReadCollapseAll }),
  setSectionScrollInto: (sectionScrollInto: string | undefined) =>
    set({ sectionScrollInto }),
  setSectionElementScrollInto: (
    sectionElementScrollInto: ToolElementScroll | undefined
  ) => set({ sectionElementScrollInto }),
  setSelectedMultiElementRowCreate: (
    selectedMultiElementRowCreate: ToolSectionElementRowIds | undefined
  ) => set({ selectedMultiElementRowCreate }),
  setSelectedMultiElementRowEdit: (
    selectedMultiElementRowEdit: ToolSectionElementRowIds | undefined
  ) => set({ selectedMultiElementRowEdit }),
  setSelectedTool: (selectedTool: Tool | undefined) => set({ selectedTool }),
  selectedMultiElementRowEditPatch: undefined,
  setSelectedMultiElementRowEditPatch: (
    selectedMultiElementRowEditPatch: ToolSectionElementRowIds | undefined
  ) => set({ selectedMultiElementRowEditPatch }),
  setVoteSummary: (voteSummary: SummaryValue[] | undefined) =>
    set({ voteSummary }),
}));
