// Models
import {
  CreateChangeInfo,
  Attachment,
  AttachmentFile,
} from '../../../shared/models/shared.types';
import { User } from '../../user/models/user.types';
import { RoomType } from './news-center.types';

export interface Message {
  id: string;
  content: string;
  create_change_info: CreateChangeInfo;
  files?: AttachmentFile[];
  images?: Attachment[];
  quote?: Message;
  read: Partial<User>[];
  send_email?: boolean;
  send_sms?: boolean;
  info?: InfoMessage;
}

export enum MessageAction {
  Delete = 'DELETE',
  Edit = 'EDIT',
  Quote = 'QUOTE',
}

export interface MessageCreateEditRequest {
  accessToken: string;
  files: SocketFile[];
  message: MessagePostContentRequest;
  message_id?: string;
  room_id: string;
  type?: RoomType;
  tool_id?: string;
  row_id?: string;
}

interface MessagePostContentRequest {
  content: string;
  quote?: string;
  send_email: boolean;
  send_sms: boolean;
  info?: InfoMessage | undefined;
}

export interface InfoMessage {
  type?: InfoMessageType;
  sub?: string;
  obj?: string;
  tool_id?: string;
}

export enum InfoMessageType {
  leave = 'LEAVE',
  invite = 'INVITE',
  close = 'CLOSE',
  remove = 'REMOVE',
  join = 'JOIN',
  ToolChatGroup = 'TOOLCHAT_GROUP',
  ToolChatMultiRow = 'TOOLCHAT_MULTIROW',
}

export interface SocketFile {
  fileName: string;
  file: File;
}
