// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MarketTemplateUse_market-template-use__rpNZx {\n  display: flex;\n  flex-direction: column }\n  .MarketTemplateUse_market-template-use-submit__4gZ-L {\n  margin-top: 0.5rem;\n  align-self: flex-end }\n  .MarketTemplateUse_market-template-use-community__mcgsp {\n  margin-top: 1rem;\n  margin-bottom: 0.5rem }\n\n@media (min-width: 1024px) {\n  .MarketTemplateUse_market-template-use-submit__4gZ-L {\n    margin-top: 1rem } }\n", "",{"version":3,"sources":["<no source>","webpack://./src/modules/market/components/MarketTemplateUse/MarketTemplateUse.module.scss"],"names":[],"mappings":"AAAA;EAAA,cAAA;EAAA,wBAAA;EAAA;EAAA,mBAAA;EAAA,sBAAA;EAAA;EAAA,iBAAA;EAAA,uBAAA;;ACYA;EDZA;IAAA,kBAAA,CCgBK","sourcesContent":[null,"@import '../../../../styles/breakpoints.scss';\n\n.market-template-use {\n  @apply flex flex-col;\n  &-submit {\n    @apply mt-2 self-end;\n  }\n  &-community {\n    @apply mt-4 mb-2;\n  }\n}\n\n@media (min-width: $lg) {\n  .market-template-use {\n    &-submit {\n      @apply mt-4;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"market-template-use": "MarketTemplateUse_market-template-use__rpNZx",
	"market-template-use-submit": "MarketTemplateUse_market-template-use-submit__4gZ-L",
	"market-template-use-community": "MarketTemplateUse_market-template-use-community__mcgsp"
};
export default ___CSS_LOADER_EXPORT___;
