import { TFunctionResult } from 'i18next';

// Models
import { CommunityUserRole } from '../../communities/models/communities.types';
import { DashboardData } from '../../dashboard/models/dashboard.types';
import {
  Address,
  Attachment,
  Avatar,
} from '../../../shared/models/shared.types';

export interface Account {
  id: string;
  email: string;
  language: string;
  notifications: UserNotificationSetting;
  roles?: UserRole[];
  phone?: string;
}

export interface LanguageUpdate {
  id?: string;
  language: string;
}

export type CommunityContact = Pick<UserOld, 'id' | 'email' | 'profile'>;

export type Deputy = Pick<
  Person,
  'id' | 'email' | 'error' | 'function' | 'profile'
>;

interface Person {
  id: string;
  communities?: PersonCommunity[];
  email: string;
  dashboard?: DashboardData;
  deputies?: Deputy[];
  error?: boolean;
  function?: string | null;
  invited: string;
  language: string;
  profile: Profile;
  roles: UserRole[];
  trial?: number;
}

export type PersonCommunity = {
  id: string;
  contact: CommunityContact;
  role: CommunityUserRole;
};

export interface Profile {
  address?: Address;
  avatar?: Attachment;
  personal_data: PersonalData;
  nickname?: string;
}

export interface PersonalData {
  birthday?: string;
  first_name?: string;
  last_name?: string;
  telephone?: string;
}

export interface RoomUser extends User {
  room_creator?: boolean,
}

export interface User {
  communities?: PersonCommunity[];
  account?: Account;
  profile?: Profile;
  read_message_id?: string;
}

// Same as Backend user entity
export interface UserEntity {
  id: string;
  avatar_id: Avatar;
  // notifications_id: Notifications;
  // theme: Theme;
  email: string;
  password: string;
  salt: string;
  roles: UserRole[];
  language: string;
  first_name: string;
  last_name: string;
  birthday: string;
  street: string;
  house_number: string;
  zip_code: string;
  place: string;
  country: string;
  phone: string;
  telephone: string;
  last_logout: Date;
  last_login: Date;
  previous_login: Date;
  register_date: Date;
  security_word: string;
  ghostAccount?: string;
  // private_links_used: ToolLinkUsed[];
  // tool_value_vote: ToolFieldValueVote[];
  // connected_tool: Tool[];
  // my_history: History[];
  // my_changed_by: CreateChangeInfo[];
  // tags: FolderTagsEntity[];
  // inviterID: InvitedUsers[];
  // invitedUserID: InvitedUsers[];
  // tool_field_value: ToolFieldValues[];
  // blocked_users: string;
  // permission_slots_connected: ToolSetRoleTags[];
  // tool_permission: ToolPermissions[];
}
export interface UserLinkChild {
  id: string;
  logo: Attachment;
  title: string | TFunctionResult;
  type: UserInfoState;
}

export interface NavigationCommunityResponse {
  myCommunities: UserLinkChild[];
  adminCommunities: UserLinkChild[];
}

export type UserOld = Omit<Person, 'error' | 'invited' | 'function'>;

export enum UserInfoState {
  Account = 'ACCOUNT',
  Community = 'COMMUNITY',
  Documents = 'DOCUMENTS',
  Profile = 'PROFILE',
  Security = 'SECURITY',
}

export enum UserRole {
  Admin = 'ADMIN',
  User = 'USER',
}

export interface SecurityWordGet {
  word: string;
}

export interface LogoDelete {
  id: string;
  logo: Attachment;
}

export interface UserNotificationSetting {
  // Methode for Notification
  email?: boolean;
  sms?: boolean;
  web?: boolean;

  // Type of Notification
  community?: boolean;
  tool?: boolean;
  group?: boolean;
  message?: boolean;
  network?: boolean;

  [key: string]: boolean | undefined;
}