import { FormTranslations } from '../models/form.translations.interface';

export const formTranslationsDE: FormTranslations = {
  address: {
    country: {
      options: {
        austria: 'Österreich',
        germany: 'Deutschland',
        switzerland: 'Schweiz',
      },
      placeholder: 'Auswählen',
      title: 'Land',
    },
    houseNumber: {
      placeholder: 'z.B. 12a',
      title: 'Hausnummer',
    },
    place: {
      placeholder: 'z.B. Musterstadt',
      title: 'Ort',
    },
    street: {
      placeholder: 'z.B. Musterstraße',
      title: 'Straße',
    },
    title: 'Adresse',
    zipCode: {
      placeholder: 'z.B. 14467',
      title: 'PLZ',
    },
  },
  common: {
    errors: {
      matches: {
        delete: 'LÖSCHEN',
        response: 'Eingabe stimmt nicht überein.',
        text1: 'Tippe',
        text2: 'um zu bestätigen',
      },
    },
  },
  file: {
    types: {
      image: 'Erlaubte Datentypen: png, jpg, jpeg  ',
      size: 'Breite: 1920px Höhe: 112px',
    },
  },
  file_drop: {
    placeholder: 'Lege deine Datei hier ab oder wähle eine Datei aus',
  },
  profile: {
    birthday: {
      placeholder: '_ _._ _._ _ _ _',
      title: 'Geburtsdatum',
    },
    email: {
      error: {
        conflict:
          'Diese E-Mail wird bereits benutzt. Bitte nutze eine andere E-Mail.',
      },
      placeholder: 'z.B. paul.mueller@mail.de',
      title: 'E-Mail',
      validation: {
        required: 'Es muss eine E-Mail eingegeben werden.',
        schema: 'Die Eingabe ist keine E-Mail.',
      },
    },
    first_name: {
      placeholder: 'z.B. Paul',
      title: 'Vorname',
    },
    function: {
      placeholder: 'z.B. Mutter',
      title: 'Funktion',
    },
    last_name: {
      placeholder: 'z.B. Müller',
      title: 'Nachname',
    },
    phone: {
      placeholder: 'z.B. 01514638618',
      title: 'Handynummer',
    },
    telephone: {
      placeholder: 'z.B. 01514638618',
      title: 'Telefonnummer',
    },
  },
};
