// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Alert_alert__rEteH {\n  padding: 1rem }\n  .Alert_alert__rEteH .Alert_MuiPaper-root__rhEb1 {\n  width: 20rem }\n  .Alert_alert-buttons__Q3DzP {\n  margin-top: 0.5rem;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end }\n    .Alert_alert-buttons-cancel__BK0dR {\n  margin-right: 0.5rem }\n  .Alert_alert-header__FpiGn {\n  margin-bottom: 0.5rem;\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: space-between }\n    .Alert_alert-header-icon__shUlh {\n  display: flex;\n  width: 2rem;\n  align-items: center;\n  justify-content: flex-end }\n    .Alert_alert-header-title__OOCXH {\n  font-weight: 600 }\n  .Alert_alert-subtitle__CSjqp {\n  font-size: 0.75rem;\n  line-height: 1rem }\n\n@media (min-width: 1024px) {\n  .Alert_alert-buttons__Q3DzP {\n    margin-top: 1rem }\n  .Alert_alert-header__FpiGn {\n    margin-bottom: 1rem } }\n", "",{"version":3,"sources":["<no source>","webpack://./src/shared/ui/Alert/Alert.module.scss"],"names":[],"mappings":"AAAA;EAAA,eAAA;EAAA;EAAA,cAAA;EAAA;EAAA,mBAAA;EAAA,cAAA;EAAA,oBAAA;EAAA,2BAAA;IAAA;EAAA,sBAAA;EAAA;EAAA,sBAAA;EAAA,cAAA;EAAA,YAAA;EAAA,oBAAA;EAAA,gCAAA;IAAA;EAAA,cAAA;EAAA,YAAA;EAAA,oBAAA;EAAA,2BAAA;IAAA;EAAA,kBAAA;EAAA;EAAA,mBAAA;EAAA,mBAAA;;AC2BA;ED3BA;IAAA,kBAAA;EAAA;IAAA,qBAAA,CCkCK","sourcesContent":[null,"@import '../../../styles/breakpoints.scss';\n\n.alert {\n  @apply p-4;\n  & .MuiPaper-root {\n    @apply w-80;\n  }\n  &-buttons {\n    @apply flex items-center justify-end mt-2;\n    &-cancel {\n      @apply mr-2;\n    }\n  }\n  &-header {\n    @apply flex items-center justify-between mb-2 w-full;\n    &-icon {\n      @apply w-8 flex items-center justify-end;\n    }\n    &-title {\n      @apply font-semibold;\n    }\n  }\n  &-subtitle {\n    @apply text-xs;\n  }\n}\n\n@media (min-width: $lg) {\n  .alert {\n    &-buttons {\n      @apply mt-4;\n    }\n    &-header {\n      @apply mb-4;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "Alert_alert__rEteH",
	"MuiPaper-root": "Alert_MuiPaper-root__rhEb1",
	"alert-buttons": "Alert_alert-buttons__Q3DzP",
	"alert-buttons-cancel": "Alert_alert-buttons-cancel__BK0dR",
	"alert-header": "Alert_alert-header__FpiGn",
	"alert-header-icon": "Alert_alert-header-icon__shUlh",
	"alert-header-title": "Alert_alert-header-title__OOCXH",
	"alert-subtitle": "Alert_alert-subtitle__CSjqp"
};
export default ___CSS_LOADER_EXPORT___;
