// Hooks
import { useFetch } from '../../../shared/hooks/use-fetch.hook';
import { Option, ResultState } from '../../../shared/models/shared.types';
import {
  TemplatePermissionSlotUsersDeleteRequest,
  TemplatePermissionSlotUsersPatchRequest,
} from '../../tools/models/tools.types';

// Models
import {
  Template,
  TemplateElementPostPatchRequest,
  TemplateElementPostPatchResponse,
  TemplateElementDeleteRequest,
  TemplateElementDeleteResponse,
  TemplateElementChildDeleteRequest,
  TemplateElementChildPostPatchResponse,
  TemplateElementChildDeleteResponse,
  TemplateMultiElementTableSettings,
  TemplateMultiElementTableSettingsPatchResponse,
  TemplatePreview,
  TemplatePublishPostRequest,
  TemplateSectionPostPatchRequest,
  TemplateSectionPostPatchResponse,
  TemplateSectionDeleteRequestResponse,
  TemplateSettings,
  TemplateSectionElementsGroupRequest,
  TemplateElementPatchOrderRequest,
  TemplatePublishPostResponse,
  TemplateDeleteResponse,
  TemplateDeleteRequest,
  CommunityFilter,
  TemplateSectionPatchOrderRequest,
  TemplateSectionElementPatchOrderRequest,
} from '../models/templates.types';

export const useTemplatesHttp = () => {
  const { fetchData, uploadData } = useFetch();

  /**
   * PATCH Group template section elements to one multi element by ids.
   * @param data TemplateSectionElementsGroupRequest
   * @returns Template
   */
  const elementsGroup = async (
    data: TemplateSectionElementsGroupRequest
  ): Promise<Template | undefined> => {
    return await fetchData(
      `templates/${data.toolId}/sections/${data.sectionId}/group`,
      {
        body: data,
        method: 'PATCH',
      }
    );
  };

  /**
   * POST Template by body data.
   * Component: TemplateSettings
   * @param body TemplatePostRequest
   * @returns Template
   */
  const templatePost = async (
    body: TemplateSettings
  ): Promise<Template | undefined> => {
    return await fetchData('templates', {
      body,
      method: 'POST',
    });
  };

  /**
   * PATCH Template by id and body data.
   * Component: Template
   * @param body TemplatePostRequest
   * @returns Template
   */
  const templatePatch = async (
    template: Template
  ): Promise<Template | undefined> => {
    return await fetchData(`templates/${template.id}`, {
      body: template,
      method: 'PATCH',
    });
  };

  /**
   * DELETE Template by id.
   * Component: Template
   * @param body Template
   * @returns Template
   */
  const templateDelete = async (
    data: TemplateDeleteRequest
  ): Promise<TemplateDeleteResponse> => {
    return await fetchData(`templates/${data.template.id}`, {
      body: data,
      method: 'DELETE',
    });
  };

  /**
   * PATCH Not publishing templates.
   * Component: Template
   * @param body TemplatePostRequest
   * @returns Template
   */
  const templateNoPublish = async (
    template: Template
  ): Promise<{ status: ResultState; template: Template }> => {
    return await fetchData(`templates/${template.id}/no_publish`, {
      body: template,
      method: 'PATCH',
    });
  };

  /**
   * GET Template by id.
   * Component: Template
   * Example mock data: templateGet()
   * @returns Template
   */
  const templateGet = async (id: string): Promise<Template | undefined> => {
    return await fetchData(`templates/${id}`, {
      method: 'GET',
    });
  };
  const templateGetMarktplatz = async (
    id: string
  ): Promise<Template | undefined> => {
    return await fetchData(`templates/${id}/marktplatz`, {
      method: 'GET',
    });
  };

  /**
   * GET Template public by id.
   * Component: Template
   * Example mock data: templateGet()
   * @returns Template
   */
  const templatePublicGet = async (
    id: string
  ): Promise<Template | undefined> => {
    return await fetchData(`templates/public/${id}`, {
      method: 'GET',
    });
  };

  /**
   * POST Template publish by id and body.
   * Component: TemplatePublish
   * @param data Template id and TemplatePublishPostRequest body
   * @returns Tool id
   */
  const templatePublishPost = async (data: {
    id: string;
    body: TemplatePublishPostRequest;
  }): Promise<TemplatePublishPostResponse | undefined> => {
    const formData = new FormData();
    formData.append('body', JSON.stringify(data.body));
    if (data.body.image_market) {
      formData.append('file', data.body.image_market);
    }
    return await uploadData(`templates/${data.id}/publish`, {
      method: 'POST',
      formData,
    });
  };

  /**
   * PATCH Template settings by id and body data.
   * Component: TemplateSettings
   * @param body TemplateSettings
   * @returns TemplateSettings
   */
  const templateSettingsPatch = async (data: {
    id: string;
    body: TemplateSettings;
  }): Promise<TemplateSettings | undefined> => {
    return await fetchData(`templates/${data.id}/settings`, {
      body: data.body,
      method: 'PATCH',
    });
  };

  /**
   * GET All own workbench templates.
   * Component: Workbench
   * Example mock data: templatesWorkbenchGet()
   * @returns Workbench templates
   */
  const templatesWorkbenchGet = async (): Promise<
    TemplatePreview[] | undefined
  > => {
    return await fetchData('templates/workbench', {
      method: 'GET',
    });
  };

  /**
   * POST Template element by id and body.
   * Component: TemplateElementCreateEditNavigation
   * @param data Template id and TemplateElementPostRequest body
   * @returns TemplateElementPostPatchResponse
   */
  const templateElementPost = async (data: {
    id: string;
    body: TemplateElementPostPatchRequest;
  }): Promise<TemplateElementPostPatchResponse | undefined> => {
    return await fetchData(`templates/${data.id}/elements`, {
      body: data.body,
      method: 'POST',
    });
  };

  /**
   * PATCH Template element by ids and body.
   * Component: TemplateElementCreateEditNavigation
   * @param body Template id and TemplateElementPostPatchRequest body
   * @returns TemplateElementPostPatchReponse
   */
  const templateElementPatch = async (data: {
    id: string;
    body: TemplateElementPostPatchRequest;
    element_id: string;
  }): Promise<TemplateElementPostPatchResponse | undefined> => {
    return await fetchData(`templates/${data.id}/elements/${data.element_id}`, {
      body: data.body,
      method: 'PATCH',
    });
  };

  /**
   * PATCH Template section change order.
   * Component: TemplateElement
   * @param body Template id and TemplateElementPatchOrderRequest body
   * @returns TemplateElementPatchOrderRequest
   */
  const templateElementOrderPatch = async (
    data: TemplateElementPatchOrderRequest
  ): Promise<TemplateElementPatchOrderRequest | undefined> => {
    return await fetchData(
      `templates/${data.id}/sections/${data.section_id}/order`,
      {
        body: data.elements,
        method: 'PATCH',
      }
    );
  };

  const templateSectionElementOrderPatch = async (data: TemplateSectionElementPatchOrderRequest): Promise<TemplateElementPatchOrderRequest | undefined> => {
    return await fetchData(
      `templates/${data.id}/sections/elements/order`,
      {
        body: data.sections,
        method: 'PATCH',
      }
    );
  };

  /**
   * PATCH Template section change order.
   */
  const templateSectionOrderPatch = async (
    data: TemplateSectionPatchOrderRequest
  ): Promise<TemplateSectionPatchOrderRequest | undefined> => {
    return await fetchData(`templates/${data.id}/order`, {
      body: data.sections,
      method: 'PATCH',
    });
  };

  /**
   * PATCH Template section change order.
   * Component: TemplateElement
   * @param body Template id and TemplateElementPatchOrderRequest body
   * @returns TemplateElementPatchOrderRequest
   */
  const templateElementMultiOrderPatch = async (
    data: TemplateElementPatchOrderRequest
  ): Promise<TemplateElementPatchOrderRequest | undefined> => {
    return await fetchData(
      `templates/${data.id}/sections/${data.section_id}/order/${data.multiid}`,
      {
        body: data.elements,
        method: 'PATCH',
      }
    );
  };

  /**
   * DELETE Template element child by ids.
   * Component: TemplateElement
   * @param data TemplateElementDeleteRequest
   * @returns TemplateElementDeleteResponse
   */
  const templateElementDelete = async (
    data: TemplateElementDeleteRequest
  ): Promise<TemplateElementDeleteResponse | undefined> => {
    return await fetchData(`templates/${data.id}/elements/${data.element_id}`, {
      method: 'DELETE',
    });
  };

  /**
   * POST Template element child by ids and body.
   * Component: TemplateElementCreateEditNavigation
   * @param data Template ids and TemplateElementPostRequest body
   * @returns TemplateElementChildPostPatchResponse
   */
  const templateElementChildPost = async (data: {
    id: string;
    element_id: string;
    body: TemplateElementPostPatchRequest;
  }): Promise<TemplateElementChildPostPatchResponse | undefined> => {
    return await fetchData(
      `templates/${data.id}/elements/${data.element_id}/children`,
      {
        body: data.body,
        method: 'POST',
      }
    );
  };

  /**
   * PATCH Template element child by ids and body.
   * Component: TemplateElementCreateEditNavigation
   * @param body Template id and TemplateElementPostPatchRequest body
   * @returns TemplateElementChildPostPatchResponse
   */
  const templateElementChildPatch = async (data: {
    id: string;
    body: TemplateElementPostPatchRequest;
    child_id: string;
    element_id: string;
  }): Promise<TemplateElementChildPostPatchResponse | undefined> => {
    return await fetchData(
      `templates/${data.id}/elements/${data.element_id}/children/${data.child_id}`,
      {
        body: data.body,
        method: 'PATCH',
      }
    );
  };

  /**
   * PATCH Template multi element settings by ids and body.
   * Component: TemplateElementCreateEditNavigation
   * @param id Template id
   * @param body TemplateMultiElementTableSettings
   * @param element_id Template element id
   * @returns TemplateMultiElementTableSettingsPatchResponse
   */
  const templateMultiElementSettingsPatch = async (data: {
    id: string;
    body: TemplateMultiElementTableSettings;
    element_id: string;
  }): Promise<TemplateMultiElementTableSettingsPatchResponse | undefined> => {
    return await fetchData(
      `templates/${data.id}/elements/${data.element_id}/settings/table`,
      {
        body: data.body,
        method: 'PATCH',
      }
    );
  };

  /**
   * DELETE Template element child by ids.
   * Component: TemplateElement
   * @param data TemplateElementDeleteRequest
   * @returns TemplateElementChildDeleteResponse
   */
  const templateElementChildDelete = async (
    data: TemplateElementChildDeleteRequest
  ): Promise<TemplateElementChildDeleteResponse | undefined> => {
    return await fetchData(
      `templates/${data.id}/elements/${data.element_id}/children/${data.child_id}`,
      {
        method: 'DELETE',
      }
    );
  };

  /**
   * POST Template section by id and body.
   * Component: TemplateSectionCreateEdit
   * @param data Template id and TemplateSectionPostRequest body
   * @returns TemplateSectionPostPatchResponse
   */
  const templateSectionPost = async (data: {
    id: string;
    body: TemplateSectionPostPatchRequest;
  }): Promise<TemplateSectionPostPatchResponse | undefined> => {
    return await fetchData(`templates/${data.id}/sections`, {
      body: data.body,
      method: 'POST',
    });
  };

  /**
   * PATCH Template section by ids and body.
   * Component: TemplateSectionCreateEdit
   * @param body Template id and TemplateSectionPostPatchRequest body
   * @returns TemplateSectionPostPatchResponse
   */
  const templateSectionPatch = async (data: {
    id: string;
    body: TemplateSectionPostPatchRequest;
    sectionId: string;
  }): Promise<TemplateSectionPostPatchResponse | undefined> => {
    return await fetchData(`templates/${data.id}/sections/${data.sectionId}`, {
      body: data.body,
      method: 'PATCH',
    });
  };

  /**
   * DELETE Template section by ids.
   * @param data TemplateSectionDeleteRequestResponse
   * @returns TemplateSectionDeleteRequestResponse
   */
  const templateSectionDelete = async (
    data: TemplateSectionDeleteRequestResponse
  ): Promise<TemplateSectionDeleteRequestResponse | undefined> => {
    return await fetchData(`templates/${data.id}/sections/${data.section_id}`, {
      method: 'DELETE',
    });
  };

  /**
   * PATCH Template section elements order by ids and updated sorted element ids list.
   * @param data Template id, Updated sorted element ids list and TemplateSection id
   * @returns Sorted element ids list
   */
  const templateSectionElementsPositionPatch = async (data: {
    id: string;
    body: string[];
    section_id: string;
  }): Promise<string[] | undefined> => {
    return await fetchData(
      `templates/${data.id}/sections/${data.section_id}/elements/position`,
      {
        body: data.body,
        method: 'PATCH',
      }
    );
  };

  /**
   * PATCH Tool users room by tool id and user ids (add).
   * @param body ToolUsersPatchRequest
   * @returns ToolUsersPatchResponse
   */
  const permissionSlotsUsersPatch = async (
    body: TemplatePermissionSlotUsersPatchRequest
  ): Promise<TemplatePermissionSlotUsersPatchRequest | undefined> => {
    return await fetchData(
      `templates/${body.templateId}/permission_slot/${body.permission_slot}/users`,
      {
        method: 'POST',
        body: { users: body.users, groups: body.selectedGroups },
      }
    );
  };

  /**
   * PATCH Tool users room by tool id and user ids (add).
   * @param body ToolUsersPatchRequest
   * @returns ToolUsersPatchResponse
   */
  const permissionSlotsUsersDelete = async (
    body: TemplatePermissionSlotUsersDeleteRequest
  ): Promise<TemplatePermissionSlotUsersDeleteRequest | undefined> => {
    return await fetchData(
      `templates/permission_slot/${body.permission_slot_id}/users`,
      {
        method: 'DELETE',
      }
    );
  };

  /**
   * GET template filter menu for community
   */
  const communityFilterListGet = async (): Promise<
    CommunityFilter | undefined
  > => {
    return await fetchData(`templates/workbench/menu/community`);
  };

  /**
   * @returns Template-Elements
   */
  const templateElementsGet = async (id: string): Promise<Option[]> => {
    if (!id) {
      return [];
    }
    return await fetchData(`templates/${id}/elements`, {
      method: 'GET',
    });
  };

  const templateElementGetFromMFETemplate = async (
    id: string
  ): Promise<Option[]> => {
    if (!id) {
      return [];
    }

    return await fetchData(`templates/${id}/elements/mfe/template`, {
      method: 'GET',
    });
  };

  return {
    elementsGroup,
    templatesWorkbenchGet,
    templateGet,
    templateGetMarktplatz,
    templatePublicGet,
    templatePost,
    templatePatch,
    templateDelete,
    templateNoPublish,
    templatePublishPost,
    templateSettingsPatch,
    templateElementPost,
    templateElementPatch,
    templateElementDelete,
    templateElementChildPost,
    templateElementChildPatch,
    templateElementChildDelete,
    communityFilterListGet,
    templateMultiElementSettingsPatch,
    templateSectionOrderPatch,
    templateSectionPost,
    templateSectionPatch,
    templateSectionDelete,
    templateSectionElementsPositionPatch,
    templateElementOrderPatch,
    templateElementMultiOrderPatch,
    templateSectionElementOrderPatch,
    permissionSlotsUsersPatch,
    permissionSlotsUsersDelete,
    templateElementsGet,
    templateElementGetFromMFETemplate,
  };
};
