import { MarketTranslations } from '../models/market.translations.interface';

export const marketTranslationsDE: MarketTranslations = {
  drawer: {
    footer: {
      title: 'Erstelle deine eigene Vorlage!',
      content:
        'Wusstest du schon, dass du mit unserer Werkbank auch eigene Vorlagen bauen kannst? Passe Vorlagen an deine Bedürfnisse an oder erstelle Vorlagen, die du mit der Community teilst und sammle Pengueen Rewards 🙌',
      link: 'ZUR WERKBANK',
    },
  },
  button: 'Zur Werkbank',
  community_tools: 'Community Hilfsmittel',
  filters: {
    search: 'Nach Namen suchen',
    search_detail: 'Beschreibung durchsuchen',
  },
  levels: {
    advanced: 'Fortgeschritten',
    beginner: 'Beginner',
    expert: 'Experte',
    title: 'Levels',
  },
  field_empty: {
    text1:
      'Leider konnten wir keine passenden Vorlagen zu deiner Suchanfrage finden. Wusstest du schon, dass du dir auch eigene Hilfsmittel in unserer Werkbank bauen kannst? ',
    text2: 'Direkt ausprobieren.',
  },
  preview: {
    back: 'Zurück zu den Hilfsmittelvorlagen',
    contains: {
      input_fields: 'Inputfelder',
      title: 'Enthält',
      used1: 'Verwendet: ',
      used2: ' mal',
    },
    copy: 'In meine Werkbank Kopieren',
    created_by: {
      title: 'Erstellt durch',
    },
    mit: {
      text: 'Dieses Hilfsmittel läuft unter folgender',
      title: 'Open Source Lizenz',
    },
    notification: {
      subtitle:
        'Du hast das Hilfsmittel erfolgreich zu deiner Werkbank hinzugefügt und kannst dieses nun bearbeiten und verwenden.',
      title: ' erfolgreich hinzugefügt',
    },
    title: 'Vorschau',
  },
  subtitle:
    'Nutze deine eigenen Hilfsmittelvorlagen oder finde neue Hilfsmittelvorlagen von der Pengueen Community.',
  tags: 'Tags',
  templates: {
    all: 'Alle Vorlagen',
    newest: 'Kürzlich hinzugefügt',
    popular: 'Beliebt',
    title: 'Vorlagen',
  },
  title: 'Marktplatz',
  use: {
    dialog: {
      name_placeholder: 'Hilfsmittel',
      subtitle:
        'Um die Hilfsmittel zu unterscheiden, benötigen sie beim anlegen eine konkrete Bezeichnung.',
      title: 'Gebe dem Hilfsmittel eine spezifische Bezeichnung',
    },
    form: {
      button: 'Jetzt nutzen und anlegen',
      name: {
        error: 'Es muss eine Bezeichnung angegeben werden',
        label: 'Bezeichnung',
        placeholder: 'z.B. Projekt Sommerfest',
      },
    },
    community:
      'Möchtest du dieses Hilfsmittel mit anderen in deiner Gemeinschaft verwenden?',
    title: 'Jetzt nutzen',
    more: 'Mehr erfahren über den Anwendungsfall/die Gemeinschaft',
  },
  use_cases: {
    grid: {
      item: {
        more: 'Mehr',
        select: 'Auswählen',
        use: 'Nutzen',
      },
      show_less: 'Weniger anzeigen',
      show_more: 'Mehr anzeigen',
    },
    selected: {
      cancel: 'Auswahl aufheben',
      view: 'Ausgewählte Hilfsmittel ansehen',
    },
    title: 'Anwendungsfälle',
  },
  find: 'Hilfsmittel finden',
};
