import { HeaderTranslations } from '../models/header.translations.interface';

export const headerTranslationsDE: HeaderTranslations = {
  substitutions: 'Meine Stellvertretungen',
  trial: {
    day: 'Tag',
    days: 'Tage',
    text1: 'Noch',
    text2: 'verbleibend',
    text3: 'im Testzeitraum'
  }
};
