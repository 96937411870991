// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".H2_h2__oG7rK {\n\n  font-size: 1.125rem;\n\n  line-height: 1.75rem;\n\n  font-weight: 600 }\n\n@media (min-width: 1024px) {\n  .H2_h2__oG7rK {\n\n    font-size: 1.25rem;\n\n    line-height: 1.75rem } }\n", "",{"version":3,"sources":["<no source>","webpack://./src/shared/ui/H2/H2.module.scss"],"names":[],"mappings":"AAAA;;EAAA,oBAAA;;EAAA,qBAAA;;EAAA,kBAAA;;ACMA;EDNA;;IAAA,mBAAA;;IAAA,sBAAA,CCSG","sourcesContent":[null,"@import '../../../styles/breakpoints.scss';\n\n.h2 {\n  @apply font-semibold text-lg;\n}\n\n@media (min-width: $lg) {\n  .h2 {\n    @apply text-xl;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"h2": "H2_h2__oG7rK"
};
export default ___CSS_LOADER_EXPORT___;
