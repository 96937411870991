// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Snackbar_snackbar__GSi0B {\n  display: flex;\n  max-width: 24rem;\n  align-items: flex-start\n}\n\n.Snackbar_snackbar-content__vq\\+xz {\n  position: relative;\n  display: flex;\n  align-items: flex-start;\n  min-width: 320px\n}\n  .Snackbar_snackbar-content-close__yWyd8 {\n  position: absolute;\n  right: -0.75rem;\n  top: -0.5rem;\n  flex-shrink: 0\n}\n  .Snackbar_snackbar-content-info__-g-Yn {\n  display: flex;\n  align-items: center\n}\n    .Snackbar_snackbar-content-info-icon__NDIMV {\n  display: flex;\n  height: 2rem;\n  width: 2rem;\n  flex-shrink: 0;\n  align-items: center;\n  justify-content: center;\n  border-radius: 9999px\n}\n    .Snackbar_snackbar-content-info-text__sLoat {\n  margin-left: 1rem;\n  display: flex;\n  flex-direction: column\n}\n      .Snackbar_snackbar-content-info-text-subtitle__qBYTm {\n  margin-top: 0.5rem\n}\n      .Snackbar_snackbar-content-info-text-title__pWJv- {\n  margin-right: 2rem;\n  font-weight: 600\n}\n", "",{"version":3,"sources":["<no source>","webpack://./src/shared/ui/Snackbar/Snackbar.module.scss"],"names":[],"mappings":"AAAA;EAAA,cAAA;EAAA,iBAAA;EAAA;CAAA;;AAAA;EAAA,mBAAA;EAAA,cAAA;EAAA,wBAAA;ECME;CDNF;EAAA;EAAA,mBAAA;EAAA,gBAAA;EAAA,aAAA;EAAA;CAAA;EAAA;EAAA,cAAA;EAAA;CAAA;IAAA;EAAA,cAAA;EAAA,aAAA;EAAA,YAAA;EAAA,eAAA;EAAA,oBAAA;EAAA,wBAAA;EAAA;CAAA;IAAA;EAAA,kBAAA;EAAA,cAAA;EAAA;CAAA;MAAA;EAAA;CAAA;MAAA;EAAA,mBAAA;EAAA;CAAA","sourcesContent":[null,".snackbar {\n  @apply flex items-start max-w-sm;\n}\n\n.snackbar-content {\n  @apply flex items-start relative;\n  min-width: 320px;\n  &-close {\n    @apply absolute -right-3 shrink-0 -top-2;\n  }\n  &-info {\n    @apply flex items-center;\n    &-icon {\n      @apply flex h-8 items-center justify-center rounded-full shrink-0 w-8;\n    }\n    &-text {\n      @apply flex flex-col ml-4;\n      &-subtitle {\n        @apply mt-2;\n      }\n      &-title {\n        @apply font-semibold mr-8;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"snackbar": "Snackbar_snackbar__GSi0B",
	"snackbar-content": "Snackbar_snackbar-content__vq+xz",
	"snackbar-content-close": "Snackbar_snackbar-content-close__yWyd8",
	"snackbar-content-info": "Snackbar_snackbar-content-info__-g-Yn",
	"snackbar-content-info-icon": "Snackbar_snackbar-content-info-icon__NDIMV",
	"snackbar-content-info-text": "Snackbar_snackbar-content-info-text__sLoat",
	"snackbar-content-info-text-subtitle": "Snackbar_snackbar-content-info-text-subtitle__qBYTm",
	"snackbar-content-info-text-title": "Snackbar_snackbar-content-info-text-title__pWJv-"
};
export default ___CSS_LOADER_EXPORT___;
