import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunctionResult } from 'i18next';
import { Box, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';

// Hooks
import { useNewsCenter } from '../../../modules/newscenter/hooks/use-news-center.hook';

// Styles
import styles from './FormFieldLabel.module.scss';
import { Icon } from '../Icon/Icon';

type FormFieldLabelProps = {
  classes?: string;
  children?: ReactNode;
  label: TFunctionResult | string;
  labelColor?: string;
  labelFontWeightClasses?: string;
  optional?: boolean;
  paddingClassName?: string;
  required?: boolean;
  sublabel?: TFunctionResult | string;
  help_text?: string;
  headline?: boolean;
};

export const FormFieldLabel = (props: FormFieldLabelProps) => {
  const { t } = useTranslation();
  const { messageHandleContent } = useNewsCenter();

  return (
    <Box
      className={clsx(
        styles['form-field-label'],
        props.classes && props.classes,
        props.paddingClassName && props.paddingClassName
      )}
    >
      <Typography
        align="left"
        className={
          props.labelFontWeightClasses ?? styles['form-field-label-label']
        }
        fontSize={props.headline ? '1.143em' : '1em'}
        color={props.labelColor ?? 'text.primary'}
        sx={{ display: 'inline' }}
      >
        {props.label}
        {props.required && <span> *</span>}
        {props.help_text && props.help_text !== '' && (
          <div className="ml-2">
            <Tooltip
              placement="right-start"
              title={props.help_text}
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: 'background.paper',
                    color: 'text.secondary',
                    border: '1px solid',
                    borderColor: 'border.app',
                    boxShadow: 3,
                  },
                },
              }}
            >
              <div className="pt-1">
                <Icon
                  icon={['fas', 'circle-question']}
                  color="primary"
                  classes="cursor-pointer text-base"
                />
              </div>
            </Tooltip>
          </div>
        )}
      </Typography>
      {props.sublabel && (
        <Typography
          align="left"
          // id="form-field-label-sublabel"
          className={styles['form-field-label-sublabel']}
          color="text.secondary"
          fontSize="1em"
          sx={{ display: 'inline' }}
          dangerouslySetInnerHTML={{
            __html: `${messageHandleContent(props.sublabel.toString())} `,
          }}
        />
      )}
      {props.optional && (
        <Typography
          align="left"
          className={styles['form-field-label-sublabel']}
          color="text.secondary"
          fontSize="1em"
          sx={{ display: 'inline' }}
        >
          ({t('app.optional')})
        </Typography>
      )}
      {props.children && (
        <div style={{ display: 'inline' }}>{props.children}</div>
      )}
    </Box>
  );
};
