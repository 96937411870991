import { Box, InputAdornment, OutlinedInput } from '@mui/material';
import clsx from 'clsx';

// Components
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';

// Styles
import styles from './Search.module.scss';

type SearchProps = {
  classes?: string;
  disabled?: boolean;
  padding?: string;
  placeholder?: string;
  preset?: 'white';
  value: string;
  onBlur?: () => void;
  onChange?: (value: string) => void;
};

export const Search = (props: SearchProps) => {
  return (
    <Box
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: props.disabled ? 'transparent !important' : undefined,
        },
        '& .MuiOutlinedInput-root': {
          padding: props.padding ?? '1rem',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          { borderColor: 'primary.main' },
      }}
      className={clsx(styles['search'], props.classes && props.classes)}
    >
      <OutlinedInput
        classes={{
          root: styles['search-outlined-input-root'],
          input: styles['search-outlined-input'],
        }}
        disabled={props.disabled}
        placeholder={props.placeholder ?? ''}
        size="small"
        startAdornment={
          <InputAdornment position="start">
            <Icon icon={['fas', 'search']} sx={{ color: 'text.secondary' }} />
          </InputAdornment>
        }
        endAdornment={
          props.value &&
          props.value.length > 0 && (
            <InputAdornment position="end">
              <IconButton
                icon={['fas', 'times']}
                padding="0.25rem 0.5rem"
                onClick={() => props.onChange && props.onChange('')}
              />
            </InputAdornment>
          )
        }
        sx={{
          backgroundColor:
            props.preset === 'white' ? 'background.paper' : 'bg.card',
          '& input::placeholder': { color: 'text.secondary' },
          '& .MuiSvgIcon-root': {
            width: '0.75rem',
          },
        }}
        value={props.value}
        onChange={(event) => {
          props.onChange && props.onChange(event.target.value);
        }}
        onBlur={props.onBlur && props.onBlur}
      />
    </Box>
  );
};
