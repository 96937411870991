import { ReactNode, memo, useEffect, useRef } from 'react';
import { TFunctionResult } from 'i18next';
import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import clsx from 'clsx';

// Components
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';

// Hooks
import { useBreakpoints } from '../../hooks/use-breakpoints.hook';

// Styles
import styles from './Dialog.module.scss';

type DialogProps = {
  back?: string | TFunctionResult;
  children: ReactNode;
  classes?: string;
  disableDividers?: boolean;
  open: boolean;
  paddingContent?: string;
  paddingTitle?: string;
  subtitle?: string | TFunctionResult;
  title?: string | TFunctionResult;
  titleSize?: 'small' | 'medium' | 'big';
  width?: string;
  widthClassName?: string;
  onBack?: () => void;
  onClose: () => void;
};

export const Dialog = memo((props: DialogProps) => {
  const { lgDown } = useBreakpoints();

  // Component refs
  const contentRef = useRef<HTMLDivElement>(null);

  // Focus content on open
  useEffect(() => {
    if (props.open) {
      const { current: content } = contentRef;
      if (content !== null) {
        content.focus();
      }
    }
  }, [props.open]);

  return (
    <MuiDialog
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      open={props.open}
      classes={{
        paper: clsx(
          styles['dialog'],
          props.classes && props.classes,
          props.widthClassName
            ? props.widthClassName
            : props.width
            ? undefined
            : styles['dialog-width']
        ),
      }}
      scroll="body"
      onClose={props.onClose}
      sx={{
        '& .MuiPaper-root': {
          backgroundImage: 'none',
          margin: 0,
          // maxHeight,
          maxWidth: 'none !important',
          //   width,
          width: props.width,
        },
      }}
      // className={clsx(props.classes && props.classes)}
    >
      {props.title && (
        <DialogTitle
          sx={{
            padding: props.paddingTitle
              ? props.paddingTitle
              : lgDown
              ? '1rem'
              : '1.5rem',
          }}
          className={styles['dialog-title']}
        >
          <div className={styles['dialog-title-container']}>
            {props.back && (
              <Button
                color="inherit"
                sx={{
                  color: 'text.secondary',
                  textTransform: 'initial',
                  '&:hover': {
                    backgroundColor: 'inherit',
                  },
                  '&:hover .MuiBox-root': {
                    backgroundColor: 'primary.main',
                  },
                  '&:hover .MuiSvgIcon-root': {
                    color: 'white',
                  },
                }}
                variant="text"
                onClick={props.onBack}
                className={styles['dialog-title-container-back']}
              >
                <Box
                  bgcolor="bg.card"
                  color="text.secondary"
                  className={styles['dialog-title-container-back-content']}
                >
                  <Icon
                    color="inherit"
                    icon={['fas', 'chevron-left']}
                    size="small"
                  />
                </Box>
                {props.back}
              </Button>
            )}
            <div
              className={clsx(
                props.titleSize === 'medium' &&
                  styles['dialog-title-size-medium'],
                props.titleSize === 'big' && styles['dialog-title-size-big']
              )}
            >
              {props.title && props.title}
            </div>
            {props.subtitle && (
              <Box
                sx={{ color: 'text.secondary' }}
                className={styles['dialog-title-container-subtitle']}
              >
                {props.subtitle}
              </Box>
            )}
          </div>
          <Box className={styles['dialog-title-placeholder']}></Box>
          <IconButton
            classes="dialog-title-close"
            icon={['fas', 'times']}
            onClick={props.onClose}
          />
        </DialogTitle>
      )}
      <DialogContent
        dividers={!props.disableDividers}
        sx={{
          borderTopColor: 'border.app',
          padding: props.paddingContent
            ? props.paddingContent
            : lgDown
            ? '1rem'
            : '1.5rem',
        }}
        className={styles['dialog-content']}
      >
        <Box ref={contentRef}>{props.children}</Box>
      </DialogContent>
    </MuiDialog>
  );
});
