// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResultBox_result-box__tuvX6 {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-radius: 0.375rem;\n  padding: 1rem }\n  .ResultBox_result-box-content__CfFyx {\n  display: flex;\n  align-items: center }\n    .ResultBox_result-box-content-icon__Uo44P {\n  margin-right: 1rem }\n    .ResultBox_result-box-content-text__YyLDd {\n  font-size: 0.75rem;\n  line-height: 1rem }\n  .ResultBox_result-box-close__uokyR {\n  margin-left: 0.5rem }\n\n@media (min-width: 1024px) {\n  .ResultBox_result-box-close__uokyR {\n    margin-left: 1rem } }\n", "",{"version":3,"sources":["<no source>","webpack://./src/shared/ui/ResultBox/ResultBox.module.scss"],"names":[],"mappings":"AAAA;EAAA,cAAA;EAAA,oBAAA;EAAA,+BAAA;EAAA,wBAAA;EAAA,eAAA;EAAA;EAAA,cAAA;EAAA,qBAAA;IAAA;EAAA,oBAAA;IAAA;EAAA,mBAAA;EAAA,mBAAA;EAAA;EAAA,qBAAA;;ACkBA;EDlBA;IAAA,mBAAA,CCsBK","sourcesContent":[null,"@import '../../../styles/breakpoints.scss';\n\n.result-box {\n  @apply flex items-center justify-between p-4 rounded-md;\n  &-content {\n    @apply flex items-center;\n    &-icon {\n      @apply mr-4;\n    }\n    &-text {\n      @apply text-xs;\n    }\n  }\n  &-close {\n    @apply ml-2;\n  }\n}\n\n@media (min-width: $lg) {\n  .result-box {\n    &-close {\n      @apply ml-4;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"result-box": "ResultBox_result-box__tuvX6",
	"result-box-content": "ResultBox_result-box-content__CfFyx",
	"result-box-content-icon": "ResultBox_result-box-content-icon__Uo44P",
	"result-box-content-text": "ResultBox_result-box-content-text__YyLDd",
	"result-box-close": "ResultBox_result-box-close__uokyR"
};
export default ___CSS_LOADER_EXPORT___;
