// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddContactsFavoriteTools_contacts-favorite-tools__aG0XA {\n    display: flex;\n    flex-direction: column\n}\n  .AddContactsFavoriteTools_contacts-favorite-tools-submit__nF9Pe {\n    margin-top: 1.5rem;\n    align-self: flex-end\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;IAAA,cAAA;IAAA;CAAA;EAAA;IAAA,mBAAA;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contacts-favorite-tools": "AddContactsFavoriteTools_contacts-favorite-tools__aG0XA",
	"contacts-favorite-tools-submit": "AddContactsFavoriteTools_contacts-favorite-tools-submit__nF9Pe"
};
export default ___CSS_LOADER_EXPORT___;
