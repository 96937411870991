// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RemoveButton_remove-button__ortDG {\n    display: flex;\n    align-items: center;\n    border-radius: 0.375rem;\n    border-width: 1px;\n    padding-left: 0.5rem;\n    padding-right: 0.5rem;\n    padding-top: 0.25rem;\n    padding-bottom: 0.25rem\n}\n  .RemoveButton_remove-button-remove__\\+Xj1X {\n    flex-shrink: 0\n}\n  .RemoveButton_remove-button-content__4S75Z {\n    margin-right: 0.5rem;\n    flex-grow: 1;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap\n}\n  .RemoveButton_remove-button-icon__ekqDu {\n    margin-right: 0.5rem;\n    flex-shrink: 0\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;IAAA,cAAA;IAAA,oBAAA;IAAA,wBAAA;IAAA,kBAAA;IAAA,qBAAA;IAAA,sBAAA;IAAA,qBAAA;IAAA;CAAA;EAAA;IAAA;CAAA;EAAA;IAAA,qBAAA;IAAA,aAAA;IAAA,iBAAA;IAAA,wBAAA;IAAA;CAAA;EAAA;IAAA,qBAAA;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"remove-button": "RemoveButton_remove-button__ortDG",
	"remove-button-remove": "RemoveButton_remove-button-remove__+Xj1X",
	"remove-button-content": "RemoveButton_remove-button-content__4S75Z",
	"remove-button-icon": "RemoveButton_remove-button-icon__ekqDu"
};
export default ___CSS_LOADER_EXPORT___;
