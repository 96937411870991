import { aboutTranslationsDE } from './de/about.translations.de';
import { appTranslationsDE } from './de/app.translations.de';
import { authTranslationsDE } from './de/auth.translations.de';
import { communityTranslationsDE } from './de/community.translations.de';
import { ContactsTranslationsDE } from './de/contacts.translations.de';
import { dashboardTranslationsDE } from './de/dashboard.translations.de';
import { formTranslationsDE } from './de/form.translations.de';
import { godparentschatTranslationsDE } from './de/godparentschat.translations.de';
import { headerTranslationsDE } from './de/header.translations.de';
import { marketTranslationsDE } from './de/market.translations.de';
import { newscenterTranslationsDE } from './de/newscenter.translations.de';
import { templatesTranslationsDE } from './de/templates.translations.de';
import { toolsTranslationsDE } from './de/tools.translations.de';
import { userTranslationsDE } from './de/user.translations.de';

export const translationsDe = {
  about: aboutTranslationsDE,
  app: appTranslationsDE,
  auth: authTranslationsDE,
  community: communityTranslationsDE,
  contacts: ContactsTranslationsDE,
  dashboard: dashboardTranslationsDE,
  form: formTranslationsDE,
  godparentschat: godparentschatTranslationsDE,
  header: headerTranslationsDE,
  market: marketTranslationsDE,
  newscenter: newscenterTranslationsDE,
  templates: templatesTranslationsDE,
  tools: toolsTranslationsDE,
  user: userTranslationsDE,
};
