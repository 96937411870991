// Models
import { Tool } from '../../tools/models/tools.types';
import { TemplatePreview as ITemplateWorkbench } from '../../templates/models/templates.types';

// Stores
import { useDashboardStore } from '../../dashboard/stores/use-dashboard.store';
import { useTemplatesStore } from '../../templates/stores/use-templates.store';

export const useMarket = () => {
  // Dashboard store state
  const [favoriteTools, setFavoriteTools] = useDashboardStore((state) => [
    state.favoriteTools,
    state.setFavoriteTools,
  ]);

  // Templates store state
  const [templates, setTemplates] = useTemplatesStore((state) => [
    state.templates,
    state.setTemplates,
  ]);

  /**
   * Add tool to dashboard widget.
   * @param tool Tool
   */
  const marketTemplateUseEffect = (tool: Tool) => {
    if (tool.id) {
      const updatedFavoriteTools = [...favoriteTools];
      updatedFavoriteTools.push({
        id: tool.id,
        changes: 0,
        name: tool.name,
        template: {
          icon: tool.template.settings.icon,
          // #TODO: required name
          name: tool.template.settings.name ?? 'No name',
        },
      });
      setFavoriteTools(updatedFavoriteTools);
    }
  };

  /**
   * Add tool to dashboard widget.
   * @param tool Tool
   */
  const marketTemplateCopyEffect = (template: ITemplateWorkbench) => {
    if (template.id) {
      const updatedTemplates = [...templates];
      updatedTemplates.push(template);
      setTemplates(updatedTemplates);
    }
  };

  return {
    marketTemplateUseEffect,
    marketTemplateCopyEffect,
  };
};
