// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormInviteIndirect_form-invite-indirect__QSB2P {\n    display: flex;\n    justify-content: flex-end\n}\n  .FormInviteIndirect_form-invite-indirect-cancel__4tH3G {\n    margin-right: 0.5rem\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;IAAA,cAAA;IAAA;CAAA;EAAA;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-invite-indirect": "FormInviteIndirect_form-invite-indirect__QSB2P",
	"form-invite-indirect-cancel": "FormInviteIndirect_form-invite-indirect-cancel__4tH3G"
};
export default ___CSS_LOADER_EXPORT___;
