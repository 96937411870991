import { lazy, Suspense, useEffect } from 'react';

// Components
import { Loader } from '../../../../shared/ui/Loader/Loader';
import { PagePath } from '../../../../shared/models/shared.types';

// Stores
import { useShared } from '../../../../shared/hooks/use-shared.hook';
import {
  SharedState,
  useSharedStore,
} from '../../../../shared/stores/use-shared.store';

// Styles
import styles from './Contacts.module.scss';
import { ContactCommunityGetAction } from '../../models/contacts.types';

// Lazy-load components
const ContactsContent = lazy(
  () => import('../../components/ContactsContent/ContactsContent')
);
type ContactsProps = {
  action?: ContactCommunityGetAction;
};

export const Contacts = (props: ContactsProps) => {
  const { getBreadcrumbPath } = useShared();

  // Shared store state
  const [setNetwork, setBreadcrumbPath] = useSharedStore(
    (state: SharedState) => [state.setNetwork, state.setBreadcrumbPath]
  );

  // Change Tooltip color in Header on mounted
  useEffect(() => {
    setNetwork(true);
    setBreadcrumbPath(getBreadcrumbPath(PagePath.Contacts));
    return () => {
      setNetwork(false);
      setBreadcrumbPath([]);
    };
    // eslint-disable-next-line
  }, []);

  return (
    // #TODO: Improve suspense fallback
    <Suspense fallback={<Loader />}>
      <ContactsContent
        include_me
        disableSelect
        showRowAction
        showToolbar
        action={props.action}
        useNavigation
        heightClasses={styles['contacts-height-nav']}
      />
    </Suspense>
  );
};
