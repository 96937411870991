import { ReactNode, Suspense } from 'react';
import { Card, Grid, Stack } from '@mui/material';
import clsx from 'clsx';

//Components
import { Loader } from '../../ui/Loader/Loader';

// Hooks
import { useBreakpoints } from '../../hooks/use-breakpoints.hook';

// Styles
import styles from './PageLayout.module.scss';

type PageLayoutProps = {
  headerComponent?: ReactNode;
  leftComponent: ReactNode;
  rightComponent: ReactNode;
  buttonInRightComp?: ReactNode;
  footerComponent?: ReactNode;
  heightClasses?: string;
  paddingClasses?: string;
  disableRightScroll?: boolean;
  showLeftFirst?: boolean;
};

export const PageLayout = (props: PageLayoutProps) => {
  const { lgUp, xxlDown } = useBreakpoints();

  return (
    <Stack
      className={clsx(
        styles['page-layout'],
        props.paddingClasses ?? styles['page-padding'],

        props.heightClasses ? props.heightClasses : styles['height']
      )}
    >
      {props.headerComponent && (
        <div className={styles['page-layout-grid-header-container']}>
          <Card className={styles['page-layout-grid-header']}>
            {props.headerComponent}
          </Card>
        </div>
      )}
      {!props.showLeftFirst && (
        <Grid container className={styles['page-layout-grid']}>
          {lgUp && (
            <Grid
              item
              lg={4}
              xl={xxlDown ? 3.5 : 3}
              className={styles['page-layout-grid-left']}
            >
              <Card
                className={clsx(
                  styles['page-layout-grid-card'],
                  styles['scrollY']
                )}
              >
                <Suspense fallback={<Loader />}>{props.leftComponent}</Suspense>
              </Card>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={12}
            lg={8}
            xl={xxlDown ? 8.5 : 9}
            className={styles['page-layout-grid-right']}
          >
            <Card
              className={clsx(
                styles['page-layout-grid-card'],
                !props.disableRightScroll && styles['scrollY']
              )}
            >
              <Suspense fallback={<Loader />}> {props.rightComponent}</Suspense>
            </Card>
          </Grid>
        </Grid>
      )}
      {props.showLeftFirst && (
        <Grid container className={styles['page-layout-grid']}>
          {lgUp && (
            <Grid
              item
              lg={4}
              xl={xxlDown ? 3.5 : 3}
              className={styles['page-layout-grid-left']}
            >
              <Card
                className={clsx(
                  styles['page-layout-grid-card'],
                  styles['scrollY']
                )}
              >
                <Suspense fallback={<Loader />}>{props.leftComponent}</Suspense>
              </Card>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={12}
            lg={8}
            xl={xxlDown ? 8.5 : 9}
            className={styles['page-layout-grid-right']}
          >
            <Card
              className={clsx(
                styles['page-layout-grid-card'],
                !props.disableRightScroll && styles['scrollY']
              )}
            >
              <Suspense fallback={<Loader />}> {props.rightComponent}</Suspense>
            </Card>
          </Grid>
        </Grid>
      )}
      {props.footerComponent && (
        <div className={styles['page-layout-grid-footer']}>
          {props.footerComponent}
        </div>
      )}
    </Stack>
  );
};
