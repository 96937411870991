import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from '@mui/material';
import { BaseEmoji, EmojiData, Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import clsx from 'clsx';

// Components
import { IconButton } from '../../ui/IconButton/IconButton';

// Hooks
import { useBreakpoints } from '../../hooks/use-breakpoints.hook';

// Models
import { Theme } from '../../models/shared.types';

// Stores
import { SharedState, useSharedStore } from '../../stores/use-shared.store';
import {
  NewsCenterState,
  useNewsCenterStore,
} from '../../../modules/newscenter/stores/use-news-center.store';

type EmojiPickerProps = {
  buttonClasses?: string;
  iconSize?: 'small' | 'medium' | 'large';
  onSelect?: (emoji: string) => void;
};

export const EmojiPicker = (props: EmojiPickerProps) => {
  const { smDown } = useBreakpoints();
  const { t } = useTranslation();

  // Shared store state
  const [theme] = useSharedStore((state: SharedState) => [state.theme]);

  const [anchorEl, setAnchorEl] = useNewsCenterStore(
    (state: NewsCenterState) => [state.anchorEl, state.setAnchorEl]
  );

  // Component state
  const [pickerTheme, setPickerTheme] = useState<
    'light' | 'auto' | 'dark' | undefined
  >(undefined);
  const open = Boolean(anchorEl);

  // Set picker theme by app theme
  useEffect(() => {
    theme === Theme.Dark && setPickerTheme('dark');
    theme === Theme.Light && setPickerTheme('light');
  }, [theme]);

  /**
   * Handler to select emoji.
   * @param emoji EmojiData
   */
  const onEmojiSelect = useCallback(
    (emoji: EmojiData) => {
      const baseEmoji = emoji as BaseEmoji;
      props.onSelect && baseEmoji.native && props.onSelect(baseEmoji.native);
      setAnchorEl(null);
    },
    // eslint-disable-next-line
    [props]
  );

  /**
   * Close menu.
   */
  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        classes={clsx(props.buttonClasses && props.buttonClasses)}
        icon={['far', 'grin']}
        iconSize={props.iconSize ?? 'small'}
        sxButton={{
          backgroundColor: anchorEl ? 'primary.light' : undefined,
        }}
        sxIcon={{
          color: anchorEl ? 'primary.main' : 'text.secondary',
        }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        open={open}
        sx={{
          '& input[type=search]': {
            boxShadow: 'none',
            borderColor: 'border.app',
            '&:focus': {
              borderColor: 'primary.main',
            },
          },
          '& .MuiPaper-root': {
            borderRadius: '0.5rem',
            boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
            marginTop: '-0.5rem',
          },
          '& .MuiList-root': {
            padding: 0,
          },
          '.emoji-mart': {
            fontFamily: "'Open Sans', 'sans-serif'",
            width: smDown ? '300px !important' : '340px !important',
          },
          '.emoji-mart-anchor-icon': {
            display: 'flex',
            justifyContent: 'center',
          },
          '.emoji-mart-search': {
            display: 'none',
          },
        }}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Picker
          color="#309EED"
          emojiSize={32}
          i18n={{
            search: t('app.emoji_picker.search'),
            notfound: t('app.emoji_picker.not_found'),
            categories: {
              search: t('app.emoji_picker.categories.search'),
              recent: t('app.emoji_picker.categories.recent'),
              people: t('app.emoji_picker.categories.people'),
              nature: t('app.emoji_picker.categories.nature'),
              foods: t('app.emoji_picker.categories.foods'),
              activity: t('app.emoji_picker.categories.activity'),
              places: t('app.emoji_picker.categories.places'),
              objects: t('app.emoji_picker.categories.objects'),
              symbols: t('app.emoji_picker.categories.symbols'),
              flags: t('app.emoji_picker.categories.flags'),
              custom: t('app.emoji_picker.categories.custom'),
            },
          }}
          showPreview={false}
          showSkinTones={false}
          theme={pickerTheme}
          useButton={false}
          onSelect={onEmojiSelect}
        />
      </Menu>
    </>
  );
};
