import { ReactNode, useEffect, useState } from 'react';
import { TFunctionResult } from 'i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import {
  Box,
  InputAdornment,
  OutlinedInput,
  SxProps,
  Theme,
} from '@mui/material';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';

// Components
import { FormFieldLabel } from '../FormFieldLabel/FormFieldLabel';
import { IconButton } from '../IconButton/IconButton';
import { ResultMessage } from '../ResultMessage/ResultMessage';

// Models
import { ResultState, Theme as ETheme } from '../../models/shared.types';

// Stores
import { SharedState, useSharedStore } from '../../stores/use-shared.store';

// Styles
import styles from './Input.module.scss';

type InputProps = {
  autoFocus?: boolean;
  classes?: string;
  defaultValue?: string | TFunctionResult;
  disabled?: boolean;
  endAdornment?: [IconPrefix, IconName];
  endAdornmentElement?: ReactNode;
  inputClassName?: string;
  inputSize?: 'small' | 'medium' | undefined;
  label?: string | TFunctionResult;
  multiline?: number;
  padding?: string;
  placeholder?: string;
  preset?: 'white';
  message?: string | TFunctionResult;
  optional?: boolean;
  register?: UseFormRegisterReturn;
  startAdornmentElement?: ReactNode;
  state?: ResultState;
  sublabel?: string | TFunctionResult;
  type?: string;
  value?: string | TFunctionResult;
  width?: string;
  widthClassName?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onEndAdornmentClick?: () => void;
};

export const Input = (props: InputProps) => {
  // Component state
  const [sxColors, setSxColors] = useState<SxProps<Theme> | undefined>(
    undefined
  );

  // Shared store state
  const [theme] = useSharedStore((state: SharedState) => [state.theme]);

  // Set color by result state
  useEffect(() => {
    let color: string | undefined = undefined;
    switch (props.state) {
      case ResultState.Error:
        color = 'error.main';
        break;
      case ResultState.Info:
        color = 'info.main';
        break;
      case ResultState.Success:
        color = 'success.main';
        break;
      case ResultState.Warning:
        color = 'warning.main';
        break;
      default:
        break;
    }

    // Set input colors by result state
    setSxColors({
      '& .MuiOutlinedInput-root': {
        backgroundColor:
          theme === ETheme.Dark
            ? 'bg.card'
            : props.preset === 'white'
            ? 'white'
            : 'inherit',
      },
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: color
          ? color
          : theme === ETheme.Dark
          ? 'transparent'
          : 'border.app',
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: color
          ? color
          : theme === ETheme.Dark
          ? 'transparent'
          : 'border.app',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: color ? color : 'primary.main',
      },
    });
  }, [props, theme]);

  return (
    <Box
      sx={{
        ...sxColors,
        width: props.width,
        '& .MuiOutlinedInput-input': {
          padding: props.padding ?? '1rem',
        },
      }}
      className={clsx(
        styles['input'],
        props.classes && props.classes,
        props.widthClassName
          ? props.widthClassName
          : props.width
          ? props.width
          : 'w-full'
      )}
    >
      {props.label && (
        <FormFieldLabel
          label={props.label}
          optional={props.optional}
          sublabel={props.sublabel}
        />
      )}
      <OutlinedInput
        autoFocus={props.autoFocus}
        value={props.value}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        endAdornment={
          props.endAdornment ? (
            <InputAdornment
              className={styles['input-end-adornment']}
              position="end"
            >
              <IconButton
                icon={props.endAdornment}
                onClick={props.onEndAdornmentClick && props.onEndAdornmentClick}
              />
            </InputAdornment>
          ) : props.endAdornmentElement ? (
            props.endAdornmentElement
          ) : undefined
        }
        minRows={props.multiline && props.multiline}
        multiline={props.multiline ? true : false}
        placeholder={props.placeholder}
        size={props.inputSize ? props.inputSize : undefined}
        startAdornment={
          props.startAdornmentElement && props.startAdornmentElement
        }
        sx={{ padding: 0 }}
        type={props.type}
        onChange={(event) =>
          props.onChange && props.onChange(event.target.value)
        }
        className={
          props.inputClassName ? props.inputClassName : styles['input-outlined']
        }
        {...props.register}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
      />
      {props.message && (
        <ResultMessage message={props.message} state={ResultState.Error} />
      )}
    </Box>
  );
};
