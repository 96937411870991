import { Box } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

// Styles
import styles from './Imprint.module.scss';

export const Imprint = () => {
  const { t } = useTranslation();
  return (
    <>
      <p>{t('about.imprint.header')}</p>
      <p className={clsx(styles['imprint-col'], styles['imprint-paragraph'])}>
        <span>{t('about.imprint.address1')}</span>
        <span>{t('about.imprint.address2')}</span>
        <span>{t('about.imprint.address3')}</span>
      </p>
      <p className={styles['imprint-paragraph']}>{t('about.imprint.info1')}</p>
      <p className={clsx(styles['imprint-col'], styles['imprint-paragraph'])}>
        <span>{t('about.imprint.info2')}</span>
        <span>{t('app.telephone')}: 0151 – 72 61 14 07</span>
        <Box
          sx={{
            '& a': {
              color: 'primary.main',
            },
          }}
        >
          {t('app.email')}:
          <a href="mailto:sascha.landowski@pengueen.de">
            sascha.landowski@pengueen.de
          </a>
        </Box>
      </p>
      <p className={styles['imprint-paragraph']}>{t('about.imprint.info3')}</p>
      <p className={clsx(styles['imprint-paragraph'], styles['imprint-title'])}>
        {t('about.imprint.disclaimer.title')}
      </p>
      <Box
        className={styles['imprint-paragraph']}
        sx={{
          '& a': {
            color: 'primary.main',
          },
        }}
      >
        {t('about.imprint.disclaimer.text1')}
        <a href="https://www.pengueen.de" rel="noreferrer" target="_blank">
          www.pengueen.de
        </a>
        {t('about.imprint.disclaimer.text2')}
      </Box>
      <p className={clsx(styles['imprint-paragraph'], styles['imprint-title'])}>
        {t('about.imprint.picture_credit.title')}
      </p>
      <Box
        className={styles['imprint-paragraph']}
        sx={{
          '& a': {
            color: 'primary.main',
          },
        }}
      >
        {t('about.imprint.picture_credit.text1')} iStock /
        <a
          href="https://www.istockphoto.com/de/portfolio/filadendron?mediatype=photography"
          rel="noreferrer"
          target="_blank"
        >
          filadendron
        </a>
        <p></p>
        {t('about.imprint.picture_credit.text2')} pixabay /
        <a
          href="https://pixabay.com/de/users/piro4d-2707530/"
          rel="noreferrer"
          target="_blank"
        >
          PIRO4D
        </a>
      </Box>
      <p className={clsx(styles['imprint-paragraph'], styles['imprint-title'])}>
        {t('about.imprint.consumer.share.title')}
      </p>
      <Box
        className={styles['imprint-paragraph']}
        sx={{
          '& a': {
            color: 'primary.main',
          },
        }}
      >
        {t('about.imprint.consumer.share.text1')} Internet:
        <a
          href="https://www.verbraucher-schlichter.de/"
          rel="noreferrer"
          target="_blank"
        >
          www.verbraucher-schlichter.de
        </a>
        , {t('app.email')}:
        <a href="mailto:mail@verbraucher-schlichter.de">
          mail@verbraucher-schlichter.de
        </a>
        . {t('about.imprint.consumer.share.text2')}
      </Box>
      <p className={clsx(styles['imprint-paragraph'], styles['imprint-title'])}>
        {t('about.imprint.consumer.regulation.title')}
      </p>
      <Box
        className={styles['imprint-paragraph']}
        sx={{
          '& a': {
            color: 'primary.main',
          },
        }}
      >
        {t('about.imprint.consumer.regulation.text1')}
        <a
          href="https://ec.europa.eu/consumers/odr"
          rel="noreferrer"
          target="_blank"
        >
          https://ec.europa.eu/consumers/odr
        </a>
        {t('about.imprint.consumer.regulation.text2')}
      </Box>
    </>
  );
};
