import create, { State } from 'zustand';

// Models

export interface CommunityState extends State {
  dialogCommunityCreate: boolean;
  setDialogCommunityCreate: (dialogCommunityCreate: boolean) => void;
}

export const useCommunityStore = create<CommunityState>((set) => ({
  dialogCommunityCreate: false,
  setDialogCommunityCreate: (dialogCommunityCreate: boolean) =>
    set({ dialogCommunityCreate }),
}));
