import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import { Input } from '../../ui/Input/Input';
import { TextButton } from '../../ui/TextButton/TextButton';

// Models
import { ResultState } from '../../models/shared.types';

// Styles
import styles from './FormDelete.module.scss';

type FormDeleteProps = {
  text: string;
  textButton?: string;
  textMatch?: RegExp;
  hideInputText?: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

type FormDeleteValues = {
  delete: string;
};

export const FormDelete = (props: FormDeleteProps) => {
  const { t } = useTranslation();

  // React hook form validation schema
  const formDeleteValidation = yup.object({
    delete: yup
      .string()
      .matches(
        props.textMatch ?? t('form.common.errors.matches.delete'),
        t('form.common.errors.matches.response')
      )
      .min(
        props.textMatch
          ? props.textMatch.toString().length
          : t('form.common.errors.matches.delete').length,
        t('form.common.errors.matches.response')
      )
      .max(
        props.textMatch
          ? props.textMatch.toString().length
          : t('form.common.errors.matches.delete').length,
        t('form.common.errors.matches.response')
      )
      .required(t('form.common.errors.matches.response')),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm<FormDeleteValues>({
    resolver: yupResolver(formDeleteValidation),
  });

  // Make input valid if props.hideInputText is true
  useEffect(() => {
    props.hideInputText &&
      !props.textMatch &&
      setValue('delete', t('form.common.errors.matches.delete'));
  }, [props]);

  return (
    <form
      onSubmit={handleSubmit(props.onSubmit)}
      className={styles['group-create-edit-common']}
    >
      <Box className={styles['form-delete-text']}>{props.text}</Box>

      {!props.hideInputText && (
        <>
          <Box className={styles['form-delete-label']}>
            <Box className={styles['form-delete-label-text']}>
              {t('form.common.errors.matches.text1')}
            </Box>
            <Box className={styles['form-delete-label-match']}>
              {props.textMatch ?? t('form.common.errors.matches.delete')}
            </Box>
            <Box>{t('form.common.errors.matches.text2')}</Box>
          </Box>
          <Box className={styles['form-delete-input']}>
            <Input
              message={errors?.delete && errors.delete.message}
              register={register('delete')}
              state={errors?.delete && ResultState.Error}
            />
          </Box>
        </>
      )}
      <Box className={styles['form-delete-buttons']}>
        <TextButton
          classes={styles['form-delete-buttons-cancel']}
          preset="secondary"
          onClick={props.onClose}
        >
          {t('app.cancel')}
        </TextButton>
        <TextButton
          preset="primary"
          // disabled={!!Object.keys(errors).length}
          type="submit"
        >
          {props.textButton ?? t('app.actions.delete')}
        </TextButton>
      </Box>
    </form>
  );
};
