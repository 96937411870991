import { AuthTranslations } from '../models/auth.translations.interface';

export const authTranslationsDE: AuthTranslations = {
  account: {
    setup: {
      community: {
        employees: 'Mitarbeiter:innen',
        form: {
          name: {
            error: 'Der Name muss eine Mindestlänge von 3 Zeichen besitzen',
            placeholder: 'Name der Gemeinschaft',
            title: 'Bezeichnung',
          },
          sector: {
            error: 'Es muss eine Branche ausgewählt werden',
            title: 'Welche Branche ist passend?',
          },
        },
        members: 'Mitglieder',
        title: {
          defined: ', wie heißt deine Gemeinschaft?',
          undefined: 'Wie heißt deine Gemeinschaft?',
        },
      },
      invite: {
        success: 'Einladung angenommen.',
      },
      name: {
        submit: {
          defined: 'Das bin ich',
          undefined: 'Ohne Namen weiter',
        },
        subtitle: `Wir freuen uns dich kennen zu lernen. Verrätst du uns deinen Namen? Dann können wir und von dir eingeladene Personen auch deinen Namen sehen.
          Das macht das ganze doch etwas persönlicher, oder?`,
        title: 'Willkommen, wie heißt du?',
      },
      password: {
        title1: 'Super',
        title2:
          'Jetzt nur noch kurz dein zukünftiges Passwort wählen und schon geht’s los',
      },
      success: {
        button: 'Zu meiner Gemeinschaft',
        text: 'Starte jetzt mit deinen ersten Hilfsmittel und lade weitere Nutzer ein und richte dein Profil ein.',
        title: 'Gute Arbeit',
      },
      users: {
        form: {
          employees: {
            placeholder: 'Deine Bezeichnung z.B. Vorstand',
            title: 'Mitarbeiter (erweiterte Rechte)',
          },
          members: {
            placeholder: 'Deine Bezeichnung z.B. Ratsmitglieder',
            title: 'Mitglieder (einfache Rechte)',
          },
          title: 'Anpassung der Rollenbezeichnung',
        },
        info: `Wir unterscheiden bei Pengueen standardmäßig in die Nutzerrollen „Mitarbeiter:in“ und „Mitglied“ um bestimmte Berechtigungen zu unterscheiden.
        Du hast hier die Möglichkeit diese anzupassen.`,
        title: 'Wie heißen deine Nutzer?',
      },
    },
  },
  login: {
    error: {
      credentials:
        'Deine E-Mail-Adresse oder dein Passwort sind nicht korrekt. Bitte überprüfe deine Eingaben.',
      timeout:
        'Du hast dein Passwort mehrmals falsch eingegeben. Warte bis der Timer abgelaufen ist und du es erneut versuchen kannst, oder klicke auf Passwort vergessen, um es zurück zu setzen.',
    },
    keep: 'Für zwei Tage angemeldet bleiben',
    tan: {
      subtitle:
        'Du hast die sichere Anmeldung mit SMS-TAN gewählt. Fordere eine TAN an und wir senden dir eine SMS mit einer Bestätigungs-TAN an {*********211}.',
      title: 'Authentifizierung benötigt',
    },
    timeout: 'Verbleibende Zeit bis zur Neueingabe des Passworts',
    title: 'Anmelden',
    existing_user_invite_title:
      'Du hast die Einladung erfolgreich angenommen. Jetzt anmelden.',
  },
  password: {
    change: {
      btnSubmit: 'Passwort speichern und anmelden',
      passwordNew: 'Neues Passwort',
      passwordNewConfirm: 'Neues Passwort bestätigen',
      text: 'Bitte wähle dein zukünftiges Passwort.',
      title: 'Änderung deines Passworts',
    },
    forgot: 'Passwort vergessen',
    requested: {
      text: 'Du erhältst in Kürze eine E-Mail zur Bestätigung und Erstellung deines neuen Passworts. Schaue bitte vorsichtshalber auch in deinem Spam-Ordner nach.',
      title: 'Du hast dein Passwort erfolgreich zurückgesetzt',
    },
    reset: {
      error:
        'Der von dir ausgewählte Link ist abgelaufen. Du kannst den Vorgang zum Zurücksetzen des Kennworts hier erneut starten.',
      text: 'Bitte gib Deine E-Mail-Adresse ein, um dein Passwort zurückzusetzen.',
      title: 'Passwort zurücksetzen',
    },
    strength: 'Passwortstärke',
    success: {
      text: 'Passwort zurücksetzen erfolgreich',
      title: 'Passwort zurücksetzen erfolgreich',
    },
    title: 'Passwort',
  },
  registration: {
    disclaimer:
      'Hiermit erkläre ich mein Einverständnis zur Verarbeitung meiner personenbezogenen Daten zu vorgenanntem Zweck. Ich kann mein Einverständnis jederzeit ganz oder teilweise mit Wirkung für die Zukunft durch einfache E-Mail (an support@pengueen.de) oder postalisch (M.F.G. Pengueen UG (haftungsbeschränkt), Damsdorfer Hauptstraße 41A, 14797 Kloster Lehnin) widerrufen.',
    info: '14-tägige kostenlose Testversion – Keine Kreditkarte notwendig',
    login: 'Du hast schon ein Konto? Melde dich hier an',
    new: 'Neu registrieren',
    privacy1: 'Ich akzeptiere die',
    privacy2: 'und die',
    title: 'Registrieren',
    title_invite:
      'Du hast deine Einladung erfolgreich angenommen. Registriere dich jetzt.',
    submit: 'Jetzt registrieren',
    success: {
      text: 'Du erhältst in Kürze eine E-Mail zur Bestätigung deines Kontos. Schaue bitte vorsichtshalber auch in deinem Spam-Ordner nach.',
      title: 'Du hast dich erfolgreich registriert',
    },
    validation: {
      disclaimer: 'Dieser Erklärung muss zugestimmt werden',
    },
  },
  trial: {
    expired: {
      admin: {
        button_payment_update: 'Jetzt Zahlungsdaten aktualisieren',
        text1:
          'Du und deine Nutzer können in Pengueen derzeit keine Inhalte editieren,',
        text2: 'Bestehende Inhalte können nur noch eingesehen werden.',
        text3:
          'Hat dir Pengueen gefallen? Trage einfach deine Zahlungsinformationen im',
        text4: 'Profil ein, um Pengueen weiterhin zu nutzen.',
        title: 'Dein 14-Tage-Test-Zeitraum ist leider abgelaufen',
      },
      button_contact: 'Kontakt mit uns aufnehmen',
      button_payment: 'Zu unseren Zahlungskonditionen',
      user: {
        button_inform: 'Jetzt Ansprechpartner informieren',
        text1:
          'Aufgrund von Einstellungen bei deiner Gemeinschaft, kannst du keine neunen Inhalte editieren oder Funktionen nutzen.',
        text2: 'Bestehende Inhalte können nur noch eingesehen werden.',
        text3:
          'Bitte melde dich bei dem Ansprechpartner deiner Gemeinschaft, um Pengueen weiterhin vollständig nutzen zu können.',
        title:
          'Deine Inhalte können derzeit nur noch eingesehen aber nicht bearbeitet werden',
      },
    },
  },
  validation: {
    passwordChar1: 'Dein Passwort muss folgende Kriterien erfüllen',
    passwordChar2: ' 8-stellig',
    passwordChar3: ' Mindestens einen ',
    passwordChar4: 'Großbuchstaben',
    passwordChar5: ' Mindestens eine ',
    passwordChar6: 'Zahl',
    passwordChar7: ' Mindestens ein ',
    passwordChar8: 'Sonderzeichen',
    passwordChar9: '',
    passwordChar:
      'Dein Passwort muss mindesten 8-stellig sein, einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.',
    passwordConfirm: 'Das Passwort muss übereinstimmen.',
    passwordReq: 'Es muss ein Passwort eingegeben werden.',
    passwordStrength: 'Passwortstärke',
    passwordCondition: 'Das Passwort muss alle Kriterien erfüllen.',
  },
};
