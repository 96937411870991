import { TemplatesTranslations } from '../models/templates.translations.interface';

export const templatesTranslationsIT: TemplatesTranslations = {
  input_setting: {
    title: 'Scegli il tipo di input',
    select: 'Selezione del tipo di ingresso',
    category: {
      single: 'Campo di testo a riga singola',
      multi: 'Corpo del testo su più righe',
      number: 'Campo numerico',
      number_for: 'Campo numerico (per i calcoli)',
    },
    date: 'Data',
    date_time: 'Data e ora',
    duration: 'Durata (hh:mm)',
    time: 'Tempo',
    email: 'E-mail',
    telephone: 'Numero di telefono',
    url: 'Url',
    point: 'Mille punti',
    suffix: 'Suffisso (es. km, ore, ..)',
    set_suffix: 'Convalida la sintassi nel frontend',
  },
  actions: {
    delete: 'cancellare nel modello',
    delete_check: {
      title: 'Elimina modello',
      subtitle: 'Il modello verrà eliminato e non potrà essere ripristinato.',
    },
    delete_success: 'I modelli strumenti venivano cancellato',
    finish: 'concludere l`elaborazione',
    publish: 'pubblicare',
    no_publish: {
      title: 'non pubblicare piu',
      success: 'Il tuo modello delli strumenti non è piu pubblicato',
    },
    print: 'visualizzazione stampa nuova',
    settings: 'Impostazione base',
    update: {
      error: 'Errore da salvare  modello degli strumenti',
      title: 'salvare /attualizzare',
    },
  },
  elements: {
    create: {
      abort: 'End element edit?',
      content: {
        form: {
          label: 'contenuto',
        },
        subtitle: 'Metti il contenuto, qualle dovrebbe indicato',
        title: 'contenuto',
      },
      headline: {
        form: {
          label: 'Intestazione',
          placeholder: 'p.e. agenda',
        },
        subtitle: 'come deve essere il tuo titolo?',
        title: 'Titolo',
      },
      image: {
        subtitle: 'carica il tuo immagine, qualle deve indicato',
        title: 'caricare immagine',
      },
      name: {
        form: {
          description: {
            label: 'Descrizione (visualizzata sotto il titolo)',
            placeholder:
              'Ecco un esempio per una descrizione, per farti sapere, come appaiono le tue categorie. Descrizione sono sempre sotto la designazione e sopra del`elemento input',
          },
          name: {
            placeholder: 'p.e. agenda',
            sublabel: `Etichetta (visualizzata sopra l'elemento)`,
          },
          help_text: {
            placeholder: 'Esempi o ulteriori spiegazioni.',
            label: `Testo della guida (icona della guida blu accanto all'etichetta)`,
          },
        },
        subtitle: 'Da il tuo elemento una designazione',
        title: 'Nome',
      },
      section: {
        form: {
          description: {
            label: 'Descrizione (visualizzata sotto il titolo)',
            placeholder:
              'Ecco un esempio per una descrizione, per farti sapere, come appaiono le tue categorie. Descrizione sono sempre sotto la designazione e sopra del`elemento input',
          },
          name: {
            placeholder: 'p.e. agenda',
            sublabel: `Etichetta (visualizzata sopra l'elemento)`,
          },
        },
        subtitle: 'Da il tuo elemento una designazione',
        title: 'Nome',
      },
      options: {
        form: {
          add: 'aggiungi una opzione nuova',
          color: 'colore',
          placeholder: 'p.e. scarso',
        },
        subtitle: 'definisci gli opzioni di scelta, qualle devono indicati',
        title: 'opzioni',
      },
      vote: {
        form: {
          add: 'aggiungi star',
          remove: 'rimuovi star',
          placeholder: '',
        },
        subtitle: 'definisci gli opzioni di scelta, qualle devono indicati',
        title: 'opzioni',
      },
      settings: {
        form: {
          advanced: {
            auto_date: 'compillare la data attualle automatica',
            auto_select:
              'Selezione automatica se gli utenti appartengono a un determinato gruppo',
            connected_contact: 'Questo elemento mostra gruppi di persone.',
            date_with_time: 'aggiungere l`ora ulteriore',
            label: 'setting avanzato',
            only_edit_own_posts: 'puoi editare solo inserimenti da te',
            only_show_employees: 'Indica solo collaboratori per scegliere',
            only_show_members: 'Indica solo membri per scegliere',
            only_show_own_posts:
              'solo inserimenti da te sono visibili (admin puo vedere tutti)',
            required: 'questo elemento marchiare obbligante',
            only_image: 'questo elemento deve permettere solo immagini!',
            only_film: 'Dieses Element soll nur Filme erlauben! (Bsp.: .mp4)',
            only_office:
              'Dieses Element soll nur Office-Dateien erlauben! (Bsp.: .docx)',
            only_archive:
              'Dieses Element soll nur Archive erlauben! (Bsp.: .zip)',
            only_one_image: 'questo elemento deve permettere solo un file!',
            small_image:
              'questo elemento ha la misura mezza solo (elemento prossimo è accanto)!',
            contact_card: 'Indica il contatto a un biglietto da visita',
            vote_change: 'valutazione è modificabile',
            vote_result: 'Indica resultato dopo valutazione alli votanti.',
            n_posts: 'Der Benutzer kann n-Einträge erstellen',
            n_posts_sub:
              '(keine Eingabe oder 0 kann beliebig viele Einträge erstellen)',
            toggle_false_string: 'An/Aus-Schalter Label "Nein"',
            toggle_true_string: 'An/Aus-Schalter Label "Ja"',
            value_show_in_other_tool:
              'Daten für ein anderes Hilfsmittel bereitstellen.',
            show_only_for_admin:
              'Das Element wird nur den Gemeinschaft-Admins angezeigt.',
            only_admin_can_post: 'Nur Admin kann Einträge erstellen.',
            only_admin_can_edit: 'Nur Admin kann bearbeiten.',
            show_at_arccordion_header: 'Element im Akkordeon-Header anzeigen.',
          },
          list: {
            employees: 'Mostra contenuto come colonna sulla rete',
            label: `Scegli se il contenuto dell'elemento apparirà come una colonna nella rete una volta assegnata questa risorsa a una persona',         members: 'Indica contenuto a colonna alla lista dei membri`,
            members: 'Indica contenuto a colonna alla lista dei membri',
          },
          rights: {
            employee: 'Employee',
            label:
              'chi ha il permesso di editare e compillare questo elemento?',
            member: 'Membro',
            pengueen_admin: 'Pengueen Admin',
          },
          export: {
            export_with_id: `ID con output all'esportazione`,
            disable_export: `Non esportare l'elemento`,
            label: `Impostazioni di esportazione`,
            sublabel: `Considera questo elemento in un'esportazione`,
          },
        },
        subtitle: 'scegli impostazioni ampliati e diritti per questo elemento',
        title: 'setting e diritti',
      },
      summary: 'riepilogo',
      type: {
        subtitle: 'Che tipo di elemento vuoi creare?',
        title: 'Elemento',
      },
      title: 'La tua attitudine',
      edit_title: 'Proprietà di {{templateName}}',
      element: 'elemento',
      view_changes: 'View changes',
    },
    group: {
      error: 'Errore al` aggruppare degli elementi:',
      success: 'Elementi sono aggruppati al`inserimento multiplo',
      title: 'aggruppare elementi al`inserimento multiplo',
      tooltip:
        'Scegli al meno due elementi dai Checkbox, per congiungerli a un voce multiplo',
    },
    delete: {
      subtitle: 'l`elemento viene cancellato e non si puo reimpostarlo.',
      text: 'cancella elemento ora?',
      title: 'cancella elemento',
      error:
        'Al momento usi questo modello dal strumento. Si deve cancellare anche strumenti creati?',
    },
    multi_elements: {
      settings: {
        display: 'Visualizzazione standard:',
        elements_admin: {
          label: 'Elementi sono visibili per il admin',
          placeholder: 'scelta degli elementi',
        },
        elements_visible: {
          label: 'Elementi visibili nel strumento',
          placeholder: 'scelta degli elementi',
        },
        error: 'Errore al salvare degli impostazioni',
        sort_by: {
          label: 'a qualle elemento si deve ordinare primario?',
          placeholder: 'scelta degli elimenti',
        },
        sort_order: {
          asc: 'ascendente',
          desc: 'discendente',
          label: 'ordinare a',
        },
        title: 'Impostazioni',
      },
    },
    types: {
      favorites: 'preferiti',
      information_display: {
        auto_id: {
          subtitle: "Informazioni per un documento d'identità",
          title: 'ID automatico',
          prefix: 'Prefisso',
          postfix: 'Postfisso',
          start_value: 'Valori iniziali',
        },
        headline: {
          subtitle: 'sepera elementi ottichi con una intestazione',
          title: 'Intestazione',
        },
        image: {
          subtitle: 'per indicare immagini all` illustrazione',
          title: 'Immagine',
        },
        info: {
          subtitle: 'per informazione e descrizione',
          title: 'Testo informativo',
        },
        title: 'Elementi per la presentazione d`informazioni',
      },
      information_obtain: {
        contact: {
          subtitle: 'Selezione dai contatti',
          title: 'contatto',
        },
        date_picker: {
          subtitle: 'per data e indicazione temporale',
          title: 'scelta data',
        },
        email: {
          subtitle: 'per digitare indirizzi email',
          title: 'Email',
        },
        input: {
          subtitle: 'per testi brevementi di una riga',
          title: 'Campo testo e numero',
        },
        number: {
          subtitle: 'Sintassi singola, su più righe (ad es. data)',
          title: 'ciffra',
        },
        reminder: {
          subtitle: 'per ricordi basati a data o tempo',
          title: 'ricordo',
        },
        telephone: {
          subtitle: 'per digitare nummeri telefono',
          title: 'Telefono',
        },
        textarea: {
          subtitle: 'per testi piu lunghi da una riga',
          title: 'casella di testo',
        },
        title: 'Elementi semplici per ottenere informazioni',
        toggle: {
          subtitle: 'si o no? decissioni semplici',
          title: 'Toggle',
        },
        upload: {
          subtitle: 'Caricare files',
          title: 'File',
        },
      },
      options: {
        checkbox: {
          subtitle: 'Selezione di una o più opzioni',
          title: 'checkbox',
        },
        group: {
          subtitle: 'Selezione di un gruppo dalla rete',
          title: 'gruppo',
        },
        create_row: {
          subtitle: 'Zum Anzeigen, wer den Eintrag erstellt hat',
          title: 'Ersteller',
        },
        radio_button: {
          subtitle: `Selezionando un'opzione`,

          title: 'pulsante di opzione',
        },
        select: {
          subtitle: 'Selezione di una o più opzioni',
          title: 'scegli',
        },
        title:
          'Elementi con scelta di opzioni definito dall` utente, per ottenere informazioni',
        user: {
          subtitle: 'per la scelta degli utenti dalla tua comunità',
          title: 'persona / utente',
        },
      },
      vote: {
        star: {
          subtitle: 'Il modo piu conosciuta per valutare',
          title: 'Metti 1-n',
        },
        decision: {
          subtitle: 'lascia votare per un inserimento',
          title: 'decissione',
        },
        grade: {
          subtitle: 'ogni si puo dare 1-n punti',
          title: 'punti 1-n',
        },
        map: {
          subtitle: 'votazione per ogni inserimento con schede colorate',
          title: 'scheda',
        },
        title: 'Elementi per la votazione (viene presto)',
      },
    },
  },
  print: {
    form: {
      cover: {
        label: 'Cover',
      },
      name: {
        label: 'designazione dalla tua visualizzazione stampa',
        placeholder: 'p.e. protocollo',
      },
    },
    save: 'salva visualizzazione stampa',
  },
  publish: {
    alert: 'Concludere la preparazione  da condividere?',
    form: {
      button: 'Salvare e pubblicare',
      community: {
        choose: 'Scegli una comunità come mittente',
        label:
          'Vorrei che una delle mie comunità diventasse il mittente del modello.',
        description:
          'Descrivi cosa fa la tua comunità e come viene utilizzato lo strumento.',
        tos: {
          content1: 'Con la presente confermo di aver letto le seguenti ',
          content2: 'regole ',
          content3:
            'per la condivisione di modelli di risorse utilizzando la licenza MIT open source.',
          dialog: {
            text1:
              'quando partecipanti hanno impostato strumenti creato da loro al marketplace o strumenti (seguente: „Software"), qualle venivano dai altri partecipanti, vogliono usarli dal marketplace, solo con il consenso della licenza seguente:',
            text2:
              'ognuno, chi ottiene una copia dal questo software e file documentazione appartenente ( „Software" ), con la presente si ottiene il permesso gratuito di usare il software senza restrizioni, incluso i diritti da copiare, modificare, pubblicare, allocare, fare sottolicenze e / o vendere copie dal Software. Personne, chi ottengono il Software al disposizione, sono permessi solo di condizioni seguenti:',
            text3:
              'Der obige Urheberrechtshinweis und dieser Genehmigungshinweis müssen in allen Kopien oder wesentlichen Teilen der Software enthalten sein.',
            text4:
              'Die Software wird ohne jegliche ausdrückliche oder stillschweigende Gewährleistung, einschließlich, aber nicht beschränkt auf die Gewährleistung der Marktgängigkeit, der Eignung für einen bestimmten Zweck und der Nichtverletzung von Rechten Dritte, zur Verfügung gestellt . Die Autoren oder Urheberrechtsinhaber sind in keinem Fall haftbar für Ansprüche, Schäden oder andere Verpflichtungen, ob in einer Rechtsstreitigkeit, die sich aus Vertrag, unerlaubter Handlung oder sonstige Gründe stützt, die sich aus, aus oder in Verbindung mit der Software oder der Nutzung oder anderen Verwendungen der Software ergeben.',
            text5:
              'a un responsabile § 7 Abs.1 TMG e § 18 Abs. 1 MStV behalten wir uns vor, Vorschläge für Hilfsmittel ohne Angabe von Gründen von der Veröffentlichung auf unserer Plattform auszuschließen."',
            title: 'regole (Open Source direttive con licenza)',
          },
          error: 'si deve accettare le regole',
        },
      },
      description: {
        error: 'deve essere disponibile una descrizione',
        label: 'descrizione brevemente',
        placeholder: 'questo modello strumento si puo usare per ...',
      },
      name: {
        error: 'si deve digitare un nome',
        label: 'nome del modello strumento',
        placeholder: 'p.e. protocollo',
      },
      screenshot: {
        error: {
          required: 'si deve scegliere un Screenshot',
          too_many: 'Troppi Screenshot sono scelti',
        },
        label:
          "Carica un'immagine che dovrebbe essere mostrata nella vista dettagliata del tuo modello di risorsa nel marketplace.",
      },
      types: {
        community: 'Interno (per la mia comunità)',
        error: 'È necessario selezionare una comunità.',
        label: 'Per chi dovrebbe essere pubblicato il tuo template?',
        public: 'condividere con la comunità Pengueen',
        private: 'Interno (solo per me)',
      },
      use_case: {
        error: 'si deve scegliere un caso d`uso',
        label: 'Dove dovremmo posizionare il tuo modello?',
      },
    },
    notification: {
      subtitle:
        'controlliamo il tuo modello. ricevi l`informazione appena il modello viene accettato.',
      title: 'consegnato con successo al controllo ',
    },
    notification_private: {
      subtitle: '',
      title: 'Il tuo modello strumento veniva pubblicato con successo.',
    },
    subtitle:
      "Pubblica il tuo modello di risorsa in privato, per utilizzarlo all'interno della tua comunità o per condividerlo con la comunità Pengueen tramite il mercato.",
    title: 'pubblica il tuo modello strumento',
  },
  purpose: {
    community: 'organizzare la comunità',
    living: 'per il contesto',
    title: 'scoppo',
  },
  sections: {
    create: {
      button: 'creare',
      subtitle: 'da il tuo strumento un segmento nuovo',
      title: 'aggiungi un segmento nuovo',
    },
    delete: {
      note: 'non puoi cancellare ancora il segmento, siccome contiene ancora elementi.',
      subtitle: 'Il segmento viene cancellato e si non puo reimpostarlo.',
      text: 'cancellare il segmento ora?',
      title: 'cancellare il segmento',
    },
    edit: {
      button: 'Edita',
      subtitle: 'Edita il segmento',
    },
    elements: {
      delete:
        'l`inserimento multiplo si puo cancellare solo quando gli elementi appartenenti sono cancellati anche.',
      add: 'aggiungi elemento nuovo',
      example: {
        description: 'questo è un esempio per una  descrizione',
        name: 'Esempio per un nome ',
      },
    },
    info: 'ad un esempio abbiamo creato un segmento e una casella di testo, cosi puoi vedere, come questo funziona. puoi editare questo elemento o cancellarlo.',
    name: {
      label: 'designazione del segmento',
      placeholder: 'p.e. tema del protocollo',
    },
    restricted: {
      label: 'segmento visibile solo per il admin da Pengueen',
      title: 'definisci la visibilità del segmento',
    },
  },
  settings: {
    alert: {
      create: 'concludere la creazione degli strumenti?',
      edit: 'concludere da editare gli strumenti?',
    },
    description: {
      label: 'descrizione brevemente',
      placeholder: 'questo modello strumento si puo usare per ...',
      subtitle:
        'descrivi per altri utenti, che si tratta nel modello strumento.',
      title: 'descrivi, per che cosa si puo usare il modello strumento',
    },
    extra: {
      settings: {
        restricted: 'solo la comunità puo crearlo',
        unique: 'solo una volta si puo crearlo e usarlo',
      },
      subtitle:
        'per specificare l`utilizzazione degli strumenti, fai altri impostazioni.',
      title: 'Indica altri impostazioni del modello strumento',
    },
    icon: {
      label: 'scegli un icon',
      subtitle: 'l`icon ti aiuta di trovare il strumento piu veloce.',
      title: 'scegli un icon per il tuo modello strumento',
    },
    notification: {
      title: 'Notifiche',
      subtitle:
        'Chi dovrebbe essere informato in seguito a modifiche agli strumenti creati in questa modello?',
      case: 'Quando',
      template_created: 'Strumento creato',
      template_changed: 'Strumento modificato*',
      template_changed_explanation:
        "* Le notifiche relative alle modifiche o agli elementi multipli vengono configurate nelle impostazioni dell'elemento multiplo specifico",
      inform: 'Informa',
      community_owner: 'Proprietario della comunità',
      admins: 'Amministratori',
      employees: 'Dipendenti',
      following_receiver: 'Ricevente seguente',
      tool_creator: 'Creatore dello strumento',
      affected_person: 'Persona interessata**',
      affected_person_explanation:
        "** La persona o i membri di un gruppo a cui è stato aggiunto questo strumento; se diverso dall'autore.",
      multi_title: 'Impostazioni delle notifiche',
      multi_subtitle:
        'Chi dovrebbe essere successivamente informato su nuove voci multiple o modifiche alle voci multiple?',
      multielement_created: 'Nuova voce multipla (anche eliminazione)',
      multielement_changed: 'Modifica dei contenuti di una voce multipla',
      multielement_creator: 'Creatore della VM',
      multielement_affected_person: 'Persona interessata**',
      multielement_changed_explanation:
        '* Nicht vergessen: Markiere diejenigen Elemente des Mehrfacheintrages, deren Veränderung eine Benachrichtigung auslösen soll.',
      multielement_affected_person_explanation: `** La persona assegnata nell'MFE o membro di un gruppo assegnato; se diversa dal creatore.`,
      element:
        'You can trigger notifications when the content of this element is changed.',
      element_changed: 'This element triggers notifications.',
      element_changed_title: 'Output the label in the notification',
      element_changed_content: 'Output the content/value in the notification',
    },
    interface: {
      title: 'Schnittstelle',
      subtitle:
        'Anbindung einer externen (Web-) Anwendung für den Zugriff auf die Daten dieses Hilfsmittels.',
      token_label: 'Schnittstelle-Token',
      token_placeholder: '8923fbb11deee0877fcb69e01db560a1',
      url_label: 'Schnittstelle-URL',
      url_placeholder: 'https://p4.pengueen.de',
    },
    name: {
      label: 'nome del modello strumento',
      placeholder: 'p.e. protocollo',
      subtitle:
        'da il tuo modello degli strumenti una descrizione comprensibile.',
      title: 'come deve chiamato il modello degli strumenti?',
    },
    purpose: {
      subtitle:
        'scegli, se lo strumento sarà per organizzare la tua comunità o per il contesto da una personna.',
      title: 'Per quale scoppo si puo usare il tuo strumento?',
    },
    summary: {
      button: 'guardare i modificazioni',
      description: 'descrizione',
      extra: 'Impostazione',
      name: 'designazione',
      icon: 'Icon',
      save: 'salvare cambiamenti',
      success: 'cambiamenti venivano salvato',
      subtitle: 'controlla i tui dati',
      title: {
        create: 'è tutto giusto?',
        edit: 'Editare gli impostazioni base',
      },
    },
    title: 'crea un strumento nuovo',
  },
  status: {
    blueprint: 'blueprint',
    community: 'per la comunità',
    published: 'pubblicato',
  },
  use_cases: {
    documentation: 'documentazione',
    other: 'altri',
    project_management: 'gestione di progetto',
  },
  workbench: {
    make: 'crea modelli',
    community: 'Modelli per strumenti per',
    mobile: {
      text: 'Il nostro laboratorio per strumenti e la creazione dal tuo strumento non è ottimizzato ancora per schermi piccoli o cellulari. Per la visualizzazione migliore cambia p.f. a un schermo largo.',
      title: 'cambia a un schermo piu grande',
    },
    personal: 'I tui modelli degli strumenti',
    title: 'banco',
    title_page: 'banco',
  },
};
