import { useTranslation } from 'react-i18next';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

// Hooks
import { useUtils } from './use-utils.hook';

// Models
import {
  AttachmentFile,
  BreadcrumbPath,
  PagePath,
} from '../models/shared.types';

export const useShared = () => {
  const { t } = useTranslation();
  const { iconPropByFileTypeGet } = useUtils();

  /**
   * Get breadcrumb path by page.
   * @returns Breadcrumb path array
   */
  const getBreadcrumbPath = (page: PagePath): BreadcrumbPath[] => {
    const path: BreadcrumbPath[] = [];
    path.push({
      location: '/dashboard',
      title: t('dashboard.title'),
    });
    if (page === PagePath.Workbench) {
      path.push({
        location: `/tools/workbench`,
        title: t('templates.workbench.title_page'),
      });
    }
    if (page === PagePath.Market) {
      path.push({
        location: '/market',
        title: t('market.title'),
      });
    }
    if (page === PagePath.Newscenter) {
      path.push({
        location: '/newscenter',
        title: t('newscenter.title'),
      });
    }
    if (page === PagePath.Contacts) {
      path.push({
        location: `/contacts`,
        title: t('contacts.title'),
      });
    }
    return path;
  };

  /**
   * GET File preview object by AttachmentFile.
   * @param file File
   * @returns File preview object
   */
  const filePreviewByAttachmentFileGet = (
    file: AttachmentFile
  ): { icon?: [IconPrefix, IconName]; image?: string; name: string } => {
    const icon: [IconPrefix, IconName] | undefined = !file.type.includes(
      'image'
    )
      ? iconPropByFileTypeGet(file.type)
      : undefined;
    let image: string | undefined = undefined;
    if (!icon) {
      image = file.url;
    }
    return {
      icon,
      image,
      name: file.name,
    };
  };

  /**
   * GET File preview object by file.
   * @param file File
   * @returns File preview object
   */
  const filePreviewByFileGet = (
    file: File
  ): { icon?: [IconPrefix, IconName]; image?: string; name: string } => {
    const icon: [IconPrefix, IconName] | undefined = !file.type.includes(
      'image'
    )
      ? iconPropByFileTypeGet(file.type)
      : undefined;
    let image: string | undefined = undefined;
    if (file.type.includes('image')) {
      try {
        image = URL.createObjectURL(file);
      } catch (error) {}
    }
    return {
      icon,
      image,
      name: file.name,
    };
  };

  /**
   * GET Full name by first, last and nick name.
   * @param first_name First name
   * @param last_name Last name
   * @param nickname Nick name
   * @returns Full name or nameless string
   */
  const fullNameGet = (
    first_name: string | undefined,
    last_name: string | undefined,
    nickname?: string | undefined
  ): string => {
    const fullName = `${
      first_name && last_name
        ? `${first_name} ${last_name}`
        : first_name
        ? first_name
        : last_name
        ? last_name
        : t('app.nameless')
    }`;

    const fullNameNickname = `${fullName}${
      nickname && nickname.length > 0 ? ` (${nickname})` : ''
    }`;

    return fullNameNickname.toString();
  };

  /**
   * Get locale string by language string.
   * #TODO: Change account language to correct locales? en --> en-US etc.
   * @param language Language
   * @returns Locale string
   */
  const localeGet = (language: string): string => {
    switch (language) {
      case 'en':
        return 'en-US';
      default:
        return 'de-DE';
    }
  };

  return {
    filePreviewByAttachmentFileGet,
    filePreviewByFileGet,
    fullNameGet,
    localeGet,
    getBreadcrumbPath,
  };
};
