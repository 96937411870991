import { memo, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

// Assets
import Background from '../../../../assets/pictures/og_pengueen_high.jpg';

// Components
import { Dialog } from '../../../../shared/ui/Dialog/Dialog';
import { Imprint } from '../../../../shared/components/Imprint/Imprint';
import { Logo } from '../../../../shared/components/Logo/Logo';

// Models
import { Attachment, Theme } from '../../../../shared/models/shared.types';

// Stores
import {
  SharedState,
  useSharedStore,
} from '../../../../shared/stores/use-shared.store';

// Styles
import styles from './AuthPage.module.scss';

type AuthPageProps = {
  page: ReactNode;
  logo?: Attachment;
};

const AuthPage = (props: AuthPageProps) => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  // Component state
  const [dialogImprint, setDialogImprint] = useState<boolean>(false);

  // Shared store state
  const [theme] = useSharedStore((state: SharedState) => [state.theme]);

  return (
    <Box
      sx={{
        backgroundColor:
          theme === Theme.Light ? 'background.paper' : 'background.default',
      }}
      className={styles['auth-page']}
    >
      {props.logo ? (
        <div className={styles['auth-page-logo-community']}>
          <img alt="Community Logo" src={props.logo.url} />
          <Box sx={{ color: 'text.primary' }}>Powered by</Box>
          <Logo
            classes={styles['auth-page-logo-small']}
            imgClasses={styles['auth-page-logo-small']}
          />
        </div>
      ) : (
        <Logo classes={styles['auth-page-logo']} showBigLogo />
      )}
      <Box
        sx={{
          backgroundColor:
            theme === Theme.Light ? 'background.paper' : 'background.paper',
        }}
        className={styles['auth-page-content']}
      >
        {props.page}
      </Box>
      <Box
        sx={{
          color: 'text.primary',
        }}
        className={styles['auth-page-footer']}
      >
        <Box className="mr-1">{currentYear} © M.F.G. Pengueen UG</Box> |
        <Box
          onClick={() => setDialogImprint(true)}
          className="mx-1"
          sx={{
            color: 'primary.main',
            cursor: 'pointer',
          }}
        >
          {t('about.imprint.title')}
        </Box>
        <Box>|</Box>
        <Link to="/" className="mx-1">
          pengueen
        </Link>
        <Box className="mr-1">Version 4.0.1</Box>
        <Box>|</Box>
        <Box className="ml-1">app.pengueen.de</Box>
      </Box>
      <Box className={styles['auth-page-background']}>
        <img alt="Pengueen Office" src={Background} />
      </Box>
      <Dialog
        open={dialogImprint}
        title={t('about.imprint.title')}
        onClose={() => setDialogImprint(false)}
      >
        <Imprint />
      </Dialog>
    </Box>
  );
};

export default memo(AuthPage);
