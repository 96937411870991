import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

export const useUtils = () => {
  /**
   * GET font awesome icon prop by file type.
   * @param type File type
   * @returns Font awesome icon prop
   */
  const iconPropByFileTypeGet = (type: string): [IconPrefix, IconName] => {
    switch (type) {
      case 'application/x-7z-compressed':
      case 'application/gzip':
      case 'application/vnd.rar':
      case 'application/x-tar':
      case 'application/zip':
      case 'application/x-zip-compressed':
        return ['fas', 'file-archive'];
      case 'application/json':
      case 'application/ld+json':
      case 'application/x-httpd-php':
      case 'text/html':
      case 'text/javascript':
        return ['fas', 'file-code'];
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return ['fas', 'file-word'];
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return ['fas', 'file-excel'];
      case 'application/pdf':
        return ['fas', 'file-pdf'];
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return ['fas', 'file-powerpoint'];
      case 'audio/3gpp':
      case 'audio/3gpp2':
      case 'audio/aac':
      case 'audio/midi':
      case 'audio/mpeg':
      case 'audio/ogg':
      case 'audio/wav':
      case 'audio/webm':
      case 'audio/x-midi':
        return ['fas', 'file-audio'];
      case 'image/bmp':
      case 'image/gif':
      case 'image/jpeg':
      case 'image/png':
      case 'image/svg+xml':
      case 'image/tiff':
      case 'image/webp':
        return ['fas', 'file-image'];
      case 'text/csv':
        return ['fas', 'file-csv'];
      case 'text/plain':
        return ['fas', 'file-signature'];
      case 'video/3gpp':
      case 'video/3gpp2':
      case 'video/mp4':
      case 'video/mpeg':
      case 'video/ogg':
      case 'video/webm':
      case 'video/x-msvideo':
        return ['fas', 'file-video'];
      default:
        return ['fas', 'file'];
    }
  };

  /**
   * Update object property by string path and value.
   * @param object Object
   * @param path Path
   * @param value Value
   */
  const updateObjectProperty = (object: any, path: string, value: any) => {
    const [head, ...rest] = path.split('.');

    !rest.length
      ? (object[head] = value)
      : updateObjectProperty(object[head], rest.join('.'), value);
  };

  return {
    iconPropByFileTypeGet,
    updateObjectProperty,
  };
};
