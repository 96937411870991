import { ChangeEvent, ReactNode } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Box, Checkbox as MuiCheckbox, SxProps, Theme } from '@mui/material';
import { TFunctionResult } from 'i18next';
import clsx from 'clsx';

// Components
import { Icon } from '../Icon/Icon';
import { ResultMessage } from '../ResultMessage/ResultMessage';

// Models
import { ResultState, Theme as ETheme } from '../../models/shared.types';

// Styles
import styles from './Checkbox.module.scss';
import { SharedState, useSharedStore } from '../../stores/use-shared.store';

type CheckboxProps = {
  classes?: string;
  checked?: boolean;
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'default';
  backgroundColor?: string;
  content?: string | ReactNode;
  disabled?: boolean;
  logo?: string;
  message?: TFunctionResult | string;
  register?: UseFormRegisterReturn;
  state?: ResultState;
  sx?: SxProps<Theme>;
  onToggle?: (checked: boolean) => void;
};

export const Checkbox = (props: CheckboxProps) => {
  const [theme] = useSharedStore((state: SharedState) => [state.theme]);
  return (
    <Box
      className={clsx('checkbox', props.classes && props.classes)}
      sx={{
        '& .MuiCheckbox-root': {
          padding: '0.25rem',
        },
      }}
    >
      <div className={styles['checkbox-container']}>
        <MuiCheckbox
          checked={props.checked && props.checked}
          checkedIcon={<Icon icon={['fas', 'check-square']} size="small" />}
          color={props.color ?? 'primary'}
          disabled={props.disabled}
          icon={<Icon icon={['fal', 'square']} size="small" />}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            props.onToggle && props.onToggle(event.target.checked)
          }
          {...props.register}
        />
        {props.logo && (
          <img
            className={styles['checkbox-logo']}
            alt="community_logo"
            src={props.logo}
          />
        )}

        {props.content && (
          <Box
            sx={{
              color: props.disabled ? 'text.secondary' : 'text.primary',
              backgroundImage: `linear-gradient(to right, ${
                props.backgroundColor
              } 0.75rem, ${
                theme === ETheme.Dark ? '#202020' : 'white'
              } 0.75rem)`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100%',
            }}
            className={clsx(
              styles['checkbox-container-content'],
              props.backgroundColor && 'pl-4'
            )}
          >
            {props.content}
          </Box>
        )}
      </div>
      {props.message && (
        <ResultMessage
          message={props.message}
          state={props.state ?? ResultState.Error}
        />
      )}
    </Box>
  );
};
