// Hooks
import { useFetch } from '../../../shared/hooks/use-fetch.hook';

// Models
import { Template } from '../../templates/models/templates.types';
import { Tool } from '../../tools/models/tools.types';
import {
  MarketMenuItem,
  MarketTemplateCopyPostRequest,
  MarketTemplatesGetParams,
  MarketTemplateUsePostRequest,
} from '../models/market.types';

export const useMarketHttp = () => {
  const { fetchData } = useFetch();

  /**
   * GET Market menu items.
   * Component: MarketFilters
   * Example mock data: menuItemsGet()
   * @returns Market
   */
  const marketMenuGet = async (): Promise<MarketMenuItem[] | undefined> => {
    return await fetchData('market/menu');
  };

  /**
   * GET Market templates.
   * Component: MarketOverview
   * Example mock data: templatesGet()
   * @returns Market templates
   */
  const marketTemplatesGet = async (
    params?: MarketTemplatesGetParams
  ): Promise<Template[] | undefined> => {
    return await fetchData('market/templates', {
      method: 'GET',
      params,
    });
  };

  /**
   * GET Market templates public.
   * Component: MarketOverview
   * Example mock data: templatesGet()
   * @returns Market templates
   */
  const marketTemplatesPublicGet = async (
    params?: MarketTemplatesGetParams
  ): Promise<Template[] | undefined> => {
    return await fetchData('market/templates/public', {
      method: 'GET',
      params,
    });
  };

  /**
   * POST Market template use.
   * Component: MarketTemplateUse
   * @param data
   * @returns Tool
   */
  const marketTemplateUse = async (
    data: MarketTemplateUsePostRequest
  ): Promise<Tool | undefined> => {
    return await fetchData(`market/templates/${data.id}/use`, {
      method: 'POST',
      body: data.body,
    });
  };

  /**
   * POST Market template copy.
   * Component: MarketTemplateCopy
   * @param data
   * @returns Tool??
   */
  const marketTemplateCopy = async (
    data: MarketTemplateCopyPostRequest
  ): Promise<any | undefined> => {
    return await fetchData(`market/templates/${data.id}/copy`, {
      method: 'POST',
      body: data,
    });
  };

  return {
    marketMenuGet,
    marketTemplatesGet,
    marketTemplateUse,
    marketTemplateCopy,
    marketTemplatesPublicGet,
  };
};
