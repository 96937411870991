import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

// Components
import { AccordionCard } from '../../../../shared/ui/AccordionCard/AccordionCard';
import { Dialog } from '../../../../shared/ui/Dialog/Dialog';
import { H2 } from '../../../../shared/ui/H2/H2';
import { H3 } from '../../../../shared/ui/H3/H3';
import { Icon } from '../../../../shared/ui/Icon/Icon';
import { MarketTemplateUse } from '../MarketTemplateUse/MarketTemplateUse';
import { MIT } from '../../../../shared/components/MIT/MIT';
import { TextButton } from '../../../../shared/ui/TextButton/TextButton';

// Hooks
import { useTemplates } from '../../../templates/hooks/use-templates.hook';

// Models
import { TemplateElementPreview as ITemplateElementPreview } from '../../../templates/models/templates.types';

// Stores
import { useMarketStore } from '../../stores/use-market.store';
import { useSharedStore } from '../../../../shared/stores/use-shared.store';

// Styles
import styles from './MarketTemplatePreview.module.scss';
import { useMutation } from 'react-query';
import { MarketTemplateCopyPostRequest } from '../../models/market.types';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useMarketHttp } from '../../hooks/use-market-http.hook';
import { useMarket } from '../../hooks/use-market.hook';
import {
  ImageFallbackType,
  MetaTags,
  ResultState,
} from '../../../../shared/models/shared.types';
import { ImageFallback } from '../../../../shared/components/ImageFallback/ImageFallback';
import { useBreakpoints } from '../../../../shared/hooks/use-breakpoints.hook';
import { Link, useNavigate } from 'react-router-dom';

type TemplateElementPreviewProps = {
  element: ITemplateElementPreview;
};

export const TemplateElementPreview = (props: TemplateElementPreviewProps) => {
  const { templateElementIconByTypeGet } = useTemplates();

  return (
    <Box
      key={props.element.id}
      className={
        styles['market-template-preview-footer-data-contains-input-item']
      }
    >
      {props.element.type && (
        <Icon
          icon={templateElementIconByTypeGet(props.element.type)}
          secondaryOpacity="0.15"
        />
      )}
      <Box
        sx={{ color: 'text.secondary' }}
        className={
          styles['market-template-preview-footer-data-contains-input-item-name']
        }
      >
        {props.element.name}
      </Box>
    </Box>
  );
};

type MarketTemplatePreviewProps = {
  public?: boolean;
};

const MarketTemplatePreview = (props: MarketTemplatePreviewProps) => {
  const { lgUp } = useBreakpoints();
  const { handleError, handleRetry } = useFetch();
  const { marketTemplateCopy } = useMarketHttp();
  // const { templatePreviewTagsGet } = useMarketMock();
  const { templateUseCaseTranslationGet } = useTemplates();
  const { marketTemplateCopyEffect } = useMarket();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Component state
  const [dialogMIT, setDialogMIT] = useState<boolean>(false);
  const [dialogUse, setDialogUse] = useState<boolean>(false);
  // const [templatePreviewTags] = useState<string[]>(templatePreviewTagsGet());
  const [elementPreviews, setElementPreviews] = useState<
    ITemplateElementPreview[]
  >([]);
  const [metaTag, setMetaTag] = useState<MetaTags | undefined>(undefined);

  // Market store state
  const [selectedTemplate] = useMarketStore((state) => [
    state.selectedTemplate,
  ]);

  // Shared store state
  const [setNotification] = useSharedStore((state) => [state.setNotification]);

  // ####### //
  // EFFECTS //
  // ####### //

  // Set element previews by selected template
  useEffect(() => {
    if (selectedTemplate && selectedTemplate.sections) {
      const previews: ITemplateElementPreview[] = [];
      for (let section of selectedTemplate?.sections) {
        if (section.elements) {
          for (let element of section.elements) {
            previews.push({
              id: element.id,
              // #TODO: Required name
              name: element.name ?? '',
              type: element.type,
            });
          }
        }
      }
      setElementPreviews(previews);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (selectedTemplate) {
      setMetaTag({
        title: `${selectedTemplate.settings.name} mit Pengueen.`,
        description: `${selectedTemplate.settings.description}`,
        keywords: `${selectedTemplate.use_case} mit Pengueen`,
        imgsrc:
          selectedTemplate.image_preview?.url ??
          '%PUBLIC_URL%/pengueen_small.png',
        url: `https://p4.pengueen.de/public/tools/preview/${selectedTemplate.id}`,
      });
    }
  }, [selectedTemplate]);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handler to use template and navigate to workbench
   */
  const onTemplateUse = useCallback(() => {
    setDialogUse(false);
    // eslint-disable-next-line
  }, [selectedTemplate]);

  /**
   * Handler to paste url to clipboard.
   */
  const onUrlPaste = useCallback(() => {
    navigator.clipboard.writeText(
      `${window.location.href.substring(
        0,
        window.location.href.indexOf('/', window.location.href.indexOf('/') + 2)
      )}/public/market/${selectedTemplate?.id}`
    );
    setNotification({
      title: t('tools.preview.info.share.notification'),
    });
    // eslint-disable-next-line
  }, [selectedTemplate]);

  // ######### //
  // MUTATIONS //
  // ######### //

  // POST Template use mutation
  const templateCopyMutation = useMutation(
    (data: MarketTemplateCopyPostRequest) => marketTemplateCopy(data)
  );

  // Template use mutation
  useEffect(() => {
    if (templateCopyMutation.data) {
      try {
        marketTemplateCopyEffect(templateCopyMutation.data);
        setNotification({
          subtitle: t('market.preview.notification.subtitle'),
          title:
            templateCopyMutation.data.settings.name +
            t('market.preview.notification.title'),
          state: ResultState.Success,
        });
      } catch (error) {
        console.log('ERROR on copying template:', error);
      }
    }
    if (templateCopyMutation.error) {
      // const errRes = templateCopyMutation.error?.response;
      // if (errRes) {
      //   handleError(errRes.status);
      // }
    }
    // eslint-disable-next-line
  }, [templateCopyMutation.data, templateCopyMutation.error]);

  return (
    <Box className={styles['market-template-preview']}>
      <div className={styles['market-template-preview-header']}>
        <Box className={styles['market-template-preview-header-image']}>
          <a
            target="_blank"
            rel="noreferrer"
            href={`${window.location.href.substring(
              0,
              window.location.href.indexOf(
                '/',
                window.location.href.indexOf('/') + 2
              )
            )}/public/tools/preview/${selectedTemplate?.preview_id}`}
          >
            <Box
              sx={{
                borderColor: 'border.strong',
                '& img': {
                  borderColor: 'bg.card',
                },
              }}
              className={
                styles['market-template-preview-header-image-container']
              }
            >
              {selectedTemplate?.image_market?.url && (
                <img
                  src={selectedTemplate?.image_market.url}
                  alt={selectedTemplate?.settings.name}
                />
              )}

              {/* <Box
                sx={{ backgroundColor: 'primary.light', color: 'primary.main' }}
                className={
                  styles[
                    'market-template-preview-header-image-container-preview'
                  ]
                }
              >
                {t('market.preview.title')}
              </Box> */}
            </Box>
          </a>
        </Box>
        <Box className={styles['market-template-preview-header-content']}>
          <div
            className={styles['market-template-preview-header-content-info']}
          >
            <div
              className={
                styles['market-template-preview-header-content-info-use-case']
              }
            >
              <Box
                bgcolor="bg.card"
                className={
                  styles[
                    'market-template-preview-header-content-info-use-case-icon'
                  ]
                }
              >
                <Icon icon={['far', 'list']} sx={{ color: 'text.secondary' }} />
              </Box>
              <Box
                sx={{ color: 'text.secondary' }}
                className={
                  styles[
                    'market-template-preview-header-content-info-use-case-text'
                  ]
                }
              >
                {selectedTemplate?.use_case &&
                  templateUseCaseTranslationGet(selectedTemplate.use_case)}
              </Box>
            </div>
            <H2>{selectedTemplate?.settings.name}</H2>
            {/* <Box sx={{ color: 'text.secondary' }}>: Beginner</Box> */}
            <Box
              sx={{ color: 'text.secondary' }}
              className={
                styles[
                  'market-template-preview-header-content-info-description'
                ]
              }
            >
              {selectedTemplate && selectedTemplate.settings.description}
            </Box>
            {/* <Divider className={styles['tool-preview-info-divider']} flexItem /> */}
            <Box
              sx={{ borderColor: 'border.strong' }}
              className={
                styles['market-template-preview-header-content-info-share']
              }
            >
              <H3>{t('tools.preview.info.share.title')}</H3>
              <Box
                className={
                  styles[
                    'market-template-preview-header-content-info-share-text'
                  ]
                }
                sx={{ color: 'text.secondary' }}
              >
                {t('tools.preview.info.share.text')}
              </Box>
              <Button
                className={
                  styles[
                    'market-template-preview-header-content-info-share-button'
                  ]
                }
                sx={{ color: 'primary.main' }}
                onClick={onUrlPaste}
              >
                <Icon
                  classes={
                    styles[
                      'market-template-preview-header-content-info-share-button-icon'
                    ]
                  }
                  color="primary"
                  icon={['fas', 'link']}
                />
                <span>{t('tools.preview.info.share.button')}</span>
              </Button>
              {!lgUp && (
                <div
                  className={
                    styles[
                      'market-template-preview-header-content-info-buttons'
                    ]
                  }
                >
                  <div className="flex flex-row">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${window.location.href.substring(
                        0,
                        window.location.href.indexOf(
                          '/',
                          window.location.href.indexOf('/') + 2
                        )
                      )}/public/tools/preview/${selectedTemplate?.preview_id}`}
                    >
                      <TextButton
                        classes={
                          styles[
                            'market-template-preview-header-content-info-buttons-use'
                          ]
                        }
                        preset="secondary"
                      >
                        {t('market.preview.title')}
                      </TextButton>
                    </a>
                    <TextButton
                      classes={
                        styles[
                          'market-template-preview-header-content-info-buttons-use'
                        ]
                      }
                      preset="primary"
                      onClick={() => {
                        props.public
                          ? navigate('/registration')
                          : setDialogUse(true);
                      }}
                    >
                      {t('market.use.title')}
                    </TextButton>
                    {/* <TextButton
                      preset="primary"
                      onClick={() => {
                        selectedTemplate?.id &&
                          templateCopyMutation.mutate({
                            id: selectedTemplate?.id,
                          });
                      }}
                    >
                      {t('market.preview.copy')}
                    </TextButton> */}
                  </div>
                </div>
              )}
            </Box>
          </div>
          {lgUp && (
            <div
              className={
                styles['market-template-preview-header-content-info-buttons']
              }
            >
              <>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${window.location.href.substring(
                    0,
                    window.location.href.indexOf(
                      '/',
                      window.location.href.indexOf('/') + 2
                    )
                  )}/public/tools/preview/${selectedTemplate?.preview_id}`}
                >
                  <TextButton
                    classes={
                      styles[
                        'market-template-preview-header-content-info-buttons-use'
                      ]
                    }
                    preset="secondary"
                  >
                    {t('market.preview.title')}
                  </TextButton>
                </a>
                <TextButton
                  classes={
                    styles[
                      'market-template-preview-header-content-info-buttons-use'
                    ]
                  }
                  preset="primary"
                  onClick={() => {
                    props.public
                      ? navigate('/registration')
                      : setDialogUse(true);
                  }}
                >
                  {t('market.use.title')}
                </TextButton>
                {/* <TextButton
                    preset="primary"
                    onClick={() => {
                      selectedTemplate?.id &&
                        templateCopyMutation.mutate({
                          id: selectedTemplate?.id,
                        });
                    }}
                  >
                    {t('market.preview.copy')}
                  </TextButton> */}
              </>
            </div>
          )}
        </Box>
      </div>
      <Box
        className={styles['market-template-preview-license']}
        sx={{
          borderColor: 'border.app',
          '& #license-link': {
            color: 'primary.main',
          },
          '& #license-text': {
            color: 'text.secondary',
          },
        }}
      >
        <span id="license-text" style={{ display: 'inline' }}>
          {t('market.preview.mit.text')}
        </span>
        <span
          className={styles['market-template-preview-license-link']}
          id="license-link"
          style={{ display: 'inline' }}
          onClick={() => setDialogMIT(true)}
        >
          {t('market.preview.mit.title')}
        </span>
        <span id="license-text" style={{ display: 'inline' }}>
          .
        </span>
      </Box>
      <Box className={styles['market-template-preview-footer']}>
        <Box
          sx={{ borderColor: 'border.app' }}
          className={styles['market-template-preview-footer-data']}
        >
          <Box
            className={styles['market-template-preview-footer-data-contains']}
          >
            <H2>{t('market.preview.contains.title')}</H2>
            <AccordionCard
              classes={
                styles['market-template-preview-footer-data-contains-accordion']
              }
              count={elementPreviews.length}
              title={t('market.preview.contains.input_fields')}
            >
              <Box
                className={
                  styles[
                    'market-template-preview-footer-data-contains-accordion-details'
                  ]
                }
              >
                {elementPreviews.map((element) => (
                  <TemplateElementPreview key={element.id} element={element} />
                ))}
              </Box>
            </AccordionCard>
            <AccordionCard
              classes={
                styles['market-template-preview-footer-data-contains-accordion']
              }
              count={2}
              title={t('app.settings')}
            >
              <Box
                className={
                  styles[
                    'market-template-preview-footer-data-contains-accordion-details'
                  ]
                }
              >
                <Icon
                  classes={
                    styles[
                      'market-template-preview-footer-data-contains-settings-item'
                    ]
                  }
                  icon={['fal', 'repeat-1']}
                />
                <Icon
                  classes={
                    styles[
                      'market-template-preview-footer-data-contains-settings-item'
                    ]
                  }
                  icon={['fal', 'user-lock']}
                />
              </Box>
            </AccordionCard>
          </Box>
          <Box sx={{ color: 'text.secondary' }}>
            {t('market.preview.contains.used1')}361
            {t('market.preview.contains.used2')}
          </Box>
        </Box>
        <Box className={styles['market-template-preview-footer-data']}>
          <H2>{t('market.preview.created_by.title')}</H2>
          <H3
            classes={styles['market-template-preview-footer-data-meta-title']}
          >
            <Box>{selectedTemplate?.community_id?.name}</Box>
            {selectedTemplate?.community_id && (
              <>
                {selectedTemplate?.community_id.logo?.url ? (
                  <img
                    alt={selectedTemplate?.community_id.name}
                    src={selectedTemplate?.community_id.logo.url}
                  />
                ) : (
                  <ImageFallback
                    iconSize={'1.5em'}
                    sizeClassName={styles['section-image-data-icon']}
                    type={ImageFallbackType.Community}
                  />
                )}
              </>
            )}
          </H3>
          <Box
            className={styles['market-template-preview-footer-data-meta-text']}
          >
            {selectedTemplate?.community_description}
          </Box>
          <Box className="font-bold" sx={{ color: 'primary.main' }}>
            {t('market.use.more')}
          </Box>
          {/* <H2>Tags</H2>
          <Box
            className={styles['market-template-preview-footer-data-meta-tags']}
          >
            {templatePreviewTags.map((tag, index: number) => {
              return (
                <Box
                  key={index}
                  sx={{ backgroundColor: 'bg.card', color: 'text.secondary' }}
                  className={
                    styles['market-template-preview-footer-data-meta-tags-item']
                  }
                >
                  {tag}
                </Box>
              );
            })}
          </Box> */}
        </Box>
      </Box>
      {selectedTemplate && (
        <Dialog
          open={dialogUse}
          subtitle={t('market.use.dialog.subtitle')}
          title={t('market.use.dialog.title')}
          onClose={() => setDialogUse(false)}
        >
          <MarketTemplateUse
            id={selectedTemplate?.id}
            name={selectedTemplate?.settings?.name ?? ''}
            onSubmit={() => onTemplateUse()}
          />
        </Dialog>
      )}
      {dialogMIT && (
        <Dialog
          open={dialogMIT}
          title={t('about.mit.title')}
          onClose={() => setDialogMIT(false)}
        >
          <MIT />
        </Dialog>
      )}
    </Box>
  );
};

export default memo(MarketTemplatePreview);
