// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageFallback_image-fallback__z-9kr {\n  display: flex;\n  align-items: center;\n  justify-content: center }\n  .ImageFallback_image-fallback-rounded__Vt6r0 {\n  border-radius: 0.375rem }\n  .ImageFallback_image-fallback-size__0Popt {\n  height: 2rem;\n  width: 2rem }\n\n@media (min-width: 1024px) {\n  .ImageFallback_image-fallback-size__0Popt {\n    height: 2.5rem }\n  .ImageFallback_image-fallback-size__0Popt {\n    width: 2.5rem } }\n", "",{"version":3,"sources":["<no source>","webpack://./src/shared/components/ImageFallback/ImageFallback.module.scss"],"names":[],"mappings":"AAAA;EAAA,cAAA;EAAA,oBAAA;EAAA,yBAAA;EAAA;EAAA,yBAAA;EAAA;EAAA,aAAA;EAAA,aAAA;;ACYA;EDZA;IAAA,gBAAA;EAAA;IAAA,eAAA,CCgBK","sourcesContent":[null,"@import '../../../styles/breakpoints.scss';\n\n.image-fallback {\n  @apply flex items-center justify-center;\n  &-rounded {\n    @apply rounded-md;\n  }\n  &-size {\n    @apply h-8 w-8;\n  }\n}\n\n@media (min-width: $lg) {\n  .image-fallback {\n    &-size {\n      @apply h-10 w-10;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image-fallback": "ImageFallback_image-fallback__z-9kr",
	"image-fallback-rounded": "ImageFallback_image-fallback-rounded__Vt6r0",
	"image-fallback-size": "ImageFallback_image-fallback-size__0Popt"
};
export default ___CSS_LOADER_EXPORT___;
