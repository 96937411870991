import create, { State } from 'zustand';

// Models
import { Message, SocketFile } from '../models/message.types';
import { GroupRoomSettings, Room } from '../models/news-center.types';
import {
  initialNewsCenterFilterData,
  NewsCenterFiltersData,
} from '../models/news-center-filters.interface';
import { EditorState } from 'draft-js';

export interface NewsCenterState extends State {
  anchorEl: Element | null | undefined;
  tempMessage: Message | undefined;
  dialogDirectRoomCreate: boolean;
  dialogGroupRoomCreate: boolean;
  dialogGroupRoomCreateEdit: boolean;
  directRooms: Room[];
  editorState: EditorState;
  favoriteRooms: Room[];
  filtersData: NewsCenterFiltersData | undefined;
  godparentsChat: Room | undefined;
  groupsMappedNone: boolean;
  // groupRoomCreateEditCrudState: CrudState | undefined;
  groupRoomCreateEditSettings: GroupRoomSettings | undefined;
  // groupRoomCreateEditState: GroupCreateEditState;
  groupRooms: Room[];
  latestUnreadRooms: Room[];
  latestReadRooms: Room[];
  selectedFiles: SocketFile[];
  selectedRoom: Room | undefined;
  selectedRoomMessages: Message[];
  // resetGroupRoomCreateData: () => void;
  resetStoreData: () => void;
  setAnchorEl: (anchorEl: Element | null | undefined) => void;
  setTempMessage: (tempMessage: Message | undefined) => void;
  setEditorState: (editorState: EditorState) => void;
  setDialogDirectRoomCreate: (dialogDirectRoomCreate: boolean) => void;
  setDialogGroupRoomCreate: (dialogGroupRoomCreate: boolean) => void;
  setDialogGroupRoomCreateEdit: (dialogGroupRoomCreateEdit: boolean) => void;
  setDirectRooms: (directRooms: Room[]) => void;
  setFavoriteRooms: (favoriteRooms: Room[]) => void;
  setFiltersData: (
    newsCenterFilterData: NewsCenterFiltersData | undefined
  ) => void;
  setGodparentsChat: (godparentsChat: Room | undefined) => void;
  setGroupsMappedNone: (groupsMappedNone: boolean) => void;
  // setGroupRoomCreateEditCrudState: (
  //   groupRoomCreateEditCrudState: CrudState | undefined
  // ) => void;
  setGroupRoomCreateEditSettings: (
    groupRoomCreateEditSettings: GroupRoomSettings | undefined
  ) => void;
  // setGroupRoomCreateEditState: (
  //   groupCreateEditState: GroupCreateEditState
  // ) => void;
  setGroupRooms: (groupRooms: Room[]) => void;
  setLatestUnreadRooms: (latestUnreadRooms: Room[]) => void;
  setLatestReadRooms: (latestReadRooms: Room[]) => void;
  setSelectedFiles: (selectedFiles: SocketFile[]) => void;
  setSelectedRoom: (selectedGroupRoom: Room | undefined) => void;
  setSelectedRoomMessages: (selectedRoomMessages: Message[]) => void;
}

export const initialGroupRoomCreateData = {
  // groupRoomCreateEditCrudState: undefined,
  groupRoomCreateEditSettings: undefined,
  // groupRoomCreateEditState: GroupCreateEditState.Common,
};

export const initialStoreData = {
  directRooms: [],
  filtersData: initialNewsCenterFilterData(),
  groupRooms: [],
  selectedRoom: undefined,
};

export const useNewsCenterStore = create<NewsCenterState>((set) => ({
  anchorEl: null,
  tempMessage: undefined,
  ...initialGroupRoomCreateData,
  ...initialStoreData,
  dialogDirectRoomCreate: false,
  dialogGroupRoomCreate: false,
  dialogGroupRoomCreateEdit: false,
  editorState: EditorState.moveFocusToEnd(EditorState.createEmpty()),
  favoriteRooms: [],
  godparentsChat: undefined,
  groupsMappedNone: false,
  latestUnreadRooms: [],
  latestReadRooms: [],
  selectedFiles: [],

  selectedRoomMessages: [],
  // resetGroupRoomCreateData: () =>
  //   set((state: NewsCenterState) => ({
  //     ...state,
  //     ...initialGroupRoomCreateData,
  //   })),
  resetStoreData: () =>
    set((state: NewsCenterState) => ({
      ...state,
      ...initialStoreData,
    })),
  setAnchorEl: (anchorEl: Element | null | undefined) => set({ anchorEl }),
  setTempMessage: (tempMessage: Message | undefined) => set({ tempMessage }),
  setDialogDirectRoomCreate: (dialogDirectRoomCreate: boolean) =>
    set({ dialogDirectRoomCreate }),
  setDialogGroupRoomCreate: (dialogGroupRoomCreate: boolean) =>
    set({ dialogGroupRoomCreate }),
  setDialogGroupRoomCreateEdit: (dialogGroupRoomCreateEdit: boolean) =>
    set({ dialogGroupRoomCreateEdit }),
  setDirectRooms: (directRooms: Room[]) => set({ directRooms }),
  setEditorState: (editorState: EditorState) => set({ editorState }),
  setFavoriteRooms: (favoriteRooms: Room[]) => set({ favoriteRooms }),
  setFiltersData: (filtersData: NewsCenterFiltersData | undefined) =>
    set({ filtersData }),
  setGodparentsChat: (godparentsChat: Room | undefined) =>
    set({ godparentsChat }),
  setGroupsMappedNone: (groupsMappedNone: boolean) => set({ groupsMappedNone }),
  // setGroupRoomCreateEditCrudState: (
  //   groupRoomCreateEditCrudState: CrudState | undefined
  // ) => set({ groupRoomCreateEditCrudState }),
  setGroupRoomCreateEditSettings: (
    groupRoomCreateEditSettings: GroupRoomSettings | undefined
  ) => set({ groupRoomCreateEditSettings }),
  // setGroupRoomCreateEditState: (
  //   groupRoomCreateEditState: GroupCreateEditState
  // ) => set({ groupRoomCreateEditState }),
  setGroupRooms: (groupRooms: Room[]) => set({ groupRooms }),
  setLatestUnreadRooms: (latestUnreadRooms: Room[]) =>
    set({ latestUnreadRooms }),
  setLatestReadRooms: (latestReadRooms: Room[]) => set({ latestReadRooms }),
  setSelectedFiles: (selectedFiles: SocketFile[]) => set({ selectedFiles }),
  setSelectedRoom: (selectedRoom: Room | undefined) => set({ selectedRoom }),
  setSelectedRoomMessages: (selectedRoomMessages: Message[]) =>
    set({ selectedRoomMessages }),
}));
