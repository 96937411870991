import { aboutTranslationsEN } from './en/about.translations.en';
import { appTranslationsEN } from './en/app.translations.en';
import { authTranslationsEN } from './en/auth.translations.en';
import { communityTranslationsEN } from './en/community.translations.en';
import { ContactsTranslationsEN } from './en/contacts.translations.en';
import { dashboardTranslationsEN } from './en/dashboard.translations.en';
import { formTranslationsEN } from './en/form.translations.en';
import { godparentschatTranslationsEN } from './en/godparentschat.translations.en';
import { headerTranslationsEN } from './en/header.translations.en';
import { marketTranslationsEN } from './en/market.translations.en';
import { newscenterTranslationsEN } from './en/newscenter.translations.en';
import { templatesTranslationsEN } from './en/templates.translations.en';
import { toolsTranslationsEN } from './en/tools.translations.en';
import { userTranslationsEN } from './en/user.translations.en';

export const translationsEn = {
  about: aboutTranslationsEN,
  app: appTranslationsEN,
  auth: authTranslationsEN,
  community: communityTranslationsEN,
  contacts: ContactsTranslationsEN,
  dashboard: dashboardTranslationsEN,
  form: formTranslationsEN,
  godparentschat: godparentschatTranslationsEN,
  header: headerTranslationsEN,
  market: marketTranslationsEN,
  newscenter: newscenterTranslationsEN,
  templates: templatesTranslationsEN,
  tools: toolsTranslationsEN,
  user: userTranslationsEN,
};
