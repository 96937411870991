// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Menu_menu-button__x7GMY {\n    font-weight: 600\n}\n  .Menu_menu-button-icon__O\\+4XD {\n    margin-left: 0.25rem;\n    padding-top: 0.5rem\n}\n\n.Menu_menu-item-icon__48poH {\n    margin-right: 0.25rem;\n    font-size: 0.875rem;\n    line-height: 1.25rem\n}\n\n.Menu_menu-item-root__CpHtP {\n    margin-top: 0.25rem;\n    margin-bottom: 0.25rem;\n    min-height: 0px;\n    border-radius: 0.375rem;\n    padding: 0.25rem;\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;\n    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;\n    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;\n    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n    transition-duration: 150ms\n}\n\n.Menu_menu-list-root__L6pMX {\n    padding: 0.25rem\n}\n\n.Menu_menu-paper-root__ZoD1m {\n    margin-top: 0.5rem;\n    justify-items: center;\n    border-radius: 0.375rem;\n    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);\n    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);\n    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);\n    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;IAAA;CAAA;EAAA;IAAA,qBAAA;IAAA;CAAA;;AAAA;IAAA,sBAAA;IAAA,oBAAA;IAAA;CAAA;;AAAA;IAAA,oBAAA;IAAA,uBAAA;IAAA,gBAAA;IAAA,wBAAA;IAAA,iBAAA;IAAA,oBAAA;IAAA,qBAAA;IAAA,wGAAA;IAAA,gGAAA;IAAA,+HAAA;IAAA,yDAAA;IAAA;CAAA;;AAAA;IAAA;CAAA;;AAAA;IAAA,mBAAA;IAAA,sBAAA;IAAA,wBAAA;IAAA,8EAAA;IAAA,kGAAA;IAAA,mDAAA;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu-button": "Menu_menu-button__x7GMY",
	"menu-button-icon": "Menu_menu-button-icon__O+4XD",
	"menu-item-icon": "Menu_menu-item-icon__48poH",
	"menu-item-root": "Menu_menu-item-root__CpHtP",
	"menu-list-root": "Menu_menu-list-root__L6pMX",
	"menu-paper-root": "Menu_menu-paper-root__ZoD1m"
};
export default ___CSS_LOADER_EXPORT___;
