import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useMutation } from 'react-query';

// Components
import { ImageFallback } from '../../../../shared/components/ImageFallback/ImageFallback';
import { UserLink } from '../UserLink/UserLink';

// Hooks
import { useUsersHttp } from '../../hooks/use-users-http.hook';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';

// Models
import { ImageFallbackType } from '../../../../shared/models/shared.types';
import { UserInfoState } from '../../models/user.types';

// Stores
import { useUserStore } from '../../stores/use-user.store';

// Styles
import styles from './UserInfo.module.scss';

export const UserInfo = () => {
  const { handleError, handleRetry } = useFetch();
  const { t } = useTranslation();

  //TODO fetch getCommunities ProfileNav
  const { userProfileNavCommunities, userCommunitiesGet } = useUsersHttp();
  //const [communityError, setCommunityError] = useState<string | null>(null);

  // User store state
  const [
    profile,
    settingsActive,
    // communities,
    setCommunities,
    navigationCommunities,
    setNavigationCommunities,
  ] = useUserStore((state) => [
    state.profile,
    state.settingsActive,
    // state.communities,
    state.setCommunities,
    state.navigationCommunities,
    state.setNavigationCommunities,
  ]);

  // ######### //
  // MUTATIONS //
  // ######### //
  // GET Favorite tools mutation
  const navigationCommunitiesGetMutation = useMutation(() =>
    userProfileNavCommunities()
  );

  // Handle GET favorite tools
  useEffect(() => {
    if (navigationCommunitiesGetMutation.data) {
      setNavigationCommunities(navigationCommunitiesGetMutation.data);
    }
    if (navigationCommunitiesGetMutation.error) {
      // const errRes = navigationCommunitiesGetMutation.error?.response;
      // // Check if error contains fetch response object
      // if (errRes) {
      //   // Set view error by response status
      //   // setViewError(handleError(errRes.status));
      //   console.error(
      //     'Error getting navigation communities:',
      //     handleError(errRes.status)
      //   );
      // }
    }
    // eslint-disable-next-line
  }, [
    navigationCommunitiesGetMutation.data,
    navigationCommunitiesGetMutation.error,
  ]);

  // GET communities mutation
  const communitiesGetMutation = useMutation(() => userCommunitiesGet(), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
  });

  // Handle GET communities
  useEffect(() => {
    if (communitiesGetMutation.data) {
      setCommunities(communitiesGetMutation.data);
      //setCommunityError(null);
    }
    if (communitiesGetMutation.error) {
      const errRes = communitiesGetMutation.error?.response;
      if (errRes) {
        //setCommunityError(handleError(errRes.status));
      }
    }

    // eslint-disable-next-line
  }, [communitiesGetMutation.data, communitiesGetMutation.error]);

  // ####### //
  // EFFECTS //
  // ####### //

  // Get favorite tools on component mount
  useEffect(() => {
    navigationCommunitiesGetMutation.mutate();
    //if (!communities) {
    communitiesGetMutation.mutate();
    //}
    // eslint-disable-next-line
  }, [profile]);

  return (
    <>
      <section className={styles['user-info-title']}>{t('user.title')}</section>
      <Box
        sx={{ borderColor: 'border.app' }}
        className={styles['user-info-avatar']}
      >
        {profile?.avatar?.url ? (
          <img
            alt="User Avatar"
            className={styles['user-info-avatar-icon']}
            src={profile.avatar.url}
          />
        ) : (
          <ImageFallback
            iconSize="3em"
            sizeClassName={styles['user-info-avatar-icon']}
            type={ImageFallbackType.Profile}
          />
        )}
        <div className={styles['user-info-avatar-name']}>
          {profile?.personal_data
            ? `${profile.personal_data.first_name ?? ''} ${
                profile.personal_data.last_name ?? ''
              }`
            : null}
        </div>
      </Box>
      <section className={styles['user-info-nav']}>
        <UserLink
          active={settingsActive === UserInfoState.Profile}
          icon={['far', 'user']}
          link="/user/profile"
          state={UserInfoState.Profile}
          subtitle={t('user.profile.subtitle')}
          title={t('user.profile.title')}
        />
        <UserLink
          active={settingsActive === UserInfoState.Security}
          icon={['fas', 'shield-alt']}
          link="/user/security"
          state={UserInfoState.Security}
          subtitle={t('user.security.subtitle')}
          title={t('user.security.title')}
        />
        <UserLink
          active={settingsActive === UserInfoState.Account}
          icon={['fas', 'layer-group']}
          link="/user/account"
          state={UserInfoState.Account}
          subtitle={t('user.account.subtitle')}
          title={t('user.account.title')}
        />
        <UserLink
          active={settingsActive === UserInfoState.Community}
          chldrn={navigationCommunities?.myCommunities}
          subChldrn={navigationCommunities?.adminCommunities}
          icon={['fas', 'building']}
          link="/user/community"
          state={UserInfoState.Community}
          subtitle={t('user.community.subtitle')}
          title={t('user.community.title')}
        />
        <UserLink
          active={settingsActive === UserInfoState.Documents}
          icon={['fas', 'folder-open']}
          link="/user/documents"
          state={UserInfoState.Documents}
          subtitle={t('user.documents.subtitle')}
          title={t('user.documents.title')}
        />
      </section>
    </>
  );
};
