import { NewsCenterTranslations } from '../models/newscenter.translations.interface';

export const newscenterTranslationsIT: NewsCenterTranslations = {
  network: {
    title: 'La tua rete',
  },
  godparents: {
    title: 'domande a Pengueen?',
    subtitle1: 'Ti ha invitato a Pengueen',
    subtitle2: 'E puo aiutarti certamente',
  },
  no_chat: {
    private:
      'non hai scritto o ricevuto un messaggio ancora? usa il bottone blu "il piu" e scrivi subito',
    group:
      'Non hai un gruppo ancora? metti un gruppo subito. usa per questo il bottone blu "il piu"',
  },
  file: {
    size: 'purtroppo il tuo file è troppo di 7 MByte. Abbiamo creato un strumento per te, come puoi ridurre la grandezza dei dati (nel senso d`ambiente)',
    link: 'al articolo',
    type: 'purtroppo non viene sostenuto questo formato di file al momento',
  },
  info: {
    join: {
      title: 'è entrato il chat room',
    },
  },
  remove: {
    text1: ' dal gruppo ',
    text2: ' rimosso ',
  },
  invite: {
    text1: ' ha',
    text2: ' Invitato.',
  },
  leave: {
    direct: ' è abbandonato il chat room. ',
  },
  close: {
    group: ' ha chiuso questo gruppo ',
  },
  favorite: {
    remove: 'rimuovere dai favoriti',
    mark: 'Marchiare a favorito',
  },
  latest: {
    read: 'letto favoriti',
    unread: 'non letto favoriti',
    title: 'attuale',
    show: {
      no: 'visualizzare meno',
      group: 'visualizzare gruppi di messaggi piu vecchi',
      direct: 'visualizzare messaggi privati piu vecchi',
    },
  },
  teaser: {
    new_direct: 'Messaggio diretto nuovo',
    new_group: 'gruppo nuovo',
    image: 'Messaggio image',
  },
  actions: {
    broadcast: 'Broadcast messaggio',
    group: 'gruppo nuovo',
    direct: 'Messaggio diretto nuovo',
    title: 'Messaggio nuovo',
  },
  filters: {
    current: {
      title: 'attuale',
    },
    group: {
      old_view: 'visualizzare gruppi di messagi piu vecchi',
      communities: 'comunità',
      none: 'No raggruppamenti',
    },
    show: {
      label: 'Mostra gruppi e messaggi di',
      no_communities:
        'al momento non c`è un chat di gruppo connesso con una comunità',
    },
  },
  groups: {
    create_edit: {
      settings: {
        buttons: {
          create: 'avanti ai partecipanti',
          edit: 'Editare gli impostazioni delli gruppi',
        },
        community: {
          error: 'devi scegliere una comunità',
          label: 'a qualle comunità si deve assegnare questo gruppo?',
        },
        description: {
          error: 'una descrizione del gruppe è richiesto',
          label: 'descrivi il scoppo del gruppo corto',
          placeholder: 'questo gruppo puo utilizzato per',
        },
        name: {
          error: 'un nome dal gruppo `richiesto',
          label: 'come deve chiamato il tuo gruppo?',
          placeholder: 'p.e. protocollo',
        },
        message: 'Attivare per membri la funzione informazione',
        no_include_creator: 'Do not add me to this group',
        folder: 'Si deve rilasciare il gruppo ad una cartella?',
        title: {
          create: 'crea il tuo gruppo',
          edit: 'Edita il tuo gruppo',
        },
      },
      users: {
        buttons: {
          back: 'Indietro agli impostazioni',
          create: 'crea un gruppo',
          edit: 'Edita gruppo',
        },
        title: 'aggiungi partecipanti',
      },
    },
    old: 'Indicare gruppi di messaggivecchi',
    no_community: 'assegnato a nessuno gruppo',
    subtitle: 'chat di gruppo',
    title: 'gruppi',
  },
  placeholder: {
    text1: 'scegli un chat di gruppo p.f. o un messaggio diretto',
    text2:
      'puoi imostare un chat di gruppo nuovo o scrivi un messaggio diretto, per questo clicca sinistra a "messaggio nuovo"',
  },
  room: {
    direct: {
      delete: {
        subtitle: 'Il tuo chat viene cancellato e si non puo ricrearlo.',
        text: 'cancellare il chat?',
        title: 'cancella chat',
      },
      message: {
        placeholder: 'scrivi un messaggio',
      },
      subtitle: 'chat con',
      title: 'Messaggi privati',
      unread_msgs: {
        multi: 'Messaggi nuovi',
        single: 'Messaggio nuovo',
      },
      read_msgs: {
        title:
          'non puoi editare questo messaggio piu, siccome lo veniva letto gia',
      },
      add: {
        title: 'Messaggio diretto nuovo',
      },
    },
    groups: {
      delete: {
        editor: 'non puoi scrivere messaggi nel questo gruppo piu',
        subtitle:
          'I gruppi e tutti i messaggi vengonno cancellato e si non puo ricrearli',
        text: 'cancellare gruppo ora?',
        title: 'cancella gruppo',
        notification: 'Il chat veniva cancellato con successo',
      },
      leave: {
        warning:
          'sei sicuro? quando chiudi questo gruppi, tutti i tui messaggi vengonno cancellati irrevocabili ed i partecipanti non possono scambiarsi piu!',
        text: 'abbandona gruppo',
        title: 'hai abbandonato il chat con successo',
        question: 'abbandonare il gruppo ora',
        matches: 'abbandonare',
      },
      close: {
        text: 'chiudere il gruppo',
        title: 'hai chiuso il chat con successo',
        question: 'chiudere il gruppo ora',
        matches: 'chiudere',
      },
      info: 'benvenuto/a al gruppo',
      link: {
        title: 'link comunità',
      },
      tools: {
        part: {
          btn: 'disconnettere il collegamento',
          match: 'disconnettere',
          subtitle: 'poi il gruppo non ha accesso piu agli strumenti',
          text: 'disconnettere il collegamento ora?',
          title1: 'vuoi il collegamento',
          title2: 'disconnettere?',
        },
        title: 'strumenti legati',
      },
      users: {
        add: {
          title: 'aggiungi utenti',
        },
        remove: {
          subtitle: 'viene rimosso dal gruppo e perde i sui diretti di accessi',
        },
        title: 'utenti',
      },
    },
    message: {
      delete: {
        subtitle: 'Il messaggio viene cancellato e si non puo ricrearlo.',
        text: 'cancellare il messaggio ora?',
        title: 'vuoi cancellare questo messaggio veramente?',
      },
      edited: 'Editato',
      tooltip: 'Modificare non è possibile, messaggio veniva letto gia.',
    },
    notifications: {
      title: 'one-time notificazione',
      tooltip:
        'Inviato un email o SMS a un messaggio nuovo. Il contenuto non viene indicato nel questo messaggio.',
    },
    quote: {
      at: 'a',
      title: 'quota',
    },
    typing: {
      double: 'stanno digitando',
      multi: 'utenti stanno digitando',
      single: 'sta digitando',
    },
  },
  title: 'centro notizie',
  write: 'scrivere messaggi',
};
