// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccordionCard_accordion-card__8dM8l {\n    margin: 0px;\n    border-radius: 0.375rem;\n    border-width: 1px;\n    border-style: solid }\n  .AccordionCard_accordion-card__8dM8l::before {\n    display: none }\n  .AccordionCard_accordion-card-details-padding__lm9cy {\n    padding: 0.5rem;\n    padding-top: 1.5rem !important }\n  .AccordionCard_accordion-card-summary__YEHAb {\n    width: 100% }\n    .AccordionCard_accordion-card-summary-count__sJ0sA {\n    margin-right: 0.5rem }\n    .AccordionCard_accordion-card-summary-svg__Ba6d- {\n    padding: 0.25rem }\n\n@media (min-width: 1024px) {\n  .AccordionCard_accordion-card-details-padding__lm9cy {\n        padding: 0.5rem } }\n", "",{"version":3,"sources":["<no source>","webpack://./src/shared/ui/AccordionCard/AccordionCard.module.scss"],"names":[],"mappings":"AAAA;IAAA,YAAA;IAAA,wBAAA;IAAA,kBAAA;IAAA,qBAAA;EAAA;IAAA,eAAA;EAAA;IAAA,gBAAA;ICUM,gCDVN;EAAA;IAAA,aAAA;IAAA;IAAA,sBAAA;IAAA;IAAA,kBAAA;;ACwBA;EDxBA;QAAA,iBAAA,CC6BO","sourcesContent":[null,"@import '../../../styles/breakpoints.scss';\n\n.accordion-card {\n  @apply border border-solid m-0 rounded-md;\n  &::before {\n    @apply hidden;\n  }\n  &-details {\n    &-padding {\n      @apply p-2;\n      padding-top: 1.5rem !important;\n    }\n  }\n  &-summary {\n    @apply w-full;\n    &-count {\n      @apply mr-2;\n    }\n    &-svg {\n      @apply p-1;\n    }\n  }\n}\n\n@media (min-width: $lg) {\n  .accordion-card {\n    &-details {\n      &-padding {\n        @apply p-2;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion-card": "AccordionCard_accordion-card__8dM8l",
	"accordion-card-details-padding": "AccordionCard_accordion-card-details-padding__lm9cy",
	"accordion-card-summary": "AccordionCard_accordion-card-summary__YEHAb",
	"accordion-card-summary-count": "AccordionCard_accordion-card-summary-count__sJ0sA",
	"accordion-card-summary-svg": "AccordionCard_accordion-card-summary-svg__Ba6d-"
};
export default ___CSS_LOADER_EXPORT___;
