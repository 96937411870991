import { useEffect, useState } from 'react';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { Box } from '@mui/material';
import clsx from 'clsx';

// Components
import { Icon } from '../../ui/Icon/Icon';

// Models
import { ImageFallbackType } from '../../models/shared.types';

// Styles
import styles from './ImageFallback.module.scss';

type ImageFallbackProps = {
  classes?: string;
  iconSize?: string;
  roundedClassName?: string;
  sizeClassName?: string;
  type?: ImageFallbackType;
};

export const ImageFallback = (props: ImageFallbackProps) => {
  // Component state
  const [icon, setIcon] = useState<[IconPrefix, IconName]>(['fal', 'user']);

  // Set icon by type
  useEffect(() => {
    switch (props.type) {
      case ImageFallbackType.Community:
        setIcon(['fal', 'building']);
        break;
      case ImageFallbackType.Group:
        setIcon(['fal', 'hashtag']);
        break;
      case ImageFallbackType.Tool:
        setIcon(['fal', 'screwdriver-wrench']);
        break;
      case ImageFallbackType.ToolUpload:
        setIcon(['far', 'image']);
        break;
      case ImageFallbackType.Network:
        setIcon(['fal', 'users']);
        break;

      // for Tool User Type
      case ImageFallbackType.Admin:
        setIcon(['fal', 'user-group-crown']);
        break;
      case ImageFallbackType.Employee:
        setIcon(['fal', 'user-group']);
        break;
      case ImageFallbackType.Member:
        setIcon(['fal', 'users']);
        break;
      case ImageFallbackType.GroupWithMessage:
        setIcon(['fal', 'hashtag']);
        break;
      case ImageFallbackType.Folder:
      case ImageFallbackType.GroupWithoutMessage:
        setIcon(['fal', 'users-rectangle']);
        break;
      default:
        setIcon(['fal', 'user']);
    }
  }, [props.type]);

  return (
    <Box
      className={clsx(
        styles['image-fallback'],
        props.classes && props.classes,
        props.roundedClassName ?? styles['image-fallback-rounded'],
        props.sizeClassName ?? styles['image-fallback-size']
      )}
      sx={{ backgroundColor: 'bg.card' }}
    >
      <Icon
        icon={icon}
        sx={{
          color: 'text.secondary',
          height: props.iconSize && props.iconSize,
          width: props.iconSize && props.iconSize,
        }}
      />
    </Box>
  );
};
