import { useTranslation } from 'react-i18next';
import { stringify } from 'query-string';

// Hooks
import { useLogout } from '../../modules/public/hooks/use-logout.hook';

// Models
import {
  FetchDataOptions,
  UploadDataOptions,
} from '../models/fetch-data.types';

// Store
import {
  AuthState,
  useAuthStore,
} from '../../modules/public/stores/use-auth.store';

class FetchError extends Error {
  constructor(public response: Response, message?: string) {
    super(message);
  }
}

export const useFetch = () => {
  const { logout } = useLogout();
  const { t } = useTranslation();

  // Auth store state
  const [accessToken] = useAuthStore((state: AuthState) => [state.accessToken]);

  /**
   * Fetch data by query.
   * @param path Path
   * @param options Fetch options
   * @returns JSON data | Error
   */
  const fetchData = (path: string, options?: FetchDataOptions) => {
    try {
      let query = {};

      // #TODO: Needs to be more intelligent..
      if (options?.params?.include_me) {
        const include_me = {
          include_me: options.params.include_me,
        };
        Object.assign(query, include_me);
      }

      if (options?.params?.exclude_community_id) {
        const exclude_community_id = {
          exclude_community_id: options.params.exclude_community_id,
        };
        Object.assign(query, exclude_community_id);
      }

      if (options?.params?.folder_id) {
        const folder_id = { folder_id: options.params.folder_id };
        Object.assign(query, folder_id);
      }

      if (options?.params?.community_id) {
        const community_id = { community_id: options.params.community_id };
        Object.assign(query, community_id);
      }

      if (options?.params?.exclude_direct_chats) {
        const exclude_direct_chats = {
          exclude_direct_chats: options.params.exclude_direct_chats,
        };
        Object.assign(query, exclude_direct_chats);
      }

      if (options?.params?.exclude_group_id) {
        const exclude_group_id = {
          exclude_group_id: options.params.exclude_group_id,
        };
        Object.assign(query, exclude_group_id);
      }

      if (options?.params?.exclude_tool_id) {
        const exclude_tool_id = {
          exclude_tool_id: options.params.exclude_tool_id,
        };
        Object.assign(query, exclude_tool_id);
      }

      if (options?.params?.group_id) {
        const group_id = { group_id: options.params.group_id };
        Object.assign(query, group_id);
      }

      if (options?.params?.community_id_member) {
        const community_id_member = {
          community_id_member: options.params.community_id_member,
        };
        Object.assign(query, community_id_member);
      }

      if (options?.params?.community_id_employee) {
        const community_id_employee = {
          community_id_employee: options.params.community_id_employee,
        };
        Object.assign(query, community_id_employee);
      }

      if (options?.params?.community_id_admin) {
        const community_id_admin = {
          community_id_admin: options.params.community_id_admin,
        };
        Object.assign(query, community_id_admin);
      }

      if (options?.params?.community_id_group) {
        const community_id_group = {
          community_id_group: options.params.community_id_group,
        };
        Object.assign(query, community_id_group);
      }

      if (options?.params?.community_id_folder) {
        const community_id_folder = {
          community_id_folder: options.params.community_id_folder,
        };
        Object.assign(query, community_id_folder);
      }

      if (options?.params?.community_id_community_link) {
        const community_id_community_link = {
          community_id_community_link:
            options.params.community_id_community_link,
        };
        Object.assign(query, community_id_community_link);
      }

      if (options?.params?.community_id_tool_link) {
        const community_id_tool_link = {
          community_id_tool_link: options.params.community_id_tool_link,
        };
        Object.assign(query, community_id_tool_link);
      }

      if (options?.params?.community_id_employee) {
        const community_id_employee = {
          community_id_employee: options.params.community_id_employee,
        };
        Object.assign(query, community_id_employee);
      }

      if (options?.params?.link_id) {
        const link_id = { link_id: options.params.link_id };
        Object.assign(query, link_id);
      }

      if (options?.params?.ids) {
        const ids = { ids: JSON.stringify(options.params.ids) };
        Object.assign(query, ids);
      }

      if (options?.params?.not_ids) {
        const not_ids = { not_ids: options.params.not_ids.join(',') };
        Object.assign(query, not_ids);
      }

      if (options?.params?.unread) {
        const unread = { unread: options.params.unread };
        Object.assign(query, unread);
      }

      if (options?.params?.skip) {
        const skip = { skip: options.params.skip };
        Object.assign(query, skip);
      }

      if (options?.params?.template_type) {
        const template_type = { template_type: options.params.template_type };
        Object.assign(query, template_type);
      }

      const url = `${process.env.REACT_APP_BACKEND_URL}/api/${path}${
        Object.keys(query).length > 0 ? '?' + stringify(query) : ''
      }`;

      return fetch(url, {
        method: options?.method,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: options?.body ? JSON.stringify(options.body) : undefined,
      }).then(async (response) => {
        if (!response.ok) {
          throw new FetchError(response);
        }
        return await response.json().catch((error) => {
          console.error('Error fetching data:', error);
          return {};
        });
      });
    } catch (error) {
      console.error('ERROR fetching data:', error);
    }
  };

  /**
   * Handle common http errors / status codes.
   * @param status Http response status code
   * @returns Error message
   */
  const handleError = (status: number): string => {
    switch (status) {
      case 401:
        // Logout if unauthorized
        logout();
        return '';
      default:
        return `Code ${status}: ${t('app.fetch.error.response')}`;
    }
  };

  /**
   * Handle http request retry by status code.
   * Response status codes beginning with the digit "5" indicate cases in which the server is aware that it has encountered an error or is otherwise incapable of performing the request.
   * @param failureCount Failure count
   * @param error Error response
   * @returns Retrying state
   */
  const handleRetry = (failureCount: number, error: any): boolean => {
    const status = error?.response?.status;
    // Abort retrying after 3 tries
    if (status && status >= 500 && status <= 513 && failureCount < 2) {
      return true;
    } else {
      return false;
    }
  };

  const uploadData = (path: string, options?: UploadDataOptions) => {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/${path}`, {
      method: options?.method,
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
      body: options?.formData,
    }).then(async (response) => {
      if (!response.ok) {
        throw new FetchError(response);
      }
      return await response.json();
    });
  };

  return {
    fetchData,
    handleError,
    handleRetry,
    uploadData,
  };
};
