import { useTranslation } from 'react-i18next';

// Components
import { TextButton } from '../../ui/TextButton/TextButton';

// Styles
import styles from './FormInviteIndirect.module.scss';

type FormInviteIndirectProps = {
  onClose: () => void;
  onSubmit: () => void;
};

export const FormInviteIndirect = (props: FormInviteIndirectProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles['form-invite-indirect']}>
      <TextButton
        classes={styles['form-invite-indirect-cancel']}
        preset="secondary"
        onClick={props.onClose}
      >
        {t('app.cancel')}
      </TextButton>
      <TextButton preset="primary" onClick={props.onSubmit}>
        {t('app.users.invite.button')}
      </TextButton>
    </div>
  );
};
