import { ContactsTranslations } from '../models/contacts.translations.interface';

export const ContactsTranslationsDE: ContactsTranslations = {
  all: 'ALLE KONTAKTE ANZEIGEN',
  all_loading: 'Alle Kontakte laden',
  toolbar: {
    export: 'Liste als .csv exportieren',
    show: {
      navigation: 'Navigation anzeigen',
    },

    filter: {
      search: 'Suchen',
    },
    community: {
      add_group: 'Gruppe anlegen',
      edit_group: 'GRUPPE SPEICHERN',
      add_tool: 'Hilfsmittelauswahl',
      add_tool1: 'Hilfsmittel',
      add_tool2: 'Auswahl',
      select_tool:
        'Wähle ein oder mehrere Hilfsmittel aus, die du zuordnen möchtest.',
    },
    group: {
      success: {
        singular: '{{userName}} wurde erfolgreich hinzugefügt.',
        plural: '{{userName}} wurden erfolgreich hinzugefügt.',
      },
      setting: 'Einstellungen',
      add_person: 'Personen hinzufügen',
      invite_person: 'Personen einladen',
      create: 'Deine Gruppe erfolgreich erstellt.',
    },
    invite: {
      community: 'Gemeinschaft Auswählen',
      group: 'Gruppe Auswählen',
      tool: 'Hilfsmittel Auswählen',
    },
    label: {
      community: 'Arbeitet ihr zusammen in einer Gemeinschaft?',
      group:
        'Füge diese Nutzer:Innen direkt zu bestehenden Gruppen hinzu (optional)',
      tool: 'Lege für diese Nutzer:Innen jeweils neue individuelle Hilfsmittel an (optional)',
    },
    link: {
      title: 'Freigabelink',
    },
  },
  nav: {
    no_community: {
      group: {
        title: 'Gruppen ohne Gemeinschaft',
      },
    },
    footer: {
      title: 'Kommunikation und Organisation im Team!',
      content:
        'Du kannst Gemeinschaften anlegen, um mit den zugeordneten Freunden, Kolleg:Innen, Kunden, Mitgliedern, .. bequem gemeinsam die Hilfsmittel zu nutzen und euch auszutauschen.',
      button: 'Zusammen arbeiten',
    },
  },
  delete: {
    this_contact: 'diesen Kontakt',
    this_contact_big: 'Diesen Kontakt',
    alert: {
      text1: 'Bist du sicher, dass du',
      text2: 'aus deinem Kontaktebuch entfernen möchtest?',
    },
    notification: {
      result: {
        title: '{{userName}} wurde aus deinen Kontakten entfernt.',
      },
      room: {
        title1: 'Es werden deine Nachrichten an ',
        this_contact: 'diesem Kontakt',
        title2: ' gelöscht.',
      },
      community: {
        title1: ' wird aus ',
        title2: ' Gruppen entfernt.',
      },
    },
  },
  teaser: {
    invite: 'Kontakte einladen',
    group: 'Kontakte gruppen',
  },
  actions: {
    add: 'Zu meinen Kontakten hinzufügen',
    message: 'Anschreiben',
    reinvite: 'Erneut einladen',
    view: 'Ansehen',
  },
  add: {
    error: 'Es ist ein Fehler beim Hinzufügen aufgetreten:',
    success: 'Kontakt eingeladen',
  },
  community: {
    title: 'Gemeinschaft',
  },
  connection: {
    direct: 'Direkt',
    indirect: 'Indirekt',
    title: '',
  },
  detail: {
    tools: {
      add: 'Hinzufügen',
      title: 'Zugewiesene Hilfsmittel',
    },
    tooltip: {
      edit_nickname: 'Spitzname bearbeiten',
      upgrade: 'Hochstufen',
      downgrade: 'Runterstufen',
    },
  },
  filters: {
    button: 'Ansicht',
    search: 'Person suchen',
    title: 'Neuer Filter',
  },
  invite: {
    reinvite: 'Einladung wiederholen',
    error: {
      emails: {
        multi: 'Du hast die Nutzer mit den E-Mail-Adressen ',
        single: 'Du hast den Nutzer mit der E-Mail-Adresse ',
        text: 'bereits zu deinen Kontakten eingeladen.',
      },
    },
    info: `Für Personen, die du via E-Mail-Adresse eingeladen hast, gilt: Wir werden der eingeladenen Person eine Einladungsmail schicken. Du kannst aber vorher schon Nachrichten schreiben. Er oder sie kann automatisch auf die Nachrichten zugreifen, sobald die Anmeldung abgeschlossen und die E-Mail Adresse bestätigt ist.`,
    submit: 'Einladung senden',
    text: 'Lade neue Nutzer:Innen zu Pengueen ein',
    title: 'Einladen',
  },
  role: {
    title: 'Zugewiesene Rolle',
  },
  state: {
    active: 'Aktiv',
    declined: 'Abgelehnt',
    indirect: 'Indirekt',
    invited: 'Eingeladen',
    requested: 'Angefragt',
    expired: 'Abgelaufen',
    title: 'Status',
  },
  title: 'Netzwerk',
  title_page: 'Deine Kontakte',
  unapproved: 'Nicht freigegeben',
  view_on_dashboard: 'Ansicht auf Dashboard',
  tools_empty: 'Keine Hilfsmittel vorhanden!',
};
