import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';

// Components
import { Icon } from '../../../../shared/ui/Icon/Icon';
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';

// Models
import { Message } from '../../models/message.types';

// Styles
import styles from './MessageQuote.module.scss';

type MessageQuoteProps = {
  border?: boolean;
  message: Message;
  onCloseQuote?: () => void;
};

export const MessageQuote = (props: MessageQuoteProps) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{ borderColor: 'text.secondary' }}
      className={clsx(
        styles['message-quote'],
        props.border && styles['message-quote-border']
      )}
    >
      <Box sx={{ display: 'flex' }}>
        <Icon
          icon={['far', 'quote-right']}
          size="small"
          sx={{ color: 'text.secondary' }}
        />
        <Box
          sx={{ color: 'text.secondary' }}
          className={styles['message-quote-content']}
        >
          <Box sx={{ display: 'flex' }}>
            {/* Sanitise raw HTML to prevent cross-site scripting (XSS) attack*/}
            <Box
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  props.message.content.length > 75
                    ? props.message.content.substring(0, 75).concat('...')
                    : props.message.content
                ),
              }}
              sx={{ fontStyle: 'italic' }}
            />
          </Box>
          {props.message.images && props.message.images.length > 0 && (
            <Box className={styles['message-quote-content-image']}>
              {props.message.images.map((image) => (
                <a
                  href={image.url}
                  rel="noreferrer"
                  target="_blank"
                  key={uuidv4()}
                >
                  <img
                    key={image.id}
                    alt="Preview"
                    src={image.url}
                    className={clsx(styles['message-quote-content-image-data'])}
                  />
                </a>
              ))}
            </Box>
          )}
          <Box className={styles['message-quote-content-user']}>
            {`${props.message.create_change_info.created_by.profile?.personal_data.first_name} ${props.message.create_change_info.created_by.profile?.personal_data.last_name}, `}
            {` ${dayjs(props.message.create_change_info.created_at).format(
              'L'
            )} ${t('newscenter.room.quote.at')} ${dayjs(
              props.message.create_change_info.created_at
            ).format('LT')}`}
          </Box>
        </Box>
      </Box>
      {props.onCloseQuote && (
        <IconButton
          icon={['fas', 'times']}
          iconSize="small"
          onClick={props.onCloseQuote}
        />
      )}
    </Box>
  );
};
