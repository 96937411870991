import { useEffect, useState } from 'react';
import { TFunctionResult } from 'i18next';
import {
  findIconDefinition,
  IconDefinition,
  IconLookup,
} from '@fortawesome/fontawesome-svg-core';
import {
  Box,
  ListSubheader,
  MenuItem,
  Select as SelectMui,
  SvgIcon,
  SxProps,
  Theme,
} from '@mui/material';
import clsx from 'clsx';

// Components
import { FormFieldLabel } from '../FormFieldLabel/FormFieldLabel';
import { ResultMessage } from '../ResultMessage/ResultMessage';

// Models
import {
  ResultState,
  Option,
  Theme as ETheme,
  SelectGroup,
} from '../../models/shared.types';

// Stores
import { SharedState, useSharedStore } from '../../stores/use-shared.store';

// Styles
import './Select.scss';
import { off } from 'process';

const CustomExpand = (props: any) => {
  const iconLookup: IconLookup = {
    prefix: 'fas',
    iconName: 'chevron-down',
  };
  const icon: IconDefinition = findIconDefinition(iconLookup);
  const {
    icon: [width, height, , , svgPathData],
  } = icon;
  return (
    <SvgIcon
      {...props}
      fontSize="small"
      sx={{ color: 'text.primary', width: '0.75rem' }}
      viewBox={`0 0 ${width} ${height}`}
    >
      {typeof svgPathData === 'string' ? (
        <path d={svgPathData} />
      ) : (
        /**
         * A multi-path Font Awesome icon seems to imply a duotune icon. The 0th path seems to
         * be the faded element (referred to as the "secondary" path in the Font Awesome docs)
         * of a duotone icon. 40% is the default opacity.
         *
         * @see https://fontawesome.com/how-to-use/on-the-web/styling/duotone-icons#changing-opacity
         */
        svgPathData.map((d: string | undefined, i: number) => (
          <path style={{ opacity: i === 0 ? 0.4 : 1 }} d={d} />
        ))
      )}
    </SvgIcon>
  );
};

type SelectProps = {
  classes?: string;
  fontSize?: string;
  defaultPlaceholder?: TFunctionResult | string;
  disabled?: boolean;
  displayEmpty?: boolean;
  label?: TFunctionResult | string;
  labelColor?: string;
  labelFontWeightClasses?: string;
  message?: TFunctionResult | string;
  multiple?: boolean;
  padding?: string;
  optional?: boolean;
  options: Option[];
  sublabel?: TFunctionResult | string;
  state?: ResultState;
  value: any;
  defaultValue?: any;
  width?: string;
  widthClassName?: string;
  isAdmin?: boolean;
  onChange: (value: any) => void;
  onClose?: (value: any) => void;
};

export const Select = (props: SelectProps) => {
  // Component state
  const [sxColors, setSxColors] = useState<SxProps<Theme> | undefined>(
    undefined
  );

  // Shared store state
  const [theme] = useSharedStore((state: SharedState) => [state.theme]);

  // ####### //
  // EFFECTS //
  // ####### //

  // Set color by result state
  useEffect(() => {
    let color: string | undefined = undefined;
    switch (props.state) {
      case ResultState.Error:
        color = 'error.main';
        break;
      case ResultState.Info:
        color = 'info.main';
        break;
      case ResultState.Success:
        color = 'success.main';
        break;
      case ResultState.Warning:
        color = 'warning.main';
        break;
      default:
        break;
    }
    setSxColors({
      '& .MuiOutlinedInput-root': {
        backgroundColor: theme === ETheme.Dark ? 'bg.card' : 'inherit',
      },
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: color
          ? color
          : theme === ETheme.Dark
          ? 'transparent'
          : 'border.app',
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: color
          ? color
          : theme === ETheme.Dark
          ? 'transparent'
          : 'border.app',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: color ? color : 'primary.main',
      },
    });
  }, [props, theme]);

  // ######### //
  // CALLBACKS //
  // ######### //

  return (
    <Box
      sx={{
        ...sxColors,
        width: props.width,
        '& .MuiSelect-select': {
          fontSize: props.fontSize,
          padding: props.padding ?? '16px 32px 16px 16px !important',
        },
      }}
      className={clsx(
        'form-select',
        props.classes && props.classes,
        props.widthClassName
          ? props.widthClassName
          : props.width
          ? undefined
          : 'w-full'
      )}
    >
      {props.label && (
        <FormFieldLabel
          labelColor={props.labelColor}
          label={props.label}
          labelFontWeightClasses={props.labelFontWeightClasses}
          optional={props.optional}
          sublabel={props.sublabel}
        />
      )}
      <SelectMui
        disabled={false === props.isAdmin || props.disabled ? true : false}
        displayEmpty={true}
        IconComponent={CustomExpand}
        multiple={props.multiple}
        sx={{
          color:
            props.value === '' ||
            props.value === undefined ||
            (Array.isArray(props.value) && props.value.length === 0)
              ? 'text.secondary'
              : 'text.primary',
        }}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={(event) => {
          props.onChange(event.target.value);
        }}
        onClose={() => props.onClose && props.onClose(props.value)}
      >
        {props.defaultPlaceholder && (
          <ListSubheader>{props.defaultPlaceholder}</ListSubheader>
        )}
        {props.options.map((option, index: number) => {
          if (option.listHeader) {
            return <ListSubheader>{option.placeholder}</ListSubheader>;
          } else {
            return (
              <MenuItem
                key={index}
                value={option.value}
                sx={{
                  color:
                    option.value === '' ||
                    option.value === undefined ||
                    (Array.isArray(option.value) && option.value.length === 0)
                      ? 'text.secondary'
                      : 'text.primary',
                }}
                className="form-select-option"
              >
                {option.placeholder}
              </MenuItem>
            );
          }
        })}
      </SelectMui>
      {props.message && (
        <ResultMessage message={props.message} state={ResultState.Error} />
      )}
    </Box>
  );
};
