import {
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Box, Dialog as MuiDialog } from '@mui/material';
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  GridCellParams,
  GridSelectionModel,
  GridFilterModel,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridValueSetterParams,
  GridToolbarExport,
  GridToolbarExportProps,
  useGridApiContext,
  GridStateColDef,
} from '@mui/x-data-grid-pro';
import { TFunctionResult } from 'i18next';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';

// Assets
import kontakte_einladen_teaser_left from '../../../../assets/pictures/contacts/kontakte_einladen_teaser_left.png';
import kontakte_gruppen_teaser from '../../../../assets/pictures/contacts/kontakte_gruppen_teaser.png';

// Components
import { AddButton } from '../../../../shared/ui/AddButton/AddButton';
import { AddContactsFavoriteTools } from '../AddContactsFavoriteTools/AddContactsFavoriteTools';
import { ContactDetail } from '../ContactDetail/ContactDetail';
import { ContactsInvite } from '../ContactsInvite/ContactsInvite';
import { ContactsNav } from '../ContactsNav/ContactsNav';
import { Dialog } from '../../../../shared/ui/Dialog/Dialog';
import { FavoriteTool } from '../../../dashboard/components/WidgetFavoriteTools/WidgetFavoriteTools';
import { GroupCreateEditSettings } from '../../../newscenter/components/GroupCreateEditSettings/GroupCreateEditSettings';
import { Icon } from '../../../../shared/ui/Icon/Icon';
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';
import { IconTextButton } from '../../../../shared/ui/IconTextButton/IconTextButton';
import { ImageFallback } from '../../../../shared/components/ImageFallback/ImageFallback';
import { Loader } from '../../../../shared/ui/Loader/Loader';
import { multiElementDataGridSxGet } from '../../../tools/utils/tools.utils';
import { NewsCenterChatHeader } from '../../../newscenter/components/NewsCenterChatHeader/NewsCenterChatHeader';
import { PageLayout } from '../../../../shared/components/PageLayout/PageLayout';
import { TextButton } from '../../../../shared/ui/TextButton/TextButton';
import { Tooltip } from '../../../../shared/ui/Tooltip/Tooltip';

// Hooks
import { useBreakpoints } from '../../../../shared/hooks/use-breakpoints.hook';
import { useContacts } from '../../hooks/use-contacts.hook';
import { useContactsHttp } from '../../hooks/use-contacts-http.hook';
import { useDataGrid } from '../../../../shared/hooks/use-data-grid.hook';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useNewsCenter } from '../../../newscenter/hooks/use-news-center.hook';
import { useNewsCenterHttp } from '../../../newscenter/hooks/use-news-center-http.hook';
import { useShared } from '../../../../shared/hooks/use-shared.hook';

// Models
import {
  ActiveState,
  CommunityFolder,
  CommunityGroup,
  ConnectionType,
  Contact,
  ContactAction,
  ContactCommunity,
  ContactCommunityGetAction,
  ContactCommunityIdType,
  ContactNavGroup,
  ContactsAddMemberPost,
  ContactsGetFilters,
  ContactsInvitePostRequest,
  ContactsViewNavType,
  ContactsViewType,
} from '../../models/contacts.types';
import {
  RoomRequest,
  RoomType,
} from '../../../newscenter/models/news-center.types';
import {
  Alert,
  AlertAction,
  Attachment,
  CrudState,
  ImageFallbackType,
  MenuItem,
  ResultState,
  Theme,
} from '../../../../shared/models/shared.types';
import {
  CommunityToolInformationParams,
  CommunityUserActionsParams,
  CommunityUserRole,
} from '../../../communities/models/communities.types';

// Stores
import {
  ContactsState,
  useContactsStore,
} from '../../stores/use-contacts.store';
import { useNewsCenterStore } from '../../../newscenter/stores/use-news-center.store';
import {
  SharedState,
  useSharedStore,
} from '../../../../shared/stores/use-shared.store';
import { UserState, useUserStore } from '../../../user/stores/use-user.store';

// Styles
import styles from './ContactsContent.module.scss';
import { AlertLocal } from '../../../../shared/ui/AlertLocal/AlertLocal';
import { Account } from '../../../user/models/user.types';
import { FormDelete } from '../../../../shared/components/FormDelete/FormDelete';
import { RemoveButton } from '../../../../shared/ui/RemoveButton/RemoveButton';
import ColumnTextArea from '../../../tools/components/ToolMultiElementTable/ColumnTextArea';

// Lazy-load components
// const CommunityUsersAdd = lazy(
//   () =>
//     import(
//       '../../../../modules/communities/components/CommunityUsersAdd/CommunityUsersAdd'
//     )
// );

const DataGridLoader = memo(() => {
  return (
    <div className={styles['data-grid-loader']}>
      <Loader size={12} classes={styles['data-grid-loader-circle']} />
    </div>
  );
});

const ColumnActions = memo((props: CommunityUserActionsParams) => {
  const { mdDown } = useBreakpoints();
  const { actionsGet, contactAddEffect } = useContacts();
  const { contactsInvitePost, contactDelete, getContactDeleteType } =
    useContactsHttp();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleError, handleRetry } = useFetch();
  const { directRoomPost } = useNewsCenterHttp();
  const { directRoomPostEffect } = useNewsCenter();
  const [contactDeleteId, setContactDeleteId] = useState<string | undefined>(
    undefined
  );
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [alert, setAlert] = useState<Alert | undefined>(undefined);
  const [alertAction, setAlertAction] = useState<AlertAction | undefined>(
    undefined
  );

  // Contacts store state
  const [
    // contactDeleteId,
    // setContactDeleteId,
    // contacts,
    // setContacts,
    // setDataLoading,
    setDialogInvite,
    // setSelectedContactId,
    setSelectedContactReinvite,
  ] = useContactsStore((state) => [
    // state.contactDeleteId,
    // state.setContactDeleteId,
    // state.contacts,
    // state.setContacts,
    // state.setDataLoading,
    state.setDialogInvite,
    // state.setSelectedContactId,
    state.setSelectedContactReinvite,
  ]);

  // Shared store state
  const [setDialogContacts, setNotification] = useSharedStore((state) => [
    state.setDialogContacts,
    state.setNotification,
  ]);

  // News center store state
  const [directRooms, setDirectRooms, setDialogDirectRoomCreate] =
    useNewsCenterStore((state) => [
      state.directRooms,
      state.setDirectRooms,
      state.setDialogDirectRoomCreate,
    ]);

  // ######### //
  // MUTATIONS //
  // ######### //

  // DELETE Contact mutation
  const contactDeleteMutation = useMutation(
    (body: { id: string }) => contactDelete(body),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        // setDataLoading(false);
        if (data) {
          // remove contact from contact book
          const updatedContacts = [...props.contacts];
          const contact_index = updatedContacts.findIndex(
            (contact) => contact.id === data.deletedUserId
          );

          if (contact_index > -1) {
            updatedContacts.splice(contact_index, 1);
            props.setContacts(updatedContacts);
          }

          // remove direct room from message center
          if (data.deletedRoomId) {
            const updatedDirectRooms = [...directRooms];
            const room_index = updatedDirectRooms.findIndex(
              (room) => room.id === data.deletedRoomId
            );

            if (room_index > -1) {
              updatedDirectRooms.splice(room_index, 1);
              setDirectRooms(updatedDirectRooms);
            }
          }

          const userName =
            data.deletedUserName !== 'Namenlos'
              ? data.deletedUserName
              : t('contacts.delete.this_contact_big');

          setNotification({
            title: `${t('contacts.delete.notification.result.title', {
              userName: userName,
            })}`,
            state: ResultState.Success,
          });
        }
        if (error) {
          console.log('ERROR on contactDeleteMutation', error);
        }
      },
    }
  );
  // GET delete Contact type mutation
  const getContactDeleteTypeMutation = useMutation(
    (body: { id: string }) => getContactDeleteType(body),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          // setDataLoading(false);
          const notification: string[] = [];
          const userName =
            props.row.name !== 'Namenlos'
              ? props.row.name
              : t('contacts.delete.notification.room.this_contact');

          // remove direct room from message center
          if (data.deletedRoomId) {
            notification.push(
              `${t('contacts.delete.notification.room.title1')}${userName}${t(
                'contacts.delete.notification.room.title2'
              )}`
            );
          }

          // show result
          if (
            data.deletedFromCommunities &&
            data.deletedFromCommunities.length > 0
          ) {
            notification.push(
              `${userName}${t(
                'contacts.delete.notification.community.title1'
              )}${data.deletedFromCommunities.toString()}${t(
                'contacts.delete.notification.community.title2'
              )}`
            );
          }

          setContactDeleteId(props.row.id);
          setAlert({
            title: `${t('contacts.delete.alert.text1')} ${userName} ${t(
              'contacts.delete.alert.text2'
            )}`,
            subtitle: (
              <div>
                {notification.map((text, index) => {
                  return (
                    <Box key={index} className={styles['contacts-alert']}>
                      <Icon
                        icon={['fas', 'times']}
                        color="error"
                        classes={styles['contacts-alert-icon']}
                      />
                      {text}
                    </Box>
                  );
                })}
              </div>
            ),
          });
        }
        if (error) {
          console.log('ERROR on getContactDeleteTypeMutation', error);
        }
      },
    }
  );

  // POST Contact add mutation
  const addPostMutation = useMutation(
    (data: ContactsInvitePostRequest) => contactsInvitePost(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        // Add contact to store
        if (data) {
          try {
            props.setContacts(
              contactAddEffect(props.row.email, props.contacts)
            );
            setNotification({
              title: t('contacts.add.success'),
              state: ResultState.Success,
            });
          } catch (error) {
            console.log('ERROR on adding contact:', error);
          }
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            handleError(errRes.status);
            setNotification({
              title: `${t('contacts.add.error')} ${errRes.status}`,
              state: ResultState.Error,
            });
          }
        }
      },
    }
  );

  // POST direct room mutation
  const directRoomPostMutation = useMutation(
    (data: RoomRequest) => directRoomPost(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        // Add new direct room to store
        if (data) {
          try {
            if (!data?.existing) {
              directRoomPostEffect(data);
            }
            setDialogDirectRoomCreate(false);
            setDialogContacts(false);
            // setDataLoading(false);
            navigate(`/newscenter/private/${data.id}`);
          } catch (error) {
            console.log('ERROR on creating direct room:', error);
          }
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            handleError(errRes.status);
          }
        }
      },
    }
  );

  // ####### //
  // EFFECTS //
  // ####### //

  useEffect(() => {
    setMenuItems(
      actionsGet(props.row.state, props.type, props.memberRoleCommunity)
    );
    // eslint-disable-next-line
  }, [props.row.state, props.type]);

  // Abort on alert submit.
  useEffect(() => {
    if (alertAction === AlertAction.Submit) {
      contactDeleteId && onContactDelete(contactDeleteId);
    }
    // eslint-disable-next-line
  }, [alertAction]);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handle contact action.
   * @param action ContactAction
   */
  const onAction = useCallback((action: ContactAction) => {
    if (action === ContactAction.Add) {
      props.row.email &&
        addPostMutation.mutate({
          contacts: [{ email: props.row.email }],
        });
    }
    if (action === ContactAction.Community) {
      props.row.id && props.onCommunityUserAdd(props.row.id);
    }
    if (
      props.memberRoleCommunity !== CommunityUserRole.Member &&
      action === ContactAction.View
    ) {
      props.setSelectedContactId(props.row.id);
    }
    if (action === ContactAction.Reinvite) {
      setSelectedContactReinvite(props.row);
      setDialogInvite(true);
    }
    if (action === ContactAction.Message) {
      // onDirectChatUserSubmit(props.row.id);
      // setDataLoading(true);
    }
    if (action === ContactAction.Delete) {
      // setDataLoading(true);
      getContactDeleteTypeMutation.mutate({ id: props.row.id });
    }

    // eslint-disable-next-line
  }, []);

  /**
   * Handler to delete contact
   * @param User id
   */
  const onContactDelete = (id: string) => {
    contactDeleteMutation.mutate({ id: id });
  };

  /**
   * Handler to submit user id and create direct chat.
   * @param User id
   */
  // const onDirectChatUserSubmit = useCallback((id: string) => {
  //   directRoomPostMutation.mutate({
  //     type: RoomType.Direct,
  //     participant: [id],
  //   });

  // eslint-disable-next-line
  // }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
      {menuItems.map((menuItem, index) => {
        return (
          <div key={index}>
            {menuItem.icon && (
              <Tooltip title={menuItem.title}>
                <Box
                  className={styles['column-action']}
                  onClick={() => onAction(menuItem.action)}
                >
                  <IconButton
                    classes={styles['column-action-icon']}
                    icon={menuItem.icon}
                    disabled={
                      (menuItem.action === ContactAction.Message ||
                        menuItem.disabled) ??
                      false
                    }
                    iconSize={mdDown ? 'inherit' : undefined}
                    sxIcon={{ color: 'text.secondary' }}
                  />
                </Box>
              </Tooltip>
            )}
          </div>
        );
      })}
      <AlertLocal alert={alert} setAlertAction={setAlertAction} />
    </div>
  );
});

// type ColumnCommunityProps = {
//   logo?: Attachment;
//   name: string;
// };

// const ColumnCommunity = (props: GridRenderCellParams<ColumnCommunityProps>) => {
//   const { t } = useTranslation();

//   return (
//     <>
//       {props.row?.community?.name && (
//         <div className={styles['column-community']}>
//           {props.row?.community?.logo?.url ? (
//             <img
//               alt={props.row?.community?.name ?? t('contacts.community.title')}
//               className={styles['column-community-logo']}
//               src={props.row.community.logo.url}
//             />
//           ) : (
//             <ImageFallback
//               sizeClassName={styles['column-community-logo']}
//               type={ImageFallbackType.Community}
//             />
//           )}
//           <div className={styles['column-community-text']}>
//             {props.row?.community?.name}
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

type ColumnConnectionProps = {
  connection: ConnectionType;
};

const ColumnConnection = memo(
  (props: GridRenderCellParams<ColumnConnectionProps>) => {
    const { t } = useTranslation();
    const { mdDown } = useBreakpoints();

    const convertItems = useCallback((connection: ConnectionType) => {
      switch (connection) {
        case ConnectionType.Direct:
          return (
            <Tooltip title={t('contacts.connection.direct')}>
              <Box
                className={styles['column-card']}
                sx={{ backgroundColor: 'bg.card' }}
              >
                <Icon
                  icon={['fal', 'people-arrows']}
                  classes={styles['column-action-icon']}
                  size={mdDown ? 'inherit' : undefined}
                />
              </Box>
            </Tooltip>
          );
        case ConnectionType.Indirect:
          return (
            <Tooltip title={t('contacts.connection.indirect')}>
              <Box
                className={styles['column-card']}
                sx={{ backgroundColor: 'bg.card' }}
              >
                <Icon
                  icon={['fal', 'chart-network']}
                  classes={styles['column-action-icon']}
                  size={mdDown ? 'inherit' : undefined}
                />
              </Box>
            </Tooltip>
          );
        case ConnectionType.Community:
          return (
            <Tooltip title={t('contacts.community.title')}>
              <Box
                className={styles['column-card']}
                sx={{ backgroundColor: 'bg.card' }}
              >
                <Icon
                  icon={['fal', 'users']}
                  classes={styles['column-action-icon']}
                  size={mdDown ? 'inherit' : undefined}
                />
              </Box>
            </Tooltip>
          );
        case ConnectionType.Group:
          return (
            <Tooltip title={t('community.tools.visibility.group')}>
              <Box
                className={styles['column-card']}
                sx={{ backgroundColor: 'bg.card' }}
              >
                <Icon
                  icon={['fal', 'hashtag']}
                  classes={styles['column-action-icon']}
                  size={mdDown ? 'inherit' : undefined}
                />
              </Box>
            </Tooltip>
          );
        case ConnectionType.Admin:
          return (
            <Tooltip title={t('community.tools.visibility.group')}>
              <Box
                className={styles['column-card']}
                sx={{ backgroundColor: 'bg.card' }}
              >
                <Icon
                  icon={['far', 'crown']}
                  classes={styles['column-action-icon']}
                  size={mdDown ? 'inherit' : undefined}
                />
              </Box>
            </Tooltip>
          );

        default:
          return (
            <Tooltip>
              <></>
            </Tooltip>
          );
      }
    }, []);

    return <>{convertItems(props.row.connection)}</>;
  }
);

type ColumnEmailProps = {
  email?: string;
  state: ActiveState;
};

const ColumnEmail = memo((props: GridRenderCellParams<ColumnEmailProps>) => {
  const { t } = useTranslation();

  // Set displayed name
  const email =
    props.row?.email && props.row?.state === ActiveState.Active
      ? props.row?.email
      : t('contacts.unapproved');
  return <>{email}</>;
});

type ColumnNameProps = {
  avatar?: Attachment;
  // nickname?: string;
  // online_state: OnlineState | undefined;
  // state: ActiveState;
};

const ColumnName = memo((props: GridRenderCellParams<ColumnNameProps>) => {
  // Component state
  // const [colorOnlineState, setColorOnlineState] = useState<string | undefined>(
  //   undefined
  // );

  // Set online state color
  // useEffect(() => {
  //   const onlineState = props.row?.online_state as OnlineState;
  //   if (onlineState === OnlineState.Online) {
  //   setColorOnlineState('success.main');
  //   } else if (onlineState === OnlineState.Away) {
  //     setColorOnlineState('warning.main');
  //   }
  // }, [props.row?.online_state]);

  return (
    <div className={styles['column-name']}>
      <div className={styles['column-name-avatar']}>
        {props.row?.avatar?.url ? (
          <img
            alt={props.row?.name}
            className={styles['column-name-avatar-icon']}
            src={props.row.avatar.url}
          />
        ) : (
          <ImageFallback
            sizeClassName={styles['column-name-avatar-icon']}
            type={ImageFallbackType.Profile}
          />
        )}
        {/* {props.row?.online_state && (
        <Box
          className={styles['column-name-avatar-online-state']}
          sx={{ backgroundColor: colorOnlineState }}
        />
        )}  */}
      </div>
      <div className={styles['column-name-text']}>{props.row?.name}</div>
    </div>
  );
});

type ColumnStateProps = {
  state: ActiveState;
};

const ColumnState = memo((props: GridRenderCellParams<ColumnStateProps>) => {
  const { activeStateColorsGet } = useContacts();
  const { t } = useTranslation();
  const { mdDown } = useBreakpoints();

  const [tooltip, setTooltip] = useState<string | TFunctionResult>('');
  const [icon, setIcon] = useState<[IconPrefix, IconName] | undefined>(
    undefined
  );

  useEffect(() => {
    switch (props.row?.state) {
      case ActiveState.Active:
        setTooltip(t('contacts.state.active'));
        setIcon(['fal', 'handshake']);
        break;
      case ActiveState.Expired:
        setTooltip(t('contacts.state.expired'));
        setIcon(['fal', 'triangle-exclamation']);
        break;
      case ActiveState.Invited:
      case ActiveState.ToolLink:
        setTooltip(t('contacts.state.invited'));
        setIcon(['fal', 'hourglass-clock']);
        break;
      default:
        setIcon(undefined);
    }
  }, [props.row?.state]);

  return (
    <>
      {icon && (
        <Tooltip title={tooltip}>
          <Box
            className={styles['column-card']}
            sx={activeStateColorsGet(props.row?.state)}
          >
            <Icon
              icon={icon}
              classes={styles['column-action-icon']}
              size={mdDown ? 'inherit' : undefined}
            />
          </Box>
        </Tooltip>
      )}
    </>
  );
});

// type ColumnToolInfomationProps = {
//   information: string;
// };

type ContactsContentProps = {
  modal?: boolean;
  action?: ContactCommunityGetAction;
  communityId?: string;
  deletedNavItem?: ContactNavGroup | undefined;
  disableDeatilView?: boolean;
  disableImage?: boolean;
  disableSelect?: boolean;
  footerComponent?: ReactNode;
  groupId?: string;
  heightClasses?: string;
  include_me?: boolean;
  newsCenter?: boolean;
  paddingClasses?: string;
  showRowAction?: boolean;
  showToolbar?: boolean;
  toolId?: string;
  type?: ContactsViewType;
  useNavigation?: boolean;
  setDeletedNavItem?: Dispatch<SetStateAction<ContactNavGroup | undefined>>;
  onCommunityUsersAddSubmit?: () => void;
  onDirectContactClick?: (contact: Contact) => void;
  onUsersDeselect?: () => void;
  onUsersSelectionChange?: (selection: string[]) => void;
};

const ContactsContent = memo((props: ContactsContentProps) => {
  const { id } = useParams<'id'>();
  const { folder_id } = useParams<'folder_id'>();
  const { groupid } = useParams<'groupid'>();
  const { communitygroupid } = useParams<'communitygroupid'>();
  const { linkid } = useParams<'linkid'>();
  const { toolid } = useParams<'toolid'>();

  const { mdDown, lgDown, xxlDown } = useBreakpoints();
  const { contactsGet, contactsAddMember, contactGroupDelete } =
    useContactsHttp();
  const { gridClassesGet, localeTextGet } = useDataGrid();
  const { handleError, handleRetry } = useFetch();
  const { t } = useTranslation();
  const { sortContacts, onToolUsersSelectionDelete } = useContacts();
  const { fullNameGet } = useShared();

  // User store state
  const [account] = useUserStore((state: UserState) => [state.account]);
  const { groupRoomDeleteFromNetwork } = useNewsCenterHttp();
  const [navCommunities, groupChats, setNavCommunities, setGroupChats] =
    useContactsStore((state: ContactsState) => [
      state.navCommunities,
      state.groupChats,
      state.setNavCommunities,
      state.setGroupChats,
    ]);
  // Shared store state
  const [setNotification, setAlert, alertAction] = useSharedStore((state) => [
    state.setNotification,
    state.setAlert,
    state.alertAction,
  ]);

  // Contacts store state
  const [
    contacts,
    communityTools,
    dialogInvite,
    selectedUsers,

    setContacts,
    setCommunityTools,
    setDialogInvite,
    setSelectedContactReinvite,
    setSelectedUsers,
  ] = useContactsStore((state) => [
    state.contacts,
    state.communityTools,
    state.dialogInvite,
    state.selectedUsers,

    state.setContacts,
    state.setCommunityTools,
    state.setDialogInvite,
    state.setSelectedContactReinvite,
    state.setSelectedUsers,
  ]);

  // Component state
  const [activeNav, setActiveNav] = useState<boolean>(false);
  const [columns, setColumns] = useState<any>([]);
  const [isGroupSettingLoad, setIsGroupSettingLoad] = useState<boolean>(false);
  const [dialogCommunityUsersAdd, setDialogCommunityUsersAdd] =
    useState<boolean>(false);
  const [communityUsersToAdd, setCommunityUsersToAdd] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [hasContacts, setHasContacts] = useState<boolean>(true);
  const [activeId, setActiveId] = useState<string | undefined>(
    ContactCommunityIdType.All
  );
  const [showAddGroup, setShowAddGroup] = useState<boolean>(true);
  const [showAddTool, setShowAddTool] = useState<boolean>(true);
  const [showAddMember, setShowAddMember] = useState<boolean>(false);
  const [showInviteUser, setShowInviteUser] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const [memberRoleCommunity, setMemberRoleCommunity] =
    useState<CommunityUserRole>(CommunityUserRole.Member);

  const [contactCommunity, setContactCommunity] = useState<ContactCommunity>({
    id: ContactCommunityIdType.None,
    name: '',
  });
  const [communityGroup, setCommunityGroup] = useState<
    CommunityGroup | undefined
  >(undefined);
  const [dialogGroupCreate, setDialogGroupCreate] = useState<boolean>(false);
  const [dialogAddMember, setDialogAddMember] = useState<boolean>(false);
  const [dialogAddTools, setDialogAddTools] = useState<boolean>(false);
  const [addMemberSelection, setDialogAddMemberSelection] = useState<string[]>(
    []
  );
  const [contentHeightClasses, setContentHeightClasses] = useState<string>(
    'h-[calc(100vh-230px)]'
  );
  const [extraHeaders, setExtraHeaders] = useState<string[] | undefined>(
    undefined
  );
  const [selectedContactId, setSelectedContactId] = useState<
    string | undefined
  >(undefined);
  const [selectedFolder, setSelectedFolder] = useState<CommunityFolder[]>([]);
  const [groupRoomCreateEditCrudState, setGroupRoomCreateEditCrudState] =
    useState<CrudState>(CrudState.CreateNoMember);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [dialogGroupRoomDelete, setDialogGroupRoomDelete] =
    useState<boolean>(false);
  const [localContacts, setLocalContacts] = useState<Contact[]>([]);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();

  // GET contacts mutation
  const contactsGetMutation = useMutation(
    (filters?: ContactsGetFilters) => contactsGet(filters),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        // Handle GET contacts
        if (data) {
          unstable_batchedUpdates(() => {
            const newRow = sortContacts(data.contacts);

            props.modal ? setLocalContacts(newRow) : setContacts(newRow);

            props.modal && onSelectionChange(selectedUsers);

            // set Community data
            setContactCommunity(data.community);
            setMemberRoleCommunity(
              data.community.member_role ?? CommunityUserRole.Member
            );

            //TODO: Switch Case for all Cases
            if (data.community.selected_group) {
              switch (data.community.selected_group.id) {
                case ContactCommunityGetAction.Community:
                  if (data.community.member_role === CommunityUserRole.Member) {
                    setShowAddGroup(false);
                    setShowAddMember(false);
                    setShowInviteUser(false);
                    setShowAddTool(false);
                    setShowFilter(false);
                  }
                  if (
                    data.community.member_role === CommunityUserRole.Employee
                  ) {
                    setShowAddGroup(true);
                    setShowAddMember(true);
                    setShowInviteUser(true);
                    setShowAddTool(false);
                    setShowFilter(false);
                  }
                  if (data.community.member_role === CommunityUserRole.Admin) {
                    setShowAddGroup(true);
                    setShowAddMember(true);
                    setShowInviteUser(true);
                    setShowAddTool(true);
                    setShowFilter(true);
                  }
                  break;
                case ContactCommunityGetAction.Admin:
                  if (data.community.member_role === CommunityUserRole.Member) {
                    navigate('/contacts');
                  }
                  if (
                    data.community.member_role === CommunityUserRole.Employee
                  ) {
                    navigate('/contacts');
                  }
                  if (data.community.member_role === CommunityUserRole.Admin) {
                    setShowAddGroup(true);
                    setShowAddMember(true);
                    setShowInviteUser(true);
                    setShowAddTool(true);
                    setShowFilter(true);
                  }
                  break;
                case ContactCommunityGetAction.Employee:
                  if (data.community.member_role === CommunityUserRole.Member) {
                    navigate('/contacts');
                  }
                  if (
                    data.community.member_role === CommunityUserRole.Employee
                  ) {
                    setShowAddGroup(false);
                    setShowAddMember(false);
                    setShowInviteUser(false);
                    setShowAddTool(false);
                    setShowFilter(false);
                  }
                  if (data.community.member_role === CommunityUserRole.Admin) {
                    setShowAddGroup(true);
                    setShowAddMember(true);
                    setShowInviteUser(true);
                    setShowAddTool(true);
                    setShowFilter(true);
                  }
                  break;
                case ContactCommunityGetAction.Member:
                  if (data.community.member_role === CommunityUserRole.Member) {
                    setShowAddGroup(false);
                    setShowAddMember(false);
                    setShowInviteUser(false);
                    setShowAddTool(false);
                    setShowFilter(false);
                  }
                  if (
                    data.community.member_role === CommunityUserRole.Employee
                  ) {
                    setShowAddGroup(true);
                    setShowAddMember(true);
                    setShowInviteUser(true);
                    setShowAddTool(true);
                    setShowFilter(true);
                  }
                  if (data.community.member_role === CommunityUserRole.Admin) {
                    setShowAddGroup(true);
                    setShowAddMember(true);
                    setShowInviteUser(true);
                    setShowAddTool(true);
                    setShowFilter(true);
                  }
                  break;
                default:
                  if (data.community.member_role === CommunityUserRole.Member) {
                    setShowAddGroup(false);
                    setShowAddMember(false);
                    setShowInviteUser(false);
                    setShowAddTool(false);
                    setShowFilter(false);
                  }
                  if (
                    data.community.member_role === CommunityUserRole.Employee
                  ) {
                    setShowAddGroup(false);
                    setShowAddMember(false);
                    setShowInviteUser(false);
                    setShowAddTool(false);
                    setShowFilter(false);
                  }
                  if (data.community.member_role === CommunityUserRole.Admin) {
                    setShowAddGroup(true);
                    setShowAddMember(true);
                    setShowInviteUser(true);
                    setShowAddTool(true);
                    setShowFilter(true);
                  }
                  if (data.community.selected_group.creator_id) {
                    if (
                      account &&
                      data.community.selected_group.creator_id.id === account.id
                    ) {
                      setShowAddGroup(true);
                      setShowAddMember(true);
                      setShowInviteUser(true);
                      setShowAddTool(true);
                      setShowFilter(true);
                    }
                  }
                  break;
              }
            }

            // if (data.community.member_role === CommunityUserRole.Admin) {
            //   setShowAddGroup(true);
            //   setShowAddMember(true);
            //   setShowInviteUser(true);
            // } else if (
            //   data.community.member_role === CommunityUserRole.Member ||
            //   data.community.member_role === CommunityUserRole.Employee
            // ) {
            //   setShowAddGroup(false);
            //   setShowAddMember(false);
            //   setShowInviteUser(false);
            // } else {
            //   setShowAddGroup(true);
            //   setShowAddMember(true);
            //   setShowInviteUser(true);
            // }

            data.community.selected_folder
              ? setSelectedFolder([data.community.selected_folder])
              : setCommunityGroup(undefined);

            data.community.selected_group
              ? setCommunityGroup(data.community.selected_group)
              : setCommunityGroup(undefined);
            
            if(!props.modal){
                 data.community.tools
              ? setCommunityTools(data.community.tools) 
              : setCommunityTools([]);
              }         
            if (data.extraHeaders) {
              setExtraHeaders(data.extraHeaders);
            } else {
              setExtraHeaders(undefined);
            }

            // set height for DataGrid
            onSetDataGridState(data.contacts.length, data.community.id);
          });
        }

        if (error) {
          const errRes = error?.response;
          // Check if error contains fetch response object
          if (errRes) {
            // Set view error by response status
            // setViewError(handleError(errRes.status));
            console.error(
              'Error getting contacts:',
              handleError(errRes.status)
            );
          }
        }
      },
    }
  );

  // contactsAddMember mutation
  const contactsAddMemberMutation = useMutation(
    (data: ContactsAddMemberPost) => contactsAddMember(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          !hasContacts && setHasContacts(true);

          // Get original contacts before adding members
          let contacts = localStorage.getItem(
            `__store_contacts_${account?.id}_${
              memberRoleCommunity
                ? memberRoleCommunity
                : communityGroup
                ? communityGroup.id
                : ''
            }`
          );

          // Add a new member to an existing contacts
          let prevContacts: Contact[];
          const updatedContacts: Contact[] = sortContacts(data.contacts);
          if (contacts) {
            prevContacts = JSON.parse(contacts);
            updatedContacts.push(...prevContacts);
          }
          setContacts(updatedContacts);

          // set success Notification
          let users = [];
          for (const contact of data.contacts) {
            const userName = fullNameGet(contact.first_name, contact.last_name);
            users.push(userName);
          }
          const userNames = users.join(', ');
          data.contacts.length === 1
            ? setNotification({
                title: `${t('contacts.toolbar.group.success.singular', {
                  userName: userNames,
                })}`,
                state: ResultState.Success,
              })
            : setNotification({
                title: `${t('contacts.toolbar.group.success.plural', {
                  userName: userNames,
                })}`,
                state: ResultState.Success,
              });

          // Delete previous contacts
          localStorage.removeItem(
            `__store_contacts_${account?.id}_${
              memberRoleCommunity
                ? memberRoleCommunity
                : communityGroup
                ? communityGroup.id
                : ''
            }`
          );
        }

        if (error) {
          const errRes = error?.response;
          if (errRes) {
            console.error(
              'Error add person to contact:',
              handleError(errRes.status)
            );
          }
        }
      },
    }
  );

  // contactGroupDeletemutation
  const contactGroupDeleteMutation = useMutation(
    (id: string) => contactGroupDelete(id),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          contactsGetMutation.mutate(undefined);
        }

        if (error) {
          const errRes = error?.response;
          if (errRes) {
            console.error('Error deleting group:', handleError(errRes.status));
          }
        }
      },
    }
  );

  // ####### //
  // EFFECTS //
  // ####### //
  useEffect(() => {
    lgDown ? setActiveNav(true) : setActiveNav(false);
  }, [lgDown]);

  // Control submit button
  useEffect(() => {
    if (isButtonDisabled) {
      const timeoutId = setTimeout(() => {
        setButtonDisabled(false);
      }, 60000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isButtonDisabled]);

  useEffect(() => {
    if (props.useNavigation) {
      setSelectedContactId(undefined);
      switch (props.action) {
        case ContactCommunityGetAction.None:
          setActiveId(ContactCommunityIdType.None);
          setHasContacts(false);
          setShowAddGroup(true);
          setShowAddMember(false);
          setShowInviteUser(true);
          setContacts([]);
          break;
        case ContactCommunityGetAction.All:
          contactsGetMutation.mutate(undefined);
          setActiveId(ContactCommunityIdType.All);
          setShowAddGroup(true);
          setShowAddMember(false);
          setShowInviteUser(true);
          // allContacts.length > 0
          //   ? setContacts(allContacts)
          //   : contactsGetMutation.mutate(undefined);
          setContactCommunity({ id: ContactCommunityIdType.All, name: '' });
          setContentHeightClasses('h-[calc(100vh-230px)]');
          break;
        case ContactCommunityGetAction.Community:
          setActiveId(id);
          contactsGetMutation.mutate({
            community_id: id,
            include_me: props.include_me,
          });
          break;
        case ContactCommunityGetAction.Admin:
          setActiveId('Admin' + id);
          setShowAddGroup(false);
          setShowAddMember(true);
          setShowInviteUser(true);
          contactsGetMutation.mutate({
            community_id_admin: id,
            include_me: props.include_me,
          });
          break;
        case ContactCommunityGetAction.Employee:
          setActiveId('Employee' + id);
          setShowAddGroup(false);
          setShowAddMember(true);
          setShowInviteUser(true);
          contactsGetMutation.mutate({
            community_id_employee: id,
            include_me: props.include_me,
          });
          break;
        case ContactCommunityGetAction.Member:
          setActiveId('Member' + id);
          setShowAddGroup(false);
          setShowAddMember(true);
          setShowInviteUser(true);
          contactsGetMutation.mutate({
            community_id_member: id,
            include_me: props.include_me,
          });
          break;
        case ContactCommunityGetAction.Group:
          setActiveId('Group' + communitygroupid);
          contactsGetMutation.mutate({
            community_id_group: communitygroupid,
            include_me: props.include_me,
          });
          break;
        case ContactCommunityGetAction.Folder:
          setContacts([]);
          setHasContacts(false);
          setActiveId(id);
          setCommunityGroup(undefined);
          setCommunityTools([]);
          setShowAddGroup(true);
          setShowAddMember(false);
          setShowInviteUser(false);
          contactsGetMutation.mutate({
            community_id_folder: id,
            include_me: props.include_me,
          });
          break;
        case ContactCommunityGetAction.FolderGroup:
          setActiveId('Group' + communitygroupid);
          contactsGetMutation.mutate({
            community_id_group: communitygroupid,
            community_id_folder: folder_id,
            include_me: props.include_me,
          });
          break;
        case ContactCommunityGetAction.CommunityLink:
          setActiveId('Community' + linkid);
          setShowAddGroup(false);
          setShowAddMember(false);
          setShowInviteUser(false);
          contactsGetMutation.mutate({
            community_id_community_link: linkid,
            include_me: props.include_me,
          });
          break;
        case ContactCommunityGetAction.ToolLink:
          setActiveId('Link' + toolid);
          setShowAddGroup(false);
          setShowAddMember(false);
          setShowInviteUser(false);
          contactsGetMutation.mutate({
            community_id_tool_link: toolid,
            include_me: props.include_me,
          });
          break;
        case ContactCommunityGetAction.Room:
          setActiveId(groupid);
          setShowAddGroup(false);
          setShowAddMember(true);
          setShowInviteUser(true);
          contactsGetMutation.mutate({
            group_id: groupid,
            include_me: props.include_me,
          });
          break;
        default:
          setActiveId(ContactCommunityIdType.None);
          setShowAddGroup(true);
          setShowAddMember(false);
          setShowInviteUser(true);
          setContactCommunity({ id: ContactCommunityIdType.None, name: '' });
          contactsGetMutation.mutate(undefined);
      }
      if (props.modal) {
        setShowAddGroup(false);
        setShowAddMember(false);
        setShowInviteUser(false);
      }
    }
    // personen hinzufugen in network
    else if (props.type === ContactsViewType.AddPersonNetwork) {
      setLocalContacts([]);
      setHasContacts(false);
      setActiveId(ContactCommunityIdType.None);
      setShowAddGroup(false);
      setShowAddMember(false);
      setShowInviteUser(false);
      setContactCommunity({ id: ContactCommunityIdType.None, name: '' });
      setContentHeightClasses('h-[calc(100vh-230px)]');
    }
    if (props.type === ContactsViewType.ToolEdit) {
      setContacts([]);
      setHasContacts(false);
      setActiveId(ContactCommunityIdType.All);
      // contactsGetMutation.mutate(undefined);
      setShowAddGroup(false);
      setShowAddMember(false);
      setShowInviteUser(false);
      setContactCommunity({ id: ContactCommunityIdType.All, name: '' });
      // setContentHeightClasses('h-[calc(100vh-230px)]');
    }
  }, [
    id,
    folder_id,
    groupid,
    communitygroupid,
    linkid,
    toolid,
    props.action,
    props.useNavigation,
    props.modal,
  ]);

  // Set dynamic columns with translations
  useEffect(() => {
    const columns: GridColumns = [
      {
        field: 'name',
        headerName: t('app.name').toUpperCase(),
        renderCell: (params: GridRenderCellParams<ColumnNameProps>) => (
          <ColumnName {...params} />
        ),
        width: lgDown ? 200 : 250,
        sortingOrder: ['asc', 'desc'],
      },
      {
        field: 'email',
        headerName: t('app.emailAddress').toUpperCase(),
        hide: selectedContactId ? true : false,
        valueGetter: (params: GridValueSetterParams) => {
          return params.row?.email && params.row?.state === ActiveState.Active
            ? params.row?.email
            : t('contacts.unapproved');
        },
        renderCell: (params: GridRenderCellParams<ColumnEmailProps>) => (
          <ColumnEmail {...params} />
        ),
        width: lgDown ? 180 : 225,
        sortingOrder: ['asc', 'desc'],
      },
      {
        field: 'connection',
        headerName: t('contacts.connection.title').toUpperCase(),
        hide: selectedContactId ? true : false,
        valueGetter: (params: GridValueSetterParams) => {
          switch (params.row.connection) {
            case ConnectionType.Admin:
              return t('community.tools.visibility.group');
            case ConnectionType.Community:
              return t('contacts.community.title');
            case ConnectionType.Group:
              return t('community.tools.visibility.group');
            case ConnectionType.Direct:
              // return ConnectionType.Direct;
              return t('contacts.connection.direct');
            case ConnectionType.Indirect:
              return t('contacts.connection.indirect');
          }
        },
        renderCell: (params: GridRenderCellParams<ColumnConnectionProps>) => {
          return <ColumnConnection {...params} />;
        },

        width: lgDown ? 20 : 30,
        sortingOrder: ['asc', 'desc'],
      },
      {
        field: 'state',
        headerName: t('contacts.connection.title').toUpperCase(),
        hide: selectedContactId ? true : false,
        valueGetter: (params: GridValueSetterParams) => {
          switch (params.row.state) {
            case ActiveState.Active:
              return t('contacts.state.active');
            case ActiveState.Expired:
              return t('contacts.state.expired');
            case ActiveState.Invited:
            case ActiveState.ToolLink:
              return t('contacts.state.invited');
          }
        },
        renderCell: (params: GridRenderCellParams<ColumnStateProps>) => (
          <ColumnState {...params} />
        ),
        width: lgDown ? 25 : 35,
        sortingOrder: ['asc', 'desc'],
      },
    ];
    if (extraHeaders && extraHeaders.length > 0) {
      extraHeaders.map((extratitle, index) => {
        columns.push({
          field: extratitle,
          headerName: extratitle.toUpperCase(),
          valueGetter: (params: GridValueSetterParams) => {
            return params.row.tool_informations
              ? params.row.tool_informations[index] !== 'undefined'
                ? params.row.tool_informations[index]?.information
                : ''
              : '';
          },
          renderCell: (params: GridRenderCellParams) => {
            const extendedParams: CommunityToolInformationParams = {
              ...params,
              information: params.row.tool_informations
                ? params.row.tool_informations[index] !== 'undefined'
                  ? params.row.tool_informations[index]?.information
                  : ''
                : '',
            };

            return <ColumnTextArea {...extendedParams} />;
          },
          width: lgDown ? 180 : 220,
          sortingOrder: ['asc', 'desc'],
        });
      });
    }
    if (props.showRowAction) {
      columns.push({
        flex: 1,
        field: 'actions',
        headerName: '',
        hide: selectedContactId ? true : false,
        minWidth: lgDown ? 120 : 140,
        filterable: false,
        renderCell: (params: GridRenderCellParams) => {
          const extendedParams: CommunityUserActionsParams = {
            ...params,
            type: props.type,
            contacts: contacts,
            memberRoleCommunity: memberRoleCommunity,
            setContacts: setContacts,
            setSelectedContactId: setSelectedContactId,
            onCommunityUserAdd: (id: string) => onCommunityUserAdd(id),
          };
          return <ColumnActions {...extendedParams} />;
        },
        sortable: false,
      });
    }
    if (lgDown) {
      columns.splice(1, 1);
    }

    setColumns(columns);
    // eslint-disable-next-line
  }, [props.showRowAction, selectedContactId, lgDown, extraHeaders, contacts]);

  // Abort on alert submit.
  useEffect(() => {
    if (alertAction === AlertAction.Submit) {
      communityGroup && contactGroupDeleteMutation.mutate(communityGroup.id);
    }
    // eslint-disable-next-line
  }, [alertAction]);

  // ######### //
  // CALLBACKS //
  // ######### //

  // set content height classes
  const onSetDataGridState = useCallback(
    (dataLength: number, community_id: string) => {
      dataLength > 0 ? setHasContacts(true) : setHasContacts(false);
      if (props.modal) {
        switch (community_id) {
          case ContactCommunityIdType.NoCommunity:
            setContentHeightClasses('h-[calc(100vh-370px)]');
            break;
          default:
            break;
        }
      } else if (activeNav) {
        lgDown
          ? community_id === ContactCommunityIdType.All
            ? setContentHeightClasses('h-[calc(100vh-150px)]')
            : setContentHeightClasses('h-[calc(100vh-300px)]')
          : setContentHeightClasses('h-[calc(100vh-230px)]');
      } else {
        lgDown
          ? setContentHeightClasses('h-[calc(100vh-10px)]')
          : community_id === ContactCommunityIdType.All ||
            community_id === ContactCommunityIdType.NoCommunity
          ? setContentHeightClasses('h-[calc(100vh-230px)]')
          : setContentHeightClasses('h-[calc(100vh-390px)]');

        // !props.showToolbar ?? setContentHeightClasses('h-[calc(100vh-170px)]');
      }
    },
    [lgDown, activeNav]
  );

  /**
   * Handler on data grid cell click.
   * @param params GridCellParams
   */
  const onCellClick = useCallback(
    (params: GridCellParams<any, any, any>) => {
      if (params.field !== 'actions') {
        if (!props.disableDeatilView) {
          // by creating private chat in NewsCenter
          if (props.type && props.type === ContactsViewType.Direct) {
            params.row?.id &&
              props.onDirectContactClick &&
              props.onDirectContactClick(params.row);
          }
          // by adding person in network
          else if (
            props.type &&
            props.type === ContactsViewType.AddPersonNetwork
          ) {
          }
          // by contacts view in Network
          else if (!props.type) {
            switch (communityGroup?.id) {
              case ContactCommunityGetAction.Admin:
                if (memberRoleCommunity === CommunityUserRole.Admin) {
                  setSelectedContactId(params.id as string);
                }
                break;
              case ContactCommunityGetAction.Employee:
                if (account && (params.id as string) === account.id) {
                  setSelectedContactId(params.id as string);
                }
                if (memberRoleCommunity === CommunityUserRole.Admin) {
                  setSelectedContactId(params.id as string);
                }
                break;
              case ContactCommunityGetAction.Member:
                if (account && (params.id as string) === account.id) {
                  setSelectedContactId(params.id as string);
                }
                if (memberRoleCommunity !== CommunityUserRole.Member) {
                  setSelectedContactId(params.id as string);
                }
                break;
              default:
                if (account && (params.id as string) === account.id) {
                  setSelectedContactId(params.id as string);
                }
                if (communityGroup?.creator_id) {
                  if (account && communityGroup?.creator_id.id === account.id) {
                    setSelectedContactId(params.id as string);
                  }
                }
                if (memberRoleCommunity === CommunityUserRole.Admin) {
                  setSelectedContactId(params.id as string);
                }
                break;
            }
          }
          // by creating group in NewsCenter
          // } else if (
          //   props.type &&
          //   props.type !== ContactsViewType.Group &&
          //   props.type !== ContactsViewType.ToolEdit
          // ) {
          //   setSelectedContactId(params.id as string);
        }
      }
      // eslint-disable-next-line
    },
    [props.type, addMemberSelection, memberRoleCommunity, communityGroup]
  );

  const onGroupSettingChange = () => {
    setDialogGroupCreate(true);
    setGroupRoomCreateEditCrudState(CrudState.UpdateNoMember);
  };

  // DELETE Group room mutation
  const groupRoomDeleteMutation = useMutation((id: string) =>
    groupRoomDeleteFromNetwork(id)
  );

  const onGroupDelete = useCallback(() => {
    setDialogGroupRoomDelete(true);
    communityGroup &&
      setAlert({
        title: t('newscenter.room.groups.delete.title'),
        subtitle: t('dashboard.widgets.delete.check'),
      });
  }, [communityGroup]);

  // Delete group room
  useEffect(() => {
    try {
      if (groupRoomDeleteMutation.data) {
        setDialogGroupRoomDelete(false);
        const updatedNavCommunities = [...navCommunities];
        const updatedGroupChats = [...groupChats];
        const comIndex = updatedNavCommunities.findIndex(
          (navCom) => navCom.id === contactCommunity.id
        );

        //Fall 1 Gruppe gehört einer Community an
        if (comIndex > -1) {
          const groupIndex =
            updatedNavCommunities[comIndex].groups?.findIndex(
              (group) => group.id === groupRoomDeleteMutation.data.id
            ) ?? -1;

          if (groupIndex > -1) {
            updatedNavCommunities[comIndex].groups?.splice(groupIndex, 1);
            setNavCommunities(updatedNavCommunities);

            // Fall 2 Gruppe gehört zusätzlich noch einem Folder
          } else {
            if (
              groupRoomDeleteMutation.data.tags &&
              groupRoomDeleteMutation.data.tags.length > 0
            ) {
              for (const del_folder of groupRoomDeleteMutation.data.tags) {
                const folder_index =
                  updatedNavCommunities[comIndex].folders?.findIndex(
                    (folder) => folder.id === del_folder
                  ) ?? -1;

                if (folder_index > -1) {
                  updatedNavCommunities[comIndex].folders?.splice(
                    folder_index,
                    1
                  );
                }
              }
            } else {
              updatedNavCommunities[comIndex].folders?.map((folder) => {
                const groupIndex =
                  folder.groups.findIndex(
                    (group) => group.id === groupRoomDeleteMutation.data.id
                  ) ?? -1;

                if (groupIndex > -1) {
                  folder.groups?.splice(groupIndex, 1);
                }

                return folder;
              });
            }

            setNavCommunities(updatedNavCommunities);
          }

          // Fall 3 Gruppe gehört keiner Community an
        } else {
          const groupIndex =
            updatedGroupChats.findIndex(
              (group) => group.id === groupRoomDeleteMutation.data.id
            ) ?? -1;

          if (groupIndex > -1) {
            updatedGroupChats.splice(groupIndex, 1);
            setGroupChats(updatedGroupChats);
          }
        }

        setContactCommunity({ id: ContactCommunityIdType.All, name: '' });
        setCommunityGroup(undefined);
        navigate('/contacts');
      }
      if (groupRoomDeleteMutation.error) {
        // const errRes = groupRoomDeleteMutation.error?.response;
        // if (errRes) {
        //   handleError(errRes.status);
        // }
      }
    } catch (error) {
      console.error('ERROR deleting group room:', error);
    }
    // eslint-disable-next-line
  }, [groupRoomDeleteMutation.data, groupRoomDeleteMutation.error]);

  /**
   * Handler to delete a group room by id.
   */
  const onGroupRoomDelete = useCallback(() => {
    if (communityGroup) {
      groupRoomDeleteMutation.mutate(communityGroup.id);
    }

    // eslint-disable-next-line
  }, [communityGroup]);

  /**
   * Handler to add single community user by id.
   * @param id User id
   */
  const onCommunityUserAdd = (id: string) => {
    setCommunityUsersToAdd([id]);
    setDialogCommunityUsersAdd(true);
  };

  /**
   * Handler to close detail view.
   */
  const onDetailClose = () => {
    // setContactsRefresh(!contactsRefresh);
    setActiveNav(false);
    setSelectedContactId(undefined);
  };

  /**
   * Handler on invite close.
   */
  const onInviteClose = () => {
    setDialogInvite(false);
    setSelectedContactReinvite(undefined);
  };

  /**
   * Handler to get contacts by params.
   */
  const onContactsGet = useCallback(
    (params?: ContactsGetFilters) => {
      if (!props.useNavigation) {
        setSelectedContactId(undefined);
        if (params?.folder_id) {
          // Folders can't have contacts
          setContacts([]);
          setHasContacts(false);
          setActiveId(params.folder_id);
          setCommunityGroup(undefined);
          setCommunityTools([]);
          setShowAddGroup(true);
          setShowAddMember(false);
          setShowInviteUser(false);
        } else {
          // Get contacts
          contactsGetMutation.mutate(params, {
            onSuccess(data, variables, context) {
              if (!params) {
                setActiveId(ContactCommunityIdType.All);
                setShowAddGroup(true);
                setShowAddMember(false);
                setShowInviteUser(true);
              } else if (params?.group_id) {
                setActiveId(params.group_id);
                setShowAddGroup(false);
                setShowAddMember(true);
                setShowInviteUser(true);
              } else if (params?.community_id) {
                setActiveId(params.community_id);
                if (
                  memberRoleCommunity !== CommunityUserRole.Member ||
                  data?.community.member_role !== CommunityUserRole.Member
                ) {
                  setShowAddGroup(true);
                  setShowAddMember(true);
                  setShowInviteUser(true);
                } else {
                  setShowAddGroup(false);
                  setShowAddMember(false);
                  setShowInviteUser(false);
                }
              } else if (params?.community_id_member) {
                setActiveId('Member' + params.community_id_member);
                setShowAddGroup(false);
                setShowAddMember(true);
                setShowInviteUser(true);
              } else if (params?.community_id_employee) {
                setActiveId('Employee' + params.community_id_employee);
                setShowAddGroup(false);
                setShowAddMember(true);
                setShowInviteUser(true);
              } else if (params?.community_id_admin) {
                setActiveId('Admin' + params.community_id_admin);
                setShowAddGroup(false);
                setShowAddMember(true);
                setShowInviteUser(true);
              } else if (params?.community_id_group) {
                setActiveId('Group' + params.community_id_group);
                if (
                  memberRoleCommunity !== CommunityUserRole.Member ||
                  data?.community.member_role !== CommunityUserRole.Member
                ) {
                  setShowAddGroup(false);
                  setShowAddMember(true);
                  setShowInviteUser(true);
                } else {
                  setShowAddGroup(false);
                  setShowAddMember(false);
                  setShowInviteUser(false);
                }
              } else if (params?.community_id_community_link) {
                setActiveId('Community' + params.community_id_community_link);
                setShowAddGroup(false);
                setShowAddMember(false);
                setShowInviteUser(false);
              } else if (params?.community_id_tool_link) {
                setActiveId('Link' + params.community_id_tool_link);
                setShowAddGroup(false);
                setShowAddMember(false);
                setShowInviteUser(false);
              } else {
                setShowAddGroup(true);
                setShowAddMember(false);
                setShowInviteUser(true);
              }
            },
          });
        }
      }

      // #TODO params
      // eslint-disable-next-line
    },
    [props.include_me, props.useNavigation]
  );

  /**
   * Handler on selection change.
   * @param selection GridSelectionModel
   */
  const onSelectionChange = useCallback(
    (selectionModel: GridSelectionModel) => {
      // to show person in footer
      let users = [...selectedUsers] as string[];
      const unselectedUsers = localContacts.filter(
        (user) => !selectionModel.some((id) => user.id === id)
      );
      // to select users
      selectionModel.map((selection) => {
        if (!users.some((user) => user === selection)) {
          users.push(selection.toString());
        }
      });
      // to remove unselected users
      users = users.filter(
        (user) =>
          !unselectedUsers.some((unselectedUser) => unselectedUser.id === user)
      );
      setSelectedUsers(users);

      // to send api request
      switch (props.type) {
        case ContactsViewType.Community:
          setCommunityUsersToAdd(users);
          break;
        case ContactsViewType.Group:
        case ContactsViewType.Tool:
        case ContactsViewType.AddPersonNetwork:
          props.onUsersSelectionChange && props.onUsersSelectionChange(users);
          break;
        default:
          break;
      }
    },
    [props, selectedUsers, setSelectedUsers]
  );

  /**
   * Handler to change selection state.
   * @param selection Selected user ids
   */
  const onGroupRoomSelectionChange = useCallback((selection: string[]) => {
    setDialogAddMemberSelection(selection);
  }, []);

  /**
   * Handler to create group room.
   */
  const onContactsAddMember = useCallback(() => {
    //handle button click
    setButtonDisabled(true);

    setDialogAddMember(false);

    contactsAddMemberMutation.mutate({
      community_id: contactCommunity?.id,
      group_id: communityGroup?.id,
      folder_id: communityGroup?.folders
        ? communityGroup?.folders[0].id
        : undefined,
      participant: addMemberSelection,
      type: props.groupId
        ? ContactsViewType.Group
        : props.communityId
        ? ContactsViewType.Community
        : undefined,
    });
    // eslint-disable-next-line
  }, [addMemberSelection, contactCommunity, communityGroup]);

  return (
    <>
      {/* <div className={clsx(styles['contacts'])}> */}

      {/* {lgDown && (activeNav || selectedContactId) && (
        <BackButtonSmall
          classes={clsx(
            styles['contacts-back'],
            activeNav && 'self-end',
            selectedContactId && 'self-start'
          )}
          iconRight={activeNav}
          onClick={onDetailClose}
        >
          {t('app.back_to_summary')}
        </BackButtonSmall>
      )} */}
      <PageLayout
        disableRightScroll={!lgDown}
        heightClasses={props.heightClasses}
        paddingClasses={props.paddingClasses}
        leftComponent={
          <ContactsNav
            activeId={activeId}
            deletedNavItem={props.deletedNavItem}
            onContactsGet={onContactsGet}
            setContactCommunity={setContactCommunity}
            setDeletedNavItem={props.setDeletedNavItem}
            setMemberRoleCommunity={setMemberRoleCommunity}
            setSelectedFolder={setSelectedFolder}
            setActiveNav={setActiveNav}
            navType={ContactsViewNavType.NetworkNavLeft}
            type={props.type}
            include_me={props.include_me}
            useNavigation={props.useNavigation}
          />
        }
        rightComponent={
          <div>
            {contactCommunity &&
              (!lgDown ||
                // Hide from view in contact details
                (lgDown && !selectedContactId)) &&
              !activeNav && (
                <NewsCenterChatHeader
                  contactCommunity={contactCommunity}
                  disabledMenu={
                    !communityGroup ||
                    memberRoleCommunity === CommunityUserRole.Employee ||
                    memberRoleCommunity === CommunityUserRole.Member
                  }
                  type={RoomType.Network}
                  onBack={() => {
                    setActiveNav(true);
                  }}
                  onMessagesAdd={() => {}}
                  onSettingChange={onGroupSettingChange}
                  onGroupDelete={onGroupDelete}
                />
              )}
            <div className={styles['contacts-container']}>
              {xxlDown && activeNav && (
                <ContactsNav
                  activeId={activeId}
                  type={props.type}
                  navType={ContactsViewNavType.NetworkNavRight}
                  setActiveNav={setActiveNav}
                  setSelectedFolder={setSelectedFolder}
                  setContactCommunity={setContactCommunity}
                  setMemberRoleCommunity={setMemberRoleCommunity}
                  onContactsGet={onContactsGet}
                  deletedNavItem={props.deletedNavItem}
                  setDeletedNavItem={props.setDeletedNavItem}
                  useNavigation={props.useNavigation}
                />
              )}

              {!activeNav && (
                <>
                  {props.showToolbar &&
                    (!lgDown || (lgDown && !selectedContactId)) && (
                      <>
                        {/* Tools Line */}
                        {contactCommunity &&
                          contactCommunity.id !== ContactCommunityIdType.All &&
                          contactCommunity.id !==
                            ContactCommunityIdType.None && (
                            <>
                              <div
                                className={styles['contacts-favorite-tools']}
                              >
                                {showAddTool ? (
                                  <>
                                    <Box
                                      className={styles['community-tools-add']}
                                    >
                                      <AddButton
                                        classes={
                                          styles['contacts-favorite-tools-add']
                                        }
                                        widthClassName={
                                          styles['contacts-favorite-tools-add']
                                        }
                                        heightClassName={
                                          styles['contacts-favorite-tools-add']
                                        }
                                        onClick={() => setDialogAddTools(true)}
                                        smallFont
                                      >
                                        {mdDown ? (
                                          <div
                                            className={
                                              styles[
                                                'contacts-favorite-tools-button'
                                              ]
                                            }
                                          >
                                            <div>
                                              {t(
                                                'contacts.toolbar.community.add_tool1'
                                              ).toUpperCase()}
                                            </div>
                                            <div>
                                              {t(
                                                'contacts.toolbar.community.add_tool2'
                                              ).toUpperCase()}
                                            </div>
                                          </div>
                                        ) : (
                                          t(
                                            'contacts.toolbar.community.add_tool'
                                          ).toUpperCase()
                                        )}
                                      </AddButton>
                                    </Box>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {/* #TODO delete */}
                                {/* {contactCommunity.tools?.map((tool) => ( */}
                                {communityTools.map((tool) => (
                                  <FavoriteTool
                                    disableCommunityLogo
                                    key={tool.id}
                                    tool={tool}
                                    classes={styles['community-tools-add']}
                                  />
                                ))}
                              </div>
                              <Dialog
                                widthClassName={
                                  styles['contacts-dialog-invite']
                                }
                                open={dialogAddTools}
                                onClose={() => setDialogAddTools(false)}
                                title={t('contacts.toolbar.community.add_tool')}
                              >
                                <AddContactsFavoriteTools
                                  onClose={() => setDialogAddTools(false)}
                                  communityId={contactCommunity.id}
                                  groupId={communityGroup?.id}
                                />
                              </Dialog>
                            </>
                          )}
                      </>
                    )}
                  {/* contacts */}
                  {hasContacts && (
                    <div
                      className={clsx(
                        styles['contacts-main-content'],
                        contentHeightClasses
                      )}
                    >
                      <div
                        className={clsx(
                          selectedContactId
                            ? styles['contacts-main-content-grid-detail']
                            : styles['contacts-main-content-grid-full']
                        )}
                      >
                        {((xxlDown && !activeNav && !selectedContactId) ||
                          !xxlDown) && (
                          <>
                            {(contactsGetMutation.isLoading ||
                              contactsAddMemberMutation.isLoading ||
                              isGroupSettingLoad) && <DataGridLoader />}
                            <DataGridPro
                              autoHeight={contacts.length < 5 ? true : false}
                              checkboxSelection={
                                props.disableSelect ? false : true
                              }
                              classes={gridClassesGet()}
                              // disableSelectionOnClick={
                              //   memberRoleCommunity !== CommunityUserRole.Member
                              //     ? true
                              //     : false
                              // }
                              // disableVirtualization
                              filterModel={filterModel}
                              onFilterModelChange={(newModel: any) => {
                                setFilterModel(newModel);
                              }}
                              localeText={localeTextGet()}
                              rows={props.modal ? localContacts : contacts}
                              columns={columns}
                              components={{
                                // LoadingOverlay: DataGridLoader,
                                Toolbar: ContactsToolbar,
                              }}
                              componentsProps={{
                                toolbar: {
                                  contactCommunity: contactCommunity,
                                  communityGroup: communityGroup,
                                  showAddMember: showAddMember,
                                  showAddGroup: showAddGroup,
                                  account: account,
                                  contacts: contacts,
                                  memberRoleCommunity: memberRoleCommunity,
                                  showInviteUser: showInviteUser,
                                  showToolbar: props.showToolbar,
                                  showFilter: showFilter,
                                  setDialogInvite: setDialogInvite,
                                  setDialogGroupCreate: setDialogGroupCreate,
                                  setDialogAddMember: setDialogAddMember,
                                  setGroupRoomCreateEditCrudState:
                                    setGroupRoomCreateEditCrudState,
                                  setActiveNav: setActiveNav,
                                  quickFilterProps: { debounceMs: 250 },
                                  // csvOptions: { disableToolbarButton: true },
                                  // printOptions: { disableToolbarButton: true },
                                },
                                panel: {
                                  sx: {
                                    top: '-60px !important',
                                    right: '450px !important',
                                    left: 'auto !important',
                                  },
                                },
                              }}
                              // loading={
                              //   contactsGetMutation.isLoading ||
                              //   contactsAddMemberMutation.isLoading
                              // }
                              pinnedColumns={{ right: ['actions'] }}
                              sx={multiElementDataGridSxGet(true)}
                              onCellClick={onCellClick}
                              onSelectionModelChange={
                                onSelectionChange
                                // memberRoleCommunity !== CommunityUserRole.Member
                                //   ? onSelectionChange
                                //   : undefined
                              }
                              selectionModel={selectedUsers}
                            />
                          </>
                        )}
                      </div>
                      {selectedContactId && (
                        <div className={styles['contacts-main-content-detail']}>
                          <ContactDetail
                            heightClass={contentHeightClasses}
                            contactCommunity={contactCommunity}
                            selectedContactId={selectedContactId}
                            contacts={contacts}
                            memberRole={memberRoleCommunity}
                            setContacts={setContacts}
                            addTool={showAddTool}
                            onClose={onDetailClose}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {!hasContacts && (
                    <>
                      {!props.modal && (
                        <div
                          className={clsx(styles['contacts-toolbar-filters'])}
                        >
                          <>
                            <div
                              className={
                                styles['contacts-toolbar-filters-section']
                              }
                            >
                              {contactCommunity && showAddGroup && (
                                <>
                                  {lgDown ? (
                                    <Tooltip
                                      title={
                                        <>
                                          {t(
                                            'contacts.toolbar.community.add_group'
                                          )}
                                        </>
                                      }
                                    >
                                      <IconButton
                                        classes={
                                          styles['contacts-toolbar-button']
                                        }
                                        icon={['fal', 'users-rectangle']}
                                        iconSize="small"
                                        padding="0.5rem 0.75rem"
                                        preset="paper-secondary"
                                        onClick={() => {
                                          setDialogGroupCreate(true);
                                        }}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <IconTextButton
                                      classes={
                                        styles['contacts-toolbar-button']
                                      }
                                      fontSizeClasses={
                                        styles['contacts-toolbar-button-text']
                                      }
                                      icon={['fal', 'plus']}
                                      padding="0.5rem"
                                      preset="paper-secondary"
                                      textTransform="uppercase"
                                      onClick={() => {
                                        setDialogGroupCreate(true);
                                        // setGroupRoomCreateEditCrudState(
                                        //   CrudState.CreateNoMember
                                        // );
                                      }}
                                    >
                                      {t(
                                        'contacts.toolbar.community.add_group'
                                      )}
                                    </IconTextButton>
                                  )}
                                </>
                              )}

                              {(contactCommunity || communityGroup) &&
                                showAddMember && (
                                  <>
                                    {lgDown ? (
                                      <Tooltip
                                        title={
                                          <>
                                            {t(
                                              'contacts.toolbar.group.add_person'
                                            )}
                                          </>
                                        }
                                      >
                                        <IconButton
                                          classes={
                                            styles['contacts-toolbar-button']
                                          }
                                          icon={['far', 'users-medical']}
                                          iconSize="small"
                                          padding="0.5rem 0.75rem"
                                          preset="paper-secondary"
                                          onClick={() => {
                                            setDialogAddMember(true);

                                            // Save original contacts before adding members
                                            localStorage.setItem(
                                              `__store_contacts_${
                                                account?.id
                                              }_${
                                                memberRoleCommunity
                                                  ? memberRoleCommunity
                                                  : communityGroup
                                                  ? communityGroup.id
                                                  : ''
                                              }`,
                                              JSON.stringify(contacts)
                                            );
                                          }}
                                        />
                                      </Tooltip>
                                    ) : (
                                      <IconTextButton
                                        classes={
                                          styles['contacts-toolbar-button']
                                        }
                                        fontSizeClasses={
                                          styles['contacts-toolbar-button-text']
                                        }
                                        icon={['fal', 'plus']}
                                        padding="0.5rem"
                                        preset="paper-secondary"
                                        textTransform="uppercase"
                                        onClick={() => {
                                          setDialogAddMember(true);

                                          // Save original contacts before adding members
                                          localStorage.setItem(
                                            `__store_contacts_${account?.id}_${
                                              memberRoleCommunity
                                                ? memberRoleCommunity
                                                : communityGroup
                                                ? communityGroup.id
                                                : ''
                                            }`,
                                            JSON.stringify(contacts)
                                          );
                                        }}
                                      >
                                        {t('contacts.toolbar.group.add_person')}
                                      </IconTextButton>
                                    )}
                                  </>
                                )}

                              {showInviteUser && (
                                <>
                                  {lgDown ? (
                                    <Tooltip
                                      title={
                                        <>
                                          {t(
                                            'contacts.toolbar.group.invite_person'
                                          )}
                                        </>
                                      }
                                    >
                                      <IconButton
                                        classes={
                                          styles['contacts-toolbar-button']
                                        }
                                        icon={['fal', 'user-plus']}
                                        iconSize="small"
                                        padding="0.5rem 0.75rem"
                                        preset="paper-secondary"
                                        onClick={() => setDialogInvite(true)}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <IconTextButton
                                      classes={
                                        styles['contacts-toolbar-button']
                                      }
                                      fontSizeClasses={
                                        styles['contacts-toolbar-button-text']
                                      }
                                      icon={['fal', 'plus']}
                                      padding="0.5rem"
                                      preset="paper-secondary"
                                      textTransform="uppercase"
                                      onClick={() => setDialogInvite(true)}
                                    >
                                      {t(
                                        'contacts.toolbar.group.invite_person'
                                      )}
                                    </IconTextButton>
                                  )}
                                </>
                              )}

                              {/* {communityGroup && (
                          <>
                            <IconTextButton
                              classes={styles['contacts-toolbar-button']}
                              fontSizeClasses={
                                styles['contacts-toolbar-button-text']
                              }
                              icon={['fal', 'plus']}
                              iconSize={lgDown ? 'inherit' : undefined}
                              padding={lgDown ? '0.5rem 0.75rem' : '0.5rem'}
                              preset="paper-secondary"
                              textTransform="uppercase"
                              onClick={onGroupSettingChange}
                            >
                              {t('app.settings')}
                            </IconTextButton>
                          </>
                        )} */}
                            </div>
                          </>
                        </div>
                      )}

                      <Box className={styles['contacts-main-content-none']}>
                        <Box
                          className={styles['contacts-main-content-none-left']}
                        >
                          {!props.disableImage && (
                            <img
                              src={kontakte_gruppen_teaser}
                              alt={t('contacts.teaser.group')}
                            />
                          )}
                        </Box>
                        {!props.modal && showInviteUser && (
                          <Box
                            className={
                              styles['contacts-main-content-none-right']
                            }
                            onClick={() => {}}
                          >
                            <img
                              src={kontakte_einladen_teaser_left}
                              alt={t('contacts.teaser.invite')}
                            />
                          </Box>
                        )}
                      </Box>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        }
        footerComponent={props.footerComponent}
      />

      <MuiDialog open={dialogInvite} onClose={() => setDialogInvite(false)}>
        <ContactsInvite
          selectedCommunity={contactCommunity ?? undefined}
          selectedGroup={communityGroup ?? undefined}
          contacts={contacts}
          setContacts={setContacts}
          onClose={onInviteClose}
        />
      </MuiDialog>
      <Dialog
        open={dialogGroupCreate}
        onClose={() => setDialogGroupCreate(false)}
        title={
          groupRoomCreateEditCrudState === CrudState.UpdateNoMember
            ? t('newscenter.groups.create_edit.settings.title.edit')
            : t('newscenter.groups.create_edit.settings.title.create')
        }
      >
        <GroupCreateEditSettings
          seletedFolder={selectedFolder}
          selectedCommunity={contactCommunity ?? undefined}
          selectedGroup={communityGroup ?? undefined}
          setCommunityGroup={setCommunityGroup}
          contactCommunity={contactCommunity}
          setContactCommunity={setContactCommunity}
          groupRoomCreateEditCrudState={groupRoomCreateEditCrudState}
          setIsGroupSettingLoad={setIsGroupSettingLoad}
          onClose={() => setDialogGroupCreate(false)}
          onContactsGet={onContactsGet}
        />
      </Dialog>
      <Dialog
        paddingContent="0.5rem"
        classes={styles['contacts-dialog-add-person']}
        widthClassName={styles['contacts-dialog-add-person-width']}
        open={dialogAddMember}
        onClose={() => {
          setDialogAddMember(false);
        }}
        title={t('contacts.toolbar.group.add_person')}
      >
        <ContactsContent
          modal
          disableDeatilView
          include_me
          groupId={communityGroup?.id}
          communityId={contactCommunity?.id}
          heightClasses="h-[calc(100vh-200px)]"
          onUsersSelectionChange={onGroupRoomSelectionChange}
          type={
            ContactsViewType.AddPersonNetwork
            // communityGroup?.id
            //   ? ContactsViewType.Group
            //   : contactCommunity?.id
            //   ? ContactsViewType.Community
            //   : ContactsViewType.Direct
          }
          footerComponent={
            <ContactsContentFooter
              classes={styles['contacts-dialog-add-person-submit']}
              forUsers
              onClick={onContactsAddMember}
              disabled={addMemberSelection.length < 1 || isButtonDisabled}
              submitButtonTitle={t('app.select')}
            />
            // <div className={styles['contacts-dialog-add-person-submit']}>
            //   <div className="flex flex-wrap">
            //     {selectedUsers.map((userId, index) => {
            //       const userIndex = allContacts.findIndex(
            //         (contact) => contact.id === userId
            //       );
            //       if (userIndex > -1) {
            //         const userName = allContacts[userIndex].name;
            //         return (
            //           <RemoveButton
            //             key={index}
            //             classes="mr-1 mb-1"
            //             onRemove={() => onToolUsersSelectionDelete(userId)}
            //             icon={['fal', 'user']}
            //           >
            //             {userName}
            //           </RemoveButton>
            //         );
            //       }
            //     })}
            //   </div>
            //   <TextButton
            //     disabled={addMemberSelection.length < 1 || isButtonDisabled}
            //     preset="primary"
            //     onClick={onContactsAddMember}
            //   >
            //     {t('community.users.add.submit')}
            //   </TextButton>
            // </div>
          }
        />
      </Dialog>
      <Dialog
        open={dialogGroupRoomDelete}
        subtitle={t('newscenter.room.groups.leave.warning')}
        title={t('newscenter.room.groups.close.text')}
        onClose={() => setDialogGroupRoomDelete(false)}
      >
        <FormDelete
          text={t('newscenter.room.groups.close.question')}
          textMatch={t('newscenter.room.groups.close.matches')}
          onClose={() => setDialogGroupRoomDelete(false)}
          onSubmit={onGroupRoomDelete}
        />
      </Dialog>
    </>
  );
});

export default memo(ContactsContent);

type ContactsToolbarProps = {
  account: Account | undefined;
  contacts: Contact[];
  memberRoleCommunity: CommunityUserRole;
  showInviteUser: boolean;
  setDialogInvite: (dialogInvite: boolean) => void;
  contactCommunity: ContactCommunity;
  communityGroup: CommunityGroup | undefined;
  showAddMember: boolean;
  showAddGroup: boolean;
  showToolbar: boolean;
  showFilter: boolean;
  setDialogGroupCreate: Dispatch<SetStateAction<boolean>>;
  setGroupRoomCreateEditCrudState: Dispatch<SetStateAction<CrudState>>;
  setDialogAddMember: Dispatch<SetStateAction<boolean>>;
  setActiveNav: Dispatch<SetStateAction<boolean>>;
};

export const ContactsToolbar = (props: ContactsToolbarProps) => {
  const { lgDown } = useBreakpoints();
  const { t } = useTranslation();
  const { removeHtmlTags } = useNewsCenter();
  // Shared store state
  const [theme] = useSharedStore((state: SharedState) => [state.theme]);

  const filterSx = {
    padding: lgDown ? '0.18rem 0.25rem' : '0.18rem 0.75rem',
    width: lgDown ? '120px' : '250px',
    marginRight: '0.5rem',
    background:
      theme === Theme.Light ? '#f3f6f9 !important' : '#2b2b2b !important',
    border: '0px !important',
    borderRadius: '6px',
    '& .MuiInput-underline:before': {
      borderBottom: '0px !important',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '0px !important',
    },
  };

  const buttonSx = {
    color: 'text.primary',
    backgroundColor: 'bg.card',
    padding: '0',
    minWidth: lgDown ? '33.5px !important' : '38px !important',
    width: lgDown ? '33.5px !important' : '38px !important',
    height: lgDown ? '36px' : '38px',
    '&:hover': { backgroundColor: 'primary.main' },
    '&:hover .icon-text-button-text': { color: 'white' },
    '&:hover .MuiSvgIcon-root': { color: 'white' },
    '& .MuiButton-startIcon': {
      marginRight: '0px !important',
    },
  };

  const apiRef = useGridApiContext();

  const handleExport = () => {
    const csvRows = [];
    const columns = apiRef.current.getAllColumns();
    const headers: string[] = [];

    // Remove column actions and state
    columns.map((column) => {
      if (
        column.field !== 'actions' &&
        column.field !== 'state' &&
        column.field
      ) {
        headers.push(column.field);
      }
    });

    // Add headers
    csvRows.push(headers.join(';'));

    // Add rows
    props.contacts.forEach((contact) => {
      const row = apiRef.current.getRow(contact.id);
      const values = headers.map(
        (column) => `"${removeHtmlTags(row[column])}"`
      );
      csvRows.push(values.join(';'));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Pengueen.csv';
    link.click();
  };

  return (
    <div className={clsx(styles['contacts-toolbar-filters'])}>
      <div className={styles['contacts-toolbar-filters-section']}>
        {lgDown && (
          <Tooltip title={<>{t('contacts.toolbar.show.navigation')}</>}>
            <IconButton
              padding="0.5rem 0.75rem"
              classes={styles['contacts-header-menu']}
              icon={['fas', 'bars']}
              preset="popover"
              onClick={() => props.setActiveNav(true)}
            />
          </Tooltip>
        )}
        <GridToolbarQuickFilter
          sx={{ ...filterSx }}
          className={
            styles['tool-multi-element-content-filters-section-search']
          }
        />
        {props.showToolbar && (
          <>
            {props.contactCommunity && props.showAddGroup && (
              <>
                {lgDown ? (
                  <Tooltip
                    title={<>{t('contacts.toolbar.community.add_group')}</>}
                  >
                    <IconButton
                      classes={styles['contacts-toolbar-button']}
                      icon={['fal', 'users-rectangle']}
                      iconSize="small"
                      padding="0.5rem 0.35rem"
                      preset="paper-secondary"
                      onClick={() => {
                        props.setDialogGroupCreate(true);
                      }}
                    />
                  </Tooltip>
                ) : (
                  <IconTextButton
                    classes={styles['contacts-toolbar-button']}
                    fontSizeClasses={styles['contacts-toolbar-button-text']}
                    icon={['fal', 'plus']}
                    padding="0.5rem"
                    preset="paper-secondary"
                    textTransform="uppercase"
                    onClick={() => {
                      props.setDialogGroupCreate(true);
                      props.setGroupRoomCreateEditCrudState(
                        CrudState.CreateNoMember
                      );
                    }}
                  >
                    {t('contacts.toolbar.community.add_group')}
                  </IconTextButton>
                )}
              </>
            )}
            {(props.contactCommunity || props.communityGroup) &&
              props.showAddMember && (
                <>
                  {lgDown ? (
                    <Tooltip
                      title={<>{t('contacts.toolbar.group.add_person')}</>}
                    >
                      <IconButton
                        classes={styles['contacts-toolbar-button']}
                        icon={['far', 'users-medical']}
                        iconSize="small"
                        padding="0.5rem 0.35rem"
                        preset="paper-secondary"
                        onClick={() => {
                          props.setDialogAddMember(true);

                          // Save original contacts before adding members
                          localStorage.setItem(
                            `__store_contacts_${props.account?.id}_${
                              props.memberRoleCommunity
                                ? props.memberRoleCommunity
                                : props.communityGroup
                                ? props.communityGroup.id
                                : ''
                            }`,
                            JSON.stringify(props.contacts)
                          );
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <IconTextButton
                      classes={styles['contacts-toolbar-button']}
                      fontSizeClasses={styles['contacts-toolbar-button-text']}
                      icon={['fal', 'plus']}
                      padding="0.5rem"
                      preset="paper-secondary"
                      textTransform="uppercase"
                      onClick={() => {
                        props.setDialogAddMember(true);

                        // Save original contacts before adding members
                        localStorage.setItem(
                          `__store_contacts_${props.account?.id}_${
                            props.memberRoleCommunity
                              ? props.memberRoleCommunity
                              : props.communityGroup
                              ? props.communityGroup.id
                              : ''
                          }`,
                          JSON.stringify(props.contacts)
                        );
                      }}
                    >
                      {t('contacts.toolbar.group.add_person')}
                    </IconTextButton>
                  )}
                </>
              )}
            {props.showInviteUser && (
              <>
                {lgDown ? (
                  <Tooltip
                    title={<>{t('contacts.toolbar.group.invite_person')}</>}
                  >
                    <IconButton
                      classes={styles['contacts-toolbar-button']}
                      icon={['fal', 'user-plus']}
                      iconSize="small"
                      padding="0.5rem 0.35rem"
                      preset="paper-secondary"
                      onClick={() => props.setDialogInvite(true)}
                    />
                  </Tooltip>
                ) : (
                  <IconTextButton
                    classes={styles['contacts-toolbar-button']}
                    fontSizeClasses={styles['contacts-toolbar-button-text']}
                    icon={['fal', 'plus']}
                    padding="0.5rem"
                    preset="paper-secondary"
                    textTransform="uppercase"
                    onClick={() => props.setDialogInvite(true)}
                  >
                    {t('contacts.toolbar.group.invite_person')}
                  </IconTextButton>
                )}
              </>
            )}
          </>
        )}
      </div>
      <div className={styles['contacts-toolbar-filters-section']}>
        {props.memberRoleCommunity === CommunityUserRole.Admin && (
          <Tooltip title={t('contacts.toolbar.export')}>
            <IconButton
              padding="0.5rem 0.5rem"
              classes={styles['contacts-toolbar-filters-section-export']}
              icon={['far', 'arrow-down-to-line']}
              preset="popover"
              onClick={handleExport}
            />
          </Tooltip>
        )}
        <GridToolbarFilterButton
          onResize={undefined}
          nonce={undefined}
          onResizeCapture={undefined}
          sx={{ ...buttonSx }}
        />
      </div>
    </div>
  );
};

type ContactsContentFooterProps = {
  classes: string;
  forGruops?: boolean;
  forUsers?: boolean;
  disabled?: boolean;
  submitButtonTitle: TFunctionResult;
  onClick: () => void;
  setDeletedNavItem?: (
    value: React.SetStateAction<ContactNavGroup | undefined>
  ) => void; // for selecting or deleting groups
};

export const ContactsContentFooter = (props: ContactsContentFooterProps) => {
  const { onToolUsersSelectionDelete, onGroupSelectionDelete } = useContacts();
  const { contactsGet } = useContactsHttp();
  const { handleError, handleRetry } = useFetch();
  const { sortContacts } = useContacts();

  const [selectedNavGroups, selectedUsers, setSelectedUsers] = useContactsStore(
    (state) => [
      state.selectedNavGroups,
      state.selectedUsers,
      state.setSelectedUsers,
    ]
  );
  const [allContacts, setAllContacts] = useState<Contact[]>([]);

  // GET all contacts mutation
  const contactsAllGetMutation = useMutation(
    (filters?: ContactsGetFilters) => contactsGet(filters),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        // Handle GET contacts
        if (data) {
          const newRow = sortContacts(data.contacts);
          setAllContacts(newRow);
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            console.error(
              'Error getting all contacts:',
              handleError(errRes.status)
            );
          }
        }
      },
    }
  );

  useEffect(() => {
    contactsAllGetMutation.mutate(undefined);
    return () => {
      setSelectedUsers([]);
    };
  }, []);

  return (
    <div className={props.classes}>
      <div className="flex flex-wrap">
        {props.forGruops &&
          props.setDeletedNavItem &&
          selectedNavGroups.map((group) => {
            return (
              <RemoveButton
                classes="mr-1 mb-1"
                onRemove={() =>
                  onGroupSelectionDelete(group, props.setDeletedNavItem!)
                }
                icon={
                  group.type === ContactCommunityGetAction.Room
                    ? ['fal', 'hashtag']
                    : ['fal', 'users']
                }
              >
                {group.itemName}
              </RemoveButton>
            );
          })}
        {selectedUsers.map((userId) => {
          const userIndex = allContacts.findIndex(
            (contact) => contact.id === userId
          );
          if (userIndex > -1) {
            const userName = allContacts[userIndex].name;
            return (
              <RemoveButton
                classes="mr-1 mb-1"
                onRemove={() => onToolUsersSelectionDelete(userId)}
                icon={['fal', 'user']}
              >
                {userName}
              </RemoveButton>
            );
          }
        })}
      </div>
      <TextButton
        disabled={props.disabled}
        preset="primary"
        onClick={props.onClick}
      >
        {props.submitButtonTitle}
      </TextButton>
    </div>
  );
};
