import { UserTranslations } from '../models/user.translations.interface';

export const userTranslationsEN: UserTranslations = {
  account: {
    notifications: {
      text: 'Demnächst kannst du hier auswählen, wie du deine Informationen erhalten möchtest.',
      title: 'Notification',
      web: 'Web notifications',
      subtitle: '',
      subtitle2: '',
    },
    delete: {
      confirmation: 'Confirmation',
      now: 'Jetzt dein Konto unwiderruflich löschen?',
      subtitle:
        'Dein Konto wird mit allen verbundenen Daten unwiderruflich gelöscht.',
      title: 'Delete my account',
    },
    email: {
      text: 'Nutzung für deinen Log-in. E-Mail wird nicht veröffentlicht.',
      change: {
        confirmation: {
          password: 'Aktuelles Passwort',
          text: 'Bestätige mit deinem aktuellen Passwort.',
        },
        text5: 'Deine neue E-Mail-Adresse ist: ',
        text4:
          'Bitte gebe dein Passwort ein, um deine E-Mail-Adresse zu ändern.',
        text3:
          '2. Du erhältst außerdem eine E-Mail an deine neue E-Mail-Adresse. Bitte bestätige durch Auswahl des Links in der E-Mail, dass du im Besitz dieses E-Mail-Kontos bist.',
        text2:
          '1. Zu deiner Sicherheit verschicken wir eine E-Mail an deine bisherige E-Mail-Adresse. Diese dient zu deiner Information.',
        text1:
          'Um deine E-Mail-Adresse zu ändern, erhältst du zwei E-Mails von uns.',
        subtitle:
          'Dein Konto ist dann nur noch über die neue E-Mail-Adresse erreichbar.',
        title: 'Bestätige die Änderung deiner E-Mail-Adresse',
      },
    },
    language: {
      lng: {
        de: 'German',
        en: 'English',
        it: 'Italian',
      },
      text: 'Wir arbeiten daran, dir weitere Sprachen zur Verfügung zu stellen.',
      title: 'Language',
      validation: 'A language is required.',
    },
    save: {
      request: 'A confirmation email has been sent.',
      success: 'Saving account successful.',
    },
    subtitle: 'Language and notifcations',
    text: 'Einstellungen für dein Konto. Ändere deine Kontoinformationen wie Sprache oder Kommunikationswege.',
    title: 'Account information',
  },
  community: {
    data: {
      form: {
        logo: 'Logo',
        name: {
          placeholder: 'e.g. Community name',
          title: 'Name',
        },
        sector: 'Sector',
      },
      save: {
        success: 'Saving community successful.',
      },
      subtitle: 'Informationen zu deiner Gemeinschaft',
    },
    notification: {
      form: {
        case: 'If',
        user: 'User',
        invite_users: 'Inviting new users',
        invitation_accepted: 'Invitation accepted',
        registration_link: 'Registration via link',
        template: 'Utility templates',
        template_changed: 'Change to resource templates',
        inform: 'Inform',
        receiver: 'receiver',
        community_owner: 'Community owner',
        admin: 'Administrators',
        employees: 'Employees:Inside',
        following_receiver: 'Following recipients',
        submit: 'Apply settings',
      },
      save: {
        success: 'VIEW CHANGES',
      },
      subtitle:
        'Decide what you and your community administrators should be notified about. You can find additional settings in the tool templates.',
      title: 'Notifications',
    },
    license: {
      form: {
        address: 'Rechnungsadresse',
        legal: {
          company: {
            placeholder: 'z.B. Schmidt GmbH',
            title: 'Firmierung',
          },
          title: 'Rechtlich',
          vat_id: {
            placeholder: 'z.B. 079 / 123 / 12347',
            title: 'USt-ID.',
          },
        },
        contact: {
          email: 'Abweichende E-Mail-Adresse',
          title: 'Contact',
        },
      },
      save: {
        success: 'Lizenzänderungen erfolgreich übernommen',
      },
      subtitle:
        'Bei Änderungswünschen, Fragen oder Wunsch zur Kündigung melde dich bitte bei uns unter hilfe@pengueen.de',
      title: 'Lizenz- und Zahlungsangaben',
    },
    license_summary: {
      bill: {
        button_summary: 'Zur Rechnungsübersicht',
        date: 'Nächstes Rechnungsdatum',
        title: 'Hier findest du deine Rechnungsübersicht',
      },
      cost: {
        text: 'pro Lizenz, pro Monat',
        title: 'Your next invoice:',
      },
      number: {
        button_manage: 'Manage licenses',
        manage: {
          licenses: 'Individual Licenses',
          submit: {
            button: 'Update licenses',
            error:
              'Es ist ein Fehler beim Aktualisieren der Lizenzen aufgetreten',
            success: 'Lizenzen erfolgreich aktualisiert',
          },
          subtitle:
            'Füge hier neue Lizenzen hinzu oder entferne Lizenzen aus deinem Plan.',
          summary: {
            text1: ' á {{cost}} € netto.',
            text2: 'Team Licenses',
            text3: 'Rechnungsbetrag, netto',
            text4: 'MwSt.',
            text5: 'Rechnungsbetrag, brutto',
            text6: 'Nächstes Rechnungsdatum: {{date}}',

            title: 'Zusammenfassung',
          },
          text1: 'You can easily increase or decrease the number of licenses.',
          text2:
            'You will receive your next invoice for the licenses on the next due date: MM 20 YYYY.',
          title: 'Verwalte deine Lizenzen',
        },
        text: 'Lizenz(en) in deinem Abonnement zum Stichtag',
        title: 'Current licenses',
        tooltip:
          'Der Stichtag ist immer der 20. eines Monats. Die zu diesem Tag aktiven Lizenzen bilden die Grundlage zur Rechnungsstellung',
      },
      subtitle: 'Here you will find an overview of your completed licenses.',
      title: 'Lizenzübersicht',
      total_gross: 'Gesamtsumme brutto',
      total_net: 'Gesamtsumme netto',
      vat: 'MwSt.',
    },
    subtitle: 'Gebe hier Informationen zu deiner Gemeinschaft an.',
    title: 'Meine Gemeinschaft',
  },
  documents: {
    filters: {
      sort: {
        date: 'Date',
        name: 'Name',
      },
    },
    subtitle: 'Document summary',
    text: 'Ablageort für die wichtigen Dokumente, die du von uns - deinem Pengueen Team - erhältst.',
    title: 'Important documents',
  },
  logout: 'Logout',
  profile: {
    form: {
      address: 'Address',
      availability: {
        title: 'Erreichbarkeit',
        verify: {
          textUnverified: `You don't receive notifications via SMS!`,
          textVerified1: 'Du kannst nun auch unter',
          textVerified2: 'die sichere Anmeldung mit SMS-TAN aktivieren.',
          title: 'Verify your number phone number',
        },
      },
      avatar: 'Profile avatar',
      contact: 'Contact data',
    },
    save: {
      success: 'Saving profile successful.',
    },
    subtitle: 'Settings and more',
    text: 'Die hier angegebenen Daten werden Personen und Gemeinschaften angezeigt, mit denen du dich verbunden hast.',
    title: 'Profile information',
  },
  security: {
    word: {
      title: 'Dein Sicherheits-Satz',
      description: 'kann Klartext sein, wir wollen ja nicht übertreiben.',
      subtitle:
        'Du findest diesen Satz in den E-Mails, die wir dir schicken. So kannst du sicher sein, dass wir der Absender dieser E-Mails sind. ',
      notification: 'Dein Sicherheits-Satz wurde gespeichert.',
    },
    change: {
      error: {
        bad: 'Typed in wrong password.',
        conflict: 'A new password is required.',
        forbidden: 'Changing password is forbidden.',
      },
      passwordCurrent: 'Current password',
      success: 'Changing password succesful!',
      title: 'Change password',
    },
    subtitle: 'Change password',
    tan: {
      activate: {
        form: {
          buttonSubmit: 'Activate secure login with SMS-TAN',
          buttonRequest: 'Request TAN',
          error: {
            incorrect: 'Die von dir eingegebene TAN ist inkorrekt.',
            length: 'Die TAN muss exakt 5 Zeichen lang sein',
          },
          input: 'Input TAN',
          missing: 'You did not receive the TAN?',
          resend: 'Resend TAN',
        },
        subtitle:
          'Fordere eine TAN an und wir senden dir eine SMS mit einer Bestätigungs-TAN an {*********211}.',
        title: 'Aktivieren der sicheren Anmeldung mit SMS-TAN',
      },
      deactivate: {
        submit: 'Deactivate',
        subtitle: 'Deine sichere Anmeldung mit SMS-TAN wird ausgeschaltet.',
        text: `Wenn du die sichere Anmeldung mit SMS-TAN deaktivierst, benötigst du nicht länger eine Bestätigungs-TAN per SMS,
          um dich in deinem Konto anzumelden. Wir empfehlen die Deaktivierung dieser Funktion nicht, da dies die Sicherheit deines Kontos verringert.`,
        title: 'Deaktivieren der sicheren Anmeldung mit SMS-TAN',
      },
      text1:
        'Um die sichere Anmeldung mit SMS-TAN verwenden zu können, musst du deine Handynummer unter',
      text2: 'verifizieren.',
      textActive: `Jedes Mal, wenn du dich in deinem Konto anmelden möchtest, benötigst du dein Passwort und eine Bestätigungs-TAN, die wir dir auf deine Handynummer,
      die du für Benachrichtigungen eingestellt hast, zusenden.`,
      title: 'Secure login with SMS-TAN',
    },
    text: 'Einstellungen für deine Kontosicherheit. Ändere deine Kontoinformationen wie Passwort oder verknüpfte E-Mail Adresse.',
    title: 'Security',
  },
  theme: {
    dark: 'Dark',
    light: 'Light',
    subtitle: 'Configure to display Pengueen in light or dark appearance.',
    title: 'Appearance',
  },
  title: 'User profile',
};
