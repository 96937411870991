import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import { Input } from '../../../../shared/ui/Input/Input';
import { TextButton } from '../../../../shared/ui/TextButton/TextButton';

// Hooks
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useMarket } from '../../hooks/use-market.hook';
import { useMarketHttp } from '../../hooks/use-market-http.hook';

// Models
import {
  MarketTemplateUseFormData,
  MarketTemplateUsePostRequest,
} from '../../models/market.types';
import { ResultState, Option } from '../../../../shared/models/shared.types';

// Stores
import { useSharedStore } from '../../../../shared/stores/use-shared.store';

// Styles
import styles from './MarketTemplateUse.module.scss';
import { Select } from '../../../../shared/ui/Select/Select';
import { useContactsHttp } from '../../../contacts/hooks/use-contacts-http.hook';

type MarketTemplateUseProps = {
  id: string;
  name: string;
  onSubmit: (id: string) => void;
};

export const MarketTemplateUse = (props: MarketTemplateUseProps) => {
  const { handleError, handleRetry } = useFetch();
  const { marketTemplateUseEffect } = useMarket();
  const { marketTemplateUse } = useMarketHttp();
  const { getContactCommunities } = useContactsHttp();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Component state
  const [communityOptions, setCommunityOptions] = useState<Option[]>([]);

  // Shared store state
  const [setNotification] = useSharedStore((state) => [state.setNotification]);

  // #### //
  // FORM //
  // #### //

  // React hook form validation schema
  const validation = yup.object({
    name: yup.string().required(t('market.use.form.name.placeholder')),
  });

  const {
    formState: { errors },
    watch,
    setValue,
  } = useForm<MarketTemplateUseFormData>({
    resolver: yupResolver(validation),
  });
  const watchCommunity = watch('community') ?? '';
  const watchName = watch('name') ?? '';

  // ######### //
  // MUTATIONS //
  // ######### //

  // POST Template use mutation
  const templateUsePostMutation = useMutation(
    (data: MarketTemplateUsePostRequest) => marketTemplateUse(data)
  );

  // Template use mutation
  useEffect(() => {
    if (templateUsePostMutation.data) {
      try {
        setNotification({
          subtitle: t('market.preview.notification.subtitle'),
          title: props.name + t('market.preview.notification.title'),
          state: ResultState.Success,
        });

        if (templateUsePostMutation.data.id) {
          marketTemplateUseEffect(templateUsePostMutation.data);
          templateUsePostMutation.data.id &&
            props.onSubmit(templateUsePostMutation.data.id);
          navigate(`/tools/${templateUsePostMutation.data.id}`);
        } else {
          navigate(`/tools/workbench`);
        }
      } catch (error) {
        console.log('ERROR on using template:', error);
      }
    }
    if (templateUsePostMutation.error) {
      // const errRes = templateUsePostMutation.error?.response;
      // if (errRes) {
      //   handleError(errRes.status);
      // }
    }
    // eslint-disable-next-line
  }, [templateUsePostMutation.data, templateUsePostMutation.error]);

  const getContactCommunitiesMutation = useMutation(
    () => getContactCommunities(),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        const options: Option[] = [
          {
            placeholder: t('contacts.toolbar.invite.community'),
            value: undefined,
          },
        ];

        if (data) {
          const contactCommunities = data;

          for (const community of contactCommunities) {
            options.push({
              placeholder: community.name,
              value: community.id,
            });
          }

          setCommunityOptions(options);
        }
        const errRes = error?.response;

        if (errRes) {
          console.error(
            'Error getting contact communities:',
            handleError(errRes.status)
          );
        }
      },
    }
  );

  // ####### //
  // EFFECTS //
  // ####### //

  // set Contact Communities on mount
  useEffect(() => {
    setCommunityOptions([
      {
        placeholder: t('contacts.toolbar.invite.community'),
        value: undefined,
      },
    ]);
    getContactCommunitiesMutation.mutate();
  }, []);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handler to use template and navigate to workbench
   * @param body: MarketTemplateUseFormData
   */
  const onTemplateUse = useCallback(
    () => {
      if (props.id) {
        templateUsePostMutation.mutate({
          id: props.id,
          body: { name: watchName, community: watchCommunity },
        });
      }
    },
    // eslint-disable-next-line
    [props, watchName, watchCommunity]
  );

  return (
    <form className={styles['market-template-use']}>
      <Input
        label={t('market.use.form.name.label')}
        message={errors?.name && errors.name.message}
        placeholder={t('market.use.form.name.placeholder')}
        state={errors?.name && ResultState.Error}
        onChange={(value: string) => setValue('name', value)}
      />
      {communityOptions.length > 1 && (
        <Select
          classes={styles['market-template-use-community']}
          label={t('market.use.community')}
          value={watchCommunity}
          options={communityOptions}
          onChange={(value) => setValue('community', value)}
        />
      )}
      <TextButton
        classes={styles['market-template-use-submit']}
        preset="primary"
        onClick={() => onTemplateUse()}
      >
        {t('market.use.form.button')}
      </TextButton>
    </form>
  );
};
