// Hooks
import { useFetch } from '../../../shared/hooks/use-fetch.hook';

// Models
import { Favorite, ToolFavorite } from '../models/dashboard.types';

export const useDashboardHttp = () => {
  const { fetchData } = useFetch();

  /**
   * GET Dashboard favorite tools.
   * Component: WidgetFavoriteTools
   * @returns ToolFavorite array
   */
  const favoriteToolsGet = async (): Promise<ToolFavorite[] | undefined> => {
    return await fetchData('dashboard/tools');
  };

  /**
   * GET Dashboard favorite contacts.
   * Component: WidgetFavoriteContacts
   * @returns ContactFavorite array
   */
  const favariteContactsGet = async (): Promise<Favorite | undefined> => {
    return await fetchData('dashboard/contacts');
  };

  return {
    favoriteToolsGet,
    favariteContactsGet,
  };
};
