import { NewsCenterTranslations } from '../models/newscenter.translations.interface';

export const newscenterTranslationsDE: NewsCenterTranslations = {
  network: {
    title: 'Dein Netzwerk',
  },
  godparents: {
    title: 'Fragen zu Pengueen?',
    subtitle1: 'hat dich zu Pengueen eingeladen',
    subtitle2: 'und kann dir sicher helfen.',
  },
  no_chat: {
    private:
      'Noch keine Nachricht geschrieben oder erhalten? Nutzen den blauen Plus-Button und schreibe gleich los.',
    group:
      'Du hast noch keine Gruppen. Leg gleich eine an. Nutzen dafür den blauen Plus-Button',
  },
  file: {
    size: 'Leider ist deine Datei größer als 7 MByte. Wir haben einen Hilfeartikel für dich erstellt, wie du die Dateigröße (im Sinne der Umwelt) reduzieren kannst: ',
    link: 'zum Artikel.',
    type: 'Leider wird dieses Dateiformat aktuell nicht unterstützt.',
  },
  info: {
    join: {
      title: ' hat den Chatraum betreten.',
    },
  },
  remove: {
    text1: ' aus der Gruppe ',
    text2: ' entfernt.',
  },
  invite: {
    text1: ' hat ',
    text2: ' eingeladen.',
    success: '{{userName}} wurde(n) erfolgreich eingeladen',
    fail: '{{userName}} ist(sind) schon im Chatraum.',
  },
  leave: {
    direct: ' hat den Chatraum verlassen.',
  },
  close: {
    group: ' hat diese Gruppe geschlossen',
  },
  favorite: {
    remove: 'Als Favorit entfernen',
    mark: 'Als Favorit kennzeichnen',
  },
  latest: {
    read: 'Favorit.1 gelesen',
    unread: 'Favorit.2 ungelesen',
    title: 'Aktuell',
    show: {
      no: 'Weniger anzeigen',
      group: 'Ältere Gruppen anzeigen',
      direct: 'Ältere Nachrichten anzeigen',
    },
  },
  teaser: {
    new_direct: 'Neue Directnachricht',
    new_group: 'Neue Gruppe',
    image: 'Nachricht Image',
  },
  actions: {
    broadcast: 'Neue Broadcast Nachricht',
    group: 'Neue Gruppe',
    direct: 'Neue Direktnachricht',
    title: 'Neue Nachricht',
  },
  filters: {
    current: {
      title: 'Aktuell',
    },
    group: {
      old_view: 'Ältere Nachrichtengruppen anzeigen',
      communities: 'Gemeinschaften',
      none: 'Keine Gruppierung',
    },
    show: {
      label: 'Gruppen und Nachrichten anzeigen von',
      no_communities:
        'Aktuell sind keine deiner Gruppen-Chats mit einer Gemeinschaft verknüpft',
    },
  },
  groups: {
    create_edit: {
      settings: {
        buttons: {
          create: 'Weiter zu den Teilnehmer:innen',
          edit: 'Gruppeneinstellungen bearbeiten',
        },
        community: {
          error: 'Es muss eine Gemeinschaft ausgewählt werden',
          label: 'Welcher Gemeinschaft soll die Gruppe zugeordnet werden?',
        },
        description: {
          error: 'Eine Gruppenbeschreibung muss angegeben werden',
          label: 'Beschreibe den Zweck der Gruppe in kurzen Worten',
          placeholder: 'Dieses Gruppe kann genutzt werden für ...',
        },
        name: {
          error: 'Ein Gruppenname ist erforderlich',
          label: 'Wie soll deine Gruppe heißen?',
          placeholder: 'z.B. Protokoll',
        },
        message: 'Nachrichtenfunktion für die Mitglieder aktivieren',
        no_include_creator: 'Mich nicht dieser Gruppe hinzufügen',
        folder: 'Soll die Gruppe in einen Ordner gelegt werden?',
        title: {
          create: 'Erstelle deine Gruppe',
          edit: 'Ändere deine Gruppe',
        },
      },
      users: {
        buttons: {
          back: 'Zurück zu den Einstellungen',
          create: 'Gruppe erstellen',
          edit: 'Gruppe editieren',
        },
        title: 'Füge Teilnehmer hinzu',
      },
    },
    old: 'Ältere Nachrichtengruppen anzeigen',
    no_community: 'Keiner Gemeinschaft zugeordnet',
    subtitle: 'Gruppen-Chat',
    title: 'Gruppen',
  },
  placeholder: {
    text1: 'Bitte wähle einen Gruppen-Chat oder eine Direktnachricht aus.',
    text2:
      'Du kannst einen neuen Gruppen-Chat anlegen oder eine neue Direktnachricht verfassen. Klicke dafür oben links auf „Neue Nachricht“.',
  },
  room: {
    direct: {
      delete: {
        subtitle:
          'Bist du dir sicher? Wenn du diese Direktnachricht löschst, werden alle deine Nachrichten unwiderruflich gelöscht!',
        text: 'Jetzt Chat löschen?',
        title: 'Chat löschen',
      },
      message: {
        placeholder: 'Schreibe eine Nachricht...',
      },
      subtitle: 'Chat mit',
      title: 'Private Nachrichten',
      unread_msgs: {
        multi: 'Neue Nachrichten',
        single: 'Neue Nachricht',
      },
      read_msgs: {
        title:
          'Du kannst diese Nachricht nicht mehr bearbeiten, da sie bereits gelesen wurde.',
      },
      add: {
        title: 'Neue Direktnachricht',
      },
    },
    groups: {
      delete: {
        editor: 'Du kannst in dieser Gruppe keine Nachrichten mehr schreiben',
        subtitle:
          'Bist du dir sicher? Wenn du diese Gruppe verlässt, werden alle deine Nachrichten unwiderruflich gelöscht!',
        text: 'Jetzt Gruppe löschen?',
        title: 'Gruppe löschen',
        notification: 'Der Chat erfolgreich gelöscht',
      },
      leave: {
        warning:
          'Bist du dir sicher? Wenn du diese Gruppe schließt, werden alle deine Nachrichten unwiderruflich gelöscht und die Teilnehmer:Innen können sich nicht weiter austauschen!',
        text: 'Gruppe verlassen',
        title: 'Du hast den Chat erfolgreich verlassen',
        question: 'Jetzt Gruppe verlassen',
        matches: 'Verlassen',
      },
      close: {
        text: 'Gruppe schließen',
        title: 'Du hast den Chat erfolgreich geschlossen',
        question: 'Jetzt Gruppe schließen',
        matches: 'Schließen',
      },
      info: 'Willkommen zur Gruppe',
      link: {
        title: 'Gemeinschaft zuordnen',
      },
      tools: {
        part: {
          btn: 'Verbindung trennen',
          match: 'TRENNEN',
          subtitle:
            'Die Gruppe hat dann keinen Zugriff mehr auf das Hilfsmittel',
          text: 'Jetzt Verbindung trennen?',
          title1: 'Möchtest du die Verbindung zu',
          title2: 'trennen?',
        },
        title: 'Verbundene Hilfsmittel',
      },
      users: {
        add: {
          title: 'Teilnehmer:in hinzufügen',
        },
        remove: {
          subtitle:
            'wird aus der Gruppe entfernt und verliert seine / ihre Zugriffsrechte',
        },
        title: 'Teilnehmer:innen',
      },
    },
    message: {
      delete: {
        subtitle:
          'Die Nachricht wird gelöscht und kann nicht wieder hergestellt werden.',
        text: 'Jetzt Nachricht löschen?',
        title: 'Möchtest du diese Nachricht wirklich löschen?',
      },
      edited: 'bearbeitet',
      tooltip: 'Bearbeiten nicht möglich, Nachricht wurde schon gelesen.',
    },
    notifications: {
      title: 'Einmalige Benachrichtigung',
      tooltip:
        'Versendet eine Benachrichtigung per E-Mail oder SMS über eine neue Nachricht. Der Inhalt der Nachricht wird in dieser Benachrichtigung nicht angezeigt.',
    },
    quote: {
      at: 'um',
      title: 'Zitieren',
    },
    typing: {
      double: 'schreiben',
      multi: 'Teilnehmer:innen schreiben',
      single: 'schreibt',
    },
  },
  title: 'Nachrichtencenter',
  write: 'Nachrichten schreiben',
};
