import { GodparentschatTranslations } from '../models/godparentschat.translations.interface';

export const godparentschatTranslationsIT: GodparentschatTranslations = {
  header: {
    title: 'scrivi al tuo padrino di Pengueen',
  },
  chat: {
    content: {
      text1: 'scrivi il tuo primo messagio a',
      text2: '. ',
      text3: 'è il tuo padrino di Pengueen',
      text4:
        'se avessi domande, certamente il tuo padrino di Pengueen puo aiutarti',
    },
  },
  button: {
    answer: 'Rispondere',
  },
};
