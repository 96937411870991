import { HeaderTranslations } from '../models/header.translations.interface';

export const headerTranslationsIT: HeaderTranslations = {
  substitutions: 'la mia sostituzione',
  trial: {
    day: 'giorno',
    days: 'giorni',
    text1: 'solo',
    text2: 'rimasto',
    text3: 'periodo di prova',
  },
};
