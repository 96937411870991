import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Box } from '@mui/material';

// Components
import { Icon } from '../../../../shared/ui/Icon/Icon';
import { ImageFallback } from '../../../../shared/components/ImageFallback/ImageFallback';

// Hooks
import { useNewsCenter } from '../../hooks/use-news-center.hook';

// Models
import { Room, RoomType } from '../../models/news-center.types';
import { ImageFallbackType } from '../../../../shared/models/shared.types';
import { User } from '../../../user/models/user.types';

// Stores
import { UserState, useUserStore } from '../../../user/stores/use-user.store';

// Styles
import styles from './ChatItem.module.scss';
import clsx from 'clsx';
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';
import { Checkbox } from '../../../../shared/ui/Checkbox/Checkbox';
import { ContactNavGroup } from '../../../contacts/models/contacts.types';

type ChatItemProps = {
  active?: boolean;
  directRoom?: Room;
  groupChat?: Room;
  hideCursorPointer?: boolean;
  latestRoom?: Room;
  type: RoomType;
  unread_msgs?: number;
  showCheckbox?: boolean;
  deletedNavItem?: ContactNavGroup | undefined;
  setDeletedNavItem?: Dispatch<SetStateAction<ContactNavGroup | undefined>>;
  onNavItemCheck?: (checked: boolean) => void;
  onClick?: (room: Room, type: RoomType, latestRoom?: boolean) => void;
};

export const ChatItem = (props: ChatItemProps) => {
  const { directRoomChatPartnerGet } = useNewsCenter();

  // Component state
  const [checked, setChecked] = useState(false);
  const [chatPartner, setChatPartner] = useState<Partial<User> | undefined>(
    undefined
  );
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const [inactiveRoom, setInactiveRoom] = useState<boolean>(false);

  // User store state
  const [account] = useUserStore((state: UserState) => [state.account]);

  useEffect(() => {
    const roomName = props.groupChat?.settings?.name
      ? props.groupChat?.settings.name
      : 'Group Chat';

    if (props.deletedNavItem && props.deletedNavItem.itemName === roomName) {
      setChecked(false);
    }
    props.setDeletedNavItem && props.setDeletedNavItem(undefined);

    // eslint-disable-next-line
  }, [props.deletedNavItem]);

  // Set chat partner in direct room
  useEffect(() => {
    if (props.directRoom && props.type === RoomType.Direct) {
      const partner = directRoomChatPartnerGet(
        account?.id ?? '',
        props.directRoom.users
      );
      partner && setChatPartner(partner);
    }

    if (props.latestRoom && props.type === RoomType.Direct) {
      const partner = directRoomChatPartnerGet(
        account?.id ?? '',
        props.latestRoom.users
      );
      partner && setChatPartner(partner);
    }
    // eslint-disable-next-line
  }, [props.directRoom, props.latestRoom, props.type]);

  // Set unread messages count
  useEffect(() => {
    setUnreadMessages(props.unread_msgs ?? 0);
    // eslint-disable-next-line
  }, [props.unread_msgs]);

  useEffect(() => {
    if (
      props.directRoom?.inactive_room ||
      props.groupChat?.inactive_room ||
      props.latestRoom?.inactive_room
    ) {
      setInactiveRoom(true);
    } else {
      setInactiveRoom(false);
    }
  }, [
    props.directRoom?.inactive_room,
    props.groupChat?.inactive_room,
    props.latestRoom?.inactive_room,
  ]);

  /**
   * Handler to navigates to chat.
   */
  const onChatClick = useCallback(() => {
    if (props.onClick && props.groupChat) {
      unreadMessages < 1 && props.onClick(props.groupChat, props.type, false);
      unreadMessages > 0 && props.onClick(props.groupChat, props.type, true);
    }

    if (props.onClick && props.directRoom) {
      unreadMessages < 1 && props.onClick(props.directRoom, props.type, false);
      unreadMessages > 0 && props.onClick(props.directRoom, props.type, true);
    }

    if (props.onClick && props.latestRoom) {
      unreadMessages < 1 && props.onClick(props.latestRoom, props.type, false);
      unreadMessages > 0 && props.onClick(props.latestRoom, props.type, true);
    }

    // eslint-disable-next-line
  }, [props.groupChat, props.directRoom, props.latestRoom]);

  return (
    <Box
      sx={{
        backgroundColor: props.active ? 'bg.card' : 'initial',
        '&:hover': {
          backgroundColor: props.showCheckbox ? undefined : 'bg.hover',
        },
      }}
      onClick={onChatClick}
      className={clsx(
        styles['chat-item'],
        !props.hideCursorPointer && 'cursor-pointer '
      )}
    >
      <Box className={styles['chat-item-content']}>
        {props.showCheckbox && (
          <Checkbox
            checked={checked}
            onToggle={(checked: boolean) => {
              setChecked(checked);
              props.onNavItemCheck && props.onNavItemCheck(checked);
            }}
          />
        )}
        <Box bgcolor="bg.card" className={styles['chat-item-content-image']}>
          {(props.type === RoomType.Group ||
            props.type === RoomType.Latest) && (
            <Icon
              icon={['fal', 'hashtag']}
              size="inherit"
              sx={{ color: 'text.secondary' }}
            />
          )}
          {props.type === RoomType.Direct && chatPartner && (
            <>
              {chatPartner.profile?.avatar?.url ? (
                <img
                  alt={`${chatPartner.profile?.personal_data.first_name} ${chatPartner.profile?.personal_data.last_name}`}
                  className={styles['chat-item-content-image-src']}
                  src={chatPartner.profile?.avatar.url}
                />
              ) : (
                <ImageFallback
                  iconSize="0.8em"
                  sizeClassName={styles['chat-item-content-image-src']}
                  type={ImageFallbackType.Profile}
                />
              )}
            </>
          )}
        </Box>
        <Box
          color="text.secondary"
          className={clsx(
            styles['chat-item-content-name'],
            props.latestRoom && props.unread_msgs && 'font-semibold'
          )}
        >
          {props.latestRoom &&
            props.latestRoom.settings &&
            props.latestRoom.settings.name}
          {props.directRoom &&
            props.directRoom.settings &&
            props.directRoom.settings.name}
          {props.groupChat &&
            props.groupChat.settings &&
            props.groupChat.settings.name}
        </Box>
      </Box>
      {unreadMessages > 0 && !inactiveRoom && (
        <Box
          bgcolor="success.light"
          color="success.main"
          className={clsx(
            styles['chat-item-content-unread'],
            !props.latestRoom && 'font-bold'
          )}
        >
          {unreadMessages}
        </Box>
      )}
      {inactiveRoom && (
        <IconButton
          padding="0px"
          classes={styles['chat-item-content-inactive']}
          sxButton={{
            '& svg': { color: 'warning.main', bgcolor: 'warning.light' },
          }}
          icon={['fal', 'exclamation']}
        />
      )}
    </Box>
  );
};
