import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

// Components
import { Icon } from '../../../../shared/ui/Icon/Icon';
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';

// Hooks
import { useShared } from '../../../../shared/hooks/use-shared.hook';

// Models
import { FilePreview as IFilePreview } from '../../../../shared/models/shared.types';

// Styles
import styles from './FilePreview.module.scss';

type FilePreviewProps = {
  file: File;
  disableDelete?: boolean;
  onDelete: () => void;
};

export const FilePreview = (props: FilePreviewProps) => {
  const { filePreviewByFileGet } = useShared();

  // Component state
  const [filePreview, setFilePreview] = useState<IFilePreview | undefined>(
    undefined
  );

  // Set file preview data on mount
  useEffect(() => {
    props && setFilePreview(filePreviewByFileGet(props.file));
    // eslint-disable-next-line
  }, [props]);

  return (
    <Box
      key={props.file.lastModified}
      sx={{
        backgroundColor: 'bg.card',
      }}
      className={styles['file-preview']}
    >
      {filePreview?.image && (
        <img alt={filePreview.name} src={filePreview.image} />
      )}
      {filePreview?.icon && !filePreview?.image && (
        <Icon
          icon={filePreview.icon}
          sx={{ color: 'text.secondary' }}
          classes={styles['file-preview-icon']}
        />
      )}
      <Box
        sx={{ color: 'text.secondary' }}
        className={styles['file-preview-name']}
      >
        {filePreview?.name}
      </Box>
      {!props.disableDelete && (
        <Box className={styles['file-preview-button']}>
          <IconButton
            icon={['fas', 'trash']}
            iconColor="text.secondary"
            sxIcon={{ height: '0.75rem', width: '0.75rem ' }}
            onClick={props.onDelete}
          />
        </Box>
      )}
    </Box>
  );
};
