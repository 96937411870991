import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Box, Button } from '@mui/material';
import clsx from 'clsx';

// Components
import { Checkbox } from '../../../../shared/ui/Checkbox/Checkbox';
import { H2 } from '../../../../shared/ui/H2/H2';
import { Icon } from '../../../../shared/ui/Icon/Icon';
import { ImageFallback } from '../../../../shared/components/ImageFallback/ImageFallback';
import { H3 } from '../../../../shared/ui/H3/H3';
import { Popover } from '../../../../shared/ui/Popover/Popover';
import { Search } from '../../../../shared/ui/Search/Search';
import { MarketTags } from '../MarketTags/MarketTags';

// Hooks
import { useBreakpoints } from '../../../../shared/hooks/use-breakpoints.hook';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useMarketHttp } from '../../hooks/use-market-http.hook';
import { useMarketMock } from '../../hooks/use-market-mock.hook';
import { useShared } from '../../../../shared/hooks/use-shared.hook';

// Models
import { ImageFallbackType } from '../../../../shared/models/shared.types';
import {
  MarketMenuItem as IMarketMenuItem,
  MarketTemplatesGetParams,
} from '../../models/market.types';
import { TemplateType } from '../../../templates/models/templates.types';

// Stores
import { useMarketStore } from '../../stores/use-market.store';
import { useUserStore } from '../../../user/stores/use-user.store';

// Styles
import styles from './MarketFilters.module.scss';
import pageStyles from '../../../../shared/components/PageLayout/PageLayout.module.scss';

type MarketMenuItemProps = {
  active?: boolean;
  item: IMarketMenuItem;
  onClick: (params?: MarketTemplatesGetParams) => void;
};

const MarketMenuItem = (props: MarketMenuItemProps) => {
  const { fullNameGet } = useShared();

  // Auth store state
  const profile = useUserStore((state) => state.profile);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handler on menu item click.
   * @param type TemplateType
   * @param community_id Community id
   */
  const onMenuItemClick = useCallback(
    (type: TemplateType, id?: string) => {
      switch (type) {
        case TemplateType.Community:
          props.onClick({
            community_id: id,
            template_type: type,
          });
          break;
        default:
          props.onClick({
            template_type: type,
          });
      }
    },
    [props]
  );

  return (
    <Button
      className={styles['market-filters-menu-item']}
      sx={{
        backgroundColor: props.active ? 'bg.card' : 'initial',
      }}
      onClick={() => onMenuItemClick(props.item.type, props.item.id)}
    >
      <Box className={styles['market-filters-menu-item-content']}>
        <Box
          bgcolor="bg.card"
          className={styles['market-filters-menu-item-content-image']}
        >
          {props.item.image ? (
            <img src={props.item.image.url} alt={props.item.name} />
          ) : (
            <ImageFallback
              type={
                props.item.type === TemplateType.Community
                  ? ImageFallbackType.Community
                  : ImageFallbackType.Profile
              }
            />
          )}
        </Box>
        <Box
          color="text.secondary"
          className={styles['market-filters-menu-item-content-name']}
        >
          {props.item.name
            ? props.item.name
            : fullNameGet(
                profile?.personal_data.first_name,
                profile?.personal_data.last_name
              )}
        </Box>
      </Box>
      <Box
        bgcolor="bg.card"
        color="text.secondary"
        className={styles['market-filters-menu-item-content-count']}
      >
        {props.item.total}
      </Box>
    </Button>
  );
};

type MarketFiltersProps = {
  onMenuItemClick: (params?: MarketTemplatesGetParams) => void;
  disableSearch?: boolean;
  public?: boolean;
};

export const MarketFilters = (props: MarketFiltersProps) => {
  const { lgDown } = useBreakpoints();
  const { menuItemsGet } = useMarketMock();
  const navigate = useNavigate();

  const { handleError, handleRetry } = useFetch();
  const { marketMenuGet } = useMarketHttp();
  const { t } = useTranslation();

  // Component state
  const [menuItems, setMenuItems] = useState<IMarketMenuItem[]>([]);
  // const [anchorFilters, setAnchorFilters] = useState<Element | undefined>(
  //   undefined
  // );
  const [anchorTags, setAnchorTags] = useState<Element | undefined>(undefined);

  // Market store state
  const [
    purposeCommunity,
    purposeLiving,
    search,
    selectedMenuItemType,
    templateAll,
    templateNewest,
    templatePopular,
    templateDocument,
    templateProject,
    templateUseOther,
    selectedTags,
    setPurposeCommunity,
    setPurposeLiving,
    setSearch,
    setTemplateAll,
    setTemplateNewest,
    setTemplatePopular,
    setTemplateDocument,
    setTemplateProject,
    setTemplateUseOther,
  ] = useMarketStore((state) => [
    state.purposeCommunity,
    state.purposeLiving,
    state.search,
    state.selectedMenuItemType,
    state.templateAll,
    state.templateNewest,
    state.templatePopular,
    state.templateDocument,
    state.templateProject,
    state.templateUseOther,
    state.selectedTags,
    state.setPurposeCommunity,
    state.setPurposeLivng,
    state.setSearch,
    state.setTemplateAll,
    state.setTemplateNewest,
    state.setTemplatePopular,
    state.setTemplateDocument,
    state.setTemplateProject,
    state.setTemplateUseOther,
  ]);

  // ######### //
  // MUTATIONS //
  // ######### //

  // GET Market menu mutation
  const marketMenuGetMutation = useMutation(() => marketMenuGet(), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
  });

  // Set mock menu items data on mount
  useEffect(() => {
    if (marketMenuGetMutation.data) {
      try {
        setMenuItems(marketMenuGetMutation.data);
      } catch (error) {
        console.log('ERROR on getting menu:', error);
      }
    }
    if (marketMenuGetMutation.error) {
      const errRes = marketMenuGetMutation.error?.response;
      if (errRes) {
        handleError(errRes.status);
      }
      // #TODO: Remove mock data
      setMenuItems(menuItemsGet());
    }

    // eslint-disable-next-line
  }, [marketMenuGetMutation.data, marketMenuGetMutation.error]);

  // Component mount effects
  useEffect(() => {
    if (props.public) {
      //no filters
    } else {
      marketMenuGetMutation.mutate(undefined);
    }
    return () => {
      setSearch('');
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box className={styles['market-drawer']}>
      <Box className={styles['market-drawer-filters']}>
        {!props.disableSearch && (
          <Box className={styles['market-drawer-filters-top']}>
            <Search
              classes={styles['market-drawer-filters-top-search']}
              padding="0.5rem 0.75rem"
              placeholder={t('market.filters.search_detail')}
              value={search}
              onChange={setSearch}
            />
            {/* <Popover
              anchor={anchorTags}
              buttonClasses={clsx(
                styles['filters-button'],
                styles['market-drawer-filters-top-filters-tags']
              )}
              buttonTitle={t('market.tags')}
              contentTitle={t('market.tags')}
              count={selectedTags.length}
              icon={['fas', 'briefcase']}
              onOpen={(anchor) => setAnchorTags(anchor)}
            >
              <MarketTags
                onClose={() => {
                  setAnchorTags(undefined);
                }}
              />
            </Popover> */}
          </Box>
        )}
        <Box className={styles['market-filters-header']}>
          <H2 marginBottom={lgDown ? '0.25rem' : '0.5rem'}>
            {t('tools.title')}
          </H2>
          {menuItems.map((item, index: number) => (
            <MarketMenuItem
              key={index}
              active={selectedMenuItemType === item.type}
              item={item}
              onClick={props.onMenuItemClick}
            />
          ))}
        </Box>
        <H2 marginBottom={lgDown ? '0.25rem' : '0.5rem'}>
          {t('market.use_cases.title')}
        </H2>
        <Checkbox
          checked={templateDocument}
          classes={styles['market-filters-checkbox']}
          content={t('templates.use_cases.documentation')}
          onToggle={(checked) => setTemplateDocument(checked)}
        />
        <Checkbox
          checked={templateProject}
          classes={styles['market-filters-checkbox']}
          content={t('templates.use_cases.project_management')}
          onToggle={(checked) => setTemplateProject(checked)}
        />
        <Checkbox
          checked={templateUseOther}
          classes={styles['market-filters-checkbox']}
          content={t('templates.use_cases.other')}
          onToggle={(checked) => setTemplateUseOther(checked)}
        />

        {/* <H2 marginBottom={lgDown ? '0.25rem' : '0.5rem'}>
        {t('market.levels.title')}
      </H2>
      <Checkbox
        checked={levelBeginner}
        classes="market-filters-checkbox"
        content={t('market.levels.beginner')}
        onToggle={(checked) => setLevelBeginner(checked)}
      />
      <Checkbox
        checked={levelAdvanced}
        classes="market-filters-checkbox"
        content={t('market.levels.advanced')}
        onToggle={(checked) => setLevelAdvanced(checked)}
      />
      <Checkbox
        checked={levelExpert}
        content={t('market.levels.expert')}
        onToggle={(checked) => setLevelExpert(checked)}
      /> */}
      </Box>
      <Box className={pageStyles['page-nav-footer']}>
        {props.public ? (
          ''
        ) : (
          <>
            <Box
              sx={{ borderColor: 'border.strong' }}
              className={pageStyles['page-nav-footer-main']}
            >
              <Box className={pageStyles['page-nav-footer-title']}>
                <H3> {t('market.drawer.footer.title')}</H3>
              </Box>
              <Box
                sx={{ color: 'text.secondary' }}
                className={pageStyles['page-nav-footer-content']}
              >
                {t('market.drawer.footer.content')}
              </Box>
            </Box>
            <Box
              sx={{ color: 'primary.main', backgroundColor: 'primary.light' }}
              className={pageStyles['page-nav-footer-button']}
              onClick={() => navigate('/tools/workbench')}
            >
              <Icon
                classes={pageStyles['page-nav-footer-button-icon']}
                icon={['fas', 'tools']}
                sx={{ color: 'primary.main' }}
              />
              <span>{t('market.drawer.footer.link').toUpperCase()}</span>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
