import { AppTranslations } from '../models/app.translations.interface';

export const appTranslationsEN: AppTranslations = {
  search: 'Search',
  no_results: 'No results found',
  group: 'Gruppieren',
  download: 'Download',
  download_as_csv: 'Download as CSV',
  print: 'Print',
  unfold: 'Ausklappen',
  website: {
    to: 'Zu unserer Webseite',
  },
  data_protection: 'Datenschutz',
  security_info: 'Leitfaden zur Informationssicherheit',
  contact: {
    text1: 'Du hast Fragen oder brauchst Hilfe?',
    text2: 'Melde dich gerne bei uns',
    text3: ', wir können dir sicher weiterhelfen.',
  },
  actions: {
    create: 'Create',
    delete: 'Delete',
    edit: 'Edit',
    title: 'Actions',
  },
  understood: 'Alles klar',
  all: 'All',
  and: 'and',
  apply: 'Apply',
  back: 'back',
  back_to_summary: 'Back to summary',
  cancel: 'Cancel',
  check: 'check',
  coming_soon: 'Coming soon',
  communities: 'Communities',
  employee: 'Employee',
  member: 'Member',
  admin: 'Admin',
  confirm: 'Confirm',
  continue: 'Continue',
  data_grid: {
    rows_selected: {
      multi: 'rows selected',
      single: 'row selected',
    },
  },
  date: {
    date: {
      placeholder: 'Select a date ...',
      title: 'Date',
    },
    date_time: {
      placeholder: 'Select a date and time ...',
      title: 'Date and time',
    },
    time: {
      placeholder: 'Choose a time...',
      title: 'time',
    },
  },
  deselect: 'Deselect',
  email: 'E-Mail',
  emailAddress: 'E-Mail-Address',
  emoji_picker: {
    search: 'Search',
    not_found: 'No Emoji Found',
    categories: {
      search: 'Search Results',
      recent: 'Frequently Used',
      people: 'People & Body',
      nature: 'Animals & Nature',
      foods: 'Food & Drink',
      activity: 'Activity',
      places: 'Travel & Places',
      objects: 'Objects',
      symbols: 'Symbols',
      flags: 'Flags',
      custom: 'Custom',
    },
  },
  employees: 'Employees',
  favorites: 'Favorites',
  fetch: {
    error: {
      fail: 'An error occured.',
      response: 'Network response was not ok.',
      unauthorized: 'Unauthorized',
      unknown: 'Unknown error.',
    },
  },
  file_drop: {
    placeholder1: 'Select an image from your ',
    placeholder2: 'files',
    placeholder3: '.',
  },
  filters: {
    group: 'Group by',
    headline: 'Filter options',
    title: 'Filters',
  },
  groups: 'Gruppen',
  toolLinks: 'Hilfsmittel-Links',
  hello: 'Hello',
  language: 'Language',
  language_text: `Choose the language of the Pengueen interface.`,
  leaflet: 'Leaflet',
  likeMark: 'Mark as favorite',
  members: 'Members',
  name: 'Name',
  nameless: 'Nameless',
  nickname: 'Nickname',
  no: 'No',
  of: 'of',
  ok: 'OK',
  optional: 'optional',
  reset: 'Reset',
  roles: {
    admin: 'Admin',
    employee: 'Employee',
    member: 'Member',
    guest: 'Guest',
  },
  save: 'save',
  select: 'Select',
  selectNoOptions: 'No options',
  settings: 'Settings',
  sms: 'SMS',
  telephone: 'Telephone',
  title: 'Pengueen. So gelingt Gemeinschaft.',
  unlikeMark: 'Unlike favorite',
  users: {
    invite: {
      button: 'Jetzt einladen',
      error: 'Es ist ein Fehler beim Einladen aufgetreten:',
      subtitle1: 'Du kannst mit',
      subtitle2: 'schreiben, nachdem du',
      subtitle3: 'zu deinen Kontakten eingeladen hast',
      success: 'Kontakt eingeladen',
      title: 'Zu meinen Kontakten einladen',
    },
    remove: {
      btn: 'Jetzt entfernen',
      match: 'ENTFERNEN',
      text1: 'Jetzt',
      text2: 'entfernen?',
      title1: 'Möchtest du',
      title2: 'wirklich entfernen?',
    },
  },
  verify: 'verify',
  yes: 'Yes',
  you: 'You',
  yourPengueenTeam: 'Your Pengueen Team',
  error: {
    fileSizeTooSmall: 'File size is too small! ',
    fileSizeToolarge: 'File size is too large! ',
    readerError: 'Problem occured while reading file! ',
    maxLimitExceeded: 'Too many files ',
    minLimitNotReached: 'Not enought files ',
    imageHeightTooBig: 'Die Höhe des Bildes stimmt nicht! ',
    imageWidthTooBig: 'Die Breite des Bildes stimmt nicht! ',
    imageHeightTooSmall: 'Die Höhe des Bildes stimmt nicht! ',
    imageWidthTooSmall: 'Die Breite des Bildes stimmt nicht!',
  },
};
