import { library } from '@fortawesome/fontawesome-svg-core';

// import { fab } from '@fortawesome/free-brands-svg-icons'

// ################## //
//  ICONS             //
// ################## //
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';

// ################## //
// FREE REGULAR ICONS //
// ################## //
import {
  faCircle as farCircle,
  faClock as farClock,
  faUser as farUser,
} from '@fortawesome/free-regular-svg-icons';

// ################ //
// FREE SOLID ICONS //
// ################ //

import {
  faArrowDown as fasArrowDown,
  faArrowUp as fasArrowUp,
  faArrowUpRightFromSquare as fasArrowUpRightFromSquare,
  faBriefcase as fasBriefcase,
  faBuilding as fasBuilding,
  faCheck as fasCheck,
  faCheckCircle as fasCheckCircle,
  faCheckSquare as fasCheckSquare,
  faChevronCircleLeft as fasChevronCircleLeft,
  faChevronCircleRight as fasChevronCircleRight,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faChevronUp as fasChevronUp,
  faClock as fasClock,
  faComments as fasComments,
  faCompress as fasCompress,
  faDownload as fasDownload,
  faEllipsis as fasEllipsis,
  faEllipsisV as fasEllipsisV,
  faExclamationTriangle as fasExclamationTriangle,
  faEye as fasEye,
  faFile as fasFile,
  faFileAudio as fasFileAudio,
  faFileArchive as fasFileArchive,
  faFileCode as fasFileCode,
  faFileExcel as fasFileExcel,
  faFileImage as fasFileImage,
  faFilePdf as fasFilePdf,
  faFilePowerpoint as fasFilePowerpoint,
  faFileSignature as fasFileSignature,
  faFileVideo as fasFileVideo,
  faFileWord as fasFileWord,
  faFilter as fasFilter,
  faFolderOpen as fasFolderOpen,
  faGift as fasGift,
  faHandsHelping as fasHandsHelping,
  faHeart as fasHeart,
  faInfo as fasInfo,
  faInfoCircle as fasInfoCircle,
  faLayerGroup as fasLayerGroup,
  faLink as fasLink,
  faList as fasList,
  faLocationArrow as fasLocationArrow,
  faLongArrowAltLeft as fasLongArrowAltLeft,
  faLongArrowAltRight as fasLongArrowAltRight,
  faMapMarked as fasMapMarked,
  faMessage as fasMessage,
  faMinus as fasMinus,
  faMoon as fasMoon,
  faPen as fasPen,
  faPeopleCarry as fasPeopleCarry,
  faPlus as fasPlus,
  faPlusCircle as fasPlusCircle,
  faRightLong as fasRightLong,
  faSearch as fasSearch,
  faShieldAlt as fasShieldAlt,
  faSliders as fasSliders,
  faSquare as fasSquare,
  faSquareCheck as fasSquareCheck,
  faSun as fasSun,
  faStar as fasStar,
  faStore as fasStore,
  faTag as fasTag,
  faThumbtack as fasThumbtack,
  faTh as fasTh,
  faTimes as fasTimes,
  faTimesCircle as fasTimesCircle,
  faTools as fasTools,
  faTrash as fasTrash,
  faUpRightFromSquare as fasFaUpRightFromSquare,
  faUsers as fasUsers,
} from '@fortawesome/free-solid-svg-icons';

// ################# //
// PRO DUOTONE ICONS //
// ################# //

import {
  faAddressCard as fadAddressCard,
  faAt as fadAt,
  faBallotCheck as fadBallotCheck,
  faBell as fadBell,
  faCalendarDay as fadCalendarDay,
  faCheckSquare as fadCheckSquare,
  faChevronSquareDown as fadChevronSquareDown,
  faCheckCircle as fadCheckCircle,
  faComments as fadComments,
  faFileUpload as fadFileUpload,
  faFontCase as fadFontCase,
  faH1 as fadH1,
  faHashtag as fadHashtag,
  faHundredPoints as fadHundredPoints,
  faImages as fadImages,
  faInfoCircle as fadInfoCircle,
  faInfoSquare as fadInfoSquare,
  faMapMarkedAlt as fadMapMarkedAlt,
  faMessages as fadMessages,
  faPhone as fadPhone,
  faRectangleHistoryCirclePlus as fadRectangleHistoryCirclePlus,
  faScrubber as fadScrubber,
  faSwatchbook as fadSwatchbook,
  faTextWidth as fadTextWidth,
  faThList as fadThList,
  faTimesCircle as fadTimesCircle,
  faToggleOff as fadToggleOff,
  faUser as fadUser,
  faUsers as fadUsers,
} from '@fortawesome/pro-duotone-svg-icons';

// ############### //
// PRO LIGHT ICONS //
// ############### //

import {
  faAddressBook as falAddressBook,
  faAddressCard as falAddressCard,
  faBarsFilter as falBarsFilter,
  faBellExclamation as falBellExclamation,
  faBell as falBell,
  faBookmark as falBookmark,
  faBookOpen as falBookOpen,
  faBuilding as falBuilding,
  faBuildingCircleCheck as falBuildingCircleCheck,
  faCalendarClock as falCalendarClock,
  faCalendarDay as falCalendarDay,
  faCopy as falCopy,
  faCrown as falCrown,
  faDownload as falDownload,
  faDraftingCompass as falDraftingCompass,
  faEdit as falEdit,
  faEnvelope as falEnvelope,
  faExclamation as falExclamation,
  faExclamationSquare as falExclamationSquare,
  faEye as falEye,
  faEyeSlash as falEyeSlash,
  faFilePen as falFilePen,
  faGear as falGear,
  faGlasses as falGlasses,
  faGroupArrowsRotate as falGroupArrowsRotate,
  faHashtag as falHashtag,
  faHeart as falHeart,
  faInfoCircle as falInfoCircle,
  faLayerPlus as falLayerPlus,
  faMessages as falMessages,
  faObjectsColumn as falObjectsColumn,
  faPen as falPen,
  faPlus as falPlus,
  faPrint as falPrint,
  faPrintSlash as falPrintSlash,
  faRectangleHistoryCirclePlus as falRectangleHistoryCirclePlus,
  faRepeat1 as falRepeat1,
  faReply as falReply,
  faScrewdriverWrench as falScrewdriverWrench,
  faShareNodes as falShareNodes,
  faSitemap as falSitemap,
  faSquare as falSquare,
  faSquareKanban as falSquareKanban,
  faStar as falStar,
  faStore as falStore,
  faTableLayout as falTableLayout,
  faThumbtack as falThumbtack,
  faUnlock as falUnlock,
  faUser as falUser,
  faUserGroup as falUserGroup,
  faUserCog as falUserCog,
  faUserCrown as falUserCrown,
  faUserLock as falUserLock,
  faUsers as falUsers,
  faUsersCog as falUsersCog,
  faUsersRays as falUsersRays,
} from '@fortawesome/pro-light-svg-icons';

// ################# //
// PRO REGULAR ICONS //
// ################# //
import {
  faBan as farBan,
  faBellExclamation as farBellExclamation,
  faCalendarDay as farCalendarDay,
  faCampground as farCampground,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faFontCase as farFontCase,
  faGrin as farGrin,
  faHeartRate as farHeartRate,
  faList as farList,
  faPaperclip as farPaperclip,
  faSquarePollVertical as farSquarePollVertical,
  faQuoteRight as farQuoteRight,
  faVideo as farVideo,
} from '@fortawesome/pro-regular-svg-icons';

// ############### //
// PRO SOLID ICONS //
// ############### //
import {
  faAcorn as fasAcorn,
  faBarsFilter as fasBarsFilter,
  faFileCsv as fasFileCsv,
  faHandsHeart as fasHandsHeart,
  faLevelDown as fasLevelDown,
  faPaperPlane as fasPaperPlane,
  faUsersRectangle as fasUsersRectangle,
} from '@fortawesome/pro-solid-svg-icons';

// Add free regular icons to libary
library.add(farCircle, farClock, farUser);
library.add(fas, far, fal, fad);

// Add free solid icons to libary
library.add(
  fasArrowDown,
  fasArrowUp,
  fasArrowUpRightFromSquare,
  fasBriefcase,
  fasBuilding,
  fasCheck,
  fasCheckCircle,
  fasCheckSquare,
  fasChevronCircleLeft,
  fasChevronCircleRight,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasChevronUp,
  fasClock,
  fasComments,
  fasCompress,
  fasDownload,
  fasEllipsis,
  fasEllipsisV,
  fasExclamationTriangle,
  fasEye,
  fasFile,
  fasFileAudio,
  fasFileArchive,
  fasFileCode,
  fasFileExcel,
  fasFileImage,
  fasFilePdf,
  fasFilePowerpoint,
  fasFileSignature,
  fasFileVideo,
  fasFileWord,
  fasFilter,
  fasFolderOpen,
  fasGift,
  fasHandsHelping,
  fasHeart,
  fasInfo,
  fasInfoCircle,
  fasLayerGroup,
  fasLink,
  fasList,
  fasLocationArrow,
  fasLongArrowAltLeft,
  fasLongArrowAltRight,
  fasMapMarked,
  fasMessage,
  fasMinus,
  fasMoon,
  fasPen,
  fasPeopleCarry,
  fasPlus,
  fasPlusCircle,
  fasRightLong,
  fasSearch,
  fasShieldAlt,
  fasSliders,
  fasSquare,
  fasSquareCheck,
  fasSun,
  fasStar,
  fasStore,
  fasTag,
  fasThumbtack,
  fasTh,
  fasTimes,
  fasTimesCircle,
  fasTools,
  fasTrash,
  fasFaUpRightFromSquare,
  fasUsers
);

// Add pro duotone icons to libary
library.add(
  fadAddressCard,
  fadAt,
  fadBallotCheck,
  fadBell,
  fadCalendarDay,
  fadCheckSquare,
  fadChevronSquareDown,
  fadCheckCircle,
  fadComments,
  fadFileUpload,
  fadFontCase,
  fadH1,
  fadHashtag,
  fadHundredPoints,
  fadImages,
  fadInfoCircle,
  fadInfoSquare,
  fadMapMarkedAlt,
  fadMessages,
  fadPhone,
  fadRectangleHistoryCirclePlus,
  fadScrubber,
  fadSwatchbook,
  fadTextWidth,
  fadThList,
  fadTimesCircle,
  fadToggleOff,
  fadUser,
  fadUsers
);

// Add pro light icons to libary
library.add(
  falAddressBook,
  falAddressCard,
  falBarsFilter,
  falBellExclamation,
  falBell,
  falBookmark,
  falBookOpen,
  falBuilding,
  falBuildingCircleCheck,
  falCalendarClock,
  falCalendarDay,
  falCopy,
  falCrown,
  falDownload,
  falDraftingCompass,
  falEdit,
  falEnvelope,
  falExclamation,
  falExclamationSquare,
  falEye,
  falEyeSlash,
  falFilePen,
  falGear,
  falGlasses,
  falGroupArrowsRotate,
  falHashtag,
  falHeart,
  falInfoCircle,
  falLayerPlus,
  falMessages,
  falObjectsColumn,
  falPen,
  falPlus,
  falPrint,
  falPrintSlash,
  falRectangleHistoryCirclePlus,
  falRepeat1,
  falReply,
  falScrewdriverWrench,
  falShareNodes,
  falSitemap,
  falSquare,
  falSquareKanban,
  falStar,
  falStore,
  falTableLayout,
  falThumbtack,
  falUnlock,
  falUser,
  falUserGroup,
  falUserCog,
  falUserCrown,
  falUserLock,
  falUsers,
  falUsersCog,
  falUsersRays
);

// Add pro regular icons to libary
library.add(
  farBan,
  farBellExclamation,
  farCalendarDay,
  farCampground,
  farEye,
  farEyeSlash,
  farFontCase,
  farGrin,
  farHeartRate,
  farList,
  farPaperclip,
  farSquarePollVertical,
  farQuoteRight,
  farVideo
);

// Add pro solid icons to libary
library.add(
  fasAcorn,
  fasBarsFilter,
  fasFileCsv,
  fasHandsHeart,
  fasLevelDown,
  fasPaperPlane,
  fasUsersRectangle
);
