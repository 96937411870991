import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TFunctionResult } from 'i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from '@mui/material';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';

// Components
import { Icon } from '../../ui/Icon/Icon';
import { UserLinkChild } from '../../../modules/user/components/UserLink/UserLink';

// Models
import {
  UserInfoState,
  UserLinkChild as IUserLinkChild,
} from '../../../modules/user/models/user.types';

// Stores
import {
  UserState,
  useUserStore,
} from '../../../modules/user/stores/use-user.store';

// Styles
import styles from './NavLink.module.scss';

type NavLinkProps = {
  active?: boolean;
  chldrn?: IUserLinkChild[];
  classes?: string;
  color?: string;
  drawerItem?: boolean;
  icon: [IconPrefix, IconName];
  link: string;
  subtitle: TFunctionResult;
  state: UserInfoState;
  title: TFunctionResult;
  onClick: () => void;
};

export const NavLink = (props: NavLinkProps) => {
  const navigate = useNavigate();

  // Component state
  const [color, setColor] = useState<string>('primary.main');

  // User store state
  const [setMenu] = useUserStore((state: UserState) => [state.setDrawer]);

  // Set color by user info state on component mount
  useEffect(() => {
    if (props.state === UserInfoState.Community) {
      setColor('secondary.main');
    }
  }, [props]);

  /**
   * Navigate to link.
   */
  const onNavigate = useCallback(() => {
    props.onClick();
    setMenu(false);
    navigate(props.link);
    // eslint-disable-next-line
  }, [props]);

  /**
   * Navigate to child link.
   */
  const onChildClick = useCallback(() => {
    props.onClick();
    setMenu(false);
    // eslint-disable-next-line
  }, [props]);

  return (
    <>
      {props.chldrn && props.chldrn.length > 0 ? (
        <Accordion
          className={styles['user-link-accordion']}
          defaultExpanded
          disableGutters
          elevation={0}
          sx={{
            backgroundColor: props.active ? 'bg.card' : 'transparent',
            '&:hover .MuiAccordionSummary-root': {
              backgroundColor: 'bg.card',
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              <Icon
                classes={styles['user-link-accordion-summary-expand-icon']}
                icon={['fas', 'chevron-down']}
                sx={{
                  color: props.active ? `${color}.main` : 'text.primary',
                }}
              />
            }
            sx={{
              color: props.active ? `${color}.main` : 'text.primary',
              // '&:hover': {
              //   backgroundColor: 'bg.hover',
              // },
              '& .MuiAccordionSummary-content': {
                alignItems: 'center',
                display: 'flex',
                margin: 0,
              },
              '& .MuiButtonBase-root-MuiAccordionSummary-root': {
                padding: 0,
              },
            }}
            className={styles['nav-link-accordion-summary']}
          >
            <Button
              color="inherit"
              // onClick={onNavigate}
              className={clsx(
                styles['nav-link-accordion'],
                props.classes && props.classes
              )}
              sx={{
                '&:hover': { backgroundColor: 'bg.card' },
              }}
            >
              <Icon
                classes={styles['nav-link-icon']}
                icon={props.icon}
                sx={{
                  backgroundColor: props.drawerItem ? 'bg.card' : 'none',
                  color: props.drawerItem ? color ?? 'text.primary' : undefined,
                }}
              />
              <Box className={styles['nav-link-text']}>
                <Box
                  sx={{
                    color: props.active ? color : 'text.primary',
                    // fontSize: smDown ? '2px' : undefined,
                  }}
                  className={clsx(
                    styles['nav-link-text-title'],
                    props.drawerItem &&
                      styles['nav-link-text-title-drawer-item']
                  )}
                >
                  {props.title}
                </Box>
                {props.drawerItem && (
                  <Box
                    sx={{ color: 'text.secondary' }}
                    className={styles['nav-link-text-subtitle']}
                  >
                    {props.subtitle}
                  </Box>
                )}
              </Box>
            </Button>
          </AccordionSummary>
          <AccordionDetails className={styles['user-link-accordion-details']}>
            {props.chldrn.map((child) => (
              <UserLinkChild
                key={child.id}
                child={child}
                color={color}
                link={props.link}
                state={props.state}
                onClick={onChildClick}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Button
          color="inherit"
          onClick={onNavigate}
          className={clsx(styles['nav-link'], props.classes && props.classes)}
        >
          <Icon
            classes={styles['nav-link-icon']}
            icon={props.icon}
            sx={{
              backgroundColor: props.drawerItem ? 'bg.card' : 'none',
              color: props.drawerItem ? color ?? 'text.primary' : undefined,
            }}
          />
          <Box className={styles['nav-link-text']}>
            <Box
              sx={{
                color: props.active ? color : 'text.primary',
                // fontSize: smDown ? '2px' : undefined,
              }}
              className={clsx(
                styles['nav-link-text-title'],
                props.drawerItem && styles['nav-link-text-title-drawer-item']
              )}
            >
              {props.title}
            </Box>
            {props.drawerItem && (
              <Box
                sx={{ color: 'text.secondary' }}
                className={styles['nav-link-text-subtitle']}
              >
                {props.subtitle}
              </Box>
            )}
          </Box>
        </Button>
      )}
    </>
  );
};
