import { TemplatesTranslations } from '../models/templates.translations.interface';

export const templatesTranslationsDE: TemplatesTranslations = {
  input_setting: {
    title: 'Wähle den Eingabetyp',
    select: 'Auswahl des Eingabetyps',
    category: {
      single: 'Einzeiliges Textfeld',
      multi: 'Mehrzeiliger Fließtext',
      number: 'Zahlenfeld',
      number_for: 'Zahlenfeld (für Berechnungen)',
    },
    date: 'Datum',
    date_time: 'Datum und Uhrzeit',
    duration: 'Dauer (hh:mm)',
    time: 'Uhrzeit',
    email: 'E-Mail',
    telephone: 'Telefon Nummer',
    url: 'Url',
    point: 'Tausender Punkt',
    suffix: 'Suffix (z.B. km, Std, ..)',
    set_suffix: 'Syntax im Frontend validieren',
  },
  actions: {
    delete: 'Vorlage löschen',
    delete_check: {
      title: 'Vorlage löschen',
      subtitle:
        'Die Vorlage wird gelöscht und kann nicht wieder hergestellt werden.',
    },
    delete_success: 'Die Hilfsmittelvorlage wurde gelöscht!',
    finish: 'Bearbeitung beenden',
    publish: 'Veröffentlichen',
    no_publish: {
      title: 'Nicht mehr veröffentlichen',
      success: 'Deine Hilfsmittelvorlage ist nun nicht mehr veröffentlicht.',
    },
    print: 'Neue Druckansicht',
    settings: 'Einstellungen',
    update: {
      error: 'Fehler beim Speichern der Hilfsmittelvorlage',
      title: 'Speichern / Aktualisieren',
    },
  },
  elements: {
    create: {
      abort: 'Elementbearbeitung beenden?',
      content: {
        form: {
          label: 'Inhalt',
        },
        subtitle: 'Setze den Inhalt, der angezeigt werden soll',
        title: 'Inhalt',
      },
      headline: {
        form: {
          label: 'Überschrift',
          placeholder: 'z.B. Tagesordnung',
        },
        subtitle: 'Wie soll deine Überschrift lauten?',
        title: 'Überschrift',
      },
      image: {
        subtitle: 'Lade dein Bild hoch, das angezeigt werden soll',
        title: 'Bildupload',
      },
      name: {
        form: {
          description: {
            label: 'Beschreibung (wird unter der Bezeichnung angezeigt)',
            placeholder:
              'Das hier ist eine Beispiel Beschreibung, um dir zu zeigen, wie deine Kategorien aussehen werden. Beschreibungen stehen immer unter der Bezeichnung und über dem Input Element.',
          },
          name: {
            placeholder: 'z.B. Tagesordnung',
            sublabel: 'Bezeichnung (wird oberhalb des Elements angezeigt)',
          },
          help_text: {
            placeholder: 'Beispiele oder weitergehende Erläuterungen.',
            label: 'Hilfetext (blaues Hilfe-Icon neben der Bezeichnung)',
          },
        },
        subtitle: 'Gib deinem Element eine Bezeichnung',
        title: 'Bezeichnung',
      },
      section: {
        form: {
          description: {
            label: 'Beschreibung (wird unter der Bezeichnung angezeigt)',
            placeholder:
              'Das hier ist eine Beispiel Beschreibung, um dir zu zeigen, wie deine Kategorien aussehen werden. Beschreibungen stehen immer unter dem Abschnitt.',
          },
          name: {
            placeholder: 'z.B. Tagesordnung',
            sublabel: 'Bezeichnung (wird oberhalb des Elements angezeigt)',
          },
        },
        subtitle: 'Gib deinem Element eine Bezeichnung',
        title: 'Bezeichnung',
      },
      options: {
        form: {
          add: 'Neue Option hinzufügen',
          color: '+ Farbe',
          placeholder: 'z.B. gering',
        },
        subtitle: 'Definiere die Auswahloptionen, die angezeigt werden sollen',
        title: 'Auswahloptionen',
      },
      vote: {
        form: {
          add: 'Stern hinzufügen',
          remove: 'Stern entfernen',
          placeholder: '',
        },
        subtitle: 'Definiere die Auswahloptionen, die angezeigt werden sollen',
        title: 'Auswahloptionen',
      },
      settings: {
        form: {
          advanced: {
            auto_date: 'Automatisch das aktuelle Datum vorausfüllen',
            auto_select:
              'Automatische Auswahl, wenn User aus einer bestimmten Gruppe',
            connected_contact: 'Diese Element zeigt Gruppen von Person an.',
            date_with_time: 'Zusätzlich eine Zeit hinzufügen',
            label: 'Erweiterte Einstellungen',
            only_edit_own_posts: 'Nur eigene Einträge können bearbeitet werden',
            only_show_employees: 'Zeige nur Mitarbeiter:innen zur Auswahl an',
            only_show_members: 'Zeige nur Mitglieder zur Auswahl an',
            only_show_own_posts:
              'Nur eigene Einträge sind sichtbar (Admin sieht alle)',
            required: 'Dieses Element als verpflichtend kennzeichnen',
            only_image: 'Dieses Element soll nur Bilder erlauben!',
            only_film: 'Dieses Element soll nur Filme erlauben! (Bsp.: .mp4)',
            only_office:
              'Dieses Element soll nur Office-Dateien erlauben! (Bsp.: .docx)',
            only_archive:
              'Dieses Element soll nur Archive erlauben! (Bsp.: .zip)',
            only_one_image: 'Dieses Element soll nur 1 Datei erlauben!',
            small_image:
              'Dieses Element ist nur halb so breit sein (nächstes Element ist daneben)!',
            contact_card: 'Kontakt als Visitenkarte anzeigen',
            vote_change: 'Bewertung änderbar',
            vote_result: 'Nach Bewertung Ergebnis für Abstimmenden anzeigen.',
            n_posts: 'Der Benutzer kann n-Einträge erstellen',
            n_posts_sub:
              '(keine Eingabe oder 0 kann beliebig viele Einträge erstellen)',
            toggle_false_string: 'An/Aus-Schalter Label "Nein"',
            toggle_true_string: 'An/Aus-Schalter Label "Ja"',
            value_show_in_other_tool:
              'Daten für ein anderes Hilfsmittel bereitstellen.',
            show_only_for_admin:
              'Das Element wird nur den Gemeinschaft-Admins angezeigt.',
            only_admin_can_post: 'Nur Admin kann Einträge erstellen.',
            only_admin_can_edit: 'Nur Admin kann bearbeiten.',
            show_at_arccordion_header: 'Element im Akkordeon-Header anzeigen.',
          },
          list: {
            employees: 'Inhalt als Spalte im Netzwerk anzeigen',
            label:
              'Wähle aus, ob der Inhalt des Elements als Spalte im Netzwerk angezeigt werden soll, sobald dieses Hilfsmittel einer Person zugewiesen ist',
            members: 'Inhalt als Spalte bei der Mitgliederliste anzeigen',
          },
          rights: {
            employee: 'Mitarbeiter',
            label: 'Erweiterte Bearbeitungsrechte',
            member: 'Mitglied',
            pengueen_admin: 'Pengueen Admin',
          },
          export: {
            export_with_id: 'ID mit Ausgeben bei export',
            disable_export: 'Element nicht exportieren',
            label: 'Exporteinstellungen',
            sublabel: 'Berücksichtigung dieses Elementes bei einem Export',
          },
        },
        subtitle:
          'Wähle erweiterte Einstellungen und Rechte für dieses Element',
        title: 'Einstellungen und Rechte',
      },
      summary: 'Überprüfen',
      type: {
        subtitle: 'Was für ein Element möchtest du anlegen?',
        title: 'Element',
      },
      title: 'Eigenschaften des neuen Elements',
      edit_title: 'Eigenschaften von {{templateName}}',
      element: 'dem Element',
      view_changes: 'Änderungen ansehen',
    },
    group: {
      error: 'Fehler beim Gruppieren von Elementen:',
      success: 'Elemente zu Mehrfacheintrag gruppiert',
      title: 'Elemente zu Mehrfacheintrag gruppieren',
      tooltip:
        'Wähle mindestens zwei Elemente über die Checkboxen aus, um diese zu einem Mehrfacheintrag zusammen zu fügen.',
    },
    delete: {
      subtitle:
        'Das Element wird gelöscht und kann nicht wieder hergestellt werden.',
      text: 'Jetzt Element löschen?',
      title: 'Element löschen',
      error:
        'Du verwendest diese Hilfsmittelvorlage aktuell. Soll die angelegten Hilfsmittel ebenfalls gelöscht werden?',
    },
    multi_elements: {
      settings: {
        display: 'Standard-Ansicht:',
        elements_admin: {
          label: 'Elemente nur für Admin sichtbar',
          placeholder: 'Auswahl der Elemente',
        },
        elements_visible: {
          label: 'Sichtbare Elemente im Hilfsmittel',
          placeholder: 'Auswahl der Elemente',
        },
        error: 'Fehler beim Speichern der Einstellungen',
        sort_by: {
          label: 'Nach welchem Element soll primär sortiert werden?',
          placeholder: 'Auswahl des Elements',
        },
        sort_order: {
          asc: 'aufsteigend',
          desc: 'absteigend',
          label: 'Sortieren nach',
        },
        title: 'Einstellungen',
      },
    },
    types: {
      favorites: 'Favoriten',
      information_display: {
        headline: {
          subtitle: 'Trenne Elemente optisch mit einer Überschrift',
          title: 'Überschrift',
        },
        image: {
          subtitle: 'Für das Anzeigen von Bildern zur Veranschaulichung',
          title: 'Bild',
        },
        info: {
          subtitle: 'Für Informationen und Beschreibungen',
          title: 'Infotext',
        },
        title: 'Elemente für Informationsdarstellung',
      },
      information_obtain: {
        contact: {
          subtitle: 'Auswahl aus den Kontakten',
          title: 'Kontakt',
        },
        date_picker: {
          subtitle: 'Für Datums und Zeitangaben',
          title: 'Datumsauswahl',
        },
        email: {
          subtitle: 'Für die Eingabe von E-Mail Adressen',
          title: 'E-Mail',
        },
        input: {
          subtitle: 'Ein-, mehrzeilig Syntax (z.B. Datum)',
          title: 'Text- Zahlenfeld',
        },
        number: {
          subtitle: 'Wie ein Textfeld für Zahlen',
          title: 'Zahl',
        },
        reminder: {
          subtitle: 'Für Erinnerungen basierend auf Datum oder Zeit',
          title: 'Erinnerung',
        },
        telephone: {
          subtitle: 'Für die Eingabe von Telefonnummern',
          title: 'Telefon',
        },
        textarea: {
          subtitle: 'Für längere Texte, die über eine Zeile hinaus gehen',
          title: 'Textbereich',
        },
        title: 'Einfache Elemente, um Informationen zu erhalten',
        toggle: {
          subtitle: 'Ja oder Nein? Einfache Entscheidungen',
          title: 'Schalter',
        },
        upload: {
          subtitle: 'Upload von Dateien',
          title: 'Dateien',
        },
      },
      options: {
        checkbox: {
          subtitle: 'Auswahl von ein oder mehreren Optionen',
          title: 'Kontrollkästchen',
        },
        group: {
          subtitle: 'Auswahl einer Gruppe aus dem Netzwerk',
          title: 'Gruppe',
        },
        create_row: {
          subtitle: 'Zum Anzeigen, wer den Eintrag erstellt hat',
          title: 'Ersteller',
        },
        radio_button: {
          subtitle: 'Auswahl von einer Option',
          title: 'Optionsfeld',
        },
        select: {
          subtitle: 'Auswahl von ein oder mehreren Optionen',
          title: 'Auswahlmenü',
        },
        title:
          'Elemente mit benutzerdefinierten Auswahloptionen, um Informationen zu erhalten',
        user: {
          subtitle: 'Für die Auswahl von Nutzern deiner Gemeinschaft',
          title: 'Person / Nutzer',
        },
      },
      vote: {
        star: {
          subtitle: 'Wohl die bekannteste Form der Bewertung',
          title: 'Sterne 1-n',
        },
        decision: {
          subtitle: 'Lasse für einen Eintrag abstimmen',
          title: 'Entscheidung',
        },
        grade: {
          subtitle: 'Jedem Eintrag können 1-n Punkte vergeben werden',
          title: 'Punkte 1-n',
        },
        map: {
          subtitle: 'Abstimmung für jeden Eintrag mit farbigen Karten',
          title: 'Karte',
        },
        title: 'Elemente zur Abstimmung',
      },
    },
  },
  print: {
    form: {
      cover: {
        label: 'Deckblatt',
      },
      name: {
        label: 'Bezeichnung deiner Druckansicht',
        placeholder: 'z.B. Protokoll',
      },
    },
    save: 'Druckansicht speichern',
  },
  publish: {
    alert: 'Vorbereitung zum Teilen beenden?',
    form: {
      button: 'Speichern und veröffentlichen',
      community: {
        choose: 'Wähle eine Gemeinschaft als Absender',
        label:
          'Ich möchte, dass eine meiner Gemeinschaften der Absender der Vorlage wird.',
        description:
          'Beschreibe, was deine Gemeinschaft macht, wie das Hilfsmittel eingesetzt wird.',
        tos: {
          content1: 'Hiermit bestätige ich, dass ich folgende',
          content2: 'Regeln',
          content3:
            'für das Teilen von Hilfsmittelvorlagen unter der Verwendung von Open Source MIT-Lizenz gelesen habe.',
          dialog: {
            text1:
              '„Wenn eine Teilnehmerin oder ein Teilnehmer selbst erstellte Hilfsmittel in den Marktplatz einstellt oder Hilfsmittel (nachfolgend : „Software"), die von anderen Teilnehmerinnen oder Teilnehmern erstellt wurden, aus dem Marktplatz nutzen möchte, so gilt dies unter ausdrücklicher Anerkennung der folgenden Lizenz:',
            text2:
              'Jedem, der eine Kopie dieser Software und der zugehörigen Dokumentationsdateien ( die „Software" ) erhält, wird hiermit kostenlos die Erlaubnis erteil, ohne Einschränkung die Software zu nutzen, einschließlich und ohne Einschränkung der Rechte zum Kopieren, Ändern, Zusammenführen, Veröffentlichen, Verteilen, Unterlizenzieren und / oder Verkaufen von Kopien der Software, und Personen, denen die Software zur Verfügung gestellt wird, dies unter den folgenden Bedingungen zu gestatten:',
            text3:
              'Der obige Urheberrechtshinweis und dieser Genehmigungshinweis müssen in allen Kopien oder wesentlichen Teilen der Software enthalten sein.',
            text4:
              'Die Software wird ohne jegliche ausdrückliche oder stillschweigende Gewährleistung, einschließlich, aber nicht beschränkt auf die Gewährleistung der Marktgängigkeit, der Eignung für einen bestimmten Zweck und der Nichtverletzung von Rechten Dritte, zur Verfügung gestellt . Die Autoren oder Urheberrechtsinhaber sind in keinem Fall haftbar für Ansprüche, Schäden oder andere Verpflichtungen, ob in einer Rechtsstreitigkeit, die sich aus Vertrag, unerlaubter Handlung oder sonstige Gründe stützt, die sich aus, aus oder in Verbindung mit der Software oder der Nutzung oder anderen Verwendungen der Software ergeben.',
            text5:
              'Als Verantwortliche gem. § 7 Abs.1 TMG und § 18 Abs. 1 MStV behalten wir uns vor, Vorschläge für Hilfsmittel ohne Angabe von Gründen von der Veröffentlichung auf unserer Plattform auszuschließen."',
            title: 'Regeln (Open Source Richtlinien- MIT Lizenz)',
          },
          error: 'Den Regeln muss zugestimmt werden',
        },
      },
      description: {
        error: 'Es muss eine Beschreibung vorhanden sein',
        label: 'Beschreibung in kurzen Worten',
        placeholder: 'Diese Hilfsmittelvorlage kann genutzt werden für ...',
      },
      name: {
        error: 'Es muss ein Name eingegeben werden',
        label: 'Name der Hilfsmittelvorlage',
        placeholder: 'z.B. Protokoll',
      },
      screenshot: {
        error: {
          required: 'Es muss ein Screenshot ausgewählt werden',
          too_many: 'Zu viele Screenshots ausgewählt',
        },
        label:
          'Lade ein Bild hoch, welches in der Detailansicht deiner Hilfsmittelvorlage im Marktplatz gezeigt werden soll.',
      },
      types: {
        community: 'Intern (für meine Gemeinschaft)',
        error: 'Es muss eine Community ausgewählt werden.',
        label: 'Für wen soll deine Vorlage veröffentlicht werden?',
        public: 'Mit der Pengueen Community teilen',
        private: 'Intern (nur für mich)',
      },
      use_case: {
        error: 'Es muss ein Anwendungsfall ausgewählt werden',
        label: 'Wo sollen wir deine Vorlage einordnen?',
      },
    },
    notification: {
      subtitle:
        'Wir werden deine Vorlage überprüfen. Du erhältst eine Benachrichtigung, sobald die Vorlage genehmigt wurde.',
      title: 'Erfolgreich zur Überprüfung eingereicht',
    },
    notification_private: {
      subtitle: '',
      title: 'Deine Hilfsmittelvorlage wurde erfolgreich veröffentlicht.',
    },
    subtitle:
      'Veröffentliche deine Hilfsmittelvorlage für dich privat, zur Nutzung innerhalb deiner Gemeinschaft oder um sie mit der Pengueen Community über den Marktplatz zu teilen.',
    title: 'Veröffentliche deine Hilfsmittelvorlage',
  },
  purpose: {
    community: 'Organisation der Gemeinschaft',
    living: 'Für die Lebenswelt',
    title: 'Zweck',
  },
  sections: {
    create: {
      button: 'Anlegen',
      subtitle: 'Gebe deinem Hilfsmittel einen neuen Abschnitt',
      title: 'Neuen Abschnitt hinzufügen',
    },
    delete: {
      note: 'Du kannst diesen Abschnitt noch nicht löschen, da dieser noch Elemente enthält.',
      subtitle:
        'Der Abschnitt wird gelöscht und kann nicht wieder hergestellt werden.',
      text: 'Jetzt Abschnitt löschen?',
      title: 'Abschnitt löschen',
    },
    edit: {
      button: 'Bearbeiten',
      subtitle: 'Bearbeite den Abschnitt',
    },
    elements: {
      delete:
        'Du kannst den Mehrfacheintrag nur löschen, wenn alle zugehörigen Elemente gelöscht sind.',
      add: 'Neues Element hinzufügen',
      example: {
        description: 'Das ist eine Beispielbeschreibung',
        name: 'Beispielfeldname',
      },
    },
    info: `Wir haben dir beispielsweise einen Abschnitt und ein Textfeld angelegt, sodass du sehen kannst, wie diese funktionieren.
      Du kannst diese Elemente gerne bearbeiten oder löschen.`,
    name: {
      label: 'Bezeichnung des Abschnitts',
      placeholder: 'z.B. Protokollthema',
    },
    restricted: {
      label: 'Abschnitt ist nur für den Pengueen Admin sichtbar',
      title: 'Bestimme die Sichtbarkeit des Abschnitts',
    },
  },
  settings: {
    alert: {
      create: 'Hilfsmittelkreierung beenden?',
      edit: 'Hilfsmitteleditierung beenden?',
    },
    description: {
      label: 'Beschreibung in kurzen Worten',
      placeholder: 'Diese Hilfsmittelvorlage kann genutzt werden für …',
      subtitle:
        'Beschreibe für andere Nutzer, um was es in der Hilfsmittelvorlage geht.',
      title: 'Beschreibe, wofür die Hilfsmittelvorlage verwendet werden kann',
    },
    extra: {
      settings: {
        restricted: 'Darf nur durch Gemeinschaft angelegt werden',
        unique: 'Darf nur einmal angelegt und verwendet werden',
      },
      subtitle:
        'Treffe weitere Einstellungen, um die Nutzung des Hilfsmittels zu spezifizieren.',
      title: 'Gebe weitere Einstellungen des Hilfsmittels an',
    },
    icon: {
      label: 'Wähle ein Icon aus',
      subtitle:
        'Über das Icon findest du das Hilfsmittel später schneller wieder.',
      title: 'Wähle ein Icon für deine Hilfsmittelvorlage aus',
    },
    notification: {
      title: 'Benachrichtigungen',
      subtitle:
        'Wer soll später über Änderungen in den angelegten Hilfsmitteln dieser Vorlage informiert werden?',
      case: 'Wenn',
      template_created: 'Hilfsmittel angelegt wird',
      template_changed: 'Hilfsmittel geändert* wird',
      template_changed_explanation:
        '* Benachrichtigungen zu Änderungen an oder in Mehrfacheinträgen konfigurierst du in den Einstellungen des jeweiligen Mehrfacheintrages',
      inform: 'Informiere',
      community_owner: 'Inhaber der Gemeinschaft',
      admins: 'die Administratoren',
      employees: 'die Mitarbeiter:Innen der Gemeinschaft',
      following_receiver: 'folgende Empfänger: ',
      tool_creator: 'Ersteller des Hilfsmittels',
      affected_person: 'betroffene Person**',
      affected_person_explanation:
        '** Die Person(en) oder die Mitglieder einer Gruppe, der/denen dieses Hilfsmittel hinzugefügt wurde; wenn vom Ersteller abweichend.',
      multi_title: 'Einstellungen für Benachrichtigungen',
      multi_subtitle:
        'Wer soll später über neue oder geänderte Mehrfacheinträge oder Änderungen an Mehrfacheinträgen informiert werden?',
      multielement_created: 'Neuer Mehrfacheintrag (auch löschen)',
      multielement_changed: 'Änderung der Inhalte eines Mehrfacheintrages*',
      multielement_creator: 'Ersteller des MFE',
      multielement_affected_person: 'betroffene Person**',
      multielement_changed_explanation:
        '* Nicht vergessen: Markiere diejenigen Elemente des Mehrfacheintrages, deren Veränderung eine Benachrichtigung auslösen soll.',
      multielement_affected_person_explanation:
        '** Die Person, die in dem MFE zugewiesen wurde oder Mitglied einer zugewiesenen Gruppe ist; wenn vom Ersteller abweichend. Nicht vergessen: du musst die betreffenden Elemente, also z.B. das Kontakt-Element noch entsprechend markieren.',
      element:
        'Du kannst Benachrichtigungen auslösen, wenn der Inhalt dieses Elementes verändert wird.',
      element_changed: 'Dieses Element löst Benachrichtigungen aus',
      element_changed_title: 'In Benachrichtigung die Bezeichnung ausgeben',
      element_changed_content: 'In Benachrichtigung den Inhalt / Wert ausgeben',
    },
    interface: {
      title: 'Schnittstelle',
      subtitle:
        'Anbindung einer externen (Web-) Anwendung für den Zugriff auf die Daten dieses Hilfsmittels.',
        token_label: 'Schnittstelle-Token',
        token_placeholder: '8923fbb11deee0877fcb69e01db560a1',
        url_label: 'Schnittstelle-URL',
        url_placeholder: 'https://p4.pengueen.de',
    },
    name: {
      label: 'Name der Hilfsmittelvorlage',
      placeholder: 'z.B. Protokoll',
      subtitle:
        'Gebe deiner Hilfsmittelvorlage eine verständliche Beschreibung.',
      title: 'Wie soll die Hilfsmittelvorlage heißen?',
    },
    purpose: {
      subtitle:
        'Wähle aus, ob das Hilfsmittel für die Organisation deiner Gemeinschaft ist oder für die Lebenswelt eines Menschen.',
      title: 'Für welchen Zweck kann das Hilfsmittel verwendet werden',
    },
    summary: {
      button: 'Änderungen ansehen',
      description: 'Beschreibung',
      extra: 'Einstellungen',
      name: 'Bezeichnung',
      icon: 'Icon',
      save: 'Änderungen übernehmen',
      success: 'Änderungen wurden gespeichert.',
      subtitle: 'Überprüfe deine Angaben.',
      title: {
        create: 'Passt alles?',
        edit: 'Einstellungen bearbeiten',
      },
    },
    title: 'Neue Hilfsmittelvorlage erstellen',
  },
  status: {
    blueprint: 'Entwurf',
    community: 'Für Community',
    published: 'Veröffentlicht',
  },
  use_cases: {
    documentation: 'Dokumentation',
    other: 'Sonstiges',
    project_management: 'Projektmanagement',
  },
  workbench: {
    make: 'Vorlagen erstellen',
    community: 'Hilfsmittelvorlagen für',
    mobile: {
      text: `Unsere Hilfsmittelschmiede und die Erstellung deines eigenen Hilfsmittels ist noch nicht für kleinere Bildschirme oder mobile Endgeräte optimiert.
        Für die best mögliche Erfahrung, Wechsel bitte zu einem breiteren Bildschirm.`,
      title: 'Wechsel auf einen größeren Bildschirm',
    },
    personal: 'Deine Hilfsmittelvorlagen',
    title: 'Werkbank',
    title_page: 'Werkbank',
  },
};
