import { useTranslation } from 'react-i18next';
import { TFunctionResult } from 'i18next';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

import { useMutation } from 'react-query';
import { useTemplatesHttp } from './use-templates-http.hook';
import { useFetch } from '../../../shared/hooks/use-fetch.hook';
import { v4 as uuidv4 } from 'uuid';

// Models
import {
  Template,
  TemplateElementDeleteResponse,
  TemplateElementPostPatchResponse,
  TemplateElementChildDeleteResponse,
  TemplateElementChildPostPatchResponse,
  TemplateElementType,
  TemplateElementTypeButton,
  TemplateSection,
  TemplateSectionDeleteRequestResponse,
  TemplateSectionPostPatchResponse,
  TemplateSettings,
  TemplateUseCase,
  TemplatePrintValues,
  TemplatePrintPostRequest,
  TemplateElementCreateEditState,
  TemplateElement,
  ElementDrafts,
  TemplateStatus,
} from '../models/templates.types';

// Stores
import { useTemplatesStore } from '../stores/use-templates.store';

export const useTemplates = () => {
  const { templateGet, templateGetMarktplatz, templatePublicGet } =
    useTemplatesHttp();
  const { handleRetry } = useFetch();
  const { t } = useTranslation();

  // Templates store state
  const [
    print,
    selectedTemplate,
    toolPreview,
    elementCreateEdit,
    setPrint,
    setElementCreateEditState,
    setElementCreateEditSummaryState,
    setElementCreateEdit,
    setElementCreateEditSteps,
    setSelectedTemplate,
    setToolPreview,
  ] = useTemplatesStore((state) => [
    state.print,
    state.selectedTemplate,
    state.toolPreview,
    state.elementCreateEdit,
    state.setPrint,
    state.setElementCreateEditState,
    state.setElementCreateEditSummaryState,
    state.setElementCreateEdit,
    state.setElementCreateEditSteps,
    state.setSelectedTemplate,
    state.setToolPreview,
  ]);

  /**
   * POST Template by body.
   * @param template Template
   */
  // const templatePostEffect = (template: Template) => {
  //   const updatedTemplates = [...templates];
  //   updatedTemplates.push(template);
  //   setTemplates(updatedTemplates);
  // };

  /**
   * PATCH Template settings by body.
   * @param settings TemplateSettings
   */
  const templateSettingsPatchEffect = (settings: TemplateSettings) => {
    selectedTemplate &&
      setSelectedTemplate({
        ...selectedTemplate,
        settings,
      });
  };

  /**
   * POST Template element by response.
   * @param response TemplateElementPostPatchReponse
   */
  const templateNoPublishEffect = () => {
    if (selectedTemplate) {
      const updatedTemplate = { ...selectedTemplate };
      updatedTemplate.status = [TemplateStatus.Blueprint];
      setSelectedTemplate(updatedTemplate);
    }
  };

  /**
   * POST Template element by response.
   * @param response TemplateElementPostPatchReponse
   */
  const templatePublishEffect = () => {
    if (selectedTemplate) {
      const updatedTemplate = { ...selectedTemplate };
      updatedTemplate.status?.push(TemplateStatus.Published);
      setSelectedTemplate(updatedTemplate);
    }
  };

  /**
   * POST Template element by response.
   * @param response TemplateElementPostPatchReponse
   */
  const templateElementPostEffect = (
    response: TemplateElementPostPatchResponse
  ) => {
    if (selectedTemplate) {
      const updatedTemplate = { ...selectedTemplate };
      const matchedSection = updatedTemplate.sections?.find(
        (section) => section.id === response.section_id
      );
      if (matchedSection) {
        matchedSection.elements?.push(response.element);
        setSelectedTemplateAndToolPreview(updatedTemplate);
      }
    }
  };

  /**
   * PATCH Template element by response.
   * @param response TemplateElementPostPatchResponse
   */
  const templateElementPatchEffect = (
    response: TemplateElementPostPatchResponse
  ) => {
    if (selectedTemplate) {
      const updatedTemplate = { ...selectedTemplate };
      const matchedSection = updatedTemplate.sections?.find(
        (section) => section.id === response.section_id
      );
      if (matchedSection) {
        // Find element by response element id
        let matchedElement = matchedSection.elements?.find(
          (element) => element.id === response.element.id
        );
        // Update matched element
        if (matchedElement) {
          matchedElement.default_value = response.element.default_value;
          matchedElement.description = response.element.description;
          matchedElement.image = response.element.image;
          matchedElement.name = response.element.name;
          matchedElement.help_text = response.element.help_text;
          matchedElement.options = response.element.options;
          matchedElement.settings = response.element.settings;
          matchedElement.vote = response.element.vote;
          matchedElement.permission_edit = response.element.permission_edit;
          matchedElement.permission_view = response.element.permission_view;
          setSelectedTemplateAndToolPreview(updatedTemplate);
        }
      }
      setTimeout(() => {
        scrollToElement(selectedTemplate);
      }, 2000);
    }
  };

  /**
   * DELETE Template element by ids.
   * @param response TemplateElementDeleteResponse
   */
  const templateElementDeleteEffect = (
    response: TemplateElementDeleteResponse
  ) => {
    if (selectedTemplate) {
      const updatedTemplate = { ...selectedTemplate };
      // Find section by response section id
      const matchedSection = updatedTemplate.sections?.find(
        (section) => section.id === response.section_id
      );
      if (matchedSection) {
        // Find element by response element id
        const matchedElementIndex = matchedSection.elements?.findIndex(
          (element) => element.id === response.element_id
        );
        // Remove element from array. Update state
        if (matchedElementIndex !== undefined && matchedElementIndex > -1) {
          matchedSection.elements?.splice(matchedElementIndex, 1);
          setSelectedTemplateAndToolPreview(updatedTemplate);
        }
      }
    }
  };

  /**
   * POST Template element child by response.
   * @param response TemplateElementChildPostPatchResponse
   */
  const templateElementChildPostEffect = (
    response: TemplateElementChildPostPatchResponse
  ) => {
    if (selectedTemplate) {
      const updatedTemplate = { ...selectedTemplate };
      const matchedSection = updatedTemplate.sections?.find(
        (section) => section.id === response.section_id
      );
      if (matchedSection) {
        const matchedElement = matchedSection.elements?.find(
          (element) => element.id === response.element_id
        );
        if (matchedElement) {
          // Add element child
          matchedElement.elements?.push(response.element);
          setSelectedTemplateAndToolPreview(updatedTemplate);
        }
      }
    }
  };

  /**
   * PATCH Template element by response.
   * @param response TemplateElementChildPostPatchResponse
   */
  const templateElementChildPatchEffect = (
    response: TemplateElementChildPostPatchResponse
  ) => {
    if (selectedTemplate) {
      const updatedTemplate = { ...selectedTemplate };
      // Find section by id
      const matchedSection = updatedTemplate.sections?.find(
        (section) => section.id === response.section_id
      );
      if (matchedSection) {
        // Find element by id
        let matchedElement = matchedSection.elements?.find(
          (element) => element.id === response.element_id
        );
        if (matchedElement) {
          // Find element child by response element id (child id)
          let matchedChild = matchedElement.elements?.find(
            (child) => child.id === response.element.id
          );
          // Update matched child
          if (matchedChild) {
            matchedChild.default_value = response.element.default_value;
            matchedChild.description = response.element.description;
            matchedChild.image = response.element.image;
            matchedChild.name = response.element.name;
            matchedChild.help_text = response.element.help_text;
            matchedChild.options = response.element.options;
            matchedChild.settings = response.element.settings;
            matchedChild.vote = response.element.vote;
            matchedChild.permission_edit = response.element.permission_edit;
            matchedChild.permission_view = response.element.permission_view;
            setSelectedTemplateAndToolPreview(updatedTemplate);
          }
        }
      }
    }
  };

  /**
   * swap elements up or down
   * @param arr your current array
   * @param a first index to change
   * @param b second index to change
   * @returns the given array with new order
   */
  const swapArrayElements = (arr: Array<any>, a: any, b: any) => {
    let _arr = [...arr];
    let temp = _arr[a];
    _arr[a] = _arr[b];
    _arr[b] = temp;
    return _arr;
  };

  /**
   * PATCH Template element by response.
   * @param data TemplateElementChildPostPatchResponse
   */
  const templateElementChangePosition = (
    data: TemplateElementChildPostPatchResponse,
    up: boolean,
    multiElementId?: string
  ): Template => {
    if (selectedTemplate) {
      const updatedTemplate = { ...selectedTemplate };
      // Find section by id
      const matchedSection = updatedTemplate.sections?.find(
        (section) => section.id === data.section_id
      );
      if (matchedSection) {
        if (multiElementId) {
          // Find element by id
          const curr_element = matchedSection!.elements?.find(
            (element) => element.id === multiElementId
          );

          if (curr_element) {
            const curr_index = curr_element.elements
              ?.map((element) => element.id)
              .indexOf(data.element.id);

            if (up) {
              curr_element.elements = swapArrayElements(
                curr_element!.elements!,
                curr_index,
                curr_index! - 1
              );
            } else {
              curr_element.elements = swapArrayElements(
                curr_element!.elements!,
                curr_index,
                curr_index! + 1
              );
            }
          }
        } else {
          // Find element by id
          const curr_index = matchedSection.elements
            ?.map((element) => element.id)
            .indexOf(data.element.id);

          if (up) {
            if (curr_index && curr_index > 0) {
              matchedSection.elements = swapArrayElements(
                matchedSection!.elements!,
                curr_index,
                curr_index! - 1
              );
            } else if (
              curr_index === 0 &&
              Array.isArray(matchedSection.elements)
            ) {
              updatedTemplate.sections = moveElementBetweenSections(
                curr_index,
                matchedSection.id,
                up
              );
            }
          } else {
            if (
              matchedSection.elements &&
              curr_index === matchedSection.elements.length - 1
            ) {
              updatedTemplate.sections = moveElementBetweenSections(
                curr_index,
                matchedSection.id,
                up
              );
            } else {
              matchedSection.elements = swapArrayElements(
                matchedSection!.elements!,
                curr_index,
                curr_index! + 1
              );
            }
          }
        }

        setSelectedTemplateAndToolPreview(updatedTemplate);
        return updatedTemplate;
      }
    }

    return {
      id: '',
      create_change_info: { changed_at: '', created_at: '', created_by: {} },
      preview_id: '',

      sections: [],
      settings: {
        description: '',
        extra: { restricted: false, unique: false },
        name: '',
        icon: { id: '', url: '' },
        purpose: undefined,
      },
    };
  };

  const moveElementBetweenSections = (
    elementIndex: number,
    sectionId: string,
    up: boolean
  ) => {
    console.log('moveElementBetweenSections');

    if (!selectedTemplate || !selectedTemplate.sections) {
      return;
    }

    const currentSectionIndex = selectedTemplate.sections.findIndex(
      (section) => section.id === sectionId
    );

    if (
      currentSectionIndex < 0 ||
      currentSectionIndex >= selectedTemplate.sections.length
    ) {
      return;
    }

    const currentSection = selectedTemplate.sections[currentSectionIndex];

    if (!currentSection.elements || currentSection.elements.length === 0) {
      return;
    }

    let sections;

    if (up && currentSectionIndex > 0) {
      const previousSection =
        selectedTemplate.sections[currentSectionIndex - 1];

      if (elementIndex !== 0) {
        return;
      }

      const elementToMove = currentSection.elements[0];
      sections = [
        ...selectedTemplate.sections.slice(0, currentSectionIndex - 1),
        {
          ...previousSection,
          elements: [...(previousSection.elements || []), elementToMove],
        },
        {
          ...currentSection,
          elements: currentSection.elements.slice(1),
        },
        ...selectedTemplate.sections.slice(currentSectionIndex + 1),
      ];
    } else if (
      !up &&
      currentSectionIndex < selectedTemplate.sections.length - 1
    ) {
      const nextSection = selectedTemplate.sections[currentSectionIndex + 1];

      if (elementIndex !== currentSection.elements.length - 1) {
        return;
      }

      const elementToMove = currentSection.elements[elementIndex];

      sections = [
        ...selectedTemplate.sections.slice(0, currentSectionIndex),
        {
          ...currentSection,
          elements: currentSection.elements.slice(0, -1),
        },
        {
          ...nextSection,
          elements: [elementToMove, ...(nextSection.elements || [])],
        },
        ...selectedTemplate.sections.slice(currentSectionIndex + 2),
      ];
    }
    return sections ?? [];
  };

  /**
   * DELETE Template element child by ids.
   * @param response TemplateElementDeleteResponse
   */
  const templateElementChildDeleteEffect = (
    response: TemplateElementChildDeleteResponse
  ) => {
    if (selectedTemplate) {
      const updatedTemplate = { ...selectedTemplate };
      // Find section by response section id
      const matchedSection = updatedTemplate.sections?.find(
        (section) => section.id === response.section_id
      );
      if (matchedSection) {
        // Find element by response element id
        const matchedElement = matchedSection.elements?.find(
          (element) => element.id === response.element_id
        );
        if (matchedElement) {
          // Find child index by response child id
          const matchedChildIndex = matchedElement.elements?.findIndex(
            (child) => child.id === response.child_id
          );
          // Remove child from array. Update state
          if (matchedChildIndex !== undefined && matchedChildIndex > -1) {
            matchedElement.elements?.splice(matchedChildIndex, 1);
            setSelectedTemplateAndToolPreview(updatedTemplate);
          }
        }
      }
    }
  };

  /**
   * GET Template element icon by type.
   * @param type TemplateElementType
   * @returns Template element icon
   */
  const templateElementIconByTypeGet = (
    type: TemplateElementType
  ): [IconPrefix, IconName] => {
    switch (type) {
      case TemplateElementType.DataElementFromOtherTool:
        return ['fal', 'file-chart-column'];
      case TemplateElementType.PersonFieldWithDataFromOtherTool:
        return ['fal', 'calendar-day'];
      case TemplateElementType.DataFromOtherTool:
        return ['fal', 'calendar-day'];
      case TemplateElementType.Checkbox:
        return ['fad', 'check-square'];
      case TemplateElementType.Contact:
        return ['fad', 'address-card'];
      // case TemplateElementType.Day:
      // case TemplateElementType.DatePicker:
      //   return ['fad', 'calendar-day'];
      case TemplateElementType.Decision:
        return ['fad', 'ballot-check'];
      // case TemplateElementType.EMail:
      //   return ['fad', 'at'];
      case TemplateElementType.Grade:
        return ['fad', 'hundred-points'];
      case TemplateElementType.Group:
        return ['fad', 'users'];
      case TemplateElementType.Headline:
        return ['fad', 'h1'];
      case TemplateElementType.Info:
        return ['fad', 'info-square'];
      case TemplateElementType.Image:
        return ['fad', 'images'];
      case TemplateElementType.Map:
        return ['fad', 'swatchbook'];
      case TemplateElementType.MultiElement:
        return ['fad', 'rectangle-history-circle-plus'];
      // case TemplateElementType.Number:
      //   return ['fad', 'hashtag'];
      case TemplateElementType.RadioButton:
        return ['fad', 'scrubber'];
      case TemplateElementType.Reminder:
        return ['fad', 'bell'];
      case TemplateElementType.Select:
        return ['fad', 'chevron-square-down'];
      case TemplateElementType.Star:
        return ['fas', 'star'];
      // case TemplateElementType.Telephone:
      //   return ['fad', 'phone'];
      // case TemplateElementType.Textarea:
      //   return ['fad', 'text-width'];
      case TemplateElementType.Toggle:
        return ['fad', 'toggle-off'];
      case TemplateElementType.Upload:
        return ['fad', 'file-upload'];
      case TemplateElementType.User:
        return ['fad', 'user'];
      // case TemplateElementType.Time:
      //   return ['far', 'clock'];
      // case TemplateElementType.Url:
      //   return ['far', 'globe'];
      case TemplateElementType.MultiElementCreateRow:
        return ['fad', 'user'];
      default:
        return ['fad', 'font-case'];
    }
  };

  /**
   * GET Template element icon by type.
   * @param type TemplateElementType
   * @returns Template element icon
   */
  const templateElementLabelShow = (type: TemplateElementType): boolean => {
    switch (type) {
      case TemplateElementType.Day:
      case TemplateElementType.DatePicker:
      case TemplateElementType.EMail:
      case TemplateElementType.Input:
      case TemplateElementType.Number:
      case TemplateElementType.Telephone:
      case TemplateElementType.Textarea:
      case TemplateElementType.Time:
      case TemplateElementType.Url:
        return true;
      default:
        return false;
    }
  };
  /**
   * Returns template element type favorite buttons array.
   * @returns Template element type favorite buttons array.
   */
  const templateElementTypeFavoriteButtonsGet =
    (): TemplateElementTypeButton[] => {
      return [
        {
          icon: ['fad', 'font-case'],
          subtitle: t(
            'templates.elements.types.information_obtain.input.subtitle'
          ),
          title: t('templates.elements.types.information_obtain.input.title'),
          type: TemplateElementType.Input,
        },
        {
          icon: ['fad', 'chevron-square-down'],
          subtitle: t('templates.elements.types.options.select.subtitle'),
          title: t('templates.elements.types.options.select.title'),
          type: TemplateElementType.Select,
        },
        {
          icon: ['fad', 'address-card'],
          subtitle: t(
            'templates.elements.types.information_obtain.contact.subtitle'
          ),
          title: t('templates.elements.types.information_obtain.contact.title'),
          type: TemplateElementType.Contact,
        },
        {
          icon: ['fad', 'file-upload'],
          subtitle: t(
            'templates.elements.types.information_obtain.upload.subtitle'
          ),
          title: t('templates.elements.types.information_obtain.upload.title'),
          type: TemplateElementType.Upload,
        },
      ];
    };

  /**
   * Returns template element type information display buttons array.
   * @returns Template element type information display buttons array.
   */
  const templateElementTypeInformationDisplayButtonsGet =
    (): TemplateElementTypeButton[] => {
      return [
        {
          icon: templateElementIconByTypeGet(TemplateElementType.Headline),
          subtitle: t(
            'templates.elements.types.information_display.headline.subtitle'
          ),
          title: t(
            'templates.elements.types.information_display.headline.title'
          ),
          type: TemplateElementType.Headline,
        },
        {
          icon: templateElementIconByTypeGet(TemplateElementType.Info),
          subtitle: t(
            'templates.elements.types.information_display.info.subtitle'
          ),
          title: t('templates.elements.types.information_display.info.title'),
          type: TemplateElementType.Info,
        },
        {
          icon: templateElementIconByTypeGet(TemplateElementType.Image),
          subtitle: t(
            'templates.elements.types.information_display.image.subtitle'
          ),
          title: t('templates.elements.types.information_display.image.title'),
          type: TemplateElementType.Image,
        },
      ];
    };

  /**
   * Returns template element type information obtain buttons array.
   * @returns Template element type information obtain buttons array.
   */
  const templateElementTypeInformationObtainButtonsGet =
    (): TemplateElementTypeButton[] => {
      return [
        {
          icon: templateElementIconByTypeGet(TemplateElementType.Toggle),
          subtitle: t(
            'templates.elements.types.information_obtain.toggle.subtitle'
          ),
          title: t('templates.elements.types.information_obtain.toggle.title'),
          type: TemplateElementType.Toggle,
        },
        {
          icon: templateElementIconByTypeGet(TemplateElementType.Upload),
          subtitle: t(
            'templates.elements.types.information_obtain.upload.subtitle'
          ),
          title: t('templates.elements.types.information_obtain.upload.title'),
          type: TemplateElementType.Upload,
        },
        {
          icon: templateElementIconByTypeGet(TemplateElementType.Reminder),
          subtitle: t(
            'templates.elements.types.information_obtain.reminder.subtitle'
          ),
          title: t(
            'templates.elements.types.information_obtain.reminder.title'
          ),
          type: TemplateElementType.Reminder,
        },
        {
          icon: templateElementIconByTypeGet(TemplateElementType.Contact),
          subtitle: t(
            'templates.elements.types.information_obtain.contact.subtitle'
          ),
          title: t('templates.elements.types.information_obtain.contact.title'),
          type: TemplateElementType.Contact,
        },
      ];
    };

  /**
   * Returns template element input type array.
   * @returns Template element input type array.
   */
  const templateElementInputTypesGet = (): TemplateElementTypeButton[] => {
    return [
      {
        icon: templateElementIconByTypeGet(TemplateElementType.Input),
        subtitle: t(
          'templates.elements.types.information_obtain.input.subtitle'
        ),
        title: t('templates.input_setting.category.single'),
        type: TemplateElementType.Input,
      },

      {
        icon: templateElementIconByTypeGet(TemplateElementType.Toggle),
        subtitle: t(
          'templates.elements.types.information_obtain.toggle.subtitle'
        ),
        title: t('templates.elements.types.information_obtain.toggle.title'),
        type: TemplateElementType.Toggle,
      },
      {
        icon: templateElementIconByTypeGet(TemplateElementType.Upload),
        subtitle: t(
          'templates.elements.types.information_obtain.upload.subtitle'
        ),
        title: t('templates.elements.types.information_obtain.upload.title'),
        type: TemplateElementType.Upload,
      },
      {
        icon: templateElementIconByTypeGet(TemplateElementType.Reminder),
        subtitle: t(
          'templates.elements.types.information_obtain.reminder.subtitle'
        ),
        title: t('templates.elements.types.information_obtain.reminder.title'),
        type: TemplateElementType.Reminder,
      },
      {
        icon: templateElementIconByTypeGet(TemplateElementType.Contact),
        subtitle: t(
          'templates.elements.types.information_obtain.contact.subtitle'
        ),
        title: t('templates.elements.types.information_obtain.contact.title'),
        type: TemplateElementType.Contact,
      },
      {
        icon: templateElementIconByTypeGet(TemplateElementType.Textarea),
        subtitle: t(
          'templates.elements.types.information_obtain.textarea.subtitle'
        ),
        title: t('templates.input_setting.category.multi'),
        type: TemplateElementType.Textarea,
      },
      {
        icon: templateElementIconByTypeGet(TemplateElementType.DatePicker),
        subtitle: t(
          'templates.elements.types.information_obtain.date_picker.subtitle'
        ),
        title: t('templates.input_setting.date_time'),
        type: TemplateElementType.DatePicker,
      },
      {
        icon: templateElementIconByTypeGet(TemplateElementType.Day),
        subtitle: t(
          'templates.elements.types.information_obtain.date_picker.subtitle'
        ),
        title: t('templates.input_setting.date'),
        type: TemplateElementType.Day,
      },
      {
        icon: templateElementIconByTypeGet(TemplateElementType.Telephone),
        subtitle: t(
          'templates.elements.types.information_obtain.telephone.subtitle'
        ),
        title: t('templates.input_setting.telephone'),
        type: TemplateElementType.Telephone,
      },
      {
        icon: templateElementIconByTypeGet(TemplateElementType.Url),
        subtitle: t(
          'templates.elements.types.information_obtain.email.subtitle'
        ),
        title: t('templates.input_setting.url'),
        type: TemplateElementType.Url,
      },
      {
        icon: templateElementIconByTypeGet(TemplateElementType.Time),
        subtitle: t(
          'templates.elements.types.information_obtain.telephone.subtitle'
        ),
        title: t('templates.input_setting.time'),
        type: TemplateElementType.Time,
      },
      {
        icon: templateElementIconByTypeGet(TemplateElementType.Number),
        subtitle: t(
          'templates.elements.types.information_obtain.number.subtitle'
        ),
        title: t('templates.input_setting.category.number'),
        type: TemplateElementType.Number,
      },
      {
        icon: templateElementIconByTypeGet(TemplateElementType.EMail),
        subtitle: t(
          'templates.elements.types.information_obtain.email.subtitle'
        ),
        title: t('templates.elements.types.information_obtain.email.title'),
        type: TemplateElementType.EMail,
      },
    ];
  };

  /*
   * Returns template element input type array.
   * @returns Template element input type array.
   */
  const templateElementTypesTitleGet = (
    type: TemplateElementType
  ): string | TFunctionResult => {
    switch (type) {
      case TemplateElementType.Day:
        return t('app.date.date.title');
      case TemplateElementType.DatePicker:
        return t(
          'templates.elements.types.information_obtain.date_picker.title'
        );
      case TemplateElementType.EMail:
        return t('templates.input_setting.email');
      case TemplateElementType.Input:
        return t('templates.input_setting.category.single');

      case TemplateElementType.Number:
        return t('templates.input_setting.category.number');
      case TemplateElementType.Telephone:
        return t('templates.elements.types.information_obtain.telephone.title');
      case TemplateElementType.Textarea:
        return t('templates.input_setting.category.multi');
      case TemplateElementType.Time:
        return t('templates.input_setting.time');
      case TemplateElementType.Url:
        return t('templates.input_setting.url');
      default:
        return '';
    }
  };

  /**
   * Returns template element type option buttons array.
   * @returns Template element type option buttons array.
   */
  const templateElementTypeOptionButtonsGet =
    (): TemplateElementTypeButton[] => {
      return [
        {
          icon: templateElementIconByTypeGet(TemplateElementType.Checkbox),
          subtitle: t('templates.elements.types.options.checkbox.subtitle'),
          title: t('templates.elements.types.options.checkbox.title'),
          type: TemplateElementType.Checkbox,
        },
        {
          icon: templateElementIconByTypeGet(TemplateElementType.RadioButton),
          subtitle: t('templates.elements.types.options.radio_button.subtitle'),
          title: t('templates.elements.types.options.radio_button.title'),
          type: TemplateElementType.RadioButton,
        },
        {
          icon: templateElementIconByTypeGet(TemplateElementType.Select),
          subtitle: t('templates.elements.types.options.select.subtitle'),
          title: t('templates.elements.types.options.select.title'),
          type: TemplateElementType.Select,
        },
        {
          icon: templateElementIconByTypeGet(TemplateElementType.User),
          subtitle: t('templates.elements.types.options.user.subtitle'),
          title: t('templates.elements.types.options.user.title'),
          type: TemplateElementType.User,
        },
        {
          icon: templateElementIconByTypeGet(TemplateElementType.Group),
          subtitle: t('templates.elements.types.options.group.subtitle'),
          title: t('templates.elements.types.options.group.title'),
          type: TemplateElementType.Group,
        },
        {
          icon: templateElementIconByTypeGet(
            TemplateElementType.MultiElementCreateRow
          ),
          subtitle: t('templates.elements.types.options.create_row.subtitle'),
          title: t('templates.elements.types.options.create_row.title'),
          type: TemplateElementType.MultiElementCreateRow,
        },
        // {
        //   icon: templateElementIconByTypeGet(TemplateElementType.Number),
        //   subtitle: t(
        //     'templates.elements.types.information_obtain.number.subtitle'
        //   ),
        //   title: t('templates.elements.types.information_obtain.number.title'),
        //   type: TemplateElementType.Number,
        // },
        // {
        //   icon: templateElementIconByTypeGet(TemplateElementType.EMail),
        //   subtitle: t(
        //     'templates.elements.types.information_obtain.email.subtitle'
        //   ),
        //   title: t('templates.elements.types.information_obtain.email.title'),
        //   type: TemplateElementType.EMail,
        // },
      ];
    };

  /**
   * Returns template element type vote buttons array.
   * @returns Template element type vote buttons array.
   */
  const templateElementTypeVoteButtonsGet = (): TemplateElementTypeButton[] => {
    return [
      {
        icon: templateElementIconByTypeGet(TemplateElementType.Star),
        subtitle: t('templates.elements.types.vote.star.subtitle'),
        title: t('templates.elements.types.vote.star.title'),
        type: TemplateElementType.Star,
      },
      {
        icon: templateElementIconByTypeGet(TemplateElementType.Grade),
        subtitle: t('templates.elements.types.vote.grade.subtitle'),
        title: t('templates.elements.types.vote.grade.title'),
        type: TemplateElementType.Grade,
      },
      {
        icon: templateElementIconByTypeGet(TemplateElementType.Decision),
        subtitle: t('templates.elements.types.vote.decision.subtitle'),
        title: t('templates.elements.types.vote.decision.title'),
        type: TemplateElementType.Decision,
      },
      {
        icon: templateElementIconByTypeGet(TemplateElementType.Map),
        subtitle: t('templates.elements.types.vote.map.subtitle'),
        title: t('templates.elements.types.vote.map.title'),
        type: TemplateElementType.Map,
      },
    ];
  };

  /**
   * Returns template element type data from other tool array.
   * @returns Template element type data from other tool array.
   */
  const templateElementTypeDataFromOtherToolGet =
    (): TemplateElementTypeButton[] => {
      return [
        {
          icon: templateElementIconByTypeGet(
            TemplateElementType.PersonFieldWithDataFromOtherTool
          ),
          subtitle: 'Holt Element aus Hilfsmittel einer Person',
          title: 'von Person',
          type: TemplateElementType.PersonFieldWithDataFromOtherTool,
        },
        {
          icon: templateElementIconByTypeGet(
            TemplateElementType.DataFromOtherTool
          ),
          subtitle: 'Holt Element aus Hilfsmittel einer Gruppe',
          title: 'von Gruppe',
          type: TemplateElementType.DataFromOtherTool,
        },
        {
          icon: templateElementIconByTypeGet(
            TemplateElementType.DataElementFromOtherTool
          ),
          subtitle: 'Element aus allen angelegten Hilfsmittel',
          title: 'Aggregiere Daten',
          type: TemplateElementType.DataElementFromOtherTool,
        },
      ];
    };

  /**
   * Returns template print view initial object.
   */
  const templatePrintInitGet = (): TemplatePrintPostRequest | undefined => {
    try {
      if (selectedTemplate) {
        // Initial empty print object for post request
        const templatePrint: TemplatePrintPostRequest = {
          cover: undefined,
          name: '',
          sections: [],
        };
        // Check for
        if (
          selectedTemplate?.sections &&
          selectedTemplate.sections.length > 0
        ) {
          for (const section of selectedTemplate.sections) {
            const elements: TemplatePrintValues[] = [];
            // Check for section elements
            if (section.elements && section.elements.length > 0) {
              for (const element of section?.elements) {
                // Check if multi element type
                if (element.type === TemplateElementType.MultiElement) {
                  // Check for multi element children
                  if (element.elements && element.elements.length > 0) {
                    const children: TemplatePrintValues[] = [];
                    for (const child of element.elements) {
                      // Add child to children array
                      children.push({
                        id: child.id,
                        checked: true,
                      });
                    }
                    // Add multi element with children to elementsarray
                    elements.push({
                      id: element.id,
                      checked: true,
                      elements: children,
                    });
                  }
                } else {
                  // Add standard element type to elements array
                  elements.push({
                    id: element.id,
                    checked: true,
                  });
                }
              }
            }
            // Add section to print object
            templatePrint.sections?.push({
              id: section.id,
              checked: true,
              elements,
            });
          }
        }
        return templatePrint;
      }
      return undefined;
    } catch (error) {
      console.error('ERROR on template print view object init:', error);
    }
  };

  /**
   * Update template print obj by ids and checked state.
   * @param checked Checked state
   * @param sectionId Template section id
   * @param elementId Template element id
   * @param childId Template child id
   */
  const templatePrintUpdate = (
    checked: boolean,
    sectionId: string,
    elementId?: string,
    childId?: string
  ) => {
    try {
      if (print) {
        const updatedPrintView = { ...print };
        // Find section by id
        const matchedSection = updatedPrintView.sections?.find(
          (section) => section.id === sectionId
        );
        if (matchedSection) {
          // Find element by id
          const matchedElement = matchedSection.elements?.find(
            (element) => element.id === elementId
          );
          if (matchedElement) {
            // Find child by id
            const matchedChild = matchedElement.elements?.find(
              (child) => child.id === childId
            );
            if (matchedChild) {
              matchedChild.checked = checked;
              setPrint(updatedPrintView);
            } else {
              matchedElement.checked = checked;
              setPrint(updatedPrintView);
            }
          } else {
            matchedSection.checked = checked;
            setPrint(updatedPrintView);
          }
        }
      }
    } catch (error) {
      console.error('ERROR updating print object:', error);
    }
  };

  /**
   * POST Template Section by body.
   * @param section TemplateSection
   */
  const templateSectionPostEffect = (section: TemplateSection) => {
    if (selectedTemplate) {
      const updatedTemplate = { ...selectedTemplate };
      updatedTemplate.sections?.push(section);
      setSelectedTemplateAndToolPreview(updatedTemplate);
    }
  };

  /**
   * PATCH Template section by id and body.
   * @param response TemplateSectionPostPatchResponse
   */
  const templateSectionPatchEffect = (
    response: TemplateSectionPostPatchResponse
  ) => {
    if (selectedTemplate) {
      const updatedTemplate = { ...selectedTemplate };
      const matchedSection = updatedTemplate.sections?.find(
        (section) => section.id === response.id
      );
      if (matchedSection) {
        matchedSection.name = response.name;
        matchedSection.settings = response.settings;
        matchedSection.description = response.description;
      }
      setSelectedTemplateAndToolPreview(updatedTemplate);
    }
  };

  /**
   * DELETE Template section by ids.
   * @param response TemplateSectionDeleteRequestResponse
   */
  const templateSectionDeleteEffect = (
    response: TemplateSectionDeleteRequestResponse
  ) => {
    if (selectedTemplate) {
      const updatedTemplate = { ...selectedTemplate };
      // Find section by response section id
      const matchedSectionIndex = updatedTemplate.sections?.findIndex(
        (section) => section.id === response.section_id
      );
      // Find section by response section id
      if (matchedSectionIndex !== undefined && matchedSectionIndex > -1) {
        // Remove section from array. Update state
        updatedTemplate.sections?.splice(matchedSectionIndex, 1);
        setSelectedTemplateAndToolPreview(updatedTemplate);
      }
    }
  };

  /**
   * GET Template use case translation
   * @param use_case TemplateUseCase
   * @returns Template use case translation.
   */
  const templateUseCaseTranslationGet = (
    use_case: TemplateUseCase
  ): TFunctionResult => {
    switch (use_case) {
      case TemplateUseCase.Documentation:
        return t('templates.use_cases.documentation');
      case TemplateUseCase.Project_Management:
        return t('templates.use_cases.project_management');
      default:
        return t('templates.use_cases.other');
    }
  };

  /**
   * GET Template use cases.
   * @returns Template use cases.
   */
  const templateUseCasesGet = (): TemplateUseCase[] => {
    return [
      TemplateUseCase.Project_Management,
      TemplateUseCase.Documentation,
      TemplateUseCase.Other,
    ];
  };

  /**
   * SAVE ElementCreateEditState to local storage.
   * @param elementCreateEditState TemplateElementCreateEditState
   */
  const setElementCreateEditStateFunc = (
    storage_key: string,
    state: TemplateElementCreateEditState
  ) => {
    setElementCreateEditState(state);
    let savedValue = localStorage.getItem(
      `__store_element-drafts_${storage_key}`
    );
    if (savedValue) {
      let elementDrafts: ElementDrafts = JSON.parse(savedValue);

      if (elementDrafts) {
        const updatedElementDrafts = { ...elementDrafts };
        updatedElementDrafts.elementCreateEditState = state;

        localStorage.setItem(
          `__store_element-drafts_${storage_key}`,
          JSON.stringify(updatedElementDrafts)
        );
      }
    }
  };

  /**
   * SAVE temporary element to local storage.
   * @param element TemplateElement
   */
  const setElementCreateEditFunc = (
    storage_key: string,
    new_element: TemplateElement
  ) => {
    setElementCreateEdit(new_element);
    let savedValue = localStorage.getItem(
      `__store_element-drafts_${storage_key}`
    );
    let updatedElementDrafts: ElementDrafts;
    if (savedValue) {
      const elementDrafts: ElementDrafts = JSON.parse(savedValue);
      updatedElementDrafts = { ...elementDrafts };
      updatedElementDrafts.element = new_element;
      updatedElementDrafts.elementCreateEdit = new_element;
    } else {
      updatedElementDrafts = {
        id: new_element.id,
        section_id: storage_key,
        element: new_element,
        elementCreateEdit: new_element,
      };
    }

    // if element is not child element
    localStorage.setItem(
      `__store_element-drafts_${storage_key}`,
      JSON.stringify(updatedElementDrafts)
    );
    localStorage.setItem(
      `__store_template-section-element-edit_${storage_key}`,
      JSON.stringify(true)
    );

    // Change Preview by changing editState
    // let updatedToolPreview = toolPreview;
    // if (updatedToolPreview && element_) {
    //   updatedToolPreview.template.sections?.map((section, index_sec) => {
    //     section.elements?.map((element, index_ele) => {
    //       if (element.id === element_?.id) {
    //         updatedToolPreview!.template.sections![index_sec].elements![
    //           index_ele
    //         ] = element_;g
    //         setToolPreview(updatedToolPreview);
    //         return;
    //       }
    //     });
    //   });
    // }
  };

  /**
   * SAVE ElementCreateEditSteps to local storage.
   * @param element TemplateElement
   */
  const setElementCreateEditStepsFunc = (
    storage_key: string,
    steps: TemplateElementCreateEditState[]
  ) => {
    setElementCreateEditSteps(steps);
    let savedValue = localStorage.getItem(
      `__store_element-drafts_${storage_key}`
    );
    if (savedValue) {
      let elementDrafts: ElementDrafts = JSON.parse(savedValue);
      if (elementDrafts) {
        const updatedElementDrafts = { ...elementDrafts };
        updatedElementDrafts.elementCreateEditSteps = steps;

        localStorage.setItem(
          `__store_element-drafts_${storage_key}`,
          JSON.stringify(updatedElementDrafts)
        );
      }
    }
  };

  /**
   * SAVE ElementCreateEditSummaryState to local storage.
   * @param element TemplateElement
   */
  const setElementCreateEditSummaryStateFunc = (
    storage_key: string,
    value: boolean
  ) => {
    setElementCreateEditSummaryState(value);
    let savedValue = localStorage.getItem(
      `__store_element-drafts_${storage_key}`
    );
    if (savedValue) {
      let elementDrafts: ElementDrafts = JSON.parse(savedValue);

      if (elementDrafts) {
        const updatedElementDrafts = { ...elementDrafts };
        updatedElementDrafts.elementCreateEditSummaryState = value;

        localStorage.setItem(
          `__store_element-drafts_${storage_key}`,
          JSON.stringify(updatedElementDrafts)
        );
      }
    }
  };

  const templateEditStateGet = (template: Template): boolean => {
    let savedValue = localStorage.getItem(
      `__store_template-edit_${template.id}`
    );
    if (!savedValue) {
      localStorage.setItem(`__store_template-edit_${template.id}`, 'false');
      return false;
    } else {
      localStorage.setItem(`__store_template-edit_${template.id}`, 'true');
      return true;
    }
  };

  /**
   * Change string from local storage into enum TemplateElementCreateEditState
   * @param savedElementCreateEditState saved value in localstorage
   */
  const setElementCreateEditStateEffect = (
    savedElementCreateEditState: string
  ): TemplateElementCreateEditState => {
    switch (savedElementCreateEditState) {
      case TemplateElementCreateEditState.Content:
        return TemplateElementCreateEditState.Content;
      case TemplateElementCreateEditState.Headline:
        return TemplateElementCreateEditState.Headline;
      case TemplateElementCreateEditState.Image:
        return TemplateElementCreateEditState.Image;
      case TemplateElementCreateEditState.Name:
        return TemplateElementCreateEditState.Name;
      case TemplateElementCreateEditState.Options:
        return TemplateElementCreateEditState.Options;
      case TemplateElementCreateEditState.Settings:
        return TemplateElementCreateEditState.Settings;
      case TemplateElementCreateEditState.Summary:
        return TemplateElementCreateEditState.Summary;
      default:
        return TemplateElementCreateEditState.Type;
    }
  };

  // GET template mutation
  const templateGetMutation = useMutation((id: string) => templateGet(id), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
    onSettled(data, error) {
      if (data) {
        setSelectedTemplate(data);
      }
    },
  });
  // GET template mutation
  const templateGetMutationMarktplatz = useMutation((id: string) =>
    templateGetMarktplatz(id)
  );

  // GET template public mutation
  const templatePublicGetMutation = useMutation((id: string) =>
    templatePublicGet(id)
  );

  /**
   * Scroll to Element
   */
  const scrollToElement = (template: Template) => {
    let scroll = localStorage.getItem(
      `__store_template_${template?.id}_scroll_`
    );
    if (scroll) {
      if (document.getElementById('template-main-content-card')) {
        document
          .getElementById('template-main-content-card')!
          .scrollTo(0, Number(scroll));
        localStorage.removeItem(`__store_template_${template?.id}_scroll_`);
      }
    }
  };

  /**
   * Save scroll position.
   */
  const scrollPositionSave = (template: Template) => {
    if (document.getElementById('template-main-content-card')) {
      let scroll = document
        .getElementById('template-main-content-card')!
        .scrollTop.toString();
      localStorage.setItem(`__store_template_${template?.id}_scroll_`, scroll);
    }
  };

  const updateTemplate = (id: string): void => {
    templateGetMutation.mutate(id);
  };

  const setSelectedTemplateAndToolPreview = (
    updatedTemplate: Template
  ): void => {
    setSelectedTemplate(updatedTemplate);
    const previewId = uuidv4();
    setToolPreview({
      name: 'Preview',
      template: updatedTemplate,
      users: [],
      id: previewId,
      values: [],
    });
  };

  const handelSettingAdvanced = (
    storage_key: string,
    type: string,
    checked: boolean
  ) => {
    if (elementCreateEdit) {
      const settings = { ...elementCreateEdit.settings };
      if (settings.advanced) {
        settings.advanced[type] = checked;

        // Update element with updated settings
        const updatedElement: TemplateElement = {
          ...elementCreateEdit,
          settings,
        };
        setElementCreateEditFunc(storage_key, updatedElement);
      }
    }
  };

  const handelSettingExport = (
    storage_key: string,
    type: 'disableExport' | 'export_with_id',
    checked: boolean
  ) => {
    if (elementCreateEdit) {
      const settings = { ...elementCreateEdit.settings };
      if (settings.export) {
        settings.export[type] = checked;
      } else {
        settings.export = {
          [type]: checked,
        };
      }

      // Update element with updated settings
      const updatedElement: TemplateElement = {
        ...elementCreateEdit,
        settings,
      };

      setElementCreateEditFunc(storage_key, updatedElement);
    }
  };

  return {
    scrollToElement,
    scrollPositionSave,
    setElementCreateEditStateFunc,
    setElementCreateEditFunc,
    setElementCreateEditStateEffect,
    setElementCreateEditSummaryStateFunc,
    setElementCreateEditStepsFunc,
    setElementCreateEdit,
    templateEditStateGet,
    templateGetMutation,
    templateGetMutationMarktplatz,
    templatePublicGetMutation,
    // templatePostEffect,
    templateSettingsPatchEffect,
    templateElementPostEffect,
    templateElementPatchEffect,
    templateElementDeleteEffect,
    templateElementChildPostEffect,
    templateElementChildPatchEffect,
    templateElementChildDeleteEffect,
    templateElementIconByTypeGet,
    templateElementTypeFavoriteButtonsGet,
    templateElementTypeInformationDisplayButtonsGet,
    templateElementTypeInformationObtainButtonsGet,
    templateElementInputTypesGet,
    templateElementTypeOptionButtonsGet,
    templateElementTypeVoteButtonsGet,
    templateNoPublishEffect,
    templatePublishEffect,
    templatePrintInitGet,
    templatePrintUpdate,
    templateSectionPostEffect,
    templateSectionPatchEffect,
    templateSectionDeleteEffect,
    templateUseCaseTranslationGet,
    templateUseCasesGet,
    templateElementChangePosition,
    templateElementLabelShow,
    templateElementTypesTitleGet,
    updateTemplate,
    templateElementTypeDataFromOtherToolGet,
    handelSettingAdvanced,
    handelSettingExport,
    setSelectedTemplateAndToolPreview,
  };
};
