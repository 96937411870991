// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContactsToolsCard_select-tools__HHD0m {\n  margin-top: 0.75rem }\n  .ContactsToolsCard_select-tools-box__se5g8 {\n  margin-top: 0.5rem;\n  display: flex;\n  flex-direction: row;\n  overflow-x: auto }\n\n.ContactsToolsCard_contacts-tools-container__PCuSC {\n  position: relative;\n  width: 114px;\n  flex-shrink: 0;\n  padding: 0.5rem }\n\n@media (min-width: 1024px) {\n  .ContactsToolsCard_select-tools-box__se5g8 {\n    margin-top: 0px } }\n", "",{"version":3,"sources":["<no source>","webpack://./src/modules/contacts/components/ContactsToolsCard/ContactsToolsCard.module.scss"],"names":[],"mappings":"AAAA;EAAA,qBAAA;EAAA;EAAA,mBAAA;EAAA,cAAA;EAAA,oBAAA;EAAA,kBAAA;;AAAA;EAAA,mBAAA;EAAA,aAAA;EAAA,eAAA;EAAA,iBAAA;;ACWA;EDXA;IAAA,iBAAA,CCeK","sourcesContent":[null,"@import '../../../../styles/breakpoints.scss';\n\n.select-tools {\n  @apply mt-3;\n  &-box {\n    @apply mt-2 flex flex-row overflow-x-auto;\n  }\n}\n.contacts-tools-container {\n  @apply relative p-2 shrink-0 w-[114px]; // calc(130px-16px)\n}\n@media (min-width: $lg) {\n  .select-tools {\n    &-box {\n      @apply mt-0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select-tools": "ContactsToolsCard_select-tools__HHD0m",
	"select-tools-box": "ContactsToolsCard_select-tools-box__se5g8",
	"contacts-tools-container": "ContactsToolsCard_contacts-tools-container__PCuSC"
};
export default ___CSS_LOADER_EXPORT___;
