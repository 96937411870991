// Models
import { NewsCenterFiltersGroupBy } from './news-center-filters-group-by.enum';

export interface NewsCenterFiltersData {
  group_by: NewsCenterFiltersGroupBy;
  selectedCommunities: string[];
  currentChatRooms?: string[];
}

export const initialNewsCenterFilterData = (): NewsCenterFiltersData => {
  return {
    group_by: NewsCenterFiltersGroupBy.Communities,
    selectedCommunities: [],
    currentChatRooms: [],
  };
};
