import { AppTranslations } from '../models/app.translations.interface';

export const appTranslationsDE: AppTranslations = {
  search: 'Suchen',
  no_results: 'Keine Ergebnisse gefunden',
  group: 'Gruppieren',
  download: 'Download',
  download_as_csv: 'Als CSV herunterladen',
  print: 'Drucken',
  unfold: 'Ausklappen',
  website: {
    to: 'Zu unserer Webseite',
  },
  data_protection: 'Datenschutz',
  security_info: 'Leitfaden zur Informationssicherheit',
  contact: {
    text1: 'Du hast Fragen oder brauchst Hilfe?',
    text2: 'Melde dich gerne bei uns',
    text3: ', wir können dir sicher weiterhelfen.',
  },
  actions: {
    create: 'Erstellen',
    delete: 'Löschen',
    edit: 'Bearbeiten',
    title: 'Aktionen',
  },
  understood: 'Alles klar',
  all: 'Alle',
  and: 'und',
  apply: 'Übernehmen',
  back: 'Zurück',
  back_to_summary: 'Zurück zur Übersicht',
  cancel: 'Abbrechen',
  check: 'überprüfen',
  coming_soon: 'Bald verfügbar',
  communities: 'Gemeinschaften',
  employee: 'Mitarbeiter',
  member: 'Mitglieder',
  admin: 'Admin',
  confirm: 'Bestätigung',
  continue: 'Weiter',
  data_grid: {
    rows_selected: {
      multi: 'Zeilen ausgewählt',
      single: 'Zeile ausgewählt',
    },
  },
  date: {
    date: {
      placeholder: 'Wähle ein Datum ...',
      title: 'Datum',
    },
    date_time: {
      placeholder: 'Wähle ein Datum und eine Uhrzeit ...',
      title: 'Datum und Uhrzeit',
    },
    time: {
      placeholder: 'Wähle eine Uhrzeit ...',
      title: 'Uhrzeit',
    },
  },
  deselect: 'Abwählen',
  email: 'E-Mail',
  emailAddress: 'E-Mail-Adresse',
  emoji_picker: {
    search: 'Suche',
    not_found: 'Kein Emoji gefunden',
    categories: {
      search: 'Suchergebnisse',
      recent: 'Häufig genutzt',
      people: 'Emojis & Personen',
      nature: 'Tiere & Natur',
      foods: 'Essen & Trinken',
      activity: 'Aktivität',
      places: 'Reisen & Orte',
      objects: 'Gegenstände',
      symbols: 'Symbole',
      flags: 'Flaggen',
      custom: 'Benutzerdefiniert',
    },
  },
  employees: 'Mitarbeiter:innen',
  favorites: 'Favoriten',
  fetch: {
    error: {
      fail: 'Es ist ein Fehler aufgetreten.',
      response: 'Die Antwort des Servers ist fehlerhaft.',
      unauthorized: 'Unautorisiert',
      unknown: 'Ein unbekannter Fehler ist aufgetreten.',
    },
  },
  file_drop: {
    placeholder1: 'Wähle ein Bild aus deinen ',
    placeholder2: 'Dateien ',
    placeholder3: 'aus.',
  },
  filters: {
    group: 'Gruppieren nach',
    headline: 'Filteroptionen',
    title: 'Filter',
  },
  groups: 'Gruppen',
  toolLinks: 'Hilfsmittel-Links',
  hello: 'Hallo',
  language: 'Sprache',
  language_text: `Wähle die Sprache der Pengueen Oberfläche.`,
  leaflet: 'Merkblatt',
  likeMark: 'Als Favorit markieren',
  members: 'Mitglieder',
  name: 'Name',
  nameless: 'Namenlos',
  nickname: 'Spitzname',
  no: 'Nein',
  of: 'von',
  ok: 'OK',
  optional: 'optional',
  reset: 'Zurücksetzen',
  roles: {
    admin: 'Admin',
    employee: 'Mitarbeiter:in',
    member: 'Mitglied',
    guest: 'Gast',
  },
  save: 'speichern',
  select: 'Auswählen',
  selectNoOptions: 'Keine Auswahlmöglichkeiten',
  settings: 'Einstellungen',
  sms: 'SMS',
  telephone: 'Telefon',
  title: 'Pengueen. So gelingt Gemeinschaft.',
  unlikeMark: 'Als Favorit abwählen',
  users: {
    invite: {
      button: 'Jetzt einladen',
      error: 'Es ist ein Fehler beim Einladen aufgetreten:',
      subtitle1: 'Du kannst mit',
      subtitle2: 'schreiben, nachdem du',
      subtitle3: 'zu deinen Kontakten eingeladen hast',
      success: 'Kontakt eingeladen',
      title: 'Zu meinen Kontakten einladen',
    },
    remove: {
      btn: 'Jetzt entfernen',
      match: 'ENTFERNEN',
      text1: 'Jetzt',
      text2: 'entfernen?',
      title1: 'Möchtest du',
      title2: 'wirklich entfernen?',
    },
  },
  verify: 'verifizieren',
  yes: 'Ja',
  you: 'Du',
  yourPengueenTeam: 'Dein Pengueen Team',
  error: {
    fileSizeTooSmall: 'Dateigröße ist zu klein! ',
    fileSizeToolarge: 'Dateigröße ist zu groß! ',
    readerError: 'Die Datei konnte nicht gelesen werden! ',
    maxLimitExceeded: 'Zu viele Dateien ausgewählt! ',
    minLimitNotReached: 'Nicht genug Dateien ausgewählt! ',
    imageHeightTooBig: 'Die Höhe des Bildes stimmt nicht! ',
    imageWidthTooBig: 'Die Breite des Bildes stimmt nicht! ',
    imageHeightTooSmall: 'Die Höhe des Bildes stimmt nicht! ',
    imageWidthTooSmall: 'Die Breite des Bildes stimmt nicht!',
  },
};
