// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicPage_public-page__Iv47E {\n  position: relative;\n  min-height: 100vh;\n  width: 100% }\n@media (min-width: 1024px) {\n  .PublicPage_public-page__Iv47E {\n    width: 66.666667% } }\n  .PublicPage_public-page-background__O\\+mIq {\n  display: none;\n  height: 100%;\n  width: 100vw }\n\n@media (min-width: 1024px) {\n  .PublicPage_public-page-background__O\\+mIq {\n    position: absolute }\n  .PublicPage_public-page-background__O\\+mIq {\n    right: 0px }\n  .PublicPage_public-page-background__O\\+mIq {\n    top: 0px }\n  .PublicPage_public-page-background__O\\+mIq {\n    display: block }\n  .PublicPage_public-page-background__O\\+mIq {\n    width: 33.333333% } }\n", "",{"version":3,"sources":["<no source>","webpack://./src/modules/public/pages/PublicPages/PublicPage.module.scss"],"names":[],"mappings":"AAAA;EAAA,mBAAA;EAAA,kBAAA;EAAA,aAAA;AAAA;EAAA;IAAA,mBAAA,EAAA;EAAA;EAAA,cAAA;EAAA,aAAA;EAAA,cAAA;;ACQA;EDRA;IAAA,oBAAA;EAAA;IAAA,YAAA;EAAA;IAAA,UAAA;EAAA;IAAA,gBAAA;EAAA;IAAA,mBAAA,CCYK","sourcesContent":[null,"@import '../../../../styles/breakpoints.scss';\n\n.public-page {\n  @apply min-h-screen relative w-full lg:w-2/3;\n  &-background {\n    @apply hidden h-full w-screen;\n  }\n}\n@media (min-width: $lg) {\n  .public-page {\n    &-background {\n      @apply absolute block right-0 top-0 w-1/3;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"public-page": "PublicPage_public-page__Iv47E",
	"public-page-background": "PublicPage_public-page-background__O+mIq"
};
export default ___CSS_LOADER_EXPORT___;
