import { Navigate, Route, Routes } from 'react-router-dom';

// Components
import { Contacts } from '../modules/contacts/page/Contacts/Contacts';
import { ContactCommunityGetAction } from '../modules/contacts/models/contacts.types';

export const ContactsRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Contacts action={ContactCommunityGetAction.None} />}
      />
      <Route
        path="/all"
        element={<Contacts action={ContactCommunityGetAction.All} />}
      />
      <Route
        path="/community/:id"
        element={<Contacts action={ContactCommunityGetAction.Community} />}
      />
      <Route
        path="/community/:id/admin"
        element={<Contacts action={ContactCommunityGetAction.Admin} />}
      />
      <Route
        path="/community/:id/employee"
        element={<Contacts action={ContactCommunityGetAction.Employee} />}
      />
      <Route
        path="/community/:id/member"
        element={<Contacts action={ContactCommunityGetAction.Member} />}
      />
      <Route
        path="/community/group/:communitygroupid"
        element={<Contacts action={ContactCommunityGetAction.Group} />}
      />
      <Route
        path="/community/folder/:forderid"
        element={<Contacts action={ContactCommunityGetAction.Folder} />}
      />
      <Route
        path="/community/folder/:forderid/group/:communitygroupid"
        element={<Contacts action={ContactCommunityGetAction.FolderGroup} />}
      />
      <Route
        path="/community/link/:linkid"
        element={<Contacts action={ContactCommunityGetAction.CommunityLink} />}
      />
      <Route
        path="/community/tool/:toolid"
        element={<Contacts action={ContactCommunityGetAction.ToolLink} />}
      />

      <Route
        path="/group/:groupid"
        element={<Contacts action={ContactCommunityGetAction.Room} />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
