import { ReactChild, useEffect, useState } from 'react';
import { TFunctionResult } from 'i18next';
import { Box, Button, SxProps, Theme } from '@mui/material';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';

// Components
import { Icon } from '../Icon/Icon';

// Styles
import styles from './IconTextButton.module.scss';

type IconTextButtonProps = {
  active?: boolean;
  borderStyle?: string;
  children: ReactChild | TFunctionResult;
  classes?: string;
  disabled?: boolean;
  flexDirection?: 'row' | 'row-reverse';
  fontSizeClasses?: string;
  hideIcon?: boolean;
  icon: [IconPrefix, IconName];
  iconSize?: 'small' | 'medium' | 'large' | 'inherit';
  padding?: string;
  margin?: string;
  preset?:
    | 'gray'
    | 'card-paper'
    | 'card-paper-white'
    | 'paper'
    | 'paper-secondary'
    | 'popover'
    | 'primary'
    | 'secondary'
    | 'tools'
    | 'tools-primary';
  sx?: SxProps<Theme>;
  textTransform?: 'capitalize' | 'lowercase' | 'uppercase';
  type?: 'reset' | 'submit';
  onClick?: (event?: any) => void;
};

export const IconTextButton = (props: IconTextButtonProps) => {
  // Component state
  const [sx, setSx] = useState<SxProps<Theme> | undefined>(undefined);
  const [sxIcon, setSxIcon] = useState<SxProps<Theme> | undefined>(undefined);
  const [sxText, setSxText] = useState<SxProps<Theme> | undefined>(undefined);

  useEffect(() => {
    switch (props.preset) {
      case 'gray':
        setSx({
          ...props.sx,
          backgroundColor: 'bg.card',
          // '&:hover': { backgroundColor: 'primary.main' },
          // '&:hover #button-text': { color: 'white' },
          // '&:hover .MuiSvgIcon-root': { color: 'white' },
        });
        setSxIcon({ color: 'text.secondary' });
        setSxText({ color: 'text.secondary' });
        break;
      case 'card-paper':
        setSx({
          ...props.sx,
          backgroundColor: props.active ? 'background.paper' : 'bg.card',
          '&:hover': { backgroundColor: 'background.paper' },
          '&:hover #button-text': { color: 'primary.main' },
          '&:hover .MuiSvgIcon-root': { color: 'primary.main' },
        });
        setSxIcon({ color: props.active ? 'primary.main' : 'text.secondary' });
        setSxText({ color: props.active ? 'primary.main' : 'text.secondary' });
        break;
      case 'card-paper-white':
        setSx({
          ...props.sx,
          backgroundColor: 'background.paper',
          '&:hover': { backgroundColor: 'background.paper' },
          '&:hover #button-text': { color: 'primary.main' },
          '&:hover .MuiSvgIcon-root': { color: 'primary.main' },
        });
        setSxIcon({ color: 'text.secondary' });
        setSxText({ color: 'text.secondary' });
        break;

      case 'paper':
        setSx({
          ...props.sx,
          backgroundColor: 'background.paper',
          border: 1,
          borderColor: 'border.app',
          '&:hover': { backgroundColor: 'bg.card' },
        });
        setSxIcon({ color: 'text.primary' });
        setSxText({ color: 'text.primary' });
        break;
      case 'paper-secondary':
        setSx({
          ...props.sx,
          backgroundColor: 'background.paper',
          border: 1,
          borderColor: 'border.app',
          '&:hover': { backgroundColor: 'bg.card' },
        });
        setSxIcon({ color: 'text.secondary' });
        setSxText({ color: 'text.secondary' });
        break;
      case 'popover':
        setSx({
          ...props.sx,
          backgroundColor: 'bg.card',
          // color: 'white',
          '&:hover': { backgroundColor: 'primary.main' },
          '&:hover #button-text': { color: 'white' },
          '&:hover .MuiSvgIcon-root': { color: 'white' },
        });
        setSxIcon({ color: 'text.primary' });
        setSxText({ color: 'text.primary' });
        break;
      case 'primary':
        setSx({
          ...props.sx,
          backgroundColor: 'primary.main',
          color: 'white',
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
        });
        setSxIcon({ color: 'white' });
        setSxText({ color: 'white' });
        break;
      case 'secondary':
        setSx({
          ...props.sx,
          backgroundColor: 'primary.light',
          color: 'primary.main',
          '&:hover': { backgroundColor: 'primary.main' },
          '&:hover #button-text': { color: 'white' },
          '&:hover .MuiSvgIcon-root': { color: 'white' },
        });
        setSxIcon({ color: 'primary.main' });
        setSxText({ color: 'primary.main' });
        break;
      case 'tools':
        setSx({
          ...props.sx,
          borderColor: 'text.secondary',
          borderStyle: 'dashed',
          borderWidth: '1px',
        });
        setSxIcon({ color: 'text.secondary' });
        setSxText({ color: 'text.secondary' });
        break;
      case 'tools-primary':
        setSx({
          ...props.sx,
          borderColor: 'border.app',
          borderStyle: 'dashed',
          borderWidth: '1px',
        });
        setSxIcon({ color: 'primary.main' });
        setSxText({ color: 'primary.main' });
        break;
      default:
        setSx({ ...props.sx });
        setSxIcon({ color: 'text.primary' });
        setSxText({ color: 'text.primary' });
        break;
    }
  }, [props]);

  return (
    <Button
      color="primary"
      disabled={props.disabled}
      disableElevation
      sx={{
        ...sx,
        borderStyle: props.borderStyle && props.borderStyle,
        flexDirection: props.flexDirection ?? 'row',
        padding: props.padding ?? '0.75rem 1rem',
        margin: props.margin ?? '',
      }}
      type={props.type || 'button'}
      // variant="contained"
      onClick={props.onClick && props.onClick}
      className={clsx(
        styles['icon-text-button'],
        props.classes && props.classes,
        props.disabled && styles['icon-text-button-disabled'],
        !props.preset && styles['icon-text-button-presets-none'],
        props.preset === 'tools' && styles['icon-text-button-presets-tools']
      )}
    >{!props.hideIcon &&      
    <Icon
        icon={props.icon}
        size={props.iconSize}
        sx={{
          ...sxIcon,
          marginLeft:
            props.flexDirection === 'row-reverse'
              ? props.children
                ? '0.5rem'
                : undefined
              : undefined,
          marginRight:
            props.flexDirection !== 'row-reverse'
              ? props.children
                ? '0.5rem'
                : undefined
              : undefined,
        }}
      /> }
      <Box
        id="button-text"
        sx={{ ...sxText, textTransform: props.textTransform ?? 'none' }}
        className={clsx(styles['icon-text-button-text'], props.fontSizeClasses)}
      >
        {props.children}
      </Box>
    </Button>
  );
};
