import { HeaderTranslations } from '../models/header.translations.interface';

export const headerTranslationsEN: HeaderTranslations = {
  substitutions: 'My Substitutions',
  trial: {
    day: 'day',
    days: 'days',
    text1: 'Only',
    text2: 'remaining',
    text3: 'in testing period'
  }
};
