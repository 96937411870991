import { CommunityTranslations } from '../models/community.translations.interface';

export const communityTranslationsIT: CommunityTranslations = {
  no_community: {
    detail:
      'Qui puoi pilotare le tue comunità. Le ti facilitano la collaborazione. Provalo adesso e crea una comunità. ',
  },
  create: {
    info: {
      end: {
        text: 'A questo giorno tu paghi per le tue licenze, che alla data di scadenza',
        title: 'Fine dal mese successivo',
      },
      notification: {
        text: 'Ottieni un ricorda, quando fine il periodo della prova.',
        title: '5 giorni prima scadenza',
      },
      start: {
        text: 'Inizia il tuo fase del test graduito nel modo esaustivo.',
        title: 'Oggi',
      },
      text1:
        'Periodo di prova gratuito fino al {{date}}. Successivamente {{cost}} € per dipendente:In al mese.',
      text2: 'Poi',
      text3: 'per mese/ licenza',
      text4:
        'La licenza non verrà attivata automaticamente. Puoi creare la community senza impegno. ',
      text5: 'Al listino prezzi',
      title: 'Come l`esecuzione del fase del test?',
    },
    form: {
      logo: 'Logo',
      name: {
        error: 'Si deve digitare un nome della comunità',
        label: 'Come si chiama la tua comunità?',
        placeholder: 'Nome della comunità',
      },
      sector: 'Qualle branca è adatto?',
      option: {
        name: 'Impostazione ampliato',
        info: 'A Pengueen diffenrenziamo in generale i ruoli degli utenti "collaboratori" e "membri", per distinguere autorizzazioni. Qui c` è l`hai la possibilità di adattarli. Qui ci puoi apprendere piu.',
        employee: 'Collaboratori (diritti ampliati)',
        member: ' Membri (diritti semplici)',
        employeeplaceholder: 'La tua designazione p.e. comitato',
        memberplaceholder: 'La tua designazione p.e. consigliere',
        link: 'Link di collegamento',
        link_info:
          'Persone, chi registrarsi sul questo link, vengono aggiunti alla comunità con il {{membersname}}!',
        headline_email: 'Texte Einladungs-E-Mail',
        headline_accept_email: 'Texte Bestätigungs-E-Mail',
        betreff_einladung_email: 'Betreff der E-Mail',
        headline_einladung_email: 'Überschrift',
        text_einladung_email: 'Text der E-Mail',
        text_einladung_bestaetigung_email: 'Text der E-Mail',
        betreff_einladung_bestaetigung_email: 'Betreff der E-Mail',
      },
    },
    subtitle:
      'Inizia il tuo fase del test graduito e usi lo nel modo esaustivo.',
    title: 'Crea la tua comunità',
    title_button: 'Creare comunità',
  },
  filters: {
    apply: 'Applicare',
    button: 'Visualizzazione',
    display: {
      gallery: 'Visualizzazione affiancata',
      list: 'Visualizzazione elenco',
    },
    labels: {
      contains: 'Nome contiene',
      order: 'Ordine ',
    },
    sort: {
      latest: 'Aggiunto all´ultimo',
      modified: 'Modificato all`ultimo',
      title: 'Ordinare a',
      used: 'Usato piu spesso',
    },
    title: 'Opzioni della visualizzazione',
  },
  table: {
    actions: {
      remove: 'Rimuovere',
      title: 'Azioni',
      view: 'Guardare',
    },
  },
  tools: {
    filters: {
      field_contains: 'Campo del strumento contiene',
      group: {
        none: 'Nessuno',
        tag: 'Voce',
        title: 'Aggruppare',
      },
      search: 'Cercare per designazione ',
    },
    table: {
      category: 'Categoria degli strumenti',
      connected_group: 'Gruppo connesso',
      field: 'Campo del strumento',
      folder_tag: 'Vove delle cartelle',
      visibility: 'Visibilità ',
    },

    title: {
      employees: 'I tui strumenti per i tui collaboratori/e',
      members: 'I tui strumenti per i tui membri',
      with_tags: 'Strumento con voce',
      without_tags: 'Strumento senza voce',
    },
    visibility: {
      group: 'Gruppo',
      private: 'Privato',
      unrestricted: 'Illimitato',
    },
  },
  sectors: {
    care_facility: 'Istituzione di cura',
    club: 'Assoziazione',
    community: 'Comune',
    company: 'Impresa',
    craft_business: 'Azienda artigiana',
    other: 'Altri',
    school: 'Scuola ed asilo',
  },
  users: {
    add: {
      submit: 'Aggiungere',
      subtitle:
        'Aggiungi persone dalla tua comunità ed assegni loro i sui ruoli adeguati.',
      title: 'Aggiungere alla tua comunità',
      title_button: 'Aggiungere alla comunità',
    },
    contact: {
      add: {
        subtitle: 'Eleggi un referente primario/a per il tuo membro',
        title: 'Eleggere referente primario',
      },
    },
    deputy: {
      title: 'Supplente',
    },
    employees: {
      add: 'Aggiungere collaboratori',
      demote: {
        btn: 'Degradare',
        confirm: 'Membro',
        subtitle: 'La tua collaboratora diventa membra',
        text: `Vuoi mettere il/la collaboratoro/a  in posizione membro adesso?`,
        title: 'Conferma',
      },
      detail: {
        title: 'Collaboratoro/a',
        tools: 'Strumenti per collaboratori/e',
      },
      remove: {
        subtitle:
          'Il collegamento dal tuo collaboratoro alla tua comunità viene seperato. La/il tuo/a collaboratoro/a non ha accesso piu.',
        text: 'Vuoi cancellare il collegamento alla collaboratoro/a?',
        title: 'Cancellare il collegamento al collaboratorio',
      },
      title: 'I/le tui/e collaboratori/e',
    },
    filters: {
      admin: 'Pengueen Admin',
      search: 'Cercare per nome',
      visibility: 'Visualizzazione colonna',
    },
    members: {
      add: 'Aggiungere membro',
      promote: {
        btn: 'Upgrade',
        text1: 'Vuoi il membro',
        text2: 'Vuoi mettere il/la collaboratoro/a  in posizione membro?',
        text3:
          'Il membro ottiene con cio diritti ampliati, puo scambiarsi con altri membri p.e. e invitare membri.',
        title: 'Upgrade membro al collaboratorio/a?',
      },
      remove: {
        subtitle:
          'Il collegamento dal tuo membro alla tua comunità viene seperato. il tuo membro non ha accesso piu.',
        text: 'Vuoi cancellare il collegamento al membro?',
        title: 'Cancellare il collegamento al membro',
      },
      title: 'I tui membri',
    },
    nameless: 'Non digita nome',
    roles: {
      admin: 'Admin',
      employee: 'Collaboratorio',
      member: 'Membro',
    },
    table: {
      actions: {
        demote: 'Mettere al membro',
        message: 'Lettera di accompagnamento',
        remove: 'Cancellare collegamento',
      },
      address: 'Indirizzo',
      admin: 'Pengueen Admin',
      email: 'Indirizzo email',
      invited: 'Invitato al',
      name: 'Nome',
      phone: 'Numero telefono',
      tools: 'Strumento',
    },
  },
};
