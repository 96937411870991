import { AuthTranslations } from '../models/auth.translations.interface';

export const authTranslationsEN: AuthTranslations = {
  account: {
    setup: {
      community: {
        employees: 'Mitarbeiter:innen',
        form: {
          name: {
            error: 'Der Name muss eine Mindestlänge von 3 Zeichen besitzen',
            placeholder: 'Name der Gemeinschaft',
            title: 'Bezeichnung',
          },
          sector: {
            error: 'Es muss eine Branche ausgewählt werden',
            title: 'Welche Branche ist passend?',
          },
        },
        members: 'Mitglieder',
        title: {
          defined: ', wie heißt deine Gemeinschaft?',
          undefined: 'Wie heißt deine Gemeinschaft?',
        },
      },
      invite: {
        success: 'Invitation accepted.',
      },
      name: {
        submit: {
          defined: `That's me`,
          undefined: 'Continue without name',
        },
        subtitle: `Wir freuen uns dich kennen zu lernen. Verrätst du uns deinen Namen? Dann können wir und von dir eingeladene Personen auch deinen Namen sehen.
          Das macht das ganze doch etwas persönlicher, oder?`,
        title: `Welcome, what's your name?`,
      },
      password: {
        title1: 'Super',
        title2:
          'Jetzt nur noch kurz dein zukünftiges Passwort wählen und schon geht’s los',
      },
      success: {
        button: 'Zu meiner Gemeinschaft',
        text: 'Starte jetzt mit deinen ersten Hilfsmittel und lade weitere Nutzer ein und richte dein Profil ein.',
        title: 'Gute Arbeit',
      },
      users: {
        form: {
          employees: {
            placeholder: 'Deine Bezeichnung z.B. Vorstand',
            title: 'Mitarbeiter (erweiterte Rechte)',
          },
          members: {
            placeholder: 'Deine Bezeichnung z.B. Ratsmitglieder',
            title: 'Mitglieder (einfache Rechte)',
          },
          title: 'Anpassung der Rollenbezeichnung',
        },
        info: `Wir unterscheiden bei Pengueen standardmäßig in die Nutzerrollen „Mitarbeiter:in“ und „Mitglied“ um bestimmte Berechtigungen zu unterscheiden.
        Du hast hier die Möglichkeit diese anzupassen.`,
        title: 'Wie heißen deine Nutzer?',
      },
    },
  },
  login: {
    error: {
      credentials:
        'Deine E-Mail-Adresse oder dein Passwort sind nicht korrekt. Bitte überprüfe deine Eingaben.',
      timeout:
        'Du hast dein Passwort mehrmals falsch eingegeben. Warte bis der Timer abgelaufen ist und du es erneut versuchen kannst, oder klicke auf Passwort vergessen, um es zurück zu setzen.',
    },
    keep: 'Für zwei Tage angemeldet bleiben',
    tan: {
      subtitle:
        'Du hast die sichere Anmeldung mit SMS-TAN gewählt. Fordere eine TAN an und wir senden dir eine SMS mit einer Bestätigungs-TAN an {*********211}.',
      title: 'Authentification required',
    },
    timeout: 'Remaining time till retry entry password',
    title: 'Login',
    existing_user_invite_title:
      'Du hast die Einladung erfolgreich angenommen. Jetzt anmelden.',
  },
  password: {
    change: {
      btnSubmit: 'Save password and login',
      passwordNew: 'New password',
      passwordNewConfirm: 'Confirm new password',
      text: 'Bitte wähle dein zukünftiges Passwort.',
      title: 'Änderung deines Passworts',
    },
    forgot: 'Forgot password',
    requested: {
      text: 'Du erhältst in Kürze eine E-Mail zur Bestätigung und Erstellung deines neuen Passworts. Schaue bitte vorsichtshalber auch in deinem Spam-Ordner nach.',
      title: 'Du hast dein Passwort erfolgreich zurückgesetzt',
    },
    reset: {
      error:
        'Der von dir ausgewählte Link ist abgelaufen. Du kannst den Vorgang zum Zurücksetzen des Kennworts hier erneut starten.',
      text: 'Bitte gib Deine E-Mail-Adresse ein, um dein Passwort zurückzusetzen.',
      title: 'Reset password',
    },
    strength: 'Password strength',
    success: {
      text: 'Password reset success',
      title: 'Password reset success',
    },
    title: 'Password',
  },
  registration: {
    disclaimer:
      'Hiermit erkläre ich mein Einverständnis zur Verarbeitung meiner personenbezogenen Daten zu vorgenanntem Zweck. Ich kann mein Einverständnis jederzeit ganz oder teilweise mit Wirkung für die Zukunft durch einfache E-Mail (an support@pengueen.de) oder postalisch (M.F.G. Pengueen UG (haftungsbeschränkt), Damsdorfer Hauptstraße 41A, 14797 Kloster Lehnin) widerrufen.',
    info: '14-tägige kostenlose Testversion – Keine Kreditkarte notwendig',
    login: 'Du hast schon ein Konto? Melde dich hier an',
    new: 'New registration',
    privacy1: 'I accept the',
    privacy2: 'and the',
    title: 'Registration',
    title_invite:
      'Du hast deine Einladung erfolgreich angenommen. Registriere dich jetzt.',
    submit: 'Registration now',
    success: {
      text: 'Du erhältst in Kürze eine E-Mail zur Bestätigung deines Kontos. Schaue bitte vorsichtshalber auch in deinem Spam-Ordner nach.',
      title: 'Du hast dich erfolgreich registriert',
    },
    validation: {
      disclaimer: 'Dieser Erklärung muss zugestimmt werden.',
    },
  },
  trial: {
    expired: {
      admin: {
        button_payment_update: 'Jetzt Zahlungsdaten aktualisieren',
        text1:
          'Du und deine Nutzer können in Pengueen derzeit keine Inhalte editieren,',
        text2: 'Bestehende Inhalte können nur noch eingesehen werden.',
        text3:
          'Hat dir Pengueen gefallen? Trage einfach deine Zahlungsinformationen im',
        text4: 'Profil ein, um Pengueen weiterhin zu nutzen.',
        title: 'Dein 14-Tage-Test-Zeitraum ist leider abgelaufen',
      },
      button_contact: 'Kontakt mit uns aufnehmen',
      button_payment: 'Zu unseren Zahlungskonditionen',
      user: {
        button_inform: 'Jetzt Ansprechpartner informieren',
        text1:
          'Aufgrund von Einstellungen bei deiner Gemeinschaft, kannst du keine neunen Inhalte editieren oder Funktionen nutzen.',
        text2: 'Bestehende Inhalte können nur noch eingesehen werden.',
        text3:
          'Bitte melde dich bei dem Ansprechpartner deiner Gemeinschaft, um Pengueen weiterhin vollständig nutzen zu können.',
        title:
          'Deine Inhalte können derzeit nur noch eingesehen aber nicht bearbeitet werden',
      },
    },
  },
  validation: {
    passwordChar1: 'Your password must containt at least ',
    passwordChar2: ' 8 characters',
    passwordChar3: ' At least ',
    passwordChar4: '1 uppercase letter',
    passwordChar5: ' At least ',
    passwordChar6: '1 number',
    passwordChar7: ' At least ',
    passwordChar8: '1 special character',
    passwordChar9: '',
    passwordChar:
      'Your password must contain at least eight characters, at least one number, at least one uppercase letter and  at least one special character.',
    passwordConfirm: 'The password must match.',
    passwordReq: 'A password is required.',
    passwordStrength: 'Password strength',
    passwordCondition: 'Das Passwort muss alle Kriterien erfüllen.',
  },
};
