import { useTranslation } from 'react-i18next';

// Styles
import styles from './MIT.module.scss';

export const MIT = () => {
  const { t } = useTranslation();

  return (
    <>
      <p className={styles['mit-paragraph']}>{t('about.mit.text1')}</p>
      <p className={styles['mit-paragraph']}>{t('about.mit.text2')}</p>
      <p className={styles['mit-paragraph']}>{t('about.mit.text3')}</p>
      <p className={styles['mit-paragraph']}>{t('about.mit.text4')}</p>
      <p className={styles['mit-paragraph']}>{t('about.mit.text5')}</p>
    </>
  );
};
