import create, { State } from 'zustand';

// Models
import { DashboardData, ToolFavorite } from '../models/dashboard.types';

export interface DashboardState extends State {
  favoriteTools: ToolFavorite[];
  dashboardData: DashboardData | null | undefined;
  resetDashboardStoreData: () => void;
  setDashboardData: (dashboardData: DashboardData | null | undefined) => void;
  setFavoriteTools: (favoriteTools: ToolFavorite[]) => void;
}

export const initialDashboardStoreData = {
  dashboardData: null,
};

export const useDashboardStore = create<DashboardState>((set) => ({
  ...initialDashboardStoreData,
  favoriteTools: [],
  resetDashboardStoreData: () =>
    set((state: DashboardState) => ({
      ...state,
      ...initialDashboardStoreData,
    })),
  setDashboardData: (dashboardData: DashboardData | null | undefined) =>
    set({ dashboardData }),
  setFavoriteTools: (favoriteTools: ToolFavorite[]) => set({ favoriteTools }),
}));
