import { ContactsTranslations } from '../models/contacts.translations.interface';

export const ContactsTranslationsEN: ContactsTranslations = {
  all: 'VIEW ALL CONTACTS',
  all_loading: 'Load all contacts',
  toolbar: {
    export: 'Export list as .csv',
    show: {
      navigation: 'Show Navigation',
    },

    filter: {
      search: 'Suchen',
    },
    community: {
      add_group: 'Gruppe anlegen',
      edit_group: 'GRUPPE SPEICHERN',
      add_tool: 'Hilfsmittelauswahl',
      add_tool1: 'Hilfsmittel',
      add_tool2: 'Auswahl',
      select_tool:
        'Wähle ein oder mehrere Hilfsmittel aus, die du zuordnen möchtest.',
    },
    group: {
      success: {
        singular: '{{userName}} wurde erfolgreich hinzugefügt.',
        plural: '{{userName}} wurden erfolgreich hinzugefügt.',
      },
      setting: 'Einstellungen',
      add_person: 'Personen hinzufügen',
      invite_person: 'Personen einladen',
      create: 'Deine Gruppe erfolreich erstellt.',
    },
    invite: {
      community: 'Gemeinschaft Auswählen',
      group: 'Gruppe Auswählen',
      tool: 'Hilfsmittel Auswählen',
    },
    label: {
      community: 'Arbeitet ihr zusammen in einer Gemeinschaft?',
      group: 'Add these users directly to existing groups (optional)',
      tool: 'Create new individual tools for these users (optional)',
    },
    link: {
      title: 'Freigabelink',
    },
  },
  nav: {
    no_community: {
      group: {
        title: 'Groups without community',
      },
    },
    footer: {
      title: 'Kommunikation und Organisation im Team!',
      content:
        'Du kannst Gemeinschaften anlegen, um mit den zugeordneten Freunden, Kolleg:Innen, Kunden, Mitgliedern, .. bequem gemeinsam die Hilfsmittel zu nutzen und euch auszutauschen.',
      button: 'Zusammen arbeiten',
    },
  },
  delete: {
    this_contact: 'diesen Kontakt',
    this_contact_big: 'Diesen Kontakt',
    alert: {
      text1: 'Bist du sicher, dass du',
      text2: 'aus der Gemeinschaft entfernen möchtest?',
      text3: 'aus dieser Gruppe entfernen möchtest?',
    },
    notification: {
      result: {
        title: ' wurde aus deinen Kontakten entfernt.',
      },
      room: {
        title1: 'Es werden deine Nachrichten an ',
        this_contact: 'diesem Kontakt',
        title2: ' gelöscht.',
      },
      community: {
        title1: ' wird aus ',
        title2: ' Gruppen entfernt.',
      },
    },
  },
  teaser: {
    invite: 'Kontakte einladen',
    group: 'Kontakte gruppen',
  },
  actions: {
    add: 'Add to my contacts',
    message: 'Message',
    reinvite: 'Reinvite',
    view: 'View',
  },
  add: {
    error: 'Es ist ein Fehler beim Hinzufügen aufgetreten:',
    success: 'Kontakt eingeladen',
  },
  community: {
    title: 'Community',
  },
  connection: {
    direct: 'Direct',
    indirect: 'Indirect',
    title: '',
  },
  detail: {
    tools: {
      add: 'Add',
      title: 'Zugewiesene Hilfsmittel',
    },
    tooltip: {
      edit_nickname: 'Edit nickname',
      upgrade: 'upgrade',
      downgrade: 'downgrade',
    },
  },
  filters: {
    button: 'Ansicht',
    search: 'Person suchen',
    title: 'New filter',
  },
  invite: {
    reinvite: 'Einladung wiederholen',
    error: {
      emails: {
        multi: 'Du hast die Nutzer mit den E-Mail-Adressen ',
        single: 'Du hast den Nutzer mit der E-Mail-Adresse ',
        text: 'bereits zu deinen Kontakten eingeladen.',
      },
    },
    info: `Für Personen, die du via E-Mail-Adresse eingeladen hast, gilt: Wir werden der eingeladenen Person eine Einladungsmail schicken. Du kannst aber vorher schon Nachrichten schreiben. Er oder sie kann automatisch auf die Nachrichten zugreifen, sobald die Anmeldung abgeschlossen und die E-Mail Adresse bestätigt ist.`,
    submit: 'Send invite',
    text: 'Invite new users to Pengueen',
    title: 'Invite',
  },
  role: {
    title: 'Zugewiesene Rolle',
  },
  state: {
    active: 'Active',
    declined: 'Declined',
    indirect: 'Indirect',
    invited: 'Invited',
    requested: 'Requested',
    expired: 'Abgelaufen',
    title: 'State',
  },
  title_page: 'Your Contacts',
  unapproved: 'Unapproved',
  view_on_dashboard: 'View on dashboard',
  title: 'Network',
  tools_empty: 'Keine Hilfsmittel vorhanden!',
};
