import { memo, ReactChild } from 'react';
import { Box } from '@mui/material';

// Styles
import styles from './PublicPage.module.scss';

type PublicPageProps = {
  children: ReactChild;
};

const PublicPage = (props: PublicPageProps) => {
  return (
    <Box>
      <Box
        className={styles['public-page']}
        sx={{
          backgroundColor: 'background.paper',
        }}
      >
        {props.children}
      </Box>
      <Box
        className={styles['public-page-background']}
        sx={{
          backgroundColor: 'primary.main',
        }}
      />
    </Box>
  );
};

export default memo(PublicPage);
