import { NewsCenterTranslations } from '../models/newscenter.translations.interface';

export const newscenterTranslationsEN: NewsCenterTranslations = {
  network: {
    title: 'Your Network',
  },
  godparents: {
    title: 'Questions about Pengueen?',
    subtitle1: 'invited you to Pengueen',
    subtitle2: 'and can certainly help you.',
  },
  no_chat: {
    private:
      "Haven't written or received a message yet? Use the blue plus button and start writing right away.",
    group:
      "You don't have any groups yet. Put one on now. Use the blue plus button to do this",
  },
  file: {
    size: 'Unfortunately your file is larger than 7 MB. We have created a help article for you on how you can reduce the file size (for the sake of the environment): ',
    link: 'to the article.',
    type: 'Unfortunately, this file format is not currently supported.',
  },
  info: {
    join: {
      title: 'has entered the chat room.',
    },
  },
  remove: {
    text1: ' from group ',
    text2: 'removed.',
  },
  invite: {
    text1: ' has ',
    text2: 'invited.',
    success: '{{userName}} was(were) successfully invited',
    fail: '{{userName}} is(are) already in the chat room.',
  },
  leave: {
    direct: 'left the chat room.',
  },
  close: {
    group: 'closed this group',
  },
  favorite: {
    remove: 'Remove as favorite',
    mark: 'Mark as favorite',
  },
  latest: {
    read: 'Favorit.1 read',
    unread: 'Favorit.2 unread',
    title: 'Current',
    show: {
      no: 'Show less',
      group: 'Show older message groups',
      direct: 'Show older private messages',
    },
  },
  teaser: {
    new_direct: 'New direct message',
    new_group: 'New Group',
    image: 'Message Image',
  },
  actions: {
    broadcast: 'Broadcast Message',
    group: 'New Group',
    direct: 'New direct message',
    title: 'New Message',
  },
  filters: {
    current: {
      title: 'Current',
    },
    group: {
      old_view: 'Show older newsgroups',
      communities: 'communities',
      none: 'No grouping',
    },
    show: {
      label: 'Show groups and messages by',
      no_communities:
        'None of your group chats are currently linked to a community',
    },
  },
  groups: {
    create_edit: {
      settings: {
        buttons: {
          create: 'Continue to participants',
          edit: 'Edit group settings',
        },
        community: {
          error: 'A community must be selected',
          label: 'Which community should the group be assigned to?',
        },
        description: {
          error: 'A group description is required',
          label: 'Describe the purpose of the group in brief words',
          placeholder: 'This group can be used for ...',
        },
        name: {
          error: 'A group name is required',
          label: 'What should your group be called?',
          placeholder: 'e.g. Protocol',
        },
        message: 'Activate message function for members',
        no_include_creator: 'Do not add me to this group',
        folder: 'Should the group be placed in a folder?',
        title: {
          create: 'Create your group',
          edit: 'Edit your group',
        },
      },
      users: {
        buttons: {
          back: 'Back to settings',
          create: 'Create group',
          edit: 'Edit group',
        },
        title: 'Add participants',
      },
    },
    old: 'Show older newsgroups',
    no_community: 'Not assigned to any community',
    subtitle: 'Group chat',
    title: 'Groups',
  },
  placeholder: {
    text1: 'Please select a group chat or direct message.',
    text2:
      'You can create a new group chat or write a new direct message. To do this, click on “New Message” at the top left.',
  },
  room: {
    direct: {
      delete: {
        subtitle: 'Your chat will be deleted and cannot be restored.',
        text: 'Delete chat now?',
        title: 'Delete chat',
      },
      message: {
        placeholder: 'Write a message...',
      },
      subtitle: 'Chat with',
      title: 'Private messages',
      unread_msgs: {
        multi: 'New Messages',
        single: 'New Message',
      },
      read_msgs: {
        title:
          'You can no longer edit this message because it has already been read.',
      },
      add: {
        title: 'New direct message',
      },
    },
    groups: {
      delete: {
        editor: 'You can no longer write messages in this group',
        subtitle:
          'The group and all messages will be deleted and cannot be restored.',
        text: 'Delete group now?',
        title: 'Delete group',
        notification: 'The chat has been successfully deleted',
      },
      leave: {
        warning:
          'Are you sure? If you close this group, all your messages will be irrevocably deleted and the participants will no longer be able to exchange ideas!',
        text: 'Leave group',
        title: 'You have successfully left the chat',
        question: 'Leave the group now',
        matches: 'Leave',
      },
      close: {
        text: 'Close group',
        title: 'You have successfully closed the chat',
        question: 'Close group now',
        matches: 'Close',
      },
      info: 'Welcome to the group',
      link: {
        title: 'Link community',
      },
      tools: {
        part: {
          btn: 'Disconnect',
          match: 'DIVISION',
          subtitle: 'The group then no longer has access to the resource',
          text: 'Disconnect now?',
          title1: 'Do you want to connect to',
          title2: 'separate?',
        },
        title: 'Connected tools',
      },
      users: {
        add: {
          title: 'Add user',
        },
        remove: {
          subtitle:
            'will be removed from the group and will lose his access rights',
        },
        title: 'Users',
      },
    },
    message: {
      delete: {
        subtitle: 'The message will be deleted and cannot be restored.',
        text: 'Delete message now?',
        title: 'Are you sure you want to delete this message?',
      },
      edited: 'edited',
      tooltip: 'Editing not possible, message has already been read.',
    },
    notifications: {
      title: 'One-time notification',
      tooltip:
        'Sends a notification via email or SMS of a new message. The content of the message will not be displayed in this notification.',
    },
    quote: {
      at: 'at',
      title: 'Quote',
    },
    typing: {
      double: 'are typing',
      multi: 'users are typing',
      single: 'is typing',
    },
  },
  title: 'News center',
  write: 'Write messages',
};
