import { AppTranslations } from '../models/app.translations.interface';

export const appTranslationsIT: AppTranslations = {
  search: 'Cerca',
  no_results: 'nessun risultato trovato',
  group: 'aggruppare',
  download: 'Download',
  download_as_csv: 'Scarica come CSV',
  print: 'Stampa',
  unfold: 'Indicare',
  website: {
    to: 'a nostro sito',
  },
  data_protection: 'protezioni dei dati',
  security_info: 'Manuale delle sicurezze informatiche',
  contact: {
    text1: '',
    text2: '',
    text3: '',
  },
  actions: {
    create: 'Creare',
    delete: 'Cancellare',
    edit: 'Editare',
    title: 'azioni',
  },
  understood: 'Compreso, tutto chiaro',
  all: 'Tutto',
  and: 'E',
  apply: 'applicare',
  back: 'Indietro',
  back_to_summary: 'Torna al riepilogo',
  cancel: 'Cancella',
  check: 'Controllare',
  coming_soon: 'Viene presto',
  communities: 'Comunità',
  employee: 'Employee',
  member: 'Membro',
  admin: 'Admin',
  confirm: 'Confermare',
  continue: 'Continuare',
  data_grid: {
    rows_selected: {
      multi: 'File selezionate',
      single: 'Fila selezionata',
    },
  },
  date: {
    date: {
      placeholder: 'Seleziona una data',
      title: 'La data',
    },
    date_time: {
      placeholder: 'Seleziona una data e l`ora',
      title: 'Data e l`ora',
    },
    time: {
      placeholder: 'Scegli un orario...',
      title: 'Tempo',
    },
  },
  deselect: 'Deselezionare',
  email: 'Email',
  emailAddress: 'Indirizzo email',
  emoji_picker: {
    search: 'Cerchi',
    not_found: 'Emoji non trovato',
    categories: {
      search: 'Cerchi risultati',
      recent: 'usato spesso',
      people: 'persone e corpo',
      nature: 'animali e natura',
      foods: 'Cibo e bevande',
      activity: 'attività',
      places: 'viaggi e posti',
      objects: 'Oggetti',
      symbols: 'Simboli',
      flags: 'bandiera',
      custom: 'abitudine',
    },
  },
  employees: 'Impiegati',
  favorites: 'Favoriti',
  fetch: {
    error: {
      fail: 'un errore manifestava',
      response: 'Risposta di rete non era ok',
      unauthorized: 'Non autorizzato',
      unknown: 'Errore sconosciuto',
    },
  },
  file_drop: {
    placeholder1: "Seleziona un'immagine dai tuoi file.",
    placeholder2: 'file',
    placeholder3: '.',
  },
  filters: {
    group: 'Gruppo di',
    headline: 'Opzioni filtri',
    title: 'Filtri',
  },
  groups: 'Gruppi',
  toolLinks: 'Link strumento',
  hello: 'Ciao',
  language: 'Lingua',
  language_text: `Scegli la lingua dell'Interfaccia di Pengueen.`,
  leaflet: 'prospetto',
  likeMark: 'Segna preferito',
  members: 'Membri',
  name: 'Nome',
  nameless: 'Senza nome',
  nickname: 'Nomignolo',
  no: 'No',
  of: 'Di',
  ok: 'Ok',
  optional: 'Opzionale',
  reset: 'Resettare',
  roles: {
    admin: 'admin',
    employee: 'Employee',
    member: 'Membro',
    guest: 'Ospito',
  },
  save: 'Salvare',
  select: 'Selezionare',
  selectNoOptions: 'No opzioni',
  settings: 'Impostazioni',
  sms: 'Sms',
  telephone: 'Telefono',
  title: 'Pengueen, cosi riesce comunità',
  unlikeMark: 'Unlike favorite',
  users: {
    invite: {
      button: 'Invita',
      error: 'un errore manifestava al invito',
      subtitle1: 'puoi con',
      subtitle2: 'Scrivi dopo',
      subtitle3: 'hai invitato ai tui contatti',
      success: 'Contatto invitato',
      title: 'Invitare a miei contatti',
    },
    remove: {
      btn: 'Cancellare ora',
      match: 'Cancellare',
      text1: 'Ora',
      text2: 'Cancellare?',
      title1: 'vuoi',
      title2: 'Cancellare veramente?',
    },
  },
  verify: 'verificare',
  yes: 'Si',
  you: 'Tu',
  yourPengueenTeam: 'Il tuo team Pengueen',
  error: {
    fileSizeTooSmall: 'Grandezza dei dati è troppo piccolo',
    fileSizeToolarge: 'Grandezza dei dati è toppo grande',
    readerError: 'Mentre leggere il file,  manifestava un problema',
    maxLimitExceeded: 'Troppi file',
    minLimitNotReached: 'File non sono sufficiente',
    imageHeightTooBig: 'L`altezza dell` immagine non è giusta',
    imageWidthTooBig: 'La larghezza dell`immagine non è giusta',
    imageHeightTooSmall: ' l`altezza dell` immagine non è giusta',
    imageWidthTooSmall: 'La larghezza dell`immagine non è giusta',
  },
};
