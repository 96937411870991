import { ReactChild, useEffect, useState } from 'react';
import { TFunctionResult } from 'i18next';
import { Box } from '@mui/material';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';

// Components
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';

// Models
import { ResultState } from '../../models/shared.types';

// Styles
import styles from './ResultBox.module.scss';

type ResultBoxProps = {
  children: ReactChild | TFunctionResult;
  classes?: string;
  state: ResultState;
  onClose?: () => void;
};

export const ResultBox = (props: ResultBoxProps) => {
  const [bgColor, setBgColor] = useState<string | undefined>(undefined);
  const [color, setColor] = useState<string | undefined>(undefined);
  const [icon, setIcon] = useState<[IconPrefix, IconName]>([
    'fas',
    'exclamation-triangle',
  ]);

  useEffect(() => {
    switch (props.state) {
      case ResultState.Error:
        setBgColor('error.light');
        setColor('error.main');
        break;
      case ResultState.Info:
        setBgColor('info.light');
        setColor('info.main');
        // setIcon(['fas', 'info-circle']);
        break;
      case ResultState.Success:
        setBgColor('success.light');
        setColor('success.main');
        setIcon(['fas', 'check-circle']);
        break;
      case ResultState.Warning:
        setBgColor('warning.light');
        setColor('warning.main');
        break;
      default:
        break;
    }
  }, [props]);

  return (
    <Box
      sx={{
        backgroundColor: bgColor,
        color,
      }}
      className={clsx(styles['result-box'], props.classes && props.classes)}
    >
      <div className={styles['result-box-content']}>
        <Icon
          classes={styles['result-box-content-icon']}
          icon={icon}
          sx={{ color }}
        />
        <Box className={styles['result-box-content-text']}>
          {props.children}
        </Box>
      </div>
      {props.onClose && (
        <IconButton
          classes={styles['result-box-close']}
          icon={['fas', 'times']}
          sxIcon={{ color }}
          onClick={props.onClose}
        />
      )}
    </Box>
  );
};
