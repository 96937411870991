import { Box } from '@mui/material';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useSharedStore } from '../../../../shared/stores/use-shared.store';

// Components
import { Icon } from '../../../../shared/ui/Icon/Icon';
import NewsCenterChat from '../../../newscenter/components/NewsCenterChat/NewsCenterChat';
import { RoomType } from '../../../newscenter/models/news-center.types';
import { useNewsCenterStore } from '../../../newscenter/stores/use-news-center.store';

// Styles
import styles from './GodparentsChat.module.scss';

export const GodparentsChat = () => {

  // Component state
  const [chatOpen, setChatOpen] = useState<boolean>(false);
  const [unreadMsg, setUnreadMsg] = useState<number | undefined>(undefined);

  // useNewscenterStore
  const [godparentsChat] = useNewsCenterStore((state) => [
    state.godparentsChat,
  ]);

  // useSharedStore
  const [dashboard] = useSharedStore((state) => [state.dashboard]);

  useEffect(() => {
    if (godparentsChat?.unread_msgs?.toString()) {
      setUnreadMsg(godparentsChat.unread_msgs);
    }
  }, [godparentsChat?.messages]);

  return (
    <>
      {dashboard && godparentsChat && (
        <Box className={styles['godparents-chat']}>
          <Box
            className={chatOpen ? styles['godparents-chat-window'] : 'hidden'}
            sx={{ bgcolor: '#F0F4F8' }}
          >
            <Box
              className={styles['godparents-chat-window-background']}
              sx={{ bgcolor: 'bg.darkgray' }}
            ></Box>
            <Box className={styles['godparents-chat-window-body']}>
              <NewsCenterChat
                type={RoomType.GodParent}
                roomId={godparentsChat.id}
              />
            </Box>
          </Box>

          {/* {showMessage && !chatOpen && (
              <Box className="flex flex-col items-end">
                <Box className="max-w-md w-fit">
                  <Box
                    sx={{ borderColor: 'text.primary' }}
                    className={styles['godparents-chat-message-container']}
                  >
                    <Box
                      sx={{ color: '#9E9EA0' }}
                      className="flex items-center mb-1"
                    >
                      {godparentsChatPartner?.profile?.avatar?.url ? (
                        <img
                          alt="Avatar"
                          className={styles['godparents-chat-partner-image']}
                          src={godparentsChatPartner.profile?.avatar?.url}
                        />
                      ) : (
                        <ImageFallback
                          sizeClassName={
                            styles['godparents-chat-partner-image']
                          }
                          type={ImageFallbackType.Profile}
                        />
                      )}
                      {godparentsChat.settings?.name !== 'undefined'
                        ? godparentsChat.settings?.name
                        : t('app.nameless')}
                    </Box>
                    <Box
                      className={
                        styles[
                          'godparents-chat-message-container-content-text-html'
                        ]
                      }
                      color={'text.primary'}
                      sx={{
                        '& a': {
                          color: 'text.primary',
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      <Box
                        sx={{ ...sxContentTextHtmlSize }}
                        dangerouslySetInnerHTML={{
                          __html: `${messageContent}`,
                        }}
                      />
                    </Box>
                  </Box>

                  {godparentChatMessages!.images &&
                    godparentChatMessages!.images.length > 0 && (
                      <Box className={styles['godparents-chat-message-data']}>
                        {godparentChatMessages!.images.map((image, index) => (
                          <a
                            key={`${image.id}${index}`}
                            href={image.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <img
                              alt="Preview"
                              src={image.url}
                              className={
                                styles['godparents-chat-message-data-image']
                              }
                            />
                          </a>
                        ))}
                      </Box>
                    )}
                </Box>
                <Button
                  className="godparents-chat-answer-button"
                  sx={{
                    color: 'white',
                    bgcolor: 'primary.main',
                    padding: '10px 20px',
                    margin: '8px 8px 40px 8px',
                  }}
                  onClick={() => setChatOpen(!chatOpen)}
                >
                  {t('godparentschat.button.answer')}
                </Button>
              </Box>
            )} */}
          <Box
            className={styles['godparents-chat-icon']}
            sx={{
              bgcolor: 'bg.darkgray',
              color: 'ffffff',
            }}
            onClick={() => setChatOpen(!chatOpen)}
          >
            {chatOpen ? (
              <Icon
                sx={{ color: 'white' }}
                icon={['fas', 'times']}
                size="large"
                classes={styles['godparents-chat-icon-button']}
              />
            ) : (
              <Icon
                sx={{ color: 'white' }}
                icon={['fal', 'messages']}
                size="large"
                classes={styles['godparents-chat-icon-button']}
              />
            )}
            {unreadMsg && (
              <Box
                bgcolor="success.main"
                color="white"
                className={clsx(styles['godparents-unread'], 'font-bold')}
              >
                {unreadMsg}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
