import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Drawer as MuiDrawer } from '@mui/material';

// Components
import { IconButton } from '../../ui/IconButton/IconButton';
import IconSwitchButton from '../../ui/IconSwitchButton/IconSwitchButton';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import { NavLink } from '../NavLink/NavLink';
import { TextButton } from '../../ui/TextButton/TextButton';

// Hooks
import { useBreakpoints } from '../../hooks/use-breakpoints.hook';
import { useLogout } from '../../../modules/public/hooks/use-logout.hook';

// Models
import { ImageFallbackType, Theme } from '../../models/shared.types';
import {
  LanguageUpdate,
  UserInfoState,
} from '../../../modules/user/models/user.types';

// Stores
import { SharedState, useSharedStore } from '../../stores/use-shared.store';
import {
  UserState,
  useUserStore,
} from '../../../modules/user/stores/use-user.store';

// Styles
import styles from './Drawer.module.scss';
import { useMutation } from 'react-query';
import { useFetch } from '../../hooks/use-fetch.hook';
import { useUsersHttp } from '../../../modules/user/hooks/use-users-http.hook';
import { Select } from '../../ui/Select/Select';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const Drawer = () => {
  const { smDown, lgDown } = useBreakpoints();
  const { logout } = useLogout();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleError, handleRetry } = useFetch();
  const { userThemePost, logoutPost, userLanguageUpdate } = useUsersHttp();

  // Shared store state
  const [theme, setTheme] = useSharedStore((state: SharedState) => [
    state.theme,
    state.setTheme,
  ]);

  // User store state
  const [
    account,
    drawer,
    profile,
    setAccount,
    setDrawer,
    setActiveCommunity,
    setSettingsActive,
  ] = useUserStore((state: UserState) => [
    state.account,
    state.drawer,
    state.profile,
    state.setAccount,
    state.setDrawer,
    state.setActiveCommunity,
    state.setSettingsActive,
  ]);

  // #### //
  // FORM //
  // #### //

  const { setValue, watch } = useForm<LanguageUpdate>();

  const watchLanguage = watch('language') ?? account?.language;

  // ######### //
  // MUTATIONS //
  // ######### //

  // GET community tools mutation
  const userThemePostMutation = useMutation(
    (data: any) => userThemePost(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        // Handle GET contacts
        if (data) {
          setTheme(data.theme);
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            console.error(
              'Error getting contact tools:',
              handleError(errRes.status)
            );
          }
        }
      },
    }
  );

  // GET contacts mutation
  const logoutPostMutation = useMutation(() => logoutPost(), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
  });

  // PATCH user language mutation
  const languageUpdateMutation = useMutation(
    (data: LanguageUpdate) => userLanguageUpdate(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          const newAccount = account;
          if (newAccount) {
            newAccount.language = data.language;
            setAccount(newAccount);
          }
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            console.error(
              'Error updating user language:',
              handleError(errRes.status)
            );
          }
        }
      },
    }
  );

  /**
   * Handler to close menu.
   */
  const onDrawerClose = useCallback(() => {
    setDrawer(false);
    // eslint-disable-next-line
  }, []);

  /**
   * Handler to logout.
   */
  const onLogout = useCallback(() => {
    logout();
    logoutPostMutation.mutate();
    setDrawer(false);
    // #TODO: Blacklist token?
    navigate('/');
    // eslint-disable-next-line
  }, []);

  /**
   * Toggle application theme
   */
  const onThemeToggle = useCallback(() => {
    userThemePostMutation.mutate({
      theme: theme === Theme.Light ? Theme.Dark : Theme.Light,
    });
    // eslint-disable-next-line
  }, [theme]);

  return (
    <MuiDrawer
      anchor="right"
      classes={{
        paper: styles['drawer-paper'],
      }}
      open={drawer}
      onClose={onDrawerClose}
      className={styles['drawer']}
    >
      <Box className={styles['drawer-header']}>
        <Box className={styles['drawer-header-title']}>{t('user.title')}</Box>
        <IconButton icon={['fas', 'times']} onClick={onDrawerClose} />
      </Box>
      <Box
        className={styles['drawer-user']}
        sx={{
          borderColor: 'border.app',
        }}
      >
        {profile?.avatar?.url ? (
          <img
            alt="User Avatar"
            className={styles['drawer-user-icon']}
            src={profile.avatar.url}
          />
        ) : (
          <ImageFallback
            iconSize={smDown ? '2.5em' : '3em'}
            sizeClassName={styles['drawer-user-icon']}
            type={ImageFallbackType.Profile}
          />
        )}
        <Box className={styles['drawer-user-info']}>
          <Box className={styles['drawer-user-info-name']}>
            {profile?.personal_data
              ? `${profile.personal_data.first_name ?? ''} ${
                  profile.personal_data.last_name ?? ''
                }`
              : null}
          </Box>
          <TextButton
            textSize={smDown ? 'text-xs' : lgDown ? 'text-sm' : 'text-lg'}
            padding={lgDown ? '0.4rem 0.75rem' : undefined}
            preset="secondary"
            onClick={onLogout}
          >
            {t('user.logout')}
          </TextButton>
        </Box>
      </Box>
      <Box
        sx={{
          borderColor: 'border.app',
        }}
        className={styles['drawer-nav']}
      >
        <NavLink
          icon={['far', 'user']}
          link="/user/profile"
          drawerItem
          state={UserInfoState.Profile}
          subtitle={t('user.profile.subtitle')}
          title={t('user.profile.title')}
          onClick={() => setSettingsActive(UserInfoState.Profile)}
        />
        <NavLink
          icon={['fas', 'shield-alt']}
          link="/user/security"
          drawerItem
          state={UserInfoState.Security}
          subtitle={t('user.security.subtitle')}
          title={t('user.security.title')}
          onClick={() => setSettingsActive(UserInfoState.Security)}
        />
        <NavLink
          icon={['fas', 'layer-group']}
          link="/user/account"
          drawerItem
          state={UserInfoState.Account}
          subtitle={t('user.account.subtitle')}
          title={t('user.account.title')}
          onClick={() => setSettingsActive(UserInfoState.Account)}
        />
        <NavLink
          // chldrn={navigationCommunities}
          icon={['fas', 'building']}
          link="/user/community"
          drawerItem
          state={UserInfoState.Community}
          subtitle={t('user.community.subtitle')}
          title={t('user.community.title')}
          onClick={() => {
            setSettingsActive(UserInfoState.Community);
            setActiveCommunity(undefined);
          }}
        />
        <NavLink
          icon={['fas', 'folder-open']}
          link="/user/documents"
          drawerItem
          state={UserInfoState.Documents}
          subtitle={t('user.documents.subtitle')}
          title={t('user.documents.title')}
          onClick={() => setSettingsActive(UserInfoState.Documents)}
        />
      </Box>
      <Box
        className={styles['drawer-settings']}
        sx={{
          borderColor: 'border.app',
        }}
      >
        <Box className={styles['drawer-settings-theme-title']}>
          {t('user.theme.title')}
        </Box>
        <Box
          sx={{ color: 'text.secondary ' }}
          className={styles['drawer-settings-theme-subtitle']}
        >
          {t('user.theme.subtitle')}
        </Box>
        <Box className={styles['drawer-settings-theme-switch']}>
          <Box className={styles['drawer-settings-theme-switch-text']}>
            {t('user.theme.dark')}
          </Box>
          <IconSwitchButton
            checked={theme === Theme.Light ? true : false}
            classes={styles['drawer-settings-theme-switch-button']}
            iconOff={['fas', 'moon']}
            iconOn={['fas', 'sun']}
            sameColor
            onChange={onThemeToggle}
          />
          <Box className={styles['drawer-settings-theme-switch-text']}>
            {t('user.theme.light')}
          </Box>
        </Box>
      </Box>
      <Box className={styles['drawer-language']}>
        {/* LANGUAGE */}
        <form>
          <Box className={styles['drawer-settings-theme-title']}>
            {t('app.language')}
          </Box>
          <Box className={styles['drawer-settings-theme-subtitle']}>
            {t('app.language_text')}
          </Box>
          <Box sx={{ color: 'text.secondary ' }}>
            <Select
              options={[
                {
                  placeholder: t('user.account.language.lng.de'),
                  value: 'de',
                },
                {
                  placeholder: t('user.account.language.lng.en'),
                  value: 'en',
                },
                {
                  placeholder: t('user.account.language.lng.it'),
                  value: 'it',
                },
              ]}
              value={watchLanguage}
              onChange={(value) => {
                setValue('language', value);
                languageUpdateMutation.mutate({ language: value });
              }}
            />
            <Box
              sx={{ color: 'text.secondary' }}
              className={styles['drawer-language-footer']}
            >
              {t('user.account.language.text')}
            </Box>
          </Box>
        </form>
      </Box>
    </MuiDrawer>
  );
};
