import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// Components
import { Loader } from '../shared/ui/Loader/Loader';

// Models
import { RoomType } from '../modules/newscenter/models/news-center.types';
import NewsCenterChat from '../modules/newscenter/components/NewsCenterChat/NewsCenterChat';

// Lazy-load pages
const NewsCenter = lazy(
  () => import('../modules/newscenter/pages/NewsCenter/NewsCenter')
);
const NewsCenterPrint = lazy(
  () => import('../modules/newscenter/pages/NewsCenter/NewsCenter_Print')
);

export const NewsCenterRouter = () => {
  return (
    <Routes>
      <Route
        path=""
        element={
          <Suspense fallback={<Loader />}>
            <NewsCenter />
          </Suspense>
        }
      />
      <Route
        path="groups/:id"
        element={
          <Suspense fallback={<Loader />}>
            <NewsCenter type={RoomType.Group} />
          </Suspense>
        }
      />
      <Route
        path="private/:id/printview"
        element={
          <Suspense fallback={<Loader />}>
            <NewsCenterPrint type={RoomType.Direct} />
          </Suspense>
        }
      />
      <Route
        path="groups/:id/printview"
        element={
          <Suspense fallback={<Loader />}>
            <NewsCenterPrint type={RoomType.Group} />
          </Suspense>
        }
      />
      <Route
        path="private/:id"
        element={
          <Suspense fallback={<Loader />}>
            <NewsCenter type={RoomType.Direct} />
          </Suspense>
        }
      />
      <Route
        path="godparents/:id"
        element={
          <Suspense fallback={<Loader />}>
            <NewsCenterChat type={RoomType.Direct} />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
