import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { Box, Button, Popover } from '@mui/material';
import clsx from 'clsx';

// Component
import { Dialog } from '../../../../shared/ui/Dialog/Dialog';
import { FormDelete } from '../../../../shared/components/FormDelete/FormDelete';
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';
import { ImageFallback } from '../../../../shared/components/ImageFallback/ImageFallback';
import { PopoverImagePreviewItem } from '../../../../shared/components/PopoverImagePreviewItem/PopoverImagePreviewItem';

// Models
import { ImageFallbackType } from '../../../../shared/models/shared.types';
import { Tool } from '../../../tools/models/tools.types';

// Stores
import { useNewsCenterStore } from '../../stores/use-news-center.store';
import { useUserStore } from '../../../user/stores/use-user.store';

// Styles
import styles from './GroupTools.module.scss';

type GroupToolItemProps = {
  tool: Tool;
};

const GroupToolItem = (props: GroupToolItemProps) => {
  const { t } = useTranslation();

  // Component state
  const [selectedToolToRemove, setSelectedToolToRemove] = useState<
    Tool | undefined
  >(undefined);

  // News center store state
  const [selectedRoom] = useNewsCenterStore((state) => [state.selectedRoom]);

  // User store state
  const [account] = useUserStore((state) => [state.account]);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handler to init user removal from group.
   * @param user User
   */
  const onRemoveInit = useCallback(
    () => {
      setSelectedToolToRemove(props.tool);
    },
    // eslint-disable-next-line
    [props.tool]
  );

  return (
    <Box className={styles['group-tool-item']}>
      <div className={styles['group-tool-item-title']}>
        <>
          {props.tool.template.image_preview?.url ? (
            <img
              alt={props.tool.name}
              className={styles['group-tool-item-title-image']}
              src={props.tool.template.image_preview.url}
            />
          ) : (
            <ImageFallback
              iconSize="0.8em"
              sizeClassName={styles['group-tool-item-title-image']}
              type={ImageFallbackType.Profile}
            />
          )}
        </>
        <Box
          sx={{ color: 'text.secondary' }}
          className={styles['group-tool-item-title-text']}
        >
          {props.tool.name}
        </Box>
      </div>
      <div className={styles['group-tool-item-actions']}>
        <IconButton
          disabled={
            props.tool.id === account?.id ||
            !selectedRoom?.permissions.can_add_user
          }
          icon={['fas', 'trash']}
          iconColor="text.secondary"
          onClick={onRemoveInit}
        />
      </div>
      <Dialog
        open={selectedToolToRemove ? true : false}
        subtitle={`${t('newscenter.room.groups.tools.part.subtitle')} ${
          selectedToolToRemove?.name ?? ''
        }`}
        title={`${t('newscenter.room.groups.tools.part.title1')} ${
          selectedToolToRemove?.name ?? ''
        } ${t('newscenter.room.groups.tools.part.title2')}`}
        onClose={() => setSelectedToolToRemove(undefined)}
      >
        <FormDelete
          text={t('newscenter.room.groups.tools.part.text')}
          textButton={t('newscenter.room.groups.tools.part.btn')}
          textMatch={t('newscenter.room.groups.tools.part.match')}
          onClose={() => setSelectedToolToRemove(undefined)}
          onSubmit={() => {}}
        />
      </Dialog>
    </Box>
  );
};

type GroupToolsProps = {
  classes?: string;
  icon?: [IconPrefix, IconName];
  tools: Tool[];
};

export const GroupTools = (props: GroupToolsProps) => {
  const { t } = useTranslation();

  // Component state
  const [anchorEl, setAnchorEl] = useState<Element | null | undefined>(null);
  const open = Boolean(anchorEl);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Close menu.
   */
  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <>
        {props.icon ? (
          <IconButton
            classes={props.classes && props.classes}
            icon={props.icon}
            preset="popover"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          />
        ) : (
          <Button
            color="inherit"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            className={clsx(
              styles['group-tools-button'],
              props.classes && props.classes
            )}
          >
            <ul className={styles['group-tools-button-preview']}>
              {props.tools.length > 0 && (
                <PopoverImagePreviewItem
                  name={props.tools[0].name ?? 'Tool'}
                  src={props.tools[0].template.image_preview?.url}
                  type={ImageFallbackType.Tool}
                />
              )}
              {props.tools.length > 1 && (
                <PopoverImagePreviewItem
                  name={props.tools[1].name ?? 'Tool'}
                  overlap
                  src={props.tools[1].template.image_preview?.url}
                  type={ImageFallbackType.Tool}
                />
              )}
              {props.tools.length > 2 && (
                <PopoverImagePreviewItem
                  name={props.tools[2].name ?? 'Tool'}
                  overlap
                  src={props.tools[2].template.image_preview?.url}
                  type={ImageFallbackType.Tool}
                />
              )}
              {props.tools.length === 4 && (
                <PopoverImagePreviewItem
                  name={props.tools[3].name ?? 'Tool'}
                  overlap
                  src={props.tools[3].template.image_preview?.url}
                  type={ImageFallbackType.Tool}
                />
              )}
              {props.tools.length > 4 && (
                <li>
                  <Box
                    className={styles['group-tools-button-preview-more']}
                    sx={{ backgroundColor: 'background.paper' }}
                  >
                    <Box
                      className={
                        styles['group-tools-button-preview-more-content']
                      }
                      sx={{
                        backgroundColor: '#444d58',
                        color: 'white',
                      }}
                    >
                      {`+ ${props.tools.length - 3}`}
                    </Box>
                  </Box>
                </li>
              )}
            </ul>
          </Button>
        )}
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          open={open}
          onClose={onClose}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: '0.5rem',
              boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
              height: 'fit-content',
              margin: '0.5rem',
              overflowY: 'auto',
              width: '400px',
            },
            '& .MuiList-root': {
              padding: 0,
            },
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          className={styles['group-tools-container']}
        >
          <Box
            sx={{ borderColor: 'border.app' }}
            className={styles['group-tools-container-title']}
          >
            {t('newscenter.room.groups.tools.title')}
          </Box>
          <div className={styles['group-tools-container-content']}>
            {props.tools
              // Sort tool list by name
              .sort((a: Tool, b: Tool) => {
                const aName = '' + a.name;
                const bName = '' + b.name;
                return aName > bName ? 1 : -1;
              })
              .map((tool) => (
                <GroupToolItem key={tool.id} tool={tool} />
              ))}
          </div>
        </Popover>
      </>
    </>
  );
};
