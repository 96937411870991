import { GodparentschatTranslations } from '../models/godparentschat.translations.interface';

export const godparentschatTranslationsEN: GodparentschatTranslations = {
  header: {
    title: 'Schreibe deinem Pengueen Paten',
  },
  chat: {
    content: {
      text1: 'Schreibe deine erste Nachricht an ',
      text2: '. ',
      text3: ' ist dein Pengueen Pate.',
      text4:
        'Dein Pengueen Pate kann dir bei Fragen sicherlich schnell helfen.',
    },
  },
  button: {
    answer: 'ANTWORTEN',
  },
};
