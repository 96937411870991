import { memo, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Box, Button, Grid } from '@mui/material';
import clsx from 'clsx';

// Assets
import pengueen from '../../../../assets/logo/pengueen_small.png';

// Components
import { Dialog } from '../../../../shared/ui/Dialog/Dialog';
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';
import { MarketFilters } from '../MarketFilters/MarketFilters';
// import { MarketTags } from '../MarketTags/MarketTags';
import { MarketTemplateUse } from '../MarketTemplateUse/MarketTemplateUse';
// import { MarketUseCases } from '../MarketUseCases/MarketUseCases';
import { Popover } from '../../../../shared/ui/Popover/Popover';
import { Search } from '../../../../shared/ui/Search/Search';
// import { TextButton } from '../../../../shared/ui/TextButton/TextButton';
import MarketTemplatePreview from '../MarketTemplatePreview/MarketTemplatePreview';
import { useTools } from '../../../tools/hooks/use-tools.hook';

// Hooks
import { useBreakpoints } from '../../../../shared/hooks/use-breakpoints.hook';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useMarketHttp } from '../../hooks/use-market-http.hook';
import { useMarketMock } from '../../hooks/use-market-mock.hook';
import { useTemplates } from '../../../templates/hooks/use-templates.hook';
// import { useTemplatesHttp } from '../../../templates/hooks/use-templates-http.hook';

// Models
import {
  MarketTemplate as IMarketTemplate,
  MarketTemplatesGetParams,
} from '../../models/market.types';
import {
  TemplateType,
  TemplateUseCase,
} from '../../../templates/models/templates.types';

// Stores
import { useMarketStore } from '../../stores/use-market.store';

// Styles
import styles from './MarketSummary.module.scss';
import { Tooltip } from '../../../../shared/ui/Tooltip/Tooltip';

type MarketTemplateProps = {
  public?: boolean;
  template: IMarketTemplate;
  selectedTemplates?: string[];
  onDeselect: () => void;
  onSelect: () => void;
};

export const MarketTemplate = (props: MarketTemplateProps) => {
  const { handleError } = useFetch();
  const navigate = useNavigate();
  const { templateid } = useParams<'templateid'>();
  const { templatePreviewGet } = useMarketMock();
  // const { templateGet } = useTemplatesHttp();
  const {
    templateGetMutation,
    templateGetMutationMarktplatz,
    templatePublicGetMutation,
  } = useTemplates();
  const { t } = useTranslation();

  // Component
  const [templateToUse, setTemplateToUse] = useState<
    { id: string; name: string } | undefined
  >(undefined);

  // Market store state
  const [setSelectedTemplate] = useMarketStore((state) => [
    state.setSelectedTemplate,
  ]);

  // ######### //
  // MUTATIONS //
  // ######### //

  // GET template mutation
  // const templateGetMutation = useMutation((id: string) => templateGet(id), {
  //   retry: (failureCount, error: any) => handleRetry(failureCount, error),
  // });

  // Add template to state
  useEffect(() => {
    if (templateGetMutationMarktplatz.data) {
      try {
        setSelectedTemplate(templateGetMutationMarktplatz.data);
      } catch (error) {
        console.log('ERROR on getting template:', error);
      }
    }
    if (templateGetMutationMarktplatz.error) {
      // const errRes = templateGetMutationMarktplatz.error?.response;
      // if (errRes) {
      //   handleError(errRes.status);
      // }
      // #TODO: Remove mock data
      setSelectedTemplate(templatePreviewGet());
    }
    // eslint-disable-next-line
  }, [templateGetMutationMarktplatz.data, templateGetMutationMarktplatz.error]);

  // Add template public to state
  useEffect(() => {
    if (templatePublicGetMutation.data) {
      try {
        setSelectedTemplate(templatePublicGetMutation.data);
      } catch (error) {
        console.log('ERROR on getting template:', error);
      }
    }
    if (templatePublicGetMutation.error) {
      // const errRes = templatePublicGetMutation.error?.response;
      // if (errRes) {
      //   handleError(errRes.status);
      // }
      // #TODO: Remove mock data
      setSelectedTemplate(templatePreviewGet());
    }
    // eslint-disable-next-line
  }, [templatePublicGetMutation.data, templatePublicGetMutation.error]);

  useEffect(() => {
    templateid && onTemplateClick(templateid);
    // eslint-disable-next-line
  }, [templateid]);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handler to get template.
   * @param id Template id
   */
  const onTemplateClick = useCallback((id: string) => {
    if (props.public) {
      templatePublicGetMutation.mutate(id);
    } else {
      templateGetMutationMarktplatz.mutate(id);
    }

    // eslint-disable-next-line
  }, []);

  /**
   * Handler to init use template.
   */
  // const onTemplateUseInit = useCallback((id: string, name: string) => {
  //   setTemplateToUse({ id, name });
  // }, []);

  /**
   * Handler to submit use template.
   * @param id Tool id
   */
  const onTemplateUseSubmit = useCallback((id: string) => {
    navigate(`/tools/${id}`);
    setTemplateToUse(undefined);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles['market-template-container']}>
      <Box
        className={styles['market-template']}
        sx={{ borderColor: 'border.app' }}
        onClick={() => onTemplateClick(props.template.id)}
      >
        <Box
          className={styles['market-template-preview']}
          sx={{ '& img': { borderColor: 'bg.card' } }}
        >
          {props.template.settings.icon && (
            <Box
              sx={{ bgcolor: 'white' }}
              className={styles['market-template-preview-icon-container']}
            >
              <img
                alt={props.template.settings.name}
                className={styles['market-template-preview-icon']}
                src={props.template.settings.icon.url}
              />
            </Box>
          )}

          {props.template.community_id?.logo?.url &&
            !props.template.community_id?.logo?.url?.includes('undefined') && (
              <img
                alt="preview-logo"
                className={styles['market-template-preview-logo']}
                src={props.template.community_id?.logo?.url}
              />
            )}
          {/* <div className={styles['market-template-preview-select']}>
            {props.selectedTemplates.includes(props.template.id) ? (
              <IconButton
                icon={['fas', 'check']}
                padding="0.25rem"
                preset="primary"
                onClick={props.onDeselect}
              />
            ) : (
              <TextButton
                padding="0.25rem 0.5rem"
                preset="gray"
                onClick={props.onSelect}
              >
                {t('market.use_cases.grid.item.select')}
              </TextButton>
            )}
          </div> */}
          {props.template.image_market && (
            <img
              alt={props.template.settings.name}
              className={styles['market-template-preview-image']}
              src={props.template.image_market.url}
            />
          )}
        </Box>
        <Box
          className={styles['market-template-content']}
          sx={{ borderColor: 'border.app' }}
        >
          <div className={styles['market-template-content-data']}>
            <Tooltip title={props.template.settings.name}>
              <div className={styles['market-template-content-data-name']}>
                {props.template.settings.name}
              </div>
            </Tooltip>
            {/* {props.template.community &&
              props.template.community.data &&
              props.template.community.data.name && (
                <div className={styles['market-template-content-data-community']}>
                  {props.template.community.data.logo && (
                    <img
                      alt={props.template.community.data.name}
                      src={props.template.community.data.logo?.url}
                    />
                  )}
                  <div
                    className={
                      styles['market-template-content-data-community-name']
                    }
                  >
                    {props.template.community.data.name}
                  </div>
                </div>
              )} */}
          </div>
          {/* <div className={styles['market-template-content-actions']}>
            <Button
              className={styles['market-template-content-actions-more']}
              onClick={() => onTemplateClick(props.template.id)}
            >
              {t('market.use_cases.grid.item.more')}
            </Button>
            <TextButton
              classes={styles['market-template-content-actions-use']}
              padding="0.25rem 0.5rem"
              preset="secondary"
              onClick={() =>
                onTemplateUseInit(
                  props.template.id,
                  props.template.settings.name ??
                    t('market.use.dialog.name_placeholder')
                )
              }
            >
              {t('market.use_cases.grid.item.use')}
            </TextButton>
          </div> */}
        </Box>
      </Box>
      {templateToUse && (
        <Dialog
          open={templateToUse ? true : false}
          subtitle={t('market.use.dialog.subtitle')}
          title={t('market.use.dialog.title')}
          onClose={() => setTemplateToUse(undefined)}
        >
          <MarketTemplateUse
            id={templateToUse.id}
            name={templateToUse.name ?? ''}
            onSubmit={onTemplateUseSubmit}
          />
        </Dialog>
      )}
    </div>
  );
};

type MarketTemplatesGridProps = {
  public?: boolean;
  templates: IMarketTemplate[];
  selectedTemplates: string[];
  use_case: TemplateUseCase;
  disabledTitle?: boolean;
  fullWidth?: boolean;
  onTemplateDeselect: (id: string) => void;
  onTemplateSelect: (id: string) => void;
};

export const MarketTemplatesGrid = (props: MarketTemplatesGridProps) => {
  const { smUp, mdUp, lgUp, xlUp, xxlUp, xxlDown, xxxlDown } = useBreakpoints();
  const { templateUseCaseTranslationGet } = useTemplates();
  const { t } = useTranslation();

  // Component state
  const [showMore, setShowMore] = useState<boolean>(false);
  const [templatesDisplayed, setTemplatesDisplayed] = useState<{
    limit: number;
    start_index: number;
    templates: IMarketTemplate[];
  }>({
    limit: 1,
    start_index: 0,
    templates: [],
  });

  // ####### //
  // EFFECTS //
  // ####### //

  // Set initial displayed templates by current breakpoint
  useEffect(() => {
    let limit = 1;
    if (props.fullWidth) {
      if (smUp && !mdUp) {
        limit = 2;
      } else if (mdUp && !lgUp) {
        limit = 3;
      } else if (lgUp && !xlUp) {
        limit = 4;
      } else if (xlUp) {
        limit = 5;
      }
      if (xxlUp) {
        limit = 6;
      }
      if (!xxxlDown) {
        limit = 8;
      }
    } else {
      if (smUp && !xlUp) {
        limit = 2;
      } else if (xlUp) {
        limit = 3;
      }
      if (xxlUp) {
        limit = 4;
      }
      if (!xxxlDown) {
        limit = 5;
      }
    }

    const templatesToShowInit: IMarketTemplate[] = [];
    for (
      let i = 0;
      i < (props.templates.length < limit ? props.templates.length : limit);
      i++
    ) {
      templatesToShowInit.push(props.templates[i]);
    }
    setTemplatesDisplayed({
      limit,
      start_index: 0,
      templates: templatesToShowInit,
    });
  }, [
    props.templates,
    props.fullWidth,
    smUp,
    mdUp,
    lgUp,
    xlUp,
    xxlUp,
    xxxlDown,
  ]);

  /**
   * Handler to display previous templates.
   */
  const onTemplatesPrev = useCallback(() => {
    // const selectedTemplates = [...props.selectedTemplates];
    const updatedTemplatesDisplayed: IMarketTemplate[] = [];
    // Determine previous start index by current display limit
    let start_index =
      templatesDisplayed.start_index - templatesDisplayed.limit > -1
        ? templatesDisplayed.start_index - templatesDisplayed.limit
        : 0;
    // Start from previous start index to current display limit or templates length
    for (
      let i = start_index;
      i <
      (start_index + templatesDisplayed.limit < props.templates.length
        ? start_index + templatesDisplayed.limit
        : props.templates.length);
      i++
    ) {
      // Push template to updated templates array
      if (props.templates[i]) {
        updatedTemplatesDisplayed.push(props.templates[i]);
      } else {
        break;
      }
    }
    // Set react state with updated start index and templates
    setTemplatesDisplayed({
      limit: templatesDisplayed.limit,
      start_index,
      templates: updatedTemplatesDisplayed,
    });
  }, [props.templates, templatesDisplayed]);

  /**
   * Handler to display next templates.
   */
  const onTemplatesNext = useCallback(() => {
    // const templatesProps = [...props.templates];
    const updatedTemplatesDisplayed: IMarketTemplate[] = [];
    // Start from next start index to current display limit
    for (
      let i = templatesDisplayed.start_index + templatesDisplayed.limit;
      i <
      templatesDisplayed.start_index +
        templatesDisplayed.limit +
        templatesDisplayed.limit;
      i++
    ) {
      // Push template to updated templates array
      if (props.templates[i]) {
        updatedTemplatesDisplayed.push(props.templates[i]);
      } else {
        break;
      }
    }
    // Set react state with updated start index and templates
    setTemplatesDisplayed({
      limit: templatesDisplayed.limit,
      start_index: templatesDisplayed.start_index + templatesDisplayed.limit,
      templates: updatedTemplatesDisplayed,
    });
  }, [props.templates, templatesDisplayed]);

  return (
    <>
      {props.templates.length > 0 && (
        <div
          className={
            props.fullWidth &&
            templatesDisplayed.limit >= props.templates.length
              ? styles['market-templates-grid-full']
              : styles['market-templates-grid']
          }
        >
          {!props.disabledTitle && (
            <div className={styles['market-templates-grid-header']}>
              <div className={styles['market-templates-grid-header-title']}>
                {templateUseCaseTranslationGet(props.use_case)}
              </div>

              {templatesDisplayed.limit < props.templates.length && (
                <Button
                  className={styles['market-templates-grid-header-button']}
                  color="inherit"
                  sx={{ color: 'text.secondary' }}
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore
                    ? t('market.use_cases.grid.show_less')
                    : t('market.use_cases.grid.show_more')}
                </Button>
              )}
            </div>
          )}
          {templatesDisplayed.limit < props.templates.length && !showMore && (
            <>
              <IconButton
                classes={
                  props.fullWidth
                    ? styles['market-templates-grid-navigation-full-prev']
                    : styles['market-templates-grid-navigation-prev']
                }
                disabled={templatesDisplayed.start_index < 1}
                icon={['fas', 'chevron-left']}
                preset="popover"
                onClick={onTemplatesPrev}
              />
              <IconButton
                classes={
                  props.fullWidth
                    ? styles['market-templates-grid-navigation-full-next']
                    : styles['market-templates-grid-navigation-next']
                }
                disabled={
                  templatesDisplayed.start_index + templatesDisplayed.limit >
                  props.templates.length - 1
                }
                icon={['fas', 'chevron-right']}
                preset="popover"
                onClick={onTemplatesNext}
              />
            </>
          )}
          <Grid
            container
            spacing={1}
            sx={{ backgroundColor: 'background.paper' }}
          >
            {showMore
              ? props.templates.map((template) => (
                  <Grid
                    key={template.id}
                    item
                    xs={12}
                    sm={6}
                    xl={xxlDown ? 4 : xxxlDown ? 3 : 2.4}
                  >
                    <MarketTemplate
                      public={props.public}
                      selectedTemplates={props.selectedTemplates}
                      template={template}
                      onDeselect={() => props.onTemplateDeselect(template.id)}
                      onSelect={() => props.onTemplateSelect(template.id)}
                    />
                  </Grid>
                ))
              : templatesDisplayed.templates.map((template) => (
                  <Grid
                    key={template.id}
                    item
                    xs={props.fullWidth ? 12 : 12}
                    sm={props.fullWidth ? 6 : 6}
                    md={props.fullWidth ? 4 : undefined}
                    lg={props.fullWidth ? 3 : undefined}
                    xl={
                      props.fullWidth
                        ? xxlDown
                          ? 2.4
                          : xxxlDown
                          ? 2
                          : 1.5
                        : xxlDown
                        ? 4
                        : xxxlDown
                        ? 3
                        : 2.4
                    }
                  >
                    <MarketTemplate
                      public={props.public}
                      selectedTemplates={props.selectedTemplates}
                      template={template}
                      onDeselect={() => props.onTemplateDeselect(template.id)}
                      onSelect={() => props.onTemplateSelect(template.id)}
                    />
                  </Grid>
                ))}
          </Grid>
        </div>
      )}
    </>
  );
};

type MarketTemplatesProps = {
  public?: boolean;
  templates: IMarketTemplate[];
};

const MarketSummary = (props_templates: MarketTemplatesProps) => {
  const { smDown, lgDown } = useBreakpoints();
  const { handleError, handleRetry } = useFetch();
  const { marketTemplatesGet } = useMarketHttp();
  const { templatesGet } = useMarketMock();
  const { templateUseCasesGet } = useTemplates();
  const { t } = useTranslation();
  const { filterdTemplatePush } = useTools();

  // Component state
  const [anchorFilters, setAnchorFilters] = useState<Element | undefined>(
    undefined
  );
  // const [anchorTags, setAnchorTags] = useState<Element | undefined>(undefined);
  // const [anchorUseCases, setAnchorUseCases] = useState<Element | undefined>(
  //   undefined
  // );

  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const [templates, setTemplates] = useState<IMarketTemplate[]>([]);
  const [filterdTemplates, setFilterdTemplates] = useState<IMarketTemplate[]>(
    []
  );

  // Market store state
  const [
    search,
    // selectedTags,
    selectedTemplate,
    selectedUseCases,
    templateDocument,
    templateProject,
    templateUseOther,
    setSearch,
    setSelectedMenuItemType,
    setSelectedTemplate,
    // setTags,
  ] = useMarketStore((state) => [
    state.search,
    // state.selectedTags,
    state.selectedTemplate,
    state.selectedUseCases,
    state.templateDocument,
    state.templateProject,
    state.templateUseOther,
    state.setSearch,
    state.setSelectedMenuItemType,
    state.setSelectedTemplate,
    // state.setTags,
  ]);

  // ######### //
  // MUTATIONS //
  // ######### //

  // GET Market templates mutation
  const marketTemplatesGetMutation = useMutation(
    (params?: MarketTemplatesGetParams) => marketTemplatesGet(params)
  );

  // Add templates to state
  useEffect(() => {
    if (marketTemplatesGetMutation.data) {
      try {
        setTemplates(marketTemplatesGetMutation.data);
      } catch (error) {
        console.log('ERROR on getting template:', error);
      }
    }
    if (marketTemplatesGetMutation.error) {
      // const errRes = marketTemplatesGetMutation.error?.response;
      // if (errRes) {
      //   handleError(errRes.status);
      // }
      // #TODO: Remove mock data
      setTemplates(templatesGet());
    }
    // eslint-disable-next-line
  }, [marketTemplatesGetMutation.data, marketTemplatesGetMutation.error]);

  // ####### //
  // EFFECTS //
  // ####### //

  // Component mount effects
  useEffect(() => {
    // Set filters data
    // setTags(marketFiltersTagsGet());
    // Set public market templates
    // marketTemplatesGetMutation.mutate({
    //   template_type: TemplateType.Public,
    // });
    return () => {
      setSearch('');
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTemplates(props_templates.templates);
  }, [props_templates.templates]);

  // set filter condition
  useEffect(() => {
    let filterdTemplate: IMarketTemplate[] = [];
    if (templateDocument || templateProject || templateUseOther) {
      templates.map((template) => {
        if (
          templateDocument &&
          template.use_case?.includes(TemplateUseCase.Documentation)
        ) {
          filterdTemplatePush(filterdTemplate, template);
        }
        if (
          templateUseOther &&
          template.use_case?.includes(TemplateUseCase.Other)
        ) {
          filterdTemplatePush(filterdTemplate, template);
        }
        if (
          templateProject &&
          template.use_case?.includes(TemplateUseCase.Project_Management)
        ) {
          filterdTemplatePush(filterdTemplate, template);
        }

        return template;
      });
    } else {
      filterdTemplate = [...templates];
    }
    setFilterdTemplates(filterdTemplate);
    // eslint-disable-next-line
  }, [templateDocument, templateProject, templateUseOther, templates]);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handler on menu item click.
   * @param params MarketTemplatesGetParams
   */
  const onMenuItemClick = useCallback((params?: MarketTemplatesGetParams) => {
    params?.template_type && setSelectedMenuItemType(params?.template_type);
    marketTemplatesGetMutation.mutate(params);
    // eslint-disable-next-line
  }, []);

  /**
   * Handler to deselect template. Remove template from string id array.
   */
  const onTemplateDeselect = useCallback(
    (id: string) => {
      const index = selectedTemplates.findIndex(
        (templateId) => templateId === id
      );
      if (index > -1) {
        const updatedSelectedTemplates = [...selectedTemplates];
        updatedSelectedTemplates.splice(index, 1);
        setSelectedTemplates(updatedSelectedTemplates);
      }
    },
    [selectedTemplates]
  );

  /**
   * Handler to select template. Add template to string id array.
   */
  const onTemplateSelect = useCallback(
    (id: string) => {
      const index = selectedTemplates.findIndex(
        (templateId) => templateId === id
      );
      if (index < 0) {
        const updatedSelectedTemplates = [...selectedTemplates];
        updatedSelectedTemplates.push(id);
        setSelectedTemplates(updatedSelectedTemplates);
      }
    },
    [selectedTemplates]
  );

  return (
    <Box className={styles['market-summary']}>
      {lgDown && (
        <Box className={styles['market-summary-content-header']}>
          <Search
            classes={styles['market-summary-content-header-search']}
            padding="0.5rem 0.75rem"
            placeholder={t('market.filters.search_detail')}
            value={search}
            onChange={setSearch}
          />
          <Popover
            buttonClasses={styles['market-summary-content-header-menu']}
            anchor={anchorFilters}
            disableButtonTitle={smDown ? true : false}
            onOpen={(anchor) => setAnchorFilters(anchor)}
          >
            <MarketFilters onMenuItemClick={onMenuItemClick} disableSearch />
          </Popover>
        </Box>
      )}
      {filterdTemplates.filter(
        (template) =>
          template.settings.name
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          template.settings.description
            ?.toLowerCase()
            .includes(search.toLowerCase())
      ).length > 0 ? (
        <Box
          className={clsx(
            styles['market-summary-content-main'],
            selectedTemplates &&
              selectedTemplates.length > 0 &&
              styles['market-summary-content-main-selected']
          )}
        >
          <div>
            {
              // templates.length > 0 &&
              templateUseCasesGet().map((use_case, index) => {
                if (
                  selectedUseCases.length === 0 ||
                  (selectedUseCases.length > 0 &&
                    selectedUseCases.includes(use_case))
                ) {
                  return (
                    <MarketTemplatesGrid
                      public={props_templates.public}
                      key={index}
                      selectedTemplates={selectedTemplates}
                      templates={filterdTemplates
                        .filter((template) => template.use_case === use_case)
                        .filter(
                          (template) =>
                            template.settings.name
                              ?.toLowerCase()
                              .includes(search.toLowerCase()) ||
                            template.settings.description
                              ?.toLowerCase()
                              .includes(search.toLowerCase())
                        )}
                      use_case={use_case}
                      onTemplateDeselect={onTemplateDeselect}
                      onTemplateSelect={onTemplateSelect}
                    />
                  );
                }
                return null;
              })
            }
          </div>
        </Box>
      ) : (
        <div className={styles['market-summary-content-main-empty']}>
          {t('market.field_empty.text1')}
          <Link to="/tools/workbench">
            <Box
              className={styles['market-summary-content-main-empty-link']}
              sx={{ color: 'primary.main', display: 'inline' }}
            >
              {t('market.field_empty.text2')}
            </Box>
          </Link>
        </div>
      )}

      {/* </Box> */}
      <Dialog
        back={t('market.preview.back')}
        classes={styles['market']}
        disableDividers={selectedTemplate ? true : false}
        open={selectedTemplate ? true : false}
        paddingContent={'0'}
        title={selectedTemplate ? selectedTemplate.settings.name : ''}
        titleSize={lgDown ? 'medium' : 'big'}
        widthClassName={styles['market-width']}
        onBack={() => setSelectedTemplate(undefined)}
        onClose={() => setSelectedTemplate(undefined)}
      >
        <MarketTemplatePreview public={props_templates.public} />
      </Dialog>
      {/* {selectedTemplates && selectedTemplates.length > 0 && (
        <Box
          className={styles['market-summary-selected']}
          sx={{
            backgroundColor: 'background.paper',
            borderColor: 'border.app',
          }}
        >
          <TextButton
            classes={styles['market-summary-selected-cancel']}
            preset="secondary"
            textTransform="none"
            onClick={() => setSelectedTemplates([])}
          >
            {t('market.use_cases.selected.cancel')}
          </TextButton>
          <TextButton
            preset="primary"
            textTransform="none"
            onClick={() =>{}
            }
          >
            {t('market.use_cases.selected.view')}
          </TextButton>
        </Box>
      )} */}
    </Box>
  );
};

export default memo(MarketSummary);
