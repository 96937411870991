// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Checkbox_checkbox__QyvmJ {\n    display: flex;\n    flex-direction: column\n}\n  .Checkbox_checkbox-container__DA-QD {\n    display: flex;\n    align-items: center\n}\n    .Checkbox_checkbox-container-content__qJ462 {\n    margin-left: 0.25rem;\n    border-radius: 0.125rem\n}\n  .Checkbox_checkbox-logo__7qC2q {\n    height: 1.5rem;\n    width: 1.5rem;\n    flex-shrink: 0;\n    border-radius: 0.375rem;\n    object-fit: cover\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;IAAA,cAAA;IAAA;CAAA;EAAA;IAAA,cAAA;IAAA;CAAA;IAAA;IAAA,qBAAA;IAAA;CAAA;EAAA;IAAA,eAAA;IAAA,cAAA;IAAA,eAAA;IAAA,wBAAA;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "Checkbox_checkbox__QyvmJ",
	"checkbox-container": "Checkbox_checkbox-container__DA-QD",
	"checkbox-container-content": "Checkbox_checkbox-container-content__qJ462",
	"checkbox-logo": "Checkbox_checkbox-logo__7qC2q"
};
export default ___CSS_LOADER_EXPORT___;
